import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import { Checkbox as CheckboxInput } from "react-input-checkbox";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createFoodFortificationReports, editFoodFortificationReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment'

const option1 = [
    { id: "1", name: "FBO" },
    { id: "2", name: "FSO" },
    { id: "3", name: "Interdepartment Official" },
    { id: "4", name: "Consumer" },
    {id:"5", name:"Lab Person"}

]
const option2 = [
    { id: "1", name: "Rice" },
    { id: "2", name: "WheatFlour" },
    { id: "3", name: "Flour" },
    { id: "4", name: "Milk" },
    { id: "5", name: "Oil" },
    { id: "6", name: "Salt" },
    {id:"7", name:"Others"}

]


const FoodFortificationReport = (props) => {
    const { districts, user,wards,wardsCategory, onCreateFoodFortificationReports,ongetDist, onEditFoodFortificationReports } = props;
    const [checkedState, setCheckedState] = useState(
        new Array(option1.length).fill(false)
    );

    const [values, setValues] = useState({
        'district': '',
        'date': '',
        'month': '',
        'area_code':'',
        'district_code':'',
        'area_name':'',
        'trainee': '',
        'category': '',
        'no_of_interdepartmental_meetings_conducted': null,
        'no_of_participants_participated_interdepartmental': null,
        'no_of_fso_trained': null,
        'no_of_fso_meetings': null,
        'no_of_fbos_training': null,
        'no_of_fbo_meetings': null,
        'no_of_conusmer_meetings': null,
        'no_of_consumer_trained': null,
        'meeting_during_the_month': null,
        'participant_during_the_month': null,
        'lab_person_meetings':null,
        'lab_person_trained':null,
        'food_fortification_coverage':null,
        'other_category':'',


    });
    const [errors, setErrors] = useState({});
    const [trainee, setTrainee] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        console.log(props);
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;});
            setCheckedState(recordData.training_given_to.map(item => item.check))
        } else if (slug) {
            console.log(slug);
        }
        if (user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district, district_code:user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    // useEffect(()=>{
    //     wardsCategory.map((o)=>{
    //         if(o.id == values.area_code)
    //         return (
    //           setValues({
    //                 ...values,
    //                 area_name: o.ward_name
    //             })
    //         )
    //     })
    // },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district', 
        'date',
        'district_code',
          'meeting_during_the_month',
            'food_fortification_coverage',
            'participant_during_the_month',
            'category',];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if (!values[key] || values[key] === '') {
                updatedErrors[key] = ' ';
            }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }
    const handleOnChange = (position) => {
        console.log(checkedState)
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
            
    }
    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            let training_given=[
                checkedState[0]? {id: '1', name:'FBO', check:true}: {id: '1', name:'FBO', check:false},
                checkedState[1]? {id: '2', name:'FSO',check:true}:{id: '2', name:'FSO',check:false},
                checkedState[2]? {id: '3', name:'Interdepartment Official',check:true}:{id: '3',  name:'Interdepartment Official',check:false},
                checkedState[3]? {id: '4', name:'Consumer',check:true}:{id:'4', name:'Consumer',check:false},
                checkedState[4]? {id: '5', name:'Lab Person',check:true}:{id: '5', name:'Lab Person',check:false} ] 
                let training_given_to = training_given.filter((a)=>a)
            const data = { ...values, training_given_to }
            console.log(data)
            const resp = await onEditFoodFortificationReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/food-fortification-list/`);
            };

        } else {
            let training_given=[
                checkedState[0]? {id: '1', name:'FBO', check:true}: {id: '1', name:'FBO', check:false},
                checkedState[1]? {id: '2', name:'FSO',check:true}:{id: '2', name:'FSO',check:false},
                checkedState[2]? {id: '3', name:'Interdepartment Official',check:true}:{id: '3',  name:'Interdepartment Official',check:false},
                checkedState[3]? {id: '4', name:'Consumer',check:true}:{id:'4', name:'Consumer',check:false},
                checkedState[4]? {id: '5', name:'Lab Person',check:true}:{id: '5', name:'Lab Person',check:false} ] 
                let training_given_to = training_given.filter((a)=>a)
            const data = { ...values, training_given_to }
            setDisableSubmit(true);
            console.log(data)
            const resp = await onCreateFoodFortificationReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Food Fortification Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                      
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district_code"
                                >District Code *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`District Code`}
                                        type="number"
                                        name="district_code"
                                        value={values.district_code}
                                        error={errors.district_code}
                                        onChange={(e) => handleTextChange("district_code", e.target.value)}
                                    />
                                </div>
                            </div>
                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="trainee"
                            >Training Given To *: </label>

                            <div style={{ display: "flex", flexFlow: "row" }} className="col-sm-8 dcheck">
                                {option1.map(({ name, id }, index) => {
                                    return (
                                        <div style={{ display: "flex", padding: " 0 20px" }} key={index}>
                                            <input
                                                type="checkbox"
                                                id={`custom-checkbox-${index}`}
                                                name={name}
                                                value={name}
                                                checked={checkedState[index]}
                                                onChange={() => handleOnChange(index)}
                                            />
                                            <label style={{ paddingLeft: "5px", marginTop: "36px" }} htmlFor={`custom-checkbox-${index}`}>{name}</label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="category"
                            >Category *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Category`}
                                    type="text"
                                    name="category"
                                    action={true}
                                    value={values.category}
                                    error={errors.category}
                                    items={option2}
                                    onChange={(text) => handleTextChange("category", text)}
                                />
                            </div>
                        </div>
                            {values && values.category.includes('Others') ? ( <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_category"
                            > </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Category`}
                                    type="text"
                                    name="other_category"
                                    value={values.other_category}
                                    error={errors.other_category}
                                    onChange={(e) => handleTextChange("other_category", e.target.value)}
                                />
                            </div>
                        </div> ) : null}
                        {checkedState[1] ? (
                            <div>    <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_fso_meetings"
                                >Number of FSO Meetings Conducted : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of FSO Meetings Conducted`}
                                        type="number"
                                        name="no_of_fso_meetings"
                                        value={values.no_of_fso_meetings}
                                        error={errors.no_of_fso_meetings}
                                        onChange={(e) => handleTextChange("no_of_fso_meetings", e.target.value)}
                                    />
                                </div>
                            </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="no_of_fso_trained"
                                    >Number of FSO's Participated : </label>
                                    <div className="col-sm-8">
                                        <TextInput
                                            placeholder={`Number of FSO participated`}
                                            type="number"
                                            name="no_of_fso_trained"
                                            value={values.no_of_fso_trained}
                                            error={errors.no_of_fso_trained}
                                            onChange={(e) => handleTextChange("no_of_fso_trained", e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {checkedState[0] ? (
                            <div>  <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_fbo_meetings"
                                >Number of FBO Meetings Conducted : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of FBO Meetings Conducted`}
                                        type="number"
                                        name="no_of_fbo_meetings"
                                        value={values.no_of_fbo_meetings}
                                        error={errors.no_of_fbo_meetings}
                                        onChange={(e) => handleTextChange("no_of_fbo_meetings", e.target.value)}
                                    />
                                </div>
                            </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="no_of_fbos_training"
                                    >Number of FBO's Participated : </label>
                                    <div className="col-sm-8">
                                        <TextInput
                                            placeholder={`Number of FBO's participated`}
                                            type="number"
                                            name="no_of_fbos_training"
                                            value={values.no_of_fbos_training}
                                            error={errors.no_of_fbos_training}
                                            onChange={(e) => handleTextChange("no_of_fbos_training", e.target.value)}
                                        />
                                    </div>
                                </div></div>
                        ) : null}
                        {checkedState[2] ? (
                            <div>  <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_interdepartmental_meetings_conducted"
                                >Number of Interdepartmental Meetings Conducted : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Interdepartmental Meetings Conducted`}
                                        type="number"
                                        name="no_of_interdepartmental_meetings_conducted"
                                        value={values.no_of_interdepartmental_meetings_conducted}
                                        error={errors.no_of_interdepartmental_meetings_conducted}
                                        onChange={(e) => handleTextChange("no_of_interdepartmental_meetings_conducted", e.target.value)}
                                    />
                                </div>
                            </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="no_of_participants_participated_interdepartmental"
                                    >Number of Participants Participated : </label>
                                    <div className="col-sm-8">
                                        <TextInput
                                            placeholder={`Number of Participants Participated`}
                                            type="number"
                                            name="no_of_participants_participated_interdepartmental"
                                            value={values.no_of_participants_participated_interdepartmental}
                                            error={errors.no_of_participants_participated_interdepartmental}
                                            onChange={(e) => handleTextChange("no_of_participants_participated_interdepartmental", e.target.value)}
                                        />
                                    </div>
                                </div></div>
                        ) : null}
                        {checkedState[3] ? (
                            <div>  <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_conusmer_meetings"
                                >Number of Consumer Meetings Conducted : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Consumer Meetings Conducted`}
                                        type="number"
                                        name="no_of_conusmer_meetings"
                                        value={values.no_of_conusmer_meetings}
                                        error={errors.no_of_conusmer_meetings}
                                        onChange={(e) => handleTextChange("no_of_conusmer_meetings", e.target.value)}
                                    />
                                </div>
                            </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="no_of_consumer_trained"
                                    >Number of Consumer Participated : </label>
                                    <div className="col-sm-8">
                                        <TextInput
                                            placeholder={`Number of Consumer Participated`}
                                            type="number"
                                            name="no_of_consumer_trained"
                                            value={values.no_of_consumer_trained}
                                            error={errors.no_of_consumer_trained}
                                            onChange={(e) => handleTextChange("no_of_consumer_trained", e.target.value)}
                                        />
                                    </div>
                                </div></div>
                        ) : null}
                        {checkedState[4] ? (
                            <div>  <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="lab_person_meetings"
                                >Number of Lab Person Meetings Conducted : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Lab Person Meetings Conducted`}
                                        type="number"
                                        name="lab_person_meetings"
                                        value={values.lab_person_meetings}
                                        error={errors.lab_person_meetings}
                                        onChange={(e) => handleTextChange("lab_person_meetings", e.target.value)}
                                    />
                                </div>
                            </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="lab_person_trained"
                                    >Number of Lab Person Trained: </label>
                                    <div className="col-sm-8">
                                        <TextInput
                                            placeholder={`Number of Lab Person Trained`}
                                            type="number"
                                            name="lab_person_trained"
                                            value={values.lab_person_trained}
                                            error={errors.lab_person_trained}
                                            onChange={(e) => handleTextChange("lab_person_trained", e.target.value)}
                                        />
                                    </div>
                                </div></div>
                        ) : null}
                             <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="food_fortification_coverage"
                            >Number of Food Fortification Coverage *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Food Fortification coverage`}
                                    type="number"
                                    name="food_fortification_coverage"
                                    value={values.food_fortification_coverage}
                                    error={errors.food_fortification_coverage}
                                    onChange={(e) => handleTextChange("food_fortification_coverage", e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="meeting_during_the_month"
                            >Number of Meetings conducted during the month *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of meetings conducted during the month`}
                                    type="number"
                                    name="meeting_during_the_month"
                                    value={values.meeting_during_the_month}
                                    error={errors.meeting_during_the_month}
                                    onChange={(e) => handleTextChange("meeting_during_the_month", e.target.value)}
                                />
                            </div>
                        </div>


                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="participant_during_the_month"
                            >Number of participants participated during the month *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of participants participated during the month`}
                                    type="number"
                                    name="participant_during_the_month"
                                    value={values.participant_during_the_month}
                                    error={errors.participant_during_the_month}
                                    onChange={(e) => handleTextChange("participant_during_the_month", e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFoodFortificationReports: (data) => dispatch(createFoodFortificationReports(data)),
    onEditFoodFortificationReports: (data, record_id) => dispatch(editFoodFortificationReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wardsCategory:state.home.wardsCategory,

});

export default connect(mapStateToProps, mapDispatchToProps)(FoodFortificationReport);
