import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DropDownInput from "../../../components/inputs/DropDownInput";
import TextAreaInput from "../../../components/inputs/TextAreaInput";
import TextInput from "../../../components/inputs/TextInput";
import UploadImageInput from "../../../components/inputs/UploadImageInput";
import {
  getComplaint,
  getComplaints,
  createInspection,
  updateInspection,
  setLoading,
} from "../../../components/reducers/actions";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import PreviewInspection from "./PreviewInspection";
import { useTranslation } from "react-i18next";
// import imageToBase64 from 'image-to-base64/browser';
// import { data } from "jquery";

const AddInspection = (props) => {
  const {
    districts,
    onCreateInspection,
    onUpdateInspection,
    foodConditions,
    foodIssues,
    foodTypes,
    traderTypes,
    onLoading,
  } = props;

  const [step, setStep] = useState(1);
  const [values, setValues] = useState({
    same_address: true,
  });
  const [isSpinner, setIsSpinner] = useState(false);
  const [errors, setErrors] = useState({});
  const [complaint, setComplaint] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [addressOffToggle, setAddressOffToggle] = useState(true);
  const [fssaiToggle, setFssaiToggle] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [step1DisableSubmit, setStep1DisableSubmit] = useState(true);
  const [step2DisableSubmit, setStep2DisableSubmit] = useState(true);
  const [step3DisableSubmit, setStep3DisableSubmit] = useState(true);
  const [step4DisableSubmit, setStep4DisableSubmit] = useState(true);
  const [step5DisableSubmit, setStep5DisableSubmit] = useState(true);
  const [step6DisableSubmit, setStep6DisableSubmit] = useState(true);
  const [step7DisableSubmit, setStep7DisableSubmit] = useState(true);
  const [updateField, setupdateField] = useState([]);
  const { t } = useTranslation();
  const [actionTakenList, setActionTakenList] = useState([
    {
      label: t("seizure"),
      name: "seizure",
      selected: false,
    },
    {
      label: t("sampleLifted"),
      name: "sample_lifted",
      selected: false,
    },
    {
      label: t("notice"),
      name: "notice",
      selected: false,
    },
   
    {
      label: t("fine"),
      name: "fine",
      selected: false,
    },
    {
      label: t("closeComplaint"),
      name: "close_complaint",
      selected: false,
    },
  ]);

  const validateValues = (values) => {
    const updatedErrors = {};
    if (step === 1) {
      if (addressOffToggle) {
        var step1DisableSubmit = true;
        let keys = [];
        keys.forEach((key) => {
          if (!values[key] || values[key] === "") {
            updatedErrors[key] = " ";
          }
        });
        step1DisableSubmit = Object.keys(updatedErrors).length > 0;
        setStep1DisableSubmit(step1DisableSubmit);
      } else {
        var shouldDisableSubmit = true;
        let keys = ["sub_division", "village", "district", "zipcode"];
        keys.forEach((key) => {
          if (!values[key] || values[key] === "") {
            updatedErrors[key] = " ";
          }
        });
        if (values.zipcode && values.zipcode.length !== 6) {
          updatedErrors.zipcode = "Zipcode should be 6 characters";
        } else if (values.zipcode && values.zipcode[0] !== "6") {
          updatedErrors.zipcode = "Not a valid Tamil Nadu zipcode.";
        }
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
      }
    } else if (step === 2) {
      var step2DisableSubmit = true;
      let keys = ["fssai_number"];
      keys.forEach((key) => {
        if (!values[key] || values[key].length !== 14) {
          updatedErrors[key] = "Fssai number should be atleast 14 characters";
        }
      });
      step2DisableSubmit = Object.keys(updatedErrors).length > 0;
      setStep2DisableSubmit(step2DisableSubmit);
    } else if (step === 3) {
      var step3DisableSubmit = true;
      let keys = [
        "auth_person_name",
        "auth_person_designation",
        "auth_person_phone_number",
      ];
      keys.forEach((key) => {
        if (!values[key] || values[key] === "") {
          updatedErrors[key] = " ";
        }
      });
      if (
        values.auth_person_phone_number &&
        (!values.auth_person_phone_number ||
          values.auth_person_phone_number.length !== 10)
      ) {
        updatedErrors.auth_person_phone_number = "Invalid phone number";
      }
      step3DisableSubmit = Object.keys(updatedErrors).length > 0;
      setStep3DisableSubmit(step3DisableSubmit);
    } else if (step === 4) {
      var step4DisableSubmit = true;
      let keys = ["nature_of_complaint"];
      keys.forEach((key) => {
        if (!values[key] || values[key] === "") {
          updatedErrors[key] = " ";
        }
      });
      step4DisableSubmit = Object.keys(updatedErrors).length > 0;
      setStep4DisableSubmit(step4DisableSubmit);
    } else if (step === 5) {
      var step5DisableSubmit = true;
      let keys = ["fso_observation"];
      keys.forEach((key) => {
        if (!values[key] || values[key] === "") {
          updatedErrors[key] = " ";
        }
      });
      step5DisableSubmit = Object.keys(updatedErrors).length > 0;
      setStep5DisableSubmit(step5DisableSubmit);
    } else if (step === 6) {
      var step6DisableSubmit = true;
      console.log(values.action_taken)
      var keys = ["action_taken",'action_taken_photo_image_1'];
      if (values && values.action_taken && values.action_taken.length === 0) {
        keys = ["action_taken",'action_taken_photo_image_1'];
      } else{ 
        if (
        values &&
        values.action_taken &&
        values.action_taken.includes("sample_lifted")
      ) {
        // keys = ["action_taken", "action_taken_sample_number"];
        keys.push('action_taken_sample_number','sample_lifted_action_taken_message')
      } 
       if (
        values &&
        values.action_taken &&
        values.action_taken.includes("fine")
      ) {
        // keys = ["action_taken", "action_taken_fine_amount"];
        keys.push('action_taken_fine_amount','fine_action_taken_message')
      }  if (
        values &&
        values.action_taken &&
        values.action_taken.includes("notice")
      ) {
        // keys = ["action_taken_photo_image_1"];
        keys.push('notice_action_taken_message')
      }
      if (
        values &&
        values.action_taken &&
        values.action_taken.includes("seizure")
      ) {
        keys.push('seizure_action_taken_message')
      }
      if (
        values &&
        values.action_taken &&
        values.action_taken.includes("close_complaint")
      ) {
        keys.splice(1,1)
        keys.push('closed_action_taken_message')
      }
    }
    
      keys.forEach((key) => {
        if (!values[key] || values[key].length === 0) {
          updatedErrors[key] = " ";
        }
       
      });
      if (
        updatedErrors["action_taken_photo_image_1"] &&
        (values["action_taken_photo_image_3"] ||
          values["action_taken_photo_image_2"] || values["action_taken_photo_image_4"] || values["action_taken_photo_image_5"] || values["action_taken_photo_image_6"])
      ) {
        delete updatedErrors["action_taken_photo_image_1"];
      }
      console.log(updatedErrors,keys);
      step6DisableSubmit = Object.keys(updatedErrors).length > 0;
      setStep6DisableSubmit(step6DisableSubmit);
    } else if (step === 7) {
      var step7DisableSubmit = true;
      let keys = [];
      keys.forEach((key) => {
        if (!values[key] || values[key] === "") {
          updatedErrors[key] = " ";
        }
        
      });
      step7DisableSubmit = Object.keys(updatedErrors).length > 0;
      setStep7DisableSubmit(step7DisableSubmit);
    }
    setErrors(updatedErrors);
    return updatedErrors;
  };

  const goBack = () => {
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (props.history.location && props.history.location.state) {
      let complaint = props.history.location.state.complaint;
      let inspection = props.history.location.state.inspection;
      console.log(props.history)
      if (props.history.location.state.coordinates) {
        let location = props.history.location.state.coordinates;
        setCoordinates([location.lat, location.lng]);
      }
      if (inspection) {
        setAddressOffToggle(inspection.same_address);
        setFssaiToggle(inspection.has_fssai_number);
      }
      setComplaint(complaint);
      if (inspection) {
        inspection.fssai_photos.forEach((item, idx) => {
          if (item) {
            inspection = {
              ...inspection,
              [`fssai_image_${idx + 1}`]: `${item}`,
            };
          }
        });
        inspection.auth_person_photos.forEach((item, idx) => {
          if (item) {
            inspection = {
              ...inspection,
              [`auth_person_photo_image_${
                idx + 1
              }`]: `${item}`,//data:image/png;base64,
            };
          }
        });

        inspection.nature_of_complaint_photos.forEach((item, idx) => {
          if (item) {
            inspection = {
              ...inspection,
              [`nature_of_complaint_image_${
                idx + 1
              }`]: `${item}`,
            };
          }
        });
        inspection.fso_observation_photos.forEach((item, idx) => {
          if (item) {
            inspection = {
              ...inspection,
              [`fso_observation_image_${
                idx + 1
              }`]: `${item}`,
            };
          }
        });
        inspection.action_taken_photos.forEach((item, idx) => {
          if (item) {
            inspection = {
              ...inspection,
              [`action_taken_photo_image_${
                idx + 1
              }`]: `${item}`,
            };
          }
        });
        inspection.fso_additional_photos.forEach((item, idx) => {
          if (item) {
            inspection = {
              ...inspection,
              [`fso_add_comment_image_${
                idx + 1
              }`]: `${item}`,
            };
          }
        });
        if (
          inspection.action_taken &&
          typeof inspection.action_taken === "string"
        ) {
          inspection.action_taken = [inspection.action_taken];
        }
        let actionTakenListData = [...actionTakenList];
        actionTakenListData.forEach((item) => {
          if (inspection.action_taken.includes(item.name)) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
        setActionTakenList(actionTakenListData);
        setValues(inspection);
        console.log('vali_inpection', inspection)
      }
    }
    validateValues(values);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!step) {
      setStep(1);
    }
    validateValues(values);
    // eslint-disable-next-line
  }, [step]);

  let foodConditon = null;
  foodConditions.forEach((item) => {
    if (complaint && item.id === complaint.condition_of_food) {
      foodConditon = item.name;
    }
  });

  let traderType = null;
  traderTypes.forEach((item) => {
    if (complaint && item.id === complaint.type_of_trader) {
      traderType = item.name;
    }
  });

  let natureOfFood = null;
  foodTypes.forEach((item) => {
    if (complaint && item.id === complaint.nature_of_food) {
      natureOfFood = item.name;
    }
  });

  let issueOfFood = null;
  foodIssues.forEach((item) => {
    if (complaint && item.id === complaint.issue_of_food) {
      issueOfFood = item.name;
    }
  });

  const nextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const handleTextChange = (key, value) => {
    var updatedValues = { ...values };
   
    console.log(updatedValues)
    if (key === "action_taken") {
      var index =
        updatedValues.action_taken && updatedValues.action_taken.indexOf(value);
      if (
        value === "close_complaint" &&
        updatedValues.action_taken &&
        updatedValues.action_taken.indexOf("close_complaint") > -1
      ) {
        updatedValues.action_taken.splice(index, 1);
      } else if (
        value === "close_complaint" ||
        (updatedValues.action_taken &&
          updatedValues.action_taken.indexOf("close_complaint") > -1)
      ) {
        updatedValues = { ...values, [key]: ["close_complaint"] };
      } else if (index === -1) {
        updatedValues = { ...values, [key]: [...values[key], value] };
      } else if (index > -1) {
        updatedValues.action_taken.splice(index, 1);
      } else {
        updatedValues = { ...values, [key]: [value] };
      }
      let actionTakenListData = [...actionTakenList];
      actionTakenListData.forEach((item) => {
        if (
          updatedValues.action_taken &&
          updatedValues.action_taken.includes("close_complaint") &&
          item.name === "close_complaint"
        ) {
          item.selected = true;
        } else if (
          updatedValues.action_taken &&
          updatedValues.action_taken.includes(item.name) &&
          !updatedValues.action_taken.includes("close_complaint")
        ) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });
      setActionTakenList(actionTakenListData);
    } else {
      updatedValues = { ...values, [key]: value };
    }
    if (!updateField.includes(key)) {
      setupdateField([...updateField, key]);
    }
    if (
      key === "action_taken" &&
      !updatedValues.action_taken.includes("sample_lifted") &&
      values &&
      values.action_taken_sample_number
    ) {
      delete updatedValues["action_taken_sample_number"];
      delete updatedValues["sample_lifted_action_taken_message"];
    }
    if (
      key === "action_taken" &&
      !updatedValues.action_taken.includes("fine") &&
      values &&
      values.action_taken_fine_amount
    ) {
      delete updatedValues["action_taken_fine_amount"];
      delete updatedValues["fine_action_taken_message"];
    }
    if (
      key === "action_taken" &&
      !updatedValues.action_taken.includes("notice") &&
      values &&
      values.notice_action_taken_message
    ) {
      delete updatedValues["notice_action_taken_message"];
    }
    if (
      key === "action_taken" &&
      !updatedValues.action_taken.includes("close_complaint") &&
      values &&
      values.closed_action_taken_message
    ) {
      delete updatedValues["closed_action_taken_message"];
    }
    if (
      key === "action_taken" &&
      !updatedValues.action_taken.includes("seizure") &&
      values &&
      values.seizure_action_taken_message
    ) {
      delete updatedValues["seizure_action_taken_message"];
    }

    setValues(updatedValues);
    validateValues(updatedValues);
    console.log(values,updatedValues)

  };

  const handleSwitch = () => {
    validateValues(values);
    handleTextChange("same_address", !addressOffToggle);
    setAddressOffToggle((prevState) => !prevState);
  };

  const handleFssaiSwitch = () => {
    validateValues(values);
    handleTextChange("has_fssai_number", !fssaiToggle);
    setFssaiToggle((prevState) => !prevState);
  };

  const handleRemoveImages = (key, value) => {
    const updatedValues = { ...values };
    delete updatedValues[key];
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  const submitComplaintInspection = async () => {
    const toBase64=async (url) => {
      if(url.split('/')[0]==='https:'){
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onload = () => {
            const base64data = reader.result;   
            resolve(base64data);
          }
        });
      }
     else return url
    }
    
    setIsSpinner(true);
    onLoading(true);
    if (props.history.location && props.history.location.state.inspection) {
      var updatedData = {};
      updateField.forEach((key) => {
        if (
          values[key] &&
          !key.startsWith("fssai_") &&
          !key.startsWith("auth_person_photo_") &&
          !key.startsWith("nature_of_complaint_") &&
          !key.startsWith("fso_observation_") &&
          !key.startsWith("action_taken_photo_") &&
          !key.startsWith("fso_add_comment_")
        ) {
          updatedData[key] = values[key];
        }
      });
      
      const fssai_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("fssai_image_")) {
        fssai_photos.push(value);
        }
      });
      const auth_person_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("auth_person_photo_image_")) {
          auth_person_photos.push(value);
        }
      });
      const nature_of_complaint_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("nature_of_complaint_image_")) {
           nature_of_complaint_photos.push(value);
        }
      });
      const fso_observation_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("fso_observation_image_")) {
           fso_observation_photos.push(value);
        }
      });
      const action_taken_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("action_taken_photo_image_")) {
          action_taken_photos.push(value);
        }
      });
      const fso_add_comment_photos = [];
      Object.entries(values).forEach(async([key, value]) => {
        if (key.startsWith("fso_add_comment_image_")) {
          fso_add_comment_photos.push(value);
        }
      });
 
      const data = {
        ...updatedData,
        // ...values,
        fso_observation: values["fso_observation"],
        coordinates: coordinates,
        nature_of_complaint: values["nature_of_complaint"],
        fssai_photos: fssai_photos,
        auth_person_photos: auth_person_photos,
        nature_of_complaint_photos: nature_of_complaint_photos,
        fso_observation_photos: fso_observation_photos,
        action_taken_photos: action_taken_photos,
        fso_additional_photos: fso_add_comment_photos,
        complaint_id: complaint.id,
      };
      // if(data.fssai_photos.length!==0){
      //   data.fssai_photos.forEach((i=> toBase64(i)))
      // }
      // if(data.nature_of_complaint_photos.length!==0){
      //   data.nature_of_complaint_photos.forEach((i=> toBase64(i)))
      // }
      // if(data.fso_observation_photos.length!==0){
      //   data.fso_observation_photos.forEach((i=> toBase64(i)))
      // }
      // if(data.action_taken_photos.length!==0){
      //   data.action_taken_photos.forEach((i=> toBase64(i)))
      // }
      // if(data.fso_additional_photos.length!==0){
      //   data.fso_additional_photos.forEach((i=> toBase64(i)))
      // }
      const resp =  await onUpdateInspection(data);
      if (resp.response) {
        setIsSpinner(false);
        onLoading(false);
        props.history.push(`/app/complaints/${complaint.id}`);
      } else {
        props.history.push(`/app/complaints`);
      }
    } else {
      const fssai_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("fssai_image_")) {
          fssai_photos.push(value);
        }
      });
      const auth_person_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("auth_person_photo_image_")) {
          auth_person_photos.push( value);
        }
      });
      const nature_of_complaint_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("nature_of_complaint_image_")) {
          nature_of_complaint_photos.push(value);
        }
      });
      const fso_observation_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("fso_observation_image_")) {
          fso_observation_photos.push(value);
        }
      });
      const action_taken_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("action_taken_photo_image_")) {
          action_taken_photos.push(value);
        }
      });
      const fso_add_comment_photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("fso_add_comment_image_")) {
          fso_add_comment_photos.push(value);
        }
      });
      const data = {
        ...values,
        fssai_photos: fssai_photos,
        coordinates: coordinates,
        auth_person_photos: auth_person_photos,
        nature_of_complaint_photos: nature_of_complaint_photos,
        fso_observation_photos: fso_observation_photos,
        action_taken_photos: action_taken_photos,
        fso_additional_photos: fso_add_comment_photos,
        complaint: complaint.id,
      };
      console.log('post_inspection',data)
      const resp = await onCreateInspection(data);
      if (resp.response) {
        setIsSpinner(false);
        onLoading(false);
        props.history.push(`/app/complaints/${complaint.id}`);
      } else {
        props.history.push(`/app/Complaints`);
      }
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">{t(props.title)}</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <form>
            {
              {
                step1: (
                  <>
                    <div className="text-center">
                      <h3>{t("Address")}</h3>
                    </div>
                    <div>
                      <ToggleSwitch
                        title="Same address as provided in the complaint ?"
                        value={addressOffToggle}
                        onValueChange={() => handleSwitch()}
                      />
                      {complaint && complaint.address && addressOffToggle ? (
                        <>
                          <p className="mt-3">{complaint.address}</p>
                          <TextInput
                            placeholder={`${t("CWN")} `}
                            type="text"
                            name="ward_number"
                            value={values.ward_number}
                            error={errors.ward_number}
                            onChange={(e) =>
                              handleTextChange("ward_number", e.target.value)
                            }
                          />
                        </>
                      ) : null}
                      {!addressOffToggle ? (
                        <>
                          <div className="form-group mt-3">
                            <TextInput
                              placeholder={`${t("House/Flat No")}`}
                              type="text"
                              name="street"
                              value={values.street}
                              error={errors.street}
                              onChange={(e) =>
                                handleTextChange("street", e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <TextInput
                              placeholder={`${t("Street Address")} 1 *`}
                              type="text"
                              name="sub_division"
                              value={values.sub_division}
                              error={errors.sub_division}
                              onChange={(e) =>
                                handleTextChange("sub_division", e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <TextInput
                              placeholder={`${t("Street Address")} 2`}
                              type="text"
                              name="sub_division1"
                              value={values.sub_division1}
                              error={errors.sub_division1}
                              onChange={(e) =>
                                handleTextChange(
                                  "sub_division1",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="form-group">
                            <TextInput
                              placeholder={`${t("City")} `}
                              type="text"
                              name="village"
                              value={values.village}
                              error={errors.village}
                              onChange={(e) =>
                                handleTextChange("village", e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <DropDownInput
                              placeholder={`${t("District")} *`}
                              type="text"
                              name="district"
                              value={values.district}
                              error={errors.district}
                              items={districts}
                              onChange={(text) =>
                                handleTextChange("district", text)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <TextInput
                              placeholder={`${t("ZipCode")} `}
                              type="text"
                              name="zipcode"
                              value={values.zipcode}
                              error={errors.zipcode}
                              onChange={(e) =>
                                handleTextChange("zipcode", e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <TextInput
                              placeholder={`${t("CWN")} `}
                              type="text"
                              name="ward_number"
                              value={values.ward_number}
                              error={errors.ward_number}
                              onChange={(e) =>
                                handleTextChange("ward_number", e.target.value)
                              }
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="form-group mt-5">
                      <button
                        onClick={() => props.history.push("/app/complaints")}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        disabled={
                          addressOffToggle ? step1DisableSubmit : disableSubmit
                        }
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </>
                ),
                step2: (
                  <>
                    <div className="text-center">
                      <h3>{t("Fssai Number")}</h3>
                    </div>
                    <div>
                      <ToggleSwitch
                        title="Has Fssai Number ?"
                        value={fssaiToggle}
                        onValueChange={() => handleFssaiSwitch()}
                      />
                      {fssaiToggle ? (
                        <>
                          <div className="form-group">
                            <TextInput
                              placeholder={`${t("Fssai Number")}`}
                              type="text"
                              name="fssai_number"
                              value={values.fssai_number}
                              error={errors.fssai_number}
                              onChange={(e) =>
                                handleTextChange("fssai_number", e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group mt-4 mb-4">
                            <h6 className="mb-3">Photos</h6>
                            <p>{t('upp')}</p>
                            <div className="row">
                              <div className="col-sm-3">
                                <UploadImageInput
                                  size={80}
                                  photo={values.fssai_image_1}
                                  name="fssai_image_1"
                                  handleRemoveImages={(text) =>
                                    handleRemoveImages("fssai_image_1", text)
                                  }
                                  onChangeText={(text) =>
                                    handleTextChange("fssai_image_1", text)
                                  }
                                />
                              </div>
                              <div className="col-sm-3">
                                <UploadImageInput
                                  size={80}
                                  photo={values.fssai_image_2}
                                  name="fssai_image_2"
                                  handleRemoveImages={(text) =>
                                    handleRemoveImages("fssai_image_2", text)
                                  }
                                  onChangeText={(text) =>
                                    handleTextChange("fssai_image_2", text)
                                  }
                                />
                              </div>
                              <div className="col-sm-3">
                                <UploadImageInput
                                  size={80}
                                  photo={values.fssai_image_3}
                                  name="fssai_image_3"
                                  handleRemoveImages={(text) =>
                                    handleRemoveImages("fssai_image_3", text)
                                  }
                                  onChangeText={(text) =>
                                    handleTextChange("fssai_image_3", text)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="form-group mt-5">
                      <button
                        onClick={() => goBack()}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Back")}
                      </button>
                      <button
                        disabled={!fssaiToggle ? false : step2DisableSubmit}
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </>
                ),
                step3: (
                  <>
                    <div className="text-center">
                      <h3>{t("Authorised person in premises")}</h3>
                    </div>
                    <div>
                      <div className="form-group">
                        <TextInput
                          placeholder={`${t("Name")}*`}
                          type="text"
                          name="auth_person_name"
                          value={values.auth_person_name}
                          error={errors.auth_person_name}
                          onChange={(e) =>
                            handleTextChange("auth_person_name", e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <TextInput
                          placeholder={`${t("Designation")}*`}
                          type="text"
                          name="auth_person_designation"
                          value={values.auth_person_designation}
                          error={errors.auth_person_designation}
                          onChange={(e) =>
                            handleTextChange(
                              "auth_person_designation",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="form-group">
                        <TextInput
                          placeholder={`${t("Phone Number")}*`}
                          type="number"
                          name="auth_person_phone_number"
                          value={values.auth_person_phone_number}
                          error={errors.auth_person_phone_number}
                          onChange={(e) =>
                            handleTextChange(
                              "auth_person_phone_number",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mt-4 mb-4">
                      <h6 className="mb-3">Photos</h6>
                      <p>{t('upp')}</p>
                      <div className="row">
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.auth_person_photo_image_1}
                            name="auth_person_photo_image_1"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "auth_person_photo_image_1",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange(
                                "auth_person_photo_image_1",
                                text
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.auth_person_photo_image_2}
                            name="auth_person_photo_image_2"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "auth_person_photo_image_2",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange(
                                "auth_person_photo_image_2",
                                text
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.auth_person_photo_image_3}
                            name="auth_person_photo_image_3"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "auth_person_photo_image_3",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange(
                                "auth_person_photo_image_3",
                                text
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-5">
                      <button
                        onClick={() => goBack()}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Back")}
                      </button>
                      <button
                        disabled={step3DisableSubmit}
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </>
                ),
                step4: (
                  <>
                    <div className="text-center">
                      <h3>{t("Nature of Complaint")}</h3>
                    </div>
                    <div>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>Type of Trader</td>
                            <td>{traderType}</td>
                          </tr>
                          <tr>
                            <td>Nature of Food</td>
                            <td>{natureOfFood}</td>
                          </tr>
                          <tr>
                            <td>Condition of Food</td>
                            <td>{foodConditon}</td>
                          </tr>
                          <tr>
                            <td>Issue of Food</td>
                            <td>{issueOfFood}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="form-group">
                      <TextAreaInput
                        placeholder={`${t("Nature of Complaint")}*`}
                        name="nature_of_complaint"
                        error={errors.nature_of_complaint}
                        value={values.nature_of_complaint}
                        onChange={(e) =>
                          handleTextChange(
                            "nature_of_complaint",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group mt-4 mb-4">
                      <h6 className="mb-3">Photos</h6>
                      <p>{t('upp')}</p>
                      <div className="row">
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.nature_of_complaint_image_1}
                            name="nature_of_complaint_image_1"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "nature_of_complaint_image_1",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange(
                                "nature_of_complaint_image_1",
                                text
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.nature_of_complaint_image_2}
                            name="nature_of_complaint_image_2"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "nature_of_complaint_image_2",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange(
                                "nature_of_complaint_image_2",
                                text
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.nature_of_complaint_image_3}
                            name="nature_of_complaint_image_3"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "nature_of_complaint_image_3",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange(
                                "nature_of_complaint_image_3",
                                text
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-5">
                      <button
                        onClick={() => goBack()}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Back")}
                      </button>
                      <button
                        disabled={step4DisableSubmit}
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </>
                ),
                step5: (
                  <>
                    <div className="text-center">
                      <h3>{t("Fso Observation")}</h3>
                    </div>
                    <div className="form-group">
                      <TextAreaInput
                        placeholder={`${t("Fso Observation")}*`}
                        name="fso_observation"
                        error={errors.fso_observation}
                        value={values.fso_observation}
                        onChange={(e) =>
                          handleTextChange("fso_observation", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group mt-4 mb-4">
                      <h6 className="mb-3">Photos</h6>
                      <p>{t('upp')}</p>
                      <div className="row">
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.fso_observation_image_1}
                            name="fso_observation_image_1"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "fso_observation_image_1",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange("fso_observation_image_1", text)
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.fso_observation_image_2}
                            name="fso_observation_image_2"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "fso_observation_image_2",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange("fso_observation_image_2", text)
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.fso_observation_image_3}
                            name="fso_observation_image_3"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "fso_observation_image_3",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange("fso_observation_image_3", text)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-5">
                      <button
                        onClick={() => goBack()}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Back")}
                      </button>
                      <button
                        disabled={step5DisableSubmit}
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </>
                ),
                step6: (
                  <>
                    <div className="text-center">
                      <h3>{t("Action Taken")}</h3>
                    </div>
                    <div>
                      <div className="row">
                        {actionTakenList
                          ? actionTakenList.map((item, idx) => {
                              return (
                                <div key={idx} className="col-sm3">
                                  <button
                                    type="button"
                                    className="btn btn-success m-2"
                                    style={{
                                      backgroundColor: item.selected
                                        ? "#1cc88a"
                                        : "#1cc88a66",
                                      borderColor: item.selected
                                        ? "#1cc88a"
                                        : "#1cc88a00",
                                    }}
                                    onClick={() =>
                                      handleTextChange(
                                        "action_taken",
                                        item.name
                                      )
                                    }
                                  >
                                    {item.label}
                                  </button>
                                </div>
                              );
                            })
                          : null}
                      </div>
                      {values &&
                      values.action_taken &&
                      values.action_taken.includes("seizure") &&
                      !values.action_taken.includes("close_complaint") ? (
                        <div className="mt-2">
                          <p>{t("SCC")}</p>
                          <div className="form-group mt-2">
                            <TextAreaInput
                              placeholder={`${t("Enter your comment")} *`}
                              name="seizure_action_taken_message"
                              error={errors.seizure_action_taken_message}
                              value={values.seizure_action_taken_message}
                              onChange={(e) =>
                                handleTextChange(
                                  "seizure_action_taken_message",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                      {values &&
                      values.action_taken &&
                      values.action_taken.includes("sample_lifted") &&
                      !values.action_taken.includes("close_complaint") ? (
                        <>
                          <p className="mt-2">{t("SLC")}</p>
                          <div className="form-group mt-2">
                            <TextInput
                              placeholder={`${t("SNCC")}*`}
                              type="text"
                              name="action_taken_sample_number"
                              value={values.action_taken_sample_number}
                              error={errors.action_taken_sample_number}
                              onChange={(e) =>
                                handleTextChange(
                                  "action_taken_sample_number",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="mt-2">
                            <p>{t("SLCC")}</p>
                            <div className="form-group mt-2">
                              <TextAreaInput
                                placeholder={`${t("Enter your comment")} *`}
                                name="sample_lifted_action_taken_message"
                                error={
                                  errors.sample_lifted_action_taken_message
                                }
                                value={
                                  values.sample_lifted_action_taken_message
                                }
                                onChange={(e) =>
                                  handleTextChange(
                                    "sample_lifted_action_taken_message",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                         
                         {values &&
                      values.action_taken &&
                      values.action_taken.includes("notice") &&
                      !values.action_taken.includes("close_complaint") ? (
                        <div className="mt-2">
                          <p>{t("NCC")}</p>
                          <div className="form-group mt-2">
                            <TextAreaInput
                              placeholder={`${t("Enter your comment")} *`}
                              name="notice_action_taken_message"
                              error={errors.notice_action_taken_message}
                              value={values.notice_action_taken_message}
                              onChange={(e) =>
                                handleTextChange(
                                  "notice_action_taken_message",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                      {values &&
                      values.action_taken &&
                      values.action_taken.includes("fine") &&
                      !values.action_taken.includes("close_complaint") ? (
                        <>
                          <p className="mt-2">{t("FNCC")}</p>
                          <div className="form-group mt-2">
                            <TextInput
                              placeholder={`${t("FNCC")}`}
                              type="number"
                              name="action_taken_fine_amount"
                              value={values.action_taken_fine_amount}
                              error={errors.action_taken_fine_amount}
                              onChange={(e) =>
                                handleTextChange(
                                  "action_taken_fine_amount",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="mt-2">
                            <p>{t("FCC")}</p>
                            <div className="form-group mt-2">
                              <TextAreaInput
                                placeholder={`${t("Enter your comment")} *`}
                                name="fine_action_taken_message"
                                error={errors.fine_action_taken_message}
                                value={values.fine_action_taken_message}
                                onChange={(e) =>
                                  handleTextChange(
                                    "fine_action_taken_message",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                  
                      {values &&
                      values.action_taken &&
                      values.action_taken.includes("close_complaint") ? (
                        <div className="mt-2">
                          <p>{t("CCC")}</p>
                          <div className="form-group mt-2">
                            <TextAreaInput
                              placeholder={`${t("Enter your comment")} *`}
                              name="closed_action_taken_message"
                              error={errors.closed_action_taken_message}
                              value={values.closed_action_taken_message}
                              onChange={(e) =>
                                handleTextChange(
                                  "closed_action_taken_message",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group mt-4 mb-4">
                        <h6 className="mb-3">
                          {values &&
                          values.action_taken &&
                          values.action_taken.includes("close_complaint")
                            ? "Photos"
                            : "Photos *"}
                        </h6>
                        <p>{t('upp')}</p>
                        <div className="row">
                          <div className="col-sm-3">
                            <UploadImageInput
                              size={80}
                              photo={values.action_taken_photo_image_1}
                              name="action_taken_photo_image_1"
                              handleRemoveImages={(text) =>
                                handleRemoveImages(
                                  "action_taken_photo_image_1",
                                  text
                                )
                              }
                              onChangeText={(text) =>
                                handleTextChange(
                                  "action_taken_photo_image_1",
                                  text
                                )
                              }
                            />
                          </div>
                          <div className="col-sm-3">
                            <UploadImageInput
                              size={80}
                              photo={values.action_taken_photo_image_2}
                              name="action_taken_photo_image_2"
                              handleRemoveImages={(text) =>
                                handleRemoveImages(
                                  "action_taken_photo_image_2",
                                  text
                                )
                              }
                              onChangeText={(text) =>
                                handleTextChange(
                                  "action_taken_photo_image_2",
                                  text
                                )
                              }
                            />
                          </div>
                          <div className="col-sm-3">
                            <UploadImageInput
                              size={80}
                              photo={values.action_taken_photo_image_3}
                              name="action_taken_photo_image_3"
                              handleRemoveImages={(text) =>
                                handleRemoveImages(
                                  "action_taken_photo_image_3",
                                  text
                                )
                              }
                              onChangeText={(text) =>
                                handleTextChange(
                                  "action_taken_photo_image_3",
                                  text
                                )
                              }
                            />
                          </div>
                          <div className="col-sm-3">
                            <UploadImageInput
                              size={80}
                              photo={values.action_taken_photo_image_4}
                              name="action_taken_photo_image_4"
                              handleRemoveImages={(text) =>
                                handleRemoveImages(
                                  "action_taken_photo_image_4",
                                  text
                                )
                              }
                              onChangeText={(text) =>
                                handleTextChange(
                                  "action_taken_photo_image_4",
                                  text
                                )
                              }
                            />
                          </div>
                          <div className="col-sm-3">
                            <UploadImageInput
                              size={80}
                              photo={values.action_taken_photo_image_5}
                              name="action_taken_photo_image_5"
                              handleRemoveImages={(text) =>
                                handleRemoveImages(
                                  "action_taken_photo_image_5",
                                  text
                                )
                              }
                              onChangeText={(text) =>
                                handleTextChange(
                                  "action_taken_photo_image_5",
                                  text
                                )
                              }
                            />
                          </div>
                          <div className="col-sm-3">
                            <UploadImageInput
                              size={80}
                              photo={values.action_taken_photo_image_6}
                              name="action_taken_photo_image_6"
                              handleRemoveImages={(text) =>
                                handleRemoveImages(
                                  "action_taken_photo_image_6",
                                  text
                                )
                              }
                              onChangeText={(text) =>
                                handleTextChange(
                                  "action_taken_photo_image_6",
                                  text
                                )
                              }
                            />
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-5">
                      <button
                        onClick={() => goBack()}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Back")}
                      </button>
                      <button
                        disabled={step6DisableSubmit}
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </>
                ),
                step7: (
                  <>
                    <div className="text-center">
                      <h3>{t("Fso Additional Comments")}</h3>
                    </div>
                    <div className="form-group mt-2">
                      <TextAreaInput
                        placeholder={t("Fso Additional Comments")}
                        name="fso_additional_comment"
                        error={errors.fso_additional_comment}
                        value={values.fso_additional_comment}
                        onChange={(e) =>
                          handleTextChange(
                            "fso_additional_comment",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group mt-4 mb-4">
                      <h6 className="mb-3">Photos</h6>
                      <p>{t('upp')}</p>
                      <div className="row">
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.fso_add_comment_image_1}
                            name="fso_add_comment_image_1"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "fso_add_comment_image_1",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange("fso_add_comment_image_1", text)
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.fso_add_comment_image_2}
                            name="fso_add_comment_image_2"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "fso_add_comment_image_2",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange("fso_add_comment_image_2", text)
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <UploadImageInput
                            size={80}
                            photo={values.fso_add_comment_image_3}
                            name="fso_add_comment_image_3"
                            handleRemoveImages={(text) =>
                              handleRemoveImages(
                                "fso_add_comment_image_3",
                                text
                              )
                            }
                            onChangeText={(text) =>
                              handleTextChange("fso_add_comment_image_3", text)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mt-5">
                      <button
                        onClick={() => goBack()}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Back")}
                      </button>
                      <button
                        disabled={step7DisableSubmit}
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Preview")}
                      </button>
                    </div>
                  </>
                ),
                step8: (
                  <PreviewInspection
                    inspection={values}
                    complaint={complaint}
                    goBack={goBack}
                    isSpinner={isSpinner}
                    disableSubmit={step7DisableSubmit}
                    submitComplaintInspection={submitComplaintInspection}
                  />
                ),
              }[`step${step}`]
            }
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onComplaint: (data) => dispatch(getComplaint(data)),
  onComplaints: (data) => dispatch(getComplaints(data)),
  onCreateInspection: (data) => dispatch(createInspection(data)),
  onUpdateInspection: (data) => dispatch(updateInspection(data)),
  onLoading: (data) => dispatch(setLoading(data)),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  districts: state.home.districts,
  foodConditions: state.home.foodConditions,
  traderTypes: state.home.traderTypes,
  foodTypes: state.home.foodTypes,
  foodIssues: state.home.foodIssues,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInspection);
