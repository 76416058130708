import React from "react";
import { Table } from "react-bootstrap";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { userDirectory } from "../../components/reducers/actions";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Who = (props) => {
  const { t, i18n } = useTranslation();
  const { onUserDirectory, user } = props;
  const [users, setUsers] = useState();
  const [count, setCount] = useState();

  const getValue = async () => {
    const dt = {'groups_id':1}
    const resp = await onUserDirectory(dt);
    if (resp.response) {
        console.log(resp)
      let data = Object.values(resp.response)[0];
      let number = Object.values(resp.response)[0];
      setUsers(data);
      setCount(number);
    } else {
      console.log("error");
    }
  };
  useEffect(() => {
    getValue();
  }, []);
  useEffect(() => {
    getValue();
  }, [user]);
  return (
    <div>
      <Header activeMenu="About" />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title ">{t("OD")}</h1>
             
            </div>
          </div>
        </div>
      </header>
      <div className="table_top th_small">
        {users && (
          <>
            <Table bordered hover responsive style={{ fontSize: 13 }}>
              {users.length !== 0 && (
                <>
                  <thead>
                    <tr  className="tr_small_th">
                      <th>{t("Designation")}</th>
                      <th>{t("Address")}</th>
                      <th>{t("Email")}</th>
                      <th>{t("Contact Number")}</th>
                      {/* <th className="th_small_tr" colSpan={3}>
                        Commissioner of Food Safety (FAC)
                      </th> */}
                    </tr>
                  </thead>
                        <tbody>
                          {users.map((i, index)=>(
                            <tr>
                            <td>{i.designations}</td>
                            <td>
                              <tr>{i.designations}</tr><br/>
                              <tr>{i.location}</tr>
                            </td>
                            <td>{i.email}</td>
                            <td>{i.phone_number}</td>
                          </tr>
                          ))}
                          
                        </tbody>
   
                </>
              )}
            </Table>
          </>
        )}
      </div>
      <div style={{textAlign:'center'}} className='directory_links'>
     <Link to="/adjudication-officers">{t("Click here for list of Adjudication officers")}</Link><br/>
     <Link to="/fso-directory">{t("Click here for list of Food Safety officers")}</Link><br/>
     <Link to="/lab-analyst-directory">{t("Click here for list of Food analyst")}</Link><br/>
     <Link to="/do-directory">{t("Click here for list of Designated officers")}</Link><br/>
     <Link to="/tribunal-officers-directory">{t("Click here for list of Appellate Tribunal Officers")}</Link><br/>
     <Link to="/user-directory">{t("Click here for list of Commissionerate")}</Link><br/>
    </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  onUserDirectory: (data) => dispatch(userDirectory(data)),
});

const mapStateToProps = (state) => ({
  user:state.home.user
});

export default connect(mapStateToProps, mapDispatchToProps)(Who);
