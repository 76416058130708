import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createPenaltyReports, editPenaltyReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";

const TypeOfTrader = [
    { id: '0', name: 'Manufacturer' },
    { id: '1', name: 'Retailer' },
    { id: '2', name: 'WholeSaler/Distributor/Repacker' },
    { id: '3', name: 'Transporter' },
    { id: '4', name: 'Others' },
]
const Appeal = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const Offens=[
    {id:'0', name:'Tobacco'},
    {id:'1', name:'Plastic'},
    {id:'1', name:'Others'},
]
const Converted = [
    {id:'0', name:'Case Converted'},
    {id:'1', name:'Minor Faulty/Defect Case Closed'},
]
const Case = [
    {id:'0', name:'Civil'},
    {id:'1', name:'Criminal'},
]
const CompoundPenaltyReport = (props) => {
    const { districts,wards, user, onCreatePenaltyReports,traderTypes,wardsCategory,ongetDist, onEditPenaltyReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'no_of_shops_inspected':'',
        'no_of_shops_found_violation':'',
        'no_of_notice_issued_shops':'',
        'no_of_shops_fined_under_compound_penalty':'',
        'amount_of_compound_penalty':'',
       'category_of_fbo':'',
       'type_of_offense':'',
       'other_type_of_offense':'',
        'address_of_fbo':'',
        'date_amount_paid':'',
        'upload_document':'',
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            values.category_of_fbo =parseInt(values.category_of_fbo)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])


    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
        'district' ,
        'date' ,
        'area_code',
        "area_name",
        'no_of_shops_inspected',
        'no_of_shops_found_violation',
        'no_of_notice_issued_shops',
        'no_of_shops_fined_under_compound_penalty',
        'amount_of_compound_penalty',
       'category_of_fbo',
       'type_of_offense',
       'address_of_fbo',
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditPenaltyReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/compound-penalty-list/`);
            };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onCreatePenaltyReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Compound Penalty Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"> Type of offense : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Type of offense`}
                                        type="text"
                                        name="type_of_offense"
                                        value={values.type_of_offense}
                                        error={errors.type_of_offense}
                                        items={Offens}
                                        action='dropdown'
                                        onChange={(text) => handleTextChange("type_of_offense", text)}
                                    />
                                </div>
                            </div>
                            {values && values.type_of_offense.includes('Others')&&(
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_offense"
                                >Other type of offense *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Other type of offense`}
                                        type="text"
                                        name="other_type_of_offense"
                                        value={values.other_type_of_offense}
                                        error={errors.other_type_of_offense}
                                        onChange={(e) =>handleTextChange("other_type_of_offense", e.target.value)}
                                    />
                                </div>
                            </div>
                            )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="category_of_fbo"
                            >Category Of FBO *:</label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Category Of FBO`}
                                    name="category_of_fbo"
                                    items={traderTypes}
                                    value={values.category_of_fbo}
                                    error={errors.category_of_fbo}
                                    onChange={(text) =>handleTextChange("category_of_fbo", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_fbo"
                            >Address of FBO *:  </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of FBO`}
                                    type="number"
                                    name="address_of_fbo"
                                    value={values.address_of_fbo}
                                    error={errors.address_of_fbo}
                                    onChange={(e) =>handleTextChange("address_of_fbo", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_shops_inspected"
                            >No Of Shops Inspected *:  </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No Of Shops Inspected`}
                                    type="number"
                                    name="no_of_shops_inspected"
                                    value={values.no_of_shops_inspected}
                                    error={errors.no_of_shops_inspected}
                                    onChange={(e) =>handleTextChange("no_of_shops_inspected", e.target.value)}
                                />
                            </div>
                        </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_shops_found_violation"
                            >No Of Shops Found Violation *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No Of Shops Found Violation`}
                                    type='number'
                                    name="no_of_shops_found_violation"
                                    value={values.no_of_shops_found_violation}
                                    error={errors.no_of_shops_found_violation}
                                    onChange={(e) =>handleTextChange("no_of_shops_found_violation", e.target.value)}
                                />
                            </div>
                        </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_shops"
                            >No Of Notice Issued Shops *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No Of Notice Issued Shops`}
                                    name="no_of_notice_issued_shops"
                                    type="number"
                                    value={values.no_of_notice_issued_shops}
                                    error={errors.no_of_notice_issued_shops}
                                    onChange={(e) =>handleTextChange("no_of_notice_issued_shops", e.target.value)}
                                />
                            </div>
                        </div> 
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_shops_fined_under_compound_penalty"
                            >No Of Shops Fined Under Compound Penalty *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    type='number'
                                    placeholder={`No Of Shops Imposed Under Compound Penalty`}
                                    name="no_of_shops_fined_under_compound_penalty"
                                    value={values.no_of_shops_fined_under_compound_penalty}
                                    error={errors.no_of_shops_fined_under_compound_penalty}
                                    onChange={(e) =>handleTextChange("no_of_shops_fined_under_compound_penalty", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="amount_of_compound_penalty"
                         >Amount Of Compound Penalty *: </label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Amount Of Compound Penalty`}
                                 name="amount_of_compound_penalty"
                                 type="number"
                                 value={values.amount_of_compound_penalty}
                                 error={errors.amount_of_compound_penalty}
                                 onChange={(e) =>handleTextChange("amount_of_compound_penalty", e.target.value)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_amount_paid"
                            >Amount Paid On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Amount Paid On `}
                                    name="date_amount_paid"
                                    id="date_amount_paid"
                                    value={values.date_amount_paid}
                                    error={errors.date_amount_paid}
                                    onChangeText={(text) => handleTextChange('date_amount_paid', text)}
                                />
                            </div>
                        </div>             
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_document"
                            >Upload Challon: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Challon`}
                                    type="file"
                                    name="upload_document"
                                    error={errors.upload_document}
                                    onChange={(e) =>handleTextChange("upload_document", e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreatePenaltyReports: (data) => dispatch(createPenaltyReports(data)),
    onEditPenaltyReports: (data, record_id) => dispatch(editPenaltyReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    traderTypes: state.home.traderTypes,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(CompoundPenaltyReport);