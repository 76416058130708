import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const SpecialProgramDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            console.log(recordData)
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Special Program Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
                        <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
                        <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Name of the FSO / DO</th>
                 <td className="text-left">{records.name_of_the_fso_do}</td>
                </tr>
              {/* <tr>
                <th>Foscos Inspection Completed Upto Date</th>
                <td className="text-left">{records.foscos_inspection_completed_upto_date}</td>
              </tr> */}
              <tr>
                <th>Foscos Inspection Completed During the Day</th>
                <td className="text-left">{records.foscos_inspection_completed_during_the_day}</td>
              </tr>
              <tr>
                <th>Foscos Inspection Completed Upto Previous Date</th>
                <td className="text-left">{records.foscoris_inspection_completed_upto_date}</td>
              </tr>
              <tr>
                <th>Foscoris Inspection Completed During the Day</th>
                <td className="text-left">{records.foscoris_inspection_completed_during_the_day}</td>
              </tr>
              <tr>
                <th>Number of FBO's Attended Basic Fostac Training(Upto Date)</th>
                <td className="text-left">{records.fostac_basic_training_attended_fbos_upto_date}</td>
              </tr>
              <tr>
                <th>Number of FBO's Attended Advance Fostac Training(Upto Date)</th>
                <td className="text-left">{records.fostac_advance_training_attended_fbos_upto_date}</td>
              </tr>
              <tr>
                <th>Number of FBO's Attended Special Fostac Training(Upto Date)</th>
                <td className="text-left">{records.fostac_special_training_attended_fbos_upto_date}</td>
              </tr>
              {/* <tr>
                <th>Number of FBO's Attended SNF Resource Fostac Training(Upto Date)</th>
                <td className="text-left">{records.fostac_snf_resource_training_attended_fbos_upto_date}</td>
              </tr> */}
              <tr>
                <th>Number of FBO's Attended Basic Fostac Training(During the Day)</th>
                <td className="text-left">{records.fostac_basic_training_attended_fbos_during_the_day}</td>
              </tr>
              <tr>
                <th>Number of FBO's Attended Advance Fostac Training(During the Day)</th>
                <td className="text-left">{records.fostac_advance_training_attended_fbos_during_the_day}</td>
              </tr>
              <tr>
                <th>Number of FBO's Attended Special Fostac Training(During the Day)</th>
                <td className="text-left">{records.fostac_special_training_attended_fbos_during_the_day}</td>
              </tr>
              {/* <tr>
                <th>Number of FBO's Attended SNF Resource Fostac Training(During the Day)</th>
                <td className="text-left">{records.fostac_snf_resource_training_attended_fbos_during_the_day}</td>
              </tr>           */}
              <tr>
                <th>Raid Conducted (Number of Shops Inspected)</th>
                <td className="text-left">{records.raid_conducted_no_of_shops_inspected}</td>
              </tr>    
              <tr>
                <th>Raid Conducted (Number of Notice Issued)</th>
                <td className="text-left">{records.raid_conducted_no_of_notice_issued}</td>
              </tr>
              <tr>
                <th>Raid Conducted (Seizure made on panmasala and gutka in kg's)</th>
                <td className="text-left">{records.raid_conducted_seizures_made_panmasala_gutka}</td>
              </tr>
              <tr>
                <th>Raid Conducted (Seizure made on Solid Food Item in kg's)</th>
                <td className="text-left">{records.raid_conducted_seizures_made_solid_food_items_in_kg}</td>
              </tr>
              <tr>
                <th>Raid Conducted (Seizure Made on Solid Food Items in Packets/Boxes)</th>
                <td className="text-left">{records.raid_conducted_seizures_made_solid_food_items_in_packets_boxes}</td>
              </tr>          
              <tr>
                <th>Raid Conducted (Plastic Seized)</th>
                <td className="text-left">{records.raid_conducted_plastic_seized}</td>
              </tr>
              <tr>
                <th>Raid Conducted (Seizure Made on Cool Drinks or Any Other Liquid Food Items in Ltr)</th>
                <td className="text-left">
                  {records.raid_conducted_seizures_made_on_liquid_food_items}</td>
              </tr>
              <tr>
                <th>Raid Conducted (Seizure Made on Others)</th>
                <td className="text-left">{records.raid_conducted_seizure_made_on_others}</td>
              </tr>
              <tr>
                <th>Clean Street Food (Number of Mobile vendor Inspected)</th>
                <td className="text-left">{records.clean_street_food_no_of_mobile_vendor_inspected}</td>
              </tr>
              <tr>
                <th>Clean Street Food (Number of Mobile Vendor's Motivated)</th>
                <td className="text-left">{records.clean_street_food_no_of_mobile_vendor_motivated}</td>
              </tr>
              <tr>
                <th>RUCO (Number Of Establishment Inspected)</th>
                <td className="text-left">{records.ruco_no_of_establishment_inspected}</td>
              </tr>
              <tr>
                <th>RUCO (Number Of Establishment Motivated)</th>
                <td className="text-left">{records.ruco_no_of_establishment_motivated}</td>
              </tr>
              <tr>
                <th>RUCO (Quantity Of Re-Used Oil Sent to Bio-Diesel)</th>
                <td className="text-left">{records.ruco_quantity_of_re_used_oil_sent_to_bio_diesel}</td>
              </tr>
              <tr>
                <th>BHOG (Number of Temple's Visited)</th>
                <td className="text-left">{records.bhog_no_of_temples_visited}</td>
              </tr>
              <tr>
                <th>BHOG (Number of Temple's Motivated)</th>
                <td className="text-left">{records.bhog_no_of_temples_motivated}</td>
              </tr>
              <tr>
                <th>Hygine Rating (Number Of Establishment Inspected)</th>
                <td className="text-left">{records.no_of_establishment_inspected_on_hygine_rating}</td>
              </tr>
              <tr>
                <th>Hygine Rating (Number Of Establishment Motivated)</th>
                <td className="text-left">{records.no_of_establishment_motivated_on_hygine_rating}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards:state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialProgramDetails);