import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const RUCOBiodieselDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            console.log(recordData)
            recordData.date = moment(recordData.date).format("DD-MM-YYYY")
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">RUCO Biodiesel Records Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{records.date}</td>
              </tr>
                <tr>
                  <th>Name Of the FBO</th>
                 <td className="text-left">{records.name_of_fbo}</td>
                </tr>
                <tr>
                  <th>Address Of the FBO</th>
                   <td className="text-left">{records.address_of_fbo}</td>
                </tr>
                <tr>
                  <th>Name Of the Agency</th>
                   <td className="text-left">{records.agency_name}</td>
                </tr>
                <tr>
                  <th>Address Of the Agency</th>
                   <td className="text-left">{records.address_of_agency}</td>
                </tr>
                <tr>
                  <th>Number Of Kilolitres Sent For Biodiesel</th>
                   <td className="text-left">{records.no_of_kilolitres_sent_for_biodiesel}</td>
                </tr>
        
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(RUCOBiodieselDetails);