import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createAppeal, createFSOSampleReports, createSampleSuccess, editFSOSampleReports, getAsignUser, getDistrictWards, getFoodCategory, getinspectionSamples, getSurvSamples, testingLab, updateSample } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";

const Result = [
    {id:'0', name:'Confirm To Standards'},
    {id:'1', name:'Unsafe'},
    {id:'2', name:'Substandarded'},
    {id:'3', name:'Labelling Defects'},
    {id:'4', name:'Misleading'},
    {id:'5', name:'Miscellaneous'},

]
const FiledBy=[
    {id:'0', name:'FSO'},
    {id:'1', name:'DO'},
    {id:'2', name:'FBO'},
    // {id:'3', name:'Others'},
]
const Judg=[
    {id:'0', name:'Convicted'},
    {id:'1', name:'Acquittal'},
]
const Resul = [
    {id:'0',name:'Hearing (Civil)'},
    {id:'1',name:'Final Judgement (Civil)'},
]
const Results = [
    {id:'0',name:'Hearing (Criminal)'},
    {id:'1',name:'Final Judgement (Criminal)'},
]
const Clarification = [
    {id:'0',name:'Yes'},
    {id:'1',name:'No'},
]
const Converted = [
    {id:'0', name:'Case Converted'},
    {id:'1', name:'Minor Faulty/Defect Case Closed'},
]
const Case = [
    {id:'0', name:'Civil'},
    {id:'1', name:'Criminal'},
]
const SampleSent = [
    {id:'0', name:'Courirer'},
    {id:'1', name:'Postal'},
    {id:'2', name:'Email'},
]

const SampleAnalysed = [
    {id:'0', name:'Analysed Within 14Days'},
    {id:'1', name:'Delay'},
]
const Fit = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const TypeOfTraders = [
    {id:'0', name:'Manufacturer'},
    {id:'1', name:'Retailer'},
    {id:'2', name:'WholeSaler/Distributor/Repacker'},
    {id:'3', name:'Transporter'},
    {id:'4', name:'Others'},

]

const resultData = [
    { id:"0", name: 'misbranded' },
    { id:"1", name: 'substandard'},
    { id:"2", name: 'unsafe'}
]
const Rectified=[
    { id:"0", name: 'yes' },
    { id:"1", name: 'no'},
]
const Aanlysi=[
    {id:'0', name:'Willing to test'},
    {id:'1', name:'Not opted to test'},
]
const JudgementResult = [
    {id:'0', name:'Convicted'},
    {id:'1', name:'Acquittal'},
]
const Retailer = [
    {id:'0', name:'WholeSaler'},
    {id:'1', name:'Distributor'},
    {id:'2', name:'Manufacturer'},
    {id:'3', name:'Repacker'},
]
const TypeOfFbo=[
    {id:'0', name:'SoloPreparator'},
    {id:'1', name:'Firm'},
    {id:'2', name:'Company'},
]
const Firm = [
    {id:'0', name:'Partnership'},
    {id:'1', name:'Private'},
]
const Company = [
    {id:'0', name:'Director'},
    {id:'1', name:'Nominee'},
]
const Partnership =[
    {id:'0', name:'Partner'},
    {id:'1', name:'Nominee'},
]
const Fines =[
    {id:'0', name:'Fine'},
    {id:'1', name:'Fine with imprisonment'},  
]
const Allowed = [
    {id:'0', name:'Appeal Allowed'},
    {id:'1', name:'Dismissed'},
]
const Distributor = [
    {id:'0', name:'Manufacturer'},
]
const Transporter = [
    {id:'0', name:'Manufacturer'},
    {id:'1', name:'Retailer'},
    {id:'2', name:'WholeSaler/Distributor'},
    {id:'3', name:'Transporter'},
]
const Others = [
    {id:'0', name:'Manufacturer'},
    {id:'1', name:'Retailer'},
    {id:'2', name:'WholeSaler/Distributor'},
    {id:'3', name:'Transporter'},
]
const TypeOfSample = [
    {id:'0', name:'Act'},
    {id:'1', name:'Surveillance'},
]
const IntimatedToDO = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const personInPremisis = [
    {id:'0', name:'Owner'},
    {id:'1', name:'Worker'}
]

const ConditionOfSample = [
    {id:'0', name:'Prepacked'},
    {id:'1', name:'Loose'},
]

const FSOSampleReport = (props) => {
    const { districts, user,wards,ongetAssignUser,users,ongetSample,ongetSampe,ongetDist,fso_submit_data,sample_data, traderTypes,wardsCategory,foodTypes,fsoOfficers, onCreateFSOSampleReports, onEditFSOSampleReports,ongetLabs,onCreateAppeal,ongetSurveilDetails,ongetInspDetails,ongetFoodCategory } = props;
    const [values, setValues] = useState({
        'district' : '',
        'area_code':'',
        'area_name':'',
       'type_of_trader':'',
       'name_of_respondant_premisis':'',
       'address_of_respondant_premisis':'',
       'name_of_the_respondant':'',
       'address_of_respondant_residence':'',
       'upload_documents_id_proof':'',
       'FSSAI_license_number':'',
       'upload_document_of_license':'',
       'type_of_sample_lifted':'',
       'form_V_given_to':'',
       'fbo_firm':'',
       'batch_lot_no':'',
       'upload_documents_of_V_A':'',
        'date_slip_given_date':'',
        'date_result_signed_date':'',
       'sample_food_category':'',
       'sample_number_given_by_do':'',
       'upload_sample_slip':'',
       'sample_purchased_receipt_in_RS':'',
       'condition_of_sample':'',
       'name_of_the_food_specified_in_label':'',
        'willing':'',
        'not_willing':'',
        'analys_sample':'',
       'name_of_the_product_purchased':'',
       'address_of_the_product_purchased':'',
       'date_form_V_sent_to_owner':'',
       'upload_documents':'',
       'sample_sent_by':'',
       'upload_postal_receipt':'',
       'Product_bought_from':'',
       'select_the_lab':'',
       'FBO_sample_4th_part_analysis_nabl_option_intimated':'',  
       'name_and_address_of_nabl_lab':'',
       'type_of_fbo':'',
       'private_partner':'',
       'nominee':'',
       'other_type_of_trader':'',
       'other_select_the_lab':'',
       'sample_no_year':'',
       'name_of_the_food_safety_officer':'',
       'nature_of_the_sample_with_brand_name':'',
       'category_of_food_stuff':'',
       'name_of_the_manufacturing_company':'',
       'name_and_address_of_sampling':'',
       'category_of_trader':'',
       'name_of_the_laboratory':'',
       'act_samples_lifted':'',
       'result':'',
       'other_name_of_the_laboratory':'',
       'license_registration_number':'',
       'license_registration_company':'',
       'remarks':'',
       'evidence_upload':'',
       'district' : '',
       'report_details':'',
       'rectified':'',
       'status_of_report':'',
       'sample_analysed':'',
       'result_number':'',
       'result_details':'',
       'result_type':'',
       'result_intimated_to_DO_by':'',
       'delay_documents':'',
       'upload':'',
       'sample_fit_for_analysis':'',
        'sample_code':'',
        'slip_matched':'',
        'date_sample_received_on':'',
        'case_converted':'',
        'case_category':'',
        'report_intimated_to_DO_by':'',
        'date_report_intimated_to_DO_on':'',
        'date_so_file_received_date':'',
        'so_need_clarification':'',
        'date_so_file_signed_date':'',
        'assign_file_to':'',
        'so_remarks':'',
        'so_docs':'',
        'date_so_final_file_received_date':'',
        'date_so_final_file_sent_date':'',
        'date_do_file_received_date':'',
        'do_need_clarification':'',
        'date_do_file_signed_date':'',
        'date_do_file_sent_date':'',
        'do_remarks':'',
        'do_docs':'',
        'date_fso_file_received_date':'',
        'fso_need_clarification':'',
        'date_fso_file_signed_date':'',
        'date_fso_file_sent_date':'',
        'fso_remarks':'',
        'fso_docs':'',
        'date_app2lab_file_received_date':'',
        'app2lab_need_clarification':'',
        'date_app2lab_file_signed_date':'',
        'date_app2lab_file_sent_date':'',
        'date_app2_file_sent_date':'',
        'app2lab_remarks':'',
        'app2lab_docs':'',
        'date_app2_so_file_received_date':'',
        'app2_need_clarification':'',
        'date_app2_file_signed_date':'',
        'app2_remarks':'',
        'app2_docs':'',
        'date_app1_file_received_date':'',
        'app1_need_clarification':'',
        'date_app1_file_signed_date':'',
        'app1_remarks':'',
        'date_ac_file_received_date':'',
        'ac_need_clarification':'',
        'date_ac_file_signed_date':'',
        'ac_remarks':'',
        'ac_docs':'',
        'date_dd_file_received_date':'',
        'dd_need_clarification':'',
        'date_dd_file_signed_date':'',
        'date_dd_file_sent_date':'',
        'dd_remarks':'',
        'dd_docs':'',
        'date_commissioner_file_received_date':'',
        'commissioner_need_clarification':'',
        'date_commissioner_file_signed_date':'',
        'commissioner_remarks':'',
        'commissioner_docs':'',
        'date_ao_file_received_date':'',
        'date_ao_filed_at_court':'',
        'ao_file_number':'',
        'ao_remarks':'',
        'ao_docs':'',
        'hearing':'',
        'final_judgement':'',
        'date_next_hearing_date':'',
        'hearing_remarks':'',
        'penalty_amount':'',
        'date_Sample_damaged_intimation_received_from_lab_on':'',
        'date_another_part_sample_sent_to_lab_on':'',
        'second_part_upload_documents':'',
        'date_report_received_on':'',
        'date_report_intimated_to_fso_on' : '',
        'date_report_intimated_to_fbo_on':'',
        'appeal_received_from_fbo':'',
        'date_appeal_received_from_fbo_on':'',
        'appeal_allowed':'',
        'date_sent_another_part_of_sample':'',
        'lab_name':'',
        'lab_address':'',
        'date_result_sent_to_fso_on':'',
        'dismiss_reason':'',
        'second_part_other_lab':'',
        'date_sample_sent_to_lab_on':'',
        'date_appeal_received_from_do_on':'',
        'date_of_manufacturing_packing':'',
        'appeal_preferred_by':'',
        'date_appeal_approved_on':'',
        'date_approval_sent_to_do_on':'',
        'date_expiry_date_valid_upto':'',
        date_do_file_signed_date:'',
        date_sample_lifted_date_and_time:'',
        date_ac_file_sent_date:'',
        date_commissioner_file_sent_date:'',
        date_app1_file_sent_date:'',
        date_app2_final_file_received_date:'',
        date_app2_final_filed_at_court:'',
        app2_final_docs:'',
        app2_final_remarks:'',
        app2_case_file_number:'',
        app_case_file_result:'',
        app_hearing_remarks:'',
        app_casefine_file_result:'',
        date_app_next_hearing_date:'',
        fine_amount:'',
        fine_amount_prison:'',
        date_second_part_sample_received_on:'',
        date_ao_appeal_received_from_do_on:'',
        appeal_ao_preferred_by:'',
        other_ao_appeal_preferred_by:'',
        date_ao_appeal_approved_on:'',
        section_file_number:'',
        date_result_received_from_lab_on:'',
        date_result_intimated_to_fso_on:'',
        date_result_intimated_to_fbo_on:'',
        next_hearing_remarks:'',
        court_result_doc:'',
        ao_court_result_doc:'',
        date_do_clarify_file_received_date:'',
        date_clarification_sent_on:'',
        do_clarify_docs:'',
        do_clarify_remarks:'',
        date_approved_file_received_from_prosecution:'',
        court_result:'',
        assign_file_next:'',
        reappeal_number:'',
        date_recommedation_by_do:'',
        fit_for_appeal:'',
        assign_file_to_seat:'',
            });
    const [errors, setErrors] = useState({});
    const [showres, setShowRes]=useState(false);
    const [sampleType, setSampleType]=useState();
    const [sampleAction, setSampleAction]=useState();
    const [dummyData, setDummyData]=useState();
    const [popup, setPopup]=useState(false);
    const [oldData, setOldData]=useState([]);
    const [showSample, setShowSample]=useState(false);
    const[Lab,setLab]=useState([]);
    const [rowView, setRowView]=useState(false)
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [showLess, setShowLess]=useState(false);
    const [asignUser, setAsignUser]=useState([]);
    const [asignSeat, setAsignSeat]=useState([]);
    const [foodCategory, setFoodCategory]=useState([]);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getUser=(id)=>{
        var data = ''
        if (users){
            data = users&& users.filter((i=>i.id==id.id))[0].name
        }
        else data = id.name
        return data
    }
const getLabots=async()=>{
    const resp = await ongetLabs()
    if(resp.response){
        const labs = resp.response.push({id:resp.response.length+1,name:'Others'})
        setLab(resp.response)
    }
}
const getFoods=async()=>{
    const resp = await ongetFoodCategory()
    if(resp.response){
        console.log(resp)
        setFoodCategory(resp.response)
    }
}
useEffect(()=>{
    getFoods()
},[])
const handlBack=()=>{
    console.log(props.history.location.state)
    {
        if(user&&user.user_types&&user.user_types.includes('so')
        ||user&&user.user_types&&user.user_types.includes('twoapp')
        ||user&&user.user_types&&user.user_types.includes('dd')
        ||user&&user.user_types&&user.user_types.includes('AO')
        ||user&&user.user_types&&user.user_types.includes('labapp')
        ||user&&user.user_types&&user.user_types.includes('ac')
        ||user&&user.user_types&&user.user_types.includes('commissioner')
        ||user&&user.user_types&&user.user_types.includes('apt')
        ||user&&user.user_types&&user.user_types.includes('oneapp')){
         props.history.push('/app/dep-case-reports')
        }
        else if((props.history.location.state&&props.history.location.state.doFinal) 
        ||(props.history.location.state&&props.history.location.state.fsoFinal)
        ||(props.history.location.state&&props.history.location.state.resultAPP)
        ||(props.history.location.state&&props.history.location.state.clarify)
        ||(props.history.location.state&&props.history.location.state.fso)
        ||(props.history.location.state&&props.history.location.state.appIIFinal)
        ||(props.history.location.state&&props.history.location.state.do)
        ||(props.history.location.state&&props.history.location.state.doFinal)
        ||(props.history.location.state&&props.history.location.state.appealApp)
        ||(user&&user.user_types&&user.user_types.includes('fso') && props.history.location.state&&props.history.location.state.hearing_)
        ){
         props.history.push('/app/dep-case-reports')
        } 
       else props.history.push('/app/dep-sample-reports')
    }
}
const getListUser=async()=>{
   const resp= await ongetAssignUser({user_types:null})
   if(resp&&resp.response){
    setAsignUser(resp.response.data)
   }
}
const getSeatUser=async()=>{
    const resp= await ongetAssignUser({user_types:'so'})
    console.log('========> SO', resp)
    if(resp&&resp.response){
        setAsignSeat(resp.response.data)
    }
 }
const getDistrictName =(id)=>{
    var nam = ''
    let data = districts && districts.filter(i=>id==i.id)[0]
    nam=data.name
    return nam
} 

useEffect(()=>{
getListUser()
getSeatUser()
},[])
useEffect(()=>{

    let data = users&& users.filter((i=>i.id==user.id))
    console.log(data,props)
},[])
    useEffect(() => {
            getLabots()
        if (props.history.location && props.history.location.state) {
            if(props.history.location.state.values.district){
                getDis(props.history.location.state.values.district)
            }
            if(props.history.location.state.action=='add_result')setShowRes(true)
            if(props.history.location.state.showLess)setShowLess(true)
            if(props.history.location.state.action_type=='sample_fit')setShowSample(true)
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            console.log(values,recordData)
        } else if (slug) {
            console.log(slug);
        }
          else if( user && user.user_types && user.user_types.includes("fso")) {
            setValues({...values,district:user.district});
        }
        if(props.history.location && props.history.location.state){
            let prop = props.history.location.state
            if(prop.appIIlab||prop.do||prop.fso||prop.ao||prop.appealApp||prop.clarify||prop.hearing_||prop.aoResult||prop.aoAppeal||prop.resultAPP|| prop.section||prop.appII||prop.dd||prop.ac||prop.commissioner||prop.sectionFinal||prop.doFinal||prop.fsoFinal||prop.appIIFinal)setRowView(true)
           
        }
        if(props.history.location && props.history.location.state&&props.history.location.state.values&&props.history.location.state.values.inspection_sample){
            setSampleAction('1')
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
       wardsCategory&&wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })

    },[values.area_code])
    const getDis=async(dat)=>{
        await ongetDist({district:dat})
    }

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
            'area_code',
            'area_name',
           'type_of_trader',
           'name_of_respondant_premisis',
           'address_of_respondant_premisis',
           'name_of_the_respondant',
           'address_of_respondant_residence',
           'FSSAI_license_number',
           'type_of_sample_lifted',
           'type_of_fbo',
            'select_the_lab',
           
       ];
       if(props?.history?.location?.state?.action=='add_result')keys.push('upload_sample_documents')
       if(values&&values.type_of_sample_lifted)keys.push('sample_number_given_by_do','date_sample_lifted_date_and_time')
       if(props?.history?.location?.state?.valdate)keys.push('date_sample_received_on','sample_code')
       if(props?.history?.location?.state?.s_fit)keys.push('sample_fit_for_analysis')
       if(props?.history?.location?.state?.rs_fit)keys.push('result_number', 'result_type','date_result_signed_date', 'date_report_intimated_to_DO_on')
       if(props?.history?.location?.state?.sampleAppeal)keys.push('appeal_allowed', 'date_report_received_on','date_report_intimated_to_fbo_on', 'date_report_intimated_to_fso_on', 'date_appeal_received_from_fbo_on',)
       if(props?.history?.location?.state?.sampleAppeal && values && values.appeal_allowed =='Appeal Allowed')keys.push('date_sent_another_part_of_sample','lab_name')
       if(props?.history?.location?.state?.sampleAppeal && values&&values.appeal_allowed =='Dismissed')keys.push('dismiss_reason')
       if(props?.history?.location?.state?.action_t=='case_submit')keys.push('case_converted')
       if(props?.history?.location?.state?.action_t=='case_submit' && values&&values.case_converted =='Case Converted')keys.push('case_category')
       if(props?.history?.location?.state?.appIIlab)keys.push('date_app2lab_file_received_date','date_app2lab_file_sent_date')
       if(props?.history?.location?.state?.do)keys.push('date_do_file_received_date', 'date_do_file_sent_date')
       if(props?.history?.location?.state?.fso)keys.push('date_fso_file_received_date','date_fso_file_sent_date')
       if(props?.history?.location?.state?.ao)keys.push('date_ao_file_received_date','date_ao_filed_at_court','ao_file_number')
       if(props?.history?.location?.state?.aoResult)keys.push('ao_case_file_result')
       if(props?.history?.location?.state?.aoResult && values&&values.ao_case_file_result =='Final Judgement (Civil)')keys.push('court_result')
       if(props?.history?.location?.state?.aoResult && values&&values.ao_case_file_result =='Final Judgement (Civil)'&&values&&values.court_result=='Convicted')keys.push('penalty_amount')
       if(props?.history?.location?.state?.aoResult && values&&values.ao_case_file_result =='Hearing (Civil)')keys.push('date_next_hearing_date')
       if(props?.history?.location?.state?.hearing_ && values&&values.ao_case_file_result =='Final Judgement (Civil)')keys.push('penalty_amount')
       if(props?.history?.location?.state?.hearing_ && values&&values.ao_case_file_result =='Hearing (Civil)')keys.push('date_next_hearing_date')
       if(props?.history?.location?.state?.aoAppeal)keys.push('appeal_ao_preferred_by','date_ao_appeal_approved_on','date_ao_filed_at_court','ao_file_number')
       if(props?.history?.location?.state?.section)keys.push('date_so_file_received_date','section_file_number','date_so_file_signed_date')
       if(props?.history?.location?.state?.appII)keys.push('date_app2_so_file_received_date','app2_need_clarification','date_app2_file_sent_date', 'assign_file_next')
       if(props?.history?.location?.state?.dd)keys.push('date_dd_file_received_date','date_dd_file_sent_date','dd_need_clarification')
       if(props?.history?.location?.state?.ac)keys.push('date_ac_file_received_date','date_ac_file_sent_date','ac_need_clarification')
       if(props?.history?.location?.state?.commissioner)keys.push('date_commissioner_file_received_date','date_commissioner_file_sent_date')
       if(props?.history?.location?.state?.sectionFinal)keys.push('date_so_final_file_received_date','date_so_final_file_sent_date')
       if(props?.history?.location?.state?.doFinal)keys.push('date_do_file_received_date','date_do_file_sent_date')
       if(props?.history?.location?.state?.fsoFinal)keys.push('date_fso_file_received_date','date_fso_file_sent_date')
       if(props?.history?.location?.state?.appIIFinal)keys.push('date_approved_file_received_from_prosecution','date_app2_final_file_received_date','date_app2_final_filed_at_court','app2_case_file_number')
       if(props?.history?.location?.state?.resultAPP)keys.push('app_case_file_result')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Hearing (Criminal)')keys.push('date_app_next_hearing_date')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)')keys.push('court_result')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)'&&values&&values.court_result == 'Convicted')keys.push('app_casefine_file_result')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)' && values&& values.app_casefine_file_result=='Fine')keys.push('fine_amount')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)' &&values&& values.app_casefine_file_result=='Fine with imprisonment')keys.push('fine_amount_prison')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Hearing (Criminal)')keys.push('date_app_next_hearing_date')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Final Judgement (Criminal)')keys.push('app_casefine_file_result')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Final Judgement (Criminal)' && values&& values.app_casefine_file_result=='Fine')keys.push('fine_amount')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Final Judgement (Criminal)' &&values&& values.app_casefine_file_result=='Fine with imprisonment')keys.push('fine_amount_prison')
       if(props?.history?.location?.state?.appealApp)keys.push('appeal_preferred_by','date_appeal_approved_on', 'date_app2_final_filed_at_court','app2_case_file_number',)

        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if(key=='FSSAI_license_number' && values[key].length !==14){
                updatedErrors[key] = "Fssai number should be atleast 14 characters"; 
            }
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }
const getSurv =async()=>{
    const resp = await ongetSurveilDetails({type:'Surveillance'})
    console.log(resp)
    if(resp.response){
        setPopup(true)
        setOldData(resp.response)
    }
}
const getSO =(name)=>{
    var id = asignSeat.filter(i=>i.name==name)[0]
    return (id && name) ? id.id : name;
}
const getApp =(name)=>{
    var id = asignUser.filter(i=>i.name==name)[0]
    return (id && name) ? id.id : name;
}
const getInsp =async()=>{
    const resp = await ongetInspDetails({type:'insepction'})
    console.log(resp)
    if(resp.response){
        setPopup(true)
        setOldData(resp.response)
    }
}
const handleValues=async(dat)=>{
    let dilt = oldData.filter(i=>i.name==dat)[0]
    if(sampleAction=='2'||sampleAction=='3'){
       dilt.type_of_sample_lifted='Act'
    }
    setValues(valueState=>{ Object.assign(valueState,dilt); return valueState;} );
    setDummyData(dat)
    setSampleType(dat)
   
}
    useEffect(()=>{
        if(sampleAction=='1'){
            setSampleType('Act')
            setValues({...values,type_of_sample_lifted:'Act'})
        }
        if(sampleAction=='3'){
            console.log('cal')
           getSurv()
        }
        if(sampleAction=='2'){
            console.log('cale')
           getInsp()

        }
    },[sampleAction])
    const hhandleSubmit=async()=>{
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
        const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
          
                const resp = await onEditFSOSampleReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                window.location.reload()
            };
        }
            else { 
                const data = { ...values}
                data.status_of_report='Waiting for submit'
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                        formData.append(key, new Date(data[key]).toISOString());
                    } else {
                        formData.append(key, data[key]);
                    }
                });
                const resp = await onCreateFSOSampleReports(formData);
                if (resp && resp.data == 'success') {
                    props.history.push("/app/dep-sample-reports");
                };
            }
    }
    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            if(props.history.location.state.action_type==='analyse'){
                data.status_of_report='Waiting for analysis'
            }
            
          else if(props.history.location.state.action_t==='case_submit'&&values&&values.case_category==='Civil'){
                data.status_of_report=`Waiting for APP(Lab) Approval`
            }
            else if(props.history.location.state.action_t==='case_submit'&&values&&values.case_category==='Criminal'){
                data.status_of_report='File submitted to section'
            }
            else if(props.history.location.state.clarify){
                data.status_of_report='File submitted to section'
            }
            else if(props.history.location.state.admore){
                data.status_of_report='Submitted to lab'
            }
            else if(props.history.location.state.aoResult&& values&&values.ao_case_file_result){
                data.status_of_report=values.ao_case_file_result    
            }
           else if(props.history.location.state.section){
                data.status_of_report=`Waiting for Prosecution Approval`
            } 
            else if(values&& user&&user.user_types&& user.user_types.includes('ac')&&values.ac_need_clarification=='Yes'){
                data.status_of_report=`Waiting for DO clarification`
            } 
           else if(props.history.location.state.appIIlab){
                data.status_of_report='Waiting for DO final approval'
            }
            else if(props.history.location.state.appII){
                data.status_of_report='Waiting for prosecution final Approval'
            }
            else if(props.history.location.state.dd){
                data.status_of_report='Waiting for AC Approval'
            }
            else if(props.history.location.state.ac){
                data.status_of_report='Waiting for Commissioner Approval'
            }
            else if(props.history.location.state.commissioner ){
                data.status_of_report='Waiting for section final approval'
            }
            else if(props.history.location.state.appealApp){
                data.status_of_report='Appeal Case filed at court'
            }
            else if(props.history.location.state.aoAppeal){
                data.status_of_report='Case filed at AO court'
            }
            else if(props.history.location.state.surveil_sample){
                data.status_of_report='Result sent to FBO'
            }
            else if(props.history.location.state.sectionFinal){
                data.status_of_report='Waiting for DO final Approval'
            }
            else if(props.history.location.state.doFinal){
                data.status_of_report='Waiting for fso to submit file to APP'
            }
            else if(props.history.location.state.do){
                data.status_of_report='Waiting for fso to submit file to AO'
            }
            else if(props.history.location.state.fso){
                data.status_of_report='File submitted to AO'
            }
            else if(props.history.location.state.fsoFinal){
                data.status_of_report=`Waiting for prosecution approval to file case`
            }
            else if(props.history.location.state.anotherPart){
                data.status_of_report='Waiting for analysis'
            }
            else if(props.history.location.state.appIIFinal){
                data.status_of_report='Case filed at court'
            }
            else if(props.history.location.state.appealRes){
                data.status_of_report='Appeal case hearing'
            }
            else if(props.history.location.state.apt){
                data.status_of_report='Appeal Case filed at court'
            }
            else if(props.history.location.state.hearing_ && values&&values.case_category==='Criminal'){
                data.status_of_report=values&&values.app_case_file_result
            }
            else if(props.history.location.state.hearing_ && values&&values.case_category==='Civil'){
                data.status_of_report=values&&values.ao_case_file_result    
            }
            // else if(props.history.location.state.finalApp){
            //     data.status_of_report=values&&values.app_case_file_result
            // }
            else if(props.history.location.state.resultAPP){
                data.status_of_report=values&&values.app_case_file_result
            }
            else if(props.history.location.state.ao){
                data.status_of_report='Case filed at AO court'
            }
            else if(values && values.case_converted==='Minor Faulty/Defect Case Closed'){
                data.status_of_report='Minor Faulty/Defect Case Closed'
            }
            else{
                if(showres){
                data.status_of_report='Result submitted to DO'
                }
                else{
                    if(values.sample_fit_for_analysis === 'Yes'){
                        data.status_of_report='waiting for lab result'
                    }
                    else{
                        data.status_of_report='waiting for another part sample'
                    }
                } 
            }
            if(props.history.location.state.appealApp || props.history.location.state.aoAppeal){
                data.re_appeal=true
            }
            if(props.history.location.state.hearing_){
                data.hearing_request=true
            }
            if(props.history.location.state.clarify){
                data.clarify_request=true
            }
            if(props.history.location.state.sampleAppeal){
                data.sample_appeal=true
                data.status_of_report='Submitted to lab'
            }
          
            if(data.assign_file_to_seat){
                data.assign_file_to_seat=getSO(data.assign_file_to_seat)
            }
            if(data.assign_file_to){
                data.assign_file_to=getApp(data.assign_file_to)
            }
            if(data.assign_file_next){
                data.assign_file_next=getApp(data.assign_file_next)
            }
            console.log(data)
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
                const resp = await onEditFSOSampleReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
               if(user&&user.user_types&&user.user_types.includes('so')
               ||user&&user.user_types&&user.user_types.includes('twoapp')
               ||user&&user.user_types&&user.user_types.includes('dd')
               ||user&&user.user_types&&user.user_types.includes('AO')
               ||user&&user.user_types&&user.user_types.includes('labapp')
               ||user&&user.user_types&&user.user_types.includes('ac')
               ||user&&user.user_types&&user.user_types.includes('commissioner')
               ||user&&user.user_types&&user.user_types.includes('apt')
               ||user&&user.user_types&&user.user_types.includes('oneapp')){
                props.history.push('/app/dep-case-reports')
               }

               else if((props.history.location.state&&props.history.location.state.doFinal) 
               ||(props.history.location.state&&props.history.location.state.fsoFinal)
               ||(props.history.location.state&&props.history.location.state.resultAPP)
               ||(props.history.location.state&&props.history.location.state.clarify)
               ||(props.history.location.state&&props.history.location.state.appIIFinal)
               ||(props.history.location.state&&props.history.location.state.do)
               ||(props.history.location.state&&props.history.location.state.fso)
               ||(props.history.location.state&&props.history.location.state.doFinal)
               ||(props.history.location.state&&props.history.location.state.appealApp)
               ||(user&&user.user_types&&user.user_types.includes('fso') && props.history.location.state&&props.history.location.state.hearing_)
               ){
                props.history.push('/app/dep-case-reports')
               } 
              else props.history.push('/app/dep-sample-reports')
            };
            // if(props.history.location.state.appealApp || props.history.location.state.aoAppeal ){
            //     const AppealData = values.case_category==='Civil'? {
            //         date_ao_appeal_received_from_do_on:values.date_ao_appeal_received_from_do_on,
            //         date_ao_appeal_approved_on:values.date_ao_appeal_approved_on,
            //         appeal_ao_preferred_by:values.appeal_ao_preferred_by,
            //         other_ao_appeal_preferred_by:values.other_ao_appeal_preferred_by,
            //         date_ao_filed_at_court:values.date_ao_filed_at_court,
            //         ao_file_number:values.ao_file_number,
            //         re_appeal:true,
            //     }:{
            //         date_appeal_received_from_do_on:values.date_appeal_received_from_do_on,
            //         appeal_preferred_by:values.appeal_preferred_by,
            //         date_appeal_approved_on:values.date_appeal_approved_on,
            //         other_appeal_preferred_by:values.other_ao_appeal_preferred_by,
            //         date_app2_final_filed_at_court:values.date_app2_final_filed_at_court,
            //         app2_case_file_number:values.app2_case_file_number,
            //         re_appeal:true,
            //     }
            //     const resp = await onCreateAppeal(AppealData,values.id)
            //     console.log(resp.response)
            // }

        } else { 
            const data = { ...values}
            data.status_of_report='Submitted to lab'
            if(sampleAction=='3'){
                data.surveil_to_act=true
            }
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            if(oldData&&values.id){
                const resp = await onEditFSOSampleReports(formData, values.id);
                if (resp && resp.data == 'success') {
                    setOldData([])
                    props.history.push("/app/dep-sample-reports");
                };
            }
            else{
                const resp = await onCreateFSOSampleReports(formData);
                if (resp && resp.data == 'success') {
                    props.history.push("/app/dep-sample-reports");
                };
            }
            
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800"> Sample Details Report</h1>
            <div className="card shadow mb-4">
            {/* {props?.history?.location?.state?.showLess? <div style={{marginLeft:'auto',marginRight:'10px'}}><button className="btn btn-success ml-3" onClick={()=>setShowLess(!showLess)}>Show detailed report</button></div>:null} */}
                <div className="card-body">
                {!props.history.location.state && !sampleType && <><div className="checkbox_row">
                         
                            <div><button
                             onClick={()=>setSampleAction('1')}
                                  className="btn btn-success ml-3"
                            >New Sample</button></div>
                               <div><button  
                                    onClick={()=>setSampleAction('2')}
                                   className="btn btn-success ml-3"
                                    >From Existing</button>
                                    </div>
                            <div><button
                             onClick={()=>setSampleAction('3')}
                                  className="btn btn-success ml-3"
                            >Surveillance to Act</button></div>
                        </div>
                        {sampleAction=='2'&& <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_preferred_by"
                            >Select Sample Number *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`select or search sample`}
                                    name="dummyData"
                                    items={oldData}
                                    action="dropdown"
                                    value={dummyData}
                                    onChange={(text) =>handleValues(text)}
                                />
                            </div>
                        </div>}
                        {sampleAction=='3'&& <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_preferred_by"
                            >Select Sample Number *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`select or search sample`}
                                    name="dummyData"
                                    items={oldData}
                                    action="dropdown"
                                    value={dummyData}
                                    onChange={(text) =>handleValues(text)}
                                />
                            </div>
                        </div>}
                        
                        </>}
                  {props.history.location.state || sampleType?  <form>
                       
                   {rowView? <>
                   <a style={{margin:"20px",fontSize:'14px', marginTop:'-15px', float:'right',border:'1px solid #808080',background:'#ddd',color:'#000',padding:'7px'}} href={values&&values.upload_sample_documents} target='_blank'>
                   View Result</a>
                    <div  className="filter_container row_view"> 
                    <div className="row_div">
                      <h5>District</h5>
                      <span>{getDistrictName(values&&values.district)}</span> 
                    </div>
                    <div className="row_div">
                      <h5>Sample Number</h5>
                      <span>{values&&values.sample_number_given_by_do}</span> 
                    </div>
                    <div className="row_div">
                       <h5>Sample code</h5> 
                       <span>{values&&values.sample_code}</span>
                    </div>
                    <div className="row_div">
                       <h5>Result Type</h5> 
                      <span>{values&&values.result_type=='Miscellaneous'? values.result_type+' ,'+values.other_result_type:values.result_type}</span>
                    </div>
                    </div>

                   {values&&values.case_category=='Civil'? <div style={{marginTop:'-30px'}} > 
                   <table style={{width:'100%'}} className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Official Designation</th>
                            {/* <th>Clarification requested</th> */}
                            <th>Remarks</th>
                            <th>Documents</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                           <td>{values&&values.date_app2lab_file_signed_date?'APP-II':'N/A'}</td> 
                           {/* <td>{values&&values.app2lab_need_clarification?values.app2lab_need_clarification:'N/A'}</td> */}
                           <td>{values&&values.app2lab_remarks?values.app2lab_remarks:'N/A'}</td>
                           <td>{values&&values.app2_docs ? <a target='_blank' href={values&&values.app2_docs}> View</a>: '-'}</td>
                        </tr>
                        <tr>
                           <td>{values&&values.date_do_file_received_date?'DO':'N/A'}</td> 
                           {/* <td>{values&&values.do_need_clarification?values.do_need_clarification:'N/A'}</td> */}
                           <td>{values&&values.do_remarks?values.do_remarks:'N/A'}</td>
                           <td>{values&&values.do_docs ? <a target='_blank' href={values&&values.do_docs}> View</a>: '-'}</td>
                        </tr>
                        <tr>
                           <td>{values&&values.date_fso_file_received_date?'FSO':'N/A'}</td> 
                           {/* <td>{values&&values.fso_need_clarification?values.fso_need_clarification:'N/A'}</td> */}
                           <td>{values&&values.fso_remarks?values.fso_remarks:'N/A'}</td>
                           <td>{values&&values.fso_docs ? <a target='_blank' href={values&&values.fso_docs}> View</a>: '-'}</td>
                        </tr>
                    </tbody>
                   </table>
                    </div>:<>

                    <div style={{marginTop:'-30px'}} > 
                    <table style={{width:'100%'}} className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th><b>Official Designation</b></th>
                            <th><b>Clarification requested</b></th>
                            <th><b>Remarks</b></th>
                            <th><b>Documents</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{values&&values.date_so_file_received_date?'Section Officer':'N/A'}</td>
                            <td>{values&&values.so_need_clarification?values.so_need_clarification:'N/A'}</td>
                            <td>{values&&values.so_remarks?values.so_remarks:'N/A'}</td>
                            <td>{values&&values.so_docs ? <a target='_blank' href={values&&values.so_docs}> View</a>: '-'}</td>
                            </tr>
                        <tr>
                            <td>{values&&values.date_app2_so_file_received_date?'Prosecution Officer':'N/A'}</td>
                            <td>{values&&values.app2_need_clarification?values.app2_need_clarification:'N/A'}</td>
                            <td>{values&&values.app2_remarks?values.app2_remarks:'N/A'}</td>
                            <td>{values&&values.app2_docs ? <a target='_blank' href={values&&values.app2_docs}> View</a>: '-'}</td>
                        </tr>
                        <tr>
                            <td>{values&&values.date_dd_file_received_date?'Prosecution Officer(Final)':'N/A'}</td>
                            <td>{values&&values.dd_need_clarification?values.dd_need_clarification:'N/A'}</td>
                            <td>{values&&values.dd_remarks?values.dd_remarks:'N/A'}</td>
                            <td>{values&&values.dd_docs ? <a target='_blank' href={values&&values.dd_docs}> View</a>: '-'}</td>
                        </tr>
                        <tr>
                            <td>{values&&values.date_ac_file_received_date?'AC':'N/A'}</td>
                            <td>{values&&values.ac_need_clarification?values.ac_need_clarification:'N/A'}</td>
                            <td>{values&&values.ac_remarks?values.ac_remarks:'N/A'}</td>
                            <td>{values&&values.ac_docs ? <a target='_blank' href={values&&values.ac_docs}> View</a>: '-'}</td>
                         </tr>
                         <tr>
                            <td>{values&&values.date_commissioner_file_received_date?'Commissioner':'N/A'}</td>
                            <td>{values&&values.commissioner_need_clarification?values.commissioner_need_clarification:'N/A'}</td>
                            <td>{values&&values.commissioner_remarks?values.commissioner_remarks:'N/A'}</td>
                            <td>{values&&values.commissioner_docs ? <a target='_blank' href={values&&values.commissioner_docs}> View</a>: '-'}</td>
                         </tr>
                         <tr>
                            <td>{values&&values.date_do_file_received_date?'DO':'N/A'}</td>
                            <td>{values&&values.do_need_clarification?values.do_need_clarification:'N/A'}</td>
                            <td>{values&&values.do_remarks?values.do_remarks:'N/A'}</td>
                            <td>{values&&values.do_docs ? <a target='_blank' href={values&&values.do_docs}> View</a>: '-'}</td>
                         </tr>
                         <tr>
                            <td>{values&&values.date_fso_file_received_date?'FSO':'N/A'}</td>
                            <td>{values&&values.fso_need_clarification?values.fso_need_clarification:'N/A'}</td>
                            <td>{values&&values.fso_remarks?values.fso_remarks:'N/A'}</td>
                            <td>{values&&values.fso_docs ? <a target='_blank' href={values&&values.fso_docs}> View</a>: '-'}</td>
                         </tr>
                    </tbody>
                   </table>
                    </div>
                  </>}
                    {(values&&values.date_clarification_sent_on || props.history.location.state.clarify)? <div>
                        <h5 style={{margin:'14px', marginBottom:'25px'}}>Clarification File Details</h5>
                    </div>:null}

                    {props.history.location.state&&props?.history?.location?.state?.section&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_so_file_received_date"
                                    value={values.date_so_file_received_date}
                                    error={errors.date_so_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_so_file_received_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="so_remarks"
                            >File Number  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`File Number`}
                                    name="section_file_number"
                                    value={values.section_file_number}
                                    error={errors.section_file_number}
                                    onChange={(e) =>handleTextChange('section_file_number', e.target.value)}
                                />
                            </div>
                        </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_file_signed_date"
                            >  File Submitted date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Submitted date`}
                                    type="text"
                                    name="date_so_file_signed_date"
                                    value={values.date_so_file_signed_date}
                                    error={errors.date_so_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_so_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="assign_file_to"
                            >Assign file to  *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Assign file to`}
                                    name="assign_file_to"
                                    items={asignUser}
                                    action="dropdown"
                                    value={values.assign_file_to}
                                    error={errors.assign_file_to}
                                    onChange={(text) =>handleTextChange('assign_file_to', text)}
                                />
                            </div>
                        </div>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="so_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.so_remarks}
                                    error={errors.so_remarks}
                                    onChange={(e) =>handleTextChange('so_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="so_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="so_docs"
                                    error={errors.so_docs}
                                    onChange={(e) =>handleTextChange("so_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.ao&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ao_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_ao_file_received_date"
                                    value={values.date_ao_file_received_date}
                                    error={errors.date_ao_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_ao_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ao_filed_at_court"
                            >  Case Filed at AO court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at AO court on`}
                                    type="text"
                                    name="date_ao_filed_at_court"
                                    value={values.date_ao_filed_at_court}
                                    error={errors.date_ao_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_ao_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_file_number"
                            >  Case file number (CC/STC) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="ao_file_number"
                                    value={values.ao_file_number}
                                    error={errors.ao_file_number}
                                    onChange={(e) => handleTextChange("ao_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ao_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.ao_remarks}
                                    error={errors.ao_remarks}
                                    onChange={(e) =>handleTextChange('ao_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_docs"
                                    error={errors.ao_docs}
                                    onChange={(e) =>handleTextChange("ao_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                       
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.aoAppeal?<>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_ao_appeal_received_from_do_on"
                            >Appeal against AO order initiation received by do date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Received From DO On`}
                                    name="date_ao_appeal_received_from_do_on"
                                    value={values.date_ao_appeal_received_from_do_on}
                                    error={errors.date_ao_appeal_received_from_do_on}
                                    onChangeText={(text) =>handleTextChange("date_ao_appeal_received_from_do_on", text)}
                                />
                            </div>
                        </div>

                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_ao_preferred_by"
                            >Appeal Preferred By *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Preferred By`}
                                    name="appeal_ao_preferred_by"
                                    items={FiledBy}
                                    action="dropdown"
                                    value={values.appeal_ao_preferred_by}
                                    error={errors.appeal_ao_preferred_by}
                                    onChange={(text) =>handleTextChange('appeal_ao_preferred_by', text)}
                                />
                            </div>
                        </div>
                        {values &&values.appeal_ao_preferred_by&& values.appeal_ao_preferred_by.includes('Others') ? 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_ao_appeal_preferred_by"
                         ></label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Appeal Preferred By`}
                                 name="other_ao_appeal_preferred_by"
                                 error={errors.other_ao_appeal_preferred_by}
                                 value={values.other_ao_appeal_preferred_by}
                                 onChange={(e) =>handleTextChange('other_ao_appeal_preferred_by', e.target.value)}
                             />
                         </div>
                     </div>
                        :null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_ao_appeal_approved_on"
                            >Appeal Approved On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Approved On`}
                                    name="date_ao_appeal_approved_on"
                                    value={values.date_ao_appeal_approved_on}
                                    error={errors.date_ao_appeal_approved_on}
                                    onChangeText={(text) =>handleTextChange('date_ao_appeal_approved_on', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ao_filed_at_court"
                            >  Case Filed at court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at  court on`}
                                    type="text"
                                    name="date_ao_filed_at_court"
                                    value={values.date_ao_filed_at_court}
                                    error={errors.date_ao_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_ao_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_file_number"
                            >  Case file number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="ao_file_number"
                                    value={values.ao_file_number}
                                    error={errors.ao_file_number}
                                    onChange={(e) => handleTextChange("ao_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="reappeal_number"
                            > Appeal number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Appeal number`}
                                    type="text"
                                    name="reappeal_number"
                                    value={values.reappeal_number}
                                    error={errors.reappeal_number}
                                    onChange={(e) => handleTextChange("reappeal_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ao_remarks"
                            >Remarks : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="ao_remarks"
                                    value={values.ao_remarks}
                                    error={errors.ao_remarks}
                                    onChange={(e) =>handleTextChange('ao_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_docs"
                            > Upload documents(if any) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_docs"
                                    error={errors.ao_docs}
                                    onChange={(e) =>handleTextChange("ao_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>:null}
                        
                      {props.history.location.state&&props?.history?.location?.state?.appealApp?<>
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_appeal_received_from_do_on"
                            >Appeal Received From DO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Received From DO On`}
                                    name="date_appeal_received_from_do_on"
                                    value={values.date_appeal_received_from_do_on}
                                    error={errors.date_appeal_received_from_do_on}
                                    onChangeText={(text) =>handleTextChange("date_appeal_received_from_do_on", text)}
                                />
                            </div>
                        </div> */}
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_preferred_by"
                            >Appeal Preferred By *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Preferred By`}
                                    name="appeal_preferred_by"
                                    items={FiledBy}
                                    action="dropdown"
                                    value={values.appeal_preferred_by}
                                    error={errors.appeal_preferred_by}
                                    onChange={(text) =>handleTextChange('appeal_preferred_by', text)}
                                />
                            </div>
                        </div>
                        {/* {values &&values.appeal_preferred_by&& values.appeal_preferred_by.includes('Others') ? 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_appeal_preferred_by"
                         ></label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Appeal Preferred By`}
                                 name="other_appeal_preferred_by"
                                 error={errors.other_appeal_preferred_by}
                                 value={values.other_appeal_preferred_by}
                                 onChange={(e) =>handleTextChange('other_appeal_preferred_by', e.target.value)}
                             />
                         </div>
                     </div>
                        :null} */}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_appeal_approved_on"
                            >Appeal Approved On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Approved On`}
                                    name="date_appeal_approved_on"
                                    value={values.date_appeal_approved_on}
                                    error={errors.date_appeal_approved_on}
                                    onChangeText={(text) =>handleTextChange('date_appeal_approved_on', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_final_filed_at_court"
                            >  Case Filed at court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at  court on`}
                                    type="text"
                                    name="date_app2_final_filed_at_court"
                                    value={values.date_app2_final_filed_at_court}
                                    error={errors.date_app2_final_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_app2_final_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_case_file_number"
                            >  Case file number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="app2_case_file_number"
                                    value={values.app2_case_file_number}
                                    error={errors.app2_case_file_number}
                                    onChange={(e) => handleTextChange("app2_case_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="reappeal_number"
                            > Appeal number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Appeal number`}
                                    type="text"
                                    name="reappeal_number"
                                    value={values.reappeal_number}
                                    error={errors.reappeal_number}
                                    onChange={(e) => handleTextChange("reappeal_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2_final_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2_final_remarks"
                                    value={values.app2_final_remarks}
                                    error={errors.app2_final_remarks}
                                    onChange={(e) =>handleTextChange('app2_final_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_final_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2_final_docs"
                                    error={errors.app2_final_docs}
                                    onChange={(e) =>handleTextChange("app2_final_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                       
                      </>:null}
                      {props.history.location.state&&props?.history?.location?.state?.clarify&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_do_clarify_file_received_date"
                            >  Clarification request received on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Clarification request received on`}
                                    type="text"
                                    name="date_do_clarify_file_received_date"
                                    value={values.date_do_clarify_file_received_date}
                                    error={errors.date_do_clarify_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_do_clarify_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_clarification_sent_on"
                            >  Clarification file sent to *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Clarification file sent to`}
                                    type="text"
                                    name="date_clarification_sent_on"
                                    value={values.date_clarification_sent_on}
                                    error={errors.date_clarification_sent_on}
                                    onChangeText={(text) => handleTextChange("date_clarification_sent_on", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="do_clarify_remarks"
                            >Remarks  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="do_clarify_remarks"
                                    value={values.do_clarify_remarks}
                                    error={errors.do_clarify_remarks}
                                    onChange={(e) =>handleTextChange('do_clarify_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="do_clarify_docs"
                            > Upload documents(if any) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="do_clarify_docs"
                                    error={errors.do_clarify_docs}
                                    onChange={(e) =>handleTextChange("do_clarify_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                       
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.appIIFinal&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_approved_file_received_from_prosecution"
                            > Prosecution approved on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Proseuction approved on`}
                                    type="text"
                                    name="date_approved_file_received_from_prosecution"
                                    value={values.date_approved_file_received_from_prosecution}
                                    error={errors.date_approved_file_received_from_prosecution}
                                    onChangeText={(text) => handleTextChange("date_approved_file_received_from_prosecution",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_final_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app2_final_file_received_date"
                                    value={values.date_app2_final_file_received_date}
                                    error={errors.date_app2_final_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app2_final_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_final_filed_at_court"
                            >  Case Filed at court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at  court on`}
                                    type="text"
                                    name="date_app2_final_filed_at_court"
                                    value={values.date_app2_final_filed_at_court}
                                    error={errors.date_app2_final_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_app2_final_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_case_file_number"
                            >  Case file number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="app2_case_file_number"
                                    value={values.app2_case_file_number}
                                    error={errors.app2_case_file_number}
                                    onChange={(e) => handleTextChange("app2_case_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2_final_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2_final_remarks"
                                    value={values.app2_final_remarks}
                                    error={errors.app2_final_remarks}
                                    onChange={(e) =>handleTextChange('app2_final_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_final_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2_final_docs"
                                    error={errors.app2_final_docs}
                                    onChange={(e) =>handleTextChange("app2_final_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                       
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.hearing_ &&values.case_category&&values.case_category==='Criminal'&&<>
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_case_file_result"
                                 items={Results}
                                 action="dropdown"
                                 value={values.app_case_file_result}
                                 error={errors.app_case_file_result}
                                 onChange={(text) =>handleTextChange('app_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.app_case_file_result=='Hearing (Criminal)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_app_next_hearing_date"
                                    value={values.date_app_next_hearing_date}
                                    error={errors.date_app_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_app_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app_hearing_remarks"
                            > Hearing  Remarks  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app_hearing_remarks"
                                    value={values.app_hearing_remarks}
                                    error={errors.app_hearing_remarks}
                                    onChange={(e) =>handleTextChange('app_hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_case_file_result=='Final Judgement (Criminal)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&&<div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_casefine_file_result"
                         >Judgement   *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_casefine_file_result"
                                 items={Fines}
                                 action="dropdown"
                                 value={values.app_casefine_file_result}
                                 error={errors.app_casefine_file_result}
                                 onChange={(text) =>handleTextChange('app_casefine_file_result', text)}
                             />
                         </div>
                     </div>}
                     {values&&values.app_casefine_file_result==='Fine'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount"
                            >Fine Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine amount`}
                                    name="fine_amount"
                                    value={values.fine_amount}
                                    error={errors.fine_amount}
                                    onChange={(e) =>handleTextChange('fine_amount', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_casefine_file_result==='Fine with imprisonment'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount_prison"
                            >Fine with imprisonment  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine with imprisonment`}
                                    name="fine_amount_prison"
                                    value={values.fine_amount_prison}
                                    error={errors.fine_amount_prison}
                                    onChange={(e) =>handleTextChange('fine_amount_prison', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     </>:null}
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="court_result_doc"
                            > Judgement Result: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="court_result_doc"
                                    error={errors.court_result_doc}
                                    onChange={(e) =>handleTextChange("court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                         </>}
                         {props.history.location.state&&props?.history?.location?.state?.hearing_ &&values.case_category&&values.case_category==='Civil'&&<>
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="ao_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="ao_case_file_result"
                                 items={Resul}
                                 action="dropdown"
                                 value={values.ao_case_file_result}
                                 error={errors.ao_case_file_result}
                                 onChange={(text) =>handleTextChange('ao_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.ao_case_file_result=='Hearing (Civil)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_next_hearing_date"
                                    value={values.date_next_hearing_date}
                                    error={errors.date_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="hearing_remarks"
                            > Hearing  Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="hearing_remarks"
                                    value={values.hearing_remarks}
                                    error={errors.hearing_remarks}
                                    onChange={(e) =>handleTextChange('hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.ao_case_file_result=='Final Judgement (Civil)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&&
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="penalty_amount"
                            >Penalty Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Penalty amount`}
                                    name="penalty_amount"
                                    value={values.penalty_amount}
                                    error={errors.penalty_amount}
                                    onChange={(e) =>handleTextChange('penalty_amount', e.target.value)}
                                />
                            </div>
                        </div>}
                     </>:null}
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_court_result_doc"
                            > Judgement upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_court_result_doc"
                                    error={errors.ao_court_result_doc}
                                    onChange={(e) =>handleTextChange("ao_court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                        </>}
                      {props.history.location.state&&props?.history?.location?.state?.resultAPP&&<>
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_case_file_result"
                                 items={Results}
                                 action="dropdown"
                                 value={values.app_case_file_result}
                                 error={errors.app_case_file_result}
                                 onChange={(text) =>handleTextChange('app_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.app_case_file_result=='Hearing (Criminal)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_app_next_hearing_date"
                                    value={values.date_app_next_hearing_date}
                                    error={errors.date_app_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_app_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app_hearing_remarks"
                            > Hearing  Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app_hearing_remarks"
                                    value={values.app_hearing_remarks}
                                    error={errors.app_hearing_remarks}
                                    onChange={(e) =>handleTextChange('app_hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_case_file_result=='Final Judgement (Criminal)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&&<div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_casefine_file_result"
                         >Judgement   *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_casefine_file_result"
                                 items={Fines}
                                 action="dropdown"
                                 value={values.app_casefine_file_result}
                                 error={errors.app_casefine_file_result}
                                 onChange={(text) =>handleTextChange('app_casefine_file_result', text)}
                             />
                         </div>
                     </div>}
                     {values&&values.app_casefine_file_result==='Fine'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount"
                            >Fine Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine amount`}
                                    name="fine_amount"
                                    value={values.fine_amount}
                                    error={errors.fine_amount}
                                    onChange={(e) =>handleTextChange('fine_amount', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_casefine_file_result==='Fine with imprisonment'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount_prison"
                            >Fine with imprisonment  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine with imprisonment`}
                                    name="fine_amount_prison"
                                    value={values.fine_amount_prison}
                                    error={errors.fine_amount_prison}
                                    onChange={(e) =>handleTextChange('fine_amount_prison', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     </>:null}
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="court_result_doc"
                            > Judgement upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="court_result_doc"
                                    error={errors.court_result_doc}
                                    onChange={(e) =>handleTextChange("court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {props.history.location.state&&props?.history?.location?.state?.aoResult&&<>
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="ao_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="ao_case_file_result"
                                 items={Resul}
                                 action="dropdown"
                                 value={values.ao_case_file_result}
                                 error={errors.ao_case_file_result}
                                 onChange={(text) =>handleTextChange('ao_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.ao_case_file_result=='Hearing (Civil)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_next_hearing_date"
                                    value={values.date_next_hearing_date}
                                    error={errors.date_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="hearing_remarks"
                            > Hearing  Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="hearing_remarks"
                                    value={values.hearing_remarks}
                                    error={errors.hearing_remarks}
                                    onChange={(e) =>handleTextChange('hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.ao_case_file_result=='Final Judgement (Civil)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&& <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="penalty_amount"
                            >Penalty Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Penalty amount`}
                                    name="penalty_amount"
                                    value={values.penalty_amount}
                                    error={errors.penalty_amount}
                                    onChange={(e) =>handleTextChange('penalty_amount', e.target.value)}
                                />
                            </div>
                        </div>}
                     </>:null}
                  
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_court_result_doc"
                            > Judgement upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_court_result_doc"
                                    error={errors.ao_court_result_doc}
                                    onChange={(e) =>handleTextChange("ao_court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {(props.history.location.state&&props?.history?.location?.state?.do||props?.history?.location?.state?.doFinal )&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_do_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_do_file_received_date"
                                    value={values.date_do_file_received_date}
                                    error={errors.date_do_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_do_file_received_date",text)}
                                />
                            </div>
                        </div>                   
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_do_file_signed_date"
                            >  File Signed date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_do_file_signed_date"
                                    value={values.date_do_file_signed_date}
                                    error={errors.date_do_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_do_file_signed_date",text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_do_file_sent_date"
                            >  File sent to fso  *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent to fso`}
                                    type="text"
                                    name="date_do_file_sent_date"
                                    value={values.date_do_file_sent_date}
                                    error={errors.date_do_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_do_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="do_remarks"
                            >Remarks : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.do_remarks}
                                    error={errors.do_remarks}
                                    onChange={(e) =>handleTextChange('do_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="do_docs"
                            > Upload documents(if any) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="do_docs"
                                    error={errors.do_docs}
                                    onChange={(e) =>handleTextChange("do_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {(props.history.location.state&&props?.history?.location?.state?.fso||props?.history?.location?.state?.fsoFinal)&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_fso_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_fso_file_received_date"
                                    value={values.date_fso_file_received_date}
                                    error={errors.date_fso_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_fso_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                     {/* <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_fso_file_signed_date"
                            >  File Signed date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_fso_file_signed_date"
                                    value={values.date_fso_file_signed_date}
                                    error={errors.date_fso_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_fso_file_signed_date", text)}
                                />
                            </div>
                        </div>  */}
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_fso_file_sent_date"
                            >  File submitted/Sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File submitted/Sent date`}
                                    type="text"
                                    name="date_fso_file_sent_date"
                                    value={values.date_fso_file_sent_date}
                                    error={errors.date_fso_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_fso_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fso_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.fso_remarks}
                                    error={errors.fso_remarks}
                                    onChange={(e) =>handleTextChange('fso_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="fso_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="fso_docs"
                                    error={errors.fso_docs}
                                    onChange={(e) =>handleTextChange("fso_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.sectionFinal&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_final_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_so_final_file_received_date"
                                    value={values.date_so_final_file_received_date}
                                    error={errors.date_so_final_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_so_final_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_final_file_sent_date"
                            >  File submitted/sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File signed/sent date`}
                                    type="text"
                                    name="date_so_final_file_sent_date"
                                    value={values.date_so_final_file_sent_date}
                                    error={errors.date_so_final_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_so_final_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="so_remarks"
                            >Remarks  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.so_remarks}
                                    error={errors.so_remarks}
                                    onChange={(e) =>handleTextChange('so_remarks', e.target.value)}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="fso_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="fso_docs"
                                    error={errors.fso_docs}
                                    onChange={(e) =>handleTextChange("fso_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.appIIlab&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2lab_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app2lab_file_received_date"
                                    value={values.date_app2lab_file_received_date}
                                    error={errors.date_app2lab_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app2lab_file_received_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2lab_file_signed_date"
                            >  File Signed date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_app2lab_file_signed_date"
                                    value={values.date_app2lab_file_signed_date}
                                    error={errors.date_app2lab_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_app2lab_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2lab_file_sent_date"
                            >  File sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_app2lab_file_sent_date"
                                    value={values.date_app2lab_file_sent_date}
                                    error={errors.date_app2lab_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_app2lab_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2lab_remarks"
                            >Remarks : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2lab_remarks"
                                    value={values.app2lab_remarks}
                                    error={errors.app2lab_remarks}
                                    onChange={(e) =>handleTextChange('app2lab_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2lab_docs"
                            > Upload documents(if any) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2lab_docs"
                                    error={errors.app2lab_docs}
                                    onChange={(e) =>handleTextChange("app2lab_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.appII&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_so_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app2_so_file_received_date"
                                    value={values.date_app2_so_file_received_date}
                                    error={errors.date_app2_so_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app2_so_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app2_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="app2_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.app2_need_clarification}
                                 error={errors.app2_need_clarification}
                                 onChange={(text) =>handleTextChange('app2_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_file_signed_date"
                            >  File Signed date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_app2_file_signed_date"
                                    value={values.date_app2_file_signed_date}
                                    error={errors.date_app2_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_app2_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="assign_file_next"
                            >Assign file to  *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Assign file to`}
                                    name="assign_file_next"
                                    items={asignUser}
                                    action="dropdown"
                                    value={values.assign_file_next}
                                    error={errors.assign_file_next}
                                    onChange={(text) =>handleTextChange('assign_file_next', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_file_sent_date"
                            >  File sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_app2_file_sent_date"
                                    value={values.date_app2_file_sent_date}
                                    error={errors.date_app2_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_app2_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2_remarks"
                                    value={values.app2_remarks}
                                    error={errors.app2_remarks}
                                    onChange={(e) =>handleTextChange('app2_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2_docs"
                                    error={errors.app2_docs}
                                    onChange={(e) =>handleTextChange("app2_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.appI&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app1_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app1_file_received_date"
                                    value={values.date_app1_file_received_date}
                                    error={errors.date_app1_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app1_file_received_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app1_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="app1_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.app1_need_clarification}
                                 error={errors.app1_need_clarification}
                                 onChange={(text) =>handleTextChange('app1_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app1_file_signed_date"
                            >  File Signed date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_app1_file_signed_date"
                                    value={values.date_app1_file_signed_date}
                                    error={errors.date_app1_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_app1_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app1_file_sent_date"
                            >  File Sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_app1_file_sent_date"
                                    value={values.date_app1_file_sent_date}
                                    error={errors.date_app1_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_app1_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app1_remarks"
                            >Remarks  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app1_remarks"
                                    value={values.app1_remarks}
                                    error={errors.app1_remarks}
                                    onChange={(e) =>handleTextChange('app1_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app1_docs"
                            > Upload documents(if any) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app1_docs"
                                    error={errors.app1_docs}
                                    onChange={(e) =>handleTextChange("app1_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.ac&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ac_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_ac_file_received_date"
                                    value={values.date_ac_file_received_date}
                                    error={errors.date_ac_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_ac_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="ac_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="ac_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.ac_need_clarification}
                                 error={errors.ac_need_clarification}
                                 onChange={(text) =>handleTextChange('ac_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ac_file_signed_date"
                            >  File Signed date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_ac_file_signed_date"
                                    value={values.date_ac_file_signed_date}
                                    error={errors.date_ac_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_ac_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ac_file_sent_date"
                            >  File Sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Sent date`}
                                    type="text"
                                    name="date_ac_file_sent_date"
                                    value={values.date_ac_file_sent_date}
                                    error={errors.date_ac_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_ac_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ac_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="ac_remarks"
                                    value={values.ac_remarks}
                                    error={errors.ac_remarks}
                                    onChange={(e) =>handleTextChange('ac_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ac_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ac_docs"
                                    error={errors.ac_docs}
                                    onChange={(e) =>handleTextChange("ac_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {props.history.location.state&&props.history.location.state.dd&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_dd_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_dd_file_received_date"
                                    value={values.date_dd_file_received_date}
                                    error={errors.date_dd_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_dd_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="dd_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="dd_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.dd_need_clarification}
                                 error={errors.dd_need_clarification}
                                 onChange={(text) =>handleTextChange('dd_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_dd_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_dd_file_signed_date"
                                    value={values.date_dd_file_signed_date}
                                    error={errors.date_dd_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_dd_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_dd_file_sent_date"
                            >  File Sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_dd_file_sent_date"
                                    value={values.date_dd_file_sent_date}
                                    error={errors.date_dd_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_dd_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="dd_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="dd_remarks"
                                    value={values.dd_remarks}
                                    error={errors.dd_remarks}
                                    onChange={(e) =>handleTextChange('dd_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="dd_docs"
                            > Upload documents(if any) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="dd_docs"
                                    error={errors.dd_docs}
                                    onChange={(e) =>handleTextChange("dd_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {props.history.location.state&&props.history.location.state.commissioner&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_commissioner_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_commissioner_file_received_date"
                                    value={values.date_commissioner_file_received_date}
                                    error={errors.date_commissioner_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_commissioner_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="commissioner_need_clarification"
                         >Need Clarification : </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="commissioner_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.commissioner_need_clarification}
                                 error={errors.commissioner_need_clarification}
                                 onChange={(text) =>handleTextChange('commissioner_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_commissioner_file_signed_date"
                            >  File Signed date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_commissioner_file_signed_date"
                                    value={values.date_commissioner_file_signed_date}
                                    error={errors.date_commissioner_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_commissioner_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_commissioner_file_sent_date"
                            >  File sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_commissioner_file_sent_date"
                                    value={values.date_commissioner_file_sent_date}
                                    error={errors.date_commissioner_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_commissioner_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="commissioner_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="commissioner_remarks"
                                    value={values.commissioner_remarks}
                                    error={errors.commissioner_remarks}
                                    onChange={(e) =>handleTextChange('commissioner_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="commissioner_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="commissioner_docs"
                                    error={errors.commissioner_docs}
                                    onChange={(e) =>handleTextChange("commissioner_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      <div style={{marginTop:'40px'}} className="form-group">
                            <button
                                onClick={() => handlBack()}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div> 
                    </>:<>
                    <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_sample_lifted"
                            >Type of Sample  *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of sample lifted`}
                                    type="text"
                                    items={TypeOfSample}
                                    action="dropdown"
                                    name="type_of_sample_lifted"
                                    value={values.type_of_sample_lifted}
                                    error={errors.type_of_sample_lifted}
                                    onChange={(text) =>handleTextChange("type_of_sample_lifted", text)}
                                />
                            </div>
                        </div> 
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        // isDisabled={props.history.location.state.values.district}
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                     {wardsCategory&&<div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                                {props?.history?.location?.state?.values?.area_code?
                                <TextInput
                                placeholder={`Area code`}
                                name="area_name"
                                id="area_name"
                                action="area_code"
                                // disabled
                                value={values.area_code}
                                error={errors.area_code}
                                onChange={(e) => handleTextChange('area_code', e.target.value)}
                            />:
                            <DropDownInput
                            type="text"
                            name="area_code"
                            action="area_code"
                            value={values.area_code}
                            error={errors.area_code}
                            items={wardsCategory}
                            onChange={(value) => handleTextChange("area_code", value)}
                             />
                            }

                            </div>
                        </div>}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    placeholder={`Area name`}
                                    name="area_name"
                                    id="area_name"
                                    // disabled={props?.history?.location?.state?.values?.area_name}
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange('area_name', e.target.value)}
                                />
                            </div>
                        </div>
                      { props.history&&props.history.location.state && !showLess&& <>    <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_sample_received_on"
                            > First Part Sample Received On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Received On`}
                                    name="date_sample_received_on"
                                    // disabled={props?.history?.location?.state?.values?.date_sample_received_on}
                                    value={values.date_sample_received_on}
                                    error={errors.date_sample_received_on}
                                    onChangeText={(text) => handleTextChange("date_sample_received_on", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="sample_code"
                            > Sample code / Lab internal code *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sample code / Lab internal code`}
                                    name="sample_code"
                                    // disabled={props?.history?.location?.state?.values?.sample_code}
                                    value={values.sample_code}
                                    error={errors.sample_code}
                                    onChange={(e) => handleTextChange("sample_code", e.target.value)}
                                />
                            </div>
                        </div> </>}
                        {props?.history?.location?.state&&props?.history?.location?.state?.sampleAppeal?<>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_report_received_on"
                            >Report Received On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report Received On`}
                                    name="date_report_received_on"
                                    value={values.date_report_received_on}
                                    error={errors.date_report_received_on}
                                    onChangeText={(text) =>handleTextChange("date_report_received_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_report_intimated_to_fso_on"
                            >Report Intimated to FSO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report Intimated to FSO On`}
                                    name="date_report_intimated_to_fso_on"
                                    value={values.date_report_intimated_to_fso_on}
                                    error={errors.date_report_intimated_to_fso_on}
                                    onChangeText={(text) =>handleTextChange("date_report_intimated_to_fso_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_report_intimated_to_fbo_on"
                            >Report Intimated To FBO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report Intimated To FBO On`}
                                    name="date_report_intimated_to_fbo_on"
                                    value={values.date_report_intimated_to_fbo_on}
                                    error={errors.date_report_intimated_to_fbo_on}
                                    onChangeText={(text) =>handleTextChange("date_report_intimated_to_fbo_on", text)}
                                />
                            </div>
                        </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_appeal_received_from_fbo_on"
                            >Appeal Received From FBO On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Received from Fbo on`}
                                    name="date_appeal_received_from_fbo_on"
                                    value={values.date_appeal_received_from_fbo_on}
                                    error={errors.date_appeal_received_from_fbo_on}
                                    onChangeText={(text) =>handleTextChange("date_appeal_received_from_fbo_on", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_allowed"
                            >Appeal Allowed: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Allowed`}
                                    name="appeal_allowed"
                                    items={Allowed}
                                    action="dropdown"
                                    value={values.appeal_allowed}
                                    error={errors.appeal_allowed}
                                    onChange={(text) =>handleTextChange("appeal_allowed", text)}
                                />
                            </div>
                        </div>
                        {values &&values.appeal_allowed&& values.appeal_allowed.includes('Appeal Allowed') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_sent_another_part_of_sample"
                            >Sent Another Part to Lab On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sent Another Part Sample to lab on`}
                                    name="date_sent_another_part_of_sample"
                                    value={values.date_sent_another_part_of_sample}
                                    error={errors.date_sent_another_part_of_sample}
                                    onChangeText={(text) =>handleTextChange("date_sent_another_part_of_sample", text)}
                                />
                            </div>
                        </div><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="lab_name"
                            >Sample Sent for Analysis *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Name of the laboratory`}
                                    type="text"
                                    action="dropdown"
                                    items={Lab}
                                    name="lab_name"
                                    id="lab_name"
                                    value={values.lab_name}
                                    error={errors.lab_name}
                                    onChange={(text) => handleTextChange("lab_name", text)}
                                />
                            </div>
                        </div>
                      {values &&values.lab_name&&values.lab_name.includes('Others')&&<><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="second_part_other_lab"
                            >Name of the Lab  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the lab`}
                                    name="second_part_other_lab"
                                    value={values.second_part_other_lab}
                                    error={errors.second_part_other_lab}
                                    onChange={(e) =>handleTextChange('second_part_other_lab', e.target.value)}
                                />
                            </div>
                        </div></>}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="lab_address"
                            >Address of the Lab: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the lab`}
                                    name="lab_address"
                                    value={values.lab_address}
                                    error={errors.lab_address}
                                    onChange={(e) =>handleTextChange("lab_address", e.target.value)}
                                />
                            </div>
                        </div>
                         </> : null}
                         {values &&values.appeal_allowed&& values.appeal_allowed.includes('Dismissed') ? <>
                           <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="dismiss_reason"
                            >Reason For Dismissal: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Reason for dismissal`}
                                    name="dismiss_reason"
                                    value={values.dismiss_reason}
                                    error={errors.dismiss_reason}
                                    onChange={(e) =>handleTextChange("dismiss_reason", e.target.value)}
                                />
                            </div>
                        </div>
                         </> : null}
                       </>:null}
                        { showSample?<>
                          {values&&values.date_another_part_sample_sent_to_lab_on&&<div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_second_part_sample_received_on"
                            >Second Part Sample Received On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Second Part Sample Received On`}
                                    name="date_second_part_sample_received_on"
                                    value={values.date_second_part_sample_received_on}
                                    error={errors.date_second_part_sample_received_on}
                                    onChangeText={(text) =>handleTextChange("date_second_part_sample_received_on", text)}
                                />
                            </div>
                        </div> }
                            <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="sample_fit_for_analysis"
                            >Sample Fit For Analysis *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample fit for analysis`}
                                    name="sample_fit_for_analysis"
                                    items={Fit}
                                    action="dropdown"
                                    value={values.sample_fit_for_analysis}
                                    error={errors.sample_fit_for_analysis}
                                    onChange={(text) => handleTextChange("sample_fit_for_analysis", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="slip_matched"
                            >Sample matching with slip: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample matching with slip`}
                                    name="slip_matched"
                                    items={Fit}
                                    action="dropdown"
                                    value={values.slip_matched}
                                    error={errors.slip_matched}
                                    onChange={(text) => handleTextChange("slip_matched", text)}
                                />
                            </div>
                        </div>
                         </>:null}
                         {props.history.location.state&&props?.history?.location?.state?.surveil_sample?<>
                          <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="date_result_received_from_lab_on"
                           > Report Received on: </label>
                           <div className="col-sm-8">
                               <Datepicker
                                   placeholder={`Analysis Report Details`}
                                   name="date_result_received_from_lab_on"
                                   id="date_result_received_from_lab_on"
                                   value={values.date_result_received_from_lab_on}
                                   error={errors.date_result_received_from_lab_on}
                                   onChangeText={(text) => handleTextChange('date_result_received_from_lab_on', text)}
                               />
                           </div>
                       </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_result_informed_to_fbo_date"
                            >Analysis Report sent to FBO on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report sent to FBO on`}
                                    name="date_result_informed_to_fbo_date"
                                    id="date_result_informed_to_fbo_date"
                                    value={values.date_result_informed_to_fbo_date}
                                    error={errors.date_result_informed_to_fbo_date}
                                    onChangeText={(text) => handleTextChange('date_result_informed_to_fbo_date', text)}
                                    />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="result"
                            >Analysis Report : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Lab Report`}
                                    type="text"
                                    name="result"
                                    disabled                                   
                                    value={values.result_type}
                                    error={errors.result}
                                    items={resultData}
                                    onChange={(e) => handleTextChange("result", e.target.value)}
                                />
                            </div>
                        </div> 
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_show_cause_notice_issued_date"
                            >Show Cause Notice Issued Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Notice issued`}
                                    name="date_show_cause_notice_issued_date"
                                    id="date_show_cause_notice_issued_date"
                                    value={values.date_show_cause_notice_issued_date}
                                    error={errors.date_show_cause_notice_issued_date}
                                    onChangeText={(text) => handleTextChange('date_show_cause_notice_issued_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="rectified"
                            >Remarks Rectified : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Remarks Rectified`}
                                    type="text"
                                    name="rectified"
                                    action="dropdown"
                                    value={values.rectified}
                                    error={errors.rectified}
                                    items={Rectified}
                                    onChange={(text) => handleTextChange("rectified", text)}
                                />
                            </div>
                        </div> 
                      {values&&values.rectified=='yes'&&  <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_remarks_rectified_date"
                            >Remarks Rectified Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Remarks Rectified Date`}
                                    name="date_remarks_rectified_date"
                                    id="date_remarks_rectified_date"
                                    value={values.date_remarks_rectified_date}
                                    error={errors.date_remarks_rectified_date}
                                    onChangeText={(text) => handleTextChange('date_remarks_rectified_date', text)}
                                />
                            </div>
                        </div>}
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="act_samples_lifted"
                            > Act Samples Lifted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Act Samples Lifted `}
                                    name="act_samples_lifted"
                                    id="act_samples_lifted"
                                    value={values.act_samples_lifted}
                                    error={errors.act_samples_lifted}
                                    onChange={(e) => handleTextChange('act_samples_lifted', e.target.value)}
                                />
                            </div>
                        </div> */}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="evidence_upload"
                            >Evidence Upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="evidence_upload"
                                    type="file"
                                    id="evidence_upload"
                                    error={errors.evidence_upload}
                                    onChange={(e) => handleTextChange('evidence_upload', e.target.files[0])}
                                />
                            </div>
                        </div> 
                      </>:null}
                         {values && values.sample_fit_for_analysis? <> 
                            {showres ?
                        <>
                          <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_number"
                            >Result Number * : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Result Number`}
                                    type="text"
                                    name="result_number"
                                    value={values.result_number}
                                    error={errors.result_number}
                                    onChange={(e) =>handleTextChange("result_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_type"
                            >Result Type * : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Result Type`}
                                    type="text"
                                    name="result_type"
                                    items={Result}
                                    action="dropdown"
                                    value={values.result_type}
                                    error={errors.result_type}
                                    onChange={(text) =>handleTextChange("result_type", text)}
                                />
                            </div>
                        </div>  
                        {values &&values.result_type&& values.result_type.includes('Miscellaneous')? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_result_type"
                            ></label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Result Type`}
                                    type="text"
                                    name="other_result_type"
                                    error={errors.other_result_type}
                                    value={values.other_result_type}
                                    onChange={(e) =>handleTextChange("other_result_type", e.target.value)}
                                />
                            </div>
                        </div>  
                         </> : null}  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_result_signed_date"
                            >Result Signed Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Result Signed Date`}
                                    name="date_result_signed_date"
                                    value={values.date_result_signed_date}
                                    error={errors.date_result_signed_date}
                                    onChangeText={(text) =>handleTextChange("date_result_signed_date", text)}
                                />
                            </div>
                        </div>   <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_sample_documents"
                            >Upload Documents *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_sample_documents"
                                    error={errors.upload_sample_documents}
                                    onChange={(e) =>handleTextChange("upload_sample_documents", e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="report_intimated_to_DO_by"
                            >Result Intimated To DO through : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Report Intimated to DO through`}
                                    type="text"
                                    items={SampleSent}
                                    action="dropdown"
                                    name="report_intimated_to_DO_by"
                                    value={values.report_intimated_to_DO_by}
                                    error={errors.report_intimated_to_DO_by}
                                    onChange={(text) =>handleTextChange("report_intimated_to_DO_by", text)}
                                />
                            </div>
                        </div> 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="date_report_intimated_to_DO_on"
                         >Report sent to DO On *: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`Report Intimated to DO On`}
                                 type="text"
                                 name="date_report_intimated_to_DO_on"
                                 value={values.date_report_intimated_to_DO_on}
                                 error={errors.date_report_intimated_to_DO_on}
                                 onChangeText={(text) =>handleTextChange("date_report_intimated_to_DO_on", text)}
                             />
                         </div>
                     </div>
                         </>
                        : null} 
                       </>:null }
                      
                       {props?.history?.location?.state&&props?.history?.location?.state?.anotherPart?  <>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_Sample_damaged_intimation_received_from_lab_on"
                            >Sample Damaged Intimation Received From Lab On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Damaged Intimation Received From Lab On`}
                                    name="date_Sample_damaged_intimation_received_from_lab_on"
                                    value={values.date_Sample_damaged_intimation_received_from_lab_on}
                                    error={errors.date_Sample_damaged_intimation_received_from_lab_on}
                                    onChangeText={(text) =>handleTextChange("date_Sample_damaged_intimation_received_from_lab_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_another_part_sample_sent_to_lab_on"
                            >Another Part Sample Sent to Lab On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Another Part Sample Sent to lab on`}
                                    name="date_another_part_sample_sent_to_lab_on"
                                    value={values.date_another_part_sample_sent_to_lab_on}
                                    error={errors.date_another_part_sample_sent_to_lab_on}
                                    onChangeText={(text) =>handleTextChange("date_another_part_sample_sent_to_lab_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="second_part_upload_documents"
                            >Upload Documents : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="second_part_upload_documents"
                                    error={errors.second_part_upload_documents}
                                    onChange={(e) =>handleTextChange("second_part_upload_documents", e.target.files[0])}
                                />
                            </div>
                        </div>
                     </>:null}

                    {props?.history?.location?.state?.action_t&&props?.history?.location?.state?.action_t=='case_submit'?
                    <> 
                      <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_converted"
                            >Case Result *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Converted`}
                                    name="case_converted"
                                    items={Converted}
                                    action="dropdown"
                                    value={values.case_converted}
                                    error={errors.case_converted}
                                    onChange={(text) =>handleTextChange("case_converted", text)}
                                />
                            </div>
                        </div>
                        { values &&values.case_converted&& values.case_converted.includes('Case Converted')? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_category"
                            >Case Conversion *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Category`}
                                    name="case_category"
                                    items={Case}
                                    action="dropdown"
                                    value={values.case_category}
                                    error={errors.case_category}
                                    onChange={(text) =>handleTextChange("case_category", text)}
                                />
                            </div>
                        </div></>:null}
                      {/* {values&&values.case_category==='Civil'?<>  <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="assign_file_to"
                         >Assign file to  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Assign file to`}
                                 name="assign_file_to"
                                 items={asignUser}
                                 action="dropdown"
                                 value={values.assign_file_to}
                                 error={errors.assign_file_to}
                                 onChange={(text) =>handleTextChange('assign_file_to', text)}
                             />
                         </div>
                     </div> </>:null}  */}
                     {values&&values.case_category&&<div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="date_recommedation_by_do"
                         >File Recommended / Sent on *: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`File Recommended on`}
                                 name="date_recommedation_by_do"
                                 value={values.date_recommedation_by_do}
                                 error={errors.date_recommedation_by_do}
                                 onChangeText={(text) =>handleTextChange('date_recommedation_by_do', text)}
                             />
                         </div>
                     </div>}
                     {values&&values.case_category==='Criminal'?<> 
                      <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="assign_file_to_seat"
                         >Assign file to  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Assign file to`}
                                 name="assign_file_to_seat"
                                 items={asignSeat}
                                 action="dropdown"
                                 value={values.assign_file_to_seat}
                                 error={errors.assign_file_to_seat}
                                 onChange={(text) =>handleTextChange('assign_file_to_seat', text)}
                             />
                         </div>
                     </div> </>:null} 
                        </>
                        :null}
                        {!props.history.location.state ||  showLess ? <>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_trader"
                            >Type of Trader *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of Trader`}
                                    type="text"
                                    items={TypeOfTraders}
                                    action="dropdown"
                                    name="type_of_trader"
                                    value={values.type_of_trader}
                                    error={errors.type_of_trader}
                                    onChange={(text) =>handleTextChange("type_of_trader", text)}
                                />
                            </div>
                        </div> 
                        {values &&values.type_of_trader&& values.type_of_trader.includes('Others') ? (
                               <div className="form-group row">
                               <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_trader"
                               ></label>
                               <div className="col-sm-8">
                                   <TextInput
                                       placeholder={`Type of Trader`}
                                       type="text"
                                       name="other_type_of_trader"
                                       value={values.other_type_of_trader}
                                       error={errors.other_type_of_trader}
                                       onChange={(e) =>handleTextChange("other_type_of_trader", e.target.value)}
                                   />
                               </div>
                           </div>    
                        ) : null}
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="name_of_respondant_premisis"
                            >  Inspected Premisis Owner Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Inspected Premisis Owner Name`}
                                    type="text"
                                    name="name_of_respondant_premisis"
                                    value={values.name_of_respondant_premisis}
                                    error={errors.name_of_respondant_premisis}
                                    onChange={(e) => handleTextChange("name_of_respondant_premisis", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="address_of_respondant_premisis"
                            > Inspected Premisis Owner Address *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Inspected Premisis Owner Address`}
                                    type="text"
                                    name="address_of_respondant_premisis"
                                    value={values.address_of_respondant_premisis}
                                    error={errors.address_of_respondant_premisis}
                                    onChange={(e) => handleTextChange("address_of_respondant_premisis", e.target.value)}
                                />
                            </div>
                        </div>
                             <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_respondant"
                            >Name of the Respondant *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the respondant`}
                                    type="text"
                                    name="name_of_the_respondant"
                                    value={values.name_of_the_respondant}
                                    error={errors.name_of_the_respondant}
                                    onChange={(e) =>handleTextChange("name_of_the_respondant", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_respondant_residence"
                            >Address of the Respondant Residence *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Respondant Residence`}
                                    type="text"
                                    name="address_of_respondant_residence"
                                    value={values.address_of_respondant_residence}
                                    error={errors.address_of_respondant_residence}
                                    onChange={(e) =>handleTextChange("address_of_respondant_residence", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_documents_id_proof"
                            >Upload documents of ID Proof if any : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_documents_id_proof"
                                    error={errors.upload_documents_id_proof}
                                    onChange={(e) => handleTextChange("upload_documents_id_proof", e.target.files[0])}
                                />
                            </div>
                        </div>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="FSSAI_license_number"
                            >FSSAI License Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`FSSAI License Number`}
                                    type="text"
                                    name="FSSAI_license_number"
                                    value={values.FSSAI_license_number}
                                    error={errors.FSSAI_license_number}
                                    onChange={(e) =>handleTextChange("FSSAI_license_number", e.target.value)}
                                />
                            </div>
                        </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_document_of_license"
                            >Upload Document of License : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_document_of_license"
                                    error={errors.upload_document_of_license}
                                    onChange={(e) =>handleTextChange("upload_document_of_license", e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_fbo"
                            >Type of FBO Premisis Inspected *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of FBO Premisis Inspected`}
                                    type="text"
                                    items={TypeOfFbo}
                                    action="dropdown"
                                    name="FSSAI_license_number"
                                    value={values.type_of_fbo}
                                    error={errors.type_of_fbo}
                                    onChange={(text) =>handleTextChange("type_of_fbo", text)}
                                />
                            </div>
                        </div> 
                        {values &&values.type_of_fbo&& values.type_of_fbo.includes('Firm') ?
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="fbo_firm"
                         >Choose FBO Premisis Owner : </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`FBO Firm`}
                                 type="text"
                                 action="dropdown"
                                 items={Firm}
                                 name="fbo_firm"
                                 value={values.fbo_firm}
                                 error={errors.fbo_firm}
                                 onChange={(text) =>handleTextChange("fbo_firm", text)}
                             />
                         </div>
                     </div>
                        
                        : null}
                            {values &&values.fbo_firm&& values.fbo_firm.includes('Partnership') ? 
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="nominee"
                            >Partner/Nominee : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Partner/Nominee`}
                                    type="text"
                                    action="dropdown"
                                    items={Partnership}
                                    name="nominee"
                                    value={values.nominee}
                                    error={errors.nominee}
                                    onChange={(text) =>handleTextChange("nominee", text)}
                                />
                            </div>
                        </div>
                            : null}
                            {values &&values.type_of_fbo&& values.type_of_fbo.includes('Company') ? 
                             <div className="form-group row">
                             <label className="control-label col-sm-4 text-label" htmlFor="nominees"
                             >Director/Nominee : </label>
                             <div className="col-sm-8">
                                 <DropDownInput
                                     placeholder={`Director/Nominees`}
                                     type="text"
                                     items={Company}
                                     action="dropdown"
                                     name="nominees"
                                     value={values.nominees}
                                     error={errors.nominees}
                                     onChange={(text) =>handleTextChange("nominee", text)}
                                 />
                             </div>
                         </div> 
                            : null}
                          {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_sample_lifted"
                            >Type of Sample Lifted *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of sample lifted`}
                                    type="text"
                                    items={TypeOfSample}
                                    action="dropdown"
                                    name="type_of_sample_lifted"
                                    value={values.type_of_sample_lifted}
                                    error={errors.type_of_sample_lifted}
                                    onChange={(text) =>handleTextChange("type_of_sample_lifted", text)}
                                />
                            </div>
                        </div>  */}
                        {values &&values.type_of_sample_lifted&& values.type_of_sample_lifted.includes('Act')? <>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="form_V_given_to"
                            >Form V-A Given To: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Form V-A given to`}
                                    type="text"
                                    items={personInPremisis}
                                    action="dropdown"
                                    name="form_V_given_to"
                                    value={values.form_V_given_to}
                                    error={errors.form_V_given_to}
                                    onChange={(text) =>handleTextChange("form_V_given_to", text)}
                                />
                            </div>
                        </div>  <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_documents_of_V_A"
                            >Upload Documents of V-A : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents of V-A`}
                                    type="file"
                                    name="upload_documents_of_V_A"
                                    error={errors.upload_documents_of_V_A}
                                    onChange={(e) =>handleTextChange("upload_documents_of_V_A",e.target.files[0])}
                                />
                            </div>
                        </div>  <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_sample_lifted_date_and_time"
                            >Sample Lifted Date {values&&values.type_of_sample_lifted&&'*'} : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample lifted date `}
                                    type="text"
                                    name="date_sample_lifted_date_and_time"
                                    // showTimeSelect
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    value={values.date_sample_lifted_date_and_time}
                                    error={errors.date_sample_lifted_date_and_time}
                                    onChangeText={(text) =>handleTextChange("date_sample_lifted_date_and_time", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_food_category"
                            >Sample Food Category: </label>
                            <div className="col-sm-8">
                                {/* <TextInput
                                    placeholder={`Sample Food Category`}
                                    type="text"
                                    name="sample_food_category"
                                    value={values.sample_food_category}
                                    error={errors.sample_food_category}
                                    onChange={(e) =>handleTextChange("sample_food_category", e.target.value)}
                                /> */}
                              {foodCategory&&foodCategory.length!==0&&   <DropDownInput
                                    placeholder={`Sample food category`}
                                    type="text"
                                    items={foodCategory}
                                    action="dropdown"
                                    name="sample_food_category"
                                    value={values.sample_food_category}
                                    error={errors.sample_food_category}
                                    onChange={(text) =>handleTextChange("sample_food_category", text)}
                                />}
                            </div>
                        </div><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_number_given_by_do"
                            >Sample Number Given By DO {values&&values.type_of_sample_lifted&&'*'} : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sample Number Given by DO`}
                                    type="text"
                                    name="sample_number_given_by_do"
                                    value={values.sample_number_given_by_do}
                                    error={errors.sample_number_given_by_do}
                                    onChange={(e) =>handleTextChange("sample_number_given_by_do", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_slip_given_date"
                            >Sample Slip Given Date : </label>
                            <div className="col-sm-8">
                            <Datepicker
                                    placeholder={`Sample Slip Given Date`}
                                    name="date_slip_given_date"
                                    id="date_slip_given_date"
                                    value={values.date_slip_given_date}
                                    error={errors.date_slip_given_date}
                                    onChangeText={(text) => handleTextChange('date_slip_given_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_sample_slip"
                            >Upload Sample Slip: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Sample Slip`}
                                    type="file"
                                    name="upload_sample_slip"
                                    error={errors.upload_sample_slip}
                                    onChange={(e) =>handleTextChange("upload_sample_slip", e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_purchased_receipt_in_RS"
                            >Sample Purchased Receipt (in RS) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sample purchased Receipt in RS`}
                                    type="text"
                                    name="sample_purchased_receipt_in_RS"
                                    value={values.sample_purchased_receipt_in_RS}
                                    error={errors.sample_purchased_receipt_in_RS}
                                    onChange={(e) =>handleTextChange("sample_purchased_receipt_in_RS", e.target.value)}
                                />
                            </div>
                        </div><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="condition_of_sample"
                            >Condition Of Sample : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Condition of Sample`}
                                    type="text"
                                    items={ConditionOfSample}
                                    action="dropdown"
                                    name="condition_of_sample"
                                    value={values.condition_of_sample}
                                    error={errors.condition_of_sample}
                                    onChange={(text) =>handleTextChange("condition_of_sample", text)}
                                />
                            </div>
                        </div>
                        {values &&values.condition_of_sample&& values.condition_of_sample.includes('Prepacked')? <><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_food_specified_in_label"
                            >Name Of the Food Specified In Label : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Food Specified in label`}
                                    type="text"
                                    name="name_of_the_food_specified_in_label"
                                    value={values.name_of_the_food_specified_in_label}
                                    error={errors.name_of_the_food_specified_in_label}
                                    onChange={(e) =>handleTextChange("name_of_the_food_specified_in_label", e.target.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="batch_lot_no"
                            >Batch/Lot Number : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Batch/Lot Number`}
                                    type="text"
                                    name="batch_lot_no"
                                    value={values.batch_lot_no}
                                    error={errors.batch_lot_no}
                                    onChange={(e) =>handleTextChange("batch_lot_no",e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_manufacturing_packing"
                            >Date of Manufacturing/Packed: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of Manufcaturing/Packed`}
                                    type="text"
                                    name="date_of_manufacturing_packing"
                                    value={values.date_of_manufacturing_packing}
                                    error={errors.date_of_manufacturing_packing}
                                    onChangeText={(text) =>handleTextChange("date_of_manufacturing_packing", text)}
                                />
                            </div>
                        </div><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_expiry_date_valid_upto"
                            >Expiry Date / Valid Upto: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Expiry Date/Valid Upto`}
                                    type="text"
                                    name="date_expiry_date_valid_upto"
                                    value={values.date_expiry_date_valid_upto}
                                    error={errors.date_expiry_date_valid_upto}
                                    onChangeText={(text) =>handleTextChange("date_expiry_date_valid_upto", text)}
                                />
                            </div>
                        </div></>: null}
                        {values &&values.type_of_trader&& values.type_of_trader.includes('Manufacturer') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Purchased From`}
                                    type="text"
                                    items={Retailer}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null }
                        {values &&values.type_of_trader&& values.type_of_trader.includes('Retailer') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Bought/Purchased From`}
                                    type="text"
                                    items={Distributor}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null }  {values && values.type_of_trader&&values.type_of_trader.includes('WholeSaler/Distributor/Repacker') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Bought/Purchased From`}
                                    type="text"
                                    items={Transporter}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null }  {values &&values.type_of_trader&& values.type_of_trader.includes('Transporter') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Bought/Purchased From`}
                                    type="text"
                                    items={Others}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null }
                        {values && values.Product_bought_from ? <> <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_product_purchased"
                            >Name Of the Product Purchased: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the product purchased`}
                                    type="text"
                                    name="name_of_the_product_purchased"
                                    value={values.name_of_the_product_purchased}
                                    error={errors.name_of_the_product_purchased}
                                    onChange={(e) =>handleTextChange("name_of_the_product_purchased", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_product_purchased"
                            >Address of the Product Purchased : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Address of the Product Purchased`}
                                    type="text"
                                    name="address_of_the_product_purchased"
                                    value={values.address_of_the_product_purchased}
                                    error={errors.address_of_the_product_purchased}
                                    onChange={(e) =>handleTextChange("address_of_the_product_purchased", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_form_V_sent_to_owner"
                            >Form V-A sent to Owner on: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Form V-A Sent to Owner`}
                                    type="text"
                                    name="date_form_V_sent_to_owner"
                                    value={values.date_form_V_sent_to_owner}
                                    error={errors.date_form_V_sent_to_owner}
                                    onChangeText={(text) =>handleTextChange("date_form_V_sent_to_owner", text)}
                                />
                            </div>
                        </div>  <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_documents"
                            >Upload Documents : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_documents"
                                    error={errors.upload_documents}
                                    onChange={(e) =>handleTextChange("upload_documents", e.target.files[0])}
                                />
                            </div>
                        </div> 
                         </> : null }
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_sent_by"
                            >Sample Sent By : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample Sent By`}
                                    type="text"
                                    items={SampleSent}
                                    action="dropdown"
                                    name="sample_sent_by"
                                    value={values.sample_sent_by}
                                    error={errors.sample_sent_by}
                                    onChange={(text) =>handleTextChange("sample_sent_by", text)}
                                />
                            </div>
                        </div> 
                        {values && values.sample_sent_by ? <>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_sample_sent_to_lab_on"
                            >Sample Sent To Lab On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Sent to lab on`}
                                    type="text"
                                    name="date_sample_sent_to_lab_on"
                                    value={values.date_sample_sent_to_lab_on}
                                    error={errors.date_sample_sent_to_lab_on}
                                    onChangeText={(text) =>handleTextChange("date_sample_sent_to_lab_on", text)}
                                />
                            </div>
                        </div> <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_postal_receipt"
                            >Upload Postal Receipt : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Postal Receipt`}
                                    type="file"
                                    name="upload_postal_receipt"
                                    error={errors.upload_postal_receipt}
                                    onChange={(e) =>handleTextChange("upload_postal_receipt", e.target.files[0])}
                                />
                            </div>
                        </div>   </> : null}
                       {Lab&& <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="select_the_lab"
                            >Select the Lab *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Select the lab`}
                                    type="text"
                                    items={Lab}
                                    action="dropdown"
                                    name="select_the_lab"
                                    value={values.select_the_lab}
                                    error={errors.select_the_lab}
                                    onChange={(text) =>handleTextChange("select_the_lab", text)}
                                />
                            </div>
                        </div> }
                        {values &&values.select_the_lab&& values.select_the_lab.includes('Others') ? 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_select_the_lab"
                         > </label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Lab name`}
                                 type="text"
                                 name="other_select_the_lab"
                                 value={values.other_select_the_lab}
                                 error={errors.other_select_the_lab}
                                 onChange={(e) =>handleTextChange("other_select_the_lab", e.target.value)}
                             />
                         </div>
                     </div>  
                        : null}
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="analys_sample"
                            >NABL Option : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`FBO sample 4th part sample analysis option`}
                                    type="text"
                                    items={Aanlysi}
                                    action="dropdown"
                                    name="analys_sample"
                                    value={values.analys_sample}
                                    error={errors.analys_sample}
                                    onChange={(text) =>handleTextChange("analys_sample", text)}
                                />
                            </div>
                        </div> 
                      {values &&values.analys_sample&& values.analys_sample.includes('Willing to test') ? <> <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="FBO_sample_4th_part_analysis_nabl_option_intimated"
                            >FBO Sample 4th Part Sample Analysis NABL Option Intimated : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`FBO sample 4th part sample analysis NABL option intimated`}
                                    type="text"
                                    items={IntimatedToDO}
                                    action="dropdown"
                                    name="FBO_sample_4th_part_analysis_nabl_option_intimated"
                                    value={values.FBO_sample_4th_part_analysis_nabl_option_intimated}
                                    error={errors.FBO_sample_4th_part_analysis_nabl_option_intimated}
                                    onChange={(text) =>handleTextChange("FBO_sample_4th_part_analysis_nabl_option_intimated", text)}
                                />
                            </div>
                        </div> 
                        {values &&values.FBO_sample_4th_part_analysis_nabl_option_intimated&& values.FBO_sample_4th_part_analysis_nabl_option_intimated.includes('Yes')? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_intimated_to_do_on"
                            >Intimated to DO On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Intimated to DO On`}
                                    type="text"
                                    name="date_intimated_to_do_on"
                                    value={values.date_intimated_to_do_on}
                                    error={errors.date_intimated_to_do_on}
                                    onChangeText={(text) =>handleTextChange("date_intimated_to_do_on", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_and_address_of_nabl_lab"
                            >Name and Address of NABL Lab : </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Name and Address Of NABL Lab`}
                                    type="text"
                                    name="name_and_address_of_nabl_lab"
                                    value={values.name_and_address_of_nabl_lab}
                                    error={errors.name_and_address_of_nabl_lab}
                                    onChange={(e) =>handleTextChange("name_and_address_of_nabl_lab", e.target.value)}
                                />
                            </div>
                        </div>
                        </> : null} </>:null}
                             </> : null}
                             {values &&values.type_of_sample_lifted&& values.type_of_sample_lifted.includes('Act')? <>
                           {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_act_sample_lifted_date_and_time"
                            > Act Sample Lifted Date and Time : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Act Sample lifted date and time`}
                                    type="text"
                                    name="date_act_sample_lifted_date_and_time"
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    value={values.date_act_sample_lifted_date_and_time}
                                    error={errors.date_act_sample_lifted_date_and_time}
                                    onChangeText={(text) =>handleTextChange("date_act_sample_lifted_date_and_time", text)}
                                />
                            </div>
                        </div>   */}
                     
                        {/* {values && values.type_of_trader.includes('Retailer') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Purchased From`}
                                    type="text"
                                    items={Retailer}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null }
                        {values && values.type_of_trader.includes('WholeSaler/Distributor/Repacker') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Bought/Purchased From`}
                                    type="text"
                                    items={Distributor}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null }  {values && values.type_of_trader.includes('Transporter') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Bought/Purchased From`}
                                    type="text"
                                    items={Transporter}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null }  {values && values.type_of_trader.includes('Others') ? <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Product_bought_from"
                            >Product Bought/Purchased From: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Product Bought/Purchased From`}
                                    type="text"
                                    items={Others}
                                    action="dropdown"
                                    name="Product_bought_from"
                                    value={values.Product_bought_from}
                                    error={errors.Product_bought_from}
                                    onChange={(text) =>handleTextChange("Product_bought_from", text)}
                                />
                            </div>
                        </div> : null } */}
                             </> : null}
                             {values && values.type_of_sample_lifted&&values.type_of_sample_lifted.includes('Surveillance')? <> <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_number_given_by_do"
                            >Sample number given by DO *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`sample number`}
                                    type="number"
                                    name="sample_number_given_by_do"
                                    id="sample_number_given_by_do"
                                    value={values.sample_number_given_by_do}
                                    error={errors.sample_number_given_by_do}
                                    onChange={(e) => handleTextChange("sample_number_given_by_do", e.target.value)}
                                />
                            </div>
                        </div>   
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_sample_lifted_date_and_time"
                            >Sample Lifted Date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample lifted date`}
                                    type="text"
                                    name="date_sample_lifted_date_and_time"
                                    // showTimeSelect
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    value={values.date_sample_lifted_date_and_time}
                                    error={errors.date_sample_lifted_date_and_time}
                                    onChangeText={(text) =>handleTextChange("date_sample_lifted_date_and_time", text)}
                                />
                            </div>
                        </div>                          
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_food_safety_officer"
                            >Name Of the Food Safety Officer *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`name of the food safety officer`}
                                    items={fsoOfficers}
                                    type="text"
                                    action="fsos"
                                    name="name_of_the_food_safety_officer"
                                    id="name_of_the_food_safety_officer"
                                    value={values.name_of_the_food_safety_officer}
                                    error={errors.name_of_the_food_safety_officer}
                                    onChange={(value) =>handleTextChange("name_of_the_food_safety_officer", value)}
                                />
                            </div>
                        </div> */}
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_and_address_of_sampling"
                            > FBO Name and Address of Sampling *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`FBO Name and address of sampling`}
                                    type="text"
                                    name="name_and_address_of_sampling"
                                    id="name_and_address_of_sampling"
                                    value={values.name_and_address_of_sampling}
                                    error={errors.name_and_address_of_sampling}
                                    onChange={(e) => handleTextChange("name_and_address_of_sampling", e.target.value)}
                                />
                            </div>
                        </div> */}
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_number"
                            > License/Registration Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`License/Registration Number`}
                                    type="text"
                                    name="license_registration_number"
                                    id="license_registration_number"
                                    value={values.license_registration_number}
                                    error={errors.license_registration_number}
                                    onChange={(e) => handleTextChange("license_registration_number", e.target.value)}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="nature_of_the_sample_with_brand_name"
                            >Nature of the Sample with Brand Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={` nature of the sample`}
                                    type="text"
                                    name="nature_of_the_sample_with_brand_name"
                                    id="nature_of_the_sample_with_brand_name"
                                    value={values.nature_of_the_sample_with_brand_name}
                                    error={errors.nature_of_the_sample_with_brand_name}
                                    onChange={(e) => handleTextChange("nature_of_the_sample_with_brand_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="category_of_food"
                            >Category of the Food Product *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`category of the food product`}
                                    type="text"
                                    action="dropdown"
                                    name="category_of_food_stuff"
                                    value={values.category_of_food_stuff}
                                    error={errors.category_of_food_stuff}
                                    items={foodTypes}
                                    onChange={(text) => handleTextChange("category_of_food_stuff", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_manufacturing_company"
                            >Name Of the Manufacturing Company *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the  manufacturing company`}
                                    type="text"
                                    name="name_of_the_manufacturing_company"
                                    id="name_of_the_manufacturing_company"
                                    value={values.name_of_the_manufacturing_company}
                                    error={errors.name_of_the_manufacturing_company}
                                    onChange={(e) => handleTextChange("name_of_the_manufacturing_company", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_company"
                            >License/Registration Number of Manufacturing Company *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`License/Registration Number of Manufacturing Company`}
                                    type="text"
                                    name="license_registration_company"
                                    id="license_registration_company"
                                    value={values.license_registration_company}
                                    error={errors.license_registration_company}
                                    onChange={(e) => handleTextChange("license_registration_company", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="category_of_trader"
                            >Category of the Traders *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Category of the Traders `}
                                    type="text"
                                    name="category_of_trader"
                                    value={values.category_of_trader}
                                    error={errors.category_of_trader}
                                    items={traderTypes}
                                    onChange={(text) => handleTextChange("category_of_trader", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="select_the_lab"
                            >Sample Sent for Analysis(Lab) *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Name of the laboratory`}
                                    type="text"
                                    action="dropdown"
                                    items={Lab}
                                    name="select_the_lab"
                                    id="select_the_lab"
                                    value={values.select_the_lab}
                                    error={errors.select_the_lab}
                                    onChange={(text) => handleTextChange("select_the_lab", text)}
                                />
                            </div>
                        </div>
                        {values &&values.name_of_the_laboratory&& values.name_of_the_laboratory.includes('Others') ? (
                           <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="other_name_of_the_laboratory"
                           > </label>
                           <div className="col-sm-8">
                               <TextInput
                                   placeholder={`Name of the laboratory`}
                                   name="other_name_of_the_laboratory"
                                   id="other_name_of_the_laboratory"
                                   value={values.other_name_of_the_laboratory}
                                   error={errors.other_name_of_the_laboratory}
                                   onChange={(e) => handleTextChange('other_name_of_the_laboratory', e.target.value)}
                               />
                           </div>
                       </div>  
                        ) : null}
                      
                             </>:null}</>:null}
                        <div className="form-group">
                            <button
                                onClick={() =>handlBack()}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                              {user&&user.user_types&&user.user_types.includes('fso')&&<button
                               onClick={hhandleSubmit}
                                className="btn btn-success"
                                style={{marginLeft:'20px'}}
                                type="button">
                                Save</button>}
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div> 
                        </>}
                    </form>:null}
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFSOSampleReports: (data) => dispatch(createFSOSampleReports(data)),
    onEditFSOSampleReports: (data, record_id) => dispatch(editFSOSampleReports(data, record_id)),
    ongetLabs: (data) => dispatch(testingLab(data)),
    ongetSample: (data) => dispatch(createSampleSuccess(data)),
    ongetSampe:(data)=>dispatch(updateSample(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
    ongetAssignUser:(data)=>dispatch(getAsignUser(data)),
    ongetSurveilDetails:(data)=>dispatch(getSurvSamples(data)),
    ongetInspDetails:(data)=>dispatch(getinspectionSamples(data)),
    onCreateAppeal:(data,id)=>dispatch(createAppeal(data,id)),
    ongetFoodCategory:(data,id)=>dispatch(getFoodCategory(data,id)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    users: state.home.users,
    wards: state.home.wards,
    traderTypes: state.home.traderTypes,
    wardsCategory:state.home.wardsCategory,
    foodTypes: state.home.foodTypes,
    fsoOfficers: state.home.fsoOfficers,
    fso_submit_data:state.home.fso_submit_data,
    sample_data:state.home.sample_data,
    lab_analyse_sample:state.home.lab_analyse_sample,


});
  
export default connect(mapStateToProps, mapDispatchToProps)(FSOSampleReport);