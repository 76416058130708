import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createEnumerationDataReports, editEnumerationDataReports, getDistrictWards, getFoodCategory } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from "moment";


const Type = [
    {id:"0", name:"License"},
    {id:"1", name:"Registration"}
]
const Reg = [
    {id:"0", name:"Registration Under Progress"},
    {id:"1", name:"Registration Obtained"}
]
const Owner = [
    {id:"0", name:"Preparator"},
    {id:"1", name:"Partner"}
]
const TurnOver = [
    {id:"0", name:"upto 12Lakh"},
    {id:"1", name:"above 12Lakh to 10Crore"},
    {id:"2", name:"above 20Crore"},
    {id:"3", name:"Central Board/Export & Import of Non sealed Foods"},
]
const Category = [
{id:"0", name:"Packed Milk"},
    {id:"1", name:"Loose Milk"},
    {id:"2", name:"Butter, Ghee, IceCream and Other Milk products"},
    {id:"3", name:"Edible Oils, Fats & Vanaspati"},
    {id:"4", name:"Fresh Fruits and Vegetables"},
    {id:"5", name:"Processed Fruit & Vegetable Product"},
    {id:"6", name:"Pickles"},
    {id:"7", name:"Cereals and Cereals Product"},
    {id:"8", name:"Meat and Meat Products"},
    {id:"9", name:"Egg and Egg Products"},
    {id:"10", name:"Fish and Fish Products"},
    {id:"11", name:"Bakery & Confectionery"},
    {id:"12", name:"Sweeteners Including Honey"},
    {id:"13", name:"Salt Including Lodisedd Salt"},
    {id:"14", name:"Whole Spices and condiments"},
    {id:"15", name:"Spices and Condiments Powder"},
    {id:"16", name:"Alcoholic Bevarages"},
    {id:"17", name:"Non-Alcholoic(Carbonated) Bevarages"},
    {id:"18", name:"Non-Alcholoic(non-Carbonated) including thermally processed fruits and vegetables juices etc"},
    {id:"19", name:"Mineral Water"},
    {id:"20", name:"Packaged Drinking"},
    {id:"21", name:"Savouries/Namkeens/Sweets"},
    {id:"22", name:"Tea, Coffee, Coco, Chicory"},
    {id:"23", name:"Infant Milk Substitutes"},
    {id:"24", name:"Infant Food (Weaning Food)"},
    {id:"25", name:"Supari, Pan-Masala"},
    {id:"26", name:"Hotels and Restaurants Prepared Food"},
    {id:"27", name:"Caterer Prepared Food"},
    {id:"28", name:"Dhaba Prepared Food"},
    {id:"29", name:"Mid-day Meal"},
    {id:"20", name:"Neutraceiticals/Health"},
    {id:"31", name:"Food Additives"},
    {id:"32", name:"Other Miscellaneous Food"},

]

const EnumerationDataReport = (props) => {
    const { districts, user, wards,wardsCategory,ongetDist, onCreateEnumerationDataReports,onGetDist, onEditEnumerationDataReports, traderTypes ,onFoodcategory} = props;
    const [values, setValues] = useState({
       
        'area_name':'',
        'area_code':'',
        'district' : '', 
        'date':'',
        'name_of_the_street':'',
        'name_of_the_operator_partners':'',
        'address_of_trade':'',
        'contact_number':'',
        'door_no':'',
        'email_id':'',
        'type_of_trader_category':'',
        'product_category':'',
        'turnover_annum':'',
        'type':'',
        'is_registered':'',
        'license_registration_refernece_number':'',
        'license_registration_applied_date':'',
        'license_registration_number':'',
        'license_registration_received_date':'',
        'license_registration_valid_upto_date':'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [field, setField] = useState(false);
    const [foodCategory, setFoodCategory]=useState([])
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });



 const getFood=async()=>{
    const resp = await onFoodcategory()
    console.log(resp)
    if(resp.response){
        setFoodCategory(resp.response)
    }
 }
 const getDis=async(data)=>{
    await ongetDist({district:data})
   }
   useEffect(()=>{
     if(values.district&&values.district){
       getDis(values.district)
     }
   },[values.district&&values.district])
useEffect(()=>{
    getFood()
},[])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            values.type_of_trader_category = JSON.parse(values.type_of_trader_category)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [ 
            'area_name',
        'area_code',
        'district', 
        'date',
        'name_of_the_village',
        'name_of_the_street',
        'name_of_the_operator_partners',
        'address_of_trade',
        'contact_number',
        'email_id',
        'type_of_trader_category',
        'product_category',
        'turnover_annum',
        'door_no',
        'license_registration_refernece_number',
        'license_registration_applied_date',
        'license_registration_number',
        'license_registration_received_date',
        'license_registration_valid_upto_date'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditEnumerationDataReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/enumeration-data-list/`);
            };

        } else {       
            const data = { ...values}
            console.log(data)
            setDisableSubmit(true);
            const resp = await onCreateEnumerationDataReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }
// useEffect(()=>{
//     console.log('cal')
//     var distWard = null
//     districts.map((o)=>{
// if(o.id == values.district){
// const resp = onGetDist()
// if(resp && resp.reponse){
//     console.log(resp.response)
//     // var distWard = resp.response.data
// }

// // return(
// //     // wardsCategory = distWard
// // )
// }
//     })
// },[values.district])

useEffect(()=>{
    wardsCategory&&wardsCategory.map((o)=>{
        if(o.id == values.area_code)
        return (
            setValues({
                ...values,
                area_name: o.ward_name
            })
        )
    })
},[values.area_code])
    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

  

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Enumeration Data Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>  
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    type="text"
                                    name="date"
                                    id="date"
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange("date", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_village"
                            >Name of the village/Ward *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the village/ward`}
                                    type="text"
                                    name="name_of_the_village"
                                    id="name_of_the_village"
                                    value={values.name_of_the_village}
                                    error={errors.name_of_the_village}
                                    onChange={(e) => handleTextChange("name_of_the_village", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_street"
                            >Name Of the Street *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the street`}
                                    type="text"
                                    name="name_name_of_the_streetof_the_fbo"
                                    id="name_of_the_street"
                                    value={values.name_of_the_street}
                                    error={errors.name_of_the_street}
                                    onChange={(e) => handleTextChange("name_of_the_street", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="door_no"
                            >Door/Flat No *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Door/Flat No`}
                                    type="text"
                                    name="door_no"
                                    id="door_no"
                                    value={values.door_no}
                                    error={errors.door_no}
                                    onChange={(e) => handleTextChange("door_no", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_operator_partners"
                            >Name Of the Owner/Partner *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                    placeholder={`Type of trader Category`}
                                    type="text"
                                    name="name_of_the_operator_partners"
                                    id="name_of_the_operator_partners"
                                    action='dropdown'
                                    items={Owner}
                                    value={values.name_of_the_operator_partners}
                                    error={errors.name_of_the_operator_partners}
                                    onChange={(text) => handleTextChange("name_of_the_operator_partners", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_trade"
                            >Address Of FBO Premisis *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Address Of trade`}
                                    type="text"
                                    name="address_of_trade"
                                    id="address_of_trade"
                                    value={values.address_of_trade}
                                    error={errors.address_of_trade}
                                    onChange={(e) => handleTextChange("address_of_trade", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="contact_number"
                            >Contact Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Contact Number`}
                                    type="number"
                                    name="contact_number"
                                    id="contact_number"
                                    value={values.contact_number}
                                    error={errors.contact_number}
                                    onChange={(e) => handleTextChange("contact_number", e.target.value)}
                                />
                            </div>
                        </div> 
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="email_id"
                            >Email ID *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Email ID`}
                                    type="text"
                                    name="email_id"
                                    id="email_id"
                                    value={values.email_id}
                                    error={errors.email_id}
                                    onChange={(e) => handleTextChange("email_id", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_trader_category"
                            >Type Of Trader Category *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of trader Category`}
                                    type="text"
                                    name="type_of_trader_category"
                                    id="type_of_trader_category"
                                    items={traderTypes}
                                    value={values.type_of_trader_category}
                                    error={errors.type_of_trader_category}
                                    onChange={(text) => handleTextChange("type_of_trader_category", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="product_category"
                            > Food Product Category *: </label>
                            <div className="col-sm-8">
                                {/* <DropDownInput
                                    placeholder={` Food Product Category`}
                                    type="text"
                                    name="product_category"
                                    id="product_category"
                                    items={Category}
                                    action="dropdown"
                                    value={values.product_category}
                                    error={errors.product_category}
                                    onChange={(text) => handleTextChange("product_category", text)}
                                /> */}
                              {foodCategory&&foodCategory.length !==0&&  <DropDownInput
                                    placeholder={` Food Product Category`}
                                    name="product_category"
                                    id="product_category"
                                    items={foodCategory}
                                    action="dropdown"
                                    value={values.product_category}
                                    error={errors.product_category}
                                    onChange={(text) => handleTextChange("product_category", text)}
                                />}
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="turnover_annum"
                            >Turn Over Per Annum *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Turn Over per Annum`}
                                    type="text"
                                    name="turnover_annum"
                                    id="turnover_annum"
                                    items={TurnOver}
                                    action="dropdown"
                                    value={values.turnover_annum}
                                    error={errors.turnover_annum}
                                    onChange={(text) => handleTextChange("turnover_annum", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type"
                            >Type: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type`}
                                    type="text"
                                    name="type"
                                    id="type"
                                    items={Type}
                                    action="dropdown"
                                    value={values.type}
                                    error={errors.type}
                                    onChange={(text) => handleTextChange("type", text)}
                                />
                            </div>
                        </div> 
                        {values && values.type.includes('License') ? (
                         <div>
                            
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_refernece_number"
                            >License Reference Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`License Reference Number`}
                                    type="text"
                                    name="license_registration_refernece_number"
                                    id="license_registration_refernece_number"
                                    value={values.license_registration_refernece_number}
                                    error={errors.license_registration_refernece_number}
                                    onChange={(e) => handleTextChange("license_registration_refernece_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_applied_date"
                            >License Applied Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`License Applied Date`}
                                    type="text"
                                    name="license_registration_applied_date"
                                    id="license_registration_applied_date"
                                    value={values.license_registration_applied_date}
                                    error={errors.license_registration_applied_date}
                                    onChangeText={(text) => handleTextChange("license_registration_applied_date", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license/registration_number"
                            >License Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`License Number`}
                                    type="text"
                                    name="license_registration_number"
                                    id="license_registration_number"
                                    value={values.license_registration_number}
                                    error={errors.license_registration_number}
                                    onChange={(e) => handleTextChange("license_registration_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_received_date"
                            >License Issued Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`License Issued Date`}
                                    type="text"
                                    name="license_registration_received_date"
                                    id="license_registration_received_date"
                                    value={values.license_registration_received_date}
                                    error={errors.license_registration_received_date}
                                    onChangeText={(text) => handleTextChange("license_registration_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_valid_upto_date"
                            >License Valid Upto *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`License Valid Upto`}
                                    type="text"
                                    name="license_registration_valid_upto_date"
                                    id="license_registration_valid_upto_date"
                                    value={values.license_registration_valid_upto_date}
                                    error={errors.license_registration_valid_upto_date}
                                    onChangeText={(text) => handleTextChange("license_registration_valid_upto_date", text)}
                                />
                            </div>
                        </div> 
                         </div>   
                        ):null}
                        {values && values.type.includes('Registration') ? (<div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="is_registered"
                            >Is Registered: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Is Registered`}
                                    type="text"
                                    name="is_registered"
                                    id="is_registered"
                                    items={Reg}
                                    action="dropdown"
                                    value={values.is_registered}
                                    error={errors.is_registered}
                                    onChange={(text) => handleTextChange("is_registered", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_refernece_number"
                            >Registration Reference Number: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Registration Reference Number`}
                                    type="text"
                                    name="license_registration_refernece_number"
                                    id="license_registration_refernece_number"
                                    value={values.license_registration_refernece_number}
                                    error={errors.license_registration_refernece_number}
                                    onChange={(e) => handleTextChange("license_registration_refernece_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_applied_date"
                            >Registration Applied Date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Registration Applied Date`}
                                    type="text"
                                    name="license_registration_applied_date"
                                    id="license_registration_applied_date"
                                    value={values.license_registration_applied_date}
                                    error={errors.license_registration_applied_date}
                                    onChangeText={(text) => handleTextChange("license_registration_applied_date", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license/registration_number"
                            >Registration Number: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Registration Number`}
                                    type="text"
                                    name="license_registration_number"
                                    id="license_registration_number"
                                    value={values.license_registration_number}
                                    error={errors.license_registration_number}
                                    onChange={(e) => handleTextChange("license_registration_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_received_date"
                            >Registration Received Date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Registration Received Date`}
                                    type="text"
                                    name="license_registration_received_date"
                                    id="license_registration_received_date"
                                    value={values.license_registration_received_date}
                                    error={errors.license_registration_received_date}
                                    onChangeText={(text) => handleTextChange("license_registration_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_valid_upto_date"
                            >Registration Valid Upto: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Registration Valid Upto`}
                                    type="text"
                                    name="license_registration_valid_upto_date"
                                    id="license_registration_valid_upto_date"
                                    value={values.license_registration_valid_upto_date}
                                    error={errors.license_registration_valid_upto_date}
                                    onChangeText={(text) => handleTextChange("license_registration_valid_upto_date", text)}
                                />
                            </div>
                        </div> </div> ):null}
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateEnumerationDataReports: (data) => dispatch(createEnumerationDataReports(data)),
    onEditEnumerationDataReports: (data, record_id) => dispatch(editEnumerationDataReports(data, record_id)),
    onGetDist:(data)=>dispatch(getDistrictWards(data)),
    onFoodcategory:(data)=>dispatch(getFoodCategory(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),



});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    traderTypes: state.home.traderTypes,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(EnumerationDataReport);