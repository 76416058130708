import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createDLACReports, editDLACReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const DLAC= [
    {id:"0", name:"Quarter I"},
    {id:"1", name:"Quarter II"},
    {id:"2", name:"Quarter III"},
    {id:"3", name:"Quarter IV"},
]

const DLACReport = (props) => {
    const { districts,wards, user, onCreateDLACReports,wardsCategory,ongetDist, onEditDLACReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'select_dlac':'',
        'documents' : '',
        'no_of_participants':'',
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'select_dlac','area_name','area_code','no_of_participants'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditDLACReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/dlac-list/`);
            };

        } else { 
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateDLACReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">DLAC Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date of DLAC Conducted *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="select_dlac"
                            >Select the DLAC Conducted Quarter *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Select`}
                                    type="number"
                                    items={DLAC}
                                    action="dropdown"
                                    name="select_dlac"
                                    value={values.select_dlac}
                                    error={errors.select_dlac}
                                    onChange={(text) =>handleTextChange("select_dlac", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_participants"
                            >Number of participants *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of participants`}
                                    type="number"
                                    name="no_of_participants"
                                    value={values.no_of_participants}
                                    error={errors.no_of_participants}
                                    onChange={(e) =>handleTextChange("no_of_participants", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="documents"
                            >Upload Meeting Minutes : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Meeting Minutes`}
                                    type="file"
                                    name="documents"
                                    error={errors.documents}
                                    onChange={(e) =>handleTextChange("documents",e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateDLACReports: (data) => dispatch(createDLACReports(data)),
    onEditDLACReports: (data, record_id) => dispatch(editDLACReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(DLACReport);