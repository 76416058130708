import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createFoodInNewspaperReport, editFoodInNewspaperReport } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";

const FoodInNewspaperReport = (props) => {
    const { districts, user, wards, onCreateFoodInNewspaperReport, onEditFoodInNewspaperReport } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'name_of_fso' : '',
        'fso_area_code' : '',
        'no_of_inspected' : '',
        'no_of_shops_found_wrapping_food_in_newspaper' : '',
        'destroyed_qty_of_food_wrapping_food_in_newspaper' : '',
        'approximate_value_of_destroyed_food' : '',
        'no_of_fbos_given_notice' : ''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(recordData);
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'name_of_fso', 'fso_area_code', 'no_of_inspected',
        'no_of_shops_found_wrapping_food_in_newspaper', 'destroyed_qty_of_food_wrapping_food_in_newspaper',
        'approximate_value_of_destroyed_food', 'no_of_fbos_given_notice'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {    
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditFoodInNewspaperReport(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/food-in-newspaper-list/${values.id}`, { records: values });
            };

        } else {   
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateFoodInNewspaperReport(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Food Wrapped In Newspaper Inspection Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_fso"
                            >Name of FSO : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of FSO`}
                                    type="text"
                                    name="name_of_fso"
                                    value={values.name_of_fso}
                                    error={errors.name_of_fso}
                                    onChange={(e) =>handleTextChange("name_of_fso", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fso_area_code"
                            >Area code : </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.fso_area_code}
                                        error={errors.fso_area_code}
                                        items={wards}
                                        onChange={(value) => handleTextChange("fso_area_code", value)}
                                />
                        </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_inspected"
                            >Number of Inspected : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Inspected`}
                                    type="number"
                                    name="no_of_inspected"
                                    value={values.no_of_inspected}
                                    error={errors.no_of_inspected}
                                    onChange={(e) =>handleTextChange("no_of_inspected", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" 
                            htmlFor="no_of_shops_found_wrapping_food_in_newspaper"
                            >Number of Shops found wrapping food in newspaper : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Shops found wrapping food in newspaper`}
                                    type="number"
                                    name="no_of_shops_found_wrapping_food_in_newspaper"
                                    value={values.no_of_shops_found_wrapping_food_in_newspaper}
                                    error={errors.no_of_shops_found_wrapping_food_in_newspaper}
                                    onChange={(e) =>handleTextChange("no_of_shops_found_wrapping_food_in_newspaper", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="destroyed_qty_of_food_wrapping_food_in_newspaper"
                            >Destroyed Qty of food wrapping in newspaper (in Kgs) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Destroyed Qty of food wrapping in newspaper`}
                                    type="number"
                                    name="destroyed_qty_of_food_wrapping_food_in_newspaper"
                                    value={values.destroyed_qty_of_food_wrapping_food_in_newspaper}
                                    error={errors.destroyed_qty_of_food_wrapping_food_in_newspaper}
                                    onChange={(e) =>handleTextChange("destroyed_qty_of_food_wrapping_food_in_newspaper", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="approximate_value_of_destroyed_food"
                            >Approximte value of detroyed food (in Rs) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Approximte value of detroyed food`}
                                    type="number"
                                    name="approximate_value_of_destroyed_food"
                                    value={values.approximate_value_of_destroyed_food}
                                    error={errors.approximate_value_of_destroyed_food}
                                    onChange={(e) =>handleTextChange("approximate_value_of_destroyed_food", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_fbos_given_notice"
                            >Number of FBO's given notice : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of FBO's given notice`}
                                    type="number"
                                    name="no_of_fbos_given_notice"
                                    value={values.no_of_fbos_given_notice}
                                    error={errors.no_of_fbos_given_notice}
                                    onChange={(e) =>handleTextChange("no_of_fbos_given_notice", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFoodInNewspaperReport: (data) => dispatch(createFoodInNewspaperReport(data)),
    onEditFoodInNewspaperReport: (data, record_id) => dispatch(editFoodInNewspaperReport(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards
});
  
export default connect(mapStateToProps, mapDispatchToProps)(FoodInNewspaperReport);