import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getSlaughterHouseReports } from "../../components/reducers/actions"
import DataTable from "react-data-table-component";
import TextInput from "../../components/inputs/TextInput";
import Datepicker from "../../components/inputs/Datepicker"

const SlaughterHouseList = (props) => {
    const { districts, onGetSlaughterHouseReports } = props;
    const [values, setValues] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const resp = await onGetSlaughterHouseReports()
            if(resp && resp.response) {
              const data = resp.response
              setValues(data)
            }
        }
        fetchData();         
        // eslint-disable-next-line   
    }, [])

    const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        fontSize: "18px",
        paddingLeft: "30px",
        paddingRight: "30px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        color: "#45b151",
        paddingLeft: "25px",
        paddingRight: "30px",
        fontSize: "16px",
        background: "rgba(70,179,84,.10196078431372549)",
      },
    },
    cells: {
      style: {
        paddingLeft: "25px",
      },
    },
  };

    const getDistrict = (district) => {
      let districtName = null;
      districts &&
        districts.forEach((item) => {
          if (item.id === district) {
            districtName = item.name;
          }
        });
      return districtName;
    };

    const columns = [
      {
        name: "District",
        cell: (row) => getDistrict(row.district),
        selector: "district",
        sortable: true
      },
      
      {
        name: "Created At",
        cell: (row) =>
         row.created_at && moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm"),
        selector: "created_at",
        sortable: true,
      },
      {
        name: "Action",
        cell: (row) =>
        moment().diff(moment(row.created_at.toString()), 'hours') < 24 ? 
        (<button className="btn btn-success btn-sm btn-circle">
        <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        </button>) : "-",
        sortable: true,
      }

    ];

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Slaughter House Reports List</h1>
            <div className="main">
                <div className="row" style={{ marginBottom : 20 }}>
                  <div className="col-sm-4">
                    <TextInput
                        placeholder={`Search district`}
                        type="text"
                        name="district"
                        value={values.district}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Datepicker
                        placeholder={`Search Date`}
                        name="date"
                        id="date"
                        value={values.date}
                    />
                  </div>
                  <div className="col-sm-4">
                    <button
                        type="button"
                        className="btn btn-secondary ml-3">
                    View</button>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={values}
                  customStyles={customStyles}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={false}
                  pagination
                  highlightOnHover
                />
          </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
  onGetSlaughterHouseReports: () => dispatch(getSlaughterHouseReports())
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SlaughterHouseList);