import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { validateEmail } from "../../utils/validateEmail";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Checkbox from "../../components/inputs/Checkbox";
import { doCreateUser, doUpdateUser, getExistingUser, testingLab, UserUpdateUser } from "../../components/reducers/actions";
import UploadImageInput from "../../components/inputs/UploadImageInput";
import ClipLoader from "react-spinners/ClipLoader";

const AddUser = (props) => {
  const { districts, user, wards, onCreateUser,onUpdateUsernew,ongetLabs, onUpdateUser,ongetOldUser } = props;
  const { t } = useTranslation();
  const [values, setValues] = useState({
    country_code: "+91",
    secondary_numbers: [],
    user_types: new Map(),
  });
  const [isSpinner, setIsSpinner] = useState(false);
  const [updateField, setupdateField] = useState([]);
  const [usertyp, setUserType]=useState();
  const [labs,setLab]=useState([]);
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isRequiredDistrict, setIsRequiredDistrict] = useState(false);
  const [first, setFirst] = useState(false)
  districts.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const languages = [
    {
      value: "tamil",
      label: "தமிழ்",
    },
    {
      value: "english",
      label: "English",
    },
  ];

  let userTypes = [];

  if (user && user.user_types.length > 0 && user.user_types.includes("do")) {
    userTypes = [
      {
        value: "fso",
        label: "Food Safety Officer",
      },
    ];
  } else {
    userTypes = [
      {
        value: "commissioner",
        label: "Commissioner",
      },
      {
        value: "commissionerate",
        label: "Commissionerate",
      },
        
      {
        value: "ac",
        label: "Director and Additional Commissioner",
      },
      // {
      //   value: "director",
      //   label: "Director",
      // },
      {
        value: "dd",
        label: "Deputy Director",
      },

      {
        value: "ho",
        label: "Health Officer",
      },
      {
        value: "do",
        label: "Designated Officer",
      },
      {
        value: "twoapp",
        label: "APP-II",
      },
      {
        value: "ddim",
        label: "DDIM",
      },
      {
        value: "oneapp",
        label: "APP-I",
      },
      {
        value: "so",
        label: "Section Official",
      },
      {
        value: "AO",
        label: "Adjudication Officer",
      },
      {
        value: "apt",
        label: "Appellate tribunal",
      },
      {
        value: "labapp",
        label: "APP-II(Lab)",
      },
      {
        value: "labadmin",
        label: "Lab Admin",
      },
      {
        value: "fso",
        label: "Food Safety Officer",
      },
      {
        value: "cfa",
        label: "Chief food analyst",
      },
    ];
  }
  const getLabots=async()=>{
    const resp = await ongetLabs()
    console.log(resp)
    setLab(resp.response)
}

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ["phone_number", "name", "email", "language"];
    let requiredDistrict = false;
    for (const [key, value] of values.user_types.entries()) {
      if ((key === "do" && value) || (key === "fso" && value)) {
        requiredDistrict = true;
      }
    }
    if (requiredDistrict) {
      keys.push("district");
      setIsRequiredDistrict(true);
    } else {
      keys.slice(keys.indexOf("district"), 0);
      setIsRequiredDistrict(false);
    }
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    if (values.email && !validateEmail(values.email)) {
      updatedErrors.email = "Invalid email address";
    }

    if (
      values.phone_number &&
      (!values.phone_number || values.phone_number.length !== 10)
    ) {
      updatedErrors.phone_number = "Invalid phone number";
    }

    if (values.password && values.password.length < 6) {
      updatedErrors.password = t("Minimum 6 characters required");
    }

    if (values.country_code && values.country_code.length < 2) {
      updatedErrors.phone_number = t(
        "Country code should at least be 2 characters"
      );
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };
useEffect(()=>{
    getLabots()
},[])
  useEffect(() => {
    console.log(props.history.location && props.history.location.state)
    if (props.history.location && props.history.location.state) {
      const user = props.history.location.state.user;
      
      let user_types = new Map();
      userTypes.forEach((item) => {
        if (user.user_types.includes(item.value)) {
          user_types.set(item.value, true);
        } else {
          user_types.set(item.value, false);
        }
      });
      // let objArr = user.lab_access.map((i,indx)=>{return({'id':indx,'name':i})})
      let userData = {
        ...user,
        user_types: user_types,
        lab_access:(user.user_types=='cfa'||user.user_types=='labapp'||user.user_types=='labadmin')?user.lab_access[0]:null,
        lab_district:(user.user_types=='so'||user.user_types=='twoapp'||user.user_types=='oneapp')?user.lab_district:null,
        profile_picture: user.profile_picture
          ? `${user.profile_picture}`
          : "",
        secondary_numbers:
          user.secondary_numbers && user.secondary_numbers.length > 0
            ? JSON.stringify(user.secondary_numbers)
            : "",
      };
      setUserType(user.user_types[0]?user.user_types[0]:'')
      setValues(userData);
      validateValues(userData);
    }
    // eslint-disable-next-line
  }, []);

  const handleTextChange = (key, value) => {
    let updatedValues = { ...values, [key]: value };
    setupdateField([...updateField, key]);
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  const handleCheckboxChange = (key, value) => {
    let updatedValues = {
      ...values,
      user_types: values.user_types.set(key, value),
    };
    setupdateField([...updateField, "user_types"]);
    setValues(updatedValues);
    validateValues(updatedValues);
    for (const [key, value] of updatedValues.user_types.entries()) {
      if (key && value) {
        setUserType(key)
        console.log(key)
        }
    }
  };

  const handleRemoveImages = (key, value) => {
    const updatedValues = { ...values };
    delete updatedValues[key];
    setValues(updatedValues);
  };

  const handleUpdate=async()=>{
    setIsSpinner(true);
    console.log("Submit button clicked");
    const updatedErrors = validateValues(values);
    console.log(updatedErrors);
    if (Object.keys(updatedErrors).length === 0) {
      let user_types_data = [];
      for (const [key, value] of values.user_types.entries()) {
        if (key && value) {
          user_types_data.push(key);
        }
      }
        var data = {};
        updateField.map((key) => {
          if (key === "profile_picture" && !values[key]) {
            data[key] = "";
          } else if (key === "user_types") {
            data[key] = user_types_data;
          } else if (key === "secondary_numbers") {
            data[key] = JSON.parse(values[key]);
          } else {
            data[key] = values[key];
          }
          return false;
        });
        data["user_id"] = values.id;
        delete data['phone_number']
        delete data['email']
        const resp = await onUpdateUsernew(data);
        if (resp && resp.data === "success") {
          setIsSpinner(false);
          props.history.push("/app/users/");
        } else if (resp && resp.error) {
          setErrors(resp.error);
        }
      }
  }
  const handleSubmitButton = async () => {
    setIsSpinner(true);
    console.log("Submit button clicked");
    const updatedErrors = validateValues(values);
    console.log(updatedErrors);
    if (Object.keys(updatedErrors).length === 0) {
      let user_types_data = [];
      for (const [key, value] of values.user_types.entries()) {
        if (key && value) {
          user_types_data.push(key);
        }
      }

      if (props.history.location && props.history.location.state) {
        var data = {};
        updateField.map((key) => {
          if (key === "profile_picture" && !values[key]) {
            data[key] = "";
          } else if (key === "user_types") {
            data[key] = user_types_data;
          } else if (key === "secondary_numbers") {
            data[key] = JSON.parse(values[key]);
          } 
          else {
            data[key] = values[key];
          }
          return false;
        });
        data["user_id"] = values.id;
        if(typeof(data.lab_access)=='string'){
          data.lab_access=[data.lab_access]
        }
        // if(data.lab_access_){
        //   data.lab_access=data.lab_access_
        // }
        // if(data.user_types=='APP-II(Lab)'){
        //   data.lab_access=values.lab_access_
        // }
        console.log(data)
        const resp = await onUpdateUser(data);
        if (resp && resp.data === "success") {
          setIsSpinner(false);
          props.history.push("/app/users/");
        } else if (resp && resp.error) {
          setErrors(resp.error);
        }
      } else {
        const data = {
          ...values,
          user_types: user_types_data,
        };
        if(typeof(data.lab_access)=='string'){
          data.lab_access=[data.lab_access]
        }
        // if(data.user_types=='APP-II(Lab)'){
        //   data.lab_access=data.lab_access_
        // }
        console.log(data)
        const resp = await onCreateUser(data);
        if (resp && resp.data === "success") {
          setIsSpinner(false);
          props.history.push("/app/users/");
        } else if (resp && resp.error) {
          const dat={
            phone_number:values.phone_number,
            email:values.email
          }
            const res = await ongetOldUser(dat)
            if(res && res.response){
              if(res.response.length !==0){
                setFirst(true)
                const data = res.response[0]
                let user_types = new Map();
                userTypes.forEach((item) => {
                  if (data.user_types.includes(item.value)) {
                    user_types.set(item.value, true);
                  } else {
                    user_types.set(item.value, false);
                  }
                  
                });
                let userData = {
                  ...data,
                  user_types: user_types,
                  profile_picture: data.profile_picture
                    ? `${data.profile_picture}`
                    : "",
                  secondary_numbers:
                    data.secondary_numbers && data.secondary_numbers.length > 0
                      ? JSON.stringify(data.secondary_numbers)
                      : "",
                };
                setValues(userData)
              }
            }
          setErrors(resp.error);
          setIsSpinner(false);
        }
      }
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="h4 mb-4 text-gray-800">{t(props.title)}</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          {values &&
          values.user_types[0] !== '' &&
          values.user_types.get("fso") &&
          values.wards &&
          values.wards.length === 0 ? (
            <div className="user_info__warning">
              <p>
                **{" "}
                {t(
                  "This FSO does not have any wards associated. Associate wards to this FSO, for complaints to auto assign"
                )}{" "}
                **
              </p>
            </div>
          ) : null}
          <Form>
            <div className="form-group col-sm-3">
              <UploadImageInput
                size={100}
                photo={values.profile_picture}
                name="profile_picture"
                enableDelete={true}
                handleRemoveImages={(text) =>
                  handleRemoveImages("profile_picture", text)
                }
                onChangeText={(value) =>
                  handleTextChange("profile_picture", value)
                }
              />
            </div>
            <div className="form-group">
              <TextInput
                placeholder={t("Name *")}
                type="text"
                name="name"
                value={values.name}
                error={errors.name}
                onChange={(e) => handleTextChange("name", e.target.value)}
              />
            </div>
            <div className="form-group">
              <TextInput
                placeholder={t("Email Address *")}
                type="email"
                name="email"
                value={values.email}
                error={errors.email}
                onChange={(e) => handleTextChange("email", e.target.value)}
              />
            </div>
            <div className="form-group">
              <TextInput
                placeholder={t("10 digit Phone Number *")}
                type="text"
                name="phone_number"
                value={values.phone_number}
                error={errors.phone_number}
                onChange={(e) =>
                  handleTextChange("phone_number", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <TextInput
                placeholder={t("Secondary numbers")}
                type="text"
                name="secondary_numbers"
                value={values.secondary_numbers}
                error={errors.secondary_numbers}
                onChange={(e) =>
                  handleTextChange("secondary_numbers", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <DropDownInput
                placeholder={t("Language")}
                type="text"
                name="language"
                value={values.language}
                error={errors.language}
                items={languages}
                action="language"
                onChange={(text) => handleTextChange("language", text)}
              />
            </div>
            <div className="form-group user__types_list">
              <label>User Types :</label>
              <ul>
                {userTypes.map((item, idx) => (
                  <li key={idx}>
                    <div className="pr-3">
                      <Checkbox
                        disabled={
                          user &&
                          user.user_types &&
                          user.user_types.includes("do")
                            ? true
                            : false
                        }
                        placeholder={item.label}
                        name={item.value}
                        value={values.user_types.get(item.value) || false}
                        onChange={(text) =>
                          handleCheckboxChange(item.value, text)
                        }
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="form-group">
            {(usertyp&& usertyp==='cfa'||usertyp&& usertyp==='labapp'||usertyp&& usertyp==='labadmin')&&labs.length!==0?
             <DropDownInput
             placeholder={`Select Lab`}
             type="text"
             name="district"
             action='dropdown'
             value={values.lab_access}
             error={errors.lab_access}
             items={labs}
             onChange={(text) => handleTextChange("lab_access", text)}
           />: (usertyp&&usertyp==="so"||usertyp&&usertyp==="twoapp"||usertyp&&usertyp==="oneapp")?
           <DropDownInput
           placeholder={t(`District ${isRequiredDistrict ? "*" : ""}`)}
           type="text"
           name="dropdown"
           value={values.lab_district}
           error={errors.lab_district}
           action='district'
           items={districts}
           onChange={(text) => handleTextChange("lab_district", text)}
         />:
            <DropDownInput
                placeholder={t(`District ${isRequiredDistrict ? "*" : ""}`)}
                type="text"
                name="dropdown"
                value={values.district}
                error={errors.district}
                isDisabled={
                  user && user.user_types && user.user_types.includes("do")
                    ? true
                    : false
                }
                items={districts}
                onChange={(text) => handleTextChange("district", text)}
              />}
            </div>
            <div className="form-group">
              <DropDownInput
                placeholder={t("Wards")}
                type="text"
                name="wards"
                isDisabled={
                  values && values.user_types && values.user_types.get("fso")
                    ? false
                    : true
                }
                value={values.wards}
                error={errors.wards}
                items={wards}
                action="wards"
                onChange={(text) => handleTextChange("wards", text)}
              />
            </div>
            <div className="form-group">
              <Checkbox
                placeholder="Verified"
                name="is_verified"
                value={values.is_verified || false}
                onChange={(text) => handleTextChange("is_verified", text)}
              />
            </div>
            <div className="form-group">
              <Checkbox
                placeholder="Posted as incharge"
                name="posted_as_incharge"
                value={values.posted_as_incharge || false}
                onChange={(text) =>
                  handleTextChange("posted_as_incharge", text)
                }
              />
            </div>
            <div className="form-group">
              <Checkbox
                placeholder="Active"
                name="is_active"
                value={values.is_active || false}
                onChange={(text) => handleTextChange("is_active", text)}
              />
            </div>
            <div className="form-group">
              <button
                onClick={() => props.history.push("/app/users/")}
                className="btn btn-success"
                type="button"
              >
                {t("Cancel")}
              </button>
              {first ? (
                <button
                disabled={disableSubmit}
                onClick={handleUpdate}
                type="button"
                className="btn btn-success ml-3"
              >
                <ClipLoader
                  color="#fff"
                  loading={isSpinner}
                  size={15}
                  css={{ marginRight: "10px" }}
                />
                {t("Update")}
              </button>):(
                <button
                disabled={disableSubmit}
                onClick={handleSubmitButton}
                type="button"
                className="btn btn-success ml-3"
              >
                <ClipLoader
                  color="#fff"
                  loading={isSpinner}
                  size={15}
                  css={{ marginRight: "10px" }}
                />
                {t("Submit")}
              </button>
              )
              }
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onCreateUser: (data) => dispatch(doCreateUser(data)),
  onUpdateUser: (data) => dispatch(doUpdateUser(data)),
  onUpdateUsernew: (data) => dispatch(UserUpdateUser(data)),
  ongetOldUser: (data) => dispatch(getExistingUser(data)),
  ongetLabs: (data) => dispatch(testingLab(data)),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  districts: state.home.districts,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
