import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createFssaactReports, editFssaactReports } from "../../components/reducers/actions"
import Datepicker from "../../components/inputs/Datepicker";
import { useParams } from "react-router-dom";
import { getFsoOfficers } from "../../components/reducers/actions"
import TextAreaInput from '../../components/inputs/TextAreaInput';

const FssaactReport = (props) => {
    const { districts, user, wards, foodTypes, traderTypes,wardsCategory,onGetFsoOfficers, fsoOfficers, onCreateFssaactReports, onEditFssaactReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'name_of_block' : '',
        'area_code' : '',
        'sample_no' : '',
        'name_of_fso' : '',
        'nature_of_food' : '',
        'category_of_food' : '',
        'category_of_trader' : '',
        'date_of_sampling' : '',
        'name_of_laboratory' : '',
        'address_of_sample_lifted':'',
        'name_of_owner':'',
        'name_of_incharge':'',
        'smaple_analysed':'',
        'sample_ignored':'',
        'sample_confirmed':'',
        'sample_not_confirmed':'',
        'sample_not_con':"",
        'confirmed_with_sec63':'',
        'other_sample':'',

    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    const NotConfirmed=[
        {id:'0',name:'Unsafe'},
        {id:'1',name:'Substranded'},
        {id:'2',name:'Misleading'},
        {id:'3',name:'Misbranded'},
        {id:'4',name:'Others'},
    ]
    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            values.category_of_trader = parseInt(values.category_of_trader)
            values.name_of_fso = parseInt(values.name_of_fso)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
            getFsoList();

        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    name_of_block: o.ward_name
                })
            )
        })
    },[values.area_code])
    const getFsoList = async () => {
        await onGetFsoOfficers({});
      };
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'name_of_block', 
        'area_code', 'sample_no', 'name_of_fso', 'nature_of_food',
        'category_of_food','category_of_trader','date_of_sampling','name_of_laboratory'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditFssaactReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/fssaact-list/`);
            };

        } else {       
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateFssaactReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }
    return (
        <div>
            <div className="container-fluid">
                <h1 className="h3 mb-4 text-gray-800">FSSA-ACT Samples Report</h1>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >Name of the district : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Name of the district`}
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                            ) : (
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="district"
                                    >Name of the district : </label>
                                    <div className="col-sm-8">
                                        <DropDownInput
                                            placeholder={`Name of the district`}
                                            type="text"
                                            name="district"
                                            value={values.district}
                                            error={errors.district}
                                            items={districts}
                                            onChange={(text) => handleTextChange("district", text)}
                                        />
                                    </div>
                                </div>
                            )}
                             <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                                >Area code *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="name_of_block"
                                >Area Name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Name of the block / mpty / corpn`}
                                        type="text"
                                        name="name_of_block"
                                        value={values.name_of_block}
                                        error={errors.name_of_block}
                                        onChange={(e) => handleTextChange("name_of_block", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="address_of_sample_lifted"
                                >Address of sample lifted *: </label>
                                <div className="col-sm-8">
                                    <TextAreaInput
                                        placeholder={`Address of sample lifted`}
                                        type="text"
                                        name="address_of_sample_lifted"
                                        value={values.address_of_sample_lifted}
                                        error={errors.address_of_sample_lifted}
                                        onChange={(e) => handleTextChange("address_of_sample_lifted", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="name_of_owner"
                                >Name of the owner *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Name of the owner`}
                                        type="text"
                                        name="name_of_owner"
                                        value={values.name_of_owner}
                                        error={errors.name_of_owner}
                                        onChange={(e) => handleTextChange("name_of_owner", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="name_of_incharge"
                                >Name of the Incharge *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Name of the Incharge`}
                                        type="text"
                                        name="name_of_incharge"
                                        value={values.name_of_incharge}
                                        error={errors.name_of_incharge}
                                        onChange={(e) => handleTextChange("name_of_incharge", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_of_sampling"
                                >Date of sampling *: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Date of sampling`}
                                        name="date_of_sampling"
                                        id="date_of_sampling"
                                        value={values.date_of_sampling}
                                        error={errors.date_of_sampling}
                                        onChangeText={(text) => handleTextChange('date_of_sampling', text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sample_no"
                                >Sample no / year *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Sample no / year`}
                                        type="text"
                                        name="sample_no"
                                        value={values.sample_no}
                                        error={errors.sample_no}
                                        onChange={(e) => handleTextChange("sample_no", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="name_of_fso"
                                >Name of the food safety officer *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Name of the food safety officer`}
                                        name="name_of_fso"
                                        action="fsos"
                                        value={values.name_of_fso}
                                        error={errors.name_of_fso}
                                        items={fsoOfficers}
                                        onChange={(value) => handleTextChange("name_of_fso", value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="nature_of_food"
                                >Nature of the food sample with brand name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Nature of the food sample with brand name`}
                                        type="text"
                                        name="nature_of_food"
                                        value={values.nature_of_food}
                                        error={errors.nature_of_food}
                                        onChange={(e) => handleTextChange("nature_of_food", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="category_of_food"
                                >Category of the Food Stuff *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`category of the food`}
                                        type="text"
                                        name="category_of_food"
                                        value={values.category_of_food}
                                        error={errors.category_of_food}
                                        items={foodTypes}
                                        onChange={(text) => handleTextChange("category_of_food", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="category_of_trader"
                                >Category of traders *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Category of traders`}
                                        type="text"
                                        name="category_of_trader"
                                        value={values.category_of_trader}
                                        error={errors.category_of_trader}
                                        items={traderTypes}
                                        onChange={(text) => handleTextChange("category_of_trader", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="name_of_laboratory"
                                >Name of the laboratory where the analysis done *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Name of the laboratory where the analysis done`}
                                        type="text"
                                        name="name_of_laboratory"
                                        value={values.name_of_laboratory}
                                        error={errors.name_of_laboratory}
                                        onChange={(e) => handleTextChange("name_of_laboratory", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="smaple_analysed"
                                >Number of sample analysed *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of sample analysed`}
                                        type="number"
                                        name="smaple_analysed"
                                        value={values.smaple_analysed}
                                        error={errors.smaple_analysed}
                                        onChange={(e) => handleTextChange("smaple_analysed", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sample_ignored"
                                >Number of sample ignored *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of sample ignored`}
                                        type="number"
                                        name="sample_ignored"
                                        value={values.sample_ignored}
                                        error={errors.sample_ignored}
                                        onChange={(e) => handleTextChange("sample_ignored", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sample_confirmed"
                                >Number of sample confirmed *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of sample confirmed`}
                                        type="number"
                                        name="sample_confirmed"
                                        value={values.sample_confirmed}
                                        error={errors.sample_confirmed}
                                        onChange={(e) => handleTextChange("sample_confirmed", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sample_not_confirmed"
                                >Number of sample not-confirmed *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of sample not-confirmed`}
                                        type="number"
                                        name="sample_not_confirmed"
                                        value={values.sample_not_confirmed}
                                        error={errors.sample_not_confirmed}
                                        onChange={(e) => handleTextChange("sample_not_confirmed", e.target.value)}
                                    />
                                </div>
                            </div>
                            {values && values.sample_not_confirmed && (
                                   <div className="form-group row">
                                   <label className="control-label col-sm-4 text-label" htmlFor="sample_not_con"
                                   >Sample not-confirmed *: </label>
                                   <div className="col-sm-8">
                                       <DropDownInput
                                           placeholder={`Number of sample not-confirmed`}
                                           name="sample_not_con"
                                           items={NotConfirmed}
                                           action='dropdown'
                                           value={values.sample_not_con}
                                           error={errors.sample_not_con}
                                           onChange={(text) => handleTextChange("sample_not_con", text)}
                                       />
                                   </div>
                               </div>
                            )}
                                 {values && values.sample_not_con.includes('Others')&&(
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="other_sample"
                                >Other sample *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Other sample`}
                                        type="number"
                                        name="other_sample"
                                        value={values.other_sample}
                                        error={errors.other_sample}
                                        onChange={(e) => handleTextChange("other_sample", e.target.value)}
                                    />
                                </div>
                            </div>  
                               )}
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="confirmed_with_sec63"
                                >Number of sample confirmed with section 63*: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of sample confirmed with section 63`}
                                        type="number"
                                        name="confirmed_with_sec63"
                                        value={values.confirmed_with_sec63}
                                        error={errors.confirmed_with_sec63}
                                        onChange={(e) => handleTextChange("confirmed_with_sec63", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => ({
    onCreateFssaactReports: (data) => dispatch(createFssaactReports(data)),
    onEditFssaactReports: (data, record_id) => dispatch(editFssaactReports(data, record_id)),
    onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data))

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    traderTypes: state.home.traderTypes,
    wards: state.home.wards,
    foodTypes: state.home.foodTypes,
    fsoOfficers: state.home.fsoOfficers,
    wardsCategory:state.home.wardsCategory,

});

export default connect(mapStateToProps, mapDispatchToProps)(FssaactReport)
