import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Header from '../components/Header';
import { getFaqCategory } from '../components/reducers/actions';
import { useTranslation } from 'react-i18next';
import Accordion from '../components/Accordion/Accordion';
import FaqAccordion from '../components/FaqAccordion/FaqAccordion';

const Faq = (props) => {

  const { faqCategories, onGetFaqCategory } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const getAssets = () => {
      onGetFaqCategory();
    }
    getAssets();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header activeMenu="Faqs" />
      <div id="main">
        <section>
          <div className="faqs__list__main">
            <h2>{t('Frequently Asked Questions')}</h2>
            <div className="faqs__list__container">
              {faqCategories && faqCategories.map((panel, index) =>
                panel.faqs.length > 0 && <Accordion
                  key={index}
                  title={panel.name}
                >
                  <FaqAccordion hideTitle={true} panels={panel.faqs} />
                </Accordion>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  onGetFaqCategory: () => dispatch(getFaqCategory()),
});

const mapStateToProps = state => ({
  faqCategories: state.home.faqCategories,
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
