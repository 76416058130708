import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Image, Form, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import PasswordInput from "../components/inputs/PasswordInput";
import TextInput from "../components/inputs/TextInput";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { doLogin,  logout, multiCheck} from "../components/reducers/actions";
import bcrypt from 'bcryptjs'
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap'
import blue from '../assets/img/bluelogo.jpg'
import { Helmet } from "react-helmet"
import moment from "moment";
var CryptoJS = require("crypto-js");

const Login = (props) => {
  const [values, setValues] = useState({
    country_code: "+91",
    password: "",
    phone_number: "",
  });
  const [errors, setErrors] = useState({});
  const [requestErrors, setRequestErrors] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [toggle, setToggle] = useState(false)
  const salt = bcrypt.genSaltSync(10)
  const { t } = useTranslation();
  const {user} = props
  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ["phone_number", "password"];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    if (
      values.phone_number &&
      (!values.phone_number || values.phone_number.length !== 10)
    ) {
      updatedErrors.phone_number = t("Invalid phone number");
      if (showPassword) {
        setShowPassword(false);
      }
    } else if (!updatedErrors.phone_number) {
      if (!showPassword) {
        setShowPassword(true);
      }
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };
  const getLogout = async () => {
    const resp = await props.onLogout();
    if (resp && resp.response) {
      localStorage.clear();
      if (user && user.user_types && user.user_types.length > 0) {
        window.location.href = "/login/";
      } else {
        window.location.href = "/login/";
      }
    }
  };
 
  useEffect(() => {
    if (window.performance) {
      console.info("window.performance is supported");
      console.info(performance.navigation.type);
    
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
        console.info( "This page is reloaded" );
        getLogout()
      } else {
        console.info( "This page is not reloaded");
      }
    }
}, [window.performance]);


const logUser = async() => {
  setToggle(false)
  var message = values.password;
  var key ='AAAAAAAAAAAAAAAA'
  key = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.AES.encrypt(message, key, {mode: CryptoJS.mode.ECB});
  encrypted =encrypted.toString();
  // const enc = bcrypt.hashSync(values.password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
    var data = { ...values };
    data.country_code = "+91";
    data.password = encrypted
    data.decrypt_check='Yes'
    data.source="web"
    const resp = await props.onLogin(data);
    if (resp && resp.response) {
        console.log(resp)
        if(resp && resp.response&& resp.response.multiple_login === true){
          setToggle(true)
          window.location.href = "/app/complaints";
        }
        else 
         window.location.href = "/app/complaints";
       
    } else {
      let message = "Something went wrong. Please try again";
      if (resp.error && resp.error.data && resp.error.data.non_field_errors) {
        message = resp.error.data.non_field_errors[0];
      } else if (
        resp.error &&
        resp.error.data &&
        resp.error.data.message &&
        !resp.error.data.is_active
      ) {
        message = resp.error.data.message;
      } else if (resp.error && resp.error.data && resp.error.data.message) {
        message = resp.error.data.message;
        props.history.push({
          pathname: "/otp-verification/",
          state: {
            phone_number: values.phone_number,
          },
        });
      }
      if(message === "Multiple Attempts"){
        props.history.push({
          pathname: "/forgot-password",
          state: {
            phoneNumber: values.phone_number,
            countryCode: values.country_code,
            action: "forgotPassword",
          },
        });
      }
     else setRequestErrors(message);
    }
}
  const handleSubmit = async (event) => {
    event.preventDefault();
   
      logUser()
  };

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    setRequestErrors(null);
    validateValues(updatedValues);
  };

  return (
    <>
     <Helmet>
     <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.1.1/crypto-js.min.js"></script>
      </Helmet>
      <Header activeMenu="Login" />
      <div className="container p-5">
        <div className="card o-hidden border-0 shadow-lg my-4">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <Image
                  style={{ width: "100%", height: "auto" }}
                  // src="https://ww1.prweb.com/prfiles/2017/11/07/14894968/Food-Safety.jpg"
                  src={blue}
                ></Image>
              </div>
              <div className="col-lg-6">
                <div className="p-5 my-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">
                      {t("Welcome Back")}!
                    </h1>
                  </div>
                  <div>
                    {requestErrors ? (
                      <Alert variant="danger">{requestErrors}</Alert>
                    ) : null}
                  </div>
                  <div>
                        <Modal isOpen={toggle}
                        style={{width:'300px'}}
                        >
                          <ModalHeader  >
                          <div style={{display:'flex', flexDirection:'row', textAlign:'center'}}> Multiple Login Detected <i className="fas fa-exclamation-triangle" style={{color:'red', marginTop:10, marginLeft:20, fontSize:30}}></i></div>
                          </ModalHeader>
                          <ModalBody >
                              <p>Your Previous session will be logged out!<br/>
                               Continue here!</p>
                              <Button style={{float:'right', backgroundColor:'green'}} onClick= {()=>window.location.href = "/app/complaints"}>Continue</Button>
                          </ModalBody>
                        </Modal>
                  </div>
                  <Form className="user" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <TextInput
                        placeholder={t("Phone number")}
                        type="text"
                        name="phone_number"
                        error={errors.phone_number}
                        value={values.phone_number}
                        onChange={(e) =>
                          handleTextChange("phone_number", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <PasswordInput
                        placeholder={t("Password")}
                        type="text"
                        name="password"
                        error={errors.password}
                        value={values.password}
                        onChange={(e) =>
                          handleTextChange("password", e.target.value)
                        }
                      />
                    </div>
                    {showPassword ? (
                      <div className="d-flex mb-2">
                        <div className="flex small text-right mb-2 w-100">
                          <Link
                            to={{
                              pathname: "/forgot-password",
                              state: {
                                phoneNumber: values.phone_number,
                                countryCode: values.country_code,
                                action: "forgotPassword",
                              },
                            }}
                          >
                            {t("Forgot Password")} ?
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    <button
                      disabled={disableSubmit}
                      type="submit"
                      className="btn btn-primary-green btn-user btn-block mb-3"
                    >
                      {t("Login")}
                    </button>
                    <span style={{ "text-align": "center" }}>
                      <p>OR</p>
                    </span>
                    {showPassword ? (
                      <Link
                        to={{
                          pathname: "/login-with-otp/",
                          state: {
                            phoneNumber: values.phone_number,
                            countryCode: values.country_code,
                          },
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary btn-user btn-block mb-3"
                        >
                          {t("Login with OTP")} ?
                        </button>
                      </Link>
                    ) : null}
                    <hr />
                    <div className="small text-center">
                      <Link to="/register">{t("Create an Account!")}</Link>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Login.propsType = {
  onLogin: PropTypes.func,
  onMultiCheck:PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onLogin: (loginUser) => dispatch(doLogin(loginUser)),
  onLogout: () => dispatch(logout()),
  onMultiCheck: (log) =>dispatch(multiCheck(log))

});

const mapStateToProps = (state) => ({
  user: state.home.user,
  error: state.home.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
