import React from 'react';
import { userDirectory } from "../../components/reducers/actions";
import { connect } from "react-redux";
import { useEffect, useMemo } from "react";
import Table from "react-bootstrap/Table";
import Header from "../../components/Header";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
// A great library for fuzzy filtering/sorting items
import {matchSorter} from 'match-sorter';
import MaterialTable from "material-table";


const AdjudicationDirectory = (props) => {
    const { t, i18n } = useTranslation();
  const { onUserDirectory,user } = props;
  const[users, setUsers]=useState();
  const [filt, setFilt] = useState();
  const [filts, setFilts] = useState();
  
const getValue=async()=>{
  const dt = {'groups_id':6}
  const resp = await onUserDirectory(dt)
  console.log(resp)
 if(resp.response){
   setUsers(Object.values(resp.response)[5])
   var obj = Object.values(resp.response)[5].reduce(function(acc, cur, i) {
    acc[cur.id] = cur.district;
    return acc;
  }, {});
  setFilt(obj)
  var obj1 = Object.values(resp.response)[5].reduce(function(acc, cur, i) {
    acc[cur.id] = cur.designations;
    return acc;
  }, {});
  console.log(obj1)
  setFilts(obj1)
  //  var val = {}
  //   val = Object.values(resp.response)[0].map((i=>i.district))
  //   console.log(val)
  //  setFilt(val)
  console.log(filt)

 }
 else{
   console.log('error')
 }
}
useEffect(()=>{
  getValue()
},[user])
  useEffect(() => {
    getValue()
  }, []);

    return (
      <div>
        <Header activeMenu="About" />
         <header className="page-header">
           <div className="container position-relative px-5 text-center text-white">
             <div className="row gx-5 justify-content-center">
               <div className="col-lg-8">
                 <h1 className="page-header-ui-title ">{t("DD")}</h1>
               </div>
             </div>
           </div>
         </header>
      {users ? (
        <div style={{width:'60%', fontSize:'16px'}} className="table_top use_dir">
          {/* <Styles>
          <Table columns={columns} data={users} />
          </Styles> */}
            <MaterialTable
          columns={[
            { title: `${t("Designation")}`, field: "designations", filtering:false},
            { title: `${t("Address")}`, field: "id", lookup:filt },
            { title: `${t("Email")}`, field: "email",filtering: false},
            {title: `${t("Contact Number")}`, field: "phone_number",filtering: false},
          ]}
          data={users}
          options={{
            search:true,
            filtering:true,
            pageSize:20,
            emptyRowsWhenPaging:false,
            pageSizeOptions:[20,30,40,50,60,70,80,90,100],
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF",
              borderColor:"#fff",
              textAlign:'center'
            },
          }}
          title={t("Adjudication Officer's Directory")}
        />
         </div>
    ):<div style={{margin:'0 auto'}}>Loading Contents...</div>}
    <div className='directory_links'>
      <Link to="/do-directory">{t("Click here for list of Designated officers")}</Link><br/>
      <Link to="/fso-directory">{t("Click here for list of Food Safety officers")}</Link><br/>
      <Link to="/lab-analyst-directory">{t("Click here for list of Food analyst")}</Link><br/>
      <Link to="/tribunal-officers-directory">{("Click here for list of Appellate Tribunal Officers")}</Link><br/>
       <Link to="/user-directory">{t("Click here for list of Commissionerate")}</Link><br/>
     </div>
    </div>
    );
};
const mapDispatchToProps = (dispatch) => ({
    onUserDirectory: (data) => dispatch(userDirectory(data)),
  });
  
  const mapStateToProps = (state) => ({
    user:state.home.user
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(AdjudicationDirectory);
