import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createFSODirectDetailsReports, editFSODirectDetailsReports, updateCivil, updateCriminal } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const Case = [
    {id:'0', name:'Civil'},
    {id:'1', name:'Criminal'}
]

const DirectCaseDetailsReport = (props) => {
    const { districts,wards, user,wardsCategory,ongetCivil,ongetCriminal,resultObtained,civilResults,criminalResults, onCreateFSODirectDetailsReports, onEditFSODirectDetailsReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'case_type':'',
        'direct_case_field_at_AO_court_on' : '',
        'direct_case_file_case_number':'',
        'direct_case_filed_at_court_app_on':'',
        'direct_case_filed_at_court_on':'',
        'direct_case_admitted_on':'',
        'direct_case_number':''
        
    });
    const [errors, setErrors] = useState({});
    const [civil,setCivil]=useState(false);
    const [criminal,setCriminal]=useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            if(props.history.location.state.action =='Civil')setCivil(true)
            if(props.history.location.state.action =='Criminal')setCriminal(true)

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
            // 'date',
            'area_code',
            "area_name",
            // 'case_type',
          
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            if(props.history.location.state.action =='Civil'){
                let id=props.history.location.state.values.sample_number_given_by_do
                const data = { ...values}
                let dat = civilResults
                let filt = civilResults.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter(i=>i)[0]
                dat[filt?filt:0].sample_status='Case filed at AO court'
                await ongetCivil(dat)
            }
           else{
            let id=props.history.location.state.values.sample_number_given_by_do
            const data = { ...values}
            let dat = criminalResults
            let filt = criminalResults.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter(i=>i)[0]
            dat[filt?filt:0].sample_status='Case filed at court'
            await ongetCriminal(dat)
           }
          
            // const resp = await onEditFSODirectDetailsReports(data, values.id);
            // if (resp && resp.response) {
            //     console.log(resp.response);
            //     props.history.push(`/app/display-reports/fso-directdetails-list/`);
            // };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateFSODirectDetailsReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Case Details Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div> */}
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_type"
                            >Case Type *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Type`}
                                    name="case_type"
                                    items={Case}
                                    action="dropdown"
                                    value={values.case_type}
                                    error={errors.case_type}
                                    onChange={(text) =>handleTextChange("case_type", text)}
                                />
                            </div>
                        </div> */}
                        {civil? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_field_at_AO_court_on"
                            > Case Field at AO Court On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={` case field at AO court on`}
                                    name="direct_case_field_at_AO_court_on"
                                    value={values.direct_case_field_at_AO_court_on}
                                    error={errors.direct_case_field_at_AO_court_on}
                                    onChangeText={(text) =>handleTextChange("direct_case_field_at_AO_court_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_file_case_number"
                            > Case File Case Number: </label>
                            <div className="col-sm-8">
                                <TextInput
                                      type="number"
                                    placeholder={` Case File Case Number`}
                                    name="direct_case_file_case_number"
                                    value={values.direct_case_file_case_number}
                                    error={errors.direct_case_file_case_number}
                                    onChange={(e) =>handleTextChange("direct_case_file_case_number", e.target.value)}
                                />
                            </div>
                        </div>
                         </> : null}
                       {criminal? <>
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_filed_at_court_app_on"
                            > Case Filed at Court APP On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={` Case Filed at Court APP on`}
                                    name="direct_case_filed_at_court_app_on"
                                    value={values.direct_case_filed_at_court_app_on}
                                    error={errors.direct_case_filed_at_court_app_on}
                                    onChangeText={(text) =>handleTextChange("direct_case_filed_at_court_app_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_filed_at_court_on"
                            > Case Filed at Court On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={` Case Filed at Court On`}
                                    name="direct_case_filed_at_court_on"
                                    value={values.direct_case_filed_at_court_on}
                                    error={errors.direct_case_filed_at_court_on}
                                    onChangeText={(text) =>handleTextChange("direct_case_filed_at_court_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_admitted_on"
                            > Case Admitted On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={` case admitted on`}
                                    type="text"
                                    name="direct_case_admitted_on"
                                    value={values.direct_case_admitted_on}
                                    error={errors.direct_case_admitted_on}
                                    onChangeText={(text) =>handleTextChange("direct_case_admitted_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_number"
                            > Case Number : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={` Case Number`}
                                    type="text"
                                    name="direct_case_number"
                                    value={values.direct_case_number}
                                    error={errors.direct_case_number}
                                    onChange={(e) =>handleTextChange("direct_case_number", e.target.value)}
                                />
                            </div>
                        </div>
                        </> : null}
                       
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFSODirectDetailsReports: (data) => dispatch(createFSODirectDetailsReports(data)),
    onEditFSODirectDetailsReports: (data, record_id) => dispatch(editFSODirectDetailsReports(data, record_id)),
    ongetCivil:(data)=>dispatch(updateCivil(data)),
    ongetCriminal:(data)=>dispatch(updateCriminal(data)),
  
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
    resultObtained:state.home.resultObtained,
    civilResults:state.home.civilResults,
    criminalResults:state.home.criminalResults,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(DirectCaseDetailsReport);