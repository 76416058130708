import React from "react";
import {
  getFilterData,
  getFsoOfficers,
  getoverall,
  getoverallWithout,
  userDirectory,
  getMasterSuccess,
  lineReportOpen,

} from "../../components/reducers/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import Header from "../../components/Header";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { date } from "yup";
import Datepicker from "../../components/inputs/Datepicker";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextInput from "../../components/inputs/TextInput";
import moment from "moment";

const LineOpen = (props) => {
  const { t, i18n } = useTranslation();
  const {
    ongetOverall,
    ongetOverallWithout,
    traderTypes,
    ongetFso,
    foodIssues,
    districts,
    foodTypes,
    fsoOfficers,
    ongetLineOpen,
    user,
  } = props;
  const [users, setUsers] = useState();
  const [errors, setErrors] = useState({});
  const [filt, setFilt] = useState();
  const [duplicateDistrict, setDuplicateDistrict] = useState([]);
  const [values, setValues] = useState({
    fromdate: new Date(),
    todate: new Date(),
    district: null,
  });
  const Status = [
    {id:'0', name:'Waiting to Allocate'},
    {id:'1', name:`Waiting for do's approval`},
    {id:'2',name:'Inprogress'},
    {id:'3',name:'Closed'},
    ]
  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
  };
  const handleSubmit = async (event) => {
    const data = { ...values };
    const datas = {}
    datas.fromdate = moment(data.fromdate).format("YYYY-MM-DD");
    datas.todate = moment(data.todate).format("YYYY-MM-DD");
    datas.req_filter=data.district?data.district=='all'?{}:{"district_id__in":[data.district]}:{};
    console.log(data);
        const resp = await ongetLineOpen(datas);
        if (resp && resp.response) {
          console.log(resp.response);
          setUsers(resp.response)
        }
  };
  const getOfficer=async(id)=>{
    const data={district_id:id}
   const resp = await ongetFso(data)
   console.log('resp', resp,data)
  }
    useEffect(()=>{
      if(values.district){
        getOfficer(values.district)
      }
    },[values.district])
    useEffect(()=>{
      var arr = [...districts]
      arr.unshift({id:'all',name:'All'})
      setDuplicateDistrict(arr)
    },[districts&&districts])
  useEffect(() => {
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
      const userData = { ...values, district: user.district };
      setValues(userData);
  }
  
  if(user && user.user_types && user.user_types.includes("fso")){
    const userData = { ...values, district: user.district, fso: user.id };
    setValues(userData);
  }
    const getfs = async () => {
      await ongetFso();
    };
    getfs();
  }, []);

const colDetails =  user &&  user.user_types && user.user_types.includes("ho") ? [
  {
    title:"District",
    field:"district",
    filtering:true,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Total Complaints",
    field: "total_complaint",
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  values && values.district?{
    title: "Complaints Assigned/Unassigned status",
    field: "fso",
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  }: {
    title: "Open Complaints",
    field: "open_complaint",
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Awaiting for Inspection",
    field: "awaiting_inspection",
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Awaiting For DO Approval",
    field: "awaiting_approval",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },

]:
[
  {
    title:"District",
    field:'district' ,
    filtering:true,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Total Complaints",
    field: "total_complaint",
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
   {
    title: "Complaints Assigned/Unassigned status",
    field: "fso",
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Awaiting for Inspection",
    field: "awaiting_inspection",
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Awaiting For DO Approval",
    field: "awaiting_approval",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
]

  return (
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}>Open Complaint Details Line Report</h3>

        <div
          className="filter_container"
        >
          <div >
            <label  htmlFor="date">From Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`From Date`}
                name="fromdate"
                id="fromdate"
                value={values.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextChange("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">To Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={values.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextChange("todate", text)}
              />
            </div>
          </div>
         
          {user && user.user_types && user.user_types.includes("ho")  ? (
          <div >
          <label htmlFor="district">District: </label>
          <div className="filter_input" >
            <DropDownInput
              type="text"
              name="district"
              value={values.district}
              error={errors.district}
              items={duplicateDistrict}
              style={true}
              onChange={(value) => handleTextChange("district", value)}
            />
          </div>
          </div>
          ):null}
          <div style={{marginTop:'30px'}} className="form-group ">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success ml-3"
            >
              View
            </button>
          </div>
        </div>
        {users ? (
          <div
            style={{ width: "90%", fontSize: "16px" }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={colDetails}
              data={users}
              options={{
                exportButton: {
       csv: true,
       pdf: false,
    },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                  zIndex:0
                },
              }}
              title="Open Complaint List"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetOverall: (data) => dispatch(getoverall(data)),
  ongetLineOpen: (data) => dispatch(lineReportOpen(data)),
  ongetOverallWithout: (data) => dispatch(getoverallWithout(data)),
  ongetFso: (data) => dispatch(getFsoOfficers(data)),
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  traderTypes: state.home.traderTypes,
  foodIssues: state.home.foodIssues,
  foodTypes: state.home.foodTypes,
  fsoOfficers: state.home.fsoOfficers,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineOpen);
