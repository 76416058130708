import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import toast from 'react-toast-notification';

export default class AvatarUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.onImageChange = this.onImageChange.bind(this);
  }
  onImageChange(e) {
    const imageToUpload = e.target.files[0];
    if (imageToUpload && imageToUpload.size > 25 * 1024 * 1024) {
      toast.error('Image size should be less than 25Mb')
    }
   else this.props.onChange(imageToUpload);
  }
  renderChildren = (props) => {
    return React.Children.map(props.children, (child) => {
      return child;
    });
  };
  render() {
    const {
      disabled,
      size,
      defaultImg,
      fileType,
      placeholder,
      currentImage,
    } = this.props;
    console.log(currentImage)
    return (
      <Avatar className="image_uploader" placeholder={placeholder} size={size}>
        {currentImage || defaultImg ? (
          <Avatar.Preview src={currentImage || defaultImg} />
        ) : null}
        <Avatar.Uploader
          fileType={fileType}
          onChange={this.onImageChange}
          disabled={disabled}
          size={size}
        />
        {this.renderChildren(this.props)}
      </Avatar>
    );
  }
}

AvatarUploader.propTypes = {
  onFinished: PropTypes.func,
  onStart: PropTypes.func,
  onChange: PropTypes.func,
  onProgress: PropTypes.func,
  placeholder: PropTypes.string,
  withCredentials: PropTypes.bool,
  customHeaders: PropTypes.object,
  disabled: PropTypes.bool,
  fileType: PropTypes.string,
  size: PropTypes.number,
  defaultImg: PropTypes.string,
  name: PropTypes.string.isRequired,
  currentImage: PropTypes.string,
};

AvatarUploader.defaultProps = {
  disabled: false,
  placeholder: "/images/dummy.jpg",
  withCredentials: false,
  fileType: "image/jpeg",
  size: 20,
  currentImage: null,
};
