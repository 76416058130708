import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { userDirectory } from "../../components/reducers/actions";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const PIOLab = (props) => {
  const { onUserDirectory, user } = props;
  const[users, setUsers]=useState();
  const { t, i18n } = useTranslation();

  
 
  
  return (
    <div style={{marginBottom:'150px'}}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">
                {t("Food Safety Department -PIO's")}
              </h1>
             
            </div>
          </div>
        </div>
      </header>
      <div style={{width:'100%', margin:'0 auto'}}>
      <div style={{fontSize:'14px',marginTop:20,marginLeft:'100px'}}>
          {localStorage.i18nextLng === 'en' ? (
     <>
   <div>
   <h6 style={{textDecoration:'underline'}}>Appellate Authority</h6>
   <p>044-24310085,<br/>
        commrfssatn@gmail.com,<br/>
       No. 359, Anna Salai,<br/> 5th Floor, DMS Campus,<br/> Teynampet, Chennai-45</p>
       
   </div>
    <div>
   <h6 style={{textDecoration:'underline'}}>Public Information Offier/Food Analyst -Guindy</h6>
   <p>044-22500573,<br/>
       falgdy32@gmail.com,<br/>
        King Institute Campus,<br/> Guindy, Chennai-32</p>
   
        
    </div>
    <div>
   <h6 style={{textDecoration:'underline'}}>Public Information Offier/Food Analyst -Coimbatore</h6>

        <p>Coimbatore,<br/>
        falcbe18@gmail.com,<br/>
       No. 219, Race Course Road,<br/> Coimbatore - 641  018  </p>
 
        
    </div>
    <div>
   <h6 style={{textDecoration:'underline'}}>Public Information Offier/Food Analyst -Madurai</h6>

   <p>0425-2538177,<br/>
        falmdu@gmail.com,<br/>
       POOR HOME CAMPUS,  Dr. Thangaraj Salai,<br/> Near Gandhi Museum, Madurai - 625 020. </p>
       
       
    </div>
    <div>
   <h6 style={{textDecoration:'underline'}}>Public Information Offier/Food Analyst -Salem</h6>

   <p>0427-2243837,<br/>
        foodlabsalem@gmail.com,<br/>
       No. 953, Kamarajar Nagar Colony,<br/> Near Holy Cross School, Ammapet,<br/> Salem 636 014.</p>
       
       
    </div>
    <div>
   <h6 style={{textDecoration:'underline'}}>Public Information Offier/Food Analyst -Thanjavur</h6>
      
   <p>0463-233147,<br/>
       falthanjavur@gmail.com,<br/>
      Near Membalam,  MC Road, <br/>Thanjavur - 613 001. </p>
        
        
    </div>
    <div>
   <h6 style={{textDecoration:'underline'}}>Public Information Offier/Food Analyst -Palayamkottai,Thirunelveli</h6>

   <p>0462-2577954,<br/>
       palaifoodlab@gmail.com,<br/>
    No. 5, Old Hospital Road, Palayamkottai,<br/> Tirunelveli - 627 002.  </p>
       
       
    </div>
    </>
                ):(
                    <>
    <div>
    <h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர்</h6>
    <p>044-24310085,<br/>
        commrfssatn@gmail.com,<br/>
        No. 359, அண்ணா சாலை, <br/> DMS வளாகம், தேனாம்பேட்டை, சென்னை.-45</p>
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர்/உணவு பகுப்பாய்வாளர் - கிண்டி</h6>
    <p>044-22500573,<br/>
        falgdy32@gmail.com,<br/>
       உணவுபகுபாய்வகம், கிங் பயிற்சி நிலையம் வளாகம்,<br/>  கிண்டி, சென்னை - 600 032.</p>
      
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர்/உணவு பகுப்பாய்வாளர் - கோயம்புத்தூர்</h6>

    <p>0422-2220136,<br/>
       falcbe18@gmail.com,<br/>
        உணவு பகுபாய்வஜ்கம்,   219, குதிரை பந்தய வளாகம், <br/>   துணை இயக்குனர் , சுகாதார பணிகள் வளாகம், <br/> கோயம்புத்தூர். 641 018</p>
       
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர்/உணவு பகுப்பாய்வாளர் - மதுரை</h6>

    <p>0425-2538177,<br/>
       falmdu@gmail.com,<br/>
     உணவு பகுபாய்வகம், ஏழை, எளியோர் காப்பகம் வளாகம், <br/> மரு.தங்கராஜ் சாலை, காந்தி காட்சி சாலை ,<br/>மதுரை.-625020. </p>
       
      
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர்/உணவு பகுப்பாய்வாளர் - சேலம்</h6>

    <p>0427-2243837,<br/>
        foodlabsalem@gmail.com,<br/>
       உணவு பகுபாய்வகம் No.953, காமராஜ் நகர் காலனி,<br/>  ஹோலும் கிராஸ் பள்ளி அருகில்,<br/>  அம்மாபேட்டை, சேலம், 636014.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர்/உணவு பகுப்பாய்வாளர் - தஞ்சாவூர்</h6>

    <p>    0463-233147,<br/>
        althanjavur@gmail.com,<br/>
        உணவு பகுபாய்வகம்,  மேம்பாலம் அருகில், <br/>  M.C.ரோடு,  தஞ்சாவூர் Pin. 613 001</p>
        
      
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர்/உணவு பகுப்பாய்வாளர் - பாளையங்கோட்டை</h6>

    <p>0462-2577954,<br/>
        palaifoodlab@gmail.com,<br/>
       உணவு பகுபாய்வகம், No:5, பழைய மருத்துவமனை சாலை,<br/>  பாளையங்கோட்டை. 627002</p>
      
       
    </div>
                    </>
                )}
 </div>
 </div>
 <div className='directory_links'>
      <Link to="/pio">{t("Click here for list of Commissionerate PIO officer's details")}</Link><br/>
      <Link to="/appellate-tribunal-pio">{t("Click here for list of District PIO officer's details")}</Link><br/>
    
    </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUserDirectory: (data) => dispatch(userDirectory(data)),
});

const mapStateToProps = (state) => ({
  user:state.home.user
});
export default connect(mapStateToProps, mapDispatchToProps)(PIOLab);
