import React from "react";
import { connect } from "react-redux";

const FinanceList = (props) => {
    const {user}=props;
    const reportNames =    (user && user.user_types && user.user_types.includes("ho"))|| 
    (user && user.user_types && user.user_types.includes("dd"))||
    (user && user.user_types && user.user_types.includes("commissioner"))||
    (user && user.user_types && user.user_types.includes("ac")) ? [
     
           { label: "Fund Credits Entry List",
            name: "/app/finance-credit-report-list",
        },
        {
            label: "Fund Distribute Repor List",
            name: "/app/finance-distribute-report-list",
        },
        {
            label: "Fund Spended Report List",
            name: "/app/finance-spend-report-list",
        },
        {
            label: "Fund Return Report List",
            name: "/app/finance-return-report-list",
        },
         
    ]:[
        {
            label: "Fund Spended Report List",
            name: "/app/finance-spend-report-list",
        },
        {
            label: "Fund Return Report List",
            name: "/app/finance-return-report-list",
        },
    ]

    const renderCard = (card, index) => {
        return (
            <div className="card-deck box" key={index}>
                <div className="card">
                    <div className="card-body text-center">
                        <button onClick={() => props.history.push(card.name)}
                            className="btn report-card" type="button"> {card.label}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <h1>Fund Detail Reports</h1>
            <div className="grid">{reportNames.map(renderCard)}</div>            
        </div>        
    )
}
const mapDispatchToProps = (dispatch) => ({ });
  
  const mapStateToProps = (state) => ({
    user: state.home.user,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(FinanceList);
