import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ExtraComplaintInput from "../components/ExtraComplaintInput";
import GoogleMapView from "../components/GoogleMapView";
import GooglePlaceAutoComplete from "../components/GooglePlaceAutoComplete";
import DropDownInput from "../components/inputs/DropDownInput";
import TextInput from "../components/inputs/TextInput";
import SelectItem from "../components/SelectItem";
import { useTranslation } from "react-i18next";
import {
  getComplaints,
  getComplaint,
  getMasterData,
  createComplaint,
  updateComplaint,
  setLoading,
  getDistrict
} from "../components/reducers/actions";

const AddComplaint = (props) => {
  const {
    traderTypes,
    districts,
    foodTypes,
    onGetMasterData,
    onUpdateComplaint,
    onComplaint,
    onComplaints,
    onCreateComplaint,
    foodConditions,
    foodIssues,
    onLoading,
    onGetDistrict,
  } = props;
  console.log(foodTypes,foodIssues, foodConditions)
  const [region, setRegion] = useState({
    lat: 11.1271,
    lng: 78.6569,
  });
  const[dist, setDist]=useState(null);
  const[isDis, setIsDis]=useState(false);
  const[isLoading,  setIsLoading]= useState(false);
  const [step, setStep] = useState(1);
  const [isSpinner, setIsSpinner] = useState(false);
  const [updateField, setupdateField] = useState([]);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [markerCoordinates, setMarkerCoordinates] = useState(null);
  const [finalDisableSubmit, setFinalDisableSubmit] = useState(true);
  const [foodIssue, setFoodIssue] = useState([]);
  const [condition, setCondition] = useState([]);
  const [nature, setNature] = useState([]);

  const maxDate = new Date();
  const { t } = useTranslation();

  districts.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const goBack = () => {
    setStep((prevState) => prevState - 1);
  };

  const validateValues = (values) => {
    console.log(values)
    const updatedErrors = {};
    if (step === 6) {
      var finalDisableSubmit = true;
      var finalKeys = [];
      finalKeys.forEach((key) => {
        if (!values[key] || values[key] === "") {
          updatedErrors[key] = " ";
        }
      });
      if (
        values.product_purchased_on &&
        values.product_used_on &&
        values.product_purchased_on > values.product_used_on
      ) {
        updatedErrors.product_purchased_on =
          "Product Purchase date should be before the Product open date";
      }
      finalDisableSubmit = Object.keys(updatedErrors).length > 0;
      console.log(finalDisableSubmit);
      setFinalDisableSubmit(finalDisableSubmit);
    } else {
      var shouldDisableSubmit = true;
      var keys = [
        "district",
        'name_of_restaurant',
        'sub_division',
        'sub_division1',
        'village'
      ];
      keys.forEach((key) => {
        if (!values[key] || values[key] === "") {
          updatedErrors[key] = " ";
        }
      });
      if(values.district) setIsLoading(true)
      if (values.zipcode && values.zipcode.length !== 6) {
        // updatedErrors.zipcode = "Zipcode should be 6 characters";
      } else if (values.zipcode && values.zipcode[0] !== "6") {
        updatedErrors.zipcode = "Not a valid Tamil Nadu zipcode.";
      }
      shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
      setDisableSubmit(shouldDisableSubmit);
    }
    setErrors(updatedErrors);
    return updatedErrors;
  };

  useEffect(() => {
    if (props.history.location && props.history.location.state) {
      let complaint = props.history.location.state.complaint;
      if (
        complaint &&
        Object.keys(complaint).length > 0 &&
        complaint.coordinates &&
        complaint.coordinates[0] &&
        complaint.coordinates[1]
      ) {
        const updatedRegion = { ...region };
        updatedRegion.lat = complaint.coordinates[0];
        updatedRegion.lng = complaint.coordinates[1];
        setRegion(updatedRegion);
        setMarkerCoordinates({
          lat: complaint.coordinates[0],
          lng: complaint.coordinates[1],
        });
      }

      setValues(complaint);
      validateValues(complaint);
    } else {
      getMyLocation();
    }
    // eslint-disable-next-line
  }, []);



  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          const updatedRegion = { ...region };
          updatedRegion.lat = position.coords.latitude;
          updatedRegion.lng = position.coords.longitude;
          setRegion(updatedRegion);
          setMarkerCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    const handleGetComplaints = async () => {
      onComplaints({
        is_base64: true,
      });
    };
    handleGetComplaints();
    onGetMasterData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!step) {
      setStep(1);
    }
    var issueItem = [];
    foodIssues && 
      foodIssues.forEach(function (itm) {
        if (itm.food_condition.includes(values.condition_of_food)) {
          issueItem.push({
            id: itm.id,
            name: itm.name,
            image: itm.image,
          });
        }
      });
    if (issueItem.length > 0) {
      validateValues(values);
    }
    setFoodIssue(issueItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var natureItem = [];
    foodTypes && values.type_of_trader &&
    foodTypes.forEach(function (itm) {
        if (itm.trader_type.includes(values.type_of_trader)) {
          natureItem.push({
            id: itm.id,
            name: itm.name,
            image: itm.image,
          });
        }
      });
    if (natureItem.length > 0) {
      validateValues(values);
    }
    setNature(natureItem);

    var conditionItem = [];
    foodConditions && 
    foodConditions.forEach(function (itm) {
        if (itm.food_type.includes(values.nature_of_food)) {
          conditionItem.push({
            id: itm.id,
            name: itm.name,
            image: itm.image,
          });
        }
      });
    if (conditionItem.length > 0) {
      validateValues(values);
    }
    console.log(conditionItem)
    setCondition(conditionItem);
  }, [step]);

  const handleTextChange = (key, value) => {
   console.log(value)
    let updatedValues = { ...values, [key]: value };
    if(key === 'zipcode'){getValue(value)}
    if (!updateField.includes(key)) {
      setupdateField([...updateField, key]);
    }
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  const submitComplaint = async () => {
    setIsSpinner(true);
    onLoading(true);
    if (props.history.location && props.history.location.state) {
      var updatedData = {};
      updateField.forEach((key) => {
        if (values[key] && key !== "is_update_photo") {
          updatedData[key] = values[key];
        }
      });
      delete updatedData.images;
      updatedData["photos"] = values.images;
      let data = { ...updatedData, id: values.id };
      setFinalDisableSubmit(true);
      const resp = await onUpdateComplaint(data);
      if (resp.response) {
        setIsSpinner(false);
        onLoading(false);
        const complaintsData = await onComplaint({
          is_base64: true,
          id: resp.response.id,
        });
        if (complaintsData.response) {
          setValues({});
          validateValues({});
          var complaintVal = complaintsData.response;
          props.history.push({
            pathname: `/app/complaints/${complaintVal.id}`,
            state: {
              complaint: complaintVal,
            },
          });
        }
      }
    } else {
      const data = { ...values, photos: values.images };
      setFinalDisableSubmit(true);
      if(typeof(data.district)=='string'){
        data.district=dist.district_id
      }
      const resp = await onCreateComplaint(data);
      if (resp.response) {
        setIsSpinner(false);
        onLoading(false);
        const complaintsData = await onComplaint({
          is_base64: true,
          id: resp.response.id,
        });
        if (complaintsData.response) {
          setValues({});
          setStep(null);
          var complaint = complaintsData.response;
          props.history.push({
            pathname: `/app/complaints/${complaint.id}`,
            state: {
              complaint: complaint,
              isFirst: true,
              hideActionMycomplaints: true,
            },
          });
        }
      } else {
        setValues({});
        setStep(null);
        props.history.push("/app/complaints");
      }
    }
  };
  
  const getValue = async(val)=>{
    if(!val){
      setValues({...values, district:null})
    }
    if(val){
     if( val[0]!=='6')setErrors({...errors,zipcode:'Not a valid Tamilnadu pincode'})
     else{ setValues({...values, district:null})
    }
    }
      if((val !== undefined) && (val.length==6)) {
        if(val[0]!=='6'){
          setErrors({...errors,zipcode:'Not a valid Tamilnadu pincode'})
          }
       else{ setErrors({...errors,zipcode:null})}
        const data = {'zipcode': val}
        const resp = await onGetDistrict(data);
      let dis = resp.response.status ==="success" && resp.response.data ? resp.response.data :'';
        console.log(resp)
      if(resp.response.status ==="success"){
        setDist(resp.response)
       setValues({
         ...values,
         district:parseInt(resp.response.district_id) ,
         zipcode:val,
       })
       let data={...values, district:dis}
       console.log(data)
       validateValues(data)
        setIsLoading(true)
        setIsDis(true)
      }
      else {
        setIsLoading(false)
        setIsDis(false)
        setValues({...values, district:null})
      }
      }
      else{
        if(val && val.length !== 6){
          setErrors({...errors,zipcode:'Zipcode should be atleast 6 characters'})
        }
        if(val && val[0]!=='6'){
        setErrors({...errors,zipcode:'Not a valid Tamilnadu pincode'})
        }
      }
  }

  useEffect(()=>{
   getValue(values.zipcode)
  },[values.zipcode])

  const nextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  const handleRemoveImages = (key, value) => {
    const updatedValues = { ...values };
    delete updatedValues[key];
    setValues(updatedValues);
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">{t(props.title)}</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <form>
            {
              {
                step1: (
                  <>
                    <div className="form-gorup mb-3">
                      <GooglePlaceAutoComplete
                        districts={districts}
                        values={values}
                        region={region}
                        setValues={setValues}
                        setMarkerCoordinates={setMarkerCoordinates}
                        setRegion={setRegion}
                        validateValues={validateValues}
                      />
                    </div>
                    <div className="form-group">
                      <GoogleMapView
                        region={region}
                        markerCoordinates={markerCoordinates}
                        setMarkerCoordinates={setMarkerCoordinates}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                      />
                    </div>
                    <div className="form-group">
                      <TextInput
                        placeholder={`${t("Establishment Name")} *`}
                        type="text"
                        name="name_of_restaurant"
                        value={values.name_of_restaurant}
                        error={errors.name_of_restaurant}
                        onChange={(e) =>
                          handleTextChange("name_of_restaurant", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <TextInput
                        placeholder={`${t("House/Flat No")}`}
                        type="text"
                        name="street"
                        value={values.street}
                        error={errors.street}
                        onChange={(e) =>
                          handleTextChange("street", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <TextInput
                        placeholder={`${t("Street Address")} 1 *`}
                        type="text"
                        name="sub_division"
                        value={values.sub_division}
                        error={errors.sub_division}
                        onChange={(e) =>
                          handleTextChange("sub_division", e.target.value)
                        }
                      />
                    </div>
                    
                    <div className="form-group">
                      <TextInput
                        placeholder={`${t("ward")} `}
                        type="text"
                        name="ward"
                        value={values.ward}
                        error={errors.ward}
                        onChange={(e) =>
                          handleTextChange("ward", e.target.value)
                        }
                      />
                    </div>
                   
                    <div className="form-group">
                      <TextInput
                        placeholder={`${t("City")} *`}
                        type="text"
                        name="village"
                        value={values.village}
                        error={errors.village}
                        onChange={(e) =>
                          handleTextChange("village", e.target.value)
                        }
                      />
                    </div>

                    <div className="form-group">
                      <TextInput
                        placeholder={`${t("cv")} ${t("ex")} *`}
                        type="text"
                        name="sub_division1"
                        value={values.sub_division1}
                        error={errors.sub_division1}
                        onChange={(e) =>
                          handleTextChange("sub_division1", e.target.value)
                        }
                      />
                    </div>

                    <div className="form-group">
                      <TextInput
                        placeholder={`${t("ZipCode")} `}
                        type="text"
                        name="zipcode"
                        value={values.zipcode}
                        error={errors.zipcode}
                        onChange={(e) =>
                          handleTextChange("zipcode", e.target.value)
                        }
                      />
                    </div>
                   {/* {isDis && <div className="form-group">
                      <TextInput
                        placeholder={`${t("ZipCode")} `}
                        type="text"
                        disabled
                        name="zipcode"
                        value={dist.data}
                        onChange={(e) =>
                          handleTextChange("zipcode", e.target.value)
                        }
                      />
                    </div>} */}
                    <div className="form-group">
                      <DropDownInput
                        placeholder={`${t("District")} *`}
                        type="text"
                        name="district"
                        value={values.district}
                        error={errors.district}
                        items={districts}
                        onChange={(text) => handleTextChange("district", text)}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        onClick={() => props.history.push("/app/complaints")}
                        className="btn btn-success"
                        type="button"
                      >
                        {t("Cancel")}
                      </button>
                      <button
                        disabled={isLoading ? disableSubmit : !isLoading }
                        onClick={() => nextStep()}
                        type="button"
                        className="btn btn-success ml-3"
                      >
                        {t("Continue")}
                      </button>
                    </div>
                  </>
                ),
                step2: (
                  <div>
                    <SelectItem
                      placeholder={t("STT")}
                      name="type_of_trader"
                      value={values.type_of_trader}
                      nextStep={nextStep}
                      goBack={goBack}
                      item={traderTypes}
                      disableSubmit={values.type_of_trader ? false : true}
                      onChangeText={(text) =>
                        handleTextChange("type_of_trader", text)
                      }
                    />
                  </div>
                ),
                step3: (
                  <div>
                    <SelectItem
                      placeholder={t("SNF")}
                      name="nature_of_food"
                      value={values.nature_of_food}
                      nextStep={nextStep}
                      goBack={goBack}
                      item={nature}
                      disableSubmit={values.nature_of_food ? false : true}
                      onChangeText={(text) =>
                        handleTextChange("nature_of_food", text)
                      }
                    />
                  </div>
                ),
                step4: (
                  <div>
                    <SelectItem
                      placeholder={t("SCF")}
                      name="condition_of_food"
                      value={values.condition_of_food}
                      nextStep={nextStep}
                      goBack={goBack}
                      item={condition}
                      disableSubmit={values.condition_of_food ? false : true}
                      onChangeText={(text) =>
                        handleTextChange("condition_of_food", text)
                      }
                    />
                  </div>
                ),
                step5: (
                  <div>
                    <SelectItem
                      placeholder={t("SIF")}
                      name="issue_of_food"
                      value={values.issue_of_food}
                      nextStep={nextStep}
                      goBack={goBack}
                      item={foodIssue}
                      disableSubmit={values.issue_of_food ? false : true}
                      onChangeText={(text) =>
                        handleTextChange("issue_of_food", text)
                      }
                    />
                  </div>
                ),
                step6: (
                  <div>
                    <ExtraComplaintInput
                      values={values}
                      errors={errors}
                      nextStep={nextStep}
                      goBack={goBack}
                      maxDate={maxDate}
                      isSpinner={isSpinner}
                      disableSubmit={finalDisableSubmit}
                      submitComplaint={submitComplaint}
                      handleTextChange={(field, text) =>
                        handleTextChange(field, text)
                      }
                      handleRemoveImages={(field, text) =>
                        handleRemoveImages(field, text)
                      }
                    />
                  </div>
                ),
              }[`step${step}`]
            }
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onComplaints: (data) => dispatch(getComplaints(data)),
  onComplaint: (data) => dispatch(getComplaint(data)),
  onCreateComplaint: (data) => dispatch(createComplaint(data)),
  onGetMasterData: () => dispatch(getMasterData()),
  onUpdateComplaint: (data) => dispatch(updateComplaint(data)),
  onLoading: (data) => dispatch(setLoading(data)),
  onGetDistrict:(data)=>dispatch(getDistrict(data))
});

const mapStateToProps = (state) => ({
  traderTypes: state.home.traderTypes,
  foodTypes: state.home.foodTypes,
  foodConditions: state.home.foodConditions,
  foodIssues: state.home.foodIssues,
  districts: state.home.districts,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddComplaint);
