import React from "react";

const DisplaySampleReports = (props) => {

    const reportNames = [
        {
            label: "Sample Details Report List - DO",
            name: "/app/display-reports/do_sample_list",
        },
        {
            label: "Damaged Sample Details Report - DO",
            name: "/app/display-reports/do_damaged_list",
        },
        {
            label: "Direct Case Report List - DO",
            name: "/app/display-reports/do-direct-list",
        },
        {
            label: "Direct Case Details Report List - DO",
            name: "/app/display-reports/do-directdetails-list",
        },
        {
            label: "CaseFile Clarification Report List - DO",
            name: "/app/display-reports/do-clarify-list",
        },
        {
            label: "Appeal Report List - DO",
            name: "/app/display-reports/do-appeal-list",
        },
        {
            label: "FSO Sample Details Report List",
            name: "/app/display-reports/fso_sample_list",
        },
        {
            label: "Case Details Report List - FSO",
            name: "/app/display-reports/fso_casedetails_list",
        },
        {
            label: "Direct Case Report List - FSO",
            name: "/app/display-reports/fso-direct-list",
        },
       
        {
            label: "Direct Case Details Report List - FSO",
            name: "/app/display-reports/fso-directdetails-list",
        },
        {
            label: "Case Details Report List - Comissioner",
            name: "/app/display-reports/comissioner-case-list",
        },
        {
            label: "Criminal Case Report List - Comissionerate",
            name: "/app/display-reports/comissionerate-criminal-list",
        },
        {
            label: "Appeal Report List - Comissionerate",
            name: "/app/display-reports/comissionerate-appeal-list",
        },
        {
            label: "Direct Case Report List - Comissionerate",
            name: "/app/display-reports/comissionerate-direct-list",
        },
        {
            label: "Civil Case Report List - APP",
            name: "/app/display-reports/app-civil-list",
        },
        {
            label: "Direct Case Report List - APP",
            name: "/app/display-reports/app-direct-list",
        },
        {
            label: "Sample Analysis Report List - LAB",
            name: "/app/display-reports/lab_sample_list",
        },
        {
            label: "Sample From Different Sources Report List - LAB",
            name: "/app/display-reports/lab-different-list",
        },           
             
    ]

    const renderCard = (card, index) => {
        return (
            <div className="card-deck box" key={index}>
                <div className="card">
                    <div className="card-body text-center">
                        <button onClick={() => props.history.push(card.name)}
                            className="btn report-card" type="button"> {card.label}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <h1> Sample Reports</h1>
            <div className="grid">{reportNames.map(renderCard)}</div>            
        </div>        
    )
}

export default DisplaySampleReports;