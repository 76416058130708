import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

const ModalPopup = (props) => {
  const { modal, toggle, data, isSpinner, handleResetSubmit } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t(`Reset Password`)}</ModalHeader>
        <ModalBody>{t(`Are you sure you want to reset password?`)}</ModalBody>
        <ModalFooter>
          <Button color="default" onClick={toggle}>
            {t("Cancel")}
          </Button>{" "}
          <Button color="danger" onClick={() => handleResetSubmit(data)}>
            <ClipLoader
              color="#fff"
              loading={isSpinner}
              size={15}
              css={{ marginRight: "10px" }}
            />
            {t("Reset")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalPopup;
