import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const FssaactDetails = (props) => {
    const { districts, traderTypes, wards,fsoOfficers } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
      return wardName;
  };

    const getFbo = (category_of_fbo) => {
      let fbo = null;
      traderTypes &&
      traderTypes.forEach((item) => {
          if (item.id === category_of_fbo) {
            fbo = item.name;
          }
        });
      return fbo;
  };
  const getFSO = (fs) => {
    let fsoName = null;
    fsoOfficers &&
    fsoOfficers.forEach((item) => {
        if (item.fso.id === fs) {
          fsoName = item.fso.name;
        }
      });
      return fsoName;
  }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">FSSA-ACT Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.name_of_block}</td>
              </tr>
                            <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date_of_sampling).format("l")}</td>
              </tr>
               
              <tr>
                <th>Sample no / year</th>
                <td className="text-left">{records.sample_no}</td>
              </tr>              
              <tr>
                <th>Name of the food saftey officer</th>
                <td className="text-left">{getFSO(parseInt(records.name_of_fso))}</td>
              </tr>
              <tr>
                <th>Nature of the food sample with brand name</th>
                <td className="text-left">{records.nature_of_food}</td>
              </tr>
              <tr>
                <th>Category of the Food Stuff</th>
                <td className="text-left">{records.category_of_food}</td>
              </tr>
              <tr>
                <th>Category of traders</th>
                <td className="text-left">{getFbo(parseInt(records.category_of_trader))}</td>
              </tr>
              <tr>
                <th>Name of the laboratory where the analysis done</th>
                <td className="text-left">{records.name_of_laboratory}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
  fsoOfficers: state.home.fsoOfficers,

});

export default connect(mapStateToProps, mapDispatchToProps)(FssaactDetails);