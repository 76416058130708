import React, { useState, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  getDistrictWards,
  getDownloadFboReports,
  getFboReports,
} from "../../components/reducers/actions";
import DataTable from "react-data-table-component";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker";
import { useSSR } from "react-i18next";
import TextInput from "../../components/inputs/TextInput";
import styled from "styled-components";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { CSVLink } from "react-csv";
import MaterialTable from "material-table";

const AppList = (props) => {
  const { data, handleSelectRow } = props;
  return <button onClick={() => handleSelectRow(data)}></button>;
};
const ViewButton = (props) => {
  const { data, handleSelectRow } = props;
  return (
    <button
      className="btn btn-primary btn-edit"
      onClick={() => handleSelectRow(data)}
    >
      View
      <i className="fas fa-eye icon-edit" aria-hidden="true"></i>
    </button>
  );
};
const EditButton = (props) => {
  const { data, handleEdit, handleSelectRow } = props;
  return (
    <button
      className="btn btn-primary btn-edit"
      onClick={() => handleEdit(data)}
    >
      Edit<i className="fas fa-pencil-alt icon-edit" aria-hidden="true"></i>
    </button>
  );
};

const FboList = (props) => {
  const {
    districts,
    onGetFboReports,
    user,
    wards,
    wardsCategory,
    onGetFboDownloadReports,
    ongetDist,
  } = props;
  const [values, setValues] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [District, setDistrict] = useState({});
  const countPerPage = 10;
  const dref = useRef();
  const [downloadData, setDownloadData] = useState([]);
  const [filterData, setFilterData] = useState();
  const [download, setDownload] = useState(false);
  const [duplicateDistrict, setDuplicateDistrict] = useState([]);

  const TypeOfTraders = [
    { id: "0", name: "All" },
    { id: "1", name: "Manufacturer" },
    { id: "2", name: "Retailer" },
    { id: "3", name: "WholeSaler/Distributor/Repacker" },
    { id: "4", name: "Transporter" },
  ];

  const Institute = [
    { id: "0", name: "All" },
    { id: "1", name: "Government" },
    { id: "2", name: "Private" },
  ];
  // const data = React.useMemo(() => makeData(100000), [])
  const reportList = async () => {
    const resp = await onGetFboReports({
      page: page,
      per_page: countPerPage,
    });
    if (resp && resp.response) {
      const data = resp.response;
      setValues(data);
    }
  };

  useEffect(() => {
    if (
      (user && user.user_types && user.user_types.includes("do")) ||
      (user && user.user_types && user.user_types.includes("fso"))
    ) {
      setDistrict(user.district);
    }
    reportList();
    // eslint-disable-next-line
  }, [page]);
  const getDis=async(data)=>{
    if(user && user.user_types && user.user_types.includes("do")||user && user.user_types && user.user_types.includes("fso")){
      var dat = data
    }
    else dat=data[0]
 await ongetDist({district:dat})
}
useEffect(()=>{
  if(searchFilter.district&&searchFilter.district){
    getDis(searchFilter.district)
  }
},[searchFilter&&searchFilter.district])

  useEffect(() => {
    wardsCategory.map((o) => {
      if (o.id == searchFilter.area_code)
        return setSearchFilter({
          ...searchFilter,
          area_name: o.ward_name,
        });
    });
  }, [searchFilter.area_code]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        fontSize: "18px",
        paddingLeft: "30px",
        paddingRight: "30px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        color: "#45b151",
        paddingLeft: "25px",
        paddingRight: "30px",
        fontSize: "16px",
        background: "rgba(70,179,84,.10196078431372549)",
      },
    },
    cells: {
      style: {
        paddingLeft: "25px",
      },
    },
  };

  const getDistrict = (district) => {
    let districtName = null;
    districts &&
      districts.forEach((item) => {
        if (item.id === district) {
          districtName = item.name;
        }
      });
    return districtName;
  };

  const columns = [
    {
      omit: true,
      cell: (row) => <AppList data={row} handleSelectRow={handleSelectRow} />,
      selector: "id",
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => getDistrict(row.district),
      selector: "district",
      sortable: true,
    },

    // {
    //   name: "Date",
    //   cell: (row) =>
    //    row.created_at && moment(row.created_at.toString()).format("MMMM Do YYYY"),
    //   sortable: true
    // },
    {
      name: "Created At",
      cell: (row) =>
        row.created_at &&
        moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm"),
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Last Edited",
      cell: (row) =>
        row.last_updated_at && row.last_updated_at
          ? moment(row.last_updated_at.toString()).format("DD-MM-YYYY hh:mm")
          : "-",
      selector: "Last_edited",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        moment().diff(moment(row.created_at.toString()), "hours") < 24 ? (
          <>
            <EditButton data={row} handleEdit={handleEdit} />{" "}
            <ViewButton data={row} handleSelectRow={handleSelectRow} />
          </>
        ) : (
          <ViewButton data={row} handleSelectRow={handleSelectRow} />
        ),
      sortable: true,
    },
  ];
  useEffect(() => {
    if (dref && dref.current && download) {
      dref.current.link.click();
      setDownload(false);
    }
  }, [download]);

  const handleTextChange = (key, value) => {
    if (key === "date" || key == "fromdate" || key == "todate") {
      const updatedValues = {
        ...searchFilter,
        [key]: moment(value).format("YYYY-MM-DD"),
      };
      setSearchFilter(updatedValues);
    }
    const updatedValues = { ...searchFilter, [key]: value };
    setSearchFilter(updatedValues);
  };

  const handleDownload = () => {
    if (searchFilter) {
      async function fetchData() {
        const resp = await onGetFboDownloadReports({
          district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
          fromdate: moment(searchFilter.fromdate||new Date()).format("YYYY-MM-DD"),
          todate: moment(searchFilter.todate||new Date()).format("YYYY-MM-DD"),
          fso_area_code: searchFilter.fso_area_code,
          type_institution:searchFilter.type_institution&&searchFilter.type_institution=='All'?'':searchFilter.type_institution,
          trader_category:searchFilter.trader_category&&searchFilter.trader_category=='All'?'':searchFilter.trader_category,
        });
        if (resp && resp.response) {
          const data = resp.response;
          setDownloadData(data);
          setDownload(true);
        }
      }
      fetchData();
    }
  };
  const handleClick = () => {
    setValues([]);
    if (searchFilter) {
      async function fetchData() {
        const resp = await onGetFboDownloadReports({
          district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
          fromdate: moment(searchFilter.fromdate||new Date()).format("YYYY-MM-DD"),
          todate: moment(searchFilter.todate||new Date()).format("YYYY-MM-DD"),
          fso_area_code: searchFilter.fso_area_code,
          type_institution:searchFilter.type_institution&&searchFilter.type_institution=='All'?'':searchFilter.type_institution,
          trader_category:searchFilter.trader_category&&searchFilter.trader_category=='All'?'':searchFilter.trader_category,
          is_json: true,
        });
        if (resp && resp.response) {
          const data = resp.response;
          console.log(data);
          setFilterData(data.data);
        }
      }
      fetchData();
    }
  };
  const handleFlick = () => {
    setFilterData();
    if (searchFilter) {
      async function fetchData() {
        const resp = await onGetFboReports({
          district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
          fromdate: moment(searchFilter.fromdate||new Date()).format("YYYY-MM-DD"),
          todate: moment(searchFilter.todate||new Date()).format("YYYY-MM-DD"),
          fso_area_code: searchFilter.fso_area_code,
          type_institution:searchFilter.type_institution&&searchFilter.type_institution=='All'?'':searchFilter.type_institution,
          trader_category:searchFilter.trader_category&&searchFilter.trader_category=='All'?'':searchFilter.trader_category,
        });
        if (resp && resp.response) {
          const data = resp.response;
          console.log(data);
          setValues(data.results);
        }
      }
      fetchData();
    }
  };
  useEffect(()=>{
    var arr = [...districts]
    arr.unshift({id:'all',name:'All'})
    setDuplicateDistrict(arr)
  },[districts&&districts])
  const handleEdit = (data) => {
    console.log(data);
    props.history.push(`/app/reports/fbo-report/${data.id}/edit`, {
      values: data,
    });
  };

  const handleSelectRow = (data) => {
    console.log(data);
    props.history.push(`/app/display-reports/fbo-list/${data.id}`, {
      records: data,
    });
  };

  const colDetails = [
    {
      title: "District",
      field: "district",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Record Date",
      field: "date",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Area Name",
      field: "area_name",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Area code",
      field: "fso_area_code",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Type of Institution",
      field: "type_institution",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Trader category",
      field: "trader_category",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Government institution",
      field: "no_of_govt_institutions",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Private FBO",
      field: "no_of_private_fbo",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">FBO Reports List</h1>
      <div className="main">
        <div className="row" style={{ marginBottom: 20 }}>
        {((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))? (
                <div className="col-sm-2">  <DropDownInput
                      placeholder={`District`}
                      type="text"
                      isDisabled
                      name="district"
                      value={District}
                      items={districts}
                      onChange={(value) => handleTextChange("district", value)}
                    />
                  </div>):(
                      <div className="col-sm-2">
                      <DropDownInput
                        placeholder={` District`}
                        type="text"
                        style
                        name="district"
                        action="district"
                        value={searchFilter.district}
                        items={duplicateDistrict}
                        onChange={(text) => handleTextChange("district", text)}
                      />
                    </div>
                  )}
          <div className="col-sm-2">
            <DropDownInput
              placeholder={`Area Code`}
              type="text"
              name="fso_area_code"
              style
              action="area_code"
              value={searchFilter.fso_area_code}
              items={wardsCategory}
              onChange={(text) => handleTextChange("fso_area_code", text)}
            />
          </div>
          {/* <div className="col-sm-2">
            <TextInput
              placeholder={`Area Name`}
              type="text"
              name="area_name"
              value={searchFilter.area_name}
              onChange={(e) => handleTextChange("area_name", e.target.value)}
            />
          </div> */}
          <div className="col-sm-2">
            <Datepicker
              placeholder={`From Date`}
              name="fromdate"
              value={searchFilter.fromdate}
              onChangeText={(text) => handleTextChange("fromdate", text)}
            />
          </div>
          <div className="col-sm-2">
            <Datepicker
              placeholder={`To Date`}
              name="todate"
              value={searchFilter.todate}
              onChangeText={(text) => handleTextChange("todate", text)}
            />
          </div>
          <div className="col-sm-3">
            <DropDownInput
              placeholder={`Institution type`}
              name="type_institution"
              action="dropdown"
              style
              value={searchFilter.type_institution}
              items={Institute}
              onChange={(text) => handleTextChange("type_institution", text)}
            />
          </div>
          <div style={{marginTop:'25px'}} className="col-sm-3">
            <DropDownInput
              placeholder={`Trader category`}
              name="trader_category"
              action="dropdown"
              value={searchFilter.trader_category}
              items={TypeOfTraders}
              onChange={(text) => handleTextChange("trader_category", text)}
            />
          </div>
        <div
          style={{
            float: "right",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "30px",
            marginRight: "60px",
            marginBottom: "40px",
          }}
        >
          <div className="col-sm-1">
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleClick}
            >
              View
            </button>
          </div>
          <div className="col-sm-6">
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleFlick}
            >
              View & Edit
            </button>
          </div>
          <div className="col-sm-1 ml-md-n5">
            <button
              style={{ background: "#44B150" }}
              type="button"
              className="btn btn-secondary ml-md-n3 ml-1"
              onClick={handleDownload}
            >
              Download
            </button>
            <CSVLink
              data={downloadData}
              ref={dref}
              filename={"FboDetails.csv"}
              // target='_blank'
            />
          </div>
        </div>
        </div>

        {values.length > 0 ? (
          <DataTable
            columns={columns}
            data={values}
            onRowClicked={handleSelectRow}
            pagination
            // paginationServer
            paginationPerPage={countPerPage}
            paginationTotalRows={values.count}
            customStyles={customStyles}
            noHeader
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            highlightOnHover
            noDataComponent={
              <div style={{ color: "#858796", marginTop: 20 }}>
                No data available in table
              </div>
            }
            onChangePage={(page) => setPage(page)}
          />
        ) : null}
       {filterData ? (
          <MaterialTable
            style={{width:'100%',marginTop:'120px'}}
            columns={colDetails}
            data={filterData}
            options={{
              exportButton: {
                csv: true,
                pdf: false,
             },
              search: true,
              filtering: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
                borderColor: "#fff",
                textAlign: "center",zIndex:0
              },
            }}
            title="Fbo List"
          />
        ) : null}
      </div>
      
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetFboReports: (data) => dispatch(getFboReports(data)),
  onGetFboDownloadReports: (data) => dispatch(getDownloadFboReports(data)),
  ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  wardsCategory: state.home.wardsCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(FboList);
