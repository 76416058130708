import React, { useRef } from "react";
import "./FsoAccordion.css";
import { useTranslation } from "react-i18next";

const FsoAccordion = (props) => {
  const contentRef = useRef(null);
  const { title, activeAccordion, index, activateAccordion } = props;
  const { t } = useTranslation();
  const isActive = activeAccordion === index;
  const innerStyle = {
    height: `${isActive ? "100%" : "0px"}`,
  };

  const handleToggle = () => {
    props.handleAccordionClick(props.data_id);
    activateAccordion();
  };

  const renderChildren = (props) => {
    return React.Children.map(props.children, (child) => {
      return child;
    });
  };

  return (
    <div className="complaints__list">
      <div className="accordion" role="tablist">
        <div className="panel" role="tabpanel" aria-expanded={isActive}>
          <button className="panel__label" role="tab" onClick={handleToggle}>
            {title}<span style={{float:'right'}}>{t("View Inspection")}</span>
          </button>
          <div
            className="panel__inner"
            ref={contentRef}
            style={innerStyle}
            aria-hidden={!isActive}
          >
            {renderChildren(props)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FsoAccordion;
