import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import { nest } from "d3-collection";
import tip from "d3-tip";
const ClosureComplaintBarChart = ({ data }) => {
  const closureBar = useRef();
  const renderStackedBar = () => {
    const margin = { top: 40, right: 30, bottom: 50, left: 40 },
      width = 350 - margin.left - margin.right,
      height = 250 - margin.top - margin.bottom;
    const svg = d3
        .select(closureBar.current)
            .attr(
                "viewBox",
                `0 0 ${width + margin.left + margin.right} ${
                height + margin.top + margin.bottom
                }`
            )
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    if (data) {
      const subgroups = ["value"];
      data = nest()
        .key(function (d) {
          if (d.closer_diff < 3) {
            return "Within 2 days";
          } else if (d.closer_diff >= 3 && d.closer_diff <= 8) {
            return "Between 2-7 days";
          } else if (d.closer_diff > 8) {
            return "Morethan 7 days";
          }
        })
        .rollup(function (v) {
          return d3.sum(v, function (d) {
            return d.count;
          });
        })
        .entries(data);
      const groups = data.map((d) => d.key);
      const tooltip = tip()
        .attr("class", "d3-tip")
        .html(function (e, d) {
          return "<strong>Count:</strong> <span>" + d.data.value + "</span>";
        });
      // Add X axis
      const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.5]);
      svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x).tickSizeOuter(0))
        .selectAll("text")  
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-25)" );
      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.value)])
        .range([height, 0]);
      svg.append("g").call(d3.axisLeft(y));
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1.5em")
        .style("text-anchor", "middle")
        .style("font-size", "x-small")
        .text("Complaint Counts");
      svg.call(tooltip);
      // color palette = one color per subgroup
      const color = d3
        .scaleOrdinal()
        .domain(subgroups)
        //.range(d3.schemeDark2)
        .range(
          d3
            .quantize((t) => d3.interpolateRainbow(t * 0.8 + 0.1), data.length)
            .reverse()
        );
      //stack the data? --> stack per subgroup
      const stackedData = d3.stack().keys(subgroups)(data);
      // Show the bars
      svg
        .append("g")
        .selectAll("g")
        // Enter in the stack data = loop key per key = group per group
        .data(stackedData)
        .join("g")
        .attr("fill", "rgb(58, 173, 173)")
        .selectAll("rect")
        // enter a second time = loop subgroup per subgroup to add all rectangles
        .data((d) => d)
        .join("rect")
        .attr("x", (d) => x(d.data.key))
        .attr("y", (d) => y(d[1]))
        .attr("height", (d) => y(d[0]) - y(d[1]))
        .attr("width", x.bandwidth())
        .on("mouseover", tooltip.show)
        .on("mouseout", tooltip.hide);
    }
  };
  useEffect(() => {
    if(data.length !== 0){
      renderStackedBar();
    }
    // eslint-disable-next-line
  }, [data]);
  return (
    <div id="districtBarChart">
      <div className="card">
        <div className="card-body">
          <h4 className="heading">Closure complaints by Days</h4>
        {data && data.length !==0?  <div id="closure_days_bar">
          <svg ref={closureBar}></svg>
          </div> : <>
          <div style={{marginTop:90, marginBottom:90}}>No Data Available</div>
          </>}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => ({
  dashboard: state.home.dashboard,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClosureComplaintBarChart);