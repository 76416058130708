import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Image, Form, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { doStaffLogin } from "../components/reducers/actions";
import PasswordInput from "../components/inputs/PasswordInput";
import TextInput from "../components/inputs/TextInput";
import Header from "../components/Header";

const StaffLogin = (props) => {
  const [values, setValues] = useState({
    country_code: "+91",
    password: "",
    phone_number: "",
  });
  const [errors, setErrors] = useState({});
  const [requestErrors, setRequestErrors] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ["phone_number", "password"];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    if (
      values.phone_number &&
      (!values.phone_number || values.phone_number.length !== 10)
    ) {
      updatedErrors.phone_number = "Invalid phone number";
      if (showPassword) {
        setShowPassword(false);
      }
    } else if (!updatedErrors.phone_number) {
      if (!showPassword) {
        setShowPassword(true);
      }
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    setRequestErrors(null);
    validateValues(updatedValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var data = { ...values };
    data.country_code = "+91";

    const resp = await props.onLogin(data);
    if (resp && resp.response) {
      window.location.href = "/app/complaints";
    } else {
      let message = "Something went wrong. Please try again";
      if (resp.error && resp.error.data && resp.error.data.non_field_errors) {
        message = resp.error.data.non_field_errors[0];
      } else if (resp.error && resp.error.data && resp.error.data.message) {
        message = resp.error.data.message;
      }
      setRequestErrors(message);
    }
  };

  return (
    <>
      <Header activeMenu="Login" />
      <div className="container p-5">
        <div className="card o-hidden border-0 shadow-lg my-4">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <Image
                  style={{ width: "100%", height: "auto" }}
                  src="https://ww1.prweb.com/prfiles/2017/11/07/14894968/Food-Safety.jpg"
                ></Image>
              </div>
              <div className="col-lg-6">
                <div className="p-5 my-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                    <h5 className="text-gray-900 mb-3">Staff Login</h5>
                  </div>
                  <div>
                    {requestErrors ? (
                      <Alert variant="danger">{requestErrors}</Alert>
                    ) : null}
                  </div>
                  <Form className="user" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <TextInput
                        placeholder="Phone number"
                        type="text"
                        name="phone_number"
                        error={errors.phone_number}
                        value={values.phone_number}
                        onChange={(e) =>
                          handleTextChange("phone_number", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <PasswordInput
                        placeholder="Password"
                        type="text"
                        name="password"
                        error={errors.password}
                        value={values.password}
                        onChange={(e) =>
                          handleTextChange("password", e.target.value)
                        }
                      />
                    </div>
                    {showPassword ? (
                      <div className="d-flex mb-2">
                        <div className="flex small text-left mb-2 w-50">
                          <Link
                            to={{
                              pathname: "/forgot-password",
                              state: {
                                phoneNumber: values.phone_number,
                                countryCode: values.country_code,
                                action: "forgotPassword",
                                userType: "staff",
                              },
                            }}
                          >
                            Forgot Password ?
                          </Link>
                        </div>
                        <div className="flex small text-right mb-2 w-50">
                          <Link
                            to={{
                              pathname: "/forgot-password",
                              state: {
                                phoneNumber: values.phone_number,
                                countryCode: values.country_code,
                                userType: "staff",
                              },
                            }}
                          >
                            Login with OTP ?
                          </Link>
                        </div>
                      </div>
                    ) : null}
                    <button
                      disabled={disableSubmit}
                      type="submit"
                      className="btn btn-primary-green btn-user btn-block mb-3"
                    >
                      Login
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

StaffLogin.propsType = {
  onLogin: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onLogin: (loginUser) => dispatch(doStaffLogin(loginUser)),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  error: state.home.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffLogin);
