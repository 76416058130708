import React from "react";

const AddSample = (props) => {

    const reportNames = [
       
        {
            label: "From Existing Complaint",
            name: "/app/reports/do_sample_reports",
        },
        {
            label: "New Sample Entry",
            name: "/app/reports/fso_sample_reports",
        },
        {
            label: "From Surveillance to Act",
            name: "/app/reports/do-direct-reports",
        },
       
    ]

    const renderCard = (card, index) => {
        return (
            <div className="card-deck box" key={index}>
                <div className="card">
                    <div className="card-body text-center">
                        <button onClick={() => props.history.push(card.name)}
                            className="btn report-card" type="button"> {card.label}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <h3>Sample Entry Reports</h3>
            <div className="grid">{reportNames.map(renderCard)}</div>
        </div>
    )
}

export default AddSample;
