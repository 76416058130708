import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const AdjudicationDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    const [name, setName] = useState([])
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            let url = props.history.location.state.records.upload_document_every_orders_of_adjudication_officer;
            setName(url);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
      return wardName;
  };
    
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Adjudication Case Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
              <tr>
                <th>Date</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Whether Case Filed For Appeal</th>
                 <td className="text-left">{records.fbo_filed_for_appeal}</td>
                </tr>
                <tr>
                  <th>Appeal Filed At</th>
                   <td className="text-left">{records.appeal_filed_at}</td>
                </tr>
              <tr>
                <th>Whether Interim Order Generated</th>
                <td className="text-left">{records.interim_order_generated}</td>
              </tr>
              {records.order_upload && <tr>
                <th>Interim Order Upload</th>
                 <td className="text-left">{records.order_upload}</td>
                </tr>  }           
              <tr>
                <th>Case Filed By </th>
                <td className="text-left">{records.case_file_filed_by}</td>
              </tr>
   
             {records.other_case_filed_by &&  <tr>
                <th>Case Filed By -Others</th>
                <td className="text-left">{records.other_case_filed_by}</td>
              </tr>}
              <tr>
                <th>Appeal Case Filed Court Name</th>
                <td className="text-left">{records.court_name}</td>
              </tr>
              <tr>
                <th>Appeal Case Filed  at Court On</th>
                <td className="text-left">{moment(records.date_act_court_date).format("l")}</td>
              </tr>
              <tr>
                <th>Case Number</th>
                <td className="text-left">{records.case_number}</td>
              </tr>
              <tr>
                <th>First Hearing Date</th>
                <td className="text-left">{moment(records.date_first_hearing_date).format("l")}</td>
              </tr>
              <tr>
                <th>Next Hearing Date</th>
                <td className="text-left">{moment(records.date_next_hearing_date).format("l")}</td>
              </tr>
              <tr>
                <th>Judgement Date</th>
                <td className="text-left">{moment(records.date_judgement_date).format("l")}</td>
              </tr>
             {records.judgement_status && <tr>
                <th>Judgement Status</th>
                <td className="text-left">{records.judgement_status}</td>
              </tr> }
              {records.judgement_status_of && <tr>
                <th>Judgement Status</th>
                <td className="text-left">{records.judgement_status_of}</td>
              </tr>  }    
              {records.judgement_status_of && <tr>
                <th>Convicted With Penalty</th>
                <td className="text-left">{records.convicted_with_penalty}</td>
              </tr>  }   
              {records.judgement_status_of && <tr>
                <th>Judgement Upload</th>
                <td className="text-left">{records.jugdgement_upload}</td>
              </tr>  }     
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(AdjudicationDetails);