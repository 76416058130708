import { useD3 } from "./UseD3BarChart";
import React, { useEffect } from "react";
import * as d3 from "d3";
import tip from "d3-tip";
function BarChart({ data }) {

  const ref = useD3(
    (svg) => {
      const height = 250;
      const width = 350;
      const margin = { top: 10, right: 10, bottom: 50, left: 40 };
      const x = d3
        .scaleBand()
        .domain(data.map((d) => d.status))
        .rangeRound([margin.left, width - margin.right])
        .padding(0.5);
      const y1 = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.count)])
        .rangeRound([height - margin.bottom, margin.top]);
      const xAxis = (g) =>
        g.attr("transform", `translate(0,${height - margin.bottom})`).call(
          d3
            .axisBottom(x)
            .tickValues(data.map((d) => d.status))
            .tickSizeOuter(0)
        )
        .selectAll("text")  
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-25)" );
      const tooltip = tip()
        .attr("class", "d3-tip")
        .html(function (e, d) {
          return "<strong>Count:</strong> <span>" + d.count + "</span>";
        });
      svg.call(tooltip);
      const y1Axis = (g) =>
        g
          .attr("transform", `translate(${margin.left},0)`)
          .call(d3.axisLeft(y1).ticks(null, "s"))
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .text(data.y1)
          );
      svg.select(".x-axis").call(xAxis);
      svg.select(".y-axis").call(y1Axis);

      svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-size", "x-small")
      .text("Complaint Counts");
      svg
        .select(".plot-area")
        .attr("fill", "rgb(58, 173, 173)")
        .selectAll(".bar")
        .data(data)
        .join("rect")
        .attr("class", "bar")
        .attr("x", (d) => x(d.status))
        .attr("width", x.bandwidth())
        .attr("y", (d) => y1(d.count))
        .attr("height", (d) => y1(0) - y1(d.count))
        .on("mouseover", tooltip.show)
        .on("mouseout", tooltip.hide);
    },
    [data]
  );
  return (
   data && data.length !==0 ?
    <svg
      ref={ref}
      style={{
        height: 250,
        width: "100%",
        marginRight: "0px",
        marginLeft: "0px",
      }}
    >
      <g className="plot-area" />
      <g className="x-axis" />
      <g className="y-axis" />
    </svg> :
    <>
              <div style={{marginTop:90, marginBottom:90}}>No Data Available</div>

    </>
  );
}
export default BarChart;