import React, { useEffect,useState } from "react";

const TextAreaInput = (props) => {
  const [first, setfirst] = useState('')
  const [err, seterr] = useState(false)
  const errorMessage = props.error ? (
    <span className="error-message">{props.error}</span>
  ) : (
    <span className="error-message"></span>
  );
  const htmlTagsRegex = /<\/?[a-z][\s\S]*>/i;
  const validateInputValue=(value)=>{
    if (htmlTagsRegex.test(value)) {
      seterr(true)
      setfirst('')
    }
    else{
      seterr(false)
      setfirst(value)
    }
  }
  useEffect(()=>{
    validateInputValue(props?.value)
},[props?.value])
  return (
    <>
      <textarea
        className="form-control form-control-user"
        placeholder={props.placeholder}
        name={props.name}
        autoComplete="off"
        value={
         first === null || typeof first === "undefined"
            ? ""
            : first
        }
        onChange={props.onChange}
      ></textarea>
      {errorMessage}
      {err?<span className="error-message">Input contains unsupported characters. Please only use letters, numbers.</span>:null}

    </>
  );
};

export default TextAreaInput;
