import React from "react";

const DisplayReports = (props) => {

    const reportNames = [
        {
            label: "FBO Report List",
            name: "/app/display-reports/fbo-list",
        },
        {
            label: "Licencing Report List",
            name: "/app/display-reports/licensing-list",
        },
        {
            label: "Registration Report List",
            name: "/app/display-reports/registration-list",
        },
        {
            label: "FSO EPO Report List",
            name: "/app/display-reports/fso-EPO-list",
        },
        {
            label: "DO EPO Report List",
            name: "/app/display-reports/do-EPO-list",
        },
        {
            label: "Commissioner EPO Report List",
            name: "/app/display-reports/comissioner-EPO-list",
        },
        // {
        //     label: "Commissionerate EPO Report List",
        //     name: "/app/display-reports/comissionerate-EPO-list",
        // },
       
        // {
        //     label: "IEC Report List",
        //     name: "/app/display-reports/iec-list",
        // },
       
        {
            label: "Slaughter Report List",
            name: "/app/display-reports/slaughter-list",
        },
        {
            label: "Ripening of Fruits with Carbide Stone/Gas Report List",
            name: "/app/display-reports/ripening-mangoes-list",
        },
        // {
        //     label: "FSSA Act Sample List",
        //     name: "/app/display-reports/fssaact-list",
        // },
        
       
        {
            label: "Packaged Drinking Water Report List",
            name: "/app/display-reports/pdw-list",
        },
        {
            label: "Hotels and Restaurants Report List",
            name: "/app/display-reports/hotels-and-restaurants-list",
        },
     
        // {
        //     label: "Special Programme Report List",
        //     name: "/app/display-reports/special-program-list",
        // },       
        {
            label: "School/College Inspection Report List",
            name: "/app/display-reports/school-list",
        },
        {
            label: "Tour Journal Report List",
            name: "/app/display-reports/tourjournal-list",
        },
        {
            label: "Foscoris Digital Inspection Report List",
            name: "/app/display-reports/foscorisdigital-list",
        },
        {
            label: "Compound Penalty Report List",
            name: "/app/display-reports/compound-penalty-list",
        },
        {
            label: "TN Foodsafety and Drug Administration Dept Daily Report List",
            name: "/app/display-reports/tndaily-list",
        },
        {
            label: "Enumeration Data Report List",
            name: "/app/display-reports/enumeration-data-list",
        },
        {
            label: "Information Education Report List",
            name: "/app/display-reports/information-education-list",
        },
        {
            label: "Notice Issued Report List",
            name: "/app/display-reports/notice-issued-list",
        },
        // {
        //     label: "L and R Enumeration of FBO's Report List",
        //     name: "/app/display-reports/enumeration-of-fbo-list",
        // },
        {
            label: "Eat Right Training Toolkit Report List",
            name: "/app/display-reports/eatright-training-list/",  
        },
        {
            label: "Panmasala and Gutkha Report List",
            name: "/app/display-reports/panmasala-list",
        },
        // {
        //     label: "Criminal Cases (Appeal Cases) Report List",
        //     name: "/app/display-reports/high-courtcase-list",
        // },
        // {
        //     label: "Civil Case Report List",
        //     name: "/app/display-reports/adjudication-case-list",
        // },
        // {
        //     label: "Adjudication Appeal Report List",
        //     name: "/app/display-reports/adjudication-appeal-list",
        // },
        // {
        //     label: "Surveillance Sample Collected Report List",
        //     name: "/app/display-reports/ssc-list",
        // },
        {
            label: "Advance Tour and Programme Report List",
            name: "/app/display-reports/tourprogram-list",
        },
       
        {
            label: "Tasmac shops and Bar Report List",
            name: "/app/display-reports/tasmac-list",
        },
        {
            label: "DLAC Report List",
            name: "/app/display-reports/dlac-list",
        },
       
        {
            label: "Food Fortification Report List",
            name: "/app/display-reports/food-fortification-list",
        },
        
        {
            label: "Food Seizure Report List",
            name: "/app/display-reports/seizure-list",
        },
     
        {
            label: "Vehicle Inspection Report List",
            name: "/app/display-reports/vehicle-inspection-list",
        },
        // {
        //     label: "FSSA-ACT Samples Report List",
        //     name: "/app/display-reports/fssaact-list",
        // },
       
        {
            label: "Special Drive Of L and R Report List",
            name: "/app/display-reports/special-drive-list",
        },
        {
            label: "Hygine Rating Report List",
            name: "/app/display-reports/hygine-rating-list/",
        },
        {
            label: "FoSTaC Training Report List",
            name: "/app/display-reports/fostac-training-list/",
        },   
        {
            label: "Clean Street Food Hub Certification Report List",
            name: "/app/display-reports/clean-food-list/",  
        },
        {
            label: "Clean Fruits/Vegetables Certification Report List ",
            name: "/app/display-reports/market-list/",  
        },
        {
            label: "Blissful Hygine Offering to God Certification Report List",
            name: "/app/display-reports/worship-place-list/",  
        },
        
        {
            label: "Eat Right School Certification Report List",
            name: "/app/display-reports/eatright-school-list/",  
        },
        {
            label: "Eat Right Campus Certification Report List",
            name: "/app/display-reports/eatright-campus-list/",  
        },
        {
            label: "RUCO Certification Report List",
            name: "/app/display-reports/ruco-certification-list/",  
        },
        {
            label: "RUCO Collection And Conversion Of Biodiesel Report List",
            name: "/app/display-reports/ruco-biodiesel-list/",  
        },
        {
            label: "UCO  Report List",
            name: "/app/display-reports/uco-list/",  
        },
        {
            label: "No Food Waste Certification Report List",
            name: "/app/display-reports/food-waste-list/",  
        },
        {
            label: "No Food Waste Agency Report List",
            name: "/app/display-reports/food-collection-list/",  
        },
        {
            label: "ERC Portal Uploaded Report List",
            name: "/app/display-reports/erc-portal-list/",  
        },
        {
            label: "ERC Activity Report List",
            name: "/app/display-reports/number-list/",  
        } ,               
       
        // {
        //     label: "Monthly Registration Report List",
        //     name: "/app/display-reports/regmonthly-list",
        // },
 
        // {
        //     label: "Monthly License Report List",
        //     name: "/app/display-reports/licensemonthly-list",
        // },

        // {
        //     label: "FOSTAC  Training Report List",
        //     name: "/app/display-reports/fostac-training-list",
        // },
        
        // {
        //     label: "Expected inspection Report List",
        //     name: "/app/display-reports/expected-inspection-list",
        // },
        // {
        //     label: "Food Wrapped in Newspaper inspection Report List",
        //     name: "/app/display-reports/food-in-newspaper-list",
        // },
             
    ]

    const renderCard = (card, index) => {
        return (
            <div className="card-deck box" key={index}>
                <div className="card">
                    <div className="card-body text-center">
                        <button onClick={() => props.history.push(card.name)}
                            className="btn report-card" type="button"> {card.label}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <h1>Reports</h1>
            <div className="grid">{reportNames.map(renderCard)}</div>            
        </div>        
    )
}

export default DisplayReports;