import React from "react";
import { useTranslation } from "react-i18next";

const SelectItem = (props) => {
  const {
    nextStep,
    goBack,
    disableSubmit,
    placeholder,
    value,
    onChangeText,
  } = props;

  const { t } = useTranslation();

  const handleChange = (item) => {
    onChangeText(item.id);
    nextStep();
  };

  const itemList = props.item
    ? props.item.map((item, idx) => {
        return (
          <div
            key={idx}
            onClick={() => handleChange(item)}
            className={`float-container ${item.id === value ? "active" : ""}`}
          >
            <div className="float-child">
              <img width="30" height="30" alt={item.name} src={item.image} />
            </div>
            <div className="float-right-child">{item.name}</div>
          </div>
        );
      })
    : null;

  return (
    <>
      <div className="form-header-title">{t(placeholder)}</div>
      <div className="form-group">{itemList}</div>
      <div className="form-group form-submit">
        <button
          onClick={() => goBack()}
          className="btn btn-success"
          type="button"
        >
          {t("Back")}
        </button>
        <button
          disabled={disableSubmit}
          onClick={() => nextStep()}
          type="button"
          className="btn btn-success ml-3"
        >
          {t("Continue")}
        </button>
      </div>
    </>
  );
};

export default SelectItem;
