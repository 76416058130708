import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const EnumerationDataDetails = (props) => {
    const { districts, wards,traderTypes } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])
    const getFbo = (category_of_fbo) => {
      let fbo = null;
      traderTypes &&
      traderTypes.forEach((item) => {
          if (item.id === category_of_fbo) {
            fbo = item.name;
          }
        });
      return fbo;
  };
    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
    
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Enumeration Data Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
    <th>Area Code</th>
    <td className="text-left">{getAreaCode(records.area_code)}</td>
  </tr>
  <tr>
    <th>Area Name</th>
    <td className="text-left">{records.area_name}</td>
  </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
              
                <tr>
                  <th>Name of the village/Ward</th>
                   <td className="text-left">{records.name_of_the_village}</td>
                </tr>
              <tr>
                <th>Name Of the Street</th>
                <td className="text-left">{records.name_of_the_street}</td>
              </tr>              
              <tr>
                <th>Door/Flat No</th>
                <td className="text-left">{records.door_no}</td>
              </tr>
              <tr>
                <th>Name Of the Owner/Partner</th>
                <td className="text-left">{records.name_of_the_operator_partners}</td>
              </tr>
              <tr>
                <th>Address Of FBO Premisis </th>
                <td className="text-left">{records.address_of_trade}</td>
              </tr>
              <tr>
                <th>Contact Number</th>
                <td className="text-left">{records.contact_number}</td>
              </tr>
              <tr>
                <th>Email ID</th>
                <td className="text-left">{records.email_id}</td>
              </tr>
              <tr>
                <th>Type Of Trader Category</th>
                <td className="text-left">{getFbo(parseInt(records.type_of_trader_category))}</td>
              </tr>
              <tr>
                <th> Food Product Category</th>
                <td className="text-left">{records.product_category}</td>
              </tr>
              <tr>
                <th>Turn Over Per Annum</th>
                <td className="text-left">{records.turnover_annum}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td className="text-left">{records.type}</td>
              </tr>
              <tr>
                <th>Is Registered:</th>
                <td className="text-left">{records.is_registered}</td>
              </tr>
              <tr>
                <th>License/Registration Reference Number</th>
                <td className="text-left">{records.license_registration_refernece_number}</td>
              </tr>
              <tr>
                <th>License/Registration Applied Date</th>
                <td className="text-left">{moment(records.license_registration_applied_date).format("l")}</td>
              </tr>
              <tr>
                <th>License/Registration Number</th>
                <td className="text-left">{records.license_registration_number}</td>
              </tr>
              <tr>
                <th>License/Registration Issued Date</th>
                <td className="text-left">{moment(records.license_registration_received_date).format("l")}</td>
              </tr>
              <tr>
                <th>License/Registration Valid Upto </th>
                <td className="text-left">{moment(records.license_registration_valid_upto_date).format("l")}</td>
              </tr>
             
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards:state.home.wards,
  traderTypes: state.home.traderTypes,

});

export default connect(mapStateToProps, mapDispatchToProps)(EnumerationDataDetails);