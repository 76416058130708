import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import { getDistrictWards, getFsoOfficers } from "../../components/reducers/actions"
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createTasmacReports, editTasmacReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment'




const Desig = [
    {id:'0', name:'FSO'},
    {id:'1', name:'DO'},
]


const TasmacReports = (props) => {
    const { districts, user, wards, onCreateTasmacReports,wardsCategory,ongetDist, onEditTasmacReports,fsoOfficers, onGetFsoOfficers  } = props;
    const [values, setValues] = useState({
        'name_of_the_fso_do' : '',
        'area_code':'',
        'area_name':'',
        'designation':'',
        'license_registration':'',
        'no_of_inspections_shops':'',
        'no_of_notice_issued_shops':'',
        'surveillance_sample_shops':'',
        'act_sample_shops':'',
        'no_of_inspections_bars':'',
        'no_of_notice_issued_bars':'',
        'surveillance_sample_bars':'',
        'act_sample_bars':'',
        'seized_from_shop':'',
        'seized_from_bar':'',
        'district' : '',
        'date' : '',
        'no_of_lic_reg':'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])


    useEffect(() => {
        console.log(props);
        console.log(user.user_types[0]);
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
           
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district,  };
            setValues(userData);
            getFsoList();
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const getFsoList = async () => {
        await onGetFsoOfficers({});
      };

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
           'name_of_the_fso_do','area_code','area_name','license_registration','no_of_inspections_shops','no_of_inspections_bars','designation',
           'no_of_notice_issued_shops','no_of_notice_issued_bars', 'surveillance_sample_shops','surveillance_sample_bars','act_sample_shops',
           'act_sample_bars','district', 'date','seized_from_bar'
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {  
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditTasmacReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/tasmac-list/`);
            };

        } else {     
            const data = { ...values}
            setDisableSubmit(true);
            console.log(data);
            const resp = await onCreateTasmacReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Report of Tasmac shops and Bars</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                         <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                                >Area code *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                    />
                                </div>
                        </div>    
                        <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                                >Area Name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        type="text"
                                        name="area_name"
                                        placeholder="area name"
                                        value={values.area_name}
                                        error={errors.area_name}
                                        onChange={(e) => handleTextChange("area_name", e.target.value)}
                                    />
                                </div>
                        </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_fso_do"
                            >Name of the FSO / DO *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the FSO/DO`}
                                    type="text"
                                    name="name_of_the_fso_do"
                                    id="name_of_the_fso_do"
                                    value={values.name_of_the_fso_do}
                                    error={errors.name_of_the_fso_do}
                                    onChange={(e) => handleTextChange("name_of_the_fso_do", e.target.value)}
                                />
                            </div>
                        </div>
                      <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="designation"
                            >Designation *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    type="text"   
                                    name="designation"
                                    action="dropdown"
                                    value={values.designation}
                                    error={errors.designation}
                                    items={Desig}
                                    onChange={(text) => handleTextChange("designation", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_inspections_shops"
                            >Number of Shop's Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of shops inspected `}
                                    type="number"
                                    name="no_of_inspections_shops"
                                    id="no_of_inspections_shops"
                                    value={values.no_of_inspections_shops}
                                    error={errors.no_of_inspections_shops}
                                    onChange={(e) => handleTextChange("no_of_inspections_shops", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration"
                            >Number of Shop's With Valid License/Registration *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of shops With Valid License/Registration `}
                                    type="number"
                                    name="license_registration"
                                    id="license_registration"
                                    value={values.license_registration}
                                    error={errors.license_registration}
                                    onChange={(e) => handleTextChange("license_registration", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_shops"
                            >Number of Notice Issued *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Notice issued `}
                                    type="number"
                                    name="no_of_notice_issued_shops"
                                    id="no_of_notice_issued_shops"
                                    value={values.no_of_notice_issued_shops}
                                    error={errors.no_of_notice_issued_shops}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_shops", e.target.value)}
                                />
                            </div>
                        </div>   
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="surveillance_sample_shops"
                            >Number of Surveillance Sample Lifted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Surveillance sample lifted `}
                                    type="number"
                                    name="surveillance_sample_shops"
                                    id="surveillance_sample_shops"
                                    value={values.surveillance_sample_shops}
                                    error={errors.surveillance_sample_shops}
                                    onChange={(e) => handleTextChange("surveillance_sample_shops", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="act_sample_shops"
                            >Number of Act Sample Lifted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Act Samples lifted from Shops`}
                                    type="number"
                                    name="act_sample_shops"
                                    id="act_sample_shops"
                                    value={values.act_sample_shops}
                                    error={errors.act_sample_shops}
                                    onChange={(e) => handleTextChange("act_sample_shops", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="seized_from_shop"
                            >Quantity of expired products seized from Tasmac shops *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Quantity of food seized from tasmac shops`}
                                    type="number"
                                    name="seized_from_shop"
                                    id="seized_from_shop"
                                    value={values.seized_from_shop}
                                    error={errors.seized_from_shop}
                                    onChange={(e) => handleTextChange("seized_from_shop", e.target.value)}
                                />
                            </div>
                        </div>                     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_inspections_bars"
                            >Number of Bar's Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of bars inspected`}
                                    type="number"
                                    name="no_of_inspections_bars"
                                    id="no_of_inspections_bars"
                                    value={values.no_of_inspections_bars}
                                    error={errors.no_of_inspections_bars}
                                    onChange={(e) =>handleTextChange("no_of_inspections_bars", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_lic_reg"
                            >Number of Bar's with valid License/Registration *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Bar's with valid License/Registration`}
                                    type="number"
                                    name="no_of_lic_reg"
                                    id="no_of_lic_reg"
                                    value={values.no_of_lic_reg}
                                    error={errors.no_of_lic_reg}
                                    onChange={(e) =>handleTextChange("no_of_lic_reg", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_bars"
                            >Number of Notice Issued Bar's *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of notice issued bar's`}
                                    type="number"
                                    name="no_of_notice_issued_bars"
                                    id="no_of_notice_issued_bars"
                                    value={values.no_of_notice_issued_bars}
                                    error={errors.no_of_notice_issued_bars}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_bars", e.target.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="surveillance_sample_bars"
                            >Number of Surveillance Sample Lifted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Surveillance sample lifted `}
                                    type="number"
                                    name="surveillance_sample_bars"
                                    id="surveillance_sample_bars"
                                    value={values.surveillance_sample_bars}
                                    error={errors.surveillance_sample_bars}
                                    onChange={(e) => handleTextChange("surveillance_sample_bars", e.target.value)}
                                />
                            </div>
                        </div>
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="act_sample_bars"
                            >Number of bar's with Act Sample Lifted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Act Samples lifted from bars`}
                                    type="number"
                                    name="act_sample_bars"
                                    id="act_sample_bars"
                                    value={values.act_sample_bars}
                                    error={errors.act_sample_bars}
                                    onChange={(e) => handleTextChange("act_sample_bars", e.target.value)}
                                />
                            </div>
                        </div>    
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="seized_from_bar"
                            >Quantity of Expired/Spoiled food seized from Bars *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Quantity of Expired/Spoiled food seized from bars`}
                                    type="number"
                                    name="seized_from_bar"
                                    id="seized_from_bar"
                                    value={values.seized_from_bar}
                                    error={errors.seized_from_bar}
                                    onChange={(e) => handleTextChange("seized_from_bar", e.target.value)}
                                />
                            </div>
                        </div>             
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateTasmacReports: (data) => dispatch(createTasmacReports(data)),
    onEditTasmacReports: (data, record_id) => dispatch(editTasmacReports(data, record_id)),
    onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),


});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    fsoOfficers: state.home.fsoOfficers,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(TasmacReports);