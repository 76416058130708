import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createRUCOBiodieselReports, editRUCOBiodieselReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const RUCOBioDieselReport = (props) => {
    const { districts, user,wards, onCreateRUCOBiodieselReports,wardsCategory,ongetDist, onEditRUCOBiodieselReports } = props;
    const [values, setValues] = useState({
        'district': '',
        'date': '',
        'area_code': '',
        "area_name": '',
        'name_of_fbo': '',
        'address_of_fbo':'',
        'no_of_kilolitres_sent_for_biodiesel': '',
        'agency_name':'',
        'address_of_agency':"",
        'documents': ''

    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if (user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'name_of_fbo', 'address_of_fbo','agency_name','address_of_agency','no_of_kilolitres_sent_for_biodiesel', 'area_name', 'area_code'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if (!values[key] || values[key] === '') {
                updatedErrors[key] = ' ';
            }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values }
            const resp = await onEditRUCOBiodieselReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/ruco-biodiesel-list/`);
            };

        } else {
            const data = { ...values }
            setDisableSubmit(true);
            const resp = await onCreateRUCOBiodieselReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">RUCO Collection and Conversion Of BioDiesel Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date Of Certification *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_fbo"
                            >Name Of the FBO *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the FBO`}
                                    type="text"
                                    name="name_of_fbo"
                                    value={values.name_of_fbo}
                                    error={errors.name_of_fbo}
                                    onChange={(e) => handleTextChange("name_of_fbo", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_fbo"
                            >Address Of the FBO *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the FBO`}
                                    type="text"
                                    name="address_of_fbo"
                                    value={values.address_of_fbo}
                                    error={errors.address_of_fbo}
                                    onChange={(e) => handleTextChange("address_of_fbo", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="agency_name"
                            >Name Of the Agency *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Agency`}
                                    type="text"
                                    name="agency_name"
                                    value={values.agency_name}
                                    error={errors.agency_name}
                                    onChange={(e) => handleTextChange("agency_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_agency"
                            >Address Of the Agency *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Agency`}
                                    type="text"
                                    name="address_of_agency"
                                    value={values.address_of_agency}
                                    error={errors.address_of_agency}
                                    onChange={(e) => handleTextChange("address_of_agency", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_kilolitres_sent_for_biodiesel"
                            >Number Of Kilolitres Sent For Biodiesel *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No Of kilolitres Sent for Biodiesel`}
                                    type="number"
                                    name="no_of_kilolitres_sent_for_biodiesel"
                                    value={values.no_of_kilolitres_sent_for_biodiesel}
                                    error={errors.no_of_kilolitres_sent_for_biodiesel}
                                    onChange={(e) => handleTextChange("no_of_kilolitres_sent_for_biodiesel", e.target.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateRUCOBiodieselReports: (data) => dispatch(createRUCOBiodieselReports(data)),
    onEditRUCOBiodieselReports: (data, record_id) => dispatch(editRUCOBiodieselReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});

export default connect(mapStateToProps, mapDispatchToProps)(RUCOBioDieselReport);