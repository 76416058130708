import React from "react";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import maps from '../../assets/img/maps.png'

const AboutDepartment = () => {
  const { t, i18n } = useTranslation();

  return (
    <div id="main" style={{ backgroundColor: "#fff" }}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">{t("FSD")}</h1>
              {/* <p className="page-header-ui-text mb-0">{t("TN")}</p> */}
            </div>
          </div>
        </div>
      </header>
      <div style={{fontSize:17}} className="department">
        <p>
          {/* {" "}
          {t("introduction")}
          <br />
          <br />
          {t("int")}
          <br />
          {t("int1")}
          <br />
          {t("int2")}
          <br />
          {t("int3")}
          <br />
          {t("int4")}
          <br />
          {t("int5")}
          <br />
          {t("int6")}
          <br />
          {t("int7")}
          <br />
          {t("int8")}
          <br /> */}
          <img  src={maps} style={{float:'right', marginLeft:'8px'}} width='400px' height='500px' />
          {t("As1")}<br /><br />
          {t("As2")}<br /><br />
          {t("As3")}<br /><br />
          {t("As4")}<br /><br />
          {t("As5")}<br /><br />
          {t("As6")}<br />
        </p>
        {/* <h6>{t("Creation of New Department")}</h6>
        <p>{t("creation")}</p>
        <h6>{t("Appointment of Commissioner of Food Safety")}</h6>
        <p>{t("Appointment")}</p>
        <h6> {t("Appointment of Designated Officers (DOs)")}</h6>
        <p>{t("DO'S")}</p>
        <h6>{t("Appointment of Food Safety Officers (FSOs)")}</h6>
        <p>{t("FSO'S")}</p>
        <h6>{t("Appointment of Adjudicating Officers")}</h6>
        <p>{t("AO'S")}</p>
        <h6>{t("AT")}</h6>
        <p>{t("ATS")}</p> */}
       
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AboutDepartment);
