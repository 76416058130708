import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const SchoolDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
 
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">School and College Inspection Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
            <th>Area Code</th>
            <td className="text-left">{getAreaCode(records.area_code)}</td>
          </tr>
          <tr>
            <th>Area Name</th>
            <td className="text-left">{records.area_name}</td>
          </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>

               {records&&records.no_of_school_college_attended_upto_the_month&& <tr>
                  <th>Number of schools/colleges canteen Available </th>
                 <td className="text-left">{records.no_of_school_college_attended_upto_the_month}</td>
                </tr>}
               {records&&records.schools_inspected&& <tr>
                  <th>Total Number of schools  canteen Inspected During The Month</th>
                   <td className="text-left">{records.schools_inspected}</td>
                </tr>}
                {records && records.college_inspected && <tr>
                  <th>Total Number of  colleges canteen Inspected During The Month</th>
                   <td className="text-left">{records.college_inspected}</td>
                </tr>}            
             {records&&records.shop_sale_tobacco_products&& <tr>
                <th>Number of shops found sale of tobacco products etc..</th>
                <td className="text-left">{records.shop_sale_tobacco_products}</td>
              </tr>}
              {records&&records.chocolates_products&&<tr>
                <th>Number of shops found sale of Chocolates with intoxicants </th>
                <td className="text-left">{records.chocolates_products}</td>
              </tr>}
             {records&&records.no_of_shops_found&& <tr>
                <th>Number of shops Found With Expired Food Products</th>
                <td className="text-left">{records.no_of_shops_found}</td>
              </tr>}
             {records&&records.samples_lifted&& <tr>
                <th>Number of samples lifted</th>
                <td className="text-left">{records.samples_lifted}</td>
              </tr> }
              <tr>
                <th>Type of offense</th>
                <td className="text-left">{records.type_of_offense}</td>
              </tr>
              <tr>
                <th>Sample type</th>
                <td className="text-left">{records.type}</td>
              </tr>
              <tr>
                <th>Sample safe</th>
                <td className="text-left">{records.sample_safe}</td>
              </tr>
              <tr>
                <th>Number of samples analyzed</th>
                <td className="text-left">{records.samples_analysed}</td>
              </tr>
              <tr>
                <th>Number of samples found unsafe</th>
                <td className="text-left">{records.samples_found_unsafe}</td>
              </tr>
                <tr>
                  <th>Number of samples found SS/MB etc</th>
                  <td className="text-left">{records.samples_found_ssmb}</td>
                </tr>
                <tr>
                  <th>Number of samples found Safe</th>
                  <td className="text-left">{records.sample_safe}</td>
                </tr>
                <tr>
                  <th>Action taken </th>
                  <td className="text-left">{records.action_taken}</td>
                </tr>
                <tr>
                  <th>Product sent </th>
                  <td className="text-left">{records.prod_sent}</td>
                </tr>
                <tr>
                  <th>Product amount </th>
                  <td className="text-left">{records.prod_amt}</td>
                </tr>
                <tr>
                  <th>Penalty Imposed </th>
                  <td className="text-left">{records.penalty_imposed}</td>
                </tr>
                <tr>
                  <th>Peanlty Paid </th>
                  <td className="text-left">{records.penalty_paid}</td>
                </tr>
                <tr>
                  <th>Upload Document</th>
                  <td className="text-left">{records.upload_documents}</td>
                </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDetails);