import React from "react";

const GeneralReports = (props) => {

    const reportNames = [
        {
            label: "Master Data Report",
            name: "/app/reports/complaint-list/",
        },
        {
            label: "Over-All Report",
            name: "/app/reports/complaint-action/",
        },
        {
            label: "Fine Details Report ",
            name: "/app/reports/complaint-status/",
        },
    
        {
            label: "Seizure Details Report",
            name: "/app/reports/complaint-seized/",
        },
        {
            label: "Notice Issued Details Report ",
            name: "/app/reports/complaint-notice/",
        },
        {
            label: "Sample Lifted Details Report",
            name: "/app/reports/complaint-sample/",
        },
        {
            label: "Closed Complaint Report",
            name: "/app/reports/complaint-closed/",
        },
        {
            label: "Open Complaints Line report",
            name: "/app/reports/line-open-list/",
        },
        {
            label: "Closed Complaint Line Report",
            name: "/app/reports/line-closed-list/",
        },
       
        
             
    ]

    const renderCard = (card, index) => {
        return (
            <div className="card-deck box" key={index}>
                <div className="card">
                    <div className="card-body text-center">
                        <button onClick={() => props.history.push(card.name)}
                            className="btn report-card" type="button"> {card.label}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <h1>General Reports</h1>
            <div className="grid">{reportNames.map(renderCard)}</div>            
        </div>        
    )
}

export default GeneralReports;