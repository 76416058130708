import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const FoodInNewspaperDetails = (props) => {
    const { districts } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }   
        // eslint-disable-next-line 
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Food found In Newspaper Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Name of FSO</th>
                 <td className="text-left">{records.name_of_fso}</td>
                </tr>
                <tr>
                  <th>FSO Area code</th>
                   <td className="text-left">{records.fso_area_code}</td>
                </tr>
              <tr>
                <th>Number of Inspected</th>
                <td className="text-left">{records.no_of_inspected}</td>
              </tr>              
              <tr>
                <th>Number of Shops found wrapping food in newspaper</th>
                <td className="text-left">{records.no_of_shops_found_wrapping_food_in_newspaper}</td>
              </tr>
              <tr>
                <th>Destroyed Qty of food wrapping in newspaper (in Kgs)</th>
                <td className="text-left">{records.destroyed_qty_of_food_wrapping_food_in_newspaper}</td>
              </tr>
              <tr>
                <th>Approximte value of detroyed food (in Rs)</th>
                <td className="text-left">{records.approximate_value_of_destroyed_food}</td>
              </tr>
              <tr>
                <th>Number of FBO's given notice</th>
                <td className="text-left">{records.no_of_fbos_given_notice}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodInNewspaperDetails);