import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const ComissionerEPODetails = (props) => {
    const { districts, traderTypes, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
      return wardName;
  };
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Comissioner EPO Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>EPO File Received From APP Grade I On</th>
                 <td className="text-left">{moment(records.date_EPO_file_received_from_app_grade_I_on).format("l")}</td>
                </tr>
                <tr>
                  <th>EPO File Approved On</th>
                 <td className="text-left">{moment(records.date_EPO_file_approved_on).format("l")}</td>
                </tr>
                
              <tr>
                <th>EPO File Sent to APP Grade I On</th>
                <td className="text-left">{moment(records.date_EPO_file_sent_to_app_grade_I_on).format("l")}</td>
              </tr>    
           {records&&records.date_epo_file_received_from_do&&records.date_epo_file_received_from_do&&
           <tr>
           <th>EPO File received from DO On</th>
           <td className="text-left">{moment(records.date_epo_file_received_from_do).format("l")}</td>
         </tr>}
         {records&&records.date_epo_file_received_from_app_on&&records.date_epo_file_received_from_app_on&&
           <tr>
           <th>EPO File received from APP On</th>
           <td className="text-left">{moment(records.date_epo_file_received_from_app_on).format("l")}</td>
         </tr>} 
         {records&&records.date_epo_order_send_to_Section&&records.date_epo_order_send_to_Section&&
           <tr>
           <th>EPO order sent to section</th>
           <td className="text-left">{moment(records.date_epo_order_send_to_Section).format("l")}</td>
         </tr>}    
         {records&&records.date_epo_order_issued&&records.date_epo_order_issued&&
           <tr>
           <th>EPO order issued on</th>
           <td className="text-left">{moment(records.date_epo_order_issued).format("l")}</td>
         </tr>} 
         {records&&records.date_epo_order_send_to_do_on&&records.date_epo_order_send_to_do_on&&
           <tr>
           <th>EPO order issued on</th>
           <td className="text-left">{moment(records.date_epo_order_send_to_do_on).format("l")}</td>
         </tr>}     
         {records&&records.date_final_epo&&records.date_final_epo&&
           <tr>
           <th>EPO order issued on</th>
           <td className="text-left">{moment(records.date_final_epo).format("l")}</td>
         </tr>}
         {records&&records.date_epo_order_issued&&records.date_epo_order_issued&&
           <tr>
           <th>EPO order issued on</th>
           <td className="text-left">{moment(records.date_epo_order_issued).format("l")}</td>
         </tr>}
         {records&&records.upload_docs&&records.upload_docs&&
           <tr>
           <th>Upload Documents</th>
           <td className="text-left">{records.upload_docs}</td>
         </tr>}
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComissionerEPODetails);