import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { validateUser } from "../utils/validateUser"
import { Navbar, Accordion, Card, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { logout } from "./reducers/actions";
import logo from '../assets/img/logo.png'


const Sidebar = (props) => {
  const [activeId, setActiveId] = useState("0");
  const [selectedMenu, setSelectedMenu] = useState(null);

  const { user } = props;

  const { t } = useTranslation();

  const masterData = [
    {
      label: "Trader Types",
      name: "/app/trader-types/",
    },
    {
      label: "Food Types",
      name: "/app/food-types/",
    },
    {
      label: "Food Conditions",
      name: "/app/food-conditions/",
    },
    {
      label: "Food Issues",
      name: "/app/food-issues/",
    },
  ];

  const sideBarMenu = [
    {
      label: t("Dashboard"),
      icon: "fa fa-rocket",
      name: "/app/dashboard/",
      visible:
      (user && user.user_types && user.user_types.includes("ho")) || 
      (user && user.user_types && user.user_types.includes("do")) 
      // ||
      // (user && user.user_types && user.user_types.length === 0)
          ? true
          : false,
    },
    {
      label: t("Complaints"),
      icon: "fa fa-list",
      name: "/app/complaints",
      visible: true,
    },
    {
      label: `Stock Details`,
      icon: "fa fa-folder",
      name: "/app/stock/add",
      visible: user && user?.id &&user?.id  == '3461' ? true:false,
    },
    {
      label: `Delete Complaint`,
      icon: "fa fa-list",
      name: "/app/delete/complaint",
      visible: user && user?.id &&user?.id  == '3' ? true:false,
    },
    {
      label: t("Users"),
      icon: "fa fa-user",
      name: "/app/users",
      visible:
        (user && user.user_types && user.user_types.includes("ho"))||(user && user.user_types && user.user_types.includes("do"))
          ? true
          : false,
    },
    // {
    //   label: t("Finance Report"),
    //   icon: "fa fa-folder",
    //   name: "/app/finance-reports",
    //   visible:
    //   (user && user.user_types && user.user_types.includes("ho"))|| 
    //   (user && user.user_types && user.user_types.includes("labadmin"))|| 
    //   (user && user.user_types && user.user_types.includes("do"))||
    //   (user && user.user_types && user.user_types.includes("dd"))||
    //   (user && user.user_types && user.user_types.includes("commissioner"))||
    //   (user && user.user_types && user.user_types.includes("ac"))? true
    //       : false,
    // },
    // {
    //   label: t("Finance Report List"),
    //   icon: "fa fa-folder",
    //   name: "/app/finance-reports-list",
    //   visible:
    //   (user && user.user_types && user.user_types.includes("ho"))|| 
    //   (user && user.user_types && user.user_types.includes("do"))||
    //   (user && user.user_types && user.user_types.includes("labadmin"))||
    //   (user && user.user_types && user.user_types.includes("dd"))||
    //   (user && user.user_types && user.user_types.includes("commissioner"))||
    //   (user && user.user_types && user.user_types.includes("ac"))? true
    //       : false,
    // },
    // {
    //   label: t("Target Reports"),
    //   icon: "fa fa-folder",
    //   name: "/app/target_reports",
    //   visible:
    //     (user && user.user_types && user.user_types.includes("ho"))|| 
    //     (user && user.user_types && user.user_types.includes("do")) || 
    //     (user && user.user_types && user.user_types.length === 0)
    //       ? true
    //       : false,
    // },
    // {
    //   label: t("Staff Positioning Reports"),
    //   icon: "fa fa-folder",
    //   name: "/app/staff_reports",
    //   visible:
    //     (user && user.user_types && user.user_types.includes("ho"))|| 
    //     (user && user.user_types && user.user_types.includes("fso")) || 
    //     (user && user.user_types && user.user_types.includes("do")) || 
    //     (user && user.user_types && user.user_types.length === 0)
    //       ? true
    //       : false,
    // },

    
          
    //   {
    //   label: t("Sample Reports"),
    //   icon: "fa fa-folder",
    //   name: "/app/sample-reports",
    //   visible:
    //     (user && user.user_types && user.user_types.includes("ho"))|| 
    //     (user && user.user_types && user.user_types.includes("fso"))|| 
    //     (user && user.user_types && user.user_types.includes("do")) 
        
    //       ? true
    //       : false,
    // },
    {
      label: 'Sample Data Entry',
      icon: "fa fa-folder",
      name: "/app/dep-sample-reports",
      visible:
        // (user && user.user_types && user.user_types.includes("ho"))|| 
        (user && user.user_types && user.user_types.includes("fso"))|| 
        // (user && user.user_types && user.user_types.includes("so"))|| 
        (user && user.user_types && user.user_types.includes("do"))||
        // (user && user.user_types && user.user_types.includes("dd"))||
        // (user && user.user_types && user.user_types.includes("APP-I"))||
        // (user && user.user_types && user.user_types.includes("APP-II"))||
        // (user && user.user_types && user.user_types.includes("APP-II(Lab)"))||
        // (user && user.user_types && user.user_types.includes("commissioner"))||
        // (user && user.user_types && user.user_types.includes("Assistant Commissioner"))||
        (user && user.user_types && user.user_types.includes("cfa")) 
        
          ? true
          : false,
    },
    {
      label: 'Sampling Details',
      icon: "fa fa-folder",
      name: "/app/dep-case-reports/",
      visible:
        (user && user.user_types && user.user_types.includes("ho"))|| 
        (user && user.user_types && user.user_types.includes("fso"))|| 
        (user && user.user_types && user.user_types.includes("so"))|| 
        (user && user.user_types && user.user_types.includes("do"))||
        (user && user.user_types && user.user_types.includes("AO"))||
        (user && user.user_types && user.user_types.includes("dd"))||
        (user && user.user_types && user.user_types.includes("oneapp"))||
        (user && user.user_types && user.user_types.includes("twoapp"))||
        (user && user.user_types && user.user_types.includes("labapp"))||
        (user && user.user_types && user.user_types.includes("commissioner"))||
        (user && user.user_types && user.user_types.includes("ac"))||
        (user && user.user_types && user.user_types.includes("apt"))
        
          ? true
          : false,
    },
    {
      label:user && user.user_types && user.user_types.includes("cfa")?'Sample from different sources':'Non Sampling Data Entry',
      icon: "fa fa-folder",
      name: "/app/dep-non-sample-reports",
      visible:
        // (user && user.user_types && user.user_types.includes("ho"))|| 
        (user && user.user_types && user.user_types.includes("fso"))|| 
        // (user && user.user_types && user.user_types.includes("so"))|| 
        (user && user.user_types && user.user_types.includes("do"))||
        // (user && user.user_types && user.user_types.includes("dd"))||
        // (user && user.user_types && user.user_types.includes("APP-I"))||
        // (user && user.user_types && user.user_types.includes("APP-II"))||
        // (user && user.user_types && user.user_types.includes("APP-II(Lab)"))||
        // (user && user.user_types && user.user_types.includes("commissioner"))||
        // (user && user.user_types && user.user_types.includes("Assistant Commissioner"))||
        (user && user.user_types && user.user_types.includes("cfa")) 
        
          ? true
          : false,
    },
    
    {
      label: 'Non Sampling Details',
      icon: "fa fa-folder",
      name: "/app/dep-ns-case-reports/",
      visible:
        (user && user.user_types && user.user_types.includes("ho"))|| 
        (user && user.user_types && user.user_types.includes("fso"))|| 
        (user && user.user_types && user.user_types.includes("so"))|| 
        (user && user.user_types && user.user_types.includes("do"))||
        (user && user.user_types && user.user_types.includes("AO"))||
        (user && user.user_types && user.user_types.includes("dd"))||
        (user && user.user_types && user.user_types.includes("oneapp"))||
        (user && user.user_types && user.user_types.includes("twoapp"))||
        (user && user.user_types && user.user_types.includes("labapp"))||
        (user && user.user_types && user.user_types.includes("commissioner"))||
        (user && user.user_types && user.user_types.includes("ac"))||
        (user && user.user_types && user.user_types.includes("apt"))
        
          ? true
          : false,
    },
    {
      label: t("Reports"),
      icon: "fa fa-folder",
      name: "/reports",
      visible:
      (user && user.user_types && user.user_types.includes("ho"))|| 
      (user && user.user_types && user.user_types.includes("fso"))|| 
      (user && user.user_types && user.user_types.includes("so"))|| 
      (user && user.user_types && user.user_types.includes("do"))||
      (user && user.user_types && user.user_types.includes("AO"))||
      (user && user.user_types && user.user_types.includes("cfa"))||
      (user && user.user_types && user.user_types.includes("dd"))||
      (user && user.user_types && user.user_types.includes("oneapp"))||
      (user && user.user_types && user.user_types.includes("twoapp"))||
      (user && user.user_types && user.user_types.includes("labapp"))||
      (user && user.user_types && user.user_types.includes("commissioner"))||
      (user && user.user_types && user.user_types.includes("ac"))
          ? true
          : false,
    },
    // {
    //   label: t("Events"),
    //   icon: "fa fa-calendar",
    //   name: "#",
    //   visible: true,
    // },
    {
      label: t("Logout"),
      icon: "fa fa-sign-out-alt",
      name: "/logout",
      visible: true,
    },
  ];

  const toggleActive = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  const handleClick = (item) => {
    setSelectedMenu(item);
  };

  const getLogout = async () => {
    const resp = await props.onLogout();
    if (resp && resp.response) {
      localStorage.clear();
      if (user && user.user_types && user.user_types.length > 0) {
        window.location.href = "/login/";
      } else {
        window.location.href = "/login/";
      }
    }
  };

  const sideBarMenuList =
    sideBarMenu && sideBarMenu.length > 0
      ? sideBarMenu.map((item, idx) => {
          if (item.name === "/logout" && item.visible) {
            return (
              <li
                onClick={() => getLogout()}
                key={idx}
                className={`nav-item ${
                  selectedMenu && item.name === selectedMenu.name
                    ? "active"
                    : ""
                }`}
              >
                <Link className="nav-link" to={item.name}>
                  <i className={item.icon} aria-hidden="true"></i>
                  <span>{item.label}</span>
                </Link>
              </li>
            );
          } else if (item.name === "/reports" && item.visible) {
            return (
              <div>
                <Link className="nav-link" to={'/app/reports'}>
                <i className="fas fa-folder fa-fw mr-2 text-gray-400 "></i>
                    <small className="text-light">{t("Reports")}</small>
                </Link>
              {/* <li
                onClick={() => window.location.href="/app/reports/" }
                key={idx}
                className={`nav-item ${
                  selectedMenu && item.name === selectedMenu.name
                    ? "active"
                    : ""
                }`}
              >
                <Link className="nav-link" to={item.name}>
                  <i className={item.icon} aria-hidden="true"></i>
                  <span>{item.label}</span>
                </Link>
              </li> */}
                {/* <Dropdown>
                  <Dropdown.Toggle  className="profile-btn my-dropdown-toggle" variant="link">
                    <i className="fas fa-folder fa-fw mr-2 text-gray-400 "></i>
                    <small className="text-light">{t("Reports")}</small>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{position:'relative',left:130,top:-100,background:'#f5f5f5'}}>
                    <Dropdown.Item  className="w-90" tag="a" href="/app/reports/">
                      <i className="fas fa-edit fa-sm fa-fw mr-2 text-blue-400"></i>
                      {t("Feed Reports")}
                    </Dropdown.Item>
                    <Dropdown.Item  className="w-90" tag="a" href="/app/display-reports/">
                      <i className="fas fa-clipboard-list fa-sm fa-fw mr-2 text-blue-400"></i>
                      {'Edit/View Reports'}
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item tag="a" href="/app/displaysample-reports/">
                      <i className="fas fa-clipboard-list fa-sm fa-fw mr-2 text-gray-400"></i>
                      {'Sample Report List'}
                    </Dropdown.Item> */}
                    {/* <Dropdown.Item  className="w-90" tag="a" href="/app/display-reports-general/">
                      <i className="fas fa-clipboard-list fa-sm fa-fw mr-2 text-blue-400"></i>
                      {'Consumer complaint reports'}
                    </Dropdown.Item>
                    <Dropdown.Item  className="w-90" tag="a" href="/app/display-reports-sample/">
                      <i className="fas fa-clipboard-list fa-sm fa-fw mr-2 text-blue-400"></i>
                      {'Sample details reports'}
                    </Dropdown.Item>
                    <Dropdown.Item  className="w-90" tag="a" href="/app/display-reports/sample-non-list/">
                      <i className="fas fa-clipboard-list fa-sm fa-fw mr-2 text-blue-400"></i>
                      {'Non-Sampling Case reports'}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
            );
          } else {
            if (item.visible) {
              return (
                <li
                  onClick={() => handleClick(item)}
                  key={idx}
                  className={`nav-item ${
                    selectedMenu && item.name === selectedMenu.name
                      ? "active"
                      : ""
                  }`}
                >
                  <Link className="nav-link" to={item.name}>
                    <i className={item.icon} aria-hidden="true"></i>
                    <span>{item.label}</span>
                  </Link>
                </li>
              );
            }
            return false;
          }
        })
      : null;

  return (
    <Navbar
      className={`bg-gradient-success sidebar  sidebar-light p-0 ${
        props.sidebarToggleTop ? "toggled" : ""
      }${props.sidebarToggle ? "toggled" : ""}`}
    >
      <ul className="mb-auto">
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/"
        >
          <div className="sidebar-brand-icon">
            <img alt="" src={logo} />
          </div>
          <div className="sidebar-brand-text mx-3">{t('FD')}</div>
        </a>
        <hr className="sidebar-divider my-1 mx-1" />
        {sideBarMenuList}
        {user && user.user_types && user.user_types.length < 0 ? (
          <li className="nav-item">
            <Accordion>
              <Accordion.Toggle
                className={activeId === "0" ? "nav-link collapsed" : "nav-link"}
                onClick={() => toggleActive("0")}
                as={Card.Link}
                variant="link"
                eventKey="0"
              >
                <i className="fas fa-folder"></i>
                <span>Master Data</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div className="bg-white py-2 collapse-inner rounded">
                  {masterData
                    ? masterData.map((item, idx) => {
                        return (
                          <Link
                            key={idx}
                            onClick={() => handleClick(item)}
                            className={`collapse-item ${
                              selectedMenu && item.name === selectedMenu.name
                                ? "active"
                                : ""
                            }`}
                            to={item.name}
                          >
                            {item.label}
                          </Link>
                        );
                      })
                    : null}
                </div>
              </Accordion.Collapse>
            </Accordion>
          </li>
        ) : null}
        <hr className="sidebar-divider d-none d-md-block" />
        <div className="nav-link-toggle text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            onClick={() => props.setSidebarToggle(!props.sidebarToggle)}
            id="sidebarToggle"
          ></button>
        </div>
      </ul>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(logout()),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
