import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import Accordion from "../../components/Accordion/Accordion";
import ClipLoader from "react-spinners/ClipLoader";
import { toCamel } from "../../utils/toCamel";
import Zoom from "react-medium-image-zoom";

const InspectionInfo = (props) => {
  const {
    districts,
    inspection,
    user,
    complaint,
    inspectionApprove,
    isSpinner,
  } = props;

  const { t } = useTranslation();
  let district = null;
  districts &&
    districts.forEach((item) => {
      if (inspection && item.id === inspection.district) {
        district = item.name;
      }
    });

  return (
    <div style={{ color: "#858796" }}>
      <div className="form-group mt-5 comment-form">
        <Accordion title={t("Address")} isExpand={true}>
          <div>
            <div className="d-flex">
              <div className="content-left">
                {t("Same address as provided in the complaint ?")}
              </div>
              <div>{inspection.same_address ? t("Yes") : t("No")}</div>
            </div>
            <div>
              <p>
                {!inspection.same_address
                  ? `${inspection.street}, ${inspection.sub_division}, ${inspection.village}, ${district}, ${inspection.zipcode}`
                  : [complaint ? complaint.address : null]}
              </p>
            </div>
            {complaint && complaint.ward_number ? (
              <div className="d-flex">
                <div className="text-left mb-2">Ward Number:</div>
                <div className="ml-1">{complaint.ward_number}</div>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Fssai Number")} isExpand={true}>
          <div>
            <div className="d-flex">
              <div className="content-left">{t("Has Fssai Number ?")}</div>
              <div>{inspection.has_fssai_number ? t("Yes") : t("No")}</div>
            </div>
            {inspection.has_fssai_number ? (
              <>
                <div className="d-flex">
                  <div className="content-left">{t("Fssai Number")}</div>
                  <div>{inspection.fssai_number}</div>
                </div>
              </>
            ) : null}
            {inspection && inspection.fssai_photos ? (
              <div className="form-group">
                <Row>
                  {inspection.fssai_photos.map((item, idx) => {
                    return (
                      <Col md="2" key={idx}>
                        <Zoom>
                          <img
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            alt={`image_${idx}`}
                            key={idx}
                            src={`${item}`}
                          />
                        </Zoom>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Authorised person in premises")} isExpand={true}>
          <div>
            <div className="d-flex">
              <div className="content-left">{t("Name")}</div>
              <div>{inspection.auth_person_name}</div>
            </div>
            <div className="d-flex">
              <div className="content-left">{t("Designation")}</div>
              <div>{inspection.auth_person_designation}</div>
            </div>
            <div className="d-flex">
              <div className="content-left">{t("Phone Number")}</div>
              <div>{inspection.auth_person_phone_number}</div>
            </div>
            {inspection && inspection.auth_person_photos ? (
              <div className="form-group">
                <Row>
                  {inspection.auth_person_photos.map((item, idx) => {
                    return (
                      <Col md="2" key={idx}>
                        <Zoom>
                          <img
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            alt={`image_${idx}`}
                            key={idx}
                            src={`${item}`}
                          />
                        </Zoom>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Nature of Complaint")} isExpand={true}>
          <div>
            <p>{inspection.nature_of_complaint}</p>
            {inspection && inspection.nature_of_complaint_photos ? (
              <div className="form-group">
                <Row>
                  {inspection.nature_of_complaint_photos.map((item, idx) => {
                    return (
                      <Col md="2" key={idx}>
                        <Zoom>
                          <img
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            alt={`image_${idx}`}
                            key={idx}
                            src={`${item}`}
                          />
                        </Zoom>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Fso Observation")} isExpand={true}>
          <div>
            <p>{inspection.fso_observation}</p>
            {inspection && inspection.fso_observation_photos ? (
              <div className="form-group">
                <Row>
                  {inspection.fso_observation_photos.map((item, idx) => {
                    return (
                      <Col md="2" key={idx}>
                        <Zoom>
                          <img
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            alt={`image_${idx}`}
                            key={idx}
                            src={`${item}`}
                          />
                        </Zoom>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Action Taken")} isExpand={true}>
          <div>
            <p>
              {inspection.action_taken.map((item, idx) => (
                <span key={idx}>
                  {t(toCamel(item))}
                  {idx !== inspection.action_taken.length - 1 ? "," : ""}{" "}
                </span>
              ))}
            </p>
            {inspection.action_taken.includes("sample_lifted") &&
              inspection.action_taken_sample_number && (
                <>
                  <div className="d-flex">
                    <div className="text-left">Sample Number:</div>
                    <div className="ml-1">
                      {inspection.action_taken_sample_number}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="text-left mb-2">Sample Lifted Comment:</div>
                    <div className="ml-1">
                      {inspection.sample_lifted_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("fine") &&
              inspection.action_taken_fine_amount && (
                <>
                  <div className="d-flex">
                    <div className="text-left">Fine Amount:</div>
                    <div className="ml-1">
                      {inspection.action_taken_fine_amount}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="text-left mb-2">Fine Comment:</div>
                    <div className="ml-1">
                      {inspection.fine_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("close_complaint") &&
              inspection.closed_action_taken_message && (
                <>
                  <div className="d-flex">
                    <div className="text-left mb-2">
                      Close Complaint Comment:
                    </div>
                    <div className="ml-1">
                      {inspection.closed_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("seizure") &&
              inspection.seizure_action_taken_message && (
                <>
                  <div className="d-flex">
                    <div className="text-left mb-2">Seizure Comment:</div>
                    <div className="ml-1">
                      {inspection.seizure_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("notice") &&
              inspection.notice_action_taken_message && (
                <>
                  <div className="d-flex">
                    <div className="text-left mb-2">Notice Comment:</div>
                    <div className="ml-1">
                      {inspection.notice_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection && inspection.action_taken_photos ? (
              <div className="form-group mt-3">
                <Row>
                  {inspection.action_taken_photos.map((item, idx) => {
                    return (
                      <Col md="2" key={idx}>
                        <Zoom>
                          <img
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            alt={`image_${idx}`}
                            key={idx}
                            src={`${item}`}
                          />
                        </Zoom>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Fso Additional Comments")} isExpand={true}>
          <div>
            <p>{inspection.fso_additional_comment}</p>
            {inspection && inspection.fso_additional_photos ? (
              <div className="form-group">
                <Row>
                  {inspection.fso_additional_photos.map((item, idx) => {
                    return (
                      <Col md="2" key={idx}>
                        <Zoom>
                          <img
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            alt={`image_${idx}`}
                            key={idx}
                            src={`${item}`}
                          />
                        </Zoom>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      {complaint &&
      complaint.awaiting_inspection_approval &&
      inspection &&
      Object.keys(inspection).length > 0 &&
      !inspection.is_approved &&
      !inspection.is_deleted &&
      user &&
      user.user_types.includes("do") ? (
        <div className="form-group close__action__btn">
          <button
            onClick={() => window.scrollTo(0, 0)}
            className="btn btn-success"
            type="button"
          >
            <span style={{ margin: 10 }}>
              <i class="fas fa-pen-square"></i>
            </span>
            {t("Reassign")}
          </button>
          <button
            onClick={() => inspectionApprove()}
            type="button"
            className="btn btn-success"
          >
            <ClipLoader
              color="#fff"
              loading={isSpinner}
              size={15}
              css={{ marginRight: "10px" }}
            />
            {!isSpinner && (
              <span style={{ margin: 10 }}>
                <i class="far fa-times-circle"></i>
              </span>
            )}
            <span>{t("Closed")}</span>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default InspectionInfo;
