import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import moment from "moment";
import { nest } from "d3-collection";

const ClosedLineChart = (props) => {
  console.log(props)
  const closedChart = useRef();

  const renderClosedLine = (data) => {
    const margin = { top: 40, right: 40, bottom: 40, left: 50 },
      width = 500 - margin.left - margin.right,
      height = 390 - margin.top - margin.bottom;
    const parseDate = d3.timeParse("%m/%d/%Y"),
      formatDate = d3.timeFormat("%b %d"),
      formatMonth = d3.timeFormat("%b %d");
    const x = d3.scaleTime().range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);
    const area = d3
      .area()
      .x((d) => {
        return x(d.date);
      })
      .y0(height)
      .y1((d) => {
        return y(d.price);
      })
    const valueline = d3
      .line()
      .x((d) => {
        return x(d.date);
      })
      .y((d) => {
        return y(d.price);
      })
      .curve(d3.curveMonotoneX)

    const svg = d3
      .select(closedChart.current)
      .attr(
        "viewBox",
        `0 0 ${width + margin.left + margin.right} ${
          height + margin.top + margin.bottom
        }`
      )
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svg
      .append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickFormat(formatMonth));
    svg.append("g").attr("class", "y axis").call(d3.axisLeft(y));
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Complaint Counts");
    if (data) {
        d3.selectAll("#closed_line.path.area").remove();
        d3.selectAll("#closed_line.path.line").remove();
      data.forEach((d) => {
        d.closed_at = moment(d.closed_at.toString()).format("L");
        d.count = Number(d.count);
      });
      data = nest()
        .key(function (d) {
          return d.closed_at;
        })
        .rollup(function (v) {
          return d3.sum(v, function (d) {
            return d.count;
          });
        })
        .entries(data)
        .sort(function (a, b) {
          return d3.ascending(a.key, b.key);
        })
        .map(function (group) {
          return {
            date: parseDate(group.key),
            price: group.value,
          };
        });
        console.log(data)
      x.domain(
        d3.extent(data, (d) => {
          return d.date;
        })
      );
      y.domain([
        0,
        d3.max(data, (d) => {
          return d.price;
        }),
      ]);
      svg
        .select(".x.axis")
        .transition()
        .duration(750)
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b")));
      svg.select(".y.axis").transition().duration(750).call(d3.axisLeft(y));
      const linePath = svg
        .append("path")
        .data([data])
        .attr("class", "line")
        .attr("d", valueline);
      const pathLength = linePath.node().getTotalLength();
      linePath
        .attr("stroke-dasharray", pathLength)
        .attr("stroke-dashoffset", pathLength)
        .attr("stroke-width", 3)
        .transition()
        .duration(1000)
        .attr("stroke-width", 0)
        .attr("stroke-dashoffset", 0);
      const focus = svg
        .append("g")
        .attr("class", "focus")
        .style("display", "none");
      focus
        .append("line")
        .attr("class", "x")
        .style("stroke-dasharray", "3,3")
        .style("opacity", 0.3)
        .attr("y1", 0)
        .attr("y2", height);
      focus
        .append("line")
        .attr("class", "y")
        .style("stroke-dasharray", "3,3")
        .style("opacity", 0.5)
        .attr("x1", width)
        .attr("x2", width);
      focus
        .append("circle")
        .attr("class", "y")
        .style("fill", "grey")
        .attr("r", 4);
      focus
        .append("text")
        .attr("class", "y1")
        .attr("dx", 8)
        .attr("dy", "-.3em");
      focus
        .append("text")
        .attr("class", "y2")
        .attr("dx", 8)
        .attr("dy", "-.3em");
      focus.append("text").attr("class", "y3").attr("dx", 8).attr("dy", "1em");
      focus.append("text").attr("class", "y4").attr("dx", 8).attr("dy", "1em");
      function mouseMove(event) {
        const bisect = d3.bisector((d) => d.date).left,
          x0 = x.invert(d3.pointer(event, this)[0]),
          i = bisect(data, x0, 1),
          d0 = data[i - 1],
          d1 = data[i],
          d = x0 - d0.date > d1.date - x0 ? d1 : d0;
        focus
          .select("circle.y")
          .attr("transform", "translate(" + x(d.date) + "," + y(d.price) + ")");
        focus
          .select("text.y1")
          .attr("transform", "translate(" + x(d.date) + "," + y(d.price) + ")")
          .text(d.price);
        focus
          .select("text.y2")
          .attr("transform", "translate(" + x(d.date) + "," + y(d.price) + ")")
          .text(d.price);
        focus
          .select("text.y3")
          .attr("transform", "translate(" + x(d.date) + "," + y(d.price) + ")")
          .text(formatDate(d.date));
        focus
          .select("text.y4")
          .attr("transform", "translate(" + x(d.date) + "," + y(d.price) + ")")
          .text(formatDate(d.date));
        focus
          .select(".x")
          .attr("transform", "translate(" + x(d.date) + "," + y(d.price) + ")")
          .attr("y2", height - y(d.price));
        focus
          .select(".y")
          .attr("transform", "translate(" + width * -1 + "," + y(d.price) + ")")
          .attr("x2", width + width);
      }
      svg
        .append("rect")
        .attr("width", width - 10)
        .attr("height", height)
        .style("fill", "none")
        .style("pointer-events", "all")
        .on("mouseover", () => {
          focus.style("display", null);
        })
        .on("mouseout", () => {
          focus.style("display", "none");
        })
        .on("touchmove mousemove", mouseMove);
    }
  };

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      renderClosedLine(props.data);
    }
  }, [props.data]);

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="heading">Closed Complaints by Date</h4>
       
       {props.data && props.data.length !==0 ? <div id="closed_line">
          <svg ref={closedChart}></svg>
        </div> :
        <>
         <div style={{marginTop:90, marginBottom:90}}>No Data Available</div>
        </>}
      </div>
    </div>
  );
};

export default ClosedLineChart;
