import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const HighCourtCaseDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    const [name, setName] = useState([])
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            let url = props.history.location.state.records.upload_document_every_orders_of_honourable_court;
            setName(url);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
   
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">HighCourt Case Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
            <th>Area Code</th>
            <td className="text-left">{getAreaCode(records.area_code)}</td>
          </tr>
          <tr>
            <th>Area Name</th>
            <td className="text-left">{records.area_name}</td>
          </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Appeal Case Number</th>
                 <td className="text-left">{records.case_no}</td>
                </tr>
                <tr>
                  <th>Name Of the Appellate</th>
                   <td className="text-left">{records.name_of_petitioner}</td>
                </tr>
              <tr>
                <th>Address Of the Appellate</th>
                <td className="text-left">{records.address_of_the_petitioner}</td>
              </tr>           
              <tr>
                <th>Name Of the  Advocate</th>
                <td className="text-left">{records.name_of_petitioner_advocate}</td>
              </tr>
              <tr>
                <th>Mobile Number Advocate </th>
                <td className="text-left">{records.mobile_no_Advocate}</td>
              </tr>
              <tr>
                <th>Email ID Of Advocate</th>
                <td className="text-left">{records.mail_of_advocate}</td>
              </tr>
              <tr>
                <th>Name Of the Respondant</th>
                <td className="text-left">{records.name_of_respondent}</td>
              </tr>
              <tr>
                <th>Address Of the Respondant</th>
                <td className="text-left">{records.address_of_the_respondent}</td>
              </tr> 
              <tr>
                <th>Name Of the Respondents's Advocate</th>
                <td className="text-left">{records.name_of_the_respondent_advocate}</td>
              </tr>
              <tr>
                <th>Address Of the Respondents's Advocate</th>
                <td className="text-left">{records.address_of_the_respondent_Advocate}</td>
              </tr>
              <tr>
                <th>Nature Of Offense</th>
                <td className="text-left">{records.nature_of_offence}</td>
              </tr>  
              <tr>
                <th>Section Details</th>
                <td className="text-left">{records.details}</td>
              </tr>  
              <tr>
                <th>Date of Appeal Filed</th>
                <td className="text-left">{moment(records.date_of_launched).format("l")}</td>
              </tr>  
              <tr>
                <th>Date of 1st Hearing</th>
                <td className="text-left">{moment(records.date_of_1st_hearing).format("l")}</td>
              </tr>     
              <tr>
                <th>Case History of Hearing </th>
                <td className="text-left">{records.case_history_of_case_hearing}</td>
              </tr> 
              <tr>
                <th>Judgment Status</th>
                <td className="text-left">{records.judgement_status}</td>
              </tr> 
              {records.trial_court_judgement && <tr>
                <th>Trial Court Judgement</th>
                <td className="text-left">{records.trial_court_judgement}</td>
              </tr> }
              <tr>
                <th> Upload Document (For Every Order of Honourable Court)   </th>
                <td className="text-left">{records.upload_document_every_orders_of_honourable_court}</td>
              </tr>     
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(HighCourtCaseDetails);