import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const DOADetails = (props) => {
    const { districts, traderTypes, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
      return wardName;
  };

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">DO - Appeal Case Details Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Report Received On </th>
                 <td className="text-left">{moment(records.report_received_on).format("l")}</td>
                </tr>
                {records.report_intimated_to_fso_on && <tr>
                  <th>Report Intimated to FSO On </th>
                 <td className="text-left">{moment(records.report_intimated_to_fso_on).format("l")}</td>
                </tr>}
                {records.report_intimated_to_fbo_on && <tr>
                  <th>Report Intimated To FBO On </th>
                 <td className="text-left">{moment(records.report_intimated_to_fbo_on).format("l")}</td>
                </tr>}  
                {records.type_of_trader && <tr>
                  <th>Type Of Trader </th>
                 <td className="text-left">{records.type_of_trader}</td>
                </tr>}
                {records.other_type_of_trader && <tr>
                  <th>Type Of Trader-Other </th>
                 <td className="text-left">{records.other_type_of_trader}</td>
                </tr>}
                {records.appeal_received_from_fbo && <tr>
                  <th>Appeal Received From FBO  </th>
                 <td className="text-left">{records.appeal_received_from_fbo}</td>
                </tr>}
                {records.appeal_received_from_fbo_on && <tr>
                  <th>Appeal Received From FBO On </th>
                 <td className="text-left">{moment(records.appeal_received_from_fbo_on).format("l")}</td>
                </tr>}
                {records.appeal_allowed && <tr>
                  <th>Appeal Allowed </th>
                 <td className="text-left">{records.appeal_allowed}</td>
                </tr>}
                {records.sent_another_part_of_sample && <tr>
                  <th>Sent Another Part to Lab On</th>
                 <td className="text-left">{moment(records.sent_another_part_of_sample).format("l")}</td>
                </tr>}
                {records.lab_name && <tr>
                  <th>Name of the Lab</th>
                 <td className="text-left">{records.lab_name}</td>
                </tr>}
                {records.sent_another_part_of_sample && <tr>
                  <th>Address of the Lab</th>
                 <td className="text-left">{records.lab_address}</td>
                </tr>}
                {records.case_category && <tr>
                  <th>Case Category</th>
                 <td className="text-left">{records.case_category}</td>
                </tr>}
                {records.result_sent_to_fso_on && <tr>
                  <th>Result Sent to FSO On</th>
                 <td className="text-left">{moment(records.result_sent_to_fso_on).format("l")}</td>
                </tr>}
                {records.case_file_recommended_to_lab_APP && <tr>
                  <th>Case File Recommended to Lap APP Grade II</th>
                 <td className="text-left">{moment(records.case_file_recommended_to_lab_APP).format("l")}</td>
                </tr>}
                {records.case_file_recommended_to_commisionerate_on && <tr>
                  <th>Case File Recommended to Commisionerate On</th>
                 <td className="text-left">{moment(records.case_file_recommended_to_commisionerate_on).format("l")}</td>
                </tr>}
               
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(DOADetails);