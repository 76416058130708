import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createIECReports, editIECReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment'

const option=[
  {id:"0", name:"Special Drive"},
  {id: "1", name: "DART"},
  {id:"2", name: "SNF"},
  {id:"3", name:"Safe Food Instances"},
  {id:"4", name:"Rally"},
  {id:"5", name:"Marathon"},
  {id:"6", name:"Drawing"},
  {id:"7", name:"Speech"},
  {id:"8", name:"Competition"},
  {id:"9", name:"Others"}
]
const IECReport = (props) => {
    const { districts, user, wards,onCreateIECReports,wardsCategory,ongetDist, onEditIECReports } = props;
    const [values, setValues] = useState({
        'activities' : '',
        'area_name':'',
        'area_code':'',
        'no_of_events_month':'',
        'no_of_participants_month':'',
        'district' : '', 
        'date' : '',
        'other_activities':'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [field, setField] = useState(false);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'activities', 
        'no_of_events_month',
        'no_of_participants_month',
        'area_name','area_code',
        'district', 
        'date'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            console.log(data)
            const resp = await onEditIECReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/iec-list/`);
            };

        } else {       
            const data = { ...values}
            setDisableSubmit(true);
            console.log(data)
            const resp = await onCreateIECReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

   

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">IEC Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>  
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_events_month"
                            > Number of Events (During the month) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of events conducted during the month`}
                                    type="number"
                                    name="no_of_events_month"
                                    id="no_of_events_month"
                                    value={values.no_of_events_month}
                                    error={errors.no_of_events_month}
                                    onChange={(e) => handleTextChange("no_of_events_month", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_participants_month"
                            >Number of Participants (During the month)  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of participants participated`}
                                    type="number"
                                    name="no_of_participants_month"
                                    id="no_of_participants_month"
                                    value={values.no_of_participants_month}
                                    error={errors.no_of_participants_month}
                                    onChange={(e) => handleTextChange("no_of_participants_month", e.target.value)}
                                />
                            </div>
                        </div>      
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="activities"
                            >Activities  *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    isMulti
                                    type="text"
                                    name="activities"
                                    id="activities"
                                    action="dropdown"
                                    value={values.activities}
                                    error={errors.activities}
                                    items={option}
                                    onChange={(text) => handleTextChange("activities",text)}
                                    
                                />
                               
                            </div>
                        </div>  
                             {values && values.activities.includes('Others')?(
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" ></label>
                           <div className="col-sm-8">
                            <TextInput
                                placeholder={`activities done`}
                                type="text"
                                name="other_activities"
                                id="other_activities"
                                value={values.other_activities}
                                error={errors.other_activities}
                                onChange={(e) => handleTextChange("other_activities", e.target.value)}
                            />
                        </div> </div>
                        ) : null}
                       
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateIECReports: (data) => dispatch(createIECReports(data)),
    onEditIECReports: (data, record_id) => dispatch(editIECReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(IECReport);