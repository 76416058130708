import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createHighCourtCaseReports, editHighCourtCaseReports } from "../../components/reducers/actions"
import Datepicker from '../../components/inputs/Datepicker'
import TextAreaInput from "../../components/inputs/TextAreaInput";
import { useParams } from "react-router-dom";
import moment from "moment";


const option =[
    {id:"1", name:"Acquitted"},
    {id:"2", name:"Convicted"}
]
const option1 =[
    {id:"1", name:"Pending"},
    {id:"2", name:"Disposed"}
]

const nature_of_offe = [
    {id:'0', name:'Unsafe'}
]

const HighCourtCaseReports = (props) => {
    const { districts, user,wards, onCreateHighCourtCaseReports,wardsCategory, onEditHighCourtCaseReports } = props;
    const [values, setValues] = useState({
        'case_no' : '',
        'area_code':'',
        'area_name':'',
        'name_of_petitioner':'',
        'address_of_the_petitioner':'',
        "name_of_petitioner_advocate":'',
        'name_of_respondent':'',
        'address_of_the_respondent':'',
        "name_of_the_respondent_advocate":'',
        "mobile_no_Advocate":'',
        'mail_of_advocate':'',
        'nature_of_offence':'UnSafe',
        'case_history_of_case_hearing' :'',
        'upload_document_every_orders_of_honourable_court': '',
        'district' : '',
        'date' : '',
        'trial_court_judgement':'',
        'judgement_status':'',
        'details':'',
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'case_no' ,
            'area_code',
            'area_name',
            'name_of_petitioner',
            'address_of_the_petitioner',
            "name_of_petitioner_advocate",
            'name_of_respondent',
            'address_of_the_respondent',
            "name_of_the_respondent_advocate",
            "mobile_no_Advocate",
            'mail_of_advocate',
            'nature_of_offence',
            'date_of_launched',
            'date_of_1st_hearing',
            'case_history_of_case_hearing',
            'district',
            'date',
           
            'details',
            
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => { 
        if (props.history.location && props.history.location.state) {
            console.log(values);
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            console.log(data)
            const resp = await onEditHighCourtCaseReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push(`/app/display-reports/high-courtcase-list/`);
            };
        } else {   
            console.log(values);   
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateHighCourtCaseReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Criminal Cases (appeal cases) Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_no"
                            > Appeal Case Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={` Appeal Case Number`}
                                    type="text"
                                    name="case_no"
                                    id="case_no"
                                    value={values.case_no}
                                    error={errors.case_no}
                                    onChange={(e) => handleTextChange("case_no", e.target.value)}
                                />
                            </div>
                        </div>                            
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_petitioner"
                            >Name Of the Appellate *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Appellate `}
                                    type="text"
                                    name="name_of_petitioner"
                                    id="name_of_petitioner"
                                    value={values.name_of_petitioner}
                                    error={errors.name_of_petitioner}
                                    onChange={(e) => handleTextChange("name_of_petitioner", e.target.value)}
                                />
                            </div>
                        </div>                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_petitioner"
                            >Address Of the Appellate *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Appellate`}
                                    type="text"
                                    name="address_of_the_petitioner"
                                    id="address_of_the_petitioner"
                                    value={values.address_of_the_petitioner}
                                    error={errors.address_of_the_petitioner}
                                    onChange={(e) =>handleTextChange("address_of_the_petitioner", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_petitioner_advocate"
                            >Name Of the  Advocate *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the  Advocate `}
                                    type="text"
                                    name="name_of_petitioner_advocate"
                                    id="name_of_petitioner_advocate"
                                    value={values.name_of_petitioner_advocate}
                                    error={errors.name_of_petitioner_advocate}
                                    onChange={(e) => handleTextChange("name_of_petitioner_advocate", e.target.value)}
                                />
                            </div>
                        </div>                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="mobile_no_Advocate"
                            >Mobile Number Advocate *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Mobile Number Advocate`}
                                    type="number"
                                    name="mobile_no_Advocate"
                                    id="mobile_no_Advocate"
                                    value={values.mobile_no_Advocate}
                                    error={errors.mobile_no_Advocate}
                                    onChange={(e) =>handleTextChange("mobile_no_Advocate", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="mail_of_advocate"
                            >Email ID Of Advocate *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Email ID of Advocate`}
                                    type="text"
                                    name="mail_of_advocate"
                                    id="mail_of_advocate"
                                    value={values.mail_of_advocate}
                                    error={errors.mail_of_advocate}
                                    onChange={(e) =>handleTextChange("mail_of_advocate", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_respondent"
                            >Name Of the Respondant *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Respondant `}
                                    type="text"
                                    name="name_of_respondent"
                                    id="name_of_respondent"
                                    value={values.name_of_respondent}
                                    error={errors.name_of_respondent}
                                    onChange={(e) => handleTextChange("name_of_respondent", e.target.value)}
                                />
                            </div>
                        </div>                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_respondent"
                            >Address Of the Respondant *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Respondant`}
                                    type="text"
                                    name="address_of_the_respondent"
                                    id="address_of_the_respondent"
                                    value={values.address_of_the_respondent}
                                    error={errors.address_of_the_respondent}
                                    onChange={(e) =>handleTextChange("address_of_the_respondent", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_respondent_advocate"
                            >Name Of the Respondents's Advocate *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Respondents's Advocate `}
                                    type="text"
                                    name="name_of_the_respondent_advocate"
                                    id="name_of_the_respondent_advocate"
                                    value={values.name_of_the_respondent_advocate}
                                    error={errors.name_of_the_respondent_advocate}
                                    onChange={(e) => handleTextChange("name_of_the_respondent_advocate", e.target.value)}
                                />
                            </div>
                        </div>                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_respondent_Advocate"
                            >Address Of the Respondents's Advocate *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Respondents's Advocate`}
                                    type="text"
                                    name="address_of_the_respondent_Advocate"
                                    id="address_of_the_respondent_Advocate"
                                    value={values.address_of_the_respondent_Advocate}
                                    error={errors.address_of_the_respondent_Advocate}
                                    onChange={(e) =>handleTextChange("address_of_the_respondent_Advocate", e.target.value)}
                                />
                            </div>
                        </div>
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="nature_of_offence"
                            >Nature Of Offense *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`UnSafe`} isDisabled
                                    type="text"
                                    name="nature_of_offence"
                                    id="nature_of_offence"
                                    action="dropdown"
                                    items={nature_of_offe}
                                    value={values.nature_of_offence }
                                    error={errors.nature_of_offence}
                                    onChange={(text) => handleTextChange("nature_of_offence", text)}
                                />
                            </div>
                        </div>
                        {values && values.nature_of_offence ? (
                           <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="details"
                           >Section Details *:</label>
                           <div className="col-sm-8">
                               <TextAreaInput
                                   placeholder={`Section Details`}
                                   type="text"
                                   name="details"
                                   id="details"
                                   value={values.details}
                                   error={errors.details}
                                   onChange={(e) => handleTextChange("details", e.target.value)}
                               />
                           </div>
                       </div> 
                        ): null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_launched"
                            >Date of Appeal Filed *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of Appeal Filed`}
                                    name="date_of_launched"
                                    id="date_of_launched"
                                    value={values.date_of_launched}
                                    error={errors.date_of_launched}
                                    onChangeText={(text) => handleTextChange('date_of_launched', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_1st_hearing"
                            >Date of 1st Hearing *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of 1st Hearing`}
                                    name="date_of_1st_hearing"
                                    id="date_of_1st_hearing"
                                    value={values.date_of_1st_hearing}
                                    error={errors.date_of_1st_hearing}
                                    onChangeText={(text) => handleTextChange('date_of_1st_hearing', text)}
                                />
                            </div>
                        </div>
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_history_of_case_hearing"
                            >Case History of Hearing *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case History of case Hearing`}
                                    type="text"
                                    name="case_history_of_case_hearing"
                                    id="case_history_of_case_hearing"
                                    value={values.case_history_of_case_hearing}
                                    error={errors.case_history_of_case_hearing}
                                    onChange={(e) => handleTextChange("case_history_of_case_hearing", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="judgement_status"
                            >Judgment Status *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        placeholder={`Judgement status`}
                                        type="text" 
                                        name="judgement_status"
                                        value={values.judgement_status}
                                        error={errors.judgement_status}
                                        items={option1}
                                        action="dropdown"
                                        onChange={(value) => handleTextChange("judgement_status", value)}
                                    />
                            </div>
                        </div>
                        {values && values.judgement_status.includes('Disposed') ? (
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="trial_court_judgement"
                            >Trial Court Judgement *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        placeholder={`Trial court judgement`}
                                        type="text" 
                                        name="trial_court_judgement"
                                        value={values.trial_court_judgement}
                                        error={errors.trial_court_judgement}
                                        items={option}
                                        action="dropdown"
                                        onChange={(value) => handleTextChange("trial_court_judgement", value)}
                                    />
                            </div>
                        </div>
                       ): null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_document_every_orders_of_honourable_court"
                            >Upload Document (For Every Order of Honourable Court) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`upload Your Document`}
                                    type="file"
                                    name="upload_document_every_orders_of_honourable_court"
                                    id="upload_document_every_orders_of_honourable_court"
                                    error={errors.upload_document_every_orders_of_honourable_court}
                                    onChange={(e) => handleTextChange("upload_document_every_orders_of_honourable_court", e.target.files[0])}
                                />
                            </div>
                        </div>                
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateHighCourtCaseReports: (data) => dispatch(createHighCourtCaseReports(data)),
    onEditHighCourtCaseReports: (data, record_id) => dispatch(editHighCourtCaseReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(HighCourtCaseReports);