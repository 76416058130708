import React, { useState, useEffect } from 'react'
import Datepicker from './inputs/Datepicker';
import TextInput from './inputs/TextInput';
import UploadImageInput from './inputs/UploadImageInput';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from 'react-i18next';

const ExtraComplaintInput = (props) => {

  const { values, errors, handleTextChange, isSpinner, submitComplaint, goBack, maxDate, disableSubmit } = props;
  const { t } = useTranslation();

  const [imageList, setImageList] = useState([{ image: "" }]);

  // handle input change
  const handleInputChange = (name, value, index) => {
    const list = [...imageList];
    list[index][name] = value;
    setImageList(list);
    const photosList = [];
    imageList.forEach((photo) => {
      photosList.push(photo.image);
    });
    handleTextChange('images', photosList)
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...imageList];
    list.splice(index, 1);
    const photosList = [];
    list.forEach((photo) => {
      photosList.push(photo.image);
    });
    handleTextChange('images', photosList)
    setImageList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setImageList([...imageList, { image: "" }]);
  };

  useEffect(() => {
    if (values && values.photos) {
      let imageData = [];
      values.photos.map((item, idx) => {
        if (item) {
          imageData = [...imageData, { image: `${item}` }];
        }
        return false;
      });
      setImageList(imageData);
    }
    // eslint-disable-next-line
  }, [values.photos])

  return (
    <>
      <div className="form-group">
        <Datepicker
          placeholder={t('Product Purchased On')}
          name='product_purchased_on'
          value={values.product_purchased_on}
          error={errors.product_purchased_on}
          onChangeText={(text) => handleTextChange('product_purchased_on', text)}
          maximumDate={maxDate}
        />
      </div>
      <div className="form-group">
        <Datepicker
          placeholder={t('Product Used/Opened On')}
          name='product_used_on'
          value={values.product_used_on}
          error={errors.product_used_on}
          onChangeText={(text) => handleTextChange('product_used_on', text)}
          maximumDate={maxDate}
        />
      </div>
      <div className="form-group">
        <TextInput
          placeholder={t("Cash Bill No")}
          type='text'
          name='cash_bill_number'
          value={values.cash_bill_number}
          error={errors.cash_bill_number}
          onChange={(e) => handleTextChange('cash_bill_number', e.target.value)}
        />
      </div>
      <div className="form-group">
        <TextInput
          placeholder={t("Short Note")}
          type='text'
          name='short_note'
          value={values.short_note}
          error={errors.short_note}
          onChange={(e) => handleTextChange('short_note', e.target.value)}
        />
      </div>
      <div className="form-group">
        {
          imageList && imageList.length < 5 ? (
            <div className="add_more_image">
              <button onClick={handleAddClick} type="button">{t('ADIM')}</button>
            </div>
          ) : null
        }
        <p>{t('upp')}</p>
        <div className="row">
          {imageList.map((x, i) => {
            return (
              <div className="col-sm-2">
                <UploadImageInput
                  size={100}
                  photo={x.image}
                  name="image"
                  enableDelete={true}
                  handleRemoveImages={() => handleRemoveClick(i)}
                  onChangeText={value => handleInputChange('image', value, i)}
                />
              </div>
            )
          })
          }
        </div>
      </div>
      <div className="form-group">
        <button onClick={() => goBack()} className="btn btn-success" type="button">{t('Back')}</button>
        <button disabled={disableSubmit} type="button" onClick={() => submitComplaint()} className="btn btn-success ml-3">
          <ClipLoader color="#fff" loading={isSpinner} size={15} css={{ marginRight: '10px' }} />
          {t('Submit')}</button>
      </div>
    </>
  )
}

export default ExtraComplaintInput;