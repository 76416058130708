import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from '../../components/inputs/Datepicker'
import { createSchoolReports, editSchoolReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const SchoolReport = (props) => {
    const { districts, user, wards,wardsCategory,ongetDist, onCreateSchoolReports, onEditSchoolReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_name':'',
        'area_code':'',
        'schools_inspected' : '',
        'college_inspected':'',
        'shop_sale_tobacco_products' : '',
        'chocolates_products':'',
        'samples_lifted' : '',
        'no_of_shops_found':'',
        'type_of_offense':'',
        'sample_safe':'',
        'type':'',
        'samples_analysed' : '',
        'samples_found_unsafe' : '',
        'samples_found_ssmb' : '',
        'no_of_school_college_attended_upto_the_month':'',
        'action_taken' : '',
        'prod_sent':'',
        'prod_amt':'',
        'penalty_imposed':'',
        'penalty_paid':'',
        'upload_documents':null,
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
const type=[
    {id:'0',name:'School'},
    {id:'1',name:'College'},

]
const Ofens=[
    {id:'0',name:'Sale of Chocolates with Chemical'},
    {id:'1',name:'Sale of Tobacco Products'},

]
const Sample=[
    {id:'0',name:'Act'},
    {id:'1',name:'Surveillance'},

]
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
         'district',
         'date', 
         'area_code',
         'area_name',
         'no_of_shops_found',
         'sample_safe',
         'samples_lifted', 
         'type_of_offense',
         'samples_analysed',
        'samples_found_unsafe',
        'samples_found_ssmb',
        'action_taken', 
        'no_of_school_college_attended_upto_the_month'
    ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {   
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditSchoolReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/school-list/`);
            };

        } else {    
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateSchoolReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }
    return (
        <div>
            <div className="container-fluid">
                <h1 className="h3 mb-4 text-gray-800">School/College Inspection Report</h1>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >Name of the district : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Name of the district`}
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                            ) : (
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="district"
                                    >Name of the district : </label>
                                    <div className="col-sm-8">
                                        <DropDownInput
                                            placeholder={`Name of the district`}
                                            type="text"
                                            name="district"
                                            value={values.district}
                                            error={errors.district}
                                            items={districts}
                                            onChange={(text) => handleTextChange("district", text)}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                                > Area Code *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                                > Area Name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Area Name`}
                                        type="text"
                                        name="area_name"
                                        value={values.area_name}
                                        error={errors.area_name}
                                        onChange={(e) => handleTextChange("area_name", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date"
                                >Month *: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Month`}
                                        name="date"
                                        id="date"
                                        showMonthYearPicker
                                        value={values.date }
                                        error={errors.date}
                                        onChangeText={(text) => handleTextChange('date', text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_school_college_attended_upto_the_month"
                                > Number of schools/colleges canteen Available *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={` Number of schools/colleges canteen available`}
                                        type="number"
                                        name="no_of_school_college_attended_upto_the_month"
                                        value={values.no_of_school_college_attended_upto_the_month}
                                        error={errors.no_of_school_college_attended_upto_the_month}
                                        onChange={(e) => handleTextChange("no_of_school_college_attended_upto_the_month", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type"
                                > Institution Type *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                        type="text"
                                        name="type"
                                        value={values.type}
                                        error={errors.type}
                                        items={type}
                                        action='dropdown'
                                        onChange={(value) => handleTextChange("type", value)}
                                />
                                </div>
                            </div>
                           {values && values.type.includes('School')&& (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="schools_inspected"
                                >Total Number of school canteen Inspected During The Month *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Total Number of school canteen inspected during the month`}
                                        type="number"
                                        name="schools_inspected"
                                        value={values.schools_inspected}
                                        error={errors.schools_inspected}
                                        onChange={(e) => handleTextChange("schools_inspected", e.target.value)}
                                    />
                                </div>
                            </div>
                           )} 
                                {values && values.type.includes('College')&& (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="college_inspected"
                                >Total Number of College canteen Inspected During The Month *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Total Number of College canteen inspected during the month`}
                                        type="number"
                                        name="college_inspected"
                                        value={values.college_inspected}
                                        error={errors.college_inspected}
                                        onChange={(e) => handleTextChange("college_inspected", e.target.value)}
                                    />
                                </div>
                            </div>
                           )} 
                               <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type_of_offense"
                                > Type of offense *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                        type="text"
                                        name="type_of_offense"
                                        value={values.type_of_offense}
                                        error={errors.type_of_offense}
                                        items={Ofens}
                                        action='dropdown'
                                        onChange={(value) => handleTextChange("type_of_offense", value)}
                                />
                                </div>
                            </div>
                          {values && values.type_of_offense.includes('Sale of Chocolates with Chemical') &&(
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="chocolates_products"
                            >Number of shops found sale of Chocolates with Chemicals *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of shops found sale of Chocolates with Chemicals`}
                                    type="number"
                                    name="chocolates_products"
                                    value={values.chocolates_products}
                                    error={errors.chocolates_products}
                                    onChange={(e) => handleTextChange("chocolates_products", e.target.value)}
                                />
                            </div>
                            </div>
                          )} 
                     {values && values.type_of_offense.includes('Sale of Tobacco Products') &&(   <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="shop_sale_tobacco_products"
                                >Number of shops found sale of tobacco products *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of shops found sale of tobacco products`}
                                        type="number"
                                        name="shop_sale_tobacco_products"
                                        value={values.shop_sale_tobacco_products}
                                        error={errors.shop_sale_tobacco_products}
                                        onChange={(e) => handleTextChange("shop_sale_tobacco_products", e.target.value)}
                                    />
                                </div>
                            </div>)}
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_shops_found"
                                >Number of shops Found With Expired Food Products *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of shops Found with expired Food Products`}
                                        type="number"
                                        name="shop_sale_tobacco_products"
                                        value={values.no_of_shops_found}
                                        error={errors.no_of_shops_found}
                                        onChange={(e) => handleTextChange("no_of_shops_found", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="samples_lifted"
                                >Type of sample lifted *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Type of sample lifted`}
                                        name="samples_lifted"
                                        items={Sample}
                                        value={values.samples_lifted}
                                        error={errors.samples_lifted}
                                        action='dropdown'
                                        onChange={(value) => handleTextChange("samples_lifted", value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="samples_analysed"
                                >Number of samples analyzed *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of samples analysed`}
                                        type="number"
                                        name="samples_analysed"
                                        value={values.samples_analysed}
                                        error={errors.samples_analysed}
                                        onChange={(e) => handleTextChange("samples_analysed", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="prod_sent"
                                >Quantity of food product Seized *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Quantity of food product Seized`}
                                        type="number"
                                        name="prod_sent"
                                        value={values.prod_sent}
                                        error={errors.prod_sent}
                                        onChange={(e) => handleTextChange("prod_sent", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="prod_amt"
                                >Value of food product seized  (RS)*: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Value of food product seized  `}
                                        type="number"
                                        name="prod_amt"
                                        value={values.prod_amt}
                                        error={errors.prod_amt}
                                        onChange={(e) => handleTextChange("prod_amt", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="penalty_imposed"
                                >Offence penalty imposed in (RS)*: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Offence penalty imposed`}
                                        type="number"
                                        name="penalty_imposed"
                                        value={values.penalty_imposed}
                                        error={errors.penalty_imposed}
                                        onChange={(e) => handleTextChange("penalty_imposed", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="penalty_paid"
                                > Penalty paid on*: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Penalty paid on`}
                                        name="penalty_paid"
                                        value={values.penalty_paid}
                                        error={errors.penalty_paid}
                                        onChangeText={(text) => handleTextChange("penalty_paid", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="samples_found_unsafe"
                                >Number of samples found unsafe *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of samples found unsafe`}
                                        type="number"
                                        name="samples_found_unsafe"
                                        value={values.samples_found_unsafe}
                                        error={errors.samples_found_unsafe}
                                        onChange={(e) => handleTextChange("samples_found_unsafe", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="samples_found_ssmb"
                                >Number of samples found SS/MB etc *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of samples found SS/MB etc`}
                                        type="number"
                                        name="samples_found_ssmb"
                                        value={values.samples_found_ssmb}
                                        error={errors.samples_found_ssmb}
                                        onChange={(e) => handleTextChange("samples_found_ssmb", e.target.value)}
                                    />
                                </div>
                            </div>     <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sample_safe"
                                >Number of samples found Safe *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of samples found safe`}
                                        type="number"
                                        name="sample_safe"
                                        value={values.sample_safe}
                                        error={errors.sample_safe}
                                        onChange={(e) => handleTextChange("sample_safe", e.target.value)}
                                    />
                                </div>
                            </div>                        
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="action_taken"
                                >Action taken *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Action taken`}
                                        type="text"
                                        name="action_taken"
                                        value={values.action_taken}
                                        error={errors.action_taken}
                                        onChange={(e) => handleTextChange("action_taken", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateSchoolReports: (data) => dispatch(createSchoolReports(data)),
    onEditSchoolReports: (data, record_id) => dispatch(editSchoolReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolReport)
