import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createLicenseMonthlyReports, editLicenseMonthlyReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";

const LicenseMonthlyReports = (props) => {
    const { districts, user, wards, traderTypes, onCreateLicenseMonthlyReports, onEditLicenseMonthlyReports } = props;
    const [values, setValues] = useState({
        'category_of_food_business_operator' : '',
        'no_of_license_existing_till_last_month':'',
        'no_of_license_granted_during_the_month':'',
        'no_of_license_cancelled_expired_during_the_month':'',
        'total_number_of_license':'',
        'district' : '',
        'date': ''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(recordData);
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
           'category_of_food_business_operator','no_of_license_existing_till_last_month','no_of_license_granted_during_the_month','no_of_license_cancelled_expired_during_the_month',
           'total_number_of_license','district', 'date'
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {   
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditLicenseMonthlyReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/licensemonthly-list/`);
            };

        } else {    
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateLicenseMonthlyReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Monthly License Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="category_of_food_business_operator"
                            >Category of food business operator : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Category of food business operator`}
                                    type="text"
                                    name="category_of_food_business_operator"
                                    value={values.category_of_food_business_operator}
                                    error={errors.category_of_food_business_operator}
                                    items={traderTypes}
                                    onChange={(text) => handleTextChange("category_of_food_business_operator", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_license_existing_till_last_month"
                            >Number of License till last month </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of license till last month`}
                                    type="number"
                                    name="no_of_license_existing_till_last_month"
                                    id="no_of_license_existing_till_last_month"
                                    value={values.no_of_license_existing_till_last_month}
                                    error={errors.no_of_license_existing_till_last_month}
                                    onChange={(e) => handleTextChange("no_of_license_existing_till_last_month", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_license_granted_during_the_month"
                            >Number of license granted during the month : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of license granted during the month `}
                                    type="number"
                                    name="no_of_license_granted_during_the_month"
                                    id="no_of_license_granted_during_the_month"
                                    value={values.no_of_license_granted_during_the_month}
                                    error={errors.no_of_license_granted_during_the_month}
                                    onChange={(e) => handleTextChange("no_of_license_granted_during_the_month", e.target.value)}
                                />
                            </div>
                        </div>                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_ino_of_license_cancelled_expired_during_the_monthnspections_bars"
                            >Number License Expired/Cancelled during the Month : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of License expired/cancelled during the month`}
                                    type="number"
                                    name="no_of_license_cancelled_expired_during_the_month"
                                    id="no_of_license_cancelled_expired_during_the_month"
                                    value={values.no_of_license_cancelled_expired_during_the_month}
                                    error={errors.no_of_license_cancelled_expired_during_the_month}
                                    onChange={(e) =>handleTextChange("no_of_license_cancelled_expired_during_the_month", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="total_number_of_license"
                            >Total Number of License : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total Number of license`}
                                    type="number"
                                    name="total_number_of_license"
                                    id="total_number_of_license"
                                    value={values.total_number_of_license}
                                    error={errors.total_number_of_license}
                                    onChange={(e) => handleTextChange("total_number_of_license", e.target.value)}
                                />
                            </div>
                        </div>                
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateLicenseMonthlyReports: (data) => dispatch(createLicenseMonthlyReports(data)),
    onEditLicenseMonthlyReports: (data, record_id) => dispatch(editLicenseMonthlyReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    traderTypes: state.home.traderTypes
});
  
export default connect(mapStateToProps, mapDispatchToProps)(LicenseMonthlyReports);