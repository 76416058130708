import React from "react";
import {
  getDistrictWards,
  getDownloadNonSampleone,
    getDownloadSampleone,
  getFilterData,
  getFilterDataWithout,
  getFoodCategory,
  getFsoOfficers,
  getMasterSuccess,
  userDirectory,
} from "../../components/reducers/actions";
import { connect } from "react-redux";
import { useEffect,useRef } from "react";
import Table from "react-bootstrap/Table";
import Header from "../../components/Header";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { date } from "yup";
import Datepicker from "../../components/inputs/Datepicker";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextInput from "../../components/inputs/TextInput";
import moment from "moment";
import { CSVLink } from "react-csv";
import { CSVDownload } from "react-csv";

const SamplNonFilter = (props) => {
  const { t, i18n } = useTranslation();
  const {
    ongetFilterData,
    traderTypes,
    ongetFso,
    foodIssues,
    districts,
    foodTypes,
    fsoOfficers,
    user,
    ongetFilterDataWithout,
    onPutMasterData,
    wardsCategory,
    foodConditions,
    wards,
    ongetDist,
    ongetFoodCategory,
  } = props;
  const [users, setUsers] = useState('');
  const [errors, setErrors] = useState(false);
  const[foodCategory,setFoodCategory]=useState([])
  const [filt, setFilt] = useState();
  const [duplicatefso, setDuplicatefso] = useState([]);
  const [duplicateDistrict, setDuplicateDistrict] = useState([]);
  const dref=useRef();
  const [values, setValues] = useState({
    fromdate: new Date(),
    todate: new Date(),
    district: null,
  
  });
  const Judg=[
    {id:'0', name:'All'},
    {id:'0', name:'Convicted'},
    {id:'1', name:'Acquittal'},
]
const Case = [
  {id:'0', name:'All'},
  {id:'0', name:'Civil'},
  {id:'1', name:'Criminal'},
]
const TypeOfTraders = [
  {id:'0', name:'All'},
  {id:'1', name:'Manufacturer'},
  {id:'2', name:'Retailer'},
  {id:'3', name:'WholeSaler/Distributor/Repacker'},
  {id:'4', name:'Transporter'},

]
const SampType = [
  {id:'0', name:'All'},
  {id:'1', name:'Act'},
  {id:'2', name:'Surveillance'},
]
const Status = [
{id:'0', name:'All'},
{id:'0', name:'Final Judgement (Civil)'},
{id:'1', name:`Hearing (Civil)`},
{id:'2',name:'Hearing (Criminal)'},
{id:'3',name:'Final Judgement (Criminal)'},
]
  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
  };
  const handleSubmit = async (event) => {
    // setErrors(true)
    const data = { ...values };
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")){
      data.district=[data.district]}
      else data.district=data?.district?data?.district.filter(i=>i!=='all')[0]?data?.district.filter(i=>i!=='all'):null:null
    data.fromdate = moment(data.fromdate||new Date()).format("YYYY-MM-DD");
    data.todate = moment(data.todate||new Date()).format("YYYY-MM-DD");
    data.sample_food_category=data.sample_food_category&&data.sample_food_category=='All'?null:data.sample_food_category
    data.status_of_report=data.status_of_report&&data.status_of_report=='All'?null:data.status_of_report
    // data.case_category=data.case_category&&data.case_category=='All'?null:data.case_category
    data.type_of_sample_lifted=data.type_of_sample_lifted&&data.type_of_sample_lifted=='All'?null:data.type_of_sample_lifted
    data.fso=data.fso&&data.fso=='all'?null:data.fso
    // data.court_result=data.court_result&&data.court_result=='All'?null:data.court_result
    data.type_of_trader= data.type_of_trader&&getTrader(data.type_of_trader)=='All'?null:data&&data.type_of_trader?getTrader(data.type_of_trader):null;
    data.date_sample_lifted_date_and_time = moment(data.date_sample_lifted_date_and_time||new Date()).format("YYYY-MM-DD");
    console.log(data)
    
      const resp = await ongetFilterData(data);
      if (resp && resp.response) {
        // setTimeout(()=>{
        //   setErrors(false)
        // },1000)
        console.log(resp.response);
        setUsers(resp.response);
        setErrors(true)

        // setTimeout(()=>{
        //   dref.click()
        // },1000)
      }
  
  };
  const handleFilt = async (event) => {
    const data = { ...values };
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")){
      data.district=[data.district]}
      else data.district=data?.district?data?.district.filter(i=>i!=='all')[0]?data?.district.filter(i=>i!=='all'):null:null
    data.fromdate = moment(data.fromdate||new Date()).format("YYYY-MM-DD");
    data.todate = moment(data.todate||new Date()).format("YYYY-MM-DD");
    data.sample_food_category=data.sample_food_category&&data.sample_food_category=='All'?null:data.sample_food_category
    data.status_of_report=data.status_of_report&&data.status_of_report=='All'?null:data.status_of_report
    // data.case_category=data.case_category&&data.case_category=='All'?null:data.case_category
    data.type_of_sample_lifted=data.type_of_sample_lifted&&data.type_of_sample_lifted=='All'?null:data.type_of_sample_lifted
    data.fso=data.fso&&data.fso=='all'?null:data.fso
    // data.court_result=data.court_result&&data.court_result=='All'?null:data.court_result
    data.type_of_trader= data.type_of_trader&&getTrader(data.type_of_trader)=='All'?null:data&&data.type_of_trader?getTrader(data.type_of_trader):null;
    data.date_sample_lifted_date_and_time = moment(data.date_sample_lifted_date_and_time||new Date()).format("YYYY-MM-DD");
      data.is_json=true
    console.log(data)
      const resp = await ongetFilterData(data);
      if (resp && resp.response) {
        setFilt(resp.response.data);
      }
  
  };
  const getFoods=async()=>{
    const resp = await ongetFoodCategory()
    if(resp.response){
        // console.log(resp)
        setFoodCategory(resp.response)
    }
}
  useEffect(() => {
    if (dref && dref.current && errors) {
        dref.current.link.click();
        setErrors(false)

    }
}, [errors]);
useEffect(()=>{
  var arr = [...districts]
  arr.unshift({id:'all',name:'All'})
  setDuplicateDistrict(arr)
},[districts&&districts])
// useEffect(()=>{
//   let d1={'fso':{id: 'all', name: 'All',food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'},id:'all',name:'All'}
//   if(fsoOfficers&&fsoOfficers.length >0){
//   var arr = [fsoOfficers]||[]
//   arr.unshift(d1)
//   setDuplicatefso(arr)}
// },[fsoOfficers&&fsoOfficers])
const getOfficer=async(id)=>{
  // if(user && user.user_types && user.user_types.includes("do")||user && user.user_types && user.user_types.includes("fso")){
  //   var dat = id
  // }
  // else dat=id[0]
  const data={district_id:id}
 const resp = await ongetFso(data)
 console.log('resp', resp,data)
 let d1={'fso':{id: 'all', name: 'All',food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'},id:'all',name:'All'}
 var arr = resp.response
 console.log(arr)
 var arry=arr.unshift(d1)
 console.log(arry)
 setDuplicatefso(arr)
}
  useEffect(()=>{
    if(values.district&&values.district){
      getOfficer(values.district)
    }
  },[values.district])
  const getTrader=(id)=>{
    var trad =  TypeOfTraders.filter(i=>i.id==id)[0]
    return trad.name
    }
  useEffect(() => {
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
      const userData = { ...values, district: user.district };
      setValues(userData);
  }
  
  if(user && user.user_types && user.user_types.includes("fso")){
    const userData = { ...values, district: user.district, fso: user.id };
    setValues(userData);
  }
      const getfs = async () => {
      await ongetFso();
    };
    getfs();
    getFoods()
  }, []);
  const getDis=async(data)=>{
    if(user && user.user_types && user.user_types.includes("do")||user && user.user_types && user.user_types.includes("fso")){
      var dat = data
    }
    else dat=data[0]
 await ongetDist({district:dat})
}
useEffect(()=>{
  if(values.district&&values.district){
    getDis(values.district)
  }
},[values&&values.district])
  // const getDown=(data)=>{
  //   return ;

  // }
  // useEffect(()=>{
  //   if(users){
  //     getDown(users)
  //   }
  // },[users])
  const colDetails =

  [
 
    {
      title: "Trader type",
      field: "type_of_trader",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "FSSAI License Number",
      field: "FSSAI_license_number",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Notice Issued Date",
      field: "date_notice_issued_date",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Notice Issued Under Section",
      field: "notice_issued_under_section",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Direct Case Sent DO on",
      field: "date_direct_case_sent_to_do_on",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Evidence Upload",
      field: "evidence_upload",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Direct Case Received By DO",
      field: "evidence_upload",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    // {
    //   title: "Case Converted",
    //   field: "case_converted",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Case Category",
    //   field: "case_category",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Report Intimated to FSO Date",
    //   field: "date_report_intimated_to_fso_on",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Appeal Received From FBO Date",
    //   field: "date_appeal_received_from_fbo_on",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Appeal Allowed",
    //   field: "appeal_allowed",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Another Part of Sample Sent Date",
    //   field: "date_sent_another_part_of_sample",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Lab Name",
    //   field: "lab_name",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Other Lab",
    //   field: "second_part_other_lab",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Lab Address",
    //   field: "lab_address",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Dismiss Reason",
    //   field: "dismiss_reason",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
  
    // {
    //   title: "DO File Received Date",
    //   field: "date_do_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "DO Clarification",
    //   field: "do_need_clarification",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "DO File Signed Date'",
    //   field: "date_do_file_signed_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "DO File Sent Date'",
    //   field: "date_do_file_sent_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "DO Remarks",
    //   field: "do_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "DO Document",
    //   field: "do_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "FSO File Received Date",
    //   field: "date_fso_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "FSO File Sent Date",
    //   field: "date_fso_file_sent_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "FSO Remarks",
    //   field: "fso_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO File Received Date",
    //   field: "date_ao_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO File Number",
    //   field: "ao_file_number",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO Remarks",
    //   field: "ao_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO Document",
    //   field: "ao_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO Case File Result",
    //   field: "ao_case_file_result",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "Judgement",
    //   field: "court_result",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Next Hearing Date",
    //   field: "date_next_hearing_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Hearing Remarks",
    //   field: "hearing_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Penalty Amount",
    //   field: "penalty_amount",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO Appeal Received From DO Date",
    //   field: "date_ao_appeal_received_from_do_on",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO Appeal Preferred By",
    //   field: "appeal_ao_preferred_by",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO Appeal Approved Date",
    //   field: "date_ao_appeal_approved_on",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AO Documents",
    //   field: "ao_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "SO File Received Date",
    //   field: "date_so_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "SO File Signed Date",
    //   field: "date_so_file_signed_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "SO Remarks",
    //   field: "so_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "SO Documents",
    //   field: "so_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II SO File Received Date",
    //   field: "date_app2_so_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II need Clarification",
    //   field: "app2_need_clarification",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II File Sent Date",
    //   field: "date_app2_file_sent_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II File Signed Date",
    //   field: "date_app2_file_signed_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II Remarks",
    //   field: "app2_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II Documents",
    //   field: "app2_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Final File Received Date",
    //   field: "date_dd_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Final File Sent Date",
    //   field: "date_dd_file_sent_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Final need Clarification",
    //   field: "dd_need_clarification",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Final File Signed Date",
    //   field: "date_dd_file_signed_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Final Remarks",
    //   field: "dd_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Final Documents",
    //   field: "dd_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "DO Clarification",
    //   field: "do_need_clarification",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AC File Received Date",
    //   field: "date_ac_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AC File Sent Date",
    //   field: "date_ac_file_sent_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AC need Clarification",
    //   field: "ac_need_clarification",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AC File Signed Date",
    //   field: "date_ac_file_signed_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "AC Remarks",
    //   field: "ac_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AC Documents",
    //   field: "ac_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Commissioner File Sent Date",
    //   field: "date_commissioner_file_sent_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Commissioner File Received Date",
    //   field: "date_commissioner_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Commissioner need Clarification'",
    //   field: "commissioner_need_clarification",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Commissioner File Signed Date",
    //   field: "date_commissioner_file_signed_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Commissioner Remarks",
    //   field: "commissioner_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Commissioner Documents",
    //   field: "commissioner_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Section File Number",
    //   field: "section_file_number",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "SO Final File Received Date",
    //   field: "date_so_final_file_received_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "SO Final File Sent Date'",
    //   field: "date_so_final_file_sent_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "DO Documents",
    //   field: "do_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "APP-II Final Filed at Court",
    //   field: "date_app2_final_filed_at_court",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II Final Documents",
    //   field: "app2_final_docs",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II Final Remarks",
    //   field: "app2_final_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP-II Case File Number",
    //   field: "app2_case_file_number",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "AC File Signed Date'",
    //   field: "do_need_clarification",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Case File Result",
    //   field: "app_case_file_result",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Hearing Remarks",
    //   field: "app_hearing_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "APP CaseFine File Result",
    //   field: "app_casefine_file_result",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
    // {
    //   title: "APP Next Hearing Date",
    //   field: "date_app_next_hearing_date",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "APP Hearing Remarks",
    //   field: "app_hearing_remarks",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Fine Amount",
    //   field: "fine_amount",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Fine Amount Prison'",
    //   field: "fine_amount_prison",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Appeal Received from DO on",
    //   field: "date_appeal_received_from_do_on",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Appeal Preferred By",
    //   field: "appeal_preferred_by",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },{
    //   title: "Appeal approved on",
    //   field: "date_appeal_approved_on",
    //   filtering: false,
    //   cellStyle: {
    //     textAlign: "center",
    //   },
    // },
  ]

  return (
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}> Non-Sample Details</h3>
      <div
          className="filter_container"
        >
      <div >
            <label htmlFor="date">From Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`From Date`}
                name="fromdate"
                id="fromdate"
                value={values.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextChange("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">To Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={values.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextChange("todate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="area_code"> Type of Trader: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text"
                name="type_of_trader"
                action="type_of_trader"
                value={values.type_of_trader}
                error={errors.type_of_trader}
                items={TypeOfTraders}
                onChange={(value) => handleTextChange("type_of_trader", value)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="fso">Sample Status: </label>
            <div className="filter_input" >
              <DropDownInput
                name="status"
                value={values.status_of_report}
                error={errors.status_of_report}
                items={Status}
                action="dropdown"
                onChange={(value) => handleTextChange("status_of_report", value)}
              />
            </div>
          </div>
          
          {/* <div >
            <label htmlFor="fso">Case category: </label>
            <div className="filter_input" >
              <DropDownInput
                name="status"
                value={values.case_category}
                error={errors.case_category}
                items={Case}
                action="dropdown"
                onChange={(value) => handleTextChange("case_category", value)}
              />
            </div>
          </div>
           */}
          {/* <div >
            <label htmlFor="fso">Judgement Result: </label>
            <div className="filter_input" >
              <DropDownInput
                name="status"
                value={values.court_result}
                error={errors.court_result}
                items={Judg}
                action="dropdown"
                onChange={(value) => handleTextChange("court_result                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      ", value)}
              />
            </div>
          </div> */}
          {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
          <div >
            <label htmlFor="district">District: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" isDisabled
                name="district"
                value={values.district}
                error={errors.district}
                items={districts}
                onChange={(value) => handleTextChange("district", value)}
              />
            </div>
          </div>
          ):(
          <div >
          <label htmlFor="district">District: </label>
          <div className="filter_input" >
            <DropDownInput
              type="text"
              name="district"
              // action='district'
              value={values.district}
              error={errors.district}
              items={duplicateDistrict}
              onChange={(value) => handleTextChange("district", value)}
            />
          </div>
          </div>
          )}
          {user && user && user.user_types && user.user_types.includes("fso") ? (
         <div >
            <label htmlFor="fso">FSO Officers: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" isDisabled
                name="fso"
                action="fsos"
                value={values.fso}
                error={errors.fso}
                items={fsoOfficers}
                onChange={(value) => handleTextChange("fso", value)}
              />
            </div>
          </div>):user && user && user.user_types && user.user_types.includes("do") ?(
            <div >
            <label htmlFor="fso">FSO Officers: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" 
                name="fso"
                action="fsos"
                value={values.fso}
                error={errors.fso}
                items={fsoOfficers&&fsoOfficers}
                onChange={(value) => handleTextChange("fso", value)}
              />
            </div>
          </div>
          ):(
             <div >
             <label htmlFor="fso">FSO Officers: </label>
             <div className="filter_input" >
               <DropDownInput
                 type="text"
                 name="fso"
                 action="fsos"
                 value={values.fso}
                 error={errors.fso}
                 items={duplicatefso}
                 onChange={(value) => handleTextChange("fso", value)}
               />
             </div>
           </div>
          )}
        {/* {wardsCategory?  <div >
             <label htmlFor="Area code">Area code: </label>
             <div className="filter_input" >
               <DropDownInput
                 name="area_code"
                 action="area_code"
                 value={values.area_code}
                 error={errors.area_code}
                 items={wardsCategory}
                 onChange={(value) => handleTextChange("area_code", value)}
               />
             </div>
           </div>:
           <div >
           <label htmlFor="Area code">Area code: </label>
           <div className="filter_input" >
             <DropDownInput
               name="area_code"
               action="area_code"
               value={values.area_code}
               error={errors.area_code}
               items={wards}
               onChange={(value) => handleTextChange("area_code", value)}
             />
           </div>
         </div>} */}
        </div>
        <div style={{marginTop:'30px',float:'right',marginLeft:'auto',marginRight:'30px',marginBottom:'60px'}} className="form-group ">
            <input
              onClick={handleFilt}
              type='button'
              value="Filter"
              className="btn btn-success ml-3"             
            />
              <input
              onClick={handleSubmit}
              type='button'
              value="Download"
              className="btn btn-success ml-3"             
            />
       <CSVLink 
            data={users}
            ref={dref}
            filename={'SampleDetails.csv'}
            // target='_blank'
            
            />
          </div>
       <br/>
       <br/>
        {filt ? (
          <div
            style={{ width: "90%", fontSize: "16px", }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={colDetails}
              style={{marginTop:'120px'}}
              data={filt}
              options={{
                exportButton: {
       csv: true,
       pdf: false,
    },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                  zIndex:0
                },
              }}
              title="Overall Non Sample Details List"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetFilterData: (data) => dispatch(getDownloadNonSampleone(data)),
  ongetFso: (data) => dispatch(getFsoOfficers(data)),
  onPutMasterData:(data)=>dispatch(getMasterSuccess(data)),
  ongetDist:(data)=>dispatch(getDistrictWards(data)),
  ongetFoodCategory:(data)=>dispatch(getFoodCategory(data)),
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  traderTypes: state.home.traderTypes,
  foodIssues: state.home.foodIssues,
  foodTypes: state.home.foodTypes,
  fsoOfficers: state.home.fsoOfficers,
  wardsCategory:state.home.wardsCategory,
  wards:state.home.wards,
  foodConditions:state.home.foodConditions,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SamplNonFilter);
