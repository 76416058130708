import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import Checkbox from '../../components/inputs/Checkbox';
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createPdwReports, editPdwReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from '../../components/inputs/TextAreaInput';

const PdwReport = (props) => {
    const { districts, user,wards,  onCreatePdwReports,wardsCategory,ongetDist, onEditPdwReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        'area_name':'',
        'total_no_of_units' : '',
        'units_functioning':'',
        'functioning_with_pwd':'',
        'functioning_without_pwd_noc':'',
        'having_bis' : '',
        'functioning_without_bis' : '',
        'having_fssai_license' : '',
        'functioning_without_fssai_license' : '',
        'functioning_with_courtorder' : '',
        'units_not_functioning': '',
        'bis_no':'',
        'name_and_address':'',
        'license_registration_number':'',
        'no_of_epo_po_issued':'',
        'type':'',
        
    });
    const type=[
        {id:'0', name:'Add'},
        {id:'1', name:'Remove'},
    ]
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();
    
    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        } 
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
         'date',
          'total_no_of_units', 
          'area_code',
          'area_name',
        'having_bis',
         'functioning_without_bis', 
         'having_fssai_license',
          'functioning_without_fssai_license',
        'functioning_with_courtorder',
        'units_not_functioning',
        'bis_no',
        'name_and_address',
        'license_registration_number',
        'no_of_epo_po_issued',
        'type',
    ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            console.log(data)
            const resp = await onEditPdwReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/pdw-list/`);
            };

        } else {       
            const data = { ...values}
            setDisableSubmit(true);
            console.log(data)
            const resp = await onCreatePdwReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }
    return (
        <div>
            <div className="container-fluid">
                <h1 className="h3 mb-4 text-gray-800">Packaged Drinking Water Report</h1>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >Name of the district : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                            ) : (
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="district"
                                    >Name of the district : </label>
                                    <div className="col-sm-8">
                                        <DropDownInput
                                            type="text"
                                            name="district"
                                            value={values.district}
                                            error={errors.district}
                                            items={districts}
                                            onChange={(text) => handleTextChange("district", text)}
                                        />
                                    </div>
                                </div>
                            )}
                              <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                                >Area Code *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                                >Area Name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Area Name`}
                                        type="text"
                                        name="area_name"
                                        value={values.area_name}
                                        error={errors.area_name}
                                        onChange={(e) => handleTextChange("area_name", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date"
                                >Month *: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Month`}
                                        name="date"
                                        id="date"
                                        showMonthYearPicker
                                        value={values.date}
                                        error={errors.date}
                                        onChangeText={(text) => handleTextChange('date', text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="total_no_of_units"
                                >Total Number of units  available*: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Total Number of units available`}
                                        type="number"
                                        name="total_no_of_units"
                                        value={values.total_no_of_units}
                                        error={errors.total_no_of_units}
                                        onChange={(e) => handleTextChange("total_no_of_units", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type"
                                >Units to be added/removed *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                        name="type"
                                        action="dropdown"
                                        value={values.type}
                                        error={errors.type}
                                        items={type}
                                        onChange={(value) => handleTextChange("type", value)}
                                />
                                </div>
                            </div>
                            {values && values.type && (<>
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="units_functioning"
                                >Number of Units Functioning : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of units functioning`}
                                        type="number"
                                        name="units_functioning"
                                        value={values.units_functioning}
                                        error={errors.units_functioning}
                                        onChange={(e) => handleTextChange("units_functioning", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="units_not_functioning"
                                >Number of units not  Functioning *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of units not  Functioning`}
                                        type="number"
                                        name="units_not_functioning"
                                        value={values.units_not_functioning}
                                        error={errors.units_not_functioning}
                                        onChange={(e) => handleTextChange("units_not_functioning", e.target.value)}
                                    />
                                </div>
                            </div>
                            </>)}
                            
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="having_bis"
                                >Number of units having Valid BIS *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of units having valid BIS`}
                                        type="number"
                                        name="having_bis"
                                        value={values.having_bis}
                                        error={errors.having_bis}
                                        onChange={(e) => handleTextChange("having_bis", e.target.value)}
                                    />
                                </div>
                            </div>
                            {values && values.having_bis&&(
                               <div className="form-group row">
                               <label className="control-label col-sm-4 text-label" htmlFor="bis_no"
                               > BIS Number *: </label>
                               <div className="col-sm-8">
                                   <TextInput
                                       placeholder={`BIS Number`}
                                       type="number"
                                       name="bis_no"
                                       value={values.bis_no}
                                       error={errors.bis_no}
                                       onChange={(e) => handleTextChange("bis_no", e.target.value)}
                                   />
                               </div>
                           </div> 
                            )}
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="functioning_with_pwd"
                                >Number of Units Functioning with PWD/NOC: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of units functioning with pwd/noc`}
                                        type="number"
                                        name="functioning_with_pwd"
                                        value={values.functioning_with_pwd}
                                        error={errors.functioning_with_pwd}
                                        onChange={(e) => handleTextChange("functioning_with_pwd", e.target.value)}
                                    />
                                </div>
                            </div>
                           
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="having_fssai_license"
                                >Number of units Functioning with Valid FSSAI License/Registration *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of units Functioning with FSSAI License/Registration.`}
                                        type="text"
                                        name="having_fssai_license"
                                        value={values.having_fssai_license}
                                        error={errors.having_fssai_license}
                                        onChange={(e) => handleTextChange("having_fssai_license", e.target.value)}
                                    />
                                </div>
                            </div>
                            {values && values.having_fssai_license && (<>
                                 <div className="form-group row">
                                 <label className="control-label col-sm-4 text-label" htmlFor="name_and_address"
                                 >Name and Address of PDW *: </label>
                                 <div className="col-sm-8">
                                     <TextAreaInput
                                         placeholder={`Name and Address of PDW `}
                                         name="name_and_address"
                                         type='text'
                                         value={values.name_and_address}
                                         error={errors.name_and_address}
                                         onChange={(e) => handleTextChange("name_and_address", e.target.value)}
                                     />
                                 </div>
                             </div>
                             <div className="form-group row">
                                 <label className="control-label col-sm-4 text-label" htmlFor="license_registration_number"
                                 >FSSAI License/Registration Number *: </label>
                                 <div className="col-sm-8">
                                     <TextInput
                                         placeholder={`FSSAI License/Registration Number`}
                                         name="name_and_address"
                                         type='text'
                                         value={values.license_registration_number}
                                         error={errors.license_registration_number}
                                         onChange={(e) => handleTextChange("license_registration_number", e.target.value)}
                                     />
                                 </div>
                             </div>
                             </>
                            )}
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="functioning_with_courtorder"
                                >Number of Units Functioning with  Court order *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Units Functioning with  Court order `}
                                        type="number"
                                        name="functioning_with_courtorder"
                                        value={values.functioning_with_courtorder}
                                        error={errors.functioning_with_courtorder}
                                        onChange={(e) => handleTextChange("functioning_with_courtorder", e.target.value)}
                                    />
                                </div>
                            </div>
                        
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="functioning_without_bis"
                                >Number of Units Functioning without BIS *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Units Functioning without BIS`}
                                        type="number"
                                        name="functioning_without_bis"
                                        value={values.functioning_without_bis}
                                        error={errors.functioning_without_bis}
                                        onChange={(e) => handleTextChange("functioning_without_bis", e.target.value)}
                                    />
                                </div>
                            </div>
                           
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="functioning_without_pwd_noc"
                                >Number of Units Functioning without PWD/NOC : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Units Functioning without pwd/noc`}
                                        type="number"
                                        name="functioning_without_pwd_noc"
                                        value={values.functioning_without_pwd_noc}
                                        error={errors.functioning_without_pwd_noc}
                                        onChange={(e) => handleTextChange("functioning_without_pwd_noc", e.target.value)}
                                    />
                                </div>
                            </div>
                          
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="functioning_without_fssai_license"
                                >Number of Units Functioning without FSSAI License/Registration *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Units Functioning without FSSAI LLicense/Registration`}
                                        type="number"
                                        name="functioning_without_fssai_license"
                                        value={values.functioning_without_fssai_license}
                                        error={errors.functioning_without_fssai_license}
                                        onChange={(e) => handleTextChange("functioning_without_fssai_license", e.target.value)}
                                    />
                                </div>
                            </div>
                            
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_epo_po_issued"
                                >Number of EPO/PO Issued *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of EPO/PO Issued`}
                                        type="number"
                                        name="no_of_epo_po_issued"
                                        value={values.no_of_epo_po_issued}
                                        error={errors.no_of_epo_po_issued}
                                        onChange={(e) => handleTextChange("no_of_epo_po_issued", e.target.value)}
                                    />
                                </div>
                            </div>
                          
                            <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreatePdwReports: (data) => dispatch(createPdwReports(data)),
    onEditPdwReports: (data, record_id) => dispatch(editPdwReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(PdwReport)
