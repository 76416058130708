import React from "react";
import { Row, Col } from "reactstrap";
import Accordion from "../../../components/Accordion/Accordion";
import { useTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import { toCamel } from "../../../utils/toCamel";
import { connect } from "react-redux";

const PreviewInspection = (props) => {
  const { t } = useTranslation();
  const {
    inspection,
    complaint,
    disableSubmit,
    districts,
    isSpinner,
    submitComplaintInspection,
    goBack,
  } = props;

  let district = null;
  districts &&
    districts.forEach((item) => {
      if (inspection && item.id === inspection.district) {
        district = item.name;
      }
    });

  return (
    <>
      <div className="text-center">
        <h3>{t("Preview")}</h3>
      </div>
      <div className="form-group mt-5 comment-form">
        <Accordion title={t("Address")} isExpand={true}>
          <div>
            <div className="d-flex">
              <div className="content-left">
                {t("Same address as provided in the complaint ?")}
              </div>
              <div>{inspection.same_address ? t("Yes") : t("No")}</div>
            </div>
            <div>
              <p>
                {!inspection.same_address
                  ? `${inspection.street}, ${inspection.sub_division}, ${inspection.village}, ${district}, ${inspection.zipcode}`
                  : [complaint ? complaint.address : null]}
              </p>
            </div>
            {inspection && inspection.ward_number ? (
              <div className="d-flex">
                <div className="text-left mb-2">Ward Number:</div>
                <div className="ml-1">{complaint.ward_number}</div>
              </div>
            ) : null}
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Fssai Number")} isExpand={true}>
          <div>
            <div className="d-flex">
              <div className="content-left">{t("Has Fssai Number ?")}</div>
              <div>{inspection.has_fssai_number ? t("Yes") : t("No")}</div>
            </div>
            {inspection.has_fssai_number ? (
              <>
                <div className="d-flex">
                  <div className="content-left">{t("Fssai Number")}</div>
                  <div>{inspection.fssai_number}</div>
                </div>
              </>
            ) : null}
            <div className="form-group">
              <Row>
                {inspection.fssai_image_1 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fssai_image_1"
                      src={`${inspection.fssai_image_1}`}
                    />
                  </Col>
                ) : null}
                {inspection.fssai_image_2 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fssai_image_2"
                      src={`${inspection.fssai_image_2}`}
                    />
                  </Col>
                ) : null}
                {inspection.fssai_image_3 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fssai_image_3"
                      src={`${inspection.fssai_image_3}`}
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Authorised person in premises")} isExpand={true}>
          <div>
            <div className="d-flex">
              <div className="content-left">{t("Name")}</div>
              <div>{inspection.auth_person_name}</div>
            </div>
            <div className="d-flex">
              <div className="content-left">{t("Designation")}</div>
              <div>{inspection.auth_person_designation}</div>
            </div>
            <div className="d-flex">
              <div className="content-left">{t("Phone Number")}</div>
              <div>{inspection.auth_person_phone_number}</div>
            </div>
            <div className="form-group">
              <Row>
                {inspection.auth_person_photo_image_1 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="auth_person_photo_image_1"
                      src={`${inspection.auth_person_photo_image_1}`}
                    />
                  </Col>
                ) : null}
                {inspection.auth_person_photo_image_2 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="auth_person_photo_image_2"
                      src={`${inspection.auth_person_photo_image_2}`}
                    />
                  </Col>
                ) : null}
                {inspection.auth_person_photo_image_3 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="auth_person_photo_image_3"
                      src={`${inspection.auth_person_photo_image_3}`}
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Nature of Complaint")} isExpand={true}>
          <div>
            <p>{inspection.nature_of_complaint}</p>
            <div className="form-group">
              <Row>
                {inspection.nature_of_complaint_image_1 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="nature_of_complaint_image_1"
                      src={`${inspection.nature_of_complaint_image_1}`}
                    />
                  </Col>
                ) : null}
                {inspection.nature_of_complaint_image_2 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="nature_of_complaint_image_2"
                      src={`${inspection.nature_of_complaint_image_2}`}
                    />
                  </Col>
                ) : null}
                {inspection.nature_of_complaint_image_3 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="nature_of_complaint_image_3"
                      src={`${inspection.nature_of_complaint_image_3}`}
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Fso Observation")} isExpand={true}>
          <div>
            <p>{inspection.fso_observation}</p>
            <div className="form-group">
              <Row>
                {inspection.fso_observation_image_1 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fso_observation_image_1"
                      src={`${inspection.fso_observation_image_1}`}
                    />
                  </Col>
                ) : null}
                {inspection.fso_observation_image_2 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fso_observation_image_2"
                      src={`${inspection.fso_observation_image_2}`}
                    />
                  </Col>
                ) : null}
                {inspection.fso_observation_image_3 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fso_observation_image_3"
                      src={`${inspection.fso_observation_image_3}`}
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Action Taken")} isExpand={true}>
          <div>
            <p>
              {inspection.action_taken.map((item, idx) => (
                <>
                  {t(toCamel(item))}
                  {idx !== inspection.action_taken.length - 1 ? "," : ""}{" "}
                </>
              ))}
            </p>
            {inspection.action_taken.includes("sample_lifted") &&
              inspection.action_taken_sample_number && (
                <>
                  <div className="d-flex">
                    <div className="text-left">Sample Number:</div>
                    <div className="ml-1">
                      {inspection.action_taken_sample_number}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="text-left mb-2">Sample Lifted Comment:</div>
                    <div className="ml-1">
                      {inspection.sample_lifted_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("fine") &&
              inspection.action_taken_fine_amount && (
                <>
                  <div className="d-flex">
                    <div className="text-left">Fine Amount:</div>
                    <div className="ml-1">
                      {inspection.action_taken_fine_amount}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="text-left mb-2">Fine Comment:</div>
                    <div className="ml-1">
                      {inspection.fine_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("close_complaint") &&
              inspection.closed_action_taken_message && (
                <>
                  <div className="d-flex">
                    <div className="text-left mb-2">
                      Close Complaint Comment:
                    </div>
                    <div className="ml-1">
                      {inspection.closed_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("seizure") &&
              inspection.seizure_action_taken_message && (
                <>
                  <div className="d-flex">
                    <div className="text-left mb-2">Seizure Comment:</div>
                    <div className="ml-1">
                      {inspection.seizure_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            {inspection.action_taken.includes("notice") &&
              inspection.notice_action_taken_message && (
                <>
                  <div className="d-flex">
                    <div className="text-left mb-2">Notice Comment:</div>
                    <div className="ml-1">
                      {inspection.notice_action_taken_message}
                    </div>
                  </div>
                </>
              )}
            <div className="form-group">
              <Row>
                {inspection.action_taken_photo_image_1 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="action_taken_photo_image_1"
                      src={`${inspection.action_taken_photo_image_1}`}
                    />
                  </Col>
                ) : null}
                {inspection.action_taken_photo_image_2 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="action_taken_photo_image_2"
                      src={`${inspection.action_taken_photo_image_2}`}
                    />
                  </Col>
                ) : null}
                {inspection.action_taken_photo_image_3 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="action_taken_photo_image_3"
                      src={`${inspection.action_taken_photo_image_3}`}
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="form-group comment-form">
        <Accordion title={t("Fso Additional Comments")} isExpand={true}>
          <div>
            <p>{inspection.fso_additional_comment}</p>
            <div className="form-group">
              <Row>
                {inspection.fso_add_comment_image_1 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fso_add_comment_image_1"
                      src={`${inspection.fso_add_comment_image_1}`}
                    />
                  </Col>
                ) : null}
                {inspection.fso_add_comment_image_2 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fso_add_comment_image_2"
                      src={`${inspection.fso_add_comment_image_2}`}
                    />
                  </Col>
                ) : null}
                {inspection.fso_add_comment_image_3 ? (
                  <Col md="2">
                    <img
                      style={{
                        width: 60,
                        height: 60,
                      }}
                      alt="fso_add_comment_image_3"
                      src={`${inspection.fso_add_comment_image_3}`}
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
        </Accordion>
      </div>
      <div className="form-group ml-4 mt-5">
        <button
          onClick={() => goBack()}
          className="btn btn-success"
          type="button"
        >
          {t("Back")}
        </button>
        <button
          disabled={disableSubmit}
          onClick={submitComplaintInspection}
          type="button"
          className="btn btn-success ml-3"
        >
          <ClipLoader
            color="#fff"
            loading={isSpinner}
            size={15}
            css={{ marginRight: "10px" }}
          />
          {t("Submit")}
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  districts: state.home.districts,
});

export default connect(mapStateToProps)(PreviewInspection);
