import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createCSFHReports, editCSFHReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const FoodVendor = [
    {id:"0", name:"Tiffen Stall"},
    {id:"1", name:"Pani Poori Shop"},
    {id:"2", name:"Fruit Stall shop"},
    {id:"3", name:"Vegetable Shop"},
    {id:"4", name:"Fast Food Shop"},
    {id:"5", name:"Others"}
]

const CSFHReport = (props) => {
    const { districts, user,wards, onCreateCSFHReports,wardsCategory,ongetDist, onEditCSFHReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'address':'',
        'type_of_food_vendor':'',
        'no_of_participants':'',
        'vendor_available':'',
        'documents':'',
        'name_vendor':''
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const ValidateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'name_vendor','address','no_of_participants','type_of_food_vendor','date_valid','area_name','area_code'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditCSFHReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/clean-food-list/`);
            };

        } else { 
            const data = { ...values}
            console.log(data)
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateCSFHReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        ValidateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Clean Street Food Hub Certification Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do")|| user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date of certification *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_vendor"
                            >Name Of the Street Food Hub Certified *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the street food hub`}
                                    type="text"
                                    name="nname_vendorame"
                                    value={values.name_vendor}
                                    error={errors.name_vendor}
                                    onChange={(e) =>handleTextChange("name_vendor", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address"
                            >Address Of the Street Food Hub Certified *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the street food hub`}
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    error={errors.address}
                                    onChange={(e) =>handleTextChange("address", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_food_vendor"
                            >Type Of Food Vendor *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of Food Vendor`}
                                    type="text"
                                    name="type_of_food_vendor"
                                    items={FoodVendor}
                                    action="dropdown"
                                    value={values.type_of_food_vendor}
                                    error={errors.type_of_food_vendor}
                                    onChange={(text) =>handleTextChange("type_of_food_vendor", text)}
                                />
                            </div>
                        </div> 
                        {values && values.type_of_food_vendor.includes('Others')? (
                             <div className="form-group row">
                             <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_food_vendor"
                             > </label>
                             <div className="col-sm-8">
                                 <TextInput
                                     placeholder={`Type of Food Vendor`}
                                     type="text"
                                     name="other_type_of_food_vendor"
                                     value={values.other_type_of_food_vendor}
                                     error={errors.other_type_of_food_vendor}
                                     onChange={(e) =>handleTextChange("other_type_of_food_vendor", e.target.value)}
                                 />
                             </div>
                         </div>
                        ):null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_participants"
                            >Number Of Clean Street Food Hub Certified *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of clean street food hub Hub Certified`}
                                    type="number"
                                    name="no_of_participants"
                                    value={values.no_of_participants}
                                    error={errors.no_of_participants}
                                    onChange={(e) =>handleTextChange("no_of_participants", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="vendor_available"
                            >Number Of Vendors available in the Hub: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of Vendors available in the Hub`}
                                    type="number"
                                    name="vendor_available"
                                    value={values.vendor_available}
                                    error={errors.vendor_available}
                                    onChange={(e) =>handleTextChange("vendor_available", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_valid"
                            >Certification date_valid Upto *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date_valid"
                                    id="date_valid"
                                    value={values.date_valid }
                                    error={errors.date_valid}
                                    onChangeText={(text) => handleTextChange('date_valid', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="documents"
                            >Uplaod Documents (If any) : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="documents"
                                    error={errors.documents}
                                    onChange={(e) =>handleTextChange("documents", e.target.files[0])}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateCSFHReports: (data) => dispatch(createCSFHReports(data)),
    onEditCSFHReports: (data, record_id) => dispatch(editCSFHReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(CSFHReport);