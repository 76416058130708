import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const SeizureDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            console.log(recordData)
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
   
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Food Product Seizure Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                
                <tr>
                  <th>FBO Type</th>
                  <td className="text-left">{records.fbo_type}</td>
                </tr>
              {records.name_of_the_shop &&  <tr>
                  <th>Name Of the Shop</th>
                   <td className="text-left">{records.name_of_the_shop}</td>
                </tr>}
              <tr>
                <th>Name Of the Owner/Partner</th>
                <td className="text-left">{records.name_of_the_fbo}</td>
              </tr>              
              <tr>
                <th>Address Of the FBO with License/Registration Number</th>
                <td className="text-left">{records.address_of_the_fbo}</td>
              </tr>
              <tr>
                <th>Name of the Owner</th>
                <td className="text-left">{records.name_of_the_owner}</td>
              </tr>
              <tr>
                <th>Type of Product seizure</th>
                <td className="text-left">{records.type_of_seizure}</td>
              </tr>
               {records.banded && <tr>
                <th>Type of Banded Product</th>
                <td className="text-left">{records.banded}</td>
              </tr>}
             {records.food && <tr>
                <th>Type of Food Product</th>
                <td className="text-left">{records.food}</td>
              </tr> }
              {records.food && <tr>
                <th>Type of Food Product seizure - other</th>
                <td className="text-left">{records.other_type_of_seizure}</td>
              </tr> }
             { records.product_details && <tr>
                <th>Name of the Product </th>
                <td className="text-left">{records.product_details}</td>
              </tr>}
            {records.batch_no &&  <tr>
                <th>Batch No</th>
                <td className="text-left">{records.batch_no}</td>
              </tr>}
             {records.date_of_packing && <tr>
                <th>Date of Packing </th>
                <td className="text-left">{moment(records.date_of_packing).format("l")}</td>
              </tr>}
              {records.num_of_units && <tr>
                <th>Number Of Units</th>
                <td className="text-left">{records.num_of_units}</td>
              </tr>}
             {records.quantity_in_kg && <tr>
                <th>Quantity in KG's</th>
                <td className="text-left">{records.quantity_in_kg}</td>
              </tr>}
             {records.value_in_RS && <tr>
                <th>Value in RS</th>
                <td className="text-left">{records.value_in_RS}</td>
              </tr>}
              {records.seized_under_section && <tr>
                <th>SeizedForm Details</th>
                <td className="text-left">{records.seized_under_section}</td>
              </tr>}
              {records.details && <tr>
                <th>SeizedForm - Details</th>
                <td className="text-left">{records.details}</td>
              </tr>}
              {records.seized_under_custody_of && <tr>
                <th>Seized Under Custody of</th>
                <td className="text-left">{records.seized_under_custody_of}</td>
              </tr>}
              {records.other_seized_under_custody_of && <tr>
                <th>Seized Under Custody of - Others</th>
                <td className="text-left">{records.other_seized_under_custody_of}</td>
              </tr>}
              {records.name_of_official && <tr>
                <th>Name of the Official </th>
                <td className="text-left">{records.name_of_official}</td>
              </tr>}
              {records.address_of_official && <tr>
                <th>Address Of the Official</th>
                <td className="text-left">{records.address_of_official}</td>
              </tr>}
              {records.default_found && <tr>
                <th>Violations Found</th>
                <td className="text-left">{records.default_found}</td>
              </tr>}
              {records.upload_documents && <tr>
                <th>Upload Documents</th>
                <td className="text-left">{records.upload_documents}</td>
              </tr>}
              
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(SeizureDetails);