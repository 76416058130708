import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import ModelPopup from "../../components/ModelPopup";
import { Link } from "react-router-dom";
import {
  addFboDetails,
  deleteComplaint,
  editFSOSampleReports,
  getComplaints,
  getFsoOfficers,
  getLabDifferentReports,
  getSampleStatus,
  updateCivil,
  updateCriminal,
  updateSample,
  updateSampleList,
} from "../../components/reducers/actions";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/FsoAccordion/FsoAccordion";
import Table from "../../components/Table/Table";
import LoadingOverlay from "react-loading-overlay";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import TextInput from "../../components/inputs/TextInput";
import Datepicker from "../../components/inputs/Datepicker";
const TypeOfTraders = [
  { id: "0", name: "Manufacturer" },
  { id: "1", name: "Retailer" },
  { id: "2", name: "WholeSaler/Distributor/Repacker" },
  { id: "3", name: "Transporter" },
  // { id: "4", name: "Others" },
];
const FSOEntry = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const {
    user,
    onComplaints,
    onGetFsoOfficers,
    sample_data,
    fso_submit_data,
    lab_analyse_sample,
    req_sample,
    resultObtained,
    criminalResults,
    civilResults,
    ongetSample,
    overall_Sample,
    ongetDLabs,
    districts,
    onaddFbo,
    fsoOfficers,
    ongetFso,
    onEditFSOSampleReports,
  } = props;
  console.log(props);
  const [activeTab, setActiveTab] = useState(
    user && user.user_types.includes("fso") ? "1" : "1"
  );
  const { t, i18n } = useTranslation();
  const [diffSample, setDiffSample]=useState([]);
  const[toggleAction,setToggleAction]=useState(false)
  const [pageClosed, setPageClosed] = useState(1);
  const [pageAssign, setPageAssign] = useState(1);
  const [pageAssignfso, setPageAssignfso] = useState(1);
  const [selectedFso, setSelectedFso] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagelist, setPageList] = useState(1);
  const [pageAnalyse, setPageAnalyse] = useState(1);
  const [pageResult, setPageResult] = useState(1);
  const [pageWaitingresult, setPageWaitingResult] = useState(1);
  const [pageCivil, setPageCivil] = useState(1);
  const [pageCivilWait, setPageCivilWait] = useState(1);
  const [pageCriminal, setPageCriminal] = useState(1);
  const [pageCriminalWait, setPageCriminalWait] = useState(1);
  const [activeAccordion, setActiveAccordion] = useState(-1);
  const [Addmodal, setAddModal] = useState(false);
  const [id, setId]=useState();
  const [ids, setIds]=useState();
  const [fsoId, setFsoOId]=useState();
  const [values, setValues] = useState([
    {
      type_of_trader: "",
      trader_name: "",
      trader_address: "",
      upload_docs: '',
      date_V_A_sent_on:'',
    },
  ]);
  const countPerPage = 10;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  useEffect(() => {
    if (user && user.user_types.includes("do")) {
      onGetFsoOfficers({
        fso_category: true,
      });
      if (props.history.location && props.history.location.state) {
        setActiveTab(props.history.location.state.tab);
      }
    }
  }, []);
useEffect(()=>{
  if(user&&user.district&&user.district){
    getOfficer(user.district)
  }
},[user&&user])
  const loadPageWaitingResult = async () => {
    setLoading(true);
    if (activeTab === "4") {
      await ongetSample({
        page: pageWaitingresult,
        per_page: countPerPage,
        is_base64: true,
        status:user&&user.user_types&&user.user_types.includes('do')? "Result submitted to DO":'waiting for another part sample',
      });
      setLoading(false);
    }
    
  };
  const loadSamples = async () => {
    setLoading(true);
    if (activeTab === "1") {
      await ongetSample({
        page: pagelist,
        per_page: countPerPage,
        is_base64: true,
        status:(user&&user.user_types&&user.user_types.includes('do')||user&&user.user_types&&user.user_types.includes('fso'))?'all':"Submitted to lab",
      });
      setLoading(false);
    }
  };

  const loadAnalyseSample = async () => {
    setLoading(true);
    if (activeTab === "2") {
      await ongetSample({
        page: pageAnalyse,
        per_page: countPerPage,
        is_base64: true,
        status:user&&user.user_types&&user.user_types.includes('fso')?'Submitted to lab':user&&user.user_types&&user.user_types.includes('do')?'Waiting for submit':"Waiting for analysis",
      });
      setLoading(false);
    }
  };
  const loadSampless = async () => {
    setLoading(true);
    if (activeTab === "3") {
      await ongetSample({
        page: pageResult,
        per_page: countPerPage,
        is_base64: true,
        status:user&&user.user_types&&user.user_types.includes('do')?'waiting for another part sample':user&&user.user_types&&user.user_types.includes('fso')?'Waiting for submit': "waiting for lab result",
      });
      setLoading(false);
    }
  };
 
  const loadCivil = async () => {
    setLoading(true);
    if (activeTab === "5" && user&&user.user_types&&user.user_types.includes('do')) {
      const resp = await ongetDLabs({status:'all'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);

    }
    if (activeTab === "4" && user&&user.user_types&&user.user_types.includes('fso')) {
      const resp = await ongetDLabs({status:'all'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);

    }
  };
 
  useEffect(() => {
    loadSampless();
  }, [pageAssign, activeTab]);
  useEffect(() => {
    loadSamples();
  }, [pagelist, activeTab]);
  useEffect(() => {
    loadPageWaitingResult();
  }, [pageWaitingresult, activeTab]);
  useEffect(() => {
    loadAnalyseSample();
  }, [pageAnalyse, activeTab]);
  useEffect(() => {
    loadCivil();
  }, [pageCivilWait, activeTab]);

  const getDistrictName =(id)=>{
    var nam = ''
    let data = districts && districts.filter(i=>id==i.id)[0]
    nam=data.name
    return nam
} 
const getOfficer=async(id)=>{
  const data={district_id:id}
 const resp = await ongetFso(data)
 console.log('resp', resp,data)
}
const setRowid=(id)=>{
  setAddModal(true)
  setId(id)
}
const setRows=(id)=>{
  setIds(id)
  setToggleAction(true)
}
const toBase64 = file => new Promise((resolve, reject) => {
  let baseURL = "";
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    baseURL = reader.result;
    resolve(baseURL);
  };
});
const conver =async(dat)=>{
  var data=''
  var datas =await toBase64(dat).then(result => {
   data  = result;
  })
  .catch(err => {
    console.log(err);
  });
  return data
}
  const submitFbo=async()=>{
    const data = [...values]
  const resp = await onaddFbo(data,id)
  console.log(resp)
  if(resp ){
    setAddModal(false)
   props.history.push('/app/dep-sample-reports')
  }
  }
  const handleActionChange = (e, index) => {
    const { name, value } = e.target;
    const valuess = [...values];
    valuess[index][name] = value;
    setValues(valuess);
  };
  const handlePlus = () => {
    setValues([
      ...values,
      {
        type_of_trader: "",
        trader_name: "",
        trader_address: "",
        upload_docs: "",
      },
    ]);
  };
  const handleLess = (index) => {
    const valuess = [...values];
    valuess.splice(index, 1);
    setValues(valuess);
  };
    const handleAssignFso=(text,txt)=>{
       setFsoOId(txt)
    }

const submitFso=async()=>{
  var data = new FormData()
  data.append('fso',parseInt(fsoId))
  console.log(fsoId)
  const resp=await onEditFSOSampleReports(data,ids)
  if(resp&&resp.data == 'success'){
   window.location.href =  '/app/dep-sample-reports'
  }
}

  const customStyles = {
    rows: {
      style: {
        minHeight: "82px",
        fontSize: "18px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        minHeight: "72px",
        marginTop: "20px",
        fontWeight: "bold",
        color: "#1589FF",
        fontSize: "18px",
        background: "#e1eef0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };
  var column = [
    {
      name: "District",
      cell: (row) => <div>{getDistrictName(row.district)}</div>,
      selector: "sample_number_given_by_do",
      sortable: true,
    },
    {
      name: "Sample Received date",
      cell: (row) => (
        <div>
        {row.date_sample_received_date? moment(row.date_sample_received_date).format("DD/MM/YYYY"):'-'}
        </div>
      ),
      selector: "date_sample_lifted_date_and_time",
      sortable: true,
    },
    {
      name: "Lab Code",
      cell: (row) => <div>{row.lab_code}</div>,
      selector: "lab_code",
      sortable: true,
    },
    {
      name: "Result Type",
      cell: (row) => <div>{row.result_type}</div>,
      selector: "result_type",
      sortable: true,
    },
  ]
  var columns = [
    {
      name: "Sample number",
      cell: (row) => <div>{row.sample_number_given_by_do}</div>,
      selector: "sample_number_given_by_do",
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => <div>{getDistrictName(row.district)}</div>,
      selector: "district",
      sortable: true,
    },
    {
      name: "FSO",
      cell: (row) => <div>{row.fso_name}</div>,
      selector: "assigned_fso",
      sortable: true,
    },
    {
      name: "Sample Type",
      cell: (row) => <div>{row.type_of_sample_lifted}</div>,
      selector: "type_of_sample_lifted",
      sortable: true,
    },
    {
      name: "Sample Lifted date",
      cell: (row) => (
        <div>
        {row.date_sample_lifted_date_and_time? moment(row.date_sample_lifted_date_and_time).format("DD/MM/YYYY"):'-'}
        </div>
      ),
      selector: "date_sample_lifted_date_and_time",
      sortable: true,
    },
    {
      name: "Sample Status",
      cell: (row) => <div>{row.status_of_report}</div>,
      selector: "status_of_report",
      sortable: true,
    },
    {
      name: t("Action"),
      cell: (row) => (
        <div className="action__area">
          {activeTab == "1" ? (
            <div>
              {user &&
                user.user_types &&
                user.user_types.includes("cfa") &&
                !row.result_docs && (
                  <Link
                    to={{
                      pathname: `/app/reports/fso_sample_reports`,
                      state: {
                        values: row,
                        action_type:
                          row.status_of_report == "Waiting for analysis"
                            ? "sample_fit"
                            : "analyse",
                            // showLess:true,
                            valdate:true,
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px" }}
                      className="btn btn-info btn-sm"
                    >
                      {row.status_of_report == "Waiting for analysis"
                        ? "Analyse Sample"
                        : row.status_of_report == "waiting for lab result"
                        ? "Add result"
                        : "Add sample code"}
                    </Button>
                  </Link>
                )}
                  {(user &&
                user.user_types &&
                user.user_types.includes("fso")||user &&
                user.user_types &&
                user.user_types.includes("do")) && (row.status_of_report!=='Final Judgement'&&row.status_of_report!=='Final Judgement_')&&row.type_of_sample_lifted=='Act'&&
                 (
                  
                    <Button
                      style={{ fontSize: "12px",margin:'2px' }}
                      className="btn btn-info btn-sm"
                      onClick={()=>setRowid(row.id)}
                    >
                      Add FBO
                    </Button>
                )}
                 {(user &&
                user.user_types &&
                user.user_types.includes("fso")||user &&
                user.user_types &&
                user.user_types.includes("do")) && (row.status_of_report!=='Final Judgement'&&row.status_of_report!=='Final Judgement_')&&
                 (
                  
                    <Button
                      style={{ fontSize: "12px",margin:'2px' }}
                      className="btn btn-info btn-sm"
                      onClick={() => setRows(row.id)}
                    >
                     Reassign to FSO
                    </Button>
                )}
            </div>
          ) : null}
          {activeTab == "2" ? (
            <>
              {user && user.user_types && user.user_types.includes("cfa") ? (
                <Link
                  to={{
                    pathname: `/app/reports/fso_sample_reports`,
                    state: {
                      values: row,
                      action_type:"sample_fit",
                      // showLess:true,
                      s_fit:true,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                   Analyse Sample
                  </Button>
                </Link>
              ) : null}
            </>
          ) : null}
          {activeTab == "3" ? (
            <>
             {user && user.user_types && user.user_types.includes("cfa") ? (
                <Link
                  to={{
                    pathname: `/app/reports/fso_sample_reports`,
                    state: {
                      values: row,
                      action: "add_result",
                      // showLess:true
                      rs_fit:true
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                    Add result
                  </Button>
                </Link>
              ) : null}
              {user && user.user_types && user.user_types.includes("do") ? (
                <Link
                  to={{
                    pathname: `/app/reports/fso_sample_reports`,
                    state: {
                      values: row,
                      anotherPart:true,
                      // showLess:true
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                    Send another part sample
                  </Button>
                </Link>
              ) : null}
              {user &&
                user.user_types &&
                user.user_types.includes("fso") &&
                <Link 
                to={{
                  pathname: `/app/reports/fso_sample_reports`,
                  state: {
                    values: row,
                    showLess:true,
                    admore:true,
                  },
                }}
                >
                <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                   Add More details
                  </Button>
                </Link>}
            </>
          ) : null}
          {activeTab == "4" ? (
            <div>
                  {user &&
                        user.user_types &&
                        user.user_types.includes("do") ?<> 
                   {row.upload_sample_documents && <Button
                      className="btn btn-info btn-sm"
                      style={{ fontSize: "12px", margin: "2px" }}
                      onClick={() =>
                        window.open(row.upload_sample_documents)
                      }
                    >
                      View Result
                    </Button>}
                    <Link
                    to={{
                      pathname:`/app/reports/fso_sample_reports`,
                      state: {
                        values: row,
                        sampleAppeal: true,
                        // showLess:true
                      },

                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", marginLeft: "3px" }}
                      className="btn btn-info btn-sm"
                    >
                      {user &&
                        user.user_types &&
                        user.user_types.includes("do") &&
                        activeTab === "4" &&
                        "Appeal request"}
                    </Button>
                  </Link>
               {row.type_of_sample_lifted =='Act'? <Link
                    to={{
                      pathname: `/app/reports/fso_sample_reports`,
                      state: {
                        values: row,
                        action_t: "case_submit",
                        // showLess:true,
                        action_type:'sample_fit',
                        action:'add_result',
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", marginLeft: "3px" ,marginBottom:'2px'}}
                      className="btn btn-info btn-sm mt-1"
                     >
                        convert case
                    </Button>
                  </Link>:<Link
                    to={{
                      pathname: `/app/reports/fso_sample_reports`,
                      state: {
                        values: row,
                        surveil_sample:true
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", marginLeft: "3px" }}
                      className="btn btn-info btn-sm"
                     >
                       Send Result to FBO
                    </Button>
                  </Link> }
             </>:null}
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const toggle = () => setModal(!modal);

  const handleDrp = (value, index) => {
    const filter = [...values];
    filter[index].type_of_trader = value;
    setValues(filter);
  };
  const handleDate = (value, index) => {
    const filter = [...values];
    filter[index].date_V_A_sent_on = value;
    setValues(filter);
  };
  const handleFile = async(e, index) => {
    const filter = [...values];
    filter[index].upload_docs = await conver(e.target.files[0]);
    setValues(filter);
  };
  return (
    <div className="container-fluid">
      {/* {modal ? (
        <ModelPopup
          modal={modal}
          data={selectedItem}
          handleDeleteSubmit={handleDeleteSubmit}
          title="Complaint"
          toggle={toggle}
        ></ModelPopup>
      ) : null} */}
      {Addmodal ? (
        <Modal isOpen={Addmodal}>
          <ModalHeader style={{ fontSize: "12px" }}>
            Add FBO Details
          </ModalHeader>
          <ModalBody>
            <div>
              {values.map((x, i) => {
                return (
                  <>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="type_of_trader"
                      >
                        Type of trader:{" "}
                      </label>
                      <div className="col-sm-8">
                        <DropDownInput
                          placeholder={`Type of Trader`}
                          type="text"
                          items={TypeOfTraders}
                          action="dropdown"
                          name="type_of_trader"
                          value={x.type_of_trader}
                          onChange={(text) => handleDrp(text, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                        Trader name:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextInput
                          placeholder={`Inspected Premisis Owner Name`}
                          type="text"
                          name="trader_name"
                          value={x.trader_name}
                          onChange={(e) => handleActionChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="address_of_respondant_premisis"
                      >
                        {" "}
                        Trader address:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextAreaInput
                          placeholder={`Inspected Premisis Owner Address`}
                          type="text"
                          name="trader_address"
                          value={x.trader_address}
                          onChange={(e) => handleActionChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                        Trader documents:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextInput
                          placeholder={`Inspected Premisis Owner Name`}
                          type="file"
                          name="upload_docs"
                          // value={x.upload_docs}
                          onChange={(e) => handleFile(e, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                       V-A sent on:{" "}
                      </label>
                      <div className="col-sm-8">
                        <Datepicker
                          placeholder={`V-A sent on`}
                          type="file"
                          name="date_V_A_sent_on"
                          value={x.date_V_A_sent_on}
                          onChangeText={(text) => handleDate(text, i)}
                        />
                      </div>
                    </div>
                    {i !== values.length - 1 && (
                      <button
                        style={{ float: "right" }}
                        className="btn btn-warning btn-sm btn-circle"
                        onClick={() => handleLess(i)}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    )}
                    {values.length - 1 === i && (
                      <button
                        style={{ float: "right" }}
                        className="btn btn-success btn-sm btn-circle"
                        onClick={handlePlus}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    )}
                  </>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="form-group">
              <button
                onClick={() => setAddModal(false)}
                className="btn btn-success"
                type="button"
              >
                Cancel
              </button>
              <button
                // disabled={disableSubmit}
                onClick={submitFbo}
                type="button"
                className="btn btn-success ml-3"
              >
                Submit
              </button>
            </div>
          </ModalFooter>
        </Modal>
      ) : null}
      {toggleAction ? (
          <Modal isOpen={toggleAction}>
            <ModalHeader toggle={() => setToggleAction(false)}>
              {t("Actions")}
            </ModalHeader>
            <ModalBody>
              <div>
                  <>
                    <div className="form-group">
                      <DropDownInput
                        type="text"
                        items={fsoOfficers}
                        name="assigned_fso"
                        action='fsos'
                        placeholder="Select food safety officer"
                        value={values.assigned_fso}
                        // valueId={true}
                        onChange={(text) =>
                          handleAssignFso("assigned_fso", text)
                        }
                      />
                    </div>
                    {/* <div className="form-group">
                      <TextAreaInput
                        placeholder={"Remarks"}
                        name="reason"
                        value={values.reason}
                        onChange={(e) =>
                          handleAssignFso("reason", e.target.value)
                        }
                      />
                    </div> */}
                  </>
                </div>
                </ModalBody>
                <ModalFooter>
            <div className="form-group">
              <button
                // disabled={disableSubmit}
                onClick={submitFso}
                type="button"
                className="btn btn-success ml-3"
              >
                Submit
              </button>
            </div>
          </ModalFooter>
                </Modal>):null}

      <div className="mb-4">
        <div className="py-3">
          {/* <h1 className="h4 mb-4 text-gray-800">{t("Sample")}</h1> */}
          {user && user.user_types.includes("fso") ? (
            <>
              <Link
                className="btn btn-primary-green pull-right"
                to="/app/reports/fso_sample_reports/"
              >
                Add New Sample
              </Link>
            </>
          ) : null}
          
        </div>
        <div
          className="mt-5"
          style={{ fontSize: i18n.language === "ta" ? 15 : null }}
        >
          <Nav tabs>
            {user && user.user_types.includes("fso") ? (
              <>
               <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                   All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Submitted Samples
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Sample(s) to be sent
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
                
              </>
            ) : null}
            {user && user.user_types.includes("do") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Pending Samples
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Waiting for DO(Damaged Sample)
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Waiting for result confirmation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem>
              </>
            ) : null}
            {user && user.user_types.includes("cfa") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Sample(s) to be analysed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Sample(s) waiting for analysis
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                   Sample(s) waiting for result
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Second part sample requested
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                   Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
              {user && user.user_types && user.user_types.includes('cfa') ? <>
                  
                  {fso_submit_data && fso_submit_data ? 
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={fso_submit_data}
                          count={fso_submit_data.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          pageFalse
                          handlePageChange={(page) => setPageList(page)}
                        />
                      </div>
                     : null}
                </> :null}
                {(user && user.user_types && user.user_types.includes('do')||user && user.user_types && user.user_types.includes('fso')) ? <>
                  {overall_Sample && overall_Sample ? 
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={overall_Sample}
                          count={overall_Sample.length}
                          countPerPage={countPerPage}
                          pageFalse
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageList(page)}
                        />
                      </div>
                     : null}
                </> :null}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                    {user &&
                    user.user_types &&
                    user.user_types.includes("cfa") ? (
                      <>
                        {lab_analyse_sample && lab_analyse_sample ? (
                          <div className="card-body">
                            <Table
                              columns={columns}
                              data={lab_analyse_sample}
                              count={lab_analyse_sample.length}
                              countPerPage={countPerPage}
                              customStyles={customStyles}
                              pageFalse
                              handlePageChange={(page) => setPageAnalyse(page)}
                            />
                          </div>
                        ) :null}
                      </>
                    ) : null}
                    {user &&
                      user.user_types &&
                      user.user_types.includes("fso") ? (
                        <>
                          {fso_submit_data && fso_submit_data ? (
                            <div className="card-body">
                              <Table
                                columns={columns}
                                data={fso_submit_data}
                                count={fso_submit_data.length}
                                countPerPage={countPerPage}
                                customStyles={customStyles}
                                pageFalse
                                handlePageChange={(page) => setPageAnalyse(page)}
                              />
                            </div>
                          ) :null}</>):null}
                          {user &&
                      user.user_types &&
                      user.user_types.includes("do") ? (
                        <>
                          {fso_submit_data && fso_submit_data ? (
                            <div className="card-body">
                              <Table
                                columns={columns}
                                data={fso_submit_data}
                                count={fso_submit_data.length}
                                countPerPage={countPerPage}
                                customStyles={customStyles}
                                pageFalse
                                handlePageChange={(page) => setPageAnalyse(page)}
                              />
                            </div>
                          ) :null}</>):null}
              </div>
            </TabPane>

              <TabPane tabId="3">
                <div className="card shadow mb-4">
                  <LoadingOverlay
                    active={loading}
                    className="secondary__loader"
                    spinner
                    text="Loading..."
                  />
                   {user && user.user_types.includes("do") &&(
                    <>
                    {req_sample && req_sample ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={req_sample}
                          count={req_sample.count}
                          countPerPage={countPerPage}
                          pageFalse
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageAssign(page)}
                        />
                      </div>):null}
                   </> )}
                  {user && user.user_types.includes("fso") && (
                    <>
                      {sample_data && sample_data ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={sample_data}
                            count={sample_data.length}
                            countPerPage={countPerPage}
                            pageFalse
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageAssign(page)}
                          />
                        </div>
                      ) : null}</>)}
                      
                      {user && user.user_types.includes("cfa")&&<>
                      {sample_data && sample_data ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={sample_data}
                            count={sample_data.length}
                            countPerPage={countPerPage}
                            pageFalse
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageAssign(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                </div>
              </TabPane>


           
            <TabPane tabId="4">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                   {user && user.user_types && user.user_types.includes("do") ? (
                    <>
                     {resultObtained && resultObtained ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={resultObtained}
                          pageFalse
                          count={resultObtained.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageWaitingResult(page)}
                        />
                      </div>
                    ) : null}
                    </>
                   ):null}
                    
                    {user && user.user_types.includes("cfa")&&<>
                      {fso_submit_data && fso_submit_data ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={fso_submit_data}
                            pageFalse
                            count={fso_submit_data.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageWaitingResult(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                {/* {user && user.user_types && user.user_types.includes("cfa") ? (
                  <>
                    {fso_submit_data && fso_submit_data ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={fso_submit_data}
                          count={fso_submit_data.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageWaitingResult(page)}
                        />
                      </div>
                    ) : null}
                   </>):null} */}
                    {user && user.user_types.includes("fso")&&<>
                      {diffSample && diffSample ? (
                        <div className="card-body">
                          <Table
                            columns={column}
                            data={diffSample}
                            pageFalse
                            count={diffSample.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageCivilWait(page)}
                          />
                        </div>
                      ) : null}
                      </>}
              </div>
            </TabPane>
            <TabPane tabId="5">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />

                    {user && user.user_types.includes("cfa")&&<>
                      {diffSample && diffSample ? (
                        <div className="card-body">
                          <Table
                            columns={column}
                            data={diffSample}
                            pageFalse
                            count={diffSample.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageCivilWait(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                      {user && user.user_types.includes("do")&&<>
                      {diffSample && diffSample ? (
                        <div className="card-body">
                          <Table
                            columns={column}
                            data={diffSample}
                            pageFalse
                            count={diffSample.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageCivilWait(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                     
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ongetSample: (data) => dispatch(getSampleStatus(data)),
  onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
  ongetSampe: (data) => dispatch(updateSample(data)),
  ongetSampelist: (data) => dispatch(updateSampleList(data)),
  ongetCivil: (data) => dispatch(updateCivil(data)),
  ongetCriminal: (data) => dispatch(updateCriminal(data)),
  ongetDLabs: (data) => dispatch(getLabDifferentReports(data)),
  onaddFbo: (data,id) => dispatch(addFboDetails(data,id)),
  ongetFso: (data) => dispatch(getFsoOfficers(data)),
  onEditFSOSampleReports: (data, record_id) => dispatch(editFSOSampleReports(data, record_id)),


});

const mapStateToProps = (state) => ({
  user: state.home.user,
  districts: state.home.districts,
  complaints: state.home.complaints,
  assignComplaints: state.home.assignComplaints,
  closedComplaints: state.home.closedComplaints,
  unasignComplaints: state.home.unasignComplaints,
  waitingComplaints: state.home.waitingComplaints,
  foodIssues: state.home.foodIssues,
  fsoCategory: state.home.fsoCategory,
  assignfsoComplaints: state.home.assignfsoComplaints,
  sample_data: state.home.sample_data,
  list_sample: state.home.list_sample,
  fso_submit_data: state.home.fso_submit_data,
  req_sample: state.home.req_sample,
  lab_analyse_sample: state.home.lab_analyse_sample,
  resultObtained: state.home.resultObtained,
  civilResults: state.home.civilResults,
  criminalResults: state.home.criminalResults,
  overall_Sample:state.home.overall_Sample,
  fsoOfficers: state.home.fsoOfficers,
});

export default connect(mapStateToProps, mapDispatchToProps)(FSOEntry);
