import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const AdjudicationCivilDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    const [name, setName] = useState([])
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            let url = props.history.location.state.records.upload_document_every_orders_of_adjudication_officer;
            setName(url);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
      return wardName;
  };
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Adjudication Case Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                    <th>Area Code</th>
                    <td className="text-left">{getAreaCode(records.area_code)}</td>
                </tr>
                <tr>
                    <th>Area Name</th>
                    <td className="text-left">{records.area_name}</td>
                </tr>
              <tr>
                <th>Date of Sample Lifted</th>
                <td className="text-left">{moment(records.date_of_sample_lifted).format("l")}</td>
              </tr>
                <tr>
                  <th>Sample Number</th>
                 <td className="text-left">{records.case_no}</td>
                </tr>
                <tr>
                  <th>Sample Report </th>
                   <td className="text-left">{records.sample_report}</td>
                </tr>
               {records.other_sample_report &&  <tr>
                  <th>Sample Report -Other</th>
                   <td className="text-left">{records.other_sample_report}</td>
                </tr>}
              <tr>
                <th>Case No</th>
                <td className="text-left">{records.case_no}</td>
              </tr>
              <tr>
                <th>Name Of the Petitioner/FSO/DO</th>
                 <td className="text-left">{records.name_of_petitioner_fso_do}</td>
                </tr>             
              <tr>
                <th>Address Of the Petitioner/FSO/DO</th>
                <td className="text-left">{records.address_of_petitioner_fso_do}</td>
              </tr>
              <tr>
                <th>Type of the Respondent</th>
                <td className="text-left">{records.type_of_respondent}</td>
              </tr>
             {records.type_of_respondent &&  <tr>
                <th>Type of the Respondent-Other</th>
                <td className="text-left">{records.type_of_respondent}</td>
              </tr>}
              <tr>
                <th>Name Of the Respondent/FBO</th>
                <td className="text-left">{records.name_of_respondent_fbo}</td>
              </tr>
              <tr>
                <th>Address Of the Respondent/FBO </th>
                <td className="text-left">{records.address_of_respondent_fbo}</td>
              </tr>
              <tr>
                <th>Nature Of Offence</th>
                <td className="text-left">{records.nature_of_offence}</td>
              </tr>
              {records.other_nature_of_offence && <tr>
                <th>Nature Of Offence</th>
                <td className="text-left">{records.other_nature_of_offence}</td>
              </tr>}
              <tr>
                <th>Case Filed Under Section</th>
                <td className="text-left">{records.section}</td>
              </tr>
              <tr>
                <th>Date Of Presentation</th>
                <td className="text-left">{moment(records.date_of_launched).format("l")}</td>
              </tr> 
              <tr>
                <th>Date Of Case Filing</th>
                <td className="text-left">{moment(records.date_of_case_filing).format("l")}</td>
              </tr>   
              <tr>
                <th>Date Of 1st Hearing</th>
                <td className="text-left">{moment(records.date_of_1st_hearing).format("l")}</td>
              </tr> 
              <tr>
                <th>Date Of next Hearing </th>
                <td className="text-left">{moment(records.date_of_next_hearing).format("l")}</td>
              </tr> 
              <tr>
                <th>Case History</th>
                <td className="text-left">{records.case_history}</td>
              </tr> 
              <tr>
                <th>Judgement status</th>
                <td className="text-left">{records.judgement_status}</td>
              </tr> 
              {records.penalty_paid && <tr>
                <th>Penalty paid </th>
                <td className="text-left">{records.penalty_paid}</td>
              </tr> }
              {records.penalty_amount && <tr>
                <th>Penalty Amount </th>
                <td className="text-left">{records.penalty_amount}</td>
              </tr> }
              {records.upload_receipt && <tr>
                <th>Upload Receipt</th>
                <td className="text-left">{records.upload_receipt}</td>
              </tr> }
              {records.petition_filed && <tr>
                <th>Whether Petition Filed Under Sec 96 </th>
                <td className="text-left">{records.petition_filed}</td>
              </tr> }
              {records.upload_petiton && <tr>
                <th>Upload Petition Document </th>
                <td className="text-left">{records.upload_petiton}</td>
              </tr> }
              {records.where_petition_filed && <tr>
                <th>Petition Filed At </th>
                <td className="text-left">{records.where_petition_filed}</td>
              </tr> }
                <tr>
                <th>Upload Document (For Every Order of Adjudication Officer) </th>
                <td className="text-left">{records.upload_document_every_orders_of_adjudication_officer}</td>
              </tr> 
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(AdjudicationCivilDetails);