import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const PanmasalaDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
   
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Panmasala and Gutkha Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                
                <tr>
                  <th>Type Of Trader</th>
                   <td className="text-left">{records.type_of_trader}</td>
                </tr>
              {records.other_type_of_trader &&  <tr>
                  <th>Type Of Trader(Other)</th>
                   <td className="text-left">{records.other_type_of_trader}</td>
                </tr>}
              <tr>
                <th>Name Of The FBO Premises Where Seized</th>
                <td className="text-left">{records.name_of_the_shop}</td>
              </tr>              
              <tr>
                <th>Address Of The Premises Where Seized</th>
                <td className="text-left">{records.address_of_the_shop}</td>
              </tr>
              <tr>
                <th>License/Registration Number</th>
                <td className="text-left">{records.license_registration_number}</td>
              </tr>
              <tr>
                <th>Witness Name</th>
                <td className="text-left">{records.witness_name}</td>
              </tr>
               <tr>
                <th>Witness Address</th>
                <td className="text-left">{records.witness_address}</td>
              </tr>
              <tr>
                <th>Quantity seized in Kgs</th>
                <td className="text-left">{records.quantity_seized}</td>
              </tr> 
              <tr>
                <th>Whether Sample Lifted</th>
                <td className="text-left">{records.sample_lifted}</td>
              </tr>
              <tr>
                <th>Seized Under Custody Of</th>
                <td className="text-left">{records.seized_under_custody}</td>
              </tr>
              <tr>
                <th>Name of the Official</th>
                <td className="text-left">{records.name_of_official}</td>
              </tr>
              <tr>
                <th>Address Of Official</th>
                <td className="text-left">{records.address_of_official}</td>
              </tr>
              <tr>
                <th>Approximate value in Rs</th>
                <td className="text-left">{records.approximate_value}</td>
              </tr>
              <tr>
                <th>Action Taken</th>
                <td className="text-left">{records.action_taken}</td>
              </tr>
              <tr>
                <th>Number of inspection during the day</th>
                <td className="text-left">{records.inspected_during_the_day}</td>
              </tr>
              <tr>
                <th>Number of Banned items found</th>
                <td className="text-left">{records.sale_banned_items}</td>
              </tr>
              <tr>
                <th>Offence Identified</th>
                <td className="text-left">{records.offense_identified}</td>
              </tr>
              <tr>
                <th>Fine Imposed</th>
                <td className="text-left">{records.fine_imposed}</td>
              </tr>
              <tr>
                <th>Upload your Documents</th>
                <td className="text-left">{records.documents}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(PanmasalaDetails);