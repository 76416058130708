import React from "react";
import { connect } from "react-redux";

const validateUser = (props) => {
    const { user } = props;

    return (
        <>
        </>
    // 'do': true,
    // 'ho': true,
    // 'fso': false,
    // 'dd': false,
    // 'director': false,
    // 'commissioner' : false,
    )
}

const mapDispatchToProps = (dispatch) => ({
  });
  
  const mapStateToProps = (state) => ({
    user: state.home.user,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(validateUser);