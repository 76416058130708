import React from "react";
import Header from "../components/Header";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div id="main">
        <section>
          <div className="contact__list__main">
            <h2>Privacy Policy</h2>
            <div className="contact__form">
              <div className="policy__content">
                <p>
                  Below mentioned are the policies proposed to be published on
                  the website as per Guidelines for TamilNadu Government
                  website.
                </p>
                <h6 className="font-weight-bold">A) COPYRIGHT POLICY</h6>
                <p>
                  Material featured on this site may be reproduced free of
                  charge in any format or media without requiring specific
                  permission. This is subject to the material being reproduced
                  accurately and not being used in a derogatory manner or in a
                  misleading context. Where the material is being published or
                  issued to others, the source must be prominently acknowledged.
                  However, the permission to reproduce this material does not
                  extend to any material on this site, which is explicitly
                  identified as being the copyright of a third party.
                  Authorisation to reproduce such material must be obtained from
                  the copyright holders concerned.
                </p>
                <h6 className="font-weight-bold">B) HYPERLINKING POLICY</h6>
                <p>
                  Links to external websites/portals <br />
                  <br /> At many places in this website, you shall find links to
                  other websites/portals. The links have been placed for your
                  convenience. is not responsible for the contents and
                  reliability of the linked websites and does not necessarily
                  endorse the views expressed in them. Mere presence of the link
                  or its listing on this portal should not be assumed as
                  endorsement of any kind. We cannot guarantee that these links
                  will work all the time and we have no control over
                  availability of linked pages.
                  <br />
                  <br />
                  Links to TN Food safety Department Website by other websites{" "}
                  <br />
                  <br />
                  We do not object to you linking directly to the information
                  that is hosted on our site and no prior permission is required
                  for the same. However, we would like you to inform us about
                  any links provided to this portal so that you can be informed
                  of any changes or updations therein. Also, we do not permit
                  our pages to be loaded into frames on your site. The pages of
                  this portal must load into a newly opened browser window of
                  the user.
                </p>
                <h6 className="font-weight-bold">
                  C) TERMS AND CONDITIONS STATEMENT
                </h6>
                <p>
                  This website is designed, developed by CareMe Health and
                  Strydo Technologies and maintained by TN Food safety
                  Department, Government of Tamilnadu. The information posted on
                  this website includes hypertext links to external websites. TN
                  Food safety Department is providing these links solely for the
                  purpose of information. When a link to an external website is
                  selected, TN Food safety Department does not guarantee the
                  availability or correctness of such linked pages at all times.
                  <br />
                  <br />
                  Under no circumstances will TN Food safety Department be
                  liable for any expense, loss or damage arising from use or
                  lack of use of data arising in connection with the use of this
                  website. These terms of use shall be governed by and construed
                  in accordance with the Indian Laws. Any dispute arising under
                  these terms of use shall be subject to the jurisdiction of the
                  courts of India.
                </p>
                <h6 className="font-weight-bold">D) PRIVACY POLICY</h6>
                <p>
                  TN Food safety Department Website does not automatically
                  capture any specific personal information from you, (like
                  name, phone number or e-mail address), that allows us to
                  identify you individually.
                  <br />
                  <br />
                  If the TN Food safety Department Website requests you to
                  provide personal information, you will be informed for the
                  particular purposes for which the information is gathered and
                  adequate security measures will be taken to protect your
                  personal information.
                  <br />
                  <br />
                  We do not sell or share any personally identifiable
                  information volunteered on the TN Food safety Department
                  Website to any third party (public/private). Any information
                  provided to this website will be protected from loss, misuse,
                  unauthorized access or disclosure, alteration,or
                  destruction.We gather certain information about the User, such
                  as Internet protocol (IP) addresses, domain name, browser
                  type, operating system, the date and time of the visit and the
                  pages visited. We make no attempt to link these addresses with
                  the identity of individuals visiting our site unless an
                  attempt to damage the site has been detected.
                </p>
                <h6 className="font-weight-bold">
                  F) Content Review policy (CRP)
                </h6>
                <p>
                  Every piece of content appearing on TN Food safety Department
                  website is reviewed for its accuracy in timely manner. Some of
                  the short lived content components like tenders, recruitment,
                  upcoming trainings etc does not hold any relevance on the
                  website after the event or intended purpose. The content in
                  WHAT’S NEW, IN THE MEDIA are regularly reviewed as per our
                  Content Review Policy.
                </p>
                <h6 className="font-weight-bold">G) MONITORING POLICY</h6>
                <p>
                  TN Food safety Department website is monitored in accordance
                  with the laid guidelines to address and fix the quality and
                  compatibility issues around the following parameters:
                  <br />
                  <br />
                  Performance- It is ensured that the site is up and working on
                  24*7 basis.
                  <br />
                  <br />
                  Broken Links- The website is thoroughly reviewed to rule out
                  the presence of any broken links or errors. The broken links
                  on the website are detected and remedial measures are taken
                  immediately.
                  <br />
                  <br />
                  Traffic Analysis- The site traffic is regularly monitored on
                  daily basis.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
