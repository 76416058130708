import React, { useEffect } from "react";
import Header from "../components/Header";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDoContacts } from "../components/reducers/actions";

const ContactDetails = (props) => {
  const { t } = useTranslation();
  const { doContacts } = props;

  if (doContacts) {
    doContacts.sort(function (a, b) {
      if (a.district.name < b.district.name) {
        return -1;
      }
      if (a.district.name > b.district.name) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    const getContacts = () => {
      props.onGetDoContacts({
        do_contacts: true,
      });
    };
    getContacts();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header activeMenu="Contact Details" />
      <div id="main">
        <section>
          <div className="contact__list__main">
            <h2>{t("Contact Details")}</h2>
            <div className="contact__form"></div>
            <div className="contact__info ">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr className="border-bottom">
                      <th className="font-size-11 font-light">District</th>
                      <th className="font-size-11 font-light">Name</th>
                      <th className="font-size-11 font-light">Phone No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {doContacts
                      ? doContacts.map((item, idx) => (
                          <tr key={idx} className="border-bottom">
                            <td>
                              <p>{item.district.name}</p>
                            </td>
                            <td>
                              <p>{item.name}</p>
                            </td>
                            <td>
                              <p>{item.phone_number}</p>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetDoContacts: (data) => dispatch(getDoContacts(data)),
});

const mapStateToProps = (state) => ({
  doContacts: state.home.doContacts,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
