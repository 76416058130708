import React from "react";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getGallery } from "../../components/reducers/actions";
import './gallery.css';
import ReactPlayer from 'react-player'
import { useTranslation } from "react-i18next";

const Gallery = (props) => {
  const{ongetGallery}=props;
const [video,setVideo] = useState();
const [image, setImage] = useState()
const [news, setNews] = useState()
const [step, setStep] = useState(0)
const { t, i18n } = useTranslation();


  const getValues =async()=>{
  const resp =  await ongetGallery()
    if(resp.response){
      console.log(resp)
     setVideo(Object.values(resp.response)[1])
      setImage(Object.values(resp.response)[0])
      setNews(Object.values(resp.response)[2])
    }
    else{
      console.log('error')
    }

  }
useEffect(()=>{
  getValues()
},[])

const VideoView=()=>{
return(
  <>
   <div onClick={()=>setStep(0)} className="back_btn">
       <i style={{fontSize:'20px', marginRight:'8px'}} class="fas fa-arrow-left"></i>
       <p style={{marginTop:'-2px'}}>{t("Gallery")}</p>
     </div>
   <section id="featured-services" className="blogs">
    <div>
      <header className="section-header">
      </header>
      <div className="row">
           { video && video.map((i, indx)=>{
              return(
                <div key={indx} className="col-lg-3 mb-5">
                <div className="box">
                <ReactPlayer
                url={i.url_link}
                controls
                playbackRate = {2}
                width='100%'
                height='100%'
            />
             {/* <iframe width="100%" height="100%" src={i.url_link} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> */}
                  <div className="video_grid">
                    <h4 className="title">{i.description}</h4>
                    {/* <p className="description">description</p> */}
                  </div>
                </div>
            </div>
              )
          })}
      
      </div>
    </div>
  </section>
  </>
)
}
const PhotoView =()=>{
  return(
    <>
     <div onClick={()=>setStep(0)} className="back_btn">
       <i style={{fontSize:'20px', marginRight:'8px'}} class="fas fa-arrow-left"></i>
       <p style={{marginTop:'-2px'}}>{t("Gallery")}</p>
     </div>
    <section id="featured-services" className="blogs">
    <div>
      <header className="section-header">
      </header>
      <div className="row">
      {image && image.map((i, indx)=>{
              return(
                <div key={indx} className="col-lg-3 mb-5">
                <div className="box">
                    <img src={i.image} alt=""  height="100px"  />
                  <div className="video_grid">
                    <h4 className="title">{i.description}</h4>
                    {/* <p className="description">description</p> */}
                  </div>
                </div>
            </div>
              )
          })}
      </div>
    </div>
  </section>
    </>
  )
}
 const Newsview =()=>{
   return(
     <>
     <div onClick={()=>setStep(0)} className="back_btn">
       <i style={{fontSize:'20px', marginRight:'8px'}} class="fas fa-arrow-left"></i>
       <p style={{marginTop:'-2px'}}>{t("Gallery")}</p>
     </div>
     <section id="featured-services" className="blogs">
    <div>
      <header className="section-header">
      </header>
      <div className="row">
      {news && news.map((i, indx)=>{
              return(
                <div key={indx} className="col-lg-3 mb-5">
                <div className="box">
                    <img src={i.news} alt=""  height="100px"  />
                  <div className="video_grid">
                    <h4 className="title">{i.description}</h4>
                    {/* <p className="description">description</p> */}
                  </div>
                </div>
            </div>
              )
          })}
      </div>
    </div>
  </section>
     </>
   )
 }
  return (
      <div style={{marginBottom:'150px'}}>
    <Header />
{step == 0 && (
  <div style={{marginTop:'50px', marginBottom:'60px'}}>
<section class="card-container">

	<div onClick={()=>{setStep(1)}} class="card">
		<i style={{fontSize: '45px'}} class="fas fa-video"></i>
		<h1>{t("Videos")}</h1>
		<p>{t("Catch our latest videos")}.</p>
	</div>

	<div onClick={()=>{setStep(2)}} class="card">
		<i style={{fontSize: '45px'}} class="fas fa-photo-video"></i>
		<h1>{t("Photos")}</h1>
		<p>{t("Our department image gallery")}</p>
	</div>

	<div onClick={()=>{setStep(3)}} class="card">
		<i style={{fontSize: '45px'}} class="fas fa-book"></i>
		<h1>{t("Newspaper")}</h1>
		<p>{t("Latest news and updates")}</p>
	</div>
</section>

</div>
)}
{step ===1 && <VideoView />}
{step ===2 && <PhotoView />}
{step ===3 && <Newsview />}
  </div>
 
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetGallery: (data) => dispatch(getGallery(data)),
});

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
