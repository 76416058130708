import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import * as d3 from 'd3';
import { nest } from "d3-collection";
import tip from "d3-tip";
const DistrictBarChart = ({ data }) => {
    console.log(data)
    const districtBar = useRef();
    const renderStackedBar = () => {
        const margin = {top: 20, right: 30, bottom: 50, left: 50},
        width = 1000 - margin.left - margin.right,
        height = 350 - margin.top - margin.bottom;
    // append the svg object to the body of the page
        const svg = d3
            .select(districtBar.current)
            .attr(
                "viewBox",
                `0 0 ${width + margin.left + margin.right} ${
                height + margin.top + margin.bottom
                }`
            )
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);
    // Parse the Data
        if(data) {

    // List of subgroups = header of the csv files = soil condition here
        const subgroups = ['complaints_awaiting_approval', 
        'complaints_awaiting_inspection', 
        'pending_allocation']

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    
        data = nest()
        .key(function (d) {
          return d.district;
        })
        .rollup(function (v) {
          return {
            total:d3.sum(v, function (d) {

                return d.complaints_awaiting_approval + d.complaints_awaiting_inspection + d.pending_allocation;
              }),
              complaints_awaiting_approval: v[0].complaints_awaiting_approval,
              complaints_awaiting_inspection: v[0].complaints_awaiting_inspection,
              pending_allocation: v[0].pending_allocation
          };
        })
        .entries(data)
        .sort(function (a, b) {
            return d3.descending(a.total, b.total);
        })
        .map(function (group) {
            return {
              key: group.key,
              total: group.value.total,
              complaints_awaiting_approval: group.value.complaints_awaiting_approval,
              complaints_awaiting_inspection: group.value.complaints_awaiting_inspection,
              pending_allocation: group.value.pending_allocation
            };
         })
        console.log(data)
        const groups = data.map(d => d.key)
        const tooltip = tip()
        .attr("class", "d3-tip")
        .html(function (e, d) {
          return `<div>
          <strong>Complaints awaiting approval:</strong> <span>${d.data.complaints_awaiting_approval}</span> <br/>
          <strong>Complaints awaiting inspection:</strong> <span>${d.data.complaints_awaiting_inspection}</span><br/>
          <strong>Inspection to allocate complaints:</strong> <span>${d.data.pending_allocation}</span>
          </div>`;
        });

    // Add X axis
        const x = d3.scaleBand()
            .domain(groups)
            .range([0, width])
            .padding([0.5])
        svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(x).tickSizeOuter(0))
            .selectAll("text")  
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-25)" );

    // Add Y axis
        const y = d3.scaleLinear()
            .domain([0, d3.max(data, (d) => d.total)])
            .range([ height, 0 ]);
        svg.append("g")
            .call(d3.axisLeft(y));        
        svg.call(tooltip);
            svg
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - height / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("font-size", "small")
            .text("Complaint Counts");

    // color palette = one color per subgroup
        const color = d3.scaleOrdinal()
            .domain(subgroups)
			//.range(d3.schemeDark2)
	        .range(d3.quantize(t => d3.interpolateRainbow(t * 0.8 + 0.1), data.length).reverse())

    //stack the data? --> stack per subgroup
        const stackedData = d3.stack()
            .keys(subgroups)
            (data)

    // Show the bars
        svg.append("g")
            .selectAll("g")
            // Enter in the stack data = loop key per key = group per group
            .data(stackedData)
            .join("g")
            .attr("fill", d => color(d.key))
            .selectAll("rect")
            // enter a second time = loop subgroup per subgroup to add all rectangles
            .data(d => d)
            .join("rect")
            .attr("x", d => x(d.data.key))
            .attr("y", d => y(d[1]))
            .attr("height", d => y(d[0]) - y(d[1]))
            .attr("width",x.bandwidth())
            .on("mouseover", tooltip.show)
            .on("mouseout", tooltip.hide);
        }
    }
    useEffect(() => {
        renderStackedBar();
    }, [data])

    return (
        <div id='districtBarChart'>
            <div className="card">
                <div className="card-body">
				    <h4 className="heading">Complaint status by District</h4>
                    {data && data.length !==0 ?<div id="district_bar">
                        <svg ref={districtBar}></svg>
                    </div>:<>
                    <div style={{marginTop:90, marginBottom:90}}>No Data Available</div>
                    </>}
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
});
  
const mapStateToProps = (state) => ({
    dashboard: state.home.dashboard,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(DistrictBarChart);