import React from "react";
import {
  getFilterData,
  getFsoOfficers,
  getoverall,
  getoverallWithout,
  userDirectory,
  getMasterSuccess,
  lineReportOpen,
  lineReportClosed,
  getSummaryIssue,
  getSummaryClosedall,
  getSummaryfinetotal,

} from "../../components/reducers/actions";
import toast from 'react-toast-notification';
import { connect } from "react-redux";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import Header from "../../components/Header";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { date } from "yup";
import Datepicker from "../../components/inputs/Datepicker";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextInput from "../../components/inputs/TextInput";
import moment from "moment";

const SummaryFinetotal = (props) => {
  const { t, i18n } = useTranslation();
  const {
    ongetOverall,
    ongetOverallWithout,
    traderTypes,
    ongetFso,
    foodIssues,
    districts,
    foodTypes,
    fsoOfficers,
    ongetLineClosed,
    ongetSummary,
    user,
  } = props;
  const [users, setUsers] = useState();
  const [errors, setErrors] = useState({});
  const [sho,setSho]=useState(false)
  const [filt, setFilt] = useState('Custom month');
  const [duplicateDistrict, setDuplicateDistrict] = useState([]);
  const [Arr, setArr] = useState([]);
  const [values, setValues] = useState({
    fromdate: new Date(),
    todate: new Date(),
    district: null,
  });
  const Status = [
    {id:'0', name:'Waiting to Allocate'},
    {id:'1', name:`Waiting for do's approval`},
    {id:'2',name:'Inprogress'},
    {id:'3',name:'Closed'},
    ]
    const Inputs=[
      {id:'0',name:'Yearly'},
      {id:'1',name:'First Quarter'},
      {id:'2',name:'Second Quarter'},
      {id:'3',name:'Third Quarter'},
      {id:'4',name:'Fourth Quarter'},
    {id:'5',name:'Custom month'},
      {id:'6',name:'Custom date'},

    ]
  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
  };
  const handleSubmit = async (event) => {
    const data = { ...values };
    if(data.fromdate&&data.todate&&parseInt(moment(data.fromdate).format("Y"))>parseInt(moment(data.todate).format("Y"))){
          if(parseInt(moment(data.fromdate).format("Y"))>parseInt(moment(data.todate).format("Y"))){
            toast.error('Start month should be less than end month');
          }
    }
 else{
   const datas = {}
  datas.year = moment(data.year||new Date()).format("YYYY");
  if(filt=='Custom month'){
    datas.month_start = moment(data.fromdate).format("M");
    datas.month_end = moment(data.todate).format("M");
  }
  else if(filt=='Custom date'){
    datas.fromdate = moment(data.fromdate).format("YYYY-MM-DD");
    datas.todate = moment(data.todate).format("YYYY-MM-DD");
  }
  else if(filt=='Yearly'){
    datas.month_start = '1';
    datas.month_end = '12';
  }
  else if(filt=='First Quarter'){
    datas.month_start = '1';
    datas.month_end = '3';
  }
  else if(filt=='Second Quarter'){
    datas.month_start = '4';
    datas.month_end = '6';
  }
  else if(filt=='Third Quarter'){
    datas.month_start = '7';
    datas.month_end = '9';
  }
  else if(filt=='Fourth Quarter'){
    datas.month_start ='10';
    datas.month_end ='12';
  }
    datas.district=data.district?data.district=='all'?null:data.district:null;
    datas.action_taken="fine"
    console.log(datas);
        const resp = await ongetSummary(datas);
        console.log(resp)
        if (resp && resp.response) {
          console.log(resp.response.data);
          setUsers(resp.response.data)
          let arr = resp.response.data[0]
          const str = Object.entries(arr).map(([key, value]) => `${key}`);
         let dat= str.map((i)=>{
            if(i!=='tableData')
            return{
            'title':i=='issue'?`${(user && user.user_types && user.user_types.includes("do")||data.district&&data.district!=='all')?'FSO':'District'}`:i=='total_data'?'Total':`${i}`,
            'field':`${i}`,
            filtering:true,
                cellStyle: {
                textAlign: "center",
                }, 
          }}).filter(i=>i)
          console.log(dat)
          setArr(dat)
        }
    }
  };

  useEffect(()=>{
    var arr = [...districts]
    arr.unshift({id:'all',name:'All'})
    setDuplicateDistrict(arr)
  },[districts&&districts])

  
  useEffect(() => {
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
      const userData = { ...values, district: user.district };
      setValues(userData);
  }
  
  if(user && user.user_types && user.user_types.includes("fso")){
    const userData = { ...values, district: user.district, fso: user.id };
    setValues(userData);
  }
  
  }, []);

  const colDetails=Arr
  return (
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}>Fine Issued Summary-Total Details</h3>

        <div
          className="filter_container"
        > 
        <div>
        <label  htmlFor="date">Filter option: </label>
        <div className="filter_input" >
          <DropDownInput
          placeholder={`Filter input`}
          type="text"
          name="area_code"
          action="dropdown"
          value={filt}
          items={Inputs}
          onChange={(text) =>setFilt(text)}
          />
          </div>
        </div>
        <div>
         {filt && !filt.includes('Custom date') &&<> <label  htmlFor="date">Year: </label>
            <div className="filter_input col-md-12" >
              <Datepicker
                placeholder={`Year`}
                name="year"
                id="year"
                showYearPicker
                value={values.year}
                error={errors.year}
                onChangeText={(text) => handleTextChange("year", text)}
              />
            </div></>}</div>
          {filt && filt.includes('Custom date') ?<> <div >
            <label className="ml-md-n5" htmlFor="date">From date: </label>
            <div className="filter_input ml-md-n5" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={values.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextChange("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label className="ml-md-n5" htmlFor="date">To date: </label>
            <div className="filter_input ml-md-n5" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={values.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextChange("todate", text)}
              />
            </div>
          </div></>:null}
          {filt && filt.includes('Custom month') ?<> <div >
            <label className="ml-md-n5" htmlFor="date">From Month: </label>
            <div className="filter_input ml-md-n5" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                format
                value={values.fromdate}
                error={errors.fromdate}
                showMonthYearPicker
                onChangeText={(text) => handleTextChange("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label className="ml-md-n5" htmlFor="date">To Month: </label>
            <div className="filter_input ml-md-n5" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                format
                value={values.todate}
                error={errors.todate}
                showMonthYearPicker
                onChangeText={(text) => handleTextChange("todate", text)}
              />
            </div>
          </div></>:null}
          {user && user.user_types && user.user_types.includes("ho") ? (
          <div >
          <label  htmlFor="date">District: </label>
            <div className="filter_input " >
            <DropDownInput
              type="text"
              name="district"
              value={values.district}
              error={errors.district}
              items={duplicateDistrict}
              style={true}
              onChange={(value) => handleTextChange("district", value)}
            />
          </div>
          </div>
          ):null}
          
        </div>
        <div style={{float:'right',marginLeft:'auto',marginTop:'30px',marginRight:'30px'}} className="form-group">
            <button
            style={{marginLeft:'auto'}}
              onClick={handleSubmit}
              type="button"
              className="btn btn-success ml-3"
            >
              View
            </button>
          </div>
        {users? (
          <div
            style={{ width: "90%", fontSize: "16px",marginTop:'120px' }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={colDetails}
              data={users}
              options={{
                exportButton: {
                    csv: true,
                    pdf: false,
                    },
                search: true,
                filtering: true,
                pageSize: 40,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                  zIndex:0
                },
              }}
              title="Fine Issued total List"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({

  ongetSummary: (data) => dispatch(getSummaryfinetotal(data)),
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  traderTypes: state.home.traderTypes,
  foodIssues: state.home.foodIssues,
  foodTypes: state.home.foodTypes,
  fsoOfficers: state.home.fsoOfficers,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryFinetotal);
