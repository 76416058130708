import React, { useState, useRef } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
  Redirect,
  Link,
} from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import MasterDataTable from "../components/MasterDataTable";
import Dashboard from "./Dashboard";
import { createCSFHReports, getMasterData, getDistrictWards, UserDirectory } from "../components/reducers/actions";
import { useEffect } from "react";
import AddMasterData from "../components/AddMasterData";
import AddComplaint from "./AddComplaint";
import ComplaintList from "./ComplaintList";
import ComplaintDeptList from "../containers/Dept/ComplaintList";
import ComplaintDeptDetails from "../containers/Dept/ComplaintDetails";
import ComplaintDetails from "./ComplaintDetails";
import { useTranslation } from "react-i18next";
import Profile from "./Profile";
import { getLangCode } from "../utils/config";
import AddInspection from "./Dept/AddInspection/AddInspection";
import UserList from "./Users/UserList";
import AddUser from "./Users/AddUser";
import Reports from "../containers/Reports/Reports"
import DisplayReports from "../containers/Reports/DisplayReports"
import FboReport from "../containers/Reports/FboReport"
import LicencingReport from "../containers/Reports/LicencingReport"
import IECReport from "./Reports/IECReport";
import SlaughterReport from "./Reports/SlaughterReport";
import RipeningOfMangoes from "./Reports/RipeningOfMangoes";
import FssaactReport from './Reports/FssaactReport';
import SurveillanceReport from "./Reports/SurveillanceReport";
import RegistrationReport from "./Reports/RegistrationReport";
import PanmasalaReport from "./Reports/PanmasalaReport";
import PdwReport from "./Reports/PdwReport";
import TasmacReport from "./Reports/TasmacReport";
import RegMonthlyReports from "./Reports/RMReports";
import LicenseMonthlyReports from './Reports/LMReports'
import TNDailyReport from "./Reports/TNDailyReport";
import SchoolReport from "./Reports/SchoolReport";
import HotelReport from "./Reports/HotelReport";
import TourReport from "./Reports/TourReport";
import FoscorisDigitalReport from './Reports/FoscorisReport';
import SpecialProgramReport from "./Reports/SpecialProgramReport";
import HighCourtCaseReport from "./Reports/HighCourtCaseReport";
import AdjudicationReport from "./Reports/AdjudicationReport";
import SlaughterHouseReport from "./Reports/SlaughterHouseReport"
import DLACReport from "./Reports/DLACReport"
import FOSTACTrainingReport from "./Reports/FOSTACTrainingReport"
import FoodFortificationReport from "./Reports/FoodFortificationReport"
import ExpectedInspectionReport from "./Reports/ExpectedInspectionReport"
import FoodInNewspaperReport from "./Reports/FoodInNewspaperReport"
import EnumerationOfFBOReport from "./Reports/EnumerationOfFBOReport"
import SpecialDriveOfLandRReport from "./Reports/SpecialDriveOfLandRReport"
import AdjudicationList from "./ReportList/AdjudicationList"
import DLACList from "./ReportList/DLACList"
import EnumerationOfFboList from "./ReportList/EnumerationOfFboList"
import ExpectedInspectionList from "./ReportList/ExpectedInspectionList"
import FboList from "./ReportList/FboList"
import FoodFortificationList from "./ReportList/FoodFortificationList"
import FoodInNewspaperList from "./ReportList/FoodInNewspaperList"
import FoscorisDigitalList from "./ReportList/FoscorisDigitalList"
import FOSTACTrainingList from "./ReportList/FOSTACTrainingList"
import FssaactList from "./ReportList/FssaactList"
import HighCourtCaseList from "./ReportList/HighCourtCaseList"
import HotelList from "./ReportList/HotelList"
import IECList from "./ReportList/IECList"
import LicenseMonthlyList from "./ReportList/LicenseMonthlyList"
import LicensingList from "./ReportList/LicensingList"
import PanmasalaList from "./ReportList/PanmasalaList"
import PdwList from "./ReportList/PdwList"
import RegistrationList from "./ReportList/RegistrationList"
import RegMonthlyList from "./ReportList/RegMonthlyList"
import RipeningOfMangoesList from "./ReportList/RipeningOfMangoesList"
import SchoolList from "./ReportList/SchoolList"
import SlaughterHouseList from "./ReportList/SlaughterHouseList"
import SlaughterList from "./ReportList/SlaughterList"
import SpecialDriveList from "./ReportList/SpecialDriveList"
import SpecialProgramList from "./ReportList/SpecialProgramList"
import SurveillanceList from "./ReportList/SurveillanceList"
import TasmacList from "./ReportList/TasmacList"
import TNDailyList from "./ReportList/TNDailyList"
import TourJournalList from "./ReportList/TourJournalList"
import TourList from "./ReportList/TourList"
import AdjudicationDetails from "./ReportDetails/AdjudicationDetails";
import DLACDetails from "./ReportDetails/DLACDetails";
import EnumerationOfFboDetails from "./ReportDetails/EnumerationOfFboDetails";
import ExpectedInspectionDetails from "./ReportDetails/ExpectedInspectionDetails";
import FboDetails from "./ReportDetails/FboDetails";
import FoodFortificationDetails from "./ReportDetails/FoodFortificationDetails";
import FoodInNewspaperDetails from "./ReportDetails/FoodInNewspaperDetails";
import FoscorisDigitalDetails from "./ReportDetails/FoscorisDigitalDetails";
import FOSTACTrainingDetails from "./ReportDetails/FOSTACTrainingDetails";
import FssaactDetails from "./ReportDetails/FssaactDetails";
import HighCourtCaseDetails from "./ReportDetails/HighCourtCaseDetails";
import HotelDetails from "./ReportDetails/HotelDetails";
import IECDetails from "./ReportDetails/IECDetails";
import LicenseMonthlyDetails from "./ReportDetails/LicenseMonthlyDetails";
import LicensingDetails from "./ReportDetails/LicensingDetails";
import PanmasalaDetails from "./ReportDetails/PanmasalaDetails";
import PdwDetails from "./ReportDetails/PdwDetails";
import RegistrationDetails from "./ReportDetails/RegistrationDetails";
import RegMonthlyDetails from "./ReportDetails/RegMonthlyDetails";
import RipeningOfMangoesDetails from "./ReportDetails/RipeningOfMangoesDetails";
import SchoolDetails from "./ReportDetails/SchoolDetails";
import SlaughterDetails from "./ReportDetails/SlaughterDetails";
import SlaughterHouseDetails from "./ReportDetails/SlaughterHouseDetails";
import SpecialDriveDetails from "./ReportDetails/SpecialDriveDetails";
import SpecialProgramDetails from "./ReportDetails/SpecialProgramDetails";
import SurveillanceDetails from "./ReportDetails/SurveillanceDetails";
import TasmacDetails from "./ReportDetails/TasmacDetails";
import TNDailyDetails from "./ReportDetails/TNDailyDetails";
import TourDetails from "./ReportDetails/TourDetails";
import TourJournalDetails from "./ReportDetails/TourJournalDetails";
import TourJournal from "./Reports/TourJournal";
import InformartionEducationReport from "./Reports/InformartionEducationReport";
import ComplaintsReport from "./Reports/ComplaintsReport";
import SeizureReport from "./Reports/SeizureReport";
import NoticeIssudReport from "./Reports/NoticeIssudReport";
import EnumerationDataReport from "./Reports/EnumerationDataReport";
import VehicleInspectionReport from "./Reports/VehicleInspectionReport";
import HygineRatingReport from './Reports/HygineRatingCertificationReport';
import CSFHReport from './Reports/CleanStreetFoodHubReport';
import MarketCertificationReport from "./Reports/MarketCertificationReport";
import WorshipCertificationReport from './Reports/PlaceOfWorshipReport'
import EatRightTrainingReport from "./Reports/EatRightTrainingReport";
import ERSchoolReport from "./Reports/EatRightSchoolReport";
import ERCampusReport from './Reports/EatRightCampusReport';
import RUCOCertificationReport from './Reports/RUCOCertificationReport';
import RUCOBiodieselReport from "./Reports/RUCOBiodieselReport";
import NFWCertificationReport from "./Reports/NFWCertificationReport";
import ERCPortalReport from "./Reports/ERCPortalReport";
import NumberReport from "./Reports/NumberReport";
import NFWCDReport from './Reports/NFWCDReport';
import TargetReport from "./Reports/TargetReports";
import InformationList from "./ReportList/InformationList";
import SeizureList from "./ReportList/SeizureList";
import NoticeList from "./ReportList/NoticeList";
import EnumerationDataList from "./ReportList/EnumerationDataList";
import VehicleList from "./ReportList/VehicleInspectionList";
import HygineList from "./ReportList/HygineRatingList";
import FostacList from "./ReportList/FostacList";
import CleanFoodList from "./ReportList/CleanFoodList";
import MarketList from "./ReportList/MarketList";
import WorshipList from "./ReportList/WorshipList";
import ERCTraningList from "./ReportList/ERCTraningList";
import ERSchoolList from "./ReportList/ERSchoolList";
import ERCampusList from "./ReportList/ERCampusList";
import RUCOCertificationList from "./ReportList/RUCOCertificationList";
import RUCOCollectionList from "./ReportList/RUCOCollectionList";
import NFWCertificationList from "./ReportList/NFWCertificationList";
import NFWCDList from "./ReportList/NFWCDList";
import ERCUploadList from "./ReportList/ERCUploadList";
import ERCActivityList from "./ReportList/ERCActivityList";
import StaffReport from "./Reports/StaffPositionReport";
import UCOReport from "./Reports/UCOReport";
import FSOSampleReport from "./Reports/FSOSampleDetailsReport";
import FSOCaseReport from "./Reports/FSOCaseReport";
import FSODirectReport from "./Reports/FSODirectReport";
import FSOSampleDetailsReport from "./Reports/FSOSampleDetailsReport";
import FSODirectDetailsReport from "./Reports/FSODirectDetailsReport";
import DOSampleReport from "./Reports/DOSampleReport";
import DODamagedReport from "./Reports/DODamagedReport";
import DOAppealReport from "./Reports/DOAppealReport";
import DOCaseClarifyReport from "./Reports/DOCaseClarifyReport";
import DODirectCase from "./Reports/DODirectCase";
import DODirectDetails from "./Reports/DODirectDetails";
import SampleAnalysisReport from "./Reports/LabSampleReport";
import LabDifferentSource from "./Reports/LabDifferentSource";
import CommisionerCase from "./Reports/CommisionerCase";
import ComissionerEPOReport from "./Reports/ComissionerEPOReport";
import SampleReport from "./Reports/SampleReport";
import AdjudicationAppealReport from "./Reports/AdjudicationAppealReport";
import APPCivilCase from "./Reports/APPCivilCase";
import APPDirectCase from "./Reports/APPDirectCase";
import ComissionerateCriminal from "./Reports/ComissionerateCriminal";
import ComissionerateAppealReport from "./Reports/ComissionerateAppealReport";
import ComissionerateDirectReport from "./Reports/ComissionerateDirectReport";
import ComissionerateEPOReport from "./Reports/ComissionerateEPOReport";
import CompoundPenalty from "./Reports/CompoundPenalty";
import DOEPOReport from "./Reports/DOEPOReport";
import FSOEPOReport from "./Reports/FSOEPOReport";
import FSOEPOList from "./ReportList/FSOEPOList";
import DOEPOList from "./ReportList/DOEPOList";
import CommissionerEPOList from "./ReportList/CommissionerEPOList";
import CommissionerateEPOList from "./ReportList/CommissionerateEPOList";
import FSSActList from "./ReportList/FSSActList";
import AdjudicationAppealList from "./ReportList/AdjudicationAppealList";
import CompoundList from "./ReportList/CompoundList";
import UCOList from "./ReportList/UCOList";
import DisplaySampleReports from "./Reports/DisplaySampleReports";
import DOSampleList from "./ReportList/DOSampleList";
import DODamagedList from "./ReportList/DODamagedList";
import DODirectList from "./ReportList/DODirectList";
import DOCaseList from "./ReportList/DOCaseList";
import DOCaseFile from "./ReportList/DOCaseFile";
import DOAppealList from "./ReportList/DOAppealList";
import FSOSampleList from "./ReportList/FSOSampleList";
import FSOCaseList from "./ReportList/FSOCaseList";
import APPCivil from "./ReportList/APPCivil";
import APPDirect from "./ReportList/APPDirect";
import ComissionerateCriminalList from "./ReportList/ComissionerateCriminalList";
import ComissionerateAppeal from "./ReportList/ComissionerateAppeal";
import ComissionerateDirect from "./ReportList/ComissionerateDirect";
import FSODirectList from "./ReportList/FSODirectList";
import FSODirectCase from "./ReportList/FSODirectCase";
import CommissionerCase from "./ReportList/CommissionerCase";
import LABSample from "./ReportList/LABSample";
import LABDifferent from "./ReportList/LABDifferent";
import FSOEPODetails from "./ReportDetails/FSOEPODetails";
import DOEPODetails from "./ReportDetails/DOEPODetails";
import ComissionerEPODetails from "./ReportDetails/ComissionerEPODetails";
import ComissionerateEPODetails from "./ReportDetails/ComissionerateEPODetails";
import EnumerationDataDetail from "./ReportDetails/EnumerationDataDetail";
import ERToolkitDetails from "./ReportDetails/ERToolkitDetails";
import ERCampusDetails from "./ReportDetails/ERCampusDetails";
import UCODetails from "./ReportDetails/UCODetails";
import CompondDetails from "./ReportDetails/CompondDetails";
import InformationDetails from "./ReportDetails/InformationDetails";
import NoticeDetails from "./ReportDetails/NoticeDetails";
import AdjudicationCivilDetails from "./ReportDetails/AdjudicationCivilDetails";
import VehicleDetails from "./ReportDetails/VehicleDetails";
import HygineDetails from "./ReportDetails/HygineDetails";
import CSFHDetails from "./ReportDetails/CSFHDetails";
import MarketDetails from "./ReportDetails/MarketDetails";
import BHOGDetails from "./ReportDetails/BHOGDetails";
import ERSchoolDetails from "./ReportDetails/ERSchoolDetails";
import RUCOCertificationDetails from "./ReportDetails/RUCOCertificationDetails";
import RUCOBiodieselDetails from "./ReportDetails/RUCOBiodieselDetails";
import NFWDetails from "./ReportDetails/NFWDetails";
import ERCUploadDetails from "./ReportDetails/ERCUploadDetails";
import ERCActivityDetails from "./ReportDetails/ERCActivityDetails";
import DOSampleDetails from "./ReportDetails/DOSampleDetails";
import DODamagedDetails from "./ReportDetails/DODamagedDetails";
import DODirectSampleDetails from "./ReportDetails/DODirectSampleDetails";
import DDSDetails from "./ReportDetails/DDSDetails";
import DOClarifyDetails from "./ReportDetails/DOClarifyDetails";
import DOADetails from "./ReportDetails/DOADetails";
import FSOSDetails from "./ReportDetails/FSOSDetails";
import FSOCDetails from "./ReportDetails/FSOCDetails";
import FSODirectCaseDetails from "./ReportDetails/FSODirectCaseDetails";
import FSOCDDetails from "./ReportDetails/FSOCDDetails";
import CommCDetails from "./ReportDetails/CommCDetails";
import CoomrCriminalDetails from "./ReportDetails/CoomrCriminalDetails";
import CommAppealDetails from "./ReportDetails/CommAppealDetails";
import CommrDCaseDetails from "./ReportDetails/CommrDCaseDetails";
import APPCivilDetails from "./ReportDetails/APPCivilDetails";
import APPDirectDetails from "./ReportDetails/APPDirectDetails";
import LABSampleDetails from "./ReportDetails/LABSampleDetails";
import LABDifferentDetails from "./ReportDetails/LABDifferentDetails";
import SeizureDetails from "./ReportDetails/SeizureDetails";
import NFWCertification from "./ReportDetails/NFWCertification";
import ComplaintFilterList from "./ReportList/ComplaintFilterList";
import GeneralReports from "./Reports/GeneralReports";
import SeizureDetailsList from "./ReportList/SeizureDetailsList";
import ClosedComplaintDetails from "./ReportList/ClosedComplaintDetails";
import NoticeIssuedList from "./ReportList/NoticeIssuedList";
import StatusFilterList from "./ReportList/StatusFilterList";
import ActionTakenReportList from "./ReportList/ActionTakenReportList";
import SampleLiftedDetails from "./ReportList/SampleLiftedDetails";
import LineOpen from "./ReportList/LineOpen";
import LineClosed from "./ReportList/LineClosed";
import FsoEntry from "./Reports/FsoEntry";
import AddSample from "./Reports/AddSample";
import APP2Criminal from "./Reports/APP2Criminal";
import APPCriminal from "./Reports/APPCriminal";
import ACCriminal from "./Reports/ACCriminal";
import DOCriminal from "./Reports/DOCriminal";
import DDCriminal from "./Reports/DDCriminal";
import ComissionerCriminal from "./Reports/ComissionerCriminal";
import Cases from "./Reports/Cases";
import NonSamplingCase from "./Reports/NonSamplingCase";
import NSEntry from "./Reports/NSEntry";
import FSONonSamplingReport from "./Reports/FSONonSamplingReport";
import SamplReports from "./Reports/SamplReports";
import SamplFilter from "./Reports/SamplFilter";
import SamplAction from "./Reports/SamplAction";
import SamplNonFilter from "./Reports/SamplNonFilter";
import SampleNonReports from "./Reports/SampleNonReports";
import FinanceReports from "./Reports/FinanceReport";
import FinanceList from "./ReportList/FinanceList";
import FinanceCredit from "./Reports/FinanceCredit";
import FinanceDistribute from "./Reports/FinanceDistribute";
import FinanceSpend from "./Reports/FinanceSpend";
import FinanceReturn from "./Reports/FinanceReturn";
import FinanceCreditlist from "./ReportList/FinanceCreditlist";
import FinanceDistributelist from "./ReportList/FinanceDistributelist";
import FinanceSpendlist from "./ReportList/FinanceSpendlist";
import FinanceReturnlist from "./ReportList/FinanceReturnlist";
import FinanceCreditview from "./ReportDetails/FinanceCreditview";
import FinanceDistributeView from "./ReportDetails/FinanceDistributeView";
import FinanceSpendView from "./ReportDetails/FinanceSpendView";
import FinanceReturnView from "./ReportDetails/FinanceReturnView";
import Summaryissue from "./ReportList/Summaryissue";
import Summarytrader from "./ReportList/Summarytrader";
import Summarycomplaint from "./ReportList/Summarycomplaint";
import SummaryFbo from "./ReportList/SummaryFbo";
import SummaryLicense from "./ReportList/SummaryLicense";
import SummaryRegistration from "./ReportList/SummaryRegistration";
import SummaryFsoEpo from "./ReportList/SummaryFsoEpo";
import SummaryDoEpo from "./ReportList/SummaryDoEpo";
import Summryslaughter from "./ReportList/Summryslaughter";
import Summaryfruits from "./ReportList/Summaryfruits";
import Summarypdw from "./ReportList/Summarypdw";
import SummaryHotel from "./ReportList/SummaryHotel";
import SummarySchool from "./ReportList/SummarySchool";
import SummaryTourJournal from "./ReportList/SummaryTourJournal";
import Summarycompound from "./ReportList/Summarycompound";
import SummaryDaily from "./ReportList/SummaryDaily";
import SummaryENumeration from "./ReportList/SummaryENumeration";
import SummaryIec from "./ReportList/SummaryIec";
import SummaryNotice from "./ReportList/SummaryNotice";
import Summarytoolkit from "./ReportList/Summarytoolkit";
import SummaryPanmasala from "./ReportList/SummaryPanmasala";
import SummaryAdvancedtour from "./ReportList/SummaryAdvancedtour";
import SummaryTasmac from "./ReportList/SummaryTasmac";
import Summarydlac from "./ReportList/Summarydlac";
import Summaryfortification from "./ReportList/Summaryfortification";
import Summaryseizure from "./ReportList/Summaryseizure";
import SummaryVehicele from "./ReportList/SummaryVehicele";
import Summarydrive from "./ReportList/Summarydrive";
import SummaryHygine from "./ReportList/SummaryHygine";
import SummaryFostac from "./ReportList/SummaryFostac";
import SummaryFood from "./ReportList/SummaryFood";
import SummaryVegetable from "./ReportList/SummaryVegetable";
import SummaryHygineRating from "./ReportList/SummaryHygineRating";
import SummaryERSchool from "./ReportList/SummaryERSchool";
import SummaryERCampus from "./ReportList/SummaryERCampus";
import SummaryRUCO from "./ReportList/SummaryRUCO";
import SummaryBio from "./ReportList/SummaryBio";
import SummaryUco from "./ReportList/SummaryUco";
import SummarySave from "./ReportList/SummarySave";
import SummaryNFW from "./ReportList/SummaryNFW";
import SummaryERCPortal from "./ReportList/SummaryERCPortal";
import SummaryErcActivity from "./ReportList/SummaryErcActivity";
import SummaryFoscoris from "./ReportList/SummaryFoscoris";
import CaseFilter from "./Reports/CaseFilter";
import Samplelinefilter from "./Reports/Samplelinefilter";
import SampleNonlinefilter from "./Reports/SampleNonlinefilter";
import Casenonfilter from "./Reports/Casenonfilter";
import Summaryfund from "./ReportList/Summaryfund";
import SummaryNs from "./ReportList/SummaryNs";
import SummarySamp from "./ReportList/SummarySamp";
import SummaryCommis from "./ReportList/SummaryCommis";
import SummaryTourProgram from "./ReportList/SummaryTourProgram";
import SummaryAct from "./ReportList/SummaryAct";
import SummaryFine from "./ReportList/SummaryFine";
import SummaryNissued from "./ReportList/SummaryNissued";
import SummaryCenforcement from "./ReportList/SummaryCenforcement";
import SummaryFoodSeizure from "./ReportList/SummaryFoodSeizure";
import SummarySlifted from "./ReportList/SummarySlifted";
import SummaryScollection from "./ReportList/SummaryScollection";
import SummarySanalysed from "./ReportList/SummarySanalysed";
import SummaryProsecution from "./ReportList/SummaryProsecution";
import Allcivil from "./ReportList/Allcivil";
import Allcriminal from "./ReportList/Allcriminal";
import Stock from "./Dept/Stock";
import Subscribelist from "./ReportList/Subscribelist";
import Feedbackreportlist from "./ReportList/Feedbackreportlist";
import Contactlist from "./ReportList/Contactlist";
import DeleteComplaint from "./Dept/DeleteComplaint";
import SummaryFinetotal from "./ReportList/SummaryFinetotal";

const Layout = ({
  traderTypes,
  foodTypes,
  foodConditions,
  foodIssues,
  districts,
  onGetMasterData,
  user,
  onGetDist,
  wardsCategory
}) => {
  const [sidebarToggleTop, setSidebarToggleTop] = useState(false);
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [backToTop, setBackToTopd] = useState(false);
  const { i18n } = useTranslation();
  const navRef = useRef();
  navRef.current = backToTop;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0;
      if (navRef.current !== show) {
        setBackToTopd(show);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchMaterData = async () => {
      await onGetMasterData();
      // await onGetDist();
    };
    fetchMaterData();
  }, [onGetMasterData]);

  useEffect(() => {
    if (user && user.language) {
      i18n.changeLanguage(getLangCode(user.language));
    }
    // eslint-disable-next-line
  }, [user]);

  const handelBacktoTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Topbar
        setSidebarToggleTop={setSidebarToggleTop}
        setSidebarToggle={setSidebarToggle}
        sidebarToggle={sidebarToggle}
        sidebarToggleTop={sidebarToggleTop}
      />
      <div id="wrapper">
        <Router>
          <Sidebar
            setSidebarToggle={setSidebarToggle}
            sidebarToggle={sidebarToggle}
            sidebarToggleTop={sidebarToggleTop}
          />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Switch>
              <Route
                  exact
                  path="/app/reports/"
                  render={(props) =>
                    (user && user.user_types && user.user_types.includes("ho"))|| 
                    (user && user.user_types && user.user_types.includes("fso"))|| 
                    (user && user.user_types && user.user_types.includes("so"))|| 
                    (user && user.user_types && user.user_types.includes("do"))||
                    (user && user.user_types && user.user_types.includes("AO"))||
                    (user && user.user_types && user.user_types.includes("cfa"))||
                    (user && user.user_types && user.user_types.includes("dd"))||
                    (user && user.user_types && user.user_types.includes("oneapp"))||
                    (user && user.user_types && user.user_types.includes("twoapp"))||
                    (user && user.user_types && user.user_types.includes("labapp"))||
                    (user && user.user_types && user.user_types.includes("commissioner"))||
                    (user && user.user_types && user.user_types.includes("ac")) ? (
                      <Reports {...props} />
                     ) : (
                       <Redirect to="/app/complaints" />
                    )
                  }
              />
               <Route
                  exact
                  path="/app/dep-case-reports/"
                  render={(props) =>  <Cases {...props} /> }
              />
              <Route
                  exact
                  path="/app/report-summary-civilall/"
                  render={(props) =>  <Allcivil {...props} /> }
              />
              <Route
                  exact
                  path="/app/report-summary-criminalall/"
                  render={(props) =>  <Allcriminal {...props} /> }
              />
               <Route
                  exact
                  path="/app/dep-ns-case-reports/"
                  render={(props) =>  <NonSamplingCase {...props} /> }
              />
              <Route
                  exact
                  path="/app/dep-sample-reports/"
                  render={(props) =>  <FsoEntry {...props} /> }
              />
               <Route
                  exact
                  path="/app/dep-non-sample-reports/"
                  render={(props) =>  <NSEntry {...props} /> }
              />
              <Route
                  exact
                  path="/app/dep-add-sample-reports/"
                      
                      render={(props) =>  <AddSample {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //    user.user_types.includes("fso") || 
                  //    user.user_types.includes("ho") ? (
                  //     <SampleReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
               <Route
                  exact
                  path="/app/sample-reports/"
                      
                      render={(props) =>  <SampleReport {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //    user.user_types.includes("fso") || 
                  //    user.user_types.includes("ho") ? (
                  //     <SampleReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
              <Route
                  exact
                  path="/app/target_reports/"
                      
                      render={(props) => <TargetReport {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //    user.user_types.includes("ho") ? (
                  //     <TargetReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
               <Route
                  exact
                  path="/app/staff_reports/"
                  render={(props) => <StaffReport {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //     user.user_types.includes("fso")||
                  //    user.user_types.includes("ho") ? (
                  //     <StaffReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />          
              <Route
                  exact
                  path="/app/display-reports/"
                     
                      render={(props) => 
                     (user &&
                      user.user_types &&
                      user.user_types.includes("do")) ||
                     user.user_types.includes("fso") || 
                     user.user_types.includes("ho") ? (
                      <DisplayReports {...props} />
                     ) : (
                       <Redirect to="/app/complaints" />
                    )
                  }
              />
               <Route
                  exact
                  path="/app/display-reports-general/"
                     
                      render={(props) => 
                    //  (user &&
                    //   user.user_types &&
                    //   user.user_types.includes("do")) ||
                    //  user.user_types.includes("fso") || 
                    //  user.user_types.includes("ho") ? (
                      <GeneralReports {...props} />
                    //  ) : (
                    //    <Redirect to="/app/complaints" />
                    // )
                  }
              />
                <Route
                  exact
                  path="/app/display-reports-sample/"
                     
                      render={(props) => 
                    //  (user &&
                    //   user.user_types &&
                    //   user.user_types.includes("do")) ||
                    //  user.user_types.includes("fso") || 
                    //  user.user_types.includes("ho") ? (
                      <SamplReports {...props} />
                    //  ) : (
                    //    <Redirect to="/app/complaints" />
                    // )
                  }
              />
              <Route
                  exact
                  path="/app/displaysample-reports/"
                     
                      render={(props) => 
                     (user &&
                      user.user_types &&
                      user.user_types.includes("do")) ||
                     user.user_types.includes("fso") || 
                     user.user_types.includes("ho") ? (
                      <DisplaySampleReports {...props} />
                     ) : (
                       <Redirect to="/app/complaints" />
                    )
                  }
              />
              <Route
                  exact
                  path="/app/dashboard/"
                  render={(props) => <Dashboard {...props} />}
              />
                <Route
                  exact
                  path="/app/display-reports/lab-different-list/"
                  render={(props) =>  <LABDifferent {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //    user.user_types.includes("fso") ? (
                  //     <LabDifferentSource {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
              <Route
                  exact
                  path="/app/finance-reports"
                  render={(props) => <FinanceReports {...props} />}
                />
                 <Route
                  exact
                  path="/app/finance-reports-list"
                  render={(props) => <FinanceList {...props} />}
                />
                 <Route
                  exact
                  path="/app/finance-credit-report"
                  render={(props) =>(user &&
                    user.user_types &&
                    user.user_types.includes("ho")) ||
                   user.user_types.includes("ac") || 
                   user.user_types.includes("commissioner") ? (
                     <FinanceCredit {...props} />):(
                      <Redirect to="/app/reports" />
                     )}
                />
                <Route
                  exact
                  path="/app/finance-credit-report/:id/"
                  render={(props) =>(user &&
                    user.user_types &&
                    user.user_types.includes("ho")) ||
                   user.user_types.includes("ac") || 
                   user.user_types.includes("commissioner") ? (
                     <FinanceCreditview {...props} />):(
                      <Redirect to="/app/reports" />
                     )}
                />
                 <Route
                  exact
                  path="/app/finance-credit-report/:id/edit"
                  render={(props) =>(user &&
                    user.user_types &&
                    user.user_types.includes("ho")) ||
                   user.user_types.includes("ac") || 
                   user.user_types.includes("commissioner") ? (
                     <FinanceCredit {...props} />):(
                      <Redirect to="/app/reports" />
                     )}
                />
                 <Route
                  exact
                  path="/app/finance-distribute-report"
                  render={(props) =>
                    (user &&
                      user.user_types &&
                      user.user_types.includes("ho")) ||
                     user.user_types.includes("ac") || 
                     user.user_types.includes("commissioner") ? (
                       <FinanceDistribute {...props} />):(
                        <Redirect to="/app/reports" />
                       )}
                />
                <Route
                  exact
                  path="/app/finance-distribute-report/:id/edit"
                  render={(props) => 
                    (user &&
                      user.user_types &&
                      user.user_types.includes("ho")) ||
                     user.user_types.includes("ac") || 
                     user.user_types.includes("commissioner") ? (
                     <FinanceDistribute {...props} />):(
                      <Redirect to="/app/reports" />
                     )}
                />
                <Route
                  exact
                  path="/app/finance-distribute-report/:id"
                  render={(props) => 
                    (user &&
                      user.user_types &&
                      user.user_types.includes("ho")) ||
                     user.user_types.includes("ac") || 
                     user.user_types.includes("commissioner") ? (
                     <FinanceDistributeView {...props} />):(
                      <Redirect to="/app/reports" />
                     )}
                />
                <Route
                  exact
                  path="/app/finance-spend-report"
                  render={(props) => <FinanceSpend {...props} />}
                />
                 <Route
                  exact
                  path="/app/finance-spend-report/:id/edit"
                  render={(props) => <FinanceSpend {...props} />}
                />
                 <Route
                  exact
                  path="/app/finance-spend-report/:id"
                  render={(props) => <FinanceSpendView {...props} />}
                />
                <Route
                  exact
                  path="/app/finance-return-report"
                  render={(props) => <FinanceReturn {...props} />}
                />
                 <Route
                  exact
                  path="/app/finance-return-report/:id/edit"
                  render={(props) => <FinanceReturn {...props} />}
                />
                 <Route
                  exact
                  path="/app/finance-return-report/:id"
                  render={(props) => <FinanceReturnView {...props} />}
                />
                <Route
                  exact
                  path="/app/finance-credit-report-list"
                  render={(props) => <FinanceCreditlist {...props} />}
                />
                 <Route
                  exact
                  path="/app/finance-distribute-report-list"
                  render={(props) => <FinanceDistributelist {...props} />}
                />
                <Route
                  exact
                  path="/app/finance-spend-report-list"
                  render={(props) => <FinanceSpendlist {...props} />}
                />
                <Route
                  exact
                  path="/app/finance-return-report-list"
                  render={(props) => <FinanceReturnlist {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/appt"
                  render={(props) => <APP2Criminal {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/appt"
                  render={(props) => <APP2Criminal {...props} />}
                />
                
                 <Route
                  exact
                  path="/app/display-reports/app"
                  render={(props) => <APPCriminal {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/ascommissioner"
                  render={(props) => <ACCriminal {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/docl"
                  render={(props) => <DOCriminal {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/ddcl"
                  render={(props) => <DDCriminal {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/commissionercl"
                  render={(props) => <ComissionerCriminal {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/lab-different-reports/"
                  render={(props) => <LabDifferentSource {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/line-open-list/"
                  render={(props) => <LineOpen {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/line-closed-list/"
                  render={(props) => <LineClosed {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/complaint-list/"
                  render={(props) => <ComplaintFilterList {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/complaint-seized/"
                  render={(props) => <SeizureDetailsList {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/complaint-sample/"
                  render={(props) => <SampleLiftedDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/complaint-closed/"
                  render={(props) => <ClosedComplaintDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/complaint-notice/"
                  render={(props) => <NoticeIssuedList {...props} />}
                />
                    <Route
                  exact
                  path="/app/reports/complaint-status/"
                  render={(props) => <StatusFilterList {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/complaint-action/"
                  render={(props) => <ActionTakenReportList {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/lab-different-reports/:id/edit"
                  render={(props) => <LabDifferentSource {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/lab_sample_list/"
                  render={(props) =>  <LABSample {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //     user.user_types.includes("fso")||
                  //    user.user_types.includes("ho") ? (
                  //     <SampleAnalysisReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
                <Route
                  exact
                  path="/app/display-reports/lab_sample_list/:id"
                  render={(props) => <LABSampleDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/lab_sample_reports/"
                  render={(props) => <SampleAnalysisReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/lab_sample_reports/:id/edit"
                  render={(props) => <SampleAnalysisReport {...props} />}
                />
                  
              <Route
                  exact
                  path="/app/display-reports/fso_casedetails_list/"
                  render={(props) => <FSOCaseList {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //     user.user_types.includes("fso")||
                  //    user.user_types.includes("ho") ? (
                  //     <FSOCaseReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
                <Route
                  exact
                  path="/app/display-reports/fso_casedetails_list/:id"
                  render={(props) => <FSOCDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso_casedetails_reports/"
                  render={(props) => <FSOCaseReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso_casedetails_reports/:id/edit"
                  render={(props) => <FSOCaseReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do_sample_list/"
                  render={(props) =>  <DOSampleList {...props} />}
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //     user.user_types.includes("fso")||
                  //    user.user_types.includes("ho") ? (
                  //     <DOSampleReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
                <Route
                  exact
                  path="/app/display-reports/do_sample_list/:id"
                  render={(props) => <DOSampleDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do_sample_reports/"
                  render={(props) => <DOSampleReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do_sample_reports/:id/edit"
                  render={(props) => <DOSampleReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/do_damaged_list/"
                  render={(props) =>   <DODamagedList {...props} />}
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //     user.user_types.includes("fso")||
                  //    user.user_types.includes("ho") ? (
                  //     <DODamagedReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
                <Route
                  exact
                  path="/app/display-reports/do_damaged_list/:id"
                  render={(props) => <DODamagedDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do_damaged_reports/"
                  render={(props) => <DODamagedReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do_damaged_reports/:id/edit"
                  render={(props) => <DODamagedReport {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/fso_sample_list/"
                  render={(props) =>  <FSOSampleList {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //     user.user_types.includes("fso")||
                  //    user.user_types.includes("ho") ? (
                  //     <FSOSampleReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
                <Route
                  exact
                  path="/app/display-reports/fso_sample_list/:id"
                  render={(props) => <FSOSDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso_sample_reports/"
                  render={(props) => <FSOSampleReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/fso_sample_reports/"
                  render={(props) => <FSOSampleReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso_non_sample_reports/"
                  render={(props) => <FSONonSamplingReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-issue/"
                  render={(props) => <Summaryissue {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-trader/"
                  render={(props) => <Summarytrader {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-complaints/"
                  render={(props) => <Summarycomplaint {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-fbo/"
                  render={(props) => <SummaryFbo {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-licnese/"
                  render={(props) => <SummaryLicense {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-act/"
                  render={(props) => <SummaryAct {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-fine/"
                  render={(props) => <SummaryFine {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-notice-issued/"
                  render={(props) => <SummaryNissued {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-case-enforcement/"
                  render={(props) => <SummaryCenforcement {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-seizure/"
                  render={(props) => <SummaryFoodSeizure {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-sample-lifted/"
                  render={(props) => <SummarySlifted {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-sample-collected/"
                  render={(props) => <SummaryScollection {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-sample-analysed/"
                  render={(props) => <SummarySanalysed {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-prosecution/"
                  render={(props) => <SummaryProsecution {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-registration/"
                  render={(props) => <SummaryRegistration {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-fso-epo/"
                  render={(props) => <SummaryFsoEpo {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-do-epo/"
                  render={(props) => <SummaryDoEpo {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-slaughter/"
                  render={(props) => <Summryslaughter {...props} />}
                />
                  <Route
                  exact
                  path="/app/reports/summary-fruits/"
                  render={(props) => <Summaryfruits {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-pdw/"
                  render={(props) => <Summarypdw {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-hotel/"
                  render={(props) => <SummaryHotel {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-school/"
                  render={(props) => <SummarySchool {...props} />}
                />
                
                 <Route
                  exact
                  path="/app/reports/summary-tourjournal/"
                  render={(props) => <SummaryTourJournal {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-compound/"
                  render={(props) => <Summarycompound {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-daily/"
                  render={(props) => <SummaryDaily {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-enumeration/"
                  render={(props) => <SummaryENumeration {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-iec/"
                  render={(props) => <SummaryIec {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-notice/"
                  render={(props) => <SummaryNotice {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-toolkit/"
                  render={(props) => <Summarytoolkit {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-toolkit/"
                  render={(props) => <Summarytoolkit {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-panmasala/"
                  render={(props) => <SummaryPanmasala {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-advance-tour/"
                  render={(props) => <SummaryTourProgram {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-tasmac/"
                  render={(props) => <SummaryTasmac {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-dlac/"
                  render={(props) => <Summarydlac {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-fortification/"
                  render={(props) => <Summaryfortification {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-seizure/"
                  render={(props) => <Summaryseizure {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-fine-total/"
                  render={(props) => <SummaryFinetotal {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-vehicle/"
                  render={(props) => <SummaryVehicele {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-commissioner-epo/"
                  render={(props) => <SummaryCommis {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-fund/"
                  render={(props) => <Summaryfund {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-non-sample/"
                  render={(props) => <SummaryNs {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-samples/"
                  render={(props) => <SummarySamp {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-specialdrive/"
                  render={(props) => <Summarydrive {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-hygine/"
                  render={(props) => <SummaryHygine {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-fostac/"
                  render={(props) => <SummaryFostac {...props} />}
                />
               
                <Route
                  exact
                  path="/app/reports/summary-food/"
                  render={(props) => <SummaryFood {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-food/"
                  render={(props) => <SummaryFood {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-vegetable/"
                  render={(props) => <SummaryVegetable {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-bhog/"
                  render={(props) => <SummaryHygineRating {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-erc-school/"
                  render={(props) => <SummaryERSchool {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-campus/"
                  render={(props) => <SummaryERCampus {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-ruco/"
                  render={(props) => <SummaryRUCO {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-biodiesel/"
                  render={(props) => <SummaryBio {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/summary-uco/"
                  render={(props) => <SummaryUco {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-sfsf/"
                  render={(props) => <SummarySave {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-nfw/"
                  render={(props) => <SummaryNFW {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-ercportal/"
                  render={(props) => <SummaryERCPortal {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-ercactivity/"
                  render={(props) => <SummaryErcActivity {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/summary-foscoris/"
                  render={(props) => <SummaryFoscoris {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/comissioner-EPO-list/"
                  render={(props) =>  <CommissionerEPOList {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //    user.user_types.includes("ho") ? (
                  //     <ComissionerEPOReport {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
                <Route
                  exact
                  path="/app/display-reports/comissioner-EPO-list/:id"
                  render={(props) => <ComissionerEPODetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissioner-EPO-reports/"
                  render={(props) => <ComissionerEPOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissioner-EPO-reports/:id/edit"
                  render={(props) => <ComissionerEPOReport {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/comissioner-case-list/"
                  render={(props) => <CommissionerCase {...props} /> }
                  //    (user &&
                  //     user.user_types &&
                  //     user.user_types.includes("do")) ||
                  //     user.user_types.includes("fso")||
                  //    user.user_types.includes("ho") ? (
                  //     <CommisionerCase {...props} />
                  //    ) : (
                  //      <Redirect to="/app/complaints" />
                  //   )
                  // }
              />
                <Route
                  exact
                  path="/app/display-reports/comissioner-case-list/:id"
                  render={(props) => <CommCDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissioner-case-reports/"
                  render={(props) => <CommisionerCase {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissioner-case-reports/:id/edit"
                  render={(props) => <CommisionerCase {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/do-appeal-list/"
                  render={(props) => <DOAppealList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do-appeal-list/:id"
                  render={(props) => <DOADetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-appeal-reports/"
                  render={(props) => <DOAppealReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-appeal-reports/:id/edit"
                  render={(props) => <DOAppealReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do-epo-list/"
                  render={(props) => <DOEPOList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do-epo-list/:id"
                  render={(props) => <DOEPODetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-epo-reports/"
                  render={(props) => <DOEPOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-epo-reports/:id/edit"
                  render={(props) => <DOEPOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fso-EPO-list/"
                  render={(props) => <FSOEPOList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fso-EPO-list/:id"
                  render={(props) => <FSOEPODetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso-EPO-reports/"
                  render={(props) => <FSOEPOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso-EPO-reports/:id/edit"
                  render={(props) => <FSOEPOReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/compound-penalty-list/"
                  render={(props) => <CompoundList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/compound-penalty-list/:id"
                  render={(props) => <CompondDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/compound-penalty-reports/"
                  render={(props) => <CompoundPenalty {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/compound-penalty-reports/:id/edit"
                  render={(props) => <CompoundPenalty {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do-direct-list/"
                  render={(props) => <DODirectList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do-direct-list/:id"
                  render={(props) => <DODirectSampleDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-direct-reports/"
                  render={(props) => <DODirectCase {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-direct-reports/:id/edit"
                  render={(props) => <DODirectCase {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/do-clarify-list/"
                  render={(props) => <DOCaseFile {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do-clarify-list/:id"
                  render={(props) => <DOClarifyDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-clarify-reports/"
                  render={(props) => <DOCaseClarifyReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-clarify-reports/:id/edit"
                  render={(props) => <DOCaseClarifyReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/do-directdetails-list/"
                  render={(props) => <DOCaseList {...props} /> }
                    //  (user &&
                    // user.user_types &&
                    // user.user_types.includes("do")) ? (
                    // <DODirectDetails {...props} /> ) : <Redirect to="/app/complaints" /> }
                />
                <Route
                  exact
                  path="/app/display-reports/do-directdetails-list/:id"
                  render={(props) => <DDSDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-directdetails-reports/"
                  render={(props) => <DODirectDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-directdetails-reports/:id/edit"
                  render={(props) => <DODirectDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/adjudication-appeal-list/"
                  render={(props) => <AdjudicationAppealList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/adjudication-appeal-list/:id"
                  render={(props) => <AdjudicationDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/adjudication-appeal-reports/"
                  render={(props) => <AdjudicationAppealReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/adjudication-appeal-reports/:id/edit"
                  render={(props) => <AdjudicationAppealReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/app-civil-list/"
                  render={(props) => <APPCivil {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/app-civil-list/:id"
                  render={(props) => <APPCivilDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/app-civil-reports/"
                  render={(props) => <APPCivilCase {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/app-civil-reports/:id/edit"
                  render={(props) => <APPCivilCase {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/app-direct-list/"
                  render={(props) => <APPDirect {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/app-direct-list/:id"
                  render={(props) => <APPDirectDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/app-direct-reports/"
                  render={(props) => <APPDirectCase {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/app-direct-reports/:id/edit"
                  render={(props) => <APPDirectCase {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/comissionerate-criminal-list/"
                  render={(props) => <ComissionerateCriminalList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/comissionerate-criminal-list/:id"
                  render={(props) => <CoomrCriminalDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-criminal-reports/"
                  render={(props) => <ComissionerateCriminal {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-criminal-reports/:id/edit"
                  render={(props) => <ComissionerateCriminal {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/comissionerate-appeal-list/"
                  render={(props) => <ComissionerateAppeal {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/comissionerate-appeal-list/:id"
                  render={(props) => <CommAppealDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-appeal-reports/"
                  render={(props) => <ComissionerateAppealReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-appeal-reports/:id/edit"
                  render={(props) => <ComissionerateAppealReport {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/comissionerate-direct-list/"
                  render={(props) => <ComissionerateDirect {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/comissionerate-direct-list/:id"
                  render={(props) => <CommrDCaseDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-direct-reports/"
                  render={(props) => <ComissionerateDirectReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-direct-reports/:id/edit"
                  render={(props) => <ComissionerateDirectReport {...props} />}
                />
                  <Route
                  exact
                  path="/app/display-reports/comissionerate-EPO-list/"
                  render={(props) => <CommissionerateEPOList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/comissionerate-EPO-list/:id"
                  render={(props) => <ComissionerateEPODetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-EPO-reports/"
                  render={(props) => <ComissionerateEPOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/comissionerate-EPO-reports/:id/edit"
                  render={(props) => <ComissionerateEPOReport {...props} />}
                />
              <Route
                  exact
                  path="/app/display-reports/special-drive-list/"
                  render={(props) => <SpecialDriveList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/special-drive-list/:id"
                  render={(props) => <SpecialDriveDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/special-drive-reports/"
                  render={(props) => <SpecialDriveOfLandRReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/special-drive-reports/:id/edit"
                  render={(props) => <SpecialDriveOfLandRReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/enumeration-of-fbo-list/"
                  render={(props) => <EnumerationOfFboList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/enumeration-of-fbo-list/:id"
                  render={(props) => <EnumerationOfFboDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/enumeration-of-fbo-reports/"
                  render={(props) => <EnumerationOfFBOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/enumeration-of-fbo-reports/:id/edit"
                  render={(props) => <EnumerationOfFBOReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/fso-directdetails-list/"
                  render={(props) => <FSODirectList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fso-directdetails-list/:id"
                  render={(props) => <FSOCDDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso-directdetails-reports/"
                  render={(props) => <FSODirectDetailsReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso-directdetails-reports/:id/edit"
                  render={(props) => <FSODirectDetailsReport {...props} />}
                />
                {/* <Route
                  exact
                  path="/app/display-reports/do-sample-list/"
                  render={(props) => <DOSampleReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/do-sample-list/:id"
                  render={(props) => <DOSampleReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-sample-reports/"
                  render={(props) => <DOSampleReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/do-sample-reports/:id/edit"
                  render={(props) => <DOSampleReport {...props} />}
                /> */}
                  <Route
                  exact
                  path="/app/display-reports/fso-direct-list/"
                  render={(props) => <FSODirectCase {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fso-direct-list/:id"
                  render={(props) => <FSODirectCaseDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso-direct-reports/"
                  render={(props) => <FSODirectReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fso-direct-reports/:id/edit"
                  render={(props) => <FSODirectReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/information-education-list/"
                  render={(props) => <InformationList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/information-education-list/:id"
                  render={(props) => <InformationDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/information-education-reports/"
                  render={(props) => <InformartionEducationReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/information-education-reports/:id/edit"
                  render={(props) => <InformartionEducationReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/notice-issued-list/"
                  render={(props) => <NoticeList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/notice-issued-list/:id"
                  render={(props) => <NoticeDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/notice-issued-reports/"
                  render={(props) => <NoticeIssudReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/notice-issued-reports/:id/edit"
                  render={(props) => <NoticeIssudReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/enumeration-data-list/"
                  render={(props) => <EnumerationDataList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/enumeration-data-list/:id"
                  render={(props) => <EnumerationDataDetail {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/enumeration-data-reports/"
                  render={(props) =>
                    <EnumerationDataReport {...props} /> }
                />
                 <Route
                  exact
                  path="/app/reports/enumeration-data-reports/:id/edit"
                  render={(props) => <EnumerationDataReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/clean-food-list/"
                  render={(props) => <CleanFoodList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/clean-food-list/:id"
                  render={(props) => <CSFHDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/clean-food-reports/"
                  render={(props) => <CSFHReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/clean-food-reports/:id/edit"
                  render={(props) => <CSFHReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/worship-place-list/"
                  render={(props) => <WorshipList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/worship-place-list/:id"
                  render={(props) => <BHOGDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/worship-place-reports/"
                  render={(props) => <WorshipCertificationReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/worship-place-reports/:id/edit"
                  render={(props) => <WorshipCertificationReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/eatright-training-list/"
                  render={(props) => <ERCTraningList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/eatright-training-list/:id"
                  render={(props) => <ERToolkitDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/eatright-training-reports/"
                  render={(props) => <EatRightTrainingReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/eatright-training-reports/:id/edit"
                  render={(props) => <EatRightTrainingReport {...props} />}
                />
                   <Route
                  exact
                  path="/app/display-reports/eatright-school-list/"
                  render={(props) => <ERSchoolList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/eatright-school-list/:id"
                  render={(props) => <ERSchoolDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/eatright-school-reports/"
                  render={(props) => <ERSchoolReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/eatright-school-reports/:id/edit"
                  render={(props) => <ERSchoolReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/eatright-campus-list/"
                  render={(props) => <ERCampusList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/eatright-campus-list/:id"
                  render={(props) => <ERCampusDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/eatright-campus-reports/"
                  render={(props) => <ERCampusReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/eatright-campus-reports/:id/edit"
                  render={(props) => <ERCampusReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/number-list/"
                  render={(props) => <ERCActivityList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/number-list/:id"
                  render={(props) => <ERCActivityDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/number-reports/"
                  render={(props) => <NumberReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/number-reports/:id/edit"
                  render={(props) => <NumberReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/ruco-certification-list/"
                  render={(props) => <RUCOCertificationList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/ruco-certification-list/:id"
                  render={(props) => <RUCOCertificationDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/ruco-certification-reports/"
                  render={(props) => <RUCOCertificationReport {...props} />  }
                    //  (user &&
                    // user.user_types &&
                    // user.user_types.includes("do")) ? (
                    //   <RUCOCertificationReport {...props} /> ) : <Redirect to="/app/complaints" />}
                />
                 <Route
                  exact
                  path="/app/reports/ruco-certification-reports/:id/edit"
                  render={(props) => <RUCOCertificationReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/ruco-biodiesel-list/"
                  render={(props) => <RUCOCollectionList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/ruco-biodiesel-list/:id"
                  render={(props) => <RUCOBiodieselDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/ruco-biodiesel-reports/"
                  render={(props) => <RUCOBiodieselReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/ruco-biodiesel-reports/:id/edit"
                  render={(props) => <RUCOBiodieselReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/food-waste-list/"
                  render={(props) => <NFWCertificationList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/food-waste-list/:id"
                  render={(props) => <NFWCertification {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/food-waste-reports/"
                  render={(props) => <NFWCertificationReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/food-waste-reports/:id/edit"
                  render={(props) => <NFWCertificationReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/food-collection-list/"
                  render={(props) => <NFWCDList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/food-collection-list/:id"
                  render={(props) => <NFWDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/food-collection-reports/"
                  render={(props) => <NFWCDReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/food-collection-reports/:id/edit"
                  render={(props) => <NFWCDReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/erc-portal-list/"
                  render={(props) => <ERCUploadList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/erc-portal-list/:id"
                  render={(props) => <ERCUploadDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/erc-portal-reports/"
                  render={(props) => <ERCPortalReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/erc-portal-reports/:id/edit"
                  render={(props) => <ERCPortalReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/market-list/"
                  render={(props) => <MarketList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/market-list/:id"
                  render={(props) => <MarketDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/market-reports/"
                  render={(props) => <MarketCertificationReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/market-reports/:id/edit"
                  render={(props) => <MarketCertificationReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/hygine-rating-list/"
                  render={(props) => <HygineList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/hygine-rating-list/:id"
                  render={(props) => <HygineDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/hygine-rating-reports/"
                  render={(props) => <HygineRatingReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/hygine-rating-reports/:id/edit"
                  render={(props) => <HygineRatingReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/seizure-list/"
                  render={(props) => <SeizureList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/seizure-list/:id"
                  render={(props) => <SeizureDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/seizure-reports/"
                  render={(props) => <SeizureReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/reports/seizure-reports/:id/edit"
                  render={(props) => <SeizureReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/vehicle-inspection-reports/:id/edit"
                  render={(props) => <VehicleInspectionReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/vehicle-inspection-list/"
                  render={(props) => <VehicleList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/vehicle-inspection-list/:id"
                  render={(props) => <VehicleDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/vehicle-inspection-reports/"
                  render={(props) => <VehicleInspectionReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/complaint-list/"
                  render={(props) => <ComplaintsReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/sample-filter-list/"
                  render={(props) => <SamplFilter {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/caseline-filter-list/"
                  render={(props) => <CaseFilter {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/casedirectline-filter-list/"
                  render={(props) => <Casenonfilter {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/sampleline-filter-list/"
                  render={(props) => <Samplelinefilter {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/samplenonline-filter-list/"
                  render={(props) => <SampleNonlinefilter {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/sample-action-list/"
                  render={(props) => <SamplAction {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/sample-action-non-list/"
                  render={(props) => <SamplNonFilter {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/sample-non-list/"
                  render={(props) => <SampleNonReports {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/complaint-list/:id"
                  render={(props) => <ComplaintsReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/complaint-reports/"
                  render={(props) => <ComplaintsReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/complaint-reports/:id/edit"
                  render={(props) => <ComplaintsReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/food-in-newspaper-list/"
                  render={(props) => <FoodInNewspaperList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/food-in-newspaper-list/:id"
                  render={(props) => <FoodInNewspaperDetails {...props} />}
                />              
                <Route
                  exact
                  path="/app/reports/food-in-newspaper-reports/"
                  render={(props) => <FoodInNewspaperReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/food-in-newspaper-reports/:id/edit"
                  render={(props) => <FoodInNewspaperReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/expected-inspection-list/"
                  render={(props) => <ExpectedInspectionList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/expected-inspection-list/:id"
                  render={(props) => <ExpectedInspectionDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/expected-inspection-reports/"
                  render={(props) => <ExpectedInspectionReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/expected-inspection-reports/:id/edit"
                  render={(props) => <ExpectedInspectionReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/food-fortification-list/"
                  render={(props) => <FoodFortificationList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/food-fortification-list/:id"
                  render={(props) => <FoodFortificationDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/food-fortification-reports/"
                  render={(props) =>  <FoodFortificationReport {...props} /> }
                    // (user &&
                    // user.user_types &&
                    // user.user_types.includes("do")) ? (
                    //   <FoodFortificationReport {...props} /> ) : <Redirect to="/app/complaints" />}
                />
                <Route
                  exact
                  path="/app/reports/food-fortification-reports/:id/edit"
                  render={(props) => <FoodFortificationReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fostac-training-list/"
                  render={(props) => <FostacList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fostac-training-list/:id"
                  render={(props) => <FOSTACTrainingDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fostac-training-reports/"
                  render={(props) => <FOSTACTrainingReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fostac-training-reports/:id/edit"
                  render={(props) => <FOSTACTrainingReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/dlac-list/"
                  render={(props) => <DLACList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/dlac-list/:id"
                  render={(props) => <DLACDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/dlac-reports/"
                  render={(props) =>  <DLACReport {...props} /> }
                    // (user &&
                    // user.user_types &&
                    // user.user_types.includes("do")) ? (
                    //   <DLACReport {...props} /> ) : <Redirect to="/app/complaints" /> }
                />
                <Route
                  exact
                  path="/app/reports/dlac-reports/:id/edit"
                  render={(props) => <DLACReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/tourjournal-list/"
                  render={(props) => <TourJournalList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/tourjournal-list/:id"
                  render={(props) => <TourJournalDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tourjournal-reports/"
                  render={(props) => <TourJournal {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tourjournal-reports/:id/edit"
                  render={(props) => <TourJournal {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/licensing-list/"
                  render={(props) => <LicensingList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/licensing-list/:id"
                  render={(props) => <LicensingDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/licencing-reports/"
                  render={(props) =>  <LicencingReport {...props} /> }
                    
                    // (user &&
                    // user.user_types &&
                    // user.user_types.includes("do")) ? (
                    //   <LicencingReport {...props} /> ) : <Redirect to="/app/complaints" />}
                />
                <Route
                  exact
                  path="/app/reports/licencing-reports/:id/edit"
                  render={(props) => <LicencingReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/slaughter-house-list/"
                  render={(props) =>  
                    <SlaughterHouseList {...props} /> }
                />
                <Route
                  exact
                  path="/app/display-reports/slaughter-house-list/:id"
                  render={(props) => <SlaughterHouseDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/slaughter-house-report/"
                  render={(props) => <SlaughterHouseReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/slaughter-house-report/:id/edit"
                  render={(props) => <SlaughterHouseReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fbo-list/"
                  render={(props) => <FboList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fbo-list/:id"
                  render={(props) => <FboDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fbo-report/"
                  render={(props) => <FboReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fbo-report/:id/edit"
                  render={(props) => <FboReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/iec-list/"
                  render={(props) => <IECList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/iec-list/:id"
                  render={(props) => <IECDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/iec-report/"
                  render={(props) => <IECReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/iec-report/:id/edit"
                  render={(props) => <IECReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/tasmac-list/"
                  render={(props) => <TasmacList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/tasmac-list/:id"
                  render={(props) => <TasmacDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tasmac-reports/"
                  render={(props) => 
                      <TasmacReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tasmac-reports/:id/edit"
                  render={(props) => <TasmacReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fssaact-list/"
                  render={(props) => <FSSActList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/fssaact-list/:id"
                  render={(props) => <FssaactDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fssaact-reports/"
                  render={(props) => <FssaactReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/fssaact-reports/:id/edit"
                  render={(props) => <FssaactReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/regmonthly-list/"
                  render={(props) => <RegMonthlyList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/regmonthly-list/:id"
                  render={(props) => <RegMonthlyDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/regmonthly-reports/"
                  render={(props) => <RegMonthlyReports {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/regmonthly-reports/:id/edit"
                  render={(props) => <RegMonthlyReports {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/tndaily-list/"
                  render={(props) => <TNDailyList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/tndaily-list/:id"
                  render={(props) => <TNDailyDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tndaily-reports/"
                  render={(props) => <TNDailyReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tndaily-reports/:id/edit"
                  render={(props) => <TNDailyReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/licensemonthly-list/"
                  render={(props) => <LicenseMonthlyList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/licensemonthly-list/:id"
                  render={(props) => <LicenseMonthlyDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/licensemonthly-reports/"
                  render={(props) => <LicenseMonthlyReports {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/licensemonthly-reports/:id/edit"
                  render={(props) => <LicenseMonthlyReports {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/slaughter-list/"
                  render={(props) => <SlaughterList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/slaughter-list/:id"
                  render={(props) => <SlaughterDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/slaughter-reports/"
                  render={(props) => 
                      <SlaughterReport {...props} />  }
                />
                <Route
                  exact
                  path="/app/reports/slaughter-reports/:id/edit"
                  render={(props) => <SlaughterReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/registration-list/"
                  render={(props) => <RegistrationList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/registration-list/:id"
                  render={(props) => <RegistrationDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/registration-reports/"
                  render={(props) => <RegistrationReport {...props} />}

                  // render={(props) => (user &&
                  //   user.user_types &&
                  //   user.user_types.includes("fso")) ? (
                  //     <RegistrationReport {...props} /> ) : <Redirect to="/app/complaints" /> }
                />
                <Route
                  exact
                  path="/app/reports/registration-reports/:id/edit"
                  render={(props) => <RegistrationReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/panmasala-list/"
                  render={(props) => <PanmasalaList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/panmasala-list/:id"
                  render={(props) => <PanmasalaDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/panmasala-reports/"
                  render={(props) => 
                      <PanmasalaReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/panmasala-reports/:id/edit"
                  render={(props) => <PanmasalaReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/high-courtcase-list/"
                  render={(props) => <HighCourtCaseList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/high-courtcase-list/:id"
                  render={(props) => <HighCourtCaseDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/high-courtcase-reports/"
                  render={(props) => <HighCourtCaseReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/high-courtcase-reports/:id/edit"
                  render={(props) => <HighCourtCaseReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/adjudication-case-list/"
                  render={(props) => <AdjudicationList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/adjudication-case-list/:id"
                  render={(props) => <AdjudicationCivilDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/adjudication-case-reports/"
                  render={(props) => <AdjudicationReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/adjudication-case-reports/:id/edit"
                  render={(props) => <AdjudicationReport {...props} />}
                />
                 <Route
                  exact
                  path="/app/display-reports/uco-list/"
                  render={(props) => <UCOList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/uco-list/:id"
                  render={(props) => <UCODetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/uco-reports/"
                  render={(props) => <UCOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/uco-reports/:id/edit"
                  render={(props) => <UCOReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/special-program-list/"
                  render={(props) => <SpecialProgramList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/special-program-list/:id"
                  render={(props) => <SpecialProgramDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/special-program-report/"
                  render={(props) => 
                      <SpecialProgramReport {...props} />  }
                />
                <Route
                  exact
                  path="/app/reports/special-program-report/:id/edit"
                  render={(props) => <SpecialProgramReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/pdw-list/"
                  render={(props) => <PdwList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/pdw-list/:id"
                  render={(props) => <PdwDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/pdw-reports/"
                  render={(props) => 
                      <PdwReport {...props} /> }
                />
                <Route
                  exact
                  path="/app/reports/pdw-reports/:id/edit"
                  render={(props) => <PdwReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/ripening-mangoes-list/"
                  render={(props) => <RipeningOfMangoesList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/ripening-mangoes-list/:id"
                  render={(props) => <RipeningOfMangoesDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/ripening-mangoes-reports/"
                  render={(props) => <RipeningOfMangoes {...props} />}

                  // render={(props) =>  (user &&
                  //   user.user_types &&
                  //   user.user_types.includes("fso")) ? (
                  //     <RipeningOfMangoes {...props} /> ) : <Redirect to="/app/complaints" />}
                />
                <Route
                  exact
                  path="/app/reports/ripening-mangoes-reports/:id/edit"
                  render={(props) => <RipeningOfMangoes {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/ssc-list/"
                  render={(props) => <SurveillanceList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/ssc-list/:id"
                  render={(props) => <SurveillanceDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/ssc-reports/"
                  render={(props) => <SurveillanceReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/ssc-reports/:id/edit"
                  render={(props) => <SurveillanceReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/hotels-and-restaurants-list/"
                  render={(props) => <HotelList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/hotels-and-restaurants-list/:id"
                  render={(props) => <HotelDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/hotels-and-restaurants-reports/"
                  render={(props) => 
                      <HotelReport {...props} />  }
                />
                <Route
                  exact
                  path="/app/reports/hotels-and-restaurants-reports/:id/edit"
                  render={(props) => <HotelReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/foscorisdigital-list/"
                  render={(props) => <FoscorisDigitalList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/foscorisdigital-list/:id"
                  render={(props) => <FoscorisDigitalDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/foscorisdigital-reports/"
                  render={(props) => 
                      <FoscorisDigitalReport {...props} />  }
                />
                <Route
                  exact
                  path="/app/reports/foscorisdigital-reports/:id/edit"
                  render={(props) => <FoscorisDigitalReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/tourprogram-list/"
                  render={(props) => <TourList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/tourprogram-list/:id"
                  render={(props) => <TourDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tourprogram-reports/"
                  render={(props) => <TourReport {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/tourprogram-reports/:id/edit"
                  render={(props) => <TourReport {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/school-list/"
                  render={(props) => <SchoolList {...props} />}
                />
                <Route
                  exact
                  path="/app/display-reports/school-list/:id"
                  render={(props) => <SchoolDetails {...props} />}
                />
                <Route
                  exact
                  path="/app/reports/school-reports/"
                  render={(props) => 
                      <SchoolReport {...props} /> }
                />
                <Route
                  exact
                  path="/app/reports/school-reports/:id/edit"
                  render={(props) => <SchoolReport {...props} />}
                />
                <Route
                  exact
                  path="/app/users/:id/edit/"
                  render={(props) => (
                    <AddUser title={"Update User"} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/users/add/"
                  render={(props) =>
                    (user &&
                      user.user_types &&
                      user.user_types.includes("do")) ||
                    user.user_types.includes("fso") ? (
                      <Redirect to="/app/complaints" />
                    ) : (
                      <AddUser title={"Add User"} {...props} />
                    )
                  }
                />
                <Route
                  exact
                  path="/app/users/"
                  render={(props) =>
                    (user &&
                      user.user_types &&
                      user.user_types.includes("fso")) ||
                    (user &&
                      user.user_types &&
                      user.user_types.length === 0) ? (
                      <Redirect to="/app/complaints" />
                    ) : (
                      <UserList {...props} />
                    )
                  }
                />
                <Route
                  exact
                  path="/app/profile/"
                  render={(props) => <Profile {...props} />}
                />
                <Route
                  exact
                  path="/app/inspections/:id/edit/"
                  render={(props) => (
                    <AddInspection title={"Edit Inspection"} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/inspections/add/"
                  render={(props) => (
                    <AddInspection title={"Add Inspection"} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/reports/blog-reports-list/"
                  render={(props) => (
                    <Subscribelist  {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/reports/feedback-reports-list/"
                  render={(props) => (
                    <Feedbackreportlist  {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/reports/contact-us-reports-list/"
                  render={(props) => (
                    <Contactlist  {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/stock/add/"
                  render={(props) => (
                    user && user?.id &&user?.id  == '3461' ? 
                    <Stock  {...props} />
                    :<Redirect to='/' />
                  )}
                />
                  <Route
                  exact
                  path="/app/delete/complaint"
                  render={(props) => (
                    user && user?.id &&user?.id  == '3' ? 
                    <DeleteComplaint  {...props} />
                    :<Redirect to='/' />
                  )}
                />
                <Route
                  exact
                  path="/app/complaints/add/"
                  render={(props) => (
                    <AddComplaint title={"Add Complaint"} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/complaints/:id/edit/"
                  render={(props) => (
                    <AddComplaint title={"Edit Complaint"} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/app/complaints/:id"
                  render={(props) =>
                    user && user.user_types.length > 0 ? (
                      <ComplaintDeptDetails {...props} />
                    ) : (
                      <ComplaintDetails {...props} />
                    )
                  }
                />
                <Route
                  exact
                  path="/app/complaints/"
                  render={(props) =>
                    user && user.user_types&& user.user_types.length > 0 &&  (user && user.user_types && user.user_types.includes("ho"))|| 
                    (user && user.user_types && user.user_types.includes("fso"))|| 
                    // (user && user.user_types && user.user_types.includes("ho"))|| 
                    (user && user.user_types && user.user_types.includes("do"))
                    // (user && user.user_types && user.user_types.includes("AO"))||
                    // (user && user.user_types && user.user_types.includes("dd"))||
                    // (user && user.user_types && user.user_types.includes("oneapp"))||
                    // (user && user.user_types && user.user_types.includes("twoapp"))||
                    // (user && user.user_types && user.user_types.includes("labapp"))||
                    // (user && user.user_types && user.user_types.includes("commissioner"))||
                    // (user && user.user_types && user.user_types.includes("ac"))
                       ? (
                      <ComplaintDeptList {...props} />
                    ) : (
                      <ComplaintList {...props} />
                    )
                  }
                />
                <Route
                  exact
                  path="/app/:type/:id/edit/"
                  render={(props) => <AddMasterData {...props} />}
                />
                <Route
                  exact
                  path="/app/:type/add/"
                  render={(props) => <AddMasterData {...props} />}
                />
                <Route
                  exact
                  path="/app/trader-types/"
                  component={() => (
                    <MasterDataTable
                      path="trader-types"
                      name="Trader Type"
                      title="Trader Types"
                      data={traderTypes}
                    />
                  )}
                />
                <Route
                  exact
                  path="/app/food-types/"
                  component={() => (
                    <MasterDataTable
                      path="food-types"
                      name="Food Type"
                      title="Food Types"
                      data={foodTypes}
                    />
                  )}
                />
                <Route
                  exact
                  path="/app/food-issues/"
                  component={() => (
                    <MasterDataTable
                      path="food-issues"
                      name="Food Issue"
                      title="Food Issues"
                      data={foodIssues}
                    />
                  )}
                />
                <Route
                  exact
                  path="/app/food-conditions/"
                  component={() => (
                    <MasterDataTable
                      path="food-conditions"
                      name="Food Condition"
                      title="Food Conditions"
                      data={foodConditions}
                    />
                  )}
                />
                <Route exact path="/app" component={() => <Dashboard />} />
                <Redirect path="*" to="/app" />
              </Switch>
            </div>
          </div>
        </Router>
      </div>
      <Link
        onClick={handelBacktoTop}
        className="scroll-to-top rounded"
        to="#"
        style={{ display: backToTop ? "inline" : "none" }}
      >
        <i className="fas fa-angle-up"></i>
      </Link>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetMasterData: () => dispatch(getMasterData()),
  onGetDist:()=>dispatch(getDistrictWards())
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  traderTypes: state.home.traderTypes,
  foodTypes: state.home.foodTypes,
  foodConditions: state.home.foodConditions,
  foodIssues: state.home.foodIssues,
  districts: state.home.districts,
  wardsCategory:state.home.wardsCategory,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
