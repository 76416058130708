import React from 'react';
import BarChart from './BarChart';
import { connect } from "react-redux";

const ActionTakenBarChart = ({data }) => {
    console.log(data)
    return (
        <>
        <div className="card">
            <div className="card-body">
				<h4 className="heading">Closure status by actions taken</h4>
              { data && data.length !==0 ?  <BarChart data={data} />:<>
              <div style={{marginTop:90, marginBottom:90}}>No Data Available</div>
              </>}
            </div>
        </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
});
  
const mapStateToProps = (state) => ({
    districts: state.home.districts,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ActionTakenBarChart);