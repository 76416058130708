import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createFboReports, editFboReports , getDistrictWards} from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const FboReport = (props) => {
    const { districts, user,wards, onCreateFboReports,wardsCategory,ongetDist, onEditFboReports, traderTypes } = props;
    const [values, setValues] = useState({
        date : '',
        area_name:'',
        fso_area_code:'',
        type_institution:'',
        type:'',
        trader_category:'',
        other_trader:'',
        no_of_govt_institution : null,
        no_of_private_fbo :null,
        district : ''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();
 const Types = [
    { id: "1", name: "Government" },
    { id: "2", name: "Private" },
 ]
 const Typ = [
    { id: "1", name: "Add" },
    { id: "2", name: "Remove" },
 ]
 const TypeOfTrader = [
    { id: '0', name: 'Manufacturer' },
    { id: '1', name: 'Retailer' },
    { id: '2', name: 'WholeSaler/Distributor/Repacker' },
    { id: '3', name: 'Transporter' },
    { id: '4', name: 'Others' },
]

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line   
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.fso_area_code)
            return (
                setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.fso_area_code])
    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['date','area_name','fso_area_code', 'district','type_institution','type','trader_category'];
        if(values&&values.trader_category&&values.type_institution=='Government')keys.push('no_of_govt_institutions')
        if(values&&values.trader_category&&values.type_institution=='Private')keys.push('no_of_private_fbo')
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditFboReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/fbo-list/`);
            };

        } else {
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateFboReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }       
        
    }

    const getDis=async(data)=>{
   await ongetDist({district:data})
  }
  useEffect(()=>{
    if(values.district&&values.district){
      getDis(values.district)
    }
  },[values.district&&values.district])
    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">FBO Enumeration Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.fso_area_code}
                                        error={errors.fso_area_code }
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("fso_area_code", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_institution"
                            >Type of Institution *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="type_institution"
                                        action="dropdown"
                                        value={values.type_institution}
                                        error={errors.type_institution }
                                        items={Types}
                                        onChange={(value) => handleTextChange("type_institution", value)}
                                />
                            </div>
                        </div>  
                        {values && values.type_institution && (
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type"
                                >Number of Institution to be Added/Removed *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                            type="text"
                                            name="type"
                                            action="dropdown"
                                            value={values.type}
                                            error={errors.type }
                                            items={Typ}
                                            onChange={(value) => handleTextChange("type", value)}
                                    />
                                </div>
                            </div>   
                        )}
                          {values && values.type && (
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="trader_category"
                                >Trader Category *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                            type="text"
                                            name="trader_category"
                                            action="dropdown"
                                            value={values.trader_category}
                                            error={errors.trader_category }
                                            items={TypeOfTrader}
                                            onChange={(value) => handleTextChange("trader_category", value)}
                                    />
                                </div>
                            </div>   
                        )}
                        {values && values.trader_category.includes('Other')&&(
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_trader"
                            >Other Trader Category *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Other Trader Category`}
                                    name="other_trader"
                                    id="other_trader"
                                    value={values.other_trader}
                                    error={errors.other_trader}
                                    onChange={(e) => handleTextChange("other_trader", e.target.value)}
                                />
                            </div>
                        </div>  
                        )}
                     {values && values.type && values.trader_category&&values.type_institution.includes('Government') &&(   <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_govt_institutions"
                            >Number of Govt institutions available in the district *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Govt institutions available in the district`}
                                    type="number"
                                    name="no_of_govt_institutions"
                                    id="no_of_govt_institutions"
                                    value={values.no_of_govt_institutions}
                                    error={errors.no_of_govt_institutions}
                                    onChange={(e) => handleTextChange("no_of_govt_institutions", e.target.value)}
                                />
                            </div>
                        </div>)}                        
                        {values && values.type && values.trader_category&&values.type_institution.includes('Private') &&(   <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_private_fbo"
                            >Number of private FBO's available in the district *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of private FBO's available in the district`}
                                    type="number"
                                    name="no_of_private_fbo"
                                    value={values.no_of_private_fbo}
                                    error={errors.no_of_private_fbo}
                                    onChange={(e) =>handleTextChange("no_of_private_fbo", e.target.value)}
                                />
                            </div>
                        </div>  )}             
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFboReports: (data) => dispatch(createFboReports(data)),
    onEditFboReports: (data, record_id) => dispatch(editFboReports(data, record_id)),
    onGetnewWards:(data)=>dispatch(getDistrictWards(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
    traderTypes: state.home.traderTypes,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(FboReport);