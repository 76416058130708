import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const TNDailyDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">TN Food Safety Dept Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Name of the FSO / DO</th>
                 <td className="text-left">{records.name_of_the_fso_do}</td>
                </tr>
              <tr>
                <th>Number of School/College Hostel Inspected</th>
                <td className="text-left">{records.no_of_school_college_hostel_visited}</td>
              </tr>
              <tr>
                <th>Number of School/College Canteen Inspected</th>
                <td className="text-left">{records.no_of_school_college_canteen_visited}</td>
              </tr>
              <tr>
                <th>Total Number of School/College Visited</th>
                <td className="text-left">{records.total_no_of_school_college_visited}</td>
              </tr>
              <tr>
                <th>Number of IEC Given</th>
                <td className="text-left">{records.no_of_iec_given}</td>
              </tr>
              <tr>
                  <th>Number of Notice Issued</th>
                 <td className="text-left">{records.no_of_notice_issued}</td>
                </tr>
                <tr>
                  <th>Number of Amma Unavakam Visited</th>
                   <td className="text-left">{records.no_of_visited_amma_unavakam}</td>
                </tr>
              <tr>
                <th>Number of IEC Issued Amma Unavakam</th>
                <td className="text-left">{records.no_of_iec_issued_amma_unavakam}</td>
              </tr>              
              <tr>
                <th>Number of Notice Issued Ammma Unavakam</th>
                <td className="text-left">{records.no_of_notice_issued_amma_unavakam}</td>
              </tr>
              <tr>
                <th>Number of PDS/Co-Op/TNCSC Godowns shop's Inspected </th>
                <td className="text-left">{records.no_of_ration_shop_visited}</td>
              </tr>
              <tr>
                  <th>Number of IEC Issued to PDS/Co-Op Shop</th>
                 <td className="text-left">{records.no_of_notice_issued_ration_shop}</td>
                </tr>
                <tr>
                  <th>Number of Notice Issued to PDS/Co-Op shop</th>
                   <td className="text-left">{records.no_of_iec_issued_ration_shop}</td>
                </tr>
              <tr>
                <th>Number of Tasmac shops Inspected</th>
                <td className="text-left">{records.no_of_tasmac_visited}</td>
              </tr>              
              <tr>
                <th>Number of Notice Issued Tasmac</th>
                <td className="text-left">{records.no_of_notice_issued_tasmac}</td>
              </tr>
              <tr>
                <th>Number of IEC Issued tasmac</th>
                <td className="text-left">{records.no_of_iec_given_tasmac}</td>
              </tr>
              <tr>
                  <th>Number of Hospital Canteen Visited</th>
                 <td className="text-left">{records.no_of_hospital_canteen_visited}</td>
                </tr>
                <tr>
                  <th>Number of Notice Issued Hospital Canteen</th>
                   <td className="text-left">{records.no_of_notice_issued_hospital_canteen}</td>
                </tr>
              <tr>
                <th>Number of IEC Issued hospital canteen</th>
                <td className="text-left">{records.no_of_iec_given_hospital_canteen}</td>
              </tr>              
              <tr>
                <th>Number of ICDS Visited</th>
                <td className="text-left">{records.no_of_icds_visited}</td>
              </tr>
              <tr>
                <th>Number of Noon Meals Centres Visited</th>
                <td className="text-left">{records.no_of_noon_meals_centres_visited}</td>
              </tr>
              <tr>
                  <th>Total Number of ICDS / Noon meals Centre's visited</th>
                 <td className="text-left">{records.total_no_of_icds_noon_meals_centres_visited}</td>
                </tr>
                <tr>
                  <th>Number Of IEC Issued ICDS / Noon Meals Centre's</th>
                   <td className="text-left">{records.no_of_iec_given_icds_noon_meal_centres}</td>
                </tr>
              <tr>
                <th>Number of Notice Issued ICDS / Noon meals Centre's</th>
                <td className="text-left">{records.no_of_notice_issued_icds_noon_meal_centres}</td>
              </tr>              
              <tr>
                <th>Number of FBO Premises Inspected</th>
                <td className="text-left">{records.no_of_inspected_fbo_premises}</td>
              </tr>
              <tr>
                <th>Number of IEC issued FBO premises</th>
                <td className="text-left">{records.no_of_iec_given_fbo_premises}</td>
              </tr>
              <tr>
                  <th>Number Of Notice Issued FBO Premises</th>
                 <td className="text-left">{records.no_of_notice_issued_fbo_premises}</td>
                </tr>
                <tr>
                  <th>Number Of Complaints Received Upto the date</th>
                   <td className="text-left">{records.no_of_complaints_received_upto_the_date}</td>
                </tr>
              <tr>
                <th>Number Of Complaints Received During the date</th>
                <td className="text-left">{records.no_of_complaints_received_during_the_date}</td>
              </tr>              
              <tr>
                <th>Number Of Complaints Attended</th>
                <td className="text-left">{records.no_of_complaints_attended}</td>
              </tr>
              <tr>
                <th>Number Of Complaints Pending</th>
                <td className="text-left">{records.no_of_complaints_pending}</td>
              </tr>
              <tr>
                  <th>Number Of IEC Meetings Organized </th>
                 <td className="text-left">{records.no_of_iec_meetings_organized}</td>
                </tr>
                <tr>
                  <th>Number Of Consumers Attended</th>
                   <td className="text-left">{records.no_of_consumers_attended}</td>
                </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(TNDailyDetails);