import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import ModelPopup from "../components/ModelPopup";
import { Link } from "react-router-dom";
import { deleteComplaint, getComplaints } from "../components/reducers/actions";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";

const TextField = styled.input`
  height: 45px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #858796;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
    outline: none;
    border: 1px solid #ddd !important;
  }
  &:active,
  &:focus {
    outline: none;
    border: 1px solid #ddd !important;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 45px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => 
  {
  const { t } = useTranslation();
  return(
  <>
    <TextField
      id="search"
      type="text"
      placeholder={`${t("Search")}`}
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </>
);
  }
const ComplaintList = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const { complaints, foodIssues, onComplaints, onDeleteComplaint } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const countPerPage = 10;

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems =
    complaints &&
    complaints.results.filter((item) =>
      Object.keys(item).some(
        (key) =>
          (typeof item[key] === "string" &&
            item[key].toLowerCase().includes(filterText.toLowerCase())) ||
          (typeof item[key] === "number" &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(filterText.toLowerCase()))
      )
    );

  const getComplaintList = async () => {
    await onComplaints({
      is_base64: true,
      page: page,
      per_page: countPerPage,
    });
    setLoading(false);
  };

  useEffect(() => {
    getComplaintList();
    // eslint-disable-next-line
  }, [page]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "82px",
        fontSize: "18px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        minHeight: "72px",
        marginTop: "20px",
        fontWeight: "bold",
        color: "#1589FF",
        fontSize: "18px",
        background: "#e1eef0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: t("Complaint No"),
      cell: (row) => <div>{row.complaint_number}</div>,
      selector: "complaint_number",
      sortable: true,
    },
    {
      name: t("Name"),
      cell: (row) => <div>{row.name_of_restaurant}</div>,
      selector: "name_of_restaurant",
      sortable: true,
    },
    {
      name: t("Complaint"),
      cell: (row) => <div>{getIssueOfFoodName(row.issue_of_food)}</div>,
      selector: "issue_of_food",
      sortable: true,
    },
    // {
    //   name: t('Status'),
    //   cell: row => <div>{
    //     row.status === 'in_progress' ? (
    //       <span key={row.id} style={{ color: 'orange' }}>{t('In Progress')}</span>
    //     ) : [(
    //       row.status === 'closed' ? (
    //         <span key={row.id} style={{ color: 'red' }}>{t('Closed')}</span>
    //       ) : <span key={row.id} style={{ color: 'green' }}>{t('New')}</span>
    //     )]
    //   }</div>,
    //   selector: 'status',
    //   sortable: true,
    // },
    {
      name: t("Current Status"),
      cell: (row) => (
        <div>
          {row.current_status === "closed" ? (
            <span key={row.id} style={{ color: "green" }}>
              {t("Closed")}
            </span>
          ) : (
            [
              row.current_status === "awaiting_your_reply" ? (
                <span key={row.id} style={{ color: "grey" }}>
                  {t("Awaiting Your Reply")}
                </span>
              ) : (
                <span key={row.id} style={{ color: "red" }}>
                  {t("Open")}
                </span>
              ),
            ]
          )}
        </div>
      ),
      selector: "current_status",
      sortable: true,
    },
    {
      name: t("Created At"),
      cell: (row) => (
        <div>
          {moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm A")}
        </div>
      ),
      selector: "created_at",
      sortable: true,
    },
    {
      name: t("Action"),
      cell: (row) => (
        <div className="action__area">
          <Link
            to={{
              pathname: `/app/complaints/${row.id}`,
              state: {
                complaint: row,
              },
            }}
          >
            <Button className="btn btn-info btn-sm" title={t("View Complaint")}>
              {t("View Complaint")}
            </Button>
          </Link>
          {/* <Link to={
          {
            pathname: `/app/complaints/${row.id}/edit/`,
            state: {
              'complaint': row
            }
          }
        }>
          <Button className="btn btn-success btn-circle btn-sm" title={t("Edit Complaint")}>
            <i className="fas fa-edit"></i>
          </Button>
        </Link>
        <Button onClick={() => handleClickDelete(row)} title={t('Delete Complaint')} className="btn btn-danger btn-circle btn-sm">
          <i className="fas fa-trash"></i>
        </Button> */}
        </div>
      ),
    },
  ];

  const getIssueOfFoodName = (issueId) => {
    let issueOfFood = null;
    foodIssues &&
      foodIssues.map((item) => {
        if (issueId && item.id === issueId) {
          issueOfFood = item.name;
        }
        return false;
      });
    return issueOfFood;
  };

  useEffect(() => {
    const handleGetComplaints = async () => {
      onComplaints({
        is_base64: true,
      });
    };
    handleGetComplaints();
    // eslint-disable-next-line
  }, []);

  // const handleClickDelete = (item) => {
  //   setSelectedItem(item);
  //   toggle();
  // }

  const handleDeleteSubmit = async (item) => {
    const resp = await onDeleteComplaint({
      complaint_id: item.id,
    });
    if (resp && resp.data === "success") {
      onComplaints({
        is_base64: true,
      });
      setSelectedItem({});
      setModal(false);
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const toggle = () => setModal(!modal);

  return (
    <div className="container-fluid">
      <div>
        {modal ? (
          <ModelPopup
            modal={modal}
            data={selectedItem}
            handleDeleteSubmit={handleDeleteSubmit}
            title="Complaint"
            toggle={toggle}
          ></ModelPopup>
        ) : null}
      </div>
      <h1 className="h3 mb-4 text-gray-800">{t("Complaints")}</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <Link
            className="btn btn-primary-green pull-right"
            to="/app/complaints/add/"
          >
            {t("+ Add New Complaint")}
          </Link>
        </div>
        {complaints ? (
          <div className="card-body">
            <LoadingOverlay
              active={loading}
              className="secondary__loader"
              spinner
              text="Loading..."
            />
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationServer
              paginationTotalRows={complaints.count}
              paginationPerPage={countPerPage}
              customStyles={customStyles}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              noDataComponent={
                <div style={{ color: "#858796", marginTop: 20 }}>
                  No Complaints available in table
                </div>
              }
              onChangePage={(page) => setPage(page)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onDeleteComplaint: (data) => dispatch(deleteComplaint(data)),
  onComplaints: (data) => dispatch(getComplaints(data)),
});

const mapStateToProps = (state) => ({
  complaints: state.home.complaints,
  foodIssues: state.home.foodIssues,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintList);
