import React from "react";

const SampleReport = (props) => {

    const reportNames = [
       
        {
            label: "Sample Details Report - DO",
            name: "/app/reports/do_sample_reports",
        },
        {
            label: " Damaged Sample Details Report - DO",
            name: "/app/reports/do_damaged_reports",
        },
        {
            label: " Direct Case Report - DO",
            name: "/app/reports/do-direct-reports",
        },
        {
            label: " Direct Case Details Report - DO",
            name: "/app/reports/do-directdetails-reports",
        },
        {
            label: "CaseFile Clarification Report - DO",
            name: "/app/reports/do-clarify-reports",
        },
        {
            label: " Sample Result Appeal Report - DO",
            name: "/app/reports/do-appeal-reports",
        },
        {
            label: " FSO Sample Details Report - FSO",
            name: "/app/reports/fso_sample_reports",
        },
        
        {
            label: " Case Details Report - FSO",
            name: "/app/reports/fso_casedetails_reports",
        },
        {
            label: " Direct Case Report - FSO",
            name: "/app/reports/fso-direct-reports",
        },
        {
            label: "Direct Case Details Report - FSO",
            name: "/app/reports/fso-directdetails-reports",
        },
        {
            label: "Case Details Report(PS) - Commissioner ",
            name: "/app/reports/comissioner-case-reports",
        },
        {
            label: "Criminal Case Report(PS)  - Commisionerate",
            name: "/app/reports/comissionerate-criminal-reports",
        },
        {
            label: " Appeal Report - Commisionerate ",
            name: "/app/reports/comissionerate-appeal-reports",
        },
        {
            label: "Prosecution Sanction Report - Commisionerate ",
            name: "/app/reports/comissionerate-direct-reports",
        },
        {
            label: "Civil Case Report - APP ",
            name: "/app/reports/app-civil-reports",
        },
        {
            label: "Direct Case Report - APP",
            name: "/app/reports/app-direct-reports",
        },
        {
            label: " Sample Analysis Report - LAB",
            name: "/app/reports/lab_sample_reports",
        },
        {
            label: " Sample From Different Sources Report - LAB",
            name: "/app/reports/lab-different-reports",
        },

       
    ]

    const renderCard = (card, index) => {
        return (
            <div className="card-deck box" key={index}>
                <div className="card">
                    <div className="card-body text-center">
                        <button onClick={() => props.history.push(card.name)}
                            className="btn report-card" type="button"> {card.label}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <h1>Sample Reports</h1>
            <div className="grid">{reportNames.map(renderCard)}</div>
        </div>
    )
}

export default SampleReport;
