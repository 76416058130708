import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { downloadEnumerationDataReports, getDistrictWards, getEnumerationDataReports, getFoodCategory } from "../../components/reducers/actions"
import DataTable from "react-data-table-component";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { useSSR } from "react-i18next";
import TextInput from "../../components/inputs/TextInput";
import { CSVLink } from "react-csv";
import MaterialTable from "material-table";

const AppList = (props) => {
  const { data, handleSelectRow } = props;
  return (
    <button onClick={() => handleSelectRow(data)}>
    </button>
  );
};
const ViewButton =(props) =>{
  const { data, handleSelectRow } = props;
  return(
  <button className="btn btn-primary btn-edit" onClick={()=> handleSelectRow(data)} > View
    <i className="fas fa-eye icon-edit"  aria-hidden="true" ></i>
    </button>
  );
};
const EditButton = (props) => {
  const { data, handleEdit, handleSelectRow } = props;
  return (
    <button className="btn btn-primary btn-edit" onClick={() => handleEdit(data)}>
      Edit<i className="fas fa-pencil-alt icon-edit" aria-hidden="true"></i>
    </button>
  );
};

const EnumerationDataList = (props) => {
    const { districts, getEnumerationDataReports,wardsCategory,traderType, user, wards,getdownloadEnumerationDataReports,ongetDist ,onFoodcategory,traderTypes } = props;
    const [values, setValues] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);
    const [page, setPage] = useState(1);
    const [District, setDistrict] = useState({})
    const countPerPage = 10;
    const dref=useRef();
    const [downloadData, setDownloadData]=useState([])
    const [download, setDownload]=useState(false);
    const [filterData, setFilterData] = useState();
    const [foodCategory, setFoodCategory]=useState([]);
    const [duplicateDistrict, setDuplicateDistrict] = useState([]);
    const [duplicateTrader, setDuplicateTrader] = useState([]);
    const reportList = async () => {
      const resp = await getEnumerationDataReports({
        page: page,
        per_page: countPerPage,
      });
      if(resp && resp.response) {
        const data = resp.response
        setValues(data)
      }      
    };
    const getDis=async(data)=>{
      if(user && user.user_types && user.user_types.includes("do")||user && user.user_types && user.user_types.includes("fso")){
        var dat = data
      }
      else dat=data[0]
   await ongetDist({district:dat})
  }
  const Type = [
    {id:"0", name:"All"},
    {id:"0", name:"License"},
    {id:"1", name:"Registration"}
]
const Owner = [
    {id:"0", name:"All"},
    {id:"0", name:"Preparator"},
    {id:"1", name:"Partner"}
]
const TurnOver = [
    {id:"0", name:"All"},
    {id:"0", name:"upto 12Lakh"},
    {id:"1", name:"above 12Lakh to 10Crore"},
    {id:"2", name:"above 20Crore"},
    {id:"3", name:"Central Board/Export & Import of Non sealed Foods"},
]
  useEffect(()=>{
    if(searchFilter.district&&searchFilter.district){
      getDis(searchFilter.district)
    }
  },[searchFilter&&searchFilter.district])
  useEffect(()=>{
    var arr = [...districts]
    arr.unshift({id:'all',name:'All'})
    setDuplicateDistrict(arr)
  },[districts&&districts])
  useEffect(()=>{
    var arr = [...traderTypes]
    arr.unshift({id:'all',name:'All'})
    setDuplicateTrader(arr)
  },[traderTypes&&traderTypes])
    useEffect(() => {
      if (dref && dref.current && download) {
          dref.current.link.click();
          setDownload(false)
      }
    }, [download]);
    const getFood=async()=>{
      const resp = await onFoodcategory()
      console.log(resp)
      if(resp.response){
        let rep=resp.response
        rep.unshift({id:'all',name:'All'})
          setFoodCategory(rep)
      }
   }
  
  useEffect(()=>{
      getFood()
  },[])
    useEffect(() => {
      if((user && user.user_types && user.user_types.includes("do")) || (user && user.user_types && user.user_types.includes("fso"))) {
        setDistrict(user.district);
    }
      reportList();
       // eslint-disable-next-line 
    }, [page]);
    useEffect(()=>{
      wardsCategory.map((o)=>{
          if(o.id == searchFilter.area_code)
          return (
            setSearchFilter({
                  ...searchFilter,
                  area_name: o.ward_name
              })
          )
      })
  },[searchFilter.area_code])
    const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        fontSize: "18px",
        paddingLeft: "30px",
        paddingRight: "30px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        color: "#45b151",
        paddingLeft: "25px",
        paddingRight: "30px",
        fontSize: "16px",
        background: "rgba(70,179,84,.10196078431372549)",
      },
    },
    cells: {
      style: {
        paddingLeft: "25px",
      },
    },
  };

    const getDistrict = (district) => {
      let districtName = null;
      districts &&
        districts.forEach((item) => {
          if (item.id === district) {
            districtName = item.name;
          }
        });
      return districtName;
    };
    const colDetails = [
      {
        title: "District",
        field: "district",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Record Date",
        field: "date",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Area Name",
        field: "area_name",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Area code",
        field: "area_code",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Village",
        field: "name_of_the_village",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Street",
        field: "name_of_the_street",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Operator Partners",
        field: "name_of_the_operator_partners",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Address of Trade",
        field: "address_of_trade",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Contact Number",
        field: "contact_number",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Door no",
        field: "door_no",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Email Id",
        field: "email_id",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Type of Trader Category",
        field: "type_of_trader_category",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Product Category",
        field: "product_category",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Turnover Annum",
        field: "turnover_annum",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Type",
        field: "type",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "License Registration applied Date",
        field: "license_registration_applied_date",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "License Registration Number",
        field: "license_registration_number",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "License Registration Received Date",
        field: "license_registration_received_date",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "License Registration Refernece Number",
        field: "license_registration_refernece_number",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "License Registration valid upto Date",
        field: "license_registration_valid_upto_date",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Registered",
        field: "is_registered",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Registration Reference Number",
        field: "registration_reference_number",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Registration Applied Date",
        field: "registration_applied_date",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },

      {
        title: "Registration Number",
        field: "registration_number",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Registration Received Date",
        field: "registration_received_date",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Registration valid upto",
        field: "registration_valid_upto",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
    ];
    const columns = [
      {
        omit: true,
        cell: (row) => <AppList data={row} handleSelectRow={handleSelectRow} />,
        selector: "id",
        sortable: true,
      },
      {
        name: "District",
        cell: (row) => getDistrict(row.district),
        selector: "district",
        sortable: true
      },

      
      {
        name: "Created At",
        cell: (row) =>
         row.created_at && moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm"),
        selector: "created_at",
        sortable: true,
      },
      {
        name:"Last Edited",
       cell:(row) =>
       row.last_updated_at && row.last_updated_at ? moment(row.last_updated_at.toString()).format("DD-MM-YYYY hh:mm") : '-',
       selector: "Last_edited",
        sortable:true,
      },
      {
        name: "Action",
        cell: (row) => 
        moment().diff(moment(row.created_at.toString()), 'hours') < 24 ? 
        (<><EditButton data={row} handleEdit={handleEdit} /> <ViewButton data={row} handleSelectRow={handleSelectRow} /></>) : <ViewButton data={row} handleSelectRow={handleSelectRow} />,
        sortable: true,
      }
    ];

    const handleTextChange = (key, value) => {
      if(key === 'date') {
        const updatedValues = { ...searchFilter, [key]: moment(value).format() };
        setSearchFilter(updatedValues);
      }
      const updatedValues = { ...searchFilter, [key]: value };
      setSearchFilter(updatedValues);
    }
    const handleDownload =()=>{
      if(searchFilter) {
        async function fetchData() {
          const resp = await getdownloadEnumerationDataReports({
            district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
            fromdate: moment(searchFilter.fromdate||new Date()).format('YYYY-MM-DD'),
            todate:moment(searchFilter.todate||new Date()).format('YYYY-MM-DD'),
            area_code:searchFilter.area_code,
            product_category:searchFilter.product_category&&searchFilter.product_category=='All'?'':searchFilter.product_category,
            type_of_trader_category:searchFilter.type_of_trader_category&&searchFilter.type_of_trader_category=='All'?'':searchFilter.type_of_trader_category,
            turnover_annum:searchFilter.turnover_annum&&searchFilter.turnover_annum=='All'?'':searchFilter.turnover_annum,
            type:searchFilter.type&&searchFilter.type=='All'?'':searchFilter.type,
          });
          if(resp && resp.response) {
            const data = resp.response
            setDownloadData(data)
            setDownload(true)
          }
        }
        fetchData();
      }
    }
    const handleFlick = () => {
      setFilterData();
      if (searchFilter) {
        async function fetchData() {
          const resp = await getEnumerationDataReports({
            district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
            fromdate: moment(searchFilter.fromdate||new Date()).format('YYYY-MM-DD'),
            todate:moment(searchFilter.todate||new Date()).format('YYYY-MM-DD'),
            area_code:searchFilter.area_code,
            product_category:searchFilter.product_category&&searchFilter.product_category=='All'?'':searchFilter.product_category,
            type_of_trader_category:searchFilter.type_of_trader_category&&searchFilter.type_of_trader_category=='All'?'':searchFilter.type_of_trader_category,
            turnover_annum:searchFilter.turnover_annum&&searchFilter.turnover_annum=='All'?'':searchFilter.turnover_annum,
            type:searchFilter.type&&searchFilter.type=='All'?'':searchFilter.type,
  
          });
          if (resp && resp.response) {
            const data = resp.response;
            console.log(data);
            setValues(data.results);
          }
        }
        fetchData();
      }
    };
    const handleClick = () => {
      setValues([])
      if(searchFilter) {
        async function fetchData() {
          const resp = await getdownloadEnumerationDataReports({
            district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
            fromdate: moment(searchFilter.fromdate||new Date()).format('YYYY-MM-DD'),
            todate:moment(searchFilter.todate||new Date()).format('YYYY-MM-DD'),
            area_code:searchFilter.area_code,
            product_category:searchFilter.product_category&&searchFilter.product_category=='All'?'':searchFilter.product_category,
            type_of_trader_category:searchFilter.type_of_trader_category&&searchFilter.type_of_trader_category=='All'?'':searchFilter.type_of_trader_category,
            turnover_annum:searchFilter.turnover_annum&&searchFilter.turnover_annum=='All'?'':searchFilter.turnover_annum,
            type:searchFilter.type&&searchFilter.type=='All'?'':searchFilter.type,
            is_json:true,
          });
          if(resp && resp.response) {
            const data = resp.response
            console.log(data)
            setFilterData(data.data)
          }
        }
        fetchData();
      }
    }

    const handleEdit = (data) => {
      console.log(data);
      props.history.push(`/app/reports/enumeration-data-reports/${data.id}/edit`, { values: data });
    };

    const handleSelectRow = (data) => {
      console.log(data);
      props.history.push(`/app/display-reports/enumeration-data-list/${data.id}`, { records: data });
    };

    return (
        
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Enumeration Data Reports List</h1>
            <div className="main">
                <div className="row" style={{ marginBottom : 20 }}>
                {((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))? (
                <div className="col-sm-2">  <DropDownInput
                      placeholder={` District`}
                      type="text"
                      isDisabled
                      name="district"
                      value={District}
                      items={districts}
                      onChange={(value) => handleTextChange("district", value)}
                    />
                  </div>):(
                      <div className="col-sm-2">
                      <DropDownInput
                        placeholder={` District`}
                        type="text"
                        name="district"
                        style
                        action="district"
                        value={searchFilter.district}
                        items={duplicateDistrict}
                        onChange={(text) => handleTextChange("district", text)}
                      />
                    </div>
                  )}
                  <div className="col-sm-2">
                    <DropDownInput
                      placeholder={`Area Code`}
                      type="text"
                      name="area_code"
                      style
                      action="area_code"
                      value={searchFilter.area_code}
                      items={wardsCategory}
                      onChange={(text) => handleTextChange("area_code", text)}
                    />
                  </div>

                  {/* <div className="col-sm-2">
                    <TextInput
                      placeholder={`Area Name`}
                      type="text"
                      name="area_name"
                      value={searchFilter.area_name}
                      onChange={(e) => handleTextChange("area_name", e.target.value)}
                    />
                  </div> */}
                  
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`From Date`}
                        name="fromdate"
                        value={searchFilter.fromdate}
                        onChangeText={(text) => handleTextChange('fromdate', text)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`To Date`}
                        name="todate"
                        value={searchFilter.todate}
                        onChangeText={(text) => handleTextChange('todate', text)}
                    />
                  </div>
                
                  <div className="col-sm-3">
                    <DropDownInput
                      placeholder={`Trader type`}
                      name="type_of_trader_category"
                      action="dropdown"
                      value={searchFilter.type_of_trader_category}
                      items={duplicateTrader}
                      onChange={(text) => handleTextChange("type_of_trader_category", text)}
                    />
                  </div>
                  <div style={{marginTop:'30px'}} className="col-sm-3">
                    <DropDownInput
                      placeholder={`Food Category`}
                      name="product_category"
                      action="dropdown"
                      value={searchFilter.product_category}
                      items={foodCategory}
                      onChange={(text) => handleTextChange("product_category", text)}
                    />
                  </div>
                  <div style={{marginTop:'30px'}} className="col-sm-3">
                    <DropDownInput
                      placeholder={`Turnover per annum`}
                      name="turnover_annum"
                      action="dropdown"
                      value={searchFilter.turnover_annum}
                      items={TurnOver}
                      onChange={(text) => handleTextChange("turnover_annum", text)}
                    />
                  </div>
                  <div style={{marginTop:'30px'}} className="col-sm-2">
                    <DropDownInput
                      placeholder={`Type`}
                      name="type"
                      action="dropdown"
                      value={searchFilter.type}
                      items={Type}
                      onChange={(text) => handleTextChange("type", text)}
                    />
                  </div>
                  <div
          style={{
            float: "right",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "30px",
            marginRight: "60px",
            marginBottom: "40px",
          }}
        >

                  <div className="col-sm-1">
                    <button
                        type="button"
                        className="btn btn-secondary ml-3"
                        onClick={handleClick}>
                    View</button>
                  </div>
                  <div className="col-sm-6">
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleFlick}
            >
              View & Edit
            </button>
          </div>
          <div className="col-sm-1 ml-md-n5">
            <button
              style={{ background: "#44B150" }}
              type="button"
              className="btn btn-secondary ml-md-n3 ml-1"
              onClick={handleDownload}
            >
              Download
            </button>
                    <CSVLink 
                        data={downloadData}
                        ref={dref}
                        filename={'Enumerationdata.csv'}
                        // target='_blank'
                        
                        />
                  </div>
                  </div>
                </div>
            {values.length>0 ? <DataTable
                  columns={columns}
                  data={values}             
                  onRowClicked={handleSelectRow}
                  pagination
                  // paginationServer
                  paginationPerPage={countPerPage}
                  paginationTotalRows={values.count}
                  customStyles={customStyles}
                  noHeader
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  highlightOnHover
                  noDataComponent={
                    <div style={{ color: "#858796", marginTop: 20 }}>
                      No data available in table
                    </div>
                  }
                  onChangePage={(page) => setPage(page)}
              />:null}
               {filterData ? (
          <MaterialTable
            style={{width:'100%'}}
            columns={colDetails}
            data={filterData}
            options={{
              exportButton: {
       csv: true,
       pdf: false,
    },
              search: true,
              filtering: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
                borderColor: "#fff",
                textAlign: "center",zIndex:0
              },
            }}
            title="Enumeration List"
          />
        ) : null}

            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    getEnumerationDataReports: (data) => dispatch(getEnumerationDataReports(data)),
    getdownloadEnumerationDataReports: (data) => dispatch(downloadEnumerationDataReports(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
    onFoodcategory:(data)=>dispatch(getFoodCategory(data))


});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  wardsCategory:state.home.wardsCategory,
  traderTypes:state.home.traderTypes,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(EnumerationDataList);