import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const RipeningOfMangoesDetails = (props) => {
    const { districts,wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Ripening Of Mangoes Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
              <tr>
                  <th>Type of fruits</th>
                   <td className="text-left">{records.type_of_fruits}</td>
                </tr>
               {records.other_type_of_fruits &&  <tr>
                  <th>Other Type of fruits</th>
                   <td className="text-left">{records.other_type_of_fruits}</td>
                </tr>}
                <tr>
                  <th>Number of Godown's, storage points, FBO Premises etc Inspected</th>
                   <td className="text-left">{records.no_of_godowns_storage_points_shops_inspected}</td>
                </tr>
              <tr>
                <th>Number of Godowns, storage points, shops found with Reipening of Mangoes with Carbide Gas/Stone</th>
                <td className="text-left">{records.no_of_godowns_ripening_mangoes_with_carbide_gas}</td>
              </tr>   
              <tr>
                <th>Sample Type</th>
                <td className="text-left">{records.sample_type}</td>
              </tr>  
              <tr>
                <th>Number Of Sample Lifted</th>
                <td className="text-left">{records.no_of_sample_lifted}</td>
              </tr>           
              <tr>
                <th>Quantity Of Mangoes Seized (in Kg's)</th>
                <td className="text-left">{records.quantity_of_mangoes_seized}</td>
              </tr>
              <tr>
                <th>Approximate value (in RS)</th>
                <td className="text-left">{records.approximate_value}</td>
              </tr>
              <tr>
                <th>Quantity of Carbide gas/ Stone Found and Seized (in Kg's)</th>
                <td className="text-left">{records.quantity_of_carbide_stones_found_and_seized}</td>
              </tr>
              <tr>
                <th>Action Taken</th>
                <td className="text-left">{records.action_taken}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(RipeningOfMangoesDetails);