import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import Checkbox from '../../components/inputs/Checkbox';
import { createSpecialDriveOfLandRReport, editSpecialDriveOfLandRReport, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";

const SpecialDriveOfLandRReport = (props) => {
    const { districts, user,wards,wardsCategory, onCreateSpecialDriveOfLandRReport,ongetDist, onEditSpecialDriveOfLandRReport } = props;
    const [values, setValues] = useState({
        'district':'',
         'date':'',
          'area_code':'',
          'area_name':'',
    });
    const [actionTaken, setActionTaken] = useState([{
        action_taken_to_sensitize_the_fbo_during_month: ''
    }])
    const [drivesToSensitize, setDrivesToSensitize] = useState([{
        date_of_awareness_drive_to_sensitize_consumers: '',
        no_of_consumer_queries_received: '',
        no_of_consumer_queries_action_taken: ''
    }])
    const [campDetails, setCampDetails] = useState([{
      date_of_special_awareness_drive: '',
      no_of_license_applied_during_special_drive: '',
      no_of_license_issued_during_special_drive: '',
      no_of_registration_applied_during_special_drive: '',
      no_of_registration_issued_during_special_drive: ''
    }]);
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            setCampDetails(recordData.camp_details)
            setDrivesToSensitize(recordData.drives_to_sensitize)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        } 
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'area_code' ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {     
        if (props.history.location && props.history.location.state) {
            const data = { ...values, 
                // 'action_taken' : actionTaken, 
                'camp_details': campDetails,
                'drives_to_sensitize' : drivesToSensitize}
            setDisableSubmit(true);
            console.log(data)
            const resp = await onEditSpecialDriveOfLandRReport(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response)            
                props.history.push(`/app/display-reports/special-drive-list/`);
            };
        } else {  
            const data = { ...values, 
                // 'action_taken' : actionTaken, 
                'camp_details': campDetails,
                'drives_to_sensitize' : drivesToSensitize}
            setDisableSubmit(true);
            console.log(data)
            const resp = await onCreateSpecialDriveOfLandRReport(data);
            if (resp && resp.response) {
                console.log(resp.response)            
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }
    
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const campList = [...campDetails];
        campList[index][name] = value;
        setCampDetails(campList);
    };

    const handleActionChange = (e, index) => {
        const { name, value } = e.target;
        const actionList = [...actionTaken];
        actionList[index][name] = value;
        setActionTaken(actionList);
    };

    const handleDrivesChange = (e, index) => {
        const { name, value } = e.target;
        const drivesList = [...drivesToSensitize];
        drivesList[index][name] = value;
        setDrivesToSensitize(drivesList);
    };

    const handleDrivesRemove = index => {
        const drivesList = [...drivesToSensitize];
        drivesList.splice(index, 1);
        setDrivesToSensitize(drivesList);
    };

    const handleRemoveClick = index => {
        const campList = [...campDetails];
        campList.splice(index, 1);
        setCampDetails(campList);
    };

    const handleActionRemoveClick = index => {
        const actionList = [...actionTaken];
        actionList.splice(index, 1);
        setActionTaken(actionList);
    };

    const handleDrivesAddClick = () => {
        setDrivesToSensitize([
        ...drivesToSensitize,
        {
            date_of_awareness_drive_to_sensitize_consumers: '',
            no_of_consumer_queries_received: '',
            no_of_consumer_queries_action_taken: ''
        }
        ]);
    };

    const handleActionAddClick = () => {
        setActionTaken([
        ...actionTaken,
        {
            action_taken_to_sensitize_the_fbo_during_month: ''
        }
        ]);
    };

    const handleAddClick = () => {
        setCampDetails([
        ...campDetails,
        {
            date_of_special_awareness_drive: '',
            no_of_license_applied_during_special_drive: '',
            no_of_license_issued_during_special_drive: '',
            no_of_registration_applied_during_special_drive: '',
            no_of_registration_issued_during_special_drive: ''
        }
        ]);
    };

  return (
    <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Special Drive of L and R Report</h1>
        <div className="card shadow mb-4">
            <div className="card-body">
                <table className="table table-bordered">
                    <tbody>
                    {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                    <tr className="col-sm-12">
                        <th colSpan="2">District : </th>
                        <td colSpan="4" className="input-label">
                            <DropDownInput
                                type="text" isDisabled
                                name="district"
                                value={values.district}
                                error={errors.district}
                                items={districts}
                                onChange={(value) => handleTextChange("district", value)}
                            />
                        </td>
                    </tr>

                    ) : (
                    <tr>
                        <th colSpan="2">District : </th>
                        <td colSpan="4" className="input-label">
                            <DropDownInput
                                placeholder={`District`}
                                type="text"
                                name="district"
                                value={values.district}
                                error={errors.district}
                                items={districts}
                                onChange={(text) => handleTextChange("district", text)}
                            />
                        </td>
                    </tr>
                    )}
                    <tr>
                        <th colSpan="2">Date *: </th>
                        <td colSpan="4" className="input-label">
                            <Datepicker
                                placeholder={`Date`}
                                name="date"
                                id="date"
                                value={values.date}
                                error={errors.date}
                                onChangeText={(text) => handleTextChange('date', text)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2">Area code *: </th>
                        <td colSpan="4" className="input-label">
                           
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="2">Area Name *: </th>
                        <td colSpan="4" className="input-label">
                            <TextInput
                                type="text"
                                placeholder="Area Name"
                                name="area_name"
                                value={values.area_name}
                                error={errors.area_name}
                                onChange={(e) => handleTextChange("area_name", e.target.value)}
                            />
                        </td>
                    </tr>
                    {/* <tr>
                        <th colSpan="2">Month : </th>
                        <td colSpan="4" className="input-label">
                            <Datepicker
                                placeholder={`Month`}
                                name="month"
                                showMonthYearPicker
                                id="month"
                                value={values.month}
                                error={errors.month}
                                onChangeText={(text) => handleTextChange('month', text)}
                            />
                        </td>
                    </tr> */}
                    {/* <tr>
                        <th colSpan="6"> Actions taken to sensitize the FBO during month : </th>
                    </tr>
                    {actionTaken.map((x, i) => {
                        return (
                            <tr>
                            <td colSpan="6">
                                <input className="form-control input-label margin-class"
                                    type="text"
                                    name="action_taken_to_sensitize_the_fbo_during_month"
                                    placeholder="Action taken to sensitize the FBO during month"
                                    value={x.action_taken_to_sensitize_the_fbo_during_month}
                                    onChange={e => handleActionChange(e, i)}
                                />
                                {i !== actionTaken.length - 1 && (
                                    <button className="btn btn-warning btn-sm btn-circle" 
                                    onClick={() => handleActionRemoveClick(i)}>
                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                    </button>
                                )}
                                {actionTaken.length - 1 === i && (
                                    <button className="btn btn-success btn-sm btn-circle" onClick={handleActionAddClick}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                )}
                            </td>
                        </tr>
                        );
                    })}                     */}
                    <tr>
                        <th>Date of special awareness drive *</th>
                        <th >Number of license applied during special drive *</th>
                        <th >Number of license issued during special drive *</th>
                        <th >Number of registration applied during special drive *</th>
                        <th colSpan="2" >Number of registration issued during special drive *</th>
                    </tr>
		                {campDetails.map((x, i) => {
                            return (
                                <tr>
                                    <td>
                                    <input className="form-control input-label" type="date"
                                        placeholder={`Date of Special awareness drive`}
                                        name="date_of_special_awareness_drive"
                                        value={x.date_of_special_awareness_drive}
                                        error={errors.date_of_special_awareness_drive}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                    <td>
                                    <input className="form-control input-label"
                                        type="number"
                                        name="no_of_license_applied_during_special_drive"
                                        placeholder="Number of license applied during special drive"
                                        value={x.no_of_license_applied_during_special_drive}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                    <td>
                                    <input className="form-control input-label"
                                        type="number"
                                        name="no_of_license_issued_during_special_drive"
                                        placeholder="Number of license issued during special drive"
                                        value={x.no_of_license_issued_during_special_drive}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                    <td>
                                    <input className="form-control input-label"
                                        type="number"
                                        name="no_of_registration_applied_during_special_drive"
                                        placeholder="Number of registration applied during special drive"
                                        value={x.no_of_registration_applied_during_special_drive}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                    <td colSpan="2">
                                        <input className="form-control input-label margin-class"
                                            type="number"
                                            name="no_of_registration_issued_during_special_drive"
                                            placeholder="Number of registration issued during special drive"
                                            value={x.no_of_registration_issued_during_special_drive}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                        {i !== campDetails.length - 1 && (
                                        <button className="btn btn-warning btn-sm btn-circle" onClick={() => handleRemoveClick(i)}>
                                            <i className="fa fa-minus" aria-hidden="true"></i>
                                        </button>
                                        )}
                                        {campDetails.length - 1 === i && (
                                        <button className="btn btn-success btn-sm btn-circle" onClick={handleAddClick}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <th >Date of sensitize</th>
                            <th colSpan="2" >Number of queries received from FBO's</th>
                            <th colSpan="3" >Number of FBO's queries action taken </th>
                        </tr>
		                {drivesToSensitize.map((x, i) => {
                            return (
                                <tr>
                                    <td>
                                    <input type="date"
                                        className="form-control input-label"
                                        placeholder={`Date of awareness drive to sensitize consumers`}
                                        name="date_of_awareness_drive_to_sensitize_consumers"
                                        value={x.date_of_awareness_drive_to_sensitize_consumers}
                                        error={errors.date_of_awareness_drive_to_sensitize_consumers}
                                        onChange={e => handleDrivesChange(e, i)}
                                    />
                                    </td>
                                    <td colSpan="2">
                                    <input className="form-control input-label"
                                        type="number"
                                        name="no_of_consumer_queries_received"
                                        placeholder="Number of consumer queries received"
                                        value={x.no_of_consumer_queries_received}
                                        onChange={e => handleDrivesChange(e, i)}
                                    />
                                    </td>
                                    <td colSpan="3">
                                        <input className="form-control input-label margin-class"
                                            type="number"
                                            name="no_of_consumer_queries_action_taken"
                                            placeholder="Number of consumer queries action taken"
                                            value={x.no_of_consumer_queries_action_taken}
                                            onChange={e => handleDrivesChange(e, i)}
                                        />
                                        {i !== drivesToSensitize.length - 1 && (
                                        <button className="btn btn-warning btn-sm btn-circle" onClick={() => handleDrivesRemove(i)}>
                                            <i className="fa fa-minus" aria-hidden="true"></i>
                                        </button>
                                        )}
                                        {drivesToSensitize.length - 1 === i && (
                                        <button className="btn btn-success btn-sm btn-circle" onClick={handleDrivesAddClick}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        {/* <tr>
                            <th className="col-sm-2">Category</th>
                            <th className="col-sm-2">Number of defaulting FBO's identified</th>
                            <th className="col-sm-2">Action initiated for number of defaulting FBO's</th>
                            <th className="col-sm-2">Number of cases launched</th>
                            <th className="col-sm-2">Number of convictions imposed</th>
                            <th className="col-sm-2">Penalty Amount raised from FBO's (in Rs.)</th>
                        </tr> */}
                        {/* <tr>
                            <th className="col-sm-2">FBO's who have obtained Regn.Certificated despite being eligible for License</th>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of FBO's obtained reg certificate despite being eligible for license identified`}
                                    type="number"
                                    name="no_of_fbos_obtained_reg_certificate_despite_license_identified"
                                    value={values.no_of_fbos_obtained_reg_certificate_despite_license_identified}
                                    error={errors.no_of_fbos_obtained_reg_certificate_despite_license_identified}
                                    onChange={(e) => handleTextChange("no_of_fbos_obtained_reg_certificate_despite_license_identified", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Action initiated for number of FBO's obtained registration certificate despite being eligible for license`}
                                    type="number"
                                    name="action_initiated_fbos_obtained_reg_certificate_license"
                                    value={values.action_initiated_fbos_obtained_reg_certificate_license}
                                    error={errors.action_initiated_fbos_obtained_reg_certificate_license}
                                    onChange={(e) => handleTextChange("action_initiated_fbos_obtained_reg_certificate_license", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of cases launched for FBO's who obtained registration certificate`}
                                    type="number"
                                    name="no_of_cases_fbos_obtained_reg_certificate"
                                    value={values.no_of_cases_fbos_obtained_reg_certificate}
                                    error={errors.no_of_cases_fbos_obtained_reg_certificate}
                                    onChange={(e) => handleTextChange("no_of_cases_fbos_obtained_reg_certificate", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of convictions imposed for FBO's who obtained registration certificate`}
                                    type="number"
                                    name="no_of_convictions_fbos_obtained_reg_certificate"
                                    value={values.no_of_convictions_fbos_obtained_reg_certificate}
                                    error={errors.no_of_convictions_fbos_obtained_reg_certificate}
                                    onChange={(e) => handleTextChange("no_of_convictions_fbos_obtained_reg_certificate", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Amount raised from FBO's obtained registration certificate despite being eligible for License (in Rs.)`}
                                    type="number"
                                    name="amount_raised_fbos_for_obtained_reg_certificate"
                                    value={values.amount_raised_fbos_for_obtained_reg_certificate}
                                    error={errors.amount_raised_fbos_for_obtained_reg_certificate}
                                    onChange={(e) => handleTextChange("amount_raised_fbos_for_obtained_reg_certificate", e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="col-sm-2">FBO's carrying businesses with expired License/without License</th>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of FBO's carrying business with expired license identified`}
                                    type="number"
                                    name="no_of_fbos_carrying_business_with_expired_license_identified"
                                    value={values.no_of_fbos_carrying_business_with_expired_license_identified}
                                    error={errors.no_of_fbos_carrying_business_with_expired_license_identified}
                                    onChange={(e) => handleTextChange("no_of_fbos_carrying_business_with_expired_license_identified", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Action initiated for number of FBO's carrying business with expired license`}
                                    type="number"
                                    name="action_initiated_fbos_carrying_business_with_expired_license"
                                    value={values.action_initiated_fbos_carrying_business_with_expired_license}
                                    error={errors.action_initiated_fbos_carrying_business_with_expired_license}
                                    onChange={(e) => handleTextChange("action_initiated_fbos_carrying_business_with_expired_license", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of cases launched for FBO's carrying business with expired license`}
                                    type="number"
                                    name="no_of_cases_fbos_carrying_business_with_expired_license"
                                    value={values.no_of_cases_fbos_carrying_business_with_expired_license}
                                    error={errors.no_of_cases_fbos_carrying_business_with_expired_license}
                                    onChange={(e) => handleTextChange("no_of_cases_fbos_carrying_business_with_expired_license", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of convictions imposed for FBO's carrying business with expired license`}
                                    type="number"
                                    name="no_of_convictions_fbos_carrying_business_with_expired_license"
                                    value={values.no_of_convictions_fbos_carrying_business_with_expired_license}
                                    error={errors.no_of_convictions_fbos_carrying_business_with_expired_license}
                                    onChange={(e) => handleTextChange("no_of_convictions_fbos_carrying_business_with_expired_license", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Amount raised from FBO's carrying business with expired license identified (in Rs.)`}
                                    type="number"
                                    name="amount_raised_fbos_for_carrying_business_with_expired_license"
                                    value={values.amount_raised_fbos_for_carrying_business_with_expired_license}
                                    error={errors.amount_raised_fbos_for_carrying_business_with_expired_license}
                                    onChange={(e) => handleTextChange("amount_raised_fbos_for_carrying_business_with_expired_license", e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="col-sm-2">FBO's who have obtained fresh License/registration instead of renewing the previous one to avoid penalty</th>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of FBO's obtained fresh license instead of renewal`}
                                    type="number"
                                    name="no_of_fbos_obtained_fresh_license_instead_of_renewal_identified"
                                    value={values.no_of_fbos_obtained_fresh_license_instead_of_renewal_identified}
                                    error={errors.no_of_fbos_obtained_fresh_license_instead_of_renewal_identified}
                                    onChange={(e) => handleTextChange("no_of_fbos_obtained_fresh_license_instead_of_renewal_identified", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Action initiated for number of FBO's obtained fresh license instead of renewal`}
                                    type="number"
                                    name="action_initiated_fbos_obtained_fresh_license_instead_of_renewal"
                                    value={values.action_initiated_fbos_obtained_fresh_license_instead_of_renewal}
                                    error={errors.action_initiated_fbos_obtained_fresh_license_instead_of_renewal}
                                    onChange={(e) => handleTextChange("action_initiated_fbos_obtained_fresh_license_instead_of_renewal", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of cases launched for FBO's obtained fresh license instead of renewal`}
                                    type="number"
                                    name="no_of_cases_fbos_obtained_fresh_license_renewal"
                                    value={values.no_of_cases_fbos_obtained_fresh_license_renewal}
                                    error={errors.no_of_cases_fbos_obtained_fresh_license_renewal}
                                    onChange={(e) => handleTextChange("no_of_cases_fbos_obtained_fresh_license_renewal", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of convictions imposed for FBO's obtained fresh license instead of renewal`}
                                    type="number"
                                    name="no_of_convictions_fbos_obtained_fresh_license_renewal"
                                    value={values.no_of_convictions_fbos_obtained_fresh_license_renewal}
                                    error={errors.no_of_convictions_fbos_obtained_fresh_license_renewal}
                                    onChange={(e) => handleTextChange("no_of_convictions_fbos_obtained_fresh_license_renewal", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Amount raised from FBO's obtained fresh license instead of renewal (in Rs.)`}
                                    type="number"
                                    name="amount_raised_fbos_for_obtained_fresh_license_renewal"
                                    value={values.amount_raised_fbos_for_obtained_fresh_license_renewal}
                                    error={errors.amount_raised_fbos_for_obtained_fresh_license_renewal}
                                    onChange={(e) => handleTextChange("amount_raised_fbos_for_obtained_fresh_license_renewal", e.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="col-sm-2">FBO's who are manufacturing/processing food products which are endorsed on their License</th>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of FBO's processing food products which are endorsed on their license identified`}
                                    type="number"
                                    name="no_of_fbos_processing_food_products_license_identified"
                                    value={values.no_of_fbos_processing_food_products_license_identified}
                                    error={errors.no_of_fbos_processing_food_products_license_identified}
                                    onChange={(e) => handleTextChange("no_of_fbos_processing_food_products_license_identified", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Action initiated for number of FBO's processing food products which are endorsed on their license`}
                                    type="number"
                                    name="action_initiated_fbos_processing_food_products_license"
                                    value={values.action_initiated_fbos_processing_food_products_license}
                                    error={errors.action_initiated_fbos_processing_food_products_license}
                                    onChange={(e) => handleTextChange("action_initiated_fbos_processing_food_products_license", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of cases launched for FBO's for processing food products which are endorsed on their license`}
                                    type="number"
                                    name="no_of_cases_fbos_processing_food_products_license"
                                    value={values.no_of_cases_fbos_processing_food_products_license}
                                    error={errors.no_of_cases_fbos_processing_food_products_license}
                                    onChange={(e) => handleTextChange("no_of_cases_fbos_processing_food_products_license", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Number of convictions imposed for FBO's for processing food products which are endorsed on their license`}
                                    type="number"
                                    name="no_of_convictions_fbos_processing_food_products_license"
                                    value={values.no_of_convictions_fbos_processing_food_products_license}
                                    error={errors.no_of_convictions_fbos_processing_food_products_license}
                                    onChange={(e) => handleTextChange("no_of_convictions_fbos_processing_food_products_license", e.target.value)}
                                />
                            </td>
                            <td className="col-sm-2">
                                <TextInput
                                    placeholder={`Amount raised from FBO's for processing food products which are endorsed on their license (in Rs.)`}
                                    type="number"
                                    name="amount_raised_fbos_for_processing_food_products_license"
                                    value={values.amount_raised_fbos_for_processing_food_products_license}
                                    error={errors.amount_raised_fbos_for_processing_food_products_license}
                                    onChange={(e) => handleTextChange("amount_raised_fbos_for_processing_food_products_license", e.target.value)}
                                />
                            </td>
                        </tr> */}
                    {/* <tr>
                        <th colSpan="6">As per clause 2.1.3.4 (iii) (f) of FSS Rules, 
                            the FSO's must maintain a database of all FBOs within the area assigned to them </th>
                    </tr>
                    <tr>
                        <td colSpan="6">
                        <Checkbox
                            placeholder="Instructions in this regard have been issued to FSO's to maintain data and submit a monthly report"
                            name="instructions_have_been_issued_to_fbos_to_maintain_data"
                            value={values.instructions_have_been_issued_to_fbos_to_maintain_data || false}
                            error={errors.instructions_have_been_issued_to_fbos_to_maintain_data}
                            onChange={(text) => handleTextChange("instructions_have_been_issued_to_fbos_to_maintain_data", text)}
                        />
                        </td>
                    </tr>
                    <tr>
                        <th colSpan="6">Details of FSOs maintaining database of the food businesses in their area </th>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <TextInput
                                placeholder={`Total number of FSO's in position`}
                                type="number"
                                name="total_no_of_fsos_in_position"
                                value={values.total_no_of_fsos_in_position}
                                error={errors.total_no_of_fsos_in_position}
                                onChange={(e) => handleTextChange("total_no_of_fsos_in_position", e.target.value)}
                            />
                        </td>
                        <td colSpan="3">
                            <TextInput
                                placeholder={`Number of FSO's maintaining database and submitting monthly reports`}
                                type="number"
                                name="no_of_fsos_maintaining_database_and_submitting_monthly_reports"
                                value={values.no_of_fsos_maintaining_database_and_submitting_monthly_reports}
                                error={errors.no_of_fsos_maintaining_database_and_submitting_monthly_reports}
                                onChange={(e) => handleTextChange("no_of_fsos_maintaining_database_and_submitting_monthly_reports", e.target.value)}
                            />
                        </td>
                    </tr> */}
                    </tbody>
                    </table>
                    <button
                        onClick={() => props.history.push("/app/reports/")}
                        className="btn btn-success"
                        type="button">
                    Cancel</button>
                    <button
                        disabled={disableSubmit}
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-success ml-3">
                    Submit</button>
            </div>
        </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
    onCreateSpecialDriveOfLandRReport: (data) => dispatch(createSpecialDriveOfLandRReport(data)),
    onEditSpecialDriveOfLandRReport: (data, record_id) => dispatch(editSpecialDriveOfLandRReport(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(SpecialDriveOfLandRReport);