import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createUCOReports, editUCOReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const UCOReport = (props) => {
    const { districts, user, wards,onCreateUCOReports,ongetDist, wardsCategory,onEditUCOReports } = props;
    const [values, setValues] = useState({
        'district': '',
        'name_of_aggregator': '',
        'address_of_aggregator':'',
        'date_of_collection': '',
        'contact_number':'',
        'email':'',
        'quantity_collected':'',
        'date_of_uco_sent':"",

    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if (user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
              'name_of_aggregator',
        'date_of_collection',
        'address_of_aggregator',
        'quantity_collected',
      ,'contact_number','email',
      'date_of_uco_sent'
];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if (!values[key] || values[key] === '') {
                updatedErrors[key] = ' ';
            }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values }
            const resp = await onEditUCOReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/ruco-biodiesel-list/`);
            };

        } else {
            const data = { ...values }
            setDisableSubmit(true);
            const resp = await onCreateUCOReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">UCO Aggregators Collection of data Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                       
                    

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_aggregator"
                            >Name Of the Aggregator Agency *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Aggregator Agency`}
                                    type="text"
                                    name="name_of_aggregator"
                                    value={values.name_of_aggregator}
                                    error={errors.name_of_aggregator}
                                    onChange={(e) => handleTextChange("name_of_aggregator", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_aggregator"
                            >Address Of the Aggregator Agency *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Aggregator Agency`}
                                    type="text"
                                    name="address_of_aggregator"
                                    value={values.address_of_aggregator}
                                    error={errors.address_of_aggregator}
                                    onChange={(e) => handleTextChange("address_of_aggregator", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="contact_number"
                            >Contact Number Of Aggregator Agency *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Contact Number of Aggregator Agency`}
                                    type="number"
                                    name="contact_number"
                                    value={values.contact_number}
                                    error={errors.contact_number}
                                    onChange={(e) => handleTextChange("contact_number", e.target.value)}
                                />
                            </div>
                        </div> <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="email"
                            >Email ID Of the Aggregator Agency *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Email ID of the Aggregator Agency`}
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    error={errors.email}
                                    onChange={(e) => handleTextChange("email", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_collection"
                            >Date Of Collection of UCO *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of Collection of UCO`}
                                    name="date_of_collection"
                                    id="date_of_collection"
                                    value={values.date_of_collection }
                                    error={errors.date_of_collection}
                                    onChangeText={(text) => handleTextChange('date_of_collection', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="quantity_collected"
                            >Quantity Collected in Kilolitres *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Quantity Collected in KiloLitres`}
                                    type="number"
                                    name="quantity_collected"
                                    value={values.quantity_collected}
                                    error={errors.quantity_collected}
                                    onChange={(e) => handleTextChange("quantity_collected", e.target.value)}
                                />
                            </div>
                        </div>
                      
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_uco_sent"
                            >Date of UCO Sent to Biodiesel Company *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of UCO Sent to Biodiesel Company`}
                                    name="date_of_uco_sent"
                                    id="date_of_uco_sent"
                                    value={values.date_of_uco_sent }
                                    error={errors.date_of_uco_sent}
                                    onChangeText={(text) => handleTextChange('date_of_uco_sent', text)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateUCOReports: (data) => dispatch(createUCOReports(data)),
    onEditUCOReports: (data, record_id) => dispatch(editUCOReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),


});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wardsCategory:state.home.wardsCategory,

});

export default connect(mapStateToProps, mapDispatchToProps)(UCOReport);