import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { userDirectory } from "../../components/reducers/actions";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const PIO = (props) => {
  const { onUserDirectory, user } = props;
  const[users, setUsers]=useState();
  const { t, i18n } = useTranslation();

  
 
  
  return (
    <div style={{marginBottom:'150px'}}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">
                {t("Food Safety Department -PIO's")}
              </h1>
             
            </div>
          </div>
        </div>
      </header>
      <div style={{width:'100%', margin:'0 auto'}}>
      <div style={{fontSize:'14px',marginTop:20,marginLeft:100}}>
       {localStorage.i18nextLng === 'en' ? (<>
                <div>
                <h6 style={{textDecoration:'underline'}}>Public Information Officer</h6>
                <div>
                  <p>
                  044-24310046,<br/>commrfssatn@gmail.com,<br />
                  No. 359, Anna Salai,<br /> 5th Floor, DMS Campus,<br /> Teynampet, Chennai-6
                  </p>
                  </div>
                </div>
                <div>
                <h6 style={{textDecoration:'underline'}}>Appellate Authority</h6>
                <div>
                  <p>
                  044-24310046,<br/>commrfssatn@gmail.com,<br />
                  No. 359, Anna Salai,<br /> 5th Floor, DMS Campus,<br /> Teynampet, Chennai-6
                  </p>
                  </div>
                  
                </div></>):(<>
                <div>
                <h6 style={{textDecoration:'underline'}}>பொதுத்தகவல் அலுவலர் </h6>
                <div>
                  <p>
                  044-24310046,<br/>commrfssatn@gmail.com,<br />
                  No. 359, அண்ணா சாலை,<br /> DMS வளாகம்,<br /> தேனாம்பேட்டை, சென்னை.-6
                  </p>
                  </div>
                </div>
                <div>
                <h6 style={{textDecoration:'underline'}}>துணை இயக்குனர் நிலை II</h6>
                <div>
                  <p>
                  044-24310046,<br/>commrfssatn@gmail.com,<br />
                  No. 359, அண்ணா சாலை,<br /> DMS வளாகம்,<br /> தேனாம்பேட்டை, சென்னை.-6
                  </p>
                  </div>
                </div></>)}
 </div>
 </div>
 <div className='directory_links'>
     
      <Link to="/appellate-tribunal-pio">{t("Click here for list of District PIO officer's details")}</Link><br/>
      <Link to="/lab-pio">{t("Click here for list of lab PIO officer's details")}</Link><br/>
    </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUserDirectory: (data) => dispatch(userDirectory(data)),
});

const mapStateToProps = (state) => ({
  user:state.home.user
});
export default connect(mapStateToProps, mapDispatchToProps)(PIO);
