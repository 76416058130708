import React from "react";
import {
  getFilterData,
  getFilterDataWithout,
  getFsoOfficers,
  getMasterSuccess,
  userDirectory,
} from "../../components/reducers/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import Header from "../../components/Header";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { date } from "yup";
import Datepicker from "../../components/inputs/Datepicker";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextInput from "../../components/inputs/TextInput";
import moment from "moment";

const ComplaintFilterList = (props) => {
  const { t, i18n } = useTranslation();
  const {
    ongetFilterData,
    traderTypes,
    ongetFso,
    foodIssues,
    districts,
    foodTypes,
    fsoOfficers,
    user,
    ongetFilterDataWithout,
    onPutMasterData,
    wardsCategory,
    foodConditions,
    wards,
  } = props;
  const [users, setUsers] = useState();
  const [errors, setErrors] = useState({});
  const [filt, setFilt] = useState();
  const [duplicatetrad, setDuplicatetrad] = useState([]);
  const [duplicatenature, setDuplicateNature] = useState([]);
  const [duplicateissue, setDuplicateIssue] = useState([]);
  const [duplicatefso, setDuplicatefso] = useState([]);
  const [duplicateDistrict, setDuplicateDistrict] = useState([]);
  const [values, setValues] = useState({
    fromdate: new Date(),
    todate: new Date(),
    type_of_trader: null,
    nature_of_food: null,
    district: null,
    zipcode: null,
    name_of_restaurant: null,
    issue_of_food: null,
    fso: null,
    return_type: null,
    status:null,
  });
const Status = [
{id:'0', name:'All'},
{id:'0', name:'Waiting to Allocate'},
{id:'1', name:`Waiting for do's approval`},
{id:'2',name:'Inprogress'},
{id:'3',name:'Closed'},
]
  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
  };
  const handleSubmit = async (event) => {
    const data = { ...values };
    data.district=data.district&&data.district=='all'?null:data.district
    data.type_of_trader=data?.type_of_trader&&data.type_of_trader=='all'?null:data.type_of_trader
    data.issue_of_food=data?.issue_of_food&&data.issue_of_food=='all'?null:data.issue_of_food
    data.nature_of_food=data?.nature_of_food&&data.nature_of_food=='all'?null:data.nature_of_food
    data.status=data?.status&&data.status=='All'?null:data.status
    data.fso=data?.fso&&data.fso=='all'?null:data.fso
    data.fromdate = moment(data.fromdate||new Date()).format("YYYY-MM-DD")
    data.todate = moment(data.todate||new Date()).format("YYYY-MM-DD")
    console.log(data)
    if(user &&  user.user_types && user.user_types.includes("ho")){
      const resp = await ongetFilterData(data);
      if (resp && resp.response) {
        console.log(resp.response);
        setUsers(resp.response.data)
      }
    }
    else{
      const resp = await ongetFilterDataWithout(data);
      if (resp && resp.response) {
        console.log(resp.response);
        setUsers(resp.response.data)
      }
    }
  
  };
const getOfficer=async(id)=>{
  const data={district_id:id}
 const resp = await ongetFso(data)
 console.log('resp', resp.response)
 let d1={'fso':{id: 'all', name: 'All',food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'},id:'all',name:'All'}
   var arr = resp.response
   console.log(arr)
   var arry=arr.unshift(d1)
   console.log(arry)
   setDuplicatefso(arr)
 
}
  useEffect(()=>{
    if(values.district){
      getOfficer(values.district)
    }
  },[values.district])

  useEffect(()=>{
    var arr = [...districts]
    arr.unshift({id:'all',name:'All'})
    setDuplicateDistrict(arr)
  },[districts&&districts])
  useEffect(()=>{
    let d1={id: 'all', name: 'All', food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'}
    var arr = [...foodTypes]
    arr.unshift(d1)
    setDuplicateNature(arr)
  },[foodTypes&&foodTypes])
  useEffect(()=>{
    let d1={id: 'all', name: 'All', food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'}
    var arr = [...traderTypes]
    arr.unshift(d1)
    setDuplicatetrad(arr)
  },[traderTypes&&traderTypes])
  useEffect(()=>{
    let d1={id: 'all', name: 'All', food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'}
    var arr = [...foodIssues]
    arr.unshift(d1)
    setDuplicateIssue(arr)
  },[foodIssues&&foodIssues])
  // useEffect(()=>{
  //   let d1={'fso':{id: 'all', name: 'All',food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'},id:'all',name:'All'}
  //   var arr = [fsoOfficers]||[]
  //   arr.unshift(d1)
  //   setDuplicatefso(arr)
  // },[fsoOfficers&&fsoOfficers])
  useEffect(() => {
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
      const userData = { ...values, district: user.district };
      setValues(userData);
  }
  
  if(user && user.user_types && user.user_types.includes("fso")){
    const userData = { ...values, district: user.district, fso: user.id };
    setValues(userData);
  }
      const getfs = async () => {
      await ongetFso();
    };
    getfs();
  }, []);

const colDetails = user &&  user.user_types && user.user_types.includes("ho") ? [
  {
    title:"Complaint Number",
    field:'complaint_number' ,
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Created Date",
    field: "created_at",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
   {
    title: "Consumer Name",
    field: "created_by__name",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Consumer Phone Number",
    field: "created_by__phone_number",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Type of Trader",
    field: "type_of_trader__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Name of restaurant",
    field: "name_of_restaurant",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Address of Premesis",
    field: "sub_division",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Area Name",
    field: "sub_division1",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Nature of Food",
    field: "nature_of_food__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Issue of Food",
    field: "issue_of_food__name",
    filtering: false,

    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Purchased On",
    field: "product_purchased_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Used On",
    field: "product_used_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Audio Attached(If Any)",
    field: "voice_note",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },

  {
    title: "Complaint Status",
    field: "status",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Assigned To",
    field: "assigned_to__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "FSO Contact Number",
    field: "assigned_to__phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Name",
    field: "do_name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Conatct Number",
    field: "do_phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Zipcode",
    field: "zipcode",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "District",
    field: "district__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
]:
[
  {
    title:"Complaint Number",
    field:'complaint_number' ,
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Created Date",
    field: "created_at",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Type of Trader",
    field: "type_of_trader__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Name of restaurant",
    field: "name_of_restaurant",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Address of Premesis",
    field: "sub_division",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Area Name",
    field: "sub_division1",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Nature of Food",
    field: "nature_of_food__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Issue of Food",
    field: "issue_of_food__name",
    filtering: false,

    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Purchased On",
    field: "product_purchased_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Used On",
    field: "product_used_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Audio Attached(If Any)",
    field: "voice_note",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },

  {
    title: "Complaint Status",
    field: "status",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Assigned To",
    field: "assigned_to__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "FSO Contact Number",
    field: "assigned_to__phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Name",
    field: "do_name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Conatct Number",
    field: "do_phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Zipcode",
    field: "zipcode",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "District",
    field: "district__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },

  

  
]

  return (
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}>Master Complaint Details</h3>
        <div
          className="filter_container"
        >
          <div >
            <label htmlFor="date">From Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`From Date`}
                name="fromdate"
                id="fromdate"
                value={values.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextChange("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">To Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={values.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextChange("todate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="area_code"> Type of Trader: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text"
                name="type_of_trader"
                action="type_of_trader"
                value={values.type_of_trader}
                error={errors.type_of_trader}
                items={duplicatetrad}
                onChange={(value) => handleTextChange("type_of_trader", value)}
              />
            </div>
          </div>
          <div className="mt-n2">
            <label htmlFor="name_of_restaurant">
            Name of the establishment/business :{" "}
            </label>
            <div className="filter_input mt-n1" >
              <TextInput
                placeholder={`Name of the establishment/business`}
                name="name_of_restaurant"
                id="name_of_restaurant"
                value={values.name_of_restaurant}
                error={errors.name_of_restaurant}
                onChange={(e) =>
                  handleTextChange("name_of_restaurant", e.target.value)
                }
              />
            </div>
          </div>
          <div >
            <label htmlFor="nature_of_food">Nature of Food: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text"
                name="nature_of_food"
                action="nature_of_food"
                value={values.nature_of_food}
                error={errors.nature_of_food}
                items={duplicatenature}
                onChange={(value) => handleTextChange("nature_of_food", value)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="issue_of_food">Issue of Food: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text"
                name="issue_of_food"
                action="issue_of_food"
                value={values.issue_of_food}
                error={errors.issue_of_food}
                items={duplicateissue}
                onChange={(value) => handleTextChange("issue_of_food", value)}
              />
            </div>
          </div>
          {user && user && user.user_types && user.user_types.includes("fso") ? (
         <div >
            <label htmlFor="fso">FSO Officers: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" isDisabled
                name="fso"
                action="fsos"
                value={values.fso}
                error={errors.fso}
                items={fsoOfficers&&fsoOfficers}
                onChange={(value) => handleTextChange("fso", value)}
              />
            </div>
          </div>):user && user && user.user_types && user.user_types.includes("do") ?(
            <div >
            <label htmlFor="fso">FSO Officers: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" 
                name="fso"
                action="fsos"
                value={values.fso}
                error={errors.fso}
                items={fsoOfficers&&fsoOfficers}
                onChange={(value) => handleTextChange("fso", value)}
              />
            </div>
          </div>
          ):(
             <div >
             <label htmlFor="fso">FSO Officers: </label>
             <div className="filter_input" >
               <DropDownInput
                 type="text"
                 name="fso"
                 action="fsos"
                 value={values.fso}
                 error={errors.fso}
                 items={duplicatefso}
                 onChange={(value) => handleTextChange("fso", value)}
               />
             </div>
           </div>
          )}
          <div >
            <label htmlFor="fso">Complaint Status: </label>
            <div className="filter_input" >
              <DropDownInput
                name="status"
                value={values.status}
                error={errors.status}
                items={Status}
                action="dropdown"
                onChange={(value) => handleTextChange("status", value)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="area_name">Zipcode: </label>
            <div className="filter_input" >
              <TextInput
                placeholder={` Zipcode`}
                name="zipcode"
                id="zipcode"
                type="number"
                value={values.zipcode}
                error={errors.zipcode}
                onChange={(e) => handleTextChange("zipcode", e.target.value)}
              />
            </div>
          </div>
          {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
          <div >
            <label htmlFor="district">District: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" isDisabled
                name="district"
                value={values.district}
                error={errors.district}
                items={districts}
                onChange={(value) => handleTextChange("district", value)}
              />
            </div>
          </div>
          ):(
          <div >
          <label htmlFor="district">District: </label>
          <div className="filter_input" >
            <DropDownInput
              type="text"
              name="district"
              value={values.district}
              error={errors.district}
              style
              items={duplicateDistrict}
              onChange={(value) => handleTextChange("district", value)}
            />
          </div>
          </div>
          )}
          <div className="form-group button_filter">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success ml-3"
            >
              View
            </button>
          </div>
        </div>
        {users ? (
          <div
            style={{ width: "90%", fontSize: "16px" }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={colDetails}
              data={users}
              options={{
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                  zIndex:0,
                },
              }}
              title="Complaint List"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetFilterData: (data) => dispatch(getFilterData(data)),
  ongetFilterDataWithout: (data) => dispatch(getFilterDataWithout(data)),
  ongetFso: (data) => dispatch(getFsoOfficers(data)),
  onPutMasterData:(data)=>dispatch(getMasterSuccess(data)),
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  traderTypes: state.home.traderTypes,
  foodIssues: state.home.foodIssues,
  foodTypes: state.home.foodTypes,
  fsoOfficers: state.home.fsoOfficers,
  wardsCategory:state.home.wardsCategory,
  wards:state.home.wards,
  foodConditions:state.home.foodConditions,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComplaintFilterList);
