import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import GoogleMapView from "../components/GoogleMapView";
import { useParams } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import ModelPopup from "../components/ModelPopup";
import UploadImageInput from "../components/inputs/UploadImageInput";
import {
  deleteComplaint,
  getComplaints,
  getComments,
  createComment,
  getComplaint,
  getInspection,
} from "../components/reducers/actions";
import Accordion from "../components/Accordion/Accordion";
import TextAreaInput from "../components/inputs/TextAreaInput";
import { useTranslation } from "react-i18next";
import { toCamel } from "../utils/toCamel";
import Zoom from "react-medium-image-zoom";

const ComplaintDetails = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [complaint, setComplaint] = useState(null);
  const [region, setRegion] = useState({
    lat: 11.1271,
    lng: 78.6569,
  });
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [markerCoordinates, setMarkerCoordinates] = useState(null);
  let { id } = useParams();
  const { t } = useTranslation();

  const {
    districts,
    onGetComments,
    onGetInspection,
    inspection,
    onCreateComment,
    onComplaint,
    comments,
    foodConditions,
    foodTypes,
    foodIssues,
    traderTypes,
    onComplaints,
    onDeleteComplaint,
  } = props;

  useEffect(() => {
    if (props.history.location && props.history.location.state) {
      const complaintData = props.history.location.state.complaint;
      if (complaintData.id) {
        onGetComments({
          is_base64: true,
          complaint: complaintData.id,
        });
        onGetInspection({
          is_base64: true,
          complaint: complaintData.id,
        });
      }
      if (
        complaintData &&
        complaintData.coordinates &&
        complaintData.coordinates[0] &&
        complaintData.coordinates[1]
      ) {
        const updatedRegion = { ...region };
        updatedRegion.lat = complaintData.coordinates[0];
        updatedRegion.lng = complaintData.coordinates[1];
        setRegion(updatedRegion);
        setMarkerCoordinates({
          lat: complaintData.coordinates[0],
          lng: complaintData.coordinates[1],
        });
      }
      setComplaint(complaintData);
      if (props.history.location.state.isFirst) {
        setTimeout(() => {
          props.history.push("/app/complaints/");
        }, 5000);
      }
    } else if (id) {
      const handleGetData = async () => {
        const complaint = await onComplaint({
          is_base64: true,
          id: id,
          type: "all",
        });
        const complaintData = complaint.response;
        if (complaintData.id) {
          onGetComments({
            is_base64: true,
            complaint: complaintData.id,
          });
          onGetInspection({
            is_base64: true,
            complaint: complaintData.id,
          });
        }
        if (
          complaintData &&
          complaintData.coordinates &&
          complaintData.coordinates[0] &&
          complaintData.coordinates[1]
        ) {
          const updatedRegion = { ...region };
          updatedRegion.lat = complaintData.coordinates[0];
          updatedRegion.lng = complaintData.coordinates[1];
          setRegion(updatedRegion);
          setMarkerCoordinates({
            lat: complaintData.coordinates[0],
            lng: complaintData.coordinates[1],
          });
        }
        setComplaint(complaintData);
      };
      handleGetData();
    }
    // eslint-disable-next-line
  }, [props.history.location.state]);

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ["message"];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };

  useEffect(() => {
    validateValues(values);
    // eslint-disable-next-line
  }, []);

  let districtName = null;
  districts &&
    districts.forEach((districtItem) => {
      if (complaint && districtItem.id === complaint.district) {
        districtName = districtItem.name;
      }
    });

  let foodCondition = null;
  foodConditions &&
    foodConditions.forEach((item) => {
      if (complaint && item.id === complaint.condition_of_food) {
        foodCondition = item.name;
      }
    });

  let traderType = null;
  traderTypes &&
    traderTypes.forEach((item) => {
      if (complaint && item.id === complaint.type_of_trader) {
        traderType = item.name;
      }
    });

  let natureOfFood = null;
  foodTypes &&
    foodTypes.forEach((item) => {
      if (complaint && item.id === complaint.nature_of_food) {
        natureOfFood = item.name;
      }
    });

  let issueOfFood = null;
  foodIssues &&
    foodIssues.forEach((item) => {
      if (complaint && item.id === complaint.issue_of_food) {
        issueOfFood = item.name;
      }
    });

  const handleRemoveImages = (key, value) => {
    const updatedValues = { ...values };
    delete updatedValues[key];
    setValues(updatedValues);
  };

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleDeleteSubmit = async (item) => {
    const resp = await onDeleteComplaint({
      complaint_id: item.id,
    });
    if (resp && resp.data === "success") {
      onComplaints();
      setModal(false);
      props.history.push("/app/complaints");
    }
  };

  const handleSubmitButton = async () => {
    const updatedErrors = validateValues(values);
    if (Object.keys(updatedErrors).length === 0) {
      const photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("image_")) {
          photos.push(value);
        }
      });
      const data = {
        ...values,
        photos: photos,
        complaint: complaint.id,
        action: "Comment",
        visible_to_consumer: true,
      };
      const resp = await onCreateComment(data);
      if (resp.response) {
        const complaintsData = await onComplaint({
          is_base64: true,
          id: complaint.id,
          type: "all",
        });
        if (complaintsData && complaintsData.response) {
          setValues({});
          validateValues({});
          props.history.push({
            pathname: `/app/complaints/${complaint.id}`,
            state: {
              complaint: complaintsData.response,
            },
          });
        } else {
          props.history.push("/app");
        }
      } else {
        setValues({});
        validateValues({});
        props.history.push({
          pathname: `/app/complaints/${complaint.id}`,
          state: {
            complaint: complaint,
          },
        });
      }
    }
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const commentsList = comments
    ? comments.map((comment, idx) => {
        return (
          comment.visible_to_consumer && (
            <div className="comment-items" key={idx.toString()}>
              <div className="comment-item-content">
                <p key={`id${idx}`}>
                  <span className="font-weight-bold">
                    {capitalize(comment.created_by)}
                  </span>
                  {comment.action === "Assigned" ? (
                    <span>
                      {" "}
                      {t("assigned the complaint to")}{" "}
                      <span className="font-weight-bold">
                        {capitalize(comment.to_assignee)}
                      </span>
                    </span>
                  ) : (
                    [
                      comment.action === "Unassigned" ? (
                        <span key={`idspan${idx}`}>
                          {" "}
                          {t("unassigned the complaint from")}{" "}
                          <span className="font-weight-bold">
                            {capitalize(comment.from_assignee)}
                          </span>
                        </span>
                      ) : (
                        [
                          comment.action === "Reassigned" ? (
                            <span>
                              {" "}
                              {t("reassigned the complaint from")}{" "}
                              <span className="font-weight-bold">
                                {capitalize(comment.from_assignee)}
                              </span>{" "}
                              {t("to")}{" "}
                              <span className="font-weight-bold">
                                {capitalize(comment.to_assignee)}
                              </span>
                            </span>
                          ) : (
                            t(" added a comment")
                          ),
                        ]
                      ),
                    ]
                  )}
                </p>
              </div>
              {comment.message ? (
                <div
                  style={{
                    fontSize: 14,
                    alignSelf: "flex-start",
                    marginTop: 15,
                    marginBottom: 20,
                  }}
                >
                  {comment.message}
                </div>
              ) : null}
              <div className="form-group">
                <Row>
                  {comment
                    ? comment.photos.map((item, idx) => {
                        return (
                          <Col md="2" key={idx}>
                            <Zoom>
                              <img
                                style={{
                                  width: 60,
                                  height: 60,
                                }}
                                alt={`image_${idx}`}
                                key={idx}
                                src={`${item}`}
                              />
                            </Zoom>
                          </Col>
                        );
                      })
                    : null}
                </Row>
              </div>
              <div
                style={{
                  textAlign: "right",
                  alignSelf: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <p style={{ fontSize: 14, marginTop: 15, color: "#5f6368" }}>
                  {moment(comment.created_at.toString()).format(
                    "DD-MM-YYYY hh:MM"
                  )}
                </p>
              </div>
            </div>
          )
        );
      })
    : null;

  return (
    <div className="container-fluid">
      <div>
        {modal ? (
          <ModelPopup
            modal={modal}
            data={complaint}
            handleDeleteSubmit={handleDeleteSubmit}
            title="Complaint"
            toggle={() => setModal(!modal)}
          ></ModelPopup>
        ) : null}
      </div>
      <h1 className="h4 mb-4 text-gray-800">{t("Complaint Details")}</h1>
      <div className="text-center">
        <h1 className="h2 mb-0 text-gray-800">
          {complaint ? complaint.name_of_restaurant : ""}
        </h1>
        {complaint && complaint.complaint_number ? (
          <p>
            <span style={{ fontWeight: "bold", marginTop: 20 }}>
              {t("Complaint No")}
            </span>{" "}
            : {complaint.complaint_number}
          </p>
        ) : null}
        {complaint && complaint.current_status ? (
          <p>
            <span style={{ fontWeight: "bold" }}>{t("Current Status")}</span> :{" "}
            <>
              {complaint.current_status === "closed" ? (
                <span style={{ color: "green" }}>{t("Closed")}</span>
              ) : (
                [
                  complaint.current_status === "awaiting_your_reply" ? (
                    <span style={{ color: "grey" }}>
                      {t("Awaiting Your Reply")}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>{t("Open")}</span>
                  ),
                ]
              )}
            </>
          </p>
        ) : null}
        <p>
          {inspection &&
          inspection.is_approved &&
          inspection.fso_additional_comment
            ? inspection.fso_additional_comment
            : null}
        </p>
      </div>
      {/* <div className="complaint-detail-header">
        <div className="complaint-detail-header-item">
          <Link to={
            {
              pathname: `/app/complaints/${complaint ? complaint.id : null}/edit/`,
              state: {
                'complaint': complaint
              }
            }
          }>
            <Button className="btn btn-success btn-circle btn-sm" title={t("Edit Complaint")}>
              <i className="fas fa-edit"></i>
            </Button>
          </Link>
        </div>
        <div className="complaint-detail-header-item">
          <Button onClick={() => setModal(!modal)} title={t("Delete Complaint")} className="btn btn-danger btn-circle btn-sm">
            <i className="fas fa-trash"></i>
          </Button>
        </div>
      </div> */}
      <div className="mt-5">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {t("Complaint Details")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {t("Comments")}
            </NavLink>
          </NavItem>
          {inspection && Object.keys(inspection).length < 0 ? (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                {t("Inspection Info")}
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tbody>
                      {inspection && inspection.is_approved ? (
                        <>
                          <tr>
                            <td>{t("Action Taken Status")}</td>
                            <td>
                              {inspection && inspection.action_taken
                                ? inspection.action_taken.map((item, idx) => (
                                    <>
                                      {t(toCamel(item))}
                                      {idx !==
                                      inspection.action_taken.length - 1
                                        ? ","
                                        : null}{" "}
                                    </>
                                  ))
                                : null}
                            </td>
                          </tr>
                          <tr>
                            <td>{t("Comments")}</td>
                            <td>
                              {inspection &&
                                inspection.is_approved &&
                                inspection.action_taken.includes("notice") &&
                                inspection.notice_action_taken_message && (
                                  <>
                                    <div>
                                      <div className="text-left font-weight-bold mb-2">
                                        Comments - Notice
                                      </div>
                                      <div className="ml-1">
                                        {inspection.notice_action_taken_message}
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )}
                              {inspection &&
                                inspection.is_approved &&
                                inspection.action_taken.includes(
                                  "sample_lifted"
                                ) &&
                                inspection.sample_lifted_action_taken_message && (
                                  <>
                                    <div>
                                      <div className="text-left font-weight-bold mb-2">
                                        Comments - Sample Lifted
                                      </div>
                                      <div className="ml-1">
                                        {
                                          inspection.sample_lifted_action_taken_message
                                        }
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )}
                              {inspection &&
                                inspection.is_approved &&
                                inspection.action_taken.includes("fine") &&
                                inspection.fine_action_taken_message && (
                                  <>
                                    <div>
                                      <div className="text-left font-weight-bold mb-2">
                                        Comments - Fine
                                      </div>
                                      <div className="ml-1">
                                        {inspection.fine_action_taken_message}
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )}
                              {inspection &&
                                inspection.is_approved &&
                                inspection.action_taken.includes("seizure") &&
                                inspection.seizure_action_taken_message && (
                                  <>
                                    <div>
                                      <div className="text-left font-weight-bold mb-2">
                                        Comments - Seizure
                                      </div>
                                      <div className="ml-1">
                                        {
                                          inspection.seizure_action_taken_message
                                        }
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )}
                              {inspection &&
                                inspection.is_approved &&
                                inspection.action_taken.includes(
                                  "close_complaint"
                                ) &&
                                inspection.closed_action_taken_message && (
                                  <>
                                    <div>
                                      <div className="text-left font-weight-bold mb-2">
                                        Comments - Close Complaint
                                      </div>
                                      <div className="ml-1">
                                        {inspection.closed_action_taken_message}
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )}
                            </td>
                          </tr>
                        </>
                      ) : null}
                      <tr>
                        <td>{t("Trader Type")}</td>
                        <td>{traderType}</td>
                      </tr>
                      <tr>
                        <td>{t("Nature of Food")}</td>
                        <td>{natureOfFood}</td>
                      </tr>
                      <tr>
                        <td>{t("Condition of Food")}</td>
                        <td>{foodCondition}</td>
                      </tr>
                      <tr>
                        <td>{t("Issue of Food")}</td>
                        <td>{issueOfFood}</td>
                      </tr>
                      <tr>
                        <td>{t("Cash Bill No")}</td>
                        <td>{complaint ? complaint.cash_bill_number : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("Created At")}</td>
                        <td>
                          {complaint && complaint.created_at
                            ? moment(complaint.created_at.toString()).format(
                                "DD-MM-YYYY HH:MM"
                              )
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("Product Purchased On")}</td>
                        <td>
                          {complaint && complaint.product_purchased_on
                            ? moment(
                                complaint.product_purchased_on.toString()
                              ).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("Product Used/Opened On")}</td>
                        <td>
                          {complaint && complaint.product_used_on
                            ? moment(
                                complaint.product_used_on.toString()
                              ).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("District")}</td>
                        <td>{districtName}</td>
                      </tr>
                      <tr>
                        <td>{`${t("Street Address")} 1`}</td>
                        <td>{complaint ? complaint.sub_division : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("House/Flat No")}</td>
                        <td>{complaint ? complaint.street : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("City")}</td>
                        <td>{complaint ? complaint.village : "-"}</td>
                      </tr>
                      <tr>
                        <td>{`${t("cv")}`}</td>
                        <td>{complaint ? complaint.sub_division1 : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("ZipCode")}</td>
                        <td>{complaint ? complaint.zipcode : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("Short Note")}</td>
                        <td>{complaint ? complaint.short_note : "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="form-group">
                  <Row>
                    {complaint
                      ? complaint.photos.map((item, idx) => {
                          return (
                            <Col key={idx}>
                              <Zoom>
                                <img
                                  style={{
                                    width: 100,
                                    height: 100,
                                  }}
                                  alt={`image_${idx}`}
                                  key={idx}
                                  src={`${item}`}
                                />
                              </Zoom>
                            </Col>
                          );
                        })
                      : null}
                  </Row>
                </div>
                <div className="form-group">
                  <GoogleMapView
                    region={region}
                    markerCoordinates={markerCoordinates}
                    setMarkerCoordinates={setMarkerCoordinates}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </div>
              </div>
            </div>
          </TabPane>
          {comments && Object.keys(comments).length > 0 ? (
            <TabPane tabId="2">
              <div className="card shadow mb-4">
                <div className="card-body">
                  {complaint && complaint.request_info_from_consumer ? (
                    <div className="form-group comment-form">
                      <Accordion title={`${t("Add comment")}`}>
                        <div className="form-group">
                          <TextAreaInput
                            placeholder={`${t("Enter your comment")}`}
                            name="message"
                            error={errors.message}
                            value={values.message}
                            onChange={(e) =>
                              handleTextChange("message", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group mt-4 mb-4">
                          <p>{t('upp')}</p>
                          <div className="row">
                            <div className="col">
                              <UploadImageInput
                                size={80}
                                photo={values.image_1}
                                name="image_1"
                                handleRemoveImages={(text) =>
                                  handleRemoveImages("image_1", text)
                                }
                                onChangeText={(text) =>
                                  handleTextChange("image_1", text)
                                }
                              />
                            </div>
                            <div className="col">
                              <UploadImageInput
                                size={80}
                                photo={values.image_2}
                                name="image_2"
                                handleRemoveImages={(text) =>
                                  handleRemoveImages("image_2", text)
                                }
                                onChangeText={(text) =>
                                  handleTextChange("image_2", text)
                                }
                              />
                            </div>
                            <div className="col">
                              <UploadImageInput
                                size={80}
                                photo={values.image_3}
                                name="image_3"
                                handleRemoveImages={(text) =>
                                  handleRemoveImages("image_3", text)
                                }
                                onChangeText={(text) =>
                                  handleTextChange("image_3", text)
                                }
                              />
                            </div>
                            <div className="col">
                              <UploadImageInput
                                size={80}
                                photo={values.image_4}
                                name="image_4"
                                handleRemoveImages={(text) =>
                                  handleRemoveImages("image_4", text)
                                }
                                onChangeText={(text) =>
                                  handleTextChange("image_4", text)
                                }
                              />
                            </div>
                            <div className="col">
                              <UploadImageInput
                                size={80}
                                photo={values.image_5}
                                name="image_5"
                                handleRemoveImages={(text) =>
                                  handleRemoveImages("image_5", text)
                                }
                                onChangeText={(text) =>
                                  handleTextChange("image_5", text)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <button
                            disabled={disableSubmit}
                            type="button"
                            onClick={() => handleSubmitButton()}
                            className="btn btn-success ml-3"
                          >
                            Submit Comment
                          </button>
                        </div>
                      </Accordion>
                    </div>
                  ) : null}
                  {commentsList}
                </div>
              </div>
            </TabPane>
          ) : (
            <TabPane tabId="2">
              <div className="card shadow mb-4">
                <div className="card-body">
                  <p className="text-center">{t("There is no comments to show")}</p>
                </div>
              </div>
            </TabPane>
          )}
          {inspection && Object.keys(inspection).length < 0 ? (
            <TabPane tabId="3">
              <div className="card shadow mb-4">
                <div className="card-body">
                  <div className="form-group mt-5 comment-form">
                    <Accordion title={t("Address")}>
                      <div>
                        <div className="d-flex">
                          <div className="content-left">
                            {t("Same address as provided in the complaint ?")}
                          </div>
                          <div>
                            {inspection.has_fssai_number ? t("Yes") : t("No")}
                          </div>
                        </div>
                        <div>
                          <p>
                            {!inspection.same_address
                              ? inspection.address
                              : [complaint ? complaint.address : null]}
                          </p>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div className="form-group comment-form">
                    <Accordion title={t("Fssai Number")}>
                      <div>
                        <div className="d-flex">
                          <div className="content-left">
                            {t("Has Fssai Number ?")}
                          </div>
                          <div>
                            {inspection.has_fssai_number ? t("Yes") : t("No")}
                          </div>
                        </div>
                        {inspection.has_fssai_number ? (
                          <>
                            <div className="d-flex">
                              <div className="content-left">
                                {t("Fssai Number")}
                              </div>
                              <div>{inspection.fssai_number}</div>
                            </div>
                          </>
                        ) : null}
                        {inspection && inspection.fssai_photos ? (
                          <div className="form-group">
                            <Row>
                              {inspection.fssai_photos.map((item, idx) => {
                                return (
                                  <Col md="2" key={idx}>
                                    <Zoom>
                                      <img
                                        style={{
                                          width: 60,
                                          height: 60,
                                        }}
                                        alt={`image_${idx}`}
                                        key={idx}
                                        src={`${item}`}
                                      />
                                    </Zoom>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Accordion>
                  </div>
                  <div className="form-group comment-form">
                    <Accordion title={t("Authorised person in premises")}>
                      <div>
                        <div className="d-flex">
                          <div className="content-left">{t("Name")}</div>
                          <div>{inspection.auth_person_name}</div>
                        </div>
                        <div className="d-flex">
                          <div className="content-left">{t("Designation")}</div>
                          <div>{inspection.auth_person_designation}</div>
                        </div>
                        <div className="d-flex">
                          <div className="content-left">
                            {t("Phone Number")}
                          </div>
                          <div>{inspection.auth_person_phone_number}</div>
                        </div>
                        {inspection && inspection.auth_person_photos ? (
                          <div className="form-group">
                            <Row>
                              {inspection.auth_person_photos.map(
                                (item, idx) => {
                                  return (
                                    <Col md="2" key={idx}>
                                      <Zoom>
                                        <img
                                          style={{
                                            width: 60,
                                            height: 60,
                                          }}
                                          alt={`image_${idx}`}
                                          key={idx}
                                          src={`${item}`}
                                        />
                                      </Zoom>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Accordion>
                  </div>
                  <div className="form-group comment-form">
                    <Accordion title={t("Nature of Complaint")}>
                      <div>
                        <p>{inspection.nature_of_complaint}</p>
                        {inspection && inspection.nature_of_complaint_photos ? (
                          <div className="form-group">
                            <Row>
                              {inspection.nature_of_complaint_photos.map(
                                (item, idx) => {
                                  return (
                                    <Col md="2" key={idx}>
                                      <Zoom>
                                        <img
                                          style={{
                                            width: 60,
                                            height: 60,
                                          }}
                                          alt={`image_${idx}`}
                                          key={idx}
                                          src={`${item}`}
                                        />
                                      </Zoom>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Accordion>
                  </div>
                  <div className="form-group comment-form">
                    <Accordion title={t("Fso Observation")}>
                      <div>
                        <p>{inspection.fso_observation}</p>
                        {inspection && inspection.fso_observation_photos ? (
                          <div className="form-group">
                            <Row>
                              {inspection.fso_observation_photos.map(
                                (item, idx) => {
                                  return (
                                    <Col md="2" key={idx}>
                                      <Zoom>
                                        <img
                                          style={{
                                            width: 60,
                                            height: 60,
                                          }}
                                          alt={`image_${idx}`}
                                          key={idx}
                                          src={`${item}`}
                                        />
                                      </Zoom>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Accordion>
                  </div>
                  <div className="form-group comment-form">
                    <Accordion title={t("Action Taken")}>
                      <div>
                        <p>
                          {inspection.action_taken
                            ? inspection.action_taken.map((item, idx) => (
                                <>
                                  {item}
                                  {idx !== inspection.action_taken.length - 1
                                    ? ","
                                    : null}{" "}
                                </>
                              ))
                            : null}
                        </p>
                        {inspection && inspection.action_taken_photos ? (
                          <div className="form-group">
                            <Row>
                              {inspection.action_taken_photos.map(
                                (item, idx) => {
                                  return (
                                    <Col md="2" key={idx}>
                                      <Zoom>
                                        <img
                                          style={{
                                            width: 60,
                                            height: 60,
                                          }}
                                          alt={`image_${idx}`}
                                          key={idx}
                                          src={`${item}`}
                                        />
                                      </Zoom>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Accordion>
                  </div>
                  <div className="form-group comment-form">
                    <Accordion title={t("Fso Additional Comments")}>
                      <div>
                        <p>{inspection.fso_additional_comment}</p>
                        {inspection && inspection.fso_additional_photos ? (
                          <div className="form-group">
                            <Row>
                              {inspection.fso_additional_photos.map(
                                (item, idx) => {
                                  return (
                                    <Col md="2" key={idx}>
                                      <Zoom>
                                        <img
                                          style={{
                                            width: 60,
                                            height: 60,
                                          }}
                                          alt={`image_${idx}`}
                                          key={idx}
                                          src={`${item}`}
                                        />
                                      </Zoom>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Accordion>
                  </div>
                </div>
              </div>
            </TabPane>
          ) : null}
        </TabContent>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetInspection: (data) => dispatch(getInspection(data)),
  onDeleteComplaint: (data) => dispatch(deleteComplaint(data)),
  onComplaints: () => dispatch(getComplaints()),
  onCreateComment: (data) => dispatch(createComment(data)),
  onComplaint: (data) => dispatch(getComplaint(data)),
  onGetComments: (data) => dispatch(getComments(data)),
});

const mapStateToProps = (state) => ({
  complaints: state.home.complaints,
  comments: state.home.comments,
  inspection: state.home.inspection,
  user: state.home.user,
  districts: state.home.districts,
  foodConditions: state.home.foodConditions,
  traderTypes: state.home.traderTypes,
  foodTypes: state.home.foodTypes,
  foodIssues: state.home.foodIssues,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintDetails);
