import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const FSOCDetails = (props) => {
    const { districts, traderTypes, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">FSO -  Case Details Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Case Type </th>
                 <td className="text-left">{records.type_of_case}</td>
                </tr>
                {records.date_case_file_received_from_do && <tr>
                  <th>Case File Received From DO on  </th>
                 <td className="text-left">{moment(records.date_case_file_received_from_do).format("l")}</td>
                </tr>}
                {records.date_ao_case_file_submitted_at_adjudication_court_date && 
              <tr>
                <th>Case File submitted at Adjudicaion Court on</th>
                <td className="text-left">{moment(records.date_ao_case_file_submitted_at_adjudication_court_date).format("l")}</td>
              </tr> }   
              {records.date_case_file_received_from_do_on && 
              <tr>
                <th>Case File Received From DO on</th>
                <td className="text-left">{moment(records.date_case_file_received_from_do_on).format("l")}</td>
              </tr> }             
              {records.date_case_file_submitted_to_court_app_on && 
              <tr>
                <th>Case File Submitted To Court App On</th>
                <td className="text-left">{moment(records.date_case_file_submitted_to_court_app_on).format("l")}</td>
              </tr> }  
              {records.date_assistant_public_prosecutor_approved_on && 
              <tr>
                <th>Assistant Public Prosecutor Approved Case File On </th>
                <td className="text-left">{moment(records.date_assistant_public_prosecutor_approved_on).format("l")}</td>
              </tr> }  
              {records.date_case_filed_at_open_court_on && 
              <tr>
                <th>Case Filed at Open Court On </th>
                <td className="text-left">{moment(records.date_case_filed_at_open_court_on).format("l")}</td>
              </tr> } 
              {records.case_admitted_on && 
              <tr>
                <th>Case Admitted On </th>
                <td className="text-left">{moment(records.case_admitted_on).format("l")}</td>
              </tr> } 
              {records.case_file_number && 
              <tr>
                <th> Case File Number </th>
                <td className="text-left">{records.case_file_number}</td>
              </tr> } 
              {records.date_of_first_hearing && 
              <tr>
                <th> Date Of First Hearing</th>
                <td className="text-left">{moment(records.date_of_first_hearing).format("l")}</td>
              </tr> } 
              {records.case_judgement && 
              <tr>
                <th>Case Judgement</th>
                <td className="text-left">{records.case_judgement}</td>
              </tr> } 
              {records.convicted && 
              <tr>
                <th>Convicted with </th>
                <td className="text-left">{records.convicted}</td>
              </tr> } 
              {records.penalty && 
              <tr>
                <th>Penalty Amount </th>
                <td className="text-left">{records.penalty}</td>
              </tr> } 
              {records.penalty_with_imprisonment && 
              <tr>
                <th>Imprisonment Penalty Amount (in RS) </th>
                <td className="text-left">{records.penalty_with_imprisonment}</td>
              </tr> } 
              {records.appeal_preferred && 
              <tr>
                <th>Any Appeal Preferred by FBO: </th>
                <td className="text-left">{records.appeal_preferred}</td>
              </tr> } 
              {records.penalty_with_imprisonment && 
              <tr>
                <th>Type Of Trader </th>
                <td className="text-left">{records.type_of_trader}</td>
              </tr> } 
              {records.other_type_of_trader && 
              <tr>
                <th>Type Of Trader </th>
                <td className="text-left">{records.other_type_of_trader}</td>
              </tr> } 
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(FSOCDetails);