import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createERCPortalReports, editERCPortalReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const Type =[
    {id:"0", name:"Photos"},
    {id:"1", name:"Videos"},
    {id:"2", name:"Banners"}
]
const Activities =[
        {id:"0", name:"Facebook"},
        {id:"1", name:"Twitter"},
        {id:"2", name:"Instagram"},
        {id:"3", name:"Pamphlet with Eat Right Messages"},
         {id:"4", name:"DART-Awareness Program"},
         {id:"5", name:"Eat Right"},
         {id:"6", name:"Food Safety on Wheels"},
         {id:"7", name:"Standee’s "},
         {id:"8", name:"Water Can Stickers"},
         {id:"9", name:"Others"}

]

const ERCPortalReport = (props) => {
    const { districts, user,wards, onCreateERCPortalReports,ongetDist, wardsCategory,onEditERCPortalReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'type' : '',
        'activity_banner':'',
        'activity_video':'',
        'activity_photos':'',
        'banners_count':null,
        'videos_count':null,
        'photos_count':null,
        'other_activity_banner':null,
        'other_activity_video':'',
        'other_activity_photos':''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district', 
            'date', 
            'type',
       'area_name','area_code'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditERCPortalReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/erc-portal-list/`);
            };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateERCPortalReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">ERC Portal Uploaded Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type"
                            >Type *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type`}
                                    type="text"
                                    name="type"
                                    items={Type}
                                    action="dropdown"
                                    value={values.type}
                                    error={errors.type}
                                    onChange={(text) =>handleTextChange("type", text)}
                                />
                            </div>
                        </div>
                        { values && values.type.includes('Photos')? (
                           <div>
                                <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="activity_photos"
                            >Name Of the Activity : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Name Of the activity`}
                                    type="text"
                                    name="activity_photos"
                                    items={Activities}
                                    action="dropdown"
                                    value={values.activity_photos}
                                    error={errors.activity_photos}
                                    onChange={(text) =>handleTextChange("activity_photos", text)}
                                />
                            </div>
                        </div>
                        {values && values.activity_photos.includes('Others') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_activity_photos"
                            ></label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the activity`}
                                    type="text"
                                    name="other_activity_photos"
                                    value={values.other_activity_photos}
                                    error={errors.other_activity_photos}
                                    onChange={(e) =>handleTextChange("other_activity_photos", e.target.value)}
                                />
                            </div>
                        </div>
                        ) :null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="photos_count"
                            >Photos Uploaded During The Month : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Photos Uploaded During the month`}
                                    type="number"
                                    name="photos_count"
                                    value={values.photos_count}
                                    error={errors.photos_count}
                                    onChange={(e) =>handleTextChange("photos_count", e.target.value)}
                                />
                            </div>
                        </div>
                           </div>
                         ) : null}
                        { values && values.type.includes('Videos')? (
                           <div>
                                <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="activity_video"
                            >Name Of the Activity : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Name Of the activity`}
                                    type="text"
                                    name="activity_video"
                                    items={Activities}
                                    action="dropdown"
                                    value={values.activity_video}
                                    error={errors.activity_video}
                                    onChange={(text) =>handleTextChange("activity_video", text)}
                                />
                            </div>
                        </div>
                        {values && values.activity_video.includes('Others') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_activity_video"
                            > </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the activity`}
                                    type="text"
                                    name="other_activity_video"
                                    value={values.other_activity_video}
                                    error={errors.other_activity_video}
                                    onChange={(e) =>handleTextChange("other_activity_video", e.target.value)}
                                />
                            </div>
                        </div>
                        ) :null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="videos_count"
                            >Videos Uploaded During The Month : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Videos Uploaded During the month`}
                                    type="number"
                                    name="videos_count"
                                    value={values.videos_count}
                                    error={errors.videos_count}
                                    onChange={(e) =>handleTextChange("videos_count", e.target.value)}
                                />
                            </div>
                        </div>
                           </div>
                         ) : null}
                          { values && values.type.includes('Banners')? (
                           <div>
                                <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="activity_banner"
                            >Name Of the Activity : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Name Of the activity`}
                                    type="text"
                                    name="activity_banner"
                                    items={Activities}
                                    action="dropdown"
                                    value={values.activity_banner}
                                    error={errors.activity_banner}
                                    onChange={(text) =>handleTextChange("activity_banner", text)}
                                />
                            </div>
                        </div>
                        {values && values.activity_banner.includes('Others') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_activity_banner"
                            > </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the activity`}
                                    type="number"
                                    name="other_activity_banner"
                                    value={values.other_activity_banner}
                                    error={errors.other_activity_banner}
                                    onChange={(e) =>handleTextChange("other_activity_banner", e.target.value)}
                                />
                            </div>
                        </div>
                        ) :null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="banners_count"
                            >Number Of Banners/Pamplets Displayed During The Month : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of IEC Activity of Banners and pamplets displayed`}
                                    type="number"
                                    name="banners_count"
                                    value={values.banners_count}
                                    error={errors.banners_count}
                                    onChange={(e) =>handleTextChange("banners_count", e.target.value)}
                                />
                            </div>
                        </div>
                           </div>
                         ) : null}
                       
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateERCPortalReports: (data) => dispatch(createERCPortalReports(data)),
    onEditERCPortalReports: (data, record_id) => dispatch(editERCPortalReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(ERCPortalReport);