import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getFoodInNewspaperReport } from "../../components/reducers/actions"
import DataTable from "react-data-table-component";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"

const AppList = (props) => {
  const { data, handleSelectRow } = props;
  return (
    <div onClick={() => handleSelectRow(data)}>
    </div>
  );
};

const EditButton = (props) => {
  const { data, handleEdit } = props;
  return (
    <button className="btn btn-success btn-sm btn-circle" onClick={() => handleEdit(data)}>
      <i className="fas fa-pencil-alt" aria-hidden="true"></i>
    </button>
  );
};

const FoodInNewspaperList = (props) => {
    const { districts, onGetFoodInNewspaperReport, user } = props;
    const [values, setValues] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 10;

    const reportList = async () => {
      const resp = await onGetFoodInNewspaperReport({
        page: page,
        per_page: countPerPage,
      });
      if(resp && resp.response) {
        const data = resp.response
        setValues(data)
      }      
    };
  
    useEffect(() => {
      reportList();
       // eslint-disable-next-line 
    }, [page]);

    const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        fontSize: "18px",
        paddingLeft: "30px",
        paddingRight: "30px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        color: "#45b151",
        paddingLeft: "25px",
        paddingRight: "30px",
        fontSize: "16px",
        background: "rgba(70,179,84,.10196078431372549)",
      },
    },
    cells: {
      style: {
        paddingLeft: "25px",
      },
    },
  };

    const getDistrict = (district) => {
      let districtName = null;
      districts &&
        districts.forEach((item) => {
          if (item.id === district) {
            districtName = item.name;
          }
        });
      return districtName;
    };

    const columns = [
      {
        omit: true,
        cell: (row) => <AppList data={row} handleSelectRow={handleSelectRow} />,
        selector: "id",
        sortable: true,
      },
      {
        name: "District",
        cell: (row) => getDistrict(row.district),
        selector: "district",
        sortable: true
      },
      
      {
        name: "Created At",
        cell: (row) =>
         row.created_at && moment(row.last_updated_at.toString()).format("DD-MM-YYYY hh:mm"),
        selector: "created_at",
        sortable: true,
      },
      {
        name: "Action",
        cell: (row) =>
        moment().diff(moment(row.created_at.toString()), 'hours') < 24 ? 
        (<EditButton data={row} handleEdit={handleEdit} />) : "-",
        sortable: true,
      }
    ];

    const handleTextChange = (key, value) => {
      if(key === 'date') {
        const updatedValues = { ...searchFilter, [key]: moment(value).format() };
        setSearchFilter(updatedValues);
      }
      const updatedValues = { ...searchFilter, [key]: value };
      setSearchFilter(updatedValues);
    }

    const handleClick = () => {
      if(searchFilter) {
        async function fetchData() {
          const resp = await onGetFoodInNewspaperReport({
            district: searchFilter.district,
            date: searchFilter.date,
          });
          if(resp && resp.response) {
            const data = resp.response
            console.log(data)
            setValues(data)
          }
        }
        fetchData();
      }
    }

    const handleEdit = (data) => {
      console.log(data);
      props.history.push(`/app/reports/food-in-newspaper-reports/${data.id}/edit`, { values: data });
    };

    const handleSelectRow = (data) => {
      console.log(data);
      props.history.push(`/app/display-reports/food-in-newspaper-list/${data.id}`, { records: data });
    };

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Food found In Newspaper Reports List</h1>
            <div className="main">
            {user && user.user_types && user.user_types.includes("ho") ? (
                <div className="row" style={{ marginBottom : 20 }}>
                  <div className="col-sm-4">
                    <DropDownInput
                      placeholder={`Select District`}
                      type="text"
                      name="district"
                      value={searchFilter.district}
                      items={districts}
                      onChange={(text) => handleTextChange("district", text)}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Datepicker
                        placeholder={`Search Date`}
                        name="date"
                        value={searchFilter.date}
                        onChangeText={(text) => handleTextChange('date', text)}
                    />
                  </div>
                  <div className="col-sm-4">
                    <button
                        type="button"
                        className="btn btn-secondary ml-3"
                        onClick={handleClick}>
                    View</button>
                  </div>
                </div>
              ) : (
                <div className="row" style={{ marginBottom : 20 }}>
                  <div className="col-sm-4">
                    <Datepicker
                        placeholder={`Search Date`}
                        name="date"
                        value={searchFilter.date}
                        onChangeText={(text) => handleTextChange('date', text)}
                    />
                  </div>
                  <div className="col-sm-8">
                    <button
                        type="button"
                        className="btn btn-secondary ml-3"
                        onClick={handleClick}>
                    View</button>
                  </div>
                </div>
              )}
                <DataTable
                  columns={columns}
                  data={values.results}             
                  onRowClicked={handleSelectRow}
                  pagination
                  // paginationServer
                  paginationPerPage={countPerPage}
                  paginationTotalRows={values.count}
                  customStyles={customStyles}
                  noHeader
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  highlightOnHover
                  noDataComponent={
                    <div style={{ color: "#858796", marginTop: 20 }}>
                      No data available in table
                    </div>
                  }
                  onChangePage={(page) => setPage(page)}
                />
          </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
  onGetFoodInNewspaperReport: (data) => dispatch(getFoodInNewspaperReport(data))
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(FoodInNewspaperList);