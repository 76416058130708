import React from "react";
import {
    getDownloadSampleone,
  getDownloadSampletwo,
  getFilterData,
  getFilterDataWithout,
  getFsoOfficers,
  getMasterSuccess,
  userDirectory,
} from "../../components/reducers/actions";
import { connect } from "react-redux";
import { useEffect,useRef } from "react";
import Table from "react-bootstrap/Table";
import Header from "../../components/Header";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { date } from "yup";
import Datepicker from "../../components/inputs/Datepicker";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextInput from "../../components/inputs/TextInput";
import moment from "moment";
import { CSVLink } from "react-csv";
import { CSVDownload } from "react-csv";

const SamplAction = (props) => {
  const { t, i18n } = useTranslation();
  const {
    ongetFilterData,
    traderTypes,
    ongetFso,
    foodIssues,
    districts,
    foodTypes,
    fsoOfficers,
    user,
    ongetFilterDataWithout,
    onPutMasterData,
    wardsCategory,
    foodConditions,
    wards,
  } = props;
  const [users, setUsers] = useState('');
  const [errors, setErrors] = useState(false);
  const [filt, setFilt] = useState();
  const dref=useRef();
  const [values, setValues] = useState({
    fromdate: new Date(),
    todate: new Date(),
    district: null,
  
  });
const Status = [
{id:'0', name:'Waiting to Allocate'},
{id:'1', name:`Waiting for do's approval`},
{id:'2',name:'Inprogress'},
{id:'3',name:'Closed'},
]
  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
  };
  const handleSubmit = async (event) => {
    // setErrors(true)
    const data = { ...values };
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso"))data.district=[data.district]
    data.fromdate = moment(data.fromdate).format("YYYY-MM-DD");
    data.todate = moment(data.todate).format("YYYY-MM-DD");
    console.log(data)
    
      const resp = await ongetFilterData(data);
      if (resp && resp.response) {
        // setTimeout(()=>{
        //   setErrors(false)
        // },1000)
        console.log(resp.response);
        setUsers(resp.response);
        setErrors(true)

        // setTimeout(()=>{
        //   dref.click()
        // },1000)
      }
  
  };
  useEffect(() => {
    if (dref && dref.current && errors) {
        dref.current.link.click();
        setErrors(false)

    }
}, [errors]);
const getOfficer=async(id)=>{
//   const data={district_id:id}
//  const resp = await ongetFso(data)
//  console.log('resp', resp,data)
}
  useEffect(()=>{
    if(values.district){
      getOfficer(values.district)
    }
  },[values.district])
  const fod=async()=>{
    let d1={id: 237, name: 'All', food_condition:[], image: 'https://foodsafety.tn.gov.in/api/media/foodissue_images/Unclean_Utensil.jpg'}
    const data = foodTypes
    data.push(d1)
    const data2=foodIssues
    data2.push(d1)
    const data3=traderTypes
    data3.push(d1)
    console.log(data,foodTypes)
    await onPutMasterData({
      food_types:data,
      trader_types:data3,
      food_conditions: foodConditions,
      food_issues:data2,
      districts :districts,
      wards :wards,
      wardsCategory:wardsCategory
    })
  }
  useEffect(()=>{
     if(foodTypes && traderTypes && districts && wardsCategory && wards && foodIssues && foodConditions){
      fod()
     } 
  },[props])
  useEffect(() => {
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
      const userData = { ...values, district: user.district };
      setValues(userData);
  }
  
  if(user && user.user_types && user.user_types.includes("fso")){
    const userData = { ...values, district: user.district, fso: user.id };
    setValues(userData);
  }
      const getfs = async () => {
      await ongetFso();
    };
    getfs();
  }, []);

  // const getDown=(data)=>{
  //   return ;

  // }
  // useEffect(()=>{
  //   if(users){
  //     getDown(users)
  //   }
  // },[users])
const colDetails = user &&  user.user_types && user.user_types.includes("ho") ? [
  {
    title:"Complaint Number",
    field:'complaint_number' ,
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Created Date",
    field: "created_at",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
   {
    title: "Consumer Name",
    field: "created_by__name",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Consumer Phone Number",
    field: "created_by__phone_number",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Type of Trader",
    field: "type_of_trader__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Name of restaurant",
    field: "name_of_restaurant",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Address of Premesis",
    field: "sub_division",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Nature of Food",
    field: "nature_of_food__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Issue of Food",
    field: "issue_of_food__name",
    filtering: false,

    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Purchased On",
    field: "product_purchased_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Used On",
    field: "product_used_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Audio Attached(If Any)",
    field: "voice_note",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },

  {
    title: "Complaint Status",
    field: "status",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Assigned To",
    field: "assigned_to__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "FSO Contact Number",
    field: "assigned_to__phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Name",
    field: "do_name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Conatct Number",
    field: "do_phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Zipcode",
    field: "zipcode",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "District",
    field: "district__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
]:
[
  {
    title:"Complaint Number",
    field:'complaint_number' ,
    filtering:false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Created Date",
    field: "created_at",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Type of Trader",
    field: "type_of_trader__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Name of restaurant",
    field: "name_of_restaurant",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Address of Premesis",
    field: "sub_division",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Nature of Food",
    field: "nature_of_food__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Issue of Food",
    field: "issue_of_food__name",
    filtering: false,

    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Purchased On",
    field: "product_purchased_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Product Used On",
    field: "product_used_on",
    filtering:false,
    type:date,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Audio Attached(If Any)",
    field: "voice_note",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },

  {
    title: "Complaint Status",
    field: "status",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Assigned To",
    field: "assigned_to__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "FSO Contact Number",
    field: "assigned_to__phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Name",
    field: "do_name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "DO Conatct Number",
    field: "do_phone_number",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "Zipcode",
    field: "zipcode",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },
  {
    title: "District",
    field: "district__name",
    filtering: false,
    cellStyle: {
      textAlign: "center",
    },
  },

  

  
]

  return (
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}> Lab Sample Details</h3>
        <div
          className="filter_container"
        >
          <div >
            <label htmlFor="date">From Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`From Date`}
                name="fromdate"
                id="fromdate"
                value={values.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextChange("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">To Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={values.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextChange("todate", text)}
              />
            </div>
          </div>
          {/* <div >
            <label htmlFor="area_code"> Type of Trader: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text"
                name="type_of_trader"
                action="type_of_trader"
                value={values.type_of_trader}
                error={errors.type_of_trader}
                items={traderTypes}
                onChange={(value) => handleTextChange("type_of_trader", value)}
              />
            </div>
          </div> */}
          
{/*         
          {user && user && user.user_types && user.user_types.includes("fso") ? (
         <div >
            <label htmlFor="fso">FSO Officers: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" isDisabled
                name="fso"
                action="fsos"
                value={values.fso}
                error={errors.fso}
                items={fsoOfficers}
                onChange={(value) => handleTextChange("fso", value)}
              />
            </div>
          </div>):(
             <div >
             <label htmlFor="fso">FSO Officers: </label>
             <div className="filter_input" >
               <DropDownInput
                 type="text"
                 name="fso"
                 action="fsos"
                 value={values.fso}
                 error={errors.fso}
                 items={fsoOfficers}
                 onChange={(value) => handleTextChange("fso", value)}
               />
             </div>
           </div>
          )} */}
          {/* <div >
            <label htmlFor="fso">Complaint Status: </label>
            <div className="filter_input" >
              <DropDownInput
                name="status"
                value={values.status}
                error={errors.status}
                items={Status}
                action="dropdown"
                onChange={(value) => handleTextChange("status", value)}
              />
            </div>
          </div> */}
          {/* <div >
            <label htmlFor="area_name">Zipcode: </label>
            <div className="filter_input" >
              <TextInput
                placeholder={` Zipcode`}
                name="zipcode"
                id="zipcode"
                type="number"
                value={values.zipcode}
                error={errors.zipcode}
                onChange={(e) => handleTextChange("zipcode", e.target.value)}
              />
            </div>
          </div> */}
          {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
          <div >
            <label htmlFor="district">District: </label>
            <div className="filter_input" >
              <DropDownInput
                type="text" isDisabled
                name="district"
                value={values.district}
                error={errors.district}
                items={districts}
                onChange={(value) => handleTextChange("district", value)}
              />
            </div>
          </div>
          ):(
          <div >
          <label htmlFor="district">District: </label>
          <div className="filter_input" >
            <DropDownInput
              type="text"
              name="district"
              action='district'
              value={values.district}
              error={errors.district}
              items={districts}
              onChange={(value) => handleTextChange("district", value)}
            />
          </div>
          </div>
          )}
          {/* {users && errors?<div> <CSVDownload data={users} target="_blank" filename={"Report.csv"} /></div>:null} */}
         
        </div>
        <div style={{marginTop:'30px',float:'right',marginLeft:'auto',marginRight:'30px'}} className="form-group ">
            <input
              onClick={handleSubmit}
              type='button'
              value="Filter"
              className="btn btn-success ml-3"             
            />
       <CSVLink 
            data={users}
            ref={dref}
            filename={'SampleActionDetails.csv'}
            // target='_blank'
            
            />
          </div>
        {/* {users ? (
          <div
            style={{ width: "90%", fontSize: "16px" }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={colDetails}
              data={users}
              options={{
                exportButton: {
       csv: true,
       pdf: false,
    },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                },
              }}
              title="Complaint List"
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetFilterData: (data) => dispatch(getDownloadSampletwo(data)),
  ongetFso: (data) => dispatch(getFsoOfficers(data)),
  onPutMasterData:(data)=>dispatch(getMasterSuccess(data)),
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  traderTypes: state.home.traderTypes,
  foodIssues: state.home.foodIssues,
  foodTypes: state.home.foodTypes,
  fsoOfficers: state.home.fsoOfficers,
  wardsCategory:state.home.wardsCategory,
  wards:state.home.wards,
  foodConditions:state.home.foodConditions,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SamplAction);
