import axiosApi from '../../utils/axios';
import toast from 'react-toast-notification';
import { setAuthToken, removeAuthToken } from '../../utils/Auth';
import i18n from '../../i18n';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_COMPLAINTS,
  GET_MASTER_DATA,
  GET_COMMENTS,
  GET_INSPECTION,
  GET_BANNERS,
  GET_NOTIFICATIONS,
  GET_FAQ_CATEGORY,
  UPDATE_LANGUAGE,
  GET_FAQS,
  GET_BLOGS,
  GET_WAITING_COMPLAINTS,
  GET_CLOSED_COMPLAINTS,
  GET_UNASSIGN_COMPLAINTS,
  GET_ASSIGN_COMPLAINTS,
  GET_ASSIGN_FSO_COMPLAINTS,
  GET_FSO_CATEGORY,
  GET_USERS,
  GET_FSO_OFFICERS,
  GET_DO_CONTACTS,
  SET_LOADING,
  GET_WARDS,
  GET_WARDS_CATEGORY,
  GET_DISTRICT,
  GET_DASHBOARD,
  GET_USERS_VIEW,
  CHANG_SAMPLE,
  CHAN_SAMPLE,
  CREATE_SAMPLE,
  ANALYSE_SAMPLE,
  REQ_SAMPLE,
  RES_OBTAINED,
  CIVIL_CASE,
  CRIMINAL_CASE, 
  ALL_SAMPLE,
} from './constants';
import { date } from 'yup';
const CryptoJS = require("crypto-js");

const decrypt=(str)=>{
  const key = CryptoJS.enc.Utf8.parse("AAAAAAAAAAAAAAAA"); 
  const cipher = CryptoJS.algo.AES.createDecryptor(key, { mode: CryptoJS.mode.ECB }); 
  const encoded_message = str; 
  const encrypted_message = CryptoJS.enc.Base64.parse(encoded_message); 
  const decrypted_message = cipher.finalize(encrypted_message).toString(CryptoJS.enc.Utf8); 
  const unpadded_message = decrypted_message.replace(/\0+$/, '');
  return unpadded_message;
}

export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload,
});

const loginFailure = payload => ({
  type: LOGIN_FAILURE,
  payload,
});
export const analyseSuccess=payload=>({
  type:ANALYSE_SAMPLE,
  payload,
})
export const reqSample=payload=>({
  type:REQ_SAMPLE,
  payload,
})
export const updateSample=payload=>({
  type:CHANG_SAMPLE,
  payload,
})
export const updateCivil=payload=>({
  type:CIVIL_CASE,
  payload,
})
export const updateCriminal=payload=>({
  type:CRIMINAL_CASE,
  payload,
})
export const resultUpadte=payload=>({
  type:RES_OBTAINED,
  payload,
})
export const updateSampleList=payload=>({
  type:CHAN_SAMPLE,
  payload,
})

export const createOverallSample=payload=>({
  type:ALL_SAMPLE,
  payload,
})
export const createSampleSuccess=payload=>({
  type:CREATE_SAMPLE,
  payload,
})
export const getMasterSuccess = payload => ({
  type: GET_MASTER_DATA,
  payload,
});
export const getDashboardSucces = payload => ({
  type: GET_DASHBOARD,
  payload,
});
export const updateLangSuccess = payload => ({
  type: UPDATE_LANGUAGE,
  payload,
});

export const getComplaintSuccess = payload => ({
  type: GET_COMPLAINTS,
  payload,
});

export const getUserSuccess = payload => ({
  type: GET_USERS,
  payload,
});
export const getUserViewSuccess = payload => ({
  type: GET_USERS_VIEW,
  payload,
});
export const getDoContactSuccess = payload => ({
  type: GET_DO_CONTACTS,
  payload,
});

export const getBannerSuccess = payload => ({
  type: GET_BANNERS,
  payload,
});

export const setLoading = payload => ({
  type: SET_LOADING,
  payload,
});

export const getNotificationSuccess = payload => ({
  type: GET_NOTIFICATIONS,
  payload,
});

export const getFaqSuccess = payload => ({
  type: GET_FAQS,
  payload,
});

export const getFaqCategorySuccess = payload => ({
  type: GET_FAQ_CATEGORY,
  payload,
});

export const getBlogSuccess = payload => ({
  type: GET_BLOGS,
  payload,
});

export const getCommentSuccess = payload => ({
  type: GET_COMMENTS,
  payload,
});

export const getInspectionSuccess = payload => ({
  type: GET_INSPECTION,
  payload,
});

export const getClosedComplaintSuccess = payload => ({
  type: GET_CLOSED_COMPLAINTS,
  payload,
});

export const getWaitingComplaintSuccess = payload => ({
  type: GET_WAITING_COMPLAINTS,
  payload,
});

export const getUnassignedComplaintSuccess = payload => ({
  type: GET_UNASSIGN_COMPLAINTS,
  payload,
});

export const getAssignedComplaintSuccess = payload => ({
  type: GET_ASSIGN_COMPLAINTS,
  payload,
});

export const getAssignedFsoComplaintSuccess = payload => ({
  type: GET_ASSIGN_FSO_COMPLAINTS,
  payload,
});

export const getFsoOfficerSuccess = payload => ({
  type: GET_FSO_OFFICERS,
  payload,
});

export const getFsoCategorySuccess = payload => ({
  type: GET_FSO_CATEGORY,
  payload,
});
export const getDistrictWardsSuccess = payload => ({
  type: GET_WARDS,
  payload,
});

export const getDistrictWardsCategorySuccess = payload => ({
  type: GET_WARDS_CATEGORY,
  payload,
});
export const getDistrictSuccess = payload => ({
  type: GET_DISTRICT,
  payload,
});
export const logout = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'logout/',
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      removeAuthToken();
      dispatch(loginSuccess(null));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    dispatch(loginSuccess(null));
    return { data: 'Fail' };
  });
};

export const doDirectLogin = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'profile',
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(loginSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    dispatch(loginFailure(error));
    return { data: 'Fail', error: error.response.data };
  });
};

export const profileUpdate = (data) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: 'profile/',
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      const user = resp.data;
      dispatch(loginSuccess(user));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDistrict = (data) => dispatch =>{
  return axiosApi({
    method: 'post',
    url: 'get_district/',
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getDistrictSuccess(resp.data));
      return { data: 'success', response: resp.data };
    } else {
      return { data: 'Fail' };
    }
  })
    .catch(async error => {
      if (error.message != "Network Error") {
        return { data: 'Fail' };
      }
    });
}

export const getMasterData = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'master-data/'
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getMasterSuccess(resp.data));
      return { data: 'success', response: resp.data };
    } else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const createMasterData = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `${data.path}/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    } else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const updateMasterData = (data) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `${data.path}/${data.id}/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    } else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const deleteMasterData = (data) => dispatch => {
  return axiosApi({
    method: 'delete',
    url: `${data.path}/${data.id}/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success' };
    } else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const doLogin = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'login/',
    data: data
  }).then(resp => {
    if (resp.data) {
      const { user, token } = resp.data
      user.name=decrypt(user.name)
      user.email=decrypt(user.email)
      user.phone_number=decrypt(user.phone_number)
      user.country_code=decrypt(user.country_code)
      user.id=decrypt(user.id)
      setAuthToken(decrypt(token));
      const language = i18n.language;
      const userData = { ...user, language: language === 'ta' ? 'tamil' : 'english' };
      
      dispatch(loginSuccess(userData));
      return { data: 'success', response: resp.data };
    }
    else {
      dispatch(loginFailure(resp.data));
    }
  }).catch(error => {
    dispatch(loginFailure(error));
    return { data: 'Fail', error: error.response };
  });
};
export const multiCheck = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'multiple_login_check/',
    data: data
  }).then(resp => {
    if (resp.data) {  
      dispatch(loginSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
    else {
      dispatch(loginFailure(resp.data));
    }
  }).catch(error => {
    dispatch(loginFailure(error));
    return { data: 'Fail', error: error.response };
  });
};
export const getBanners = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'banners/',
  }).then(resp => {
    if (resp.data) {
      dispatch(getBannerSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getBanned = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'banned-category/',
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const foodWaste = (data) => (dispatch) => {
  return axiosApi(
    {
      method: "post",
      url: "foodwaste-form/",
      data:data
    },
    { withCredentials: true }
  )
    .then((resp) => {
      if (resp.data) {
        return { data: "success", response: resp.data };
      } else {
        return { data: "Fail" };
      }
    })
    .catch(async (error) => {
      if (error.message != "Network Error") {
        return { data: "Fail" };
      }
    });
};
export const getFilterData = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/complaint-details-user/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFilterDataWithout = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/complaint-details/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getoverall = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/overall-details-user/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getoverallWithout = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/overall-details/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getfine = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/fined/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getfineWithout = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/fined-user/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSeizureWith = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/seizure-user/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSeizureWithout = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/seizure/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSampleWith = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/sample_lifted-user/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSampleWithout = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/sample_lifted/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getNoticeWith = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/notice-user/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getNoticeWithout = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/notice/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getCloseWith = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/close_complaint-user/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getCloseWithout = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint_reports/close_complaint/',
    data:data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getNotifications = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'notifications/',
  }).then(resp => {
    if (resp.data) {
      dispatch(getNotificationSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const doSubscribe = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'subscribe/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Subscribed to library');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const createContact = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'contacts/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};


export const getFaqs = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'faqs/',
  }).then(resp => {
    if (resp.data) {
      dispatch(getFaqSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const getFaqCategory = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'faq-categories/',
  }).then(resp => {
    if (resp.data) {
      dispatch(getFaqCategorySuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const getBlogs = () => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'blogs/',
  }).then(resp => {
    if (resp.data) {
      dispatch(getBlogSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const doRegister = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'signup/',
    data: data
  }).then(resp => {
    return { data: 'success' };
  }).catch(error => {
    return { data: 'Fail', error: error.response.data };
  });
};

export const reSendOtp = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'generate-otp/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response.data };
  });
};

export const doOtpVerification = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'otp-verification/',
    data: data
  }).then(resp => {
    if (resp.data) {
      setAuthToken(resp.data.token);
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response.data };
  });
};

export const doStaffLogin = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'staff/login/',
    data: data
  }).then(resp => {
    if (resp.data) {
      const { user, token } = resp.data
      setAuthToken(token);
      dispatch(loginSuccess(user));
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    dispatch(loginFailure(error));
    return { data: 'Fail', error: error.response };
  });
};

export const generateOtp = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'generate-otp/',
    data: data
  }).then(resp => {
    return { data: 'success', response: resp.data };
  }).catch(error => {
    return { data: 'Fail', error: error.response.data };
  });
};



export const forgotPassword = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'forgot-password/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response.data };
  });
};

export const getAsignUser=(data)=>dispatch=>{
    return axiosApi({
      method: 'get',
      url: 'list_app_details/',
      params: data
    }, { withCredentials: true }).then(resp => {
      if (resp.data) {
         return { data: 'success', response: resp.data };
      }
    }).catch(error => {
      return { data: 'Fail', error: error.response.data };
    });
  };

export const getComplaints = (data) => dispatch => {
  console.log('params are', data)
  return axiosApi({
    method: 'get',
    url: 'complaints/',
    params: data
  }, { withCredentials: true }).then(resp => {
   
    if (resp.data) {
      if (data && data.assign_status && data.assign_status === 'unassign') {
        dispatch(getUnassignedComplaintSuccess(resp.data));
      }
      else if (data && data.assign_status && data.assign_status === 'assign') {
        dispatch(getAssignedComplaintSuccess(resp.data));
      }
      else if (data && data.assign_status && data.assign_status === 'open') {
        dispatch(getAssignedFsoComplaintSuccess(resp.data));
      }
      else if (data && data.assign_status && data.assign_status === 'closed') {
        dispatch(getClosedComplaintSuccess(resp.data));
      }
      else if (data && data.assign_status && data.assign_status === 'waiting_final_status') {
        dispatch(getWaitingComplaintSuccess(resp.data));
      }
      else if (data && data.assign_status && data.assign_status === 'my_complaints') {
        dispatch(getComplaintSuccess(resp.data));
      }
      else {
        dispatch(getComplaintSuccess(resp.data));
        return { data: 'success', response: resp.data };
      }
    }
  });
};
export const getSurvSamples = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'list_sample_surveillance/',
    params:data 
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getinspectionSamples = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'list_sample_surveillance/',
    params:data,
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSampleStatus = (data) => dispatch => {
  console.log('get params',data)
  return axiosApi({
    method: 'get',
    url: `list_fso_sample/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      console.log(resp)
      if (data && data.status && data.status === 'Submitted to lab') {
        dispatch(createSampleSuccess(resp.data));
      }
      if (data && data.status && data.status === 'Waiting for submit') {
        dispatch(updateSample(resp.data));
      }
      if(data&&data.status&&data.status_civ){   
        dispatch(updateCivil(resp.data)) 
      }
      if (data && data.status && data.status === 'Waiting for submit'||'waiting for another part sample') {
        dispatch(createSampleSuccess(resp.data));
        dispatch(reqSample(resp.data));
      }
      if (data && data.status && data.status === 'all') {
        dispatch(createOverallSample(resp.data));
      }
      if(data&&data.status&&data.status==='Waiting for analysis'){
        dispatch(analyseSuccess(resp.data))
      }
      if(data&&data.status&&data.status==='waiting for lab result'){
        dispatch(updateSample(resp.data))
      }
      if(data&&data.status&&data.status==='Result submitted to DO'){
        dispatch(resultUpadte(resp.data))
      }
      if(data&&data.status&&data.status==='Waiting for APP-II Approval'
      ||data&&data.status&&data.status==='Waiting for DO final approval'
      ||data&&data.status&&data.status==='Waiting for fso to submit file to AO' 
      ||data&&data.status&&data.status==='Case filed at AO court' 
      ||data&&data.status&&data.status==='Appeal Case filed at court' 
      ||data&&data.status&&data.status==='File submitted to AO'
      ||data&&data.status&&data.status==='Final Judgement (Civil)'
      ||data&&data.status&&data.status==='Hearing (Civil)'
      ||data&&data.status&&data.status==='Appeal case hearing'
      ){  
        dispatch(updateCivil(resp.data)) 
      }
      if(data&&data.status&&data.status==='File submitted to section'){   
        dispatch(updateCriminal(resp.data)) 
      }
      if(data&&data.status&&data.status==='Waiting for section final approval'||'Waiting for DO final Approval'||'Waiting for fso to submit file to APP-II'||'Waiting for DD Approval'){   
        dispatch(updateCriminal(resp.data)) 
      }
      if(data&&data.status&&!data.status_check){   
        dispatch(updateCriminal(resp.data)) 
      }
     
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getNonSampleStatus = (data) => dispatch => {
  console.log('get params',data)
  return axiosApi({
    method: 'get',
    url: `list_directsample/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      console.log(resp)
      if(data&&data.status&&data.status_civ){   
        dispatch(updateCivil(resp.data)) 
      }
      if (data && data.status && data.status === 'all') {
        dispatch(createOverallSample(resp.data));
      }
      if (data && data.status && data.status === 'Waiting for DO Approval') {
        dispatch(createSampleSuccess(resp.data));
      }
      if (data && data.status && data.status === 'Waiting for submit') {
        dispatch(updateSample(resp.data));
      }
      if(data&&data.status&&data.status==='Waiting for APP-II Approval'
      ||data&&data.status&&data.status==='Waiting for DO final approval'
      ||data&&data.status&&data.status==='Waiting for fso to submit file to AO' 
      ||data&&data.status&&data.status==='Case filed at AO court' 
      ||data&&data.status&&data.status==='File submitted to AO'
      ||data&&data.status&&data.status==='Final Judgement (Civil)'
      ||data&&data.status&&data.status==='Hearing (Civil)'
      ){  
        dispatch(updateCivil(resp.data)) 
      }
      if(data&&data.status&&data.status==='File submitted to section'){   
        dispatch(updateCriminal(resp.data)) 
      }
      if(data&&data.status&&data.status==='Waiting for section final approval'||'Waiting for DO final Approval'||'Waiting for fso to submit file to APP-II'||'Waiting for DD Approval'){   
        dispatch(updateCriminal(resp.data)) 
      }
      if(data&&data.status&&!data.status_check){   
        dispatch(updateCriminal(resp.data)) 
      }
     
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const addFboDetails = (data,id) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `add_fbo_samples/${id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const addNSFboDetails = (data,id) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `add_fbo_direct_samples/${id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDownloadNonSampleone = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `report_directsample/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDownloadSampleone = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `report_sampledetails/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDownloadSampletwo = (data,id) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `report_fso_sample/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createAppeal = (data,id) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `add_sample_appeal/${id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
 export const userDirectory = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `user-directory/`,
    data:data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const testingLab = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `testing-labs/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      console.log(resp)
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const lineReportOpen = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `line_report/open_complaints/`,
    data:data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const lineReportClosed = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `line_report/closed_complaints/`,
    data:data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const bannedProducts = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `banned-products/`,
    data:data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getNotification = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `notification/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const govtOrders = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `govt-orders/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getGallery = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `gallery/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const circularFoodsafety = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `circular-foodsafety/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const courtOrders = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `court-orders/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getBooks = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `form-books/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};


export const getStruct = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `organization-structure/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getReferralLab = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `form-referrallabs/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getForms = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `form-forms/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getTenders = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `tenders/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createComplaint = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaints/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      toast.success(i18n.t('Complaint created successfully.'));
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const updateComplaint = (data) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `complaints/${data.id}/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      toast.success(i18n.t('Complaint updated successfully.'));
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const deleteComplaint = (data) => dispatch => {
  return axiosApi({
    method: 'delete',
    url: `complaints/${data.complaint_id}/`
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      toast.success(i18n.t('Complaint deleted successfully.'));
      return { data: 'success' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const createComment = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaints/${data.complaint}/comments/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      toast.success(i18n.t('Comment submitted successfully.'));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getComments = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `complaints/${data.complaint}/comments/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      dispatch(getCommentSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getComplaint = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `complaints/${data.id}/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getInspection = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `complaints/${data.complaint}/inspections/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getInspectionSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getFsoOfficersonly = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'fso-officers/',
    params: data
  }, { withCredentials: true }).then(resp => {
    console.log(resp.data)
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      console.log(error.response);
      return { data: 'Fail' };
    });
};


export const getFsoOfficers = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'fso-officers/',
    params: data
  }, { withCredentials: true }).then(resp => {
    console.log(resp.data)
    if (resp.data && data && data.fso_category) {
      console.log('dkd')
      dispatch(getFsoCategorySuccess(resp.data));
    }
    else {
      console.log('ds')
      dispatch(getFsoOfficerSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      console.log(error.response);
      return { data: 'Fail' };
    });
};
export const getDistrictWards= (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ward_district/',
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getDistrictWardsCategorySuccess(resp.data.data));
      return { data: 'success', response: resp.data.data };
    }
    else {
      dispatch(getDistrictWardsSuccess(resp.data));
    }
  })
    .catch(error => {
      console.log(error.response);
      return { data: 'Fail' };
    });
};
export const doComplaintAssign = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaints/${data.id}/complaint-assign/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      toast.success(i18n.t('Complaint successfully assigned'));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const doComplaintUnassign = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaints/${data.id}/complaint-unassign/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      toast.success(i18n.t('Complaint successfully unassigned'));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const createInspection = (data) => dispatch => {
  console.log('post', data)
  return axiosApi({
    method: 'post',
    url: `complaints/${data.complaint}/inspections/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      toast.success(i18n.t('Inspection added successfully'));
      return { data: 'success', response: resp.data };
    }
    else {
      return { data: 'Fail' };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const updateInspection = (data) => dispatch => {
  console.log('patchh', data)
  return axiosApi({
    method: 'patch',
    url: `complaints/${data.complaint_id}/inspections/`,
    data: data
  }, 
  { withCredentials: true }).then(resp => {
  console.log(data)
    if (resp.data) {
      toast.success(i18n.t('Inspection updated successfully'));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const approveInspection = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaints/${data.complaint_id}/inspection-approve/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      toast.success(i18n.t('Inspection successfully approved'));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const doComplaintSendEmails = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaints/${data.id}/send-emails/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      toast.success(i18n.t('Email Send Successfully.'));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getUsers = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'users/',
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getUserSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getUsersView = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'user-view/',
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getUserViewSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getExistingUser = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'existing_user/',
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const doCreateUser = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'users/',
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail', error: error.response.data };
    });
};

export const getDoContacts = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: 'do-contacts/',
    params: data
  }, {}).then(resp => {
    if (resp.data) {
      dispatch(getDoContactSuccess(resp.data));
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail', error: error.response.data };
    });
};

export const doUpdateUser = (data) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `users/${data.user_id}/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail', error: error.response.data };
    });
};
export const UserUpdateUser = (data) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `user-to-fso/${data.user_id}/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail', error: error.response.data };
    });
};

export const doGeneratePassword = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `generate-password/`,
    data: data
  }, {}).then(resp => {
    if (resp.data) {
      if (data.isResetPassword) {
        toast.success(i18n.t('Password reset Successfully.'));
      }
      else {
        toast.success(i18n.t('Password generated Successfully.'));
      }
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail', error: error.response };
    });
};

export const doResetPassUser = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `users/${data.user_id}/reset-password/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail', error: error.response };
    });
};

export const getFboReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fbo-counts/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDownloadFboReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `fbo-counts/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFboReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fbo-counts/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const editFboReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fbo-counts/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const getLicencingReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `licencing-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDownloadLicencingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `licencing-reports/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createLicencingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'licencing-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const editLicencingReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `licencing-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const getIECReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `iec-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createIECReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'iec-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editIECReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `iec-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSlaughterReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `slaughter-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadSlaughterReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'slaughter-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createSlaughterReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'slaughter-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editSlaughterReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `slaughter-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getRipeningMangoesReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `ripening-mangoes-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadRipeningMangoesReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ripening-mangoes-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createRipeningMangoesReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ripening-mangoes-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editRipeningMangoesReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `ripening-mangoes-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFssaactReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fssaact-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFssaactReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fssaact-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFssaactReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fssaact-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSurveillanceReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `ssc-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createSurveillanceReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ssc-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editSurveillanceReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `ssc-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getRegistrationReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `registration-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadRegistrationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'registration-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createRegistrationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'registration-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editRegistrationReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `registration-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getTourJournalReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `tourjournal-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadTourJournalReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tourjournal-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createTourJournalReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tourjournal-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editTourJournalReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `tourjournal-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getInformationEducationReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `informationeducation-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadInformationEducationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'informationeducation-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createInformationEducationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'informationeducation-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editInformationEducationReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `informationeducation-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getCompalintReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `complaint-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createComplaintReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'complaint-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editComplaintReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `complaint-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSeizureReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `seizure-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadSeizureReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'seizure-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createSeizureReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'seizure-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editSeizureReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `seizure-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getNoticeIssuedReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `notice-issued-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadNoticeIssuedReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'notice-issued-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createNoticeIssuedReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'notice-issued-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editNoticeIssuedReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `notice-issued-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getHygineRatingReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `hygine-rating-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadHygineRatingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'hygine-rating-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createHygineRatingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'hygine-rating-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editHygineRatingReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `hygine-rating-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getCSFHReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `clean-food-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadCSFHReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'clean-food-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createCSFHReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'clean-food-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editCSFHReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `clean-food-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getMarketReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `market-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadMarketReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'market-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createMarketReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'market-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editMarketReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `market-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getWorshipPlaceReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `worship-place-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadWorshipPlaceReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'worship-place-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createWorshipPlaceReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'worship-place-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editWorshipPlaceReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `worship-place-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getERTrainingReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `eatright-training-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadERTrainingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'eatright-training-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createERTrainingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'eatright-training-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editERTrainingReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `eatright-training-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getERSchoolReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `eatright-school-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadERSchoolReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'eatright-school-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createERSchoolReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'eatright-school-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editERSchoolReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `eatright-school-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getERCampusReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `eatright-campus-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadERCampusReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'eatright-campus-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createERCampusReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'eatright-campus-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editERCampusReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `eatright-campus-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getRUCOCertificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `ruco-certification-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadRUCOCertificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ruco-certification-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createRUCOCertificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ruco-certification-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editRUCOCertificationReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `ruco-certification-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getRUCOBiodieselReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `ruco-biodiesel-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadRUCOBiodieselReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ruco-biodiesel-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createRUCOBiodieselReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'ruco-biodiesel-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editRUCOBiodieselReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `ruco-biodiesel-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getUCOReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `uco-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadUCOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'uco-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createUCOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'uco-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editUCOReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `uco-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getNFWCertificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `food-waste-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadNFWCertificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'food-waste-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createNFWCertificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'food-waste-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editNFWCertificationReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `food-waste-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getNFWCDReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `food-collection-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadNFWCDReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'food-collection-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createNFWCDReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'food-collection-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editNFWCDReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `food-collection-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFSOCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fso-casedetails-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFSOCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fso-casedetails-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFSOCaseReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fso-casedetails-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFSOSampleReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fso-sample-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFSOSampleReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fso-sample-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createFSOSNonSampleReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'direct-samplefso-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFSOSampleReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fso-sample-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFSONonSampleReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `direct-samplefso-reports//${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDOSampleReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `do-sample-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const createDOSampleReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-sample-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDOSampleReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `do-sample-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDODamagedReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `do-damaged-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createDODamagedReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-damaged-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDODamagedReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `do-damaged-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDOClarifyReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `do-clarify-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createDOClarifyReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-clarify-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDOClarifyReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `do-clarify-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getLabDifferentReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `lab-different-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createLabDifferentReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'lab-different-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editLabDifferentReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `lab-different-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getComissionerCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `comissioner-case-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createComissionerCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'comissioner-case-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editComissionerCaseReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `comissioner-case-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getComissionerEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `comissioner-EPO-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadComissionerEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'comissioner-EPO-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createComissionerEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'comissioner-EPO-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editComissionerEPOReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `comissioner-EPO-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getAdjudicationAppealReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `adjudication-appeal-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createAdjudicationAppealReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'adjudication-appeal-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editAdjudicationAppealReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `adjudication-appeal-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getAPPCivilReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `app-civil-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createAPPCivilReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'app-civil-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editAPPCivilReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `app-civil-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getAPPDirectReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `app-direct-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createAPPDirectReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'app-direct-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editAPPDirectReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `app-direct-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getComissionerateCriminalReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `comissionerate-criminal-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createComissionerateCriminalReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'comissionerate-criminal-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editComissionerateCriminalReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `comissionerate-criminal-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getComissionerateAppealReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `comissionerate-appeal-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createComissionerateAppealReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'comissionerate-appeal-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editComissionerateAppealReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `comissionerate-appeal-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFSOEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fso-EPO-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadFSOEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fso-EPO-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createFSOEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fso-EPO-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFSOEPOReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fso-EPO-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDOEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `do-EPO-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadDOEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-EPO-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createDOEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-EPO-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDOEPOReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `do-EPO-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getPenaltyReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `compound-penalty-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadPenaltyReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'compound-penalty-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createPenaltyReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'compound-penalty-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editPenaltyReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `compound-penalty-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getComissionerateDirectReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `comissionerate-direct-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createComissionerateDirectReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'comissionerate-direct-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editComissionerateDirectReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `comissionerate-direct-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getComissionerateEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `comissionerate-EPO-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createComissionerateEPOReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'comissionerate-EPO-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editComissionerateEPOReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `comissionerate-EPO-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getLabSampleReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `lab-sample-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createLabSampleReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'lab-sample-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editLabSampleReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `lab-sample-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDODirectReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `do-direct-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createDODirectReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-direct-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDODirectReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `do-direct-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDODirectDetailsReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `do-directdetails-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createDODirectDetailsReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-directdetails-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDODirectDetailsReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `do-directdetails-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDOAppealReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `do-appeal-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createDOAppealReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'do-appeal-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDOAppealReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `do-appeal-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFSODirectCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fso-direct-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFSODirectCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fso-direct-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFSODirectCaseReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fso-direct-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const getFSODirectDetailsReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fso-directdetails-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFSODirectDetailsReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fso-directdetails-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFSODirectDetailsReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fso-directdetails-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getERCPortalReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `erc-portal-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadERCPortalReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'erc-portal-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createERCPortalReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'erc-portal-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editERCPortalReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `erc-portal-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getNumberReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `number-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadNumberReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'number-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createNumberReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'number-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editNumberReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `number-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getEnumerationDataReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `enumeration-data-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadEnumerationDataReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'enumeration-data-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createEnumerationDataReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'enumeration-data-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editEnumerationDataReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `enumeration-data-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getVehicleInspectionReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `vehicle-inspection-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadVehicleInspectionReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'vehicle-inspection-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createVehicleInspectionReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'vehicle-inspection-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editVehicleInspectionReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `vehicle-inspection-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getPanmasalaReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `panmasala-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadPanmasalaReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'panmasala-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createPanmasalaReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'panmasala-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editPanmasalaReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `panmasala-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getPdwReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `pdw-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadPdwReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'pdw-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createPdwReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'pdw-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editPdwReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `pdw-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFoodCategory = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `list-product-sub-category/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getTasmacReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `tasmac-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadTasmacReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tasmac-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createTasmacReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tasmac-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editTasmacReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `tasmac-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getRegMonthlyReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `regmonthly-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createRegMonthlyReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'regmonthly-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editRegMonthlyReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `regmonthly-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getLicenseMonthlyReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `licensemonthly-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createLicenseMonthlyReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'licensemonthly-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editLicenseMonthlyReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `licensemonthly-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getTnDailyReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `tndaily-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadTnDailyReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tndaily-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createTnDailyReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tndaily-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editTnDailyReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `tndaily-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getHotelReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `hotels-and-restaurants-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadHotelReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'hotels-and-restaurants-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createHotelReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'hotels-and-restaurants-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editHotelReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `hotels-and-restaurants-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSchoolReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `school-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadSchoolReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'school-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createSchoolReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'school-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editSchoolReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `school-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getTourProgramReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `tourprogram-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadTourProgramReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tourprogram-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createTourProgramReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'tourprogram-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editTourProgramReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `tourprogram-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFoscorisDigitalReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `foscorisdigital-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadFoscorisDigitalReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'foscorisdigital-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createFoscorisDigitalReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'foscorisdigital-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFoscorisDigitalReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `foscorisdigital-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSpecialProgramReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `special-program-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createSpecialProgramReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'special-program-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editSpecialProgramReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `special-program-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSlaughterHouseReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `slaughter-house-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createSlaughterHouseReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'slaughter-house-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editSlaughterHouseReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `slaughter-house-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getHighCourtCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `high-courtcase-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createHighCourtCaseReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'high-courtcase-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editHighCourtCaseReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `high-courtcase-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getAdjudicationReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `adjudication-case-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createAdjudicationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'adjudication-case-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editAdjudicationReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `adjudication-case-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getDLACReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `dlac-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadDLACReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'dlac-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createDLACReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'dlac-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editDLACReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `dlac-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFOSTACTrainingReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `fostac-training-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadFOSTACTrainingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fostac-training-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createFOSTACTrainingReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'fostac-training-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFOSTACTrainingReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `fostac-training-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFoodFortificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `food-fortification-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const  downloadFoodFortificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'food-fortification-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createFoodFortificationReports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'food-fortification-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFoodFortificationReports = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `food-fortification-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getExpectedInspectionReport = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `expected-inspection-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createExpectedInspectionReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'expected-inspection-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editExpectedInspectionReport = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `expected-inspection-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFoodInNewspaperReport = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `food-in-newspaper-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFoodInNewspaperReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'food-in-newspaper-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFoodInNewspaperReport = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `food-in-newspaper-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getEnumerationOfFBOReport = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `enumeration-of-fbo-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createEnumerationOfFBOReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'enumeration-of-fbo-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editEnumerationOfFBOReport = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `enumeration-of-fbo-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getSpecialDriveOfLandRReport = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `special-drive-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const downloadSpecialDriveOfLandRReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'special-drive-reports/csv-report/',
    data: data
  }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const createSpecialDriveOfLandRReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'special-drive-reports/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editSpecialDriveOfLandRReport = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `special-drive-reports/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};

export const getDashboard = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `web/dashboard/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getDashboardSucces(resp.data))
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDashboarddistrict = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `web/dashboard/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      dispatch(getDashboardSucces(resp.data))
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getFinancelist = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `special-drive-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getCreditFund = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-credit/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createCreditFund = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'finance-credit/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editCreditFund = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `finance-credit/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFundDistributed = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-distributed/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFundDistributed = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'finance-distributed/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFundDistributed = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `finance-distributed/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFundSpend = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-utilized/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFundSpend = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'finance-utilized/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFundSpend = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `finance-utilized/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFundReturn = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-returned/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const createFundReturn = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: 'finance-returned/',
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report submitted successfully');
      return { data: 'success', response: resp.data };
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const editFundReturn = (data, record_id) => dispatch => {
  return axiosApi({
    method: 'patch',
    url: `finance-returned/${record_id}/`,
    data: data
  }).then(resp => {
    if (resp.data) {
      toast.success('Report edited successfully');
      return { data: 'success', response: resp.data };      
    }
  }).catch(error => {
    return { data: 'Fail', error: error.response };
  });
};
export const getFundOverallDetails = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-distributed/overall_debit/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getFundDetails = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-credit/listcredit-remaining/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getFundspendDetails = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-utilized/overall_utilized/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getFundspenddistributeDetails = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `finance-distributed/listdistribute-remaining/ `,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getFundParticular = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `enumeration-of-fbo-reports/`,
    params: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDownloadCreditRerport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `finance-credit/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDownloadDistributedReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `finance-distributed/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDownloadSpendReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `finance-utilized/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDownloadReturnReport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `finance-returned/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryIssue = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `foodissue_report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryTrader= (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `trader_report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryComplaints = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaint_month_report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryfboreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `fbo-counts/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummarylicensereports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `licencing-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryregistrationreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `registration-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};


export const getSummaryfsoeporeports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `fso-EPO-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarydoeporeports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `do-EPO-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarycommeporeports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `comissioner-EPO-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryslaughterreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `slaughter-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryfruitsreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `ripening-mangoes-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};


export const getSummarypdwreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `pdw-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryhotelreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `hotels-and-restaurants-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryschoolreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `school-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummarytourjournalreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `tourjournal-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryfoscorisreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `foscorisdigital-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummarydailyreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `tndaily-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryenumerationreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `enumeration-data-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryiecreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `informationeducation-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarynoticereports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `notice-issued-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarytoolkitreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `eatright-training-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarypanmasalareports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `panmasala-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarytourprogramreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `tourprogram-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarytasmacreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `tasmac-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummarydlacreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `dlac-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryfooddotificationreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `food-fortification-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryseizurereports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `seizure-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryvehiclereports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `vehicle-inspection-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryspecialdrivereports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `special-drive-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryhyginereports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `hygine-rating-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryfostacreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `fostac-training-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryfoodhubreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `clean-food-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryvegetablereports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `market-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarybhogreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `worship-place-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryerschoolreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `eatright-school-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryClosedall = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaint_inspection_summary/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryfinetotal = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `complaint-finereport/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryercampusreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `eatright-campus-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryRUCOreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `ruco-certification-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryBiodieselreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `ruco-biodiesel-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryucoreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `uco-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryNFWCreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `food-waste-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryNFWreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `food-collection-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryERCPortalreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `erc-portal-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummaryERCActivityreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `number-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getSummarycompoundreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `compound-penalty-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarysamplesreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `fso-sample-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummarynonsamplesreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `direct-samplefso-reports/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getSummaryfundsreports = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `finance-distributed/line-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const postStock = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `stock-details/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      toast.success('Stock added successfully');
      return { data: 'success', response: resp };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const postStockcsv = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `stock-details/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const distributeStock = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `stock-used/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      toast.success('Stock distributed successfully');
      return { data: 'success', response: resp };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const distributeStockcsv = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `stock-used/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getRemainingstock = (data) => dispatch => {
  return axiosApi({
    method: 'get',
    url: `stock-details/list-remaining-stock/`,
  }, { withCredentials: true }).then(resp => {
    if (resp) {
      return { data: 'success', response: resp};
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getDownloadfeedbackreport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `feedback/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
export const getDownloadlibraryreport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `subscribe/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getDownloadcontactusreport = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `contacts/csv-report/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const delComplaint = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `delete-complaint/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const ComplaintClosure = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `manual-close-complaint/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};

export const getDelCOmplaints = (data) => dispatch => {
  return axiosApi({
    method: 'post',
    url: `/complaints/${data.complaint_id}/single-complaint/`,
    data: data
  }, { withCredentials: true }).then(resp => {
    if (resp.data) {
      return { data: 'success', response: resp.data };
    }
  })
    .catch(error => {
      return { data: 'Fail' };
    });
};
