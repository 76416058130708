import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createAppeal, createFSOSampleReports, createFSOSNonSampleReports, createSampleSuccess, editFSONonSampleReports, editFSOSampleReports, getAsignUser, getDistrictWards, getFoodCategory, testingLab, updateSample } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";

const Result = [
    {id:'0', name:'Confirm To Standards'},
    {id:'1', name:'Unsafe'},
    {id:'2', name:'Substandarded'},
    {id:'3', name:'Labelling Defects'},
    {id:'4', name:'Misleading'},
    {id:'5', name:'Miscellaneous'},

]
const FiledBy=[
    {id:'0', name:'FSO'},
    {id:'1', name:'DO'},
    {id:'2', name:'FBO'},
    // {id:'3', name:'Others'},
]
const JudgementResult = [
    {id:'0', name:'Convicted'},
    {id:'1', name:'Acquittal'},
]
const Resul = [
    {id:'0',name:'Hearing (Civil)'},
    {id:'1',name:'Final Judgement (Civil)'},
]
const Results = [
    {id:'0',name:'Hearing (Criminal)'},
    {id:'1',name:'Final Judgement (Criminal)'},
]
const Clarification = [
    {id:'0',name:'Yes'},
    {id:'1',name:'No'},
]
const Converted = [
    {id:'0', name:'Case Converted'},
    {id:'1', name:'Minor Faulty/Defect Case Closed'},
]
const Case = [
    {id:'0', name:'Civil'},
    {id:'1', name:'Criminal'},
]
const SampleSent = [
    {id:'0', name:'Courirer'},
    {id:'1', name:'Postal'},
    {id:'2', name:'Email'},
]

const SampleAnalysed = [
    {id:'0', name:'Analysed Within 14Days'},
    {id:'1', name:'Delay'},
]
const Fit = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const TypeOfTraders = [
    {id:'0', name:'Manufacturer'},
    {id:'1', name:'Retailer'},
    {id:'2', name:'WholeSaler/Distributor/Repacker'},
    {id:'3', name:'Transporter'},
    {id:'4', name:'Others'},

]

const resultData = [
    { id:"0", name: 'misbranded' },
    { id:"1", name: 'substandard'},
    { id:"2", name: 'unsafe'}
]
const Rectified=[
    { id:"0", name: 'yes' },
    { id:"1", name: 'no'},
]
const Aanlysi=[
    {id:'0', name:'Willing to test'},
    {id:'1', name:'Not opted to test'},
]
// const SampleSent = [
//     {id:'0', name:'Courirer'},
//     {id:'1', name:'Postal'},
// ]
const Retailer = [
    {id:'0', name:'WholeSaler'},
    {id:'1', name:'Distributor'},
    {id:'2', name:'Manufacturer'},
    {id:'3', name:'Repacker'},
]
const TypeOfFbo=[
    {id:'0', name:'SoloPreparator'},
    {id:'1', name:'Firm'},
    {id:'2', name:'Company'},
]
const Firm = [
    {id:'0', name:'Partnership'},
    {id:'1', name:'Private'},
]
const Company = [
    {id:'0', name:'Director'},
    {id:'1', name:'Nominee'},
]
const Partnership =[
    {id:'0', name:'Partner'},
    {id:'1', name:'Nominee'},
]
const Fines =[
    {id:'0', name:'Fine'},
    {id:'1', name:'Fine with imprisonment'},  
]
const Allowed = [
    {id:'0', name:'Appeal Allowed'},
    {id:'1', name:'Dismissed'},
]
const Distributor = [
    {id:'0', name:'Manufacturer'},
]
const Judg=[
    {id:'0', name:'Convicted'},
    {id:'1', name:'Acquittal'},
]
const Transporter = [
    {id:'0', name:'Manufacturer'},
    {id:'1', name:'Retailer'},
    {id:'2', name:'WholeSaler/Distributor'},
    {id:'3', name:'Transporter'},
]
const Others = [
    {id:'0', name:'Manufacturer'},
    {id:'1', name:'Retailer'},
    {id:'2', name:'WholeSaler/Distributor'},
    {id:'3', name:'Transporter'},
]
const TypeOfSample = [
    {id:'0', name:'Act'},
    {id:'1', name:'Surveillance'},
]
const IntimatedToDO = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const personInPremisis = [
    {id:'0', name:'Owner'},
    {id:'1', name:'Worker'}
]

const ConditionOfSample = [
    {id:'0', name:'Prepacked'},
    {id:'1', name:'Loose'},
]

const FSONonSampleReport = (props) => {
    const { districts, user,wards,ongetAssignUser,users,ongetSample,ongetSampe,ongetDist,fso_submit_data,sample_data, traderTypes,wardsCategory,foodTypes,fsoOfficers, onCreateFSOSampleReports, onEditFSOSampleReports,ongetLabs,onCreateAppeal,ongetFoodCategory } = props;
    const [values, setValues] = useState({
        'district' : '',
        'area_code':'',
        'area_name':'',
        'type_of_trader':'',
        status_of_report:'',
        other_type_of_trader:'',
        name_of_respondant_premisis:'',
        address_of_respondant_premisis:'',
        name_of_the_respondant:'',
        address_of_respondant_residence:'',
        FSSAI_license_number:'',
        upload_document_of_license:'',
        upload_documents_id_proof:'',   
        'date_notice_issued_date':'',
        'notice_issued_under_section' : '',
        'date_direct_case_sent_to_do_on':'',
        'date_approved_case_file_received_from_do_on':'',
        'direct_case_approved_on':'',
        'direct_case_sent_on':'',
        'upload_documents':'',
       'sample_fit_for_analysis':'',
        'sample_code':'',
        'slip_matched':'',
        'date_sample_received_on':'',
        'case_converted':'',
        'case_category':'',
        'report_intimated_to_DO_by':'',
        'date_report_intimated_to_DO_on':'',
        'date_so_file_received_date':'',
        'so_need_clarification':'',
        'date_so_file_signed_date':'',
        'assign_file_to':'',
        'so_remarks':'',
        'so_docs':'',
        'date_so_final_file_received_date':'',
        'date_so_final_file_sent_date':'',
        'date_do_file_received_date':'',
        'do_need_clarification':'',
        'date_do_file_signed_date':'',
        'date_do_file_sent_date':'',
        'do_remarks':'',
        'do_docs':'',
        'date_fso_file_received_date':'',
        'fso_need_clarification':'',
        'date_fso_file_signed_date':'',
        'date_fso_file_sent_date':'',
        'fso_remarks':'',
        'fso_docs':'',
        'date_app2lab_file_received_date':'',
        'app2lab_need_clarification':'',
        'date_app2lab_file_signed_date':'',
        'date_app2lab_file_sent_date':'',
        'date_app2_file_sent_date':'',
        'app2lab_remarks':'',
        'app2lab_docs':'',
        'date_app2_so_file_received_date':'',
        'app2_need_clarification':'',
        'date_app2_file_signed_date':'',
        'app2_remarks':'',
        'app2_docs':'',
        'date_app1_file_received_date':'',
        'app1_need_clarification':'',
        'date_app1_file_signed_date':'',
        'app1_remarks':'',
        'date_ac_file_received_date':'',
        'ac_need_clarification':'',
        'date_ac_file_signed_date':'',
        'ac_remarks':'',
        'ac_docs':'',
        'date_dd_file_received_date':'',
        'dd_need_clarification':'',
        'date_dd_file_signed_date':'',
        'dd_remarks':'',
        'dd_docs':'',
        'date_commissioner_file_received_date':'',
        'commissioner_need_clarification':'',
        'date_commissioner_file_signed_date':'',
        'commissioner_remarks':'',
        'commissioner_docs':'',
        'date_ao_file_received_date':'',
        'date_ao_filed_at_court':'',
        'ao_file_number':'',
        'ao_remarks':'',
        'ao_docs':'',
        'hearing':'',
        'final_judgement':'',
        'date_next_hearing_date':'',
        'hearing_remarks':'',
        'penalty_amount':'',
        'date_Sample_damaged_intimation_received_from_lab_on':'',
        'date_another_part_sample_sent_to_lab_on':'',
        'second_part_upload_documents':'',
        'date_report_received_on':'',
        'date_report_intimated_to_fso_on' : '',
        'date_report_intimated_to_fbo_on':'',
        'appeal_received_from_fbo':'',
        'date_appeal_received_from_fbo_on':'',
        'appeal_allowed':'',
        'date_sent_another_part_of_sample':'',
        'lab_name':'',
        'lab_address':'',
        'date_result_sent_to_fso_on':'',
        'dismiss_reason':'',
        'second_part_other_lab':'',
        'date_sample_sent_to_lab_on':'',
        'date_appeal_received_from_do_on':'',
        'appeal_preferred_by':'',
        'date_appeal_approved_on':'',
        'date_approval_sent_to_do_on':'',
        date_do_file_signed_date:'',
        date_ac_file_sent_date:'',
        date_commissioner_file_sent_date:'',
        date_app1_file_sent_date:'',
        date_app2_final_file_received_date:'',
        date_app2_final_filed_at_court:'',
        app2_final_docs:'',
        app2_final_remarks:'',
        app2_case_file_number:'',
        app_case_file_result:'',
        app_hearing_remarks:'',
        app_casefine_file_result:'',
        date_app_next_hearing_date:'',
        fine_amount:'',
        fine_amount_prison:'',
        date_second_part_sample_received_on:'',
        date_ao_appeal_received_from_do_on:'',
        appeal_ao_preferred_by:'',
        other_ao_appeal_preferred_by:'',
        date_ao_appeal_approved_on:'',
        section_file_number:'',
        status_of_report:'',
        date_approved_file_received_from_prosecution:'',
        date_direct_case_received_by_do_on:'',
        court_result:'',
        assign_file_next:'',
        reappeal_number:'',
        date_recommedation_by_do:'',
        fit_for_appeal:'',
        assign_file_to_seat:'',
            });
    const [errors, setErrors] = useState({});
    const [showres, setShowRes]=useState(false);
    const [showSample, setShowSample]=useState(false);
    const[Lab,setLab]=useState([]);
    const [rowView, setRowView]=useState(false)
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [showLess, setShowLess]=useState(false);
    const [asignUser, setAsignUser]=useState([]);
    const [asignSeat, setAsignSeat]=useState([]);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getUser=(id)=>{
        var data = ''
        if (users){
            data = users&& users.filter((i=>i.id==id.id))[0].name
        }
        else data = id.name
        return data
    }
const getLabots=async()=>{
    const resp = await ongetLabs()
    if(resp.response){
        const labs = resp.response.push({id:resp.response.length+1,name:'Others'})
        setLab(resp.response)
    }
}
const getListUser=async()=>{
   const resp= await ongetAssignUser({user_types:null})
   if(resp&&resp.response){
    setAsignUser(resp.response.data)
   }
}
 const getSeatUser=async()=>{
     const resp= await ongetAssignUser({user_types:'so'})
     console.log('========> SO', resp)
     if(resp&&resp.response){
         setAsignSeat(resp.response.data)
     }
  }
 const getDistrictName =(id)=>{
     var nam = ''
     let data = districts && districts.filter(i=>id==i.id)[0]
     nam=data.name
     return nam
 } 
 
 useEffect(()=>{
 getListUser()
 getSeatUser()
 },[])

const handlBack=()=>{
    
        if(user&&user.user_types&&user.user_types.includes('so')
||user&&user.user_types&&user.user_types.includes('twoapp')
||user&&user.user_types&&user.user_types.includes('dd')
||user&&user.user_types&&user.user_types.includes('AO')
||user&&user.user_types&&user.user_types.includes('labapp')
||user&&user.user_types&&user.user_types.includes('ac')
||user&&user.user_types&&user.user_types.includes('commissioner')
||user&&user.user_types&&user.user_types.includes('oneapp')){
props.history.push('/app/dep-ns-case-reports/')
}

else if((props.history.location.state&&props.history.location.state.doFinal)
||(props.history.location.state&&props.history.location.state.fsoFinal)
||(props.history.location.state&&props.history.location.state.resultAPP)
||(props.history.location.state&&props.history.location.state.clarify)
||(props.history.location.state&&props.history.location.state.do)
||(props.history.location.state&&props.history.location.state.fso)
||(props.history.location.state&&props.history.location.state.doFinal)
||(props.history.location.state&&props.history.location.state.appIIFinal)
||(props.history.location.state&&props.history.location.state.appealApp)
||(user&&user.user_types&&user.user_types.includes('fso') && props.history.location.state&&props.history.location.state.hearing_)
){
props.history.push('/app/dep-ns-case-reports/')
} 
else props.history.push('/app/dep-non-sample-reports')
    
}
useEffect(()=>{
getListUser()
},[])
useEffect(()=>{

    let data = users&& users.filter((i=>i.id==user.id))
    console.log(data,props)
},[])
    useEffect(() => {
            getLabots()
        if (props.history.location && props.history.location.state) {
            if(props.history.location.state.values.district){
                getDis(props.history.location.state.values.district)
            }
         
            if(props.history.location.state.action=='add_result')setShowRes(true)
            if(props.history.location.state.showLess)setShowLess(true)
            if(props.history.location.state.action_type=='sample_fit')setShowSample(true)
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            console.log(values,recordData)
        } else if (slug) {
            console.log(slug);
        }
          else if( user && user.user_types && user.user_types.includes("fso")) {
            setValues({...values,district:user.district});
        }
        if(props.history.location && props.history.location.state){
            let prop = props.history.location.state
            if(prop.appIIlab||prop.do||prop.fso||prop.ao||prop.appealApp||prop.aoResult||prop.aoAppeal||prop.resultAPP|| prop.section||prop.appII||prop.dd||prop.ac||prop.commissioner||prop.sectionFinal||prop.doFinal||prop.fsoFinal||prop.appIIFinal||prop.hearing_)setRowView(true)
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
       wardsCategory&&wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })

    },[values.area_code])
    const getDis=async(dat)=>{
        await ongetDist({district:dat})
    }

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
            'area_code',
            'area_name',
           'type_of_trader',
           'name_of_respondant_premisis',
           'address_of_respondant_premisis',
           'name_of_the_respondant',
           'address_of_respondant_residence',
           'FSSAI_license_number',
           'notice_issued_under_section',
           'date_notice_issued_date',
           'date_direct_case_sent_to_do_on',
           'evidence_upload'
           
       ];

       if(props?.history?.location?.state?.action_t=='case_submit')keys.push('case_converted','date_direct_case_received_by_do_on')
       if(props?.history?.location?.state?.action_t=='case_submit' && values&&values.case_converted =='Case Converted')keys.push('case_category')
       if(props?.history?.location?.state?.do)keys.push('date_do_file_received_date', 'date_do_file_sent_date')
       if(props?.history?.location?.state?.fso)keys.push('date_fso_file_received_date','date_fso_file_sent_date')
       if(props?.history?.location?.state?.ao)keys.push('date_ao_file_received_date','date_ao_filed_at_court','ao_file_number')
       if(props?.history?.location?.state?.aoResult)keys.push('ao_case_file_result')
       if(props?.history?.location?.state?.aoResult && values&&values.ao_case_file_result =='Final Judgement (Civil)')keys.push('court_result')
       if(props?.history?.location?.state?.aoResult && values&&values.ao_case_file_result =='Final Judgement (Civil)'&&values&&values.court_result=='Convicted')keys.push('penalty_amount')
       if(props?.history?.location?.state?.aoResult && values&&values.ao_case_file_result =='Hearing (Civil)')keys.push('date_next_hearing_date')
       if(props?.history?.location?.state?.hearing_ && values&&values.ao_case_file_result =='Final Judgement (Civil)')keys.push('penalty_amount')
       if(props?.history?.location?.state?.hearing_ && values&&values.ao_case_file_result =='Hearing (Civil)')keys.push('date_next_hearing_date')
       if(props?.history?.location?.state?.aoAppeal)keys.push('date_ao_appeal_received_from_do_on','appeal_ao_preferred_by','date_ao_appeal_approved_on','date_ao_filed_at_court','ao_file_number')
       if(props?.history?.location?.state?.section)keys.push('date_so_file_received_date','section_file_number','date_so_file_signed_date')
       if(props?.history?.location?.state?.appII)keys.push('date_app2_so_file_received_date','app2_need_clarification','date_app2_file_sent_date')
       if(props?.history?.location?.state?.dd)keys.push('date_dd_file_received_date','date_dd_file_sent_date','dd_need_clarification')
       if(props?.history?.location?.state?.ac)keys.push('date_ac_file_received_date','date_ac_file_sent_date','ac_need_clarification')
       if(props?.history?.location?.state?.commissioner)keys.push('date_commissioner_file_received_date','date_commissioner_file_sent_date')
       if(props?.history?.location?.state?.sectionFinal)keys.push('date_so_final_file_received_date','date_so_final_file_sent_date')
       if(props?.history?.location?.state?.doFinal)keys.push('date_do_file_received_date','date_do_file_sent_date')
       if(props?.history?.location?.state?.fsoFinal)keys.push('date_fso_file_received_date','date_fso_file_sent_date')
       if(props?.history?.location?.state?.appIIFinal)keys.push('date_approved_file_received_from_prosecution','date_app2_final_file_received_date','date_app2_final_filed_at_court','app2_case_file_number')
       if(props?.history?.location?.state?.resultAPP)keys.push('app_case_file_result')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Hearing (Criminal)')keys.push('date_app_next_hearing_date')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)')keys.push('court_result')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)'&&values&&values.court_result == 'Convicted')keys.push('app_casefine_file_result')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)' && values&& values.app_casefine_file_result=='Fine')keys.push('fine_amount')
       if(props?.history?.location?.state?.resultAPP && values&&values.app_case_file_result =='Final Judgement (Criminal)' &&values&& values.app_casefine_file_result=='Fine with imprisonment')keys.push('fine_amount_prison')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Hearing (Criminal)')keys.push('date_app_next_hearing_date')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Final Judgement (Criminal)')keys.push('app_casefine_file_result')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Final Judgement (Criminal)' && values&& values.app_casefine_file_result=='Fine')keys.push('fine_amount')
       if(props?.history?.location?.state?.hearing_ && values&&values.app_case_file_result =='Final Judgement (Criminal)' &&values&& values.app_casefine_file_result=='Fine with imprisonment')keys.push('fine_amount_prison')
       if(props?.history?.location?.state?.appealApp)keys.push('date_appeal_received_from_do_on','appeal_preferred_by','date_appeal_approved_on', 'date_app2_final_filed_at_court','app2_case_file_number',)

        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if(key=='FSSAI_license_number' && values[key].length !==14){
                updatedErrors[key] = "Fssai number should be atleast 14 characters"; 
            }
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });   
       
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }
    const getSO =(name)=>{
        var id = asignSeat.filter(i=>i.name==name)[0]
        return (id && name) ? id.id : name;
    }
    const getApp =(name)=>{
        var id = asignUser.filter(i=>i.name==name)[0]
        return (id && name) ? id.id : name;
    }
    const hhandleSubmit=async()=>{
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
        const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
          
                const resp = await onEditFSOSampleReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                window.location.reload()
            };
        }
            else { 
                const data = { ...values}
                data.status_of_report='Waiting for submit'
                const formData = new FormData();
                Object.keys(data).forEach((key) => {
                    if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                        formData.append(key, new Date(data[key]).toISOString());
                    } else {
                        formData.append(key, data[key]);
                    }
                });
                const resp = await onCreateFSOSampleReports(formData);
                if (resp && resp.data == 'success') {
                    props.history.push("/app/dep-sample-reports");
                };
            }
    }
    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            if(props.history.location.state.action_type==='analyse'){
                data.status_of_report='Waiting for analysis'
            }
          else if(props.history.location.state.action_t==='case_submit'&&values&&values.case_category==='Civil'){
                data.status_of_report=`Waiting for fso to submit file to AO`
            }
            else if(props.history.location.state.action_t==='case_submit'&&values&&values.case_category==='Criminal'){
                data.status_of_report='File submitted to section'
            }
            else if(props.history.location.state.aoResult&& values&&values.ao_case_file_result){
                data.status_of_report=values&&values.ao_case_file_result    
            }
           else if(props.history.location.state.section){
                data.status_of_report=`Waiting for Prosecution Approval`
            } 
           else if(props.history.location.state.appIIlab){
                data.status_of_report='Waiting for DO final Approval'
            }
            else if(props.history.location.state.appII){
                data.status_of_report='Waiting for prosecution final Approval'
            }
            else if(props.history.location.state.dd){
                data.status_of_report='Waiting for AC Approval'
            }
            else if(values&& user&&user.user_types&& user.user_types.includes('ac')&&values.ac_need_clarification=='Yes'){
                data.status_of_report=`Waiting for DO clarification`
            } 
            else if(props.history.location.state.ac){
                data.status_of_report='Waiting for Commissioner Approval'
            }
            else if(props.history.location.state.commissioner ){
                data.status_of_report='Waiting for section final approval'
            }
            else if(props.history.location.state.appealApp){
                data.status_of_report='Appeal Case filed at court'
            }
            else if(props.history.location.state.appealApp){
                data.status_of_report='Case filed at court'
            }
            else if(props.history.location.state.aoAppeal){
                data.status_of_report='Case filed at AO court'
            }
            else if(props.history.location.state.sectionFinal){
                data.status_of_report='Waiting for DO final Approval'
            }
            else if(props.history.location.state.doFinal){
                data.status_of_report='Waiting for fso to submit file to APP'
            }
            else if(props.history.location.state.appealRes){
                data.status_of_report='Appeal case hearing'
            }
            else if(props.history.location.state.apt){
                data.status_of_report='Appeal Case filed at court'
            }
            else if(props.history.location.state.hearing_ && values&&values.case_category==='Criminal'){
                data.status_of_report=values&&values.app_case_file_result
            }
            else if(props.history.location.state.hearing_ && values&&values.case_category==='Civil'){
                data.status_of_report=values&&values.ao_case_file_result    
            }
            else if(props.history.location.state.do){
                data.status_of_report='Waiting for fso to submit file to AO'
            }
            else if(props.history.location.state.admore){
                data.status_of_report='Waiting for DO Approval'
            }
            else if(props.history.location.state.fso){
                data.status_of_report='File submitted to AO'
            }
            else if(props.history.location.state.fsoFinal){
                data.status_of_report=`Waiting for prosecution approval to file case`
            }
            else if(props.history.location.state.anotherPart){
                data.status_of_report='Waiting for analysis'
            }
            else if(props.history.location.state.appIIFinal){
                data.status_of_report='Case filed at court'
            }
            // else if(props.history.location.state.finalApp){
            //     data.status_of_report=values&&values.app_case_file_result
            // }
            else if(props.history.location.state.resultAPP){
                data.status_of_report=values&&values.app_case_file_result
            }
            else if(props.history.location.state.ao){
                data.status_of_report='Case filed at AO court'
            }
            else if(values && values.case_converted==='Minor Faulty/Defect Case Closed'){
                data.status_of_report='Case Closed'
            }
            if(props.history.location.state.appealApp || props.history.location.state.aoAppeal ){
                data.re_appeal=true
            }
            if(props.history.location.state.clarify){
                data.clarify_request=true
            }
            if(props.history.location.state.hearing_){
                data.hearing_request=true
            }
            if(data.assign_file_to_seat){
                data.assign_file_to_seat=getSO(data.assign_file_to_seat)
            }
            if(data.assign_file_to){
                data.assign_file_to=getApp(data.assign_file_to)
            }
            if(data.assign_file_next){
                data.assign_file_next=getApp(data.assign_file_next)
            }
            console.log(data)
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
          
                const resp = await onEditFSOSampleReports(formData, values.id);
            if (resp && resp.response) {
                if(user&&user.user_types&&user.user_types.includes('so')
               ||user&&user.user_types&&user.user_types.includes('twoapp')
               ||user&&user.user_types&&user.user_types.includes('dd')
               ||user&&user.user_types&&user.user_types.includes('AO')
               ||user&&user.user_types&&user.user_types.includes('labapp')
               ||user&&user.user_types&&user.user_types.includes('ac')
               ||user&&user.user_types&&user.user_types.includes('commissioner')
               ||user&&user.user_types&&user.user_types.includes('apt')
               ||user&&user.user_types&&user.user_types.includes('oneapp')){
                props.history.push('/app/dep-ns-case-reports/')
               }

               else if((props.history.location.state&&props.history.location.state.doFinal)
               ||(props.history.location.state&&props.history.location.state.fsoFinal)
               ||(props.history.location.state&&props.history.location.state.resultAPP)
               ||(props.history.location.state&&props.history.location.state.clarify)
               ||(props.history.location.state&&props.history.location.state.do)
               ||(props.history.location.state&&props.history.location.state.doFinal)
               ||(props.history.location.state&&props.history.location.state.appIIFinal)
               ||(props.history.location.state&&props.history.location.state.fso)
               ||(props.history.location.state&&props.history.location.state.appealApp)
               ||(user&&user.user_types&&user.user_types.includes('fso') && props.history.location.state&&props.history.location.state.hearing_)
               ){
                props.history.push('/app/dep-ns-case-reports/')
               } 
              else props.history.push('/app/dep-non-sample-reports')
            };
           

        } else { 
            const data = { ...values}
            data.status_of_report='Waiting for DO Approval'
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onCreateFSOSampleReports(formData);
            if (resp && resp.data == 'success') {
                props.history.push("/app/dep-non-sample-reports");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800"> Sample Details Report</h1>
            <div className="card shadow mb-4">
            {/* {props?.history?.location?.state?.showLess? <div style={{marginLeft:'auto',marginRight:'10px'}}><button className="btn btn-success ml-3" onClick={()=>setShowLess(!showLess)}>Show detailed report</button></div>:null} */}
                <div className="card-body">
                    <form>
                   {rowView? <>
                    <a style={{margin:"20px",fontSize:'14px', marginTop:'-15px', float:'right',border:'1px solid #808080',background:'#ddd',color:'#000',padding:'7px'}} href={values&&values.evidence_upload} target='_blank'>
                   View Result</a>         
                   <div  className="filter_container row_view"> 
                    <div className="row_div">
                      <h5>District</h5>
                      <span>{getDistrictName(values&&values.district)}</span> 
                    </div>
                    <div className="row_div">
                      <h5>Sample Type</h5>
                      <span>Non-Sample</span> 
                    </div>
                    <div className="row_div">
                       <h5>Submitted by</h5> 
                       <span>{values&&values.fso_name}</span>
                    </div>
                    {/* <div className="row_div">
                       <h5>Result Type</h5> 
                      <span>{values&&values.result_type}</span>
                    </div> */}
                    </div>
                    {values&&values.case_category=='Civil'?null:<div> 
                    <table style={{width:'100%'}} className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th><b>Official Designation</b></th>
                            <th><b>Clarification requested</b></th>
                            <th><b>Remarks</b></th>
                            <th><b>Documents</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{values&&values.date_so_file_received_date?'Section Officer':'N/A'}</td>
                            <td>{values&&values.so_need_clarification?values.so_need_clarification:'N/A'}</td>
                            <td>{values&&values.so_remarks?values.so_remarks:'N/A'}</td>
                            <td>{values&&values.so_docs ? <a target='_blank' href={values&&values.so_docs}> View</a>: '-'}</td>
                            </tr>
                        <tr>
                            <td>{values&&values.date_app2_so_file_received_date?'Prosecution Officer':'N/A'}</td>
                            <td>{values&&values.app2_need_clarification?values.app2_need_clarification:'N/A'}</td>
                            <td>{values&&values.app2_remarks?values.app2_remarks:'N/A'}</td>
                            <td>{values&&values.app2_docs ? <a target='_blank' href={values&&values.app2_docs}> View</a>: '-'}</td>
                        </tr>
                        <tr>
                            <td>{values&&values.date_dd_file_received_date?'Prosecution Officer(Final)':'N/A'}</td>
                            <td>{values&&values.dd_need_clarification?values.dd_need_clarification:'N/A'}</td>
                            <td>{values&&values.dd_remarks?values.dd_remarks:'N/A'}</td>
                            <td>{values&&values.dd_docs ? <a target='_blank' href={values&&values.dd_docs}> View</a>: '-'}</td>
                        </tr>
                        <tr>
                            <td>{values&&values.date_ac_file_received_date?'AC':'N/A'}</td>
                            <td>{values&&values.ac_need_clarification?values.ac_need_clarification:'N/A'}</td>
                            <td>{values&&values.ac_remarks?values.ac_remarks:'N/A'}</td>
                            <td>{values&&values.ac_docs ? <a target='_blank' href={values&&values.ac_docs}> View</a>: '-'}</td>
                         </tr>
                         <tr>
                            <td>{values&&values.date_commissioner_file_received_date?'Commissioner':'N/A'}</td>
                            <td>{values&&values.commissioner_need_clarification?values.commissioner_need_clarification:'N/A'}</td>
                            <td>{values&&values.commissioner_remarks?values.commissioner_remarks:'N/A'}</td>
                            <td>{values&&values.commissioner_docs ? <a target='_blank' href={values&&values.commissioner_docs}> View</a>: '-'}</td>
                         </tr>
                         <tr>
                            <td>{values&&values.date_do_file_received_date?'DO':'N/A'}</td>
                            <td>{values&&values.do_need_clarification?values.do_need_clarification:'N/A'}</td>
                            <td>{values&&values.do_remarks?values.do_remarks:'N/A'}</td>
                            <td>{values&&values.do_docs ? <a target='_blank' href={values&&values.do_docs}> View</a>: '-'}</td>
                         </tr>
                         <tr>
                            <td>{values&&values.date_fso_file_received_date?'FSO':'N/A'}</td>
                            <td>{values&&values.fso_need_clarification?values.fso_need_clarification:'N/A'}</td>
                            <td>{values&&values.fso_remarks?values.fso_remarks:'N/A'}</td>
                            <td>{values&&values.fso_docs ? <a target='_blank' href={values&&values.fso_docs}> View</a>: '-'}</td>
                         </tr>
                    </tbody>
                   </table>
                    </div>}
                    {props.history.location.state&&props?.history?.location?.state?.section&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_so_file_received_date"
                                    value={values.date_so_file_received_date}
                                    error={errors.date_so_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_so_file_received_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="so_remarks"
                            >File Number  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`File Number`}
                                    name="section_file_number"
                                    value={values.section_file_number}
                                    error={errors.section_file_number}
                                    onChange={(e) =>handleTextChange('section_file_number', e.target.value)}
                                />
                            </div>
                        </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_file_signed_date"
                            >  File Submitted date * </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Submitted date`}
                                    type="text"
                                    name="date_so_file_signed_date"
                                    value={values.date_so_file_signed_date}
                                    error={errors.date_so_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_so_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="assign_file_to"
                         >Assign file to  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Assign file to`}
                                 name="assign_file_to"
                                 items={asignUser}
                                 action="dropdown"
                                 value={values.assign_file_to}
                                 error={errors.assign_file_to}
                                 onChange={(text) =>handleTextChange('assign_file_to', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="so_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.so_remarks}
                                    error={errors.so_remarks}
                                    onChange={(e) =>handleTextChange('so_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="so_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="so_docs"
                                    error={errors.so_docs}
                                    onChange={(e) =>handleTextChange("so_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                   
                      {props.history.location.state&&props?.history?.location?.state?.ao&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ao_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_ao_file_received_date"
                                    value={values.date_ao_file_received_date}
                                    error={errors.date_ao_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_ao_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ao_filed_at_court"
                            >  Case Filed at AO court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at AO court on`}
                                    type="text"
                                    name="date_ao_filed_at_court"
                                    value={values.date_ao_filed_at_court}
                                    error={errors.date_ao_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_ao_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_file_number"
                            > Case file number (CC/STC) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="ao_file_number"
                                    value={values.ao_file_number}
                                    error={errors.ao_file_number}
                                    onChange={(e) => handleTextChange("ao_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ao_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.ao_remarks}
                                    error={errors.ao_remarks}
                                    onChange={(e) =>handleTextChange('ao_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_docs"
                                    error={errors.ao_docs}
                                    onChange={(e) =>handleTextChange("ao_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                       
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.aoAppeal?<>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_ao_appeal_received_from_do_on"
                            >Appeal against AO order initiation received by do date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Received From DO On`}
                                    name="date_ao_appeal_received_from_do_on"
                                    value={values.date_ao_appeal_received_from_do_on}
                                    error={errors.date_ao_appeal_received_from_do_on}
                                    onChangeText={(text) =>handleTextChange("date_ao_appeal_received_from_do_on", text)}
                                />
                            </div>
                        </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_ao_preferred_by"
                            >Appeal Preferred By *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Preferred By`}
                                    name="appeal_ao_preferred_by"
                                    items={FiledBy}
                                    action="dropdown"
                                    value={values.appeal_ao_preferred_by}
                                    error={errors.appeal_ao_preferred_by}
                                    onChange={(text) =>handleTextChange('appeal_ao_preferred_by', text)}
                                />
                            </div>
                        </div>
                        {values && values.appeal_ao_preferred_by&&values.appeal_ao_preferred_by.includes('Others') ? 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_ao_appeal_preferred_by"
                         ></label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Appeal Preferred By`}
                                 name="other_ao_appeal_preferred_by"
                                 error={errors.other_ao_appeal_preferred_by}
                                 value={values.other_ao_appeal_preferred_by}
                                 onChange={(e) =>handleTextChange('other_ao_appeal_preferred_by', e.target.value)}
                             />
                         </div>
                     </div>
                        :null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_ao_appeal_approved_on"
                            >Appeal Approved On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Approved On`}
                                    name="date_ao_appeal_approved_on"
                                    value={values.date_ao_appeal_approved_on}
                                    error={errors.date_ao_appeal_approved_on}
                                    onChangeText={(text) =>handleTextChange('date_ao_appeal_approved_on', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ao_filed_at_court"
                            >  Case Filed at court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at AO court on`}
                                    type="text"
                                    name="date_ao_filed_at_court"
                                    value={values.date_ao_filed_at_court}
                                    error={errors.date_ao_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_ao_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_file_number"
                            >  Case file number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="ao_file_number"
                                    value={values.ao_file_number}
                                    error={errors.ao_file_number}
                                    onChange={(e) => handleTextChange("ao_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="reappeal_number"
                            > Appeal number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Appeal number`}
                                    type="text"
                                    name="reappeal_number"
                                    value={values.reappeal_number}
                                    error={errors.reappeal_number}
                                    onChange={(e) => handleTextChange("reappeal_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ao_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="ao_remarks"
                                    value={values.ao_remarks}
                                    error={errors.ao_remarks}
                                    onChange={(e) =>handleTextChange('ao_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_docs"
                                    error={errors.ao_docs}
                                    onChange={(e) =>handleTextChange("ao_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>:null}
                      {props.history.location.state&&props?.history?.location?.state?.appealApp?<>
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_appeal_received_from_do_on"
                            >Appeal Received From DO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Received From DO On`}
                                    name="date_appeal_received_from_do_on"
                                    value={values.date_appeal_received_from_do_on}
                                    error={errors.date_appeal_received_from_do_on}
                                    onChangeText={(text) =>handleTextChange("date_appeal_received_from_do_on", text)}
                                />
                            </div>
                        </div> */}
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_preferred_by"
                            >Appeal Preferred By *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Preferred By`}
                                    name="appeal_preferred_by"
                                    items={FiledBy}
                                    action="dropdown"
                                    value={values.appeal_preferred_by}
                                    error={errors.appeal_preferred_by}
                                    onChange={(text) =>handleTextChange('appeal_preferred_by', text)}
                                />
                            </div>
                        </div>
                        {/* {values && values.appeal_preferred_by&&values.appeal_preferred_by.includes('Others') ? 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_appeal_preferred_by"
                         ></label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Appeal Preferred By`}
                                 name="other_appeal_preferred_by"
                                 error={errors.other_appeal_preferred_by}
                                 value={values.other_appeal_preferred_by}
                                 onChange={(e) =>handleTextChange('other_appeal_preferred_by', e.target.value)}
                             />
                         </div>
                     </div>
                        :null} */}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_appeal_approved_on"
                            >Appeal Approved On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Approved On`}
                                    name="date_appeal_approved_on"
                                    value={values.date_appeal_approved_on}
                                    error={errors.date_appeal_approved_on}
                                    onChangeText={(text) =>handleTextChange('date_appeal_approved_on', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_final_filed_at_court"
                            >  Case Filed at court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at AO court on`}
                                    type="text"
                                    name="date_app2_final_filed_at_court"
                                    value={values.date_app2_final_filed_at_court}
                                    error={errors.date_app2_final_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_app2_final_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_case_file_number"
                            >  Case file number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="app2_case_file_number"
                                    value={values.app2_case_file_number}
                                    error={errors.app2_case_file_number}
                                    onChange={(e) => handleTextChange("app2_case_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="reappeal_number"
                            > Appeal number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Appeal number`}
                                    type="text"
                                    name="reappeal_number"
                                    value={values.reappeal_number}
                                    error={errors.reappeal_number}
                                    onChange={(e) => handleTextChange("reappeal_number", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2_final_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2_final_remarks"
                                    value={values.app2_final_remarks}
                                    error={errors.app2_final_remarks}
                                    onChange={(e) =>handleTextChange('app2_final_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_final_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2_final_docs"
                                    error={errors.app2_final_docs}
                                    onChange={(e) =>handleTextChange("app2_final_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                       
                      </>:null}
                      {props.history.location.state&&props?.history?.location?.state?.appIIFinal&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_approved_file_received_from_prosecution"
                            > Prosection approved on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Prosection approved on`}
                                    type="text"
                                    name="date_approved_file_received_from_prosecution"
                                    value={values.date_approved_file_received_from_prosecution}
                                    error={errors.date_approved_file_received_from_prosecution}
                                    onChangeText={(text) => handleTextChange("date_approved_file_received_from_prosecution",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_final_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app2_final_file_received_date"
                                    value={values.date_app2_final_file_received_date}
                                    error={errors.date_app2_final_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app2_final_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_final_filed_at_court"
                            >  Case Filed at court on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case Filed at AO court on`}
                                    type="text"
                                    name="date_app2_final_filed_at_court"
                                    value={values.date_app2_final_filed_at_court}
                                    error={errors.date_app2_final_filed_at_court}
                                    onChangeText={(text) => handleTextChange("date_app2_final_filed_at_court", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_case_file_number"
                            >  Case file number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case file number`}
                                    type="text"
                                    name="app2_case_file_number"
                                    value={values.app2_case_file_number}
                                    error={errors.app2_case_file_number}
                                    onChange={(e) => handleTextChange("app2_case_file_number", e.target.value)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2_final_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2_final_remarks"
                                    value={values.app2_final_remarks}
                                    error={errors.app2_final_remarks}
                                    onChange={(e) =>handleTextChange('app2_final_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_final_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2_final_docs"
                                    error={errors.app2_final_docs}
                                    onChange={(e) =>handleTextChange("app2_final_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                       
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.hearing_ &&values.case_category&&values.case_category==='Criminal'&&<>
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_case_file_result"
                                 items={Results}
                                 action="dropdown"
                                 value={values.app_case_file_result}
                                 error={errors.app_case_file_result}
                                 onChange={(text) =>handleTextChange('app_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.app_case_file_result=='Hearing (Criminal)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_app_next_hearing_date"
                                    value={values.date_app_next_hearing_date}
                                    error={errors.date_app_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_app_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app_hearing_remarks"
                            > Hearing  Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app_hearing_remarks"
                                    value={values.app_hearing_remarks}
                                    error={errors.app_hearing_remarks}
                                    onChange={(e) =>handleTextChange('app_hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_case_file_result=='Final Judgement (Criminal)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&&<div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_casefine_file_result"
                         >Judgement   *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_casefine_file_result"
                                 items={Fines}
                                 action="dropdown"
                                 value={values.app_casefine_file_result}
                                 error={errors.app_casefine_file_result}
                                 onChange={(text) =>handleTextChange('app_casefine_file_result', text)}
                             />
                         </div>
                     </div>}
                     
                     {values&&values.app_casefine_file_result==='Fine'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount"
                            >Fine Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine amount`}
                                    name="fine_amount"
                                    value={values.fine_amount}
                                    error={errors.fine_amount}
                                    onChange={(e) =>handleTextChange('fine_amount', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_casefine_file_result==='Fine with imprisonment'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount_prison"
                            >Fine with imprisonment  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine with imprisonment`}
                                    name="fine_amount_prison"
                                    value={values.fine_amount_prison}
                                    error={errors.fine_amount_prison}
                                    onChange={(e) =>handleTextChange('fine_amount_prison', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     </>:null}
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="court_result_doc"
                            > Judgement upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="court_result_doc"
                                    error={errors.court_result_doc}
                                    onChange={(e) =>handleTextChange("court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                         </>}
                         {props.history.location.state&&props?.history?.location?.state?.hearing_ &&values.case_category&&values.case_category==='Civil'&&<>
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="ao_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="ao_case_file_result"
                                 items={Resul}
                                 action="dropdown"
                                 value={values.ao_case_file_result}
                                 error={errors.ao_case_file_result}
                                 onChange={(text) =>handleTextChange('ao_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.ao_case_file_result=='Hearing (Civil)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_next_hearing_date"
                                    value={values.date_next_hearing_date}
                                    error={errors.date_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="hearing_remarks"
                            > Hearing  Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="hearing_remarks"
                                    value={values.hearing_remarks}
                                    error={errors.hearing_remarks}
                                    onChange={(e) =>handleTextChange('hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
    {values&&values.ao_case_file_result=='Final Judgement (Civil)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&& <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="penalty_amount"
                            >Penalty Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Penalty amount`}
                                    name="penalty_amount"
                                    value={values.penalty_amount}
                                    error={errors.penalty_amount}
                                    onChange={(e) =>handleTextChange('penalty_amount', e.target.value)}
                                />
                            </div>
                        </div>}</>:null}
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_court_result_doc"
                            > Judgement Result: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_court_result_doc"
                                    error={errors.ao_court_result_doc}
                                    onChange={(e) =>handleTextChange("ao_court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                        </>}
                      {props.history.location.state&&props?.history?.location?.state?.resultAPP&&<>
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_case_file_result"
                                 items={Results}
                                 action="dropdown"
                                 value={values.app_case_file_result}
                                 error={errors.app_case_file_result}
                                 onChange={(text) =>handleTextChange('app_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.app_case_file_result=='Hearing (Criminal)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_app_next_hearing_date"
                                    value={values.date_app_next_hearing_date}
                                    error={errors.date_app_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_app_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app_hearing_remarks"
                            > Hearing  Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app_hearing_remarks"
                                    value={values.app_hearing_remarks}
                                    error={errors.app_hearing_remarks}
                                    onChange={(e) =>handleTextChange('app_hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_case_file_result=='Final Judgement (Criminal)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&&<div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app_casefine_file_result"
                         >Judgement   *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="app_casefine_file_result"
                                 items={Fines}
                                 action="dropdown"
                                 value={values.app_casefine_file_result}
                                 error={errors.app_casefine_file_result}
                                 onChange={(text) =>handleTextChange('app_casefine_file_result', text)}
                             />
                         </div>
                     </div>}
                     {values&&values.app_casefine_file_result==='Fine'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount"
                            >Fine Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine amount`}
                                    name="fine_amount"
                                    value={values.fine_amount}
                                    error={errors.fine_amount}
                                    onChange={(e) =>handleTextChange('fine_amount', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     {values&&values.app_casefine_file_result==='Fine with imprisonment'?<>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fine_amount_prison"
                            >Fine with imprisonment  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fine with imprisonment`}
                                    name="fine_amount_prison"
                                    value={values.fine_amount_prison}
                                    error={errors.fine_amount_prison}
                                    onChange={(e) =>handleTextChange('fine_amount_prison', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     </>:null}
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="court_result_doc"
                            > Judgement Result: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="court_result_doc"
                                    error={errors.court_result_doc}
                                    onChange={(e) =>handleTextChange("court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {props.history.location.state&&props?.history?.location?.state?.aoResult&&<>
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="ao_case_file_result"
                         >Result  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Result`}
                                 name="ao_case_file_result"
                                 items={Resul}
                                 action="dropdown"
                                 value={values.ao_case_file_result}
                                 error={errors.ao_case_file_result}
                                 onChange={(text) =>handleTextChange('ao_case_file_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.ao_case_file_result=='Hearing (Civil)'?<>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_next_hearing_date"
                            >  Next hearing date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next hearing date`}
                                    type="text"
                                    name="date_next_hearing_date"
                                    value={values.date_next_hearing_date}
                                    error={errors.date_next_hearing_date}
                                    onChangeText={(text) => handleTextChange("date_next_hearing_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="hearing_remarks"
                            > Hearing  Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="hearing_remarks"
                                    value={values.hearing_remarks}
                                    error={errors.hearing_remarks}
                                    onChange={(e) =>handleTextChange('hearing_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                     </>:null}
                     
                     {values&&values.ao_case_file_result=='Final Judgement (Civil)'?<>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="court_result"
                         >Judgement Result *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Judgement`}
                                 name="court_result"
                                 items={JudgementResult}
                                 action="dropdown"
                                 value={values.court_result}
                                 error={errors.court_result}
                                 onChange={(text) =>handleTextChange('court_result', text)}
                             />
                         </div>
                     </div>
                     {values&&values.court_result=='Convicted'&& <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="penalty_amount"
                            >Penalty Amount  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Penalty amount`}
                                    name="penalty_amount"
                                    value={values.penalty_amount}
                                    error={errors.penalty_amount}
                                    onChange={(e) =>handleTextChange('penalty_amount', e.target.value)}
                                />
                            </div>
                        </div>}</>:null}
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ao_court_result_doc"
                            > Judgement upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ao_court_result_doc"
                                    error={errors.ao_court_result_doc}
                                    onChange={(e) =>handleTextChange("ao_court_result_doc",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {(props.history.location.state&&props?.history?.location?.state?.do||props?.history?.location?.state?.doFinal )&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_do_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_do_file_received_date"
                                    value={values.date_do_file_received_date}
                                    error={errors.date_do_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_do_file_received_date",text)}
                                />
                            </div>
                        </div>                   
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_do_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_do_file_signed_date"
                                    value={values.date_do_file_signed_date}
                                    error={errors.date_do_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_do_file_signed_date",text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_do_file_sent_date"
                            >  File sent to fso  *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent to fso`}
                                    type="text"
                                    name="date_do_file_sent_date"
                                    value={values.date_do_file_sent_date}
                                    error={errors.date_do_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_do_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="do_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.do_remarks}
                                    error={errors.do_remarks}
                                    onChange={(e) =>handleTextChange('do_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="do_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="do_docs"
                                    error={errors.do_docs}
                                    onChange={(e) =>handleTextChange("do_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {(props.history.location.state&&props?.history?.location?.state?.fso||props?.history?.location?.state?.fsoFinal)&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_fso_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_fso_file_received_date"
                                    value={values.date_fso_file_received_date}
                                    error={errors.date_fso_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_fso_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                     {/* <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_fso_file_signed_date"
                            >  File Signed date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_fso_file_signed_date"
                                    value={values.date_fso_file_signed_date}
                                    error={errors.date_fso_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_fso_file_signed_date", text)}
                                />
                            </div>
                        </div>  */}
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_fso_file_sent_date"
                            >  File submitted date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Submitted date`}
                                    type="text"
                                    name="date_fso_file_sent_date"
                                    value={values.date_fso_file_sent_date}
                                    error={errors.date_fso_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_fso_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fso_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.fso_remarks}
                                    error={errors.fso_remarks}
                                    onChange={(e) =>handleTextChange('fso_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="fso_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="fso_docs"
                                    error={errors.fso_docs}
                                    onChange={(e) =>handleTextChange("fso_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props?.history?.location?.state?.sectionFinal&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_final_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_so_final_file_received_date"
                                    value={values.date_so_final_file_received_date}
                                    error={errors.date_so_final_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_so_final_file_received_date",text)}
                                />
                            </div>
                        </div> 
                      
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_so_final_file_sent_date"
                            >  File submitted date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Submitted date`}
                                    type="text"
                                    name="date_so_final_file_sent_date"
                                    value={values.date_so_final_file_sent_date}
                                    error={errors.date_so_final_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_so_final_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="so_remarks"
                            >Remarks  *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="remarks"
                                    value={values.so_remarks}
                                    error={errors.so_remarks}
                                    onChange={(e) =>handleTextChange('so_remarks', e.target.value)}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="fso_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="fso_docs"
                                    error={errors.fso_docs}
                                    onChange={(e) =>handleTextChange("fso_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.appIIlab&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2lab_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app2lab_file_received_date"
                                    value={values.date_app2lab_file_received_date}
                                    error={errors.date_app2lab_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app2lab_file_received_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2lab_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_app2lab_file_signed_date"
                                    value={values.date_app2lab_file_signed_date}
                                    error={errors.date_app2lab_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_app2lab_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2lab_file_sent_date"
                            >  File sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_app2lab_file_sent_date"
                                    value={values.date_app2lab_file_sent_date}
                                    error={errors.date_app2lab_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_app2lab_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2lab_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2lab_remarks"
                                    value={values.app2lab_remarks}
                                    error={errors.app2lab_remarks}
                                    onChange={(e) =>handleTextChange('app2lab_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2lab_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2lab_docs"
                                    error={errors.app2lab_docs}
                                    onChange={(e) =>handleTextChange("app2lab_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.appII&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_so_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app2_so_file_received_date"
                                    value={values.date_app2_so_file_received_date}
                                    error={errors.date_app2_so_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app2_so_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app2_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="app2_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.app2_need_clarification}
                                 error={errors.app2_need_clarification}
                                 onChange={(text) =>handleTextChange('app2_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="assign_file_next"
                            >Assign file to  *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Assign file to`}
                                    name="assign_file_next"
                                    items={asignUser}
                                    action="dropdown"
                                    value={values.assign_file_next}
                                    error={errors.assign_file_next}
                                    onChange={(text) =>handleTextChange('assign_file_next', text)}
                                />
                            </div>
                        </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_app2_file_signed_date"
                                    value={values.date_app2_file_signed_date}
                                    error={errors.date_app2_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_app2_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app2_file_sent_date"
                            >  File sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_app2_file_sent_date"
                                    value={values.date_app2_file_sent_date}
                                    error={errors.date_app2_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_app2_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app2_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app2_remarks"
                                    value={values.app2_remarks}
                                    error={errors.app2_remarks}
                                    onChange={(e) =>handleTextChange('app2_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app2_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app2_docs"
                                    error={errors.app2_docs}
                                    onChange={(e) =>handleTextChange("app2_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.appI&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app1_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_app1_file_received_date"
                                    value={values.date_app1_file_received_date}
                                    error={errors.date_app1_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_app1_file_received_date",text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="app1_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="app1_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.app1_need_clarification}
                                 error={errors.app1_need_clarification}
                                 onChange={(text) =>handleTextChange('app1_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app1_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_app1_file_signed_date"
                                    value={values.date_app1_file_signed_date}
                                    error={errors.date_app1_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_app1_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_app1_file_sent_date"
                            >  File Sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_app1_file_sent_date"
                                    value={values.date_app1_file_sent_date}
                                    error={errors.date_app1_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_app1_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="app1_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="app1_remarks"
                                    value={values.app1_remarks}
                                    error={errors.app1_remarks}
                                    onChange={(e) =>handleTextChange('app1_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="app1_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="app1_docs"
                                    error={errors.app1_docs}
                                    onChange={(e) =>handleTextChange("app1_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>} 
                      {props.history.location.state&&props.history.location.state.ac&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ac_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_ac_file_received_date"
                                    value={values.date_ac_file_received_date}
                                    error={errors.date_ac_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_ac_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="ac_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="ac_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.ac_need_clarification}
                                 error={errors.ac_need_clarification}
                                 onChange={(text) =>handleTextChange('ac_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ac_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_ac_file_signed_date"
                                    value={values.date_ac_file_signed_date}
                                    error={errors.date_ac_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_ac_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_ac_file_sent_date"
                            >  File Sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Sent date`}
                                    type="text"
                                    name="date_ac_file_sent_date"
                                    value={values.date_ac_file_sent_date}
                                    error={errors.date_ac_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_ac_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ac_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="ac_remarks"
                                    value={values.ac_remarks}
                                    error={errors.ac_remarks}
                                    onChange={(e) =>handleTextChange('ac_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="ac_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="ac_docs"
                                    error={errors.ac_docs}
                                    onChange={(e) =>handleTextChange("ac_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {props.history.location.state&&props.history.location.state.dd&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_dd_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_dd_file_received_date"
                                    value={values.date_dd_file_received_date}
                                    error={errors.date_dd_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_dd_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="dd_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="dd_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.dd_need_clarification}
                                 error={errors.dd_need_clarification}
                                 onChange={(text) =>handleTextChange('dd_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_dd_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_dd_file_signed_date"
                                    value={values.date_dd_file_signed_date}
                                    error={errors.date_dd_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_dd_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_dd_file_sent_date"
                            >  File Sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_dd_file_sent_date"
                                    value={values.date_dd_file_sent_date}
                                    error={errors.date_dd_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_dd_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="dd_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="dd_remarks"
                                    value={values.dd_remarks}
                                    error={errors.dd_remarks}
                                    onChange={(e) =>handleTextChange('dd_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="dd_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="dd_docs"
                                    error={errors.dd_docs}
                                    onChange={(e) =>handleTextChange("dd_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      {props.history.location.state&&props.history.location.state.commissioner&&<>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_commissioner_file_received_date"
                            >  File Received date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Received date`}
                                    type="text"
                                    name="date_commissioner_file_received_date"
                                    value={values.date_commissioner_file_received_date}
                                    error={errors.date_commissioner_file_received_date}
                                    onChangeText={(text) => handleTextChange("date_commissioner_file_received_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="commissioner_need_clarification"
                         >Need Clarification  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="commissioner_need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.commissioner_need_clarification}
                                 error={errors.commissioner_need_clarification}
                                 onChange={(text) =>handleTextChange('commissioner_need_clarification', text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_commissioner_file_signed_date"
                            >  File Signed date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File Signed date`}
                                    type="text"
                                    name="date_commissioner_file_signed_date"
                                    value={values.date_commissioner_file_signed_date}
                                    error={errors.date_commissioner_file_signed_date}
                                    onChangeText={(text) => handleTextChange("date_commissioner_file_signed_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_commissioner_file_sent_date"
                            >  File sent date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`File sent date`}
                                    type="text"
                                    name="date_commissioner_file_sent_date"
                                    value={values.date_commissioner_file_sent_date}
                                    error={errors.date_commissioner_file_sent_date}
                                    onChangeText={(text) => handleTextChange("date_commissioner_file_sent_date", text)}
                                />
                            </div>
                        </div> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="commissioner_remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="commissioner_remarks"
                                    value={values.commissioner_remarks}
                                    error={errors.commissioner_remarks}
                                    onChange={(e) =>handleTextChange('commissioner_remarks', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="commissioner_docs"
                            > Upload documents(if any): </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload documents`}
                                    type="file"
                                    name="commissioner_docs"
                                    error={errors.commissioner_docs}
                                    onChange={(e) =>handleTextChange("commissioner_docs",e.target.files[0])}
                                />
                            </div>
                        </div>
                      </>}
                      <div style={{marginTop:'40px'}} className="form-group">
                            <button
                                onClick={() => handlBack()}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div> 
                    </>:<>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        // isDisabled={props.history.location.state.values.district}
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                     {wardsCategory&&<div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                                {props?.history?.location?.state?.values?.area_code?
                                <TextInput
                                placeholder={`Area code`}
                                name="area_name"
                                id="area_name"
                                action="area_code"
                                // disabled
                                value={values.area_code}
                                error={errors.area_code}
                                onChange={(e) => handleTextChange('area_code', e.target.value)}
                            />:
                            <DropDownInput
                            type="text"
                            name="area_code"
                            action="area_code"
                            value={values.area_code}
                            error={errors.area_code}
                            items={wardsCategory}
                            onChange={(value) => handleTextChange("area_code", value)}
                             />
                            }

                            </div>
                        </div>}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    placeholder={`Area name`}
                                    name="area_name"
                                    id="area_name"
                                    // disabled={props?.history?.location?.state?.values?.area_name}
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange('area_name', e.target.value)}
                                />
                            </div>
                        </div>
               

                   
                        {!props.history.location.state ||  showLess ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_trader"
                            >Type of Trader *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of Trader`}
                                    type="text"
                                    items={TypeOfTraders}
                                    action="dropdown"
                                    name="type_of_trader"
                                    value={values.type_of_trader}
                                    error={errors.type_of_trader}
                                    onChange={(text) =>handleTextChange("type_of_trader", text)}
                                />
                            </div>
                        </div> 
                        {values && values.type_of_trader&&values.type_of_trader.includes('Others') ? (
                               <div className="form-group row">
                               <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_trader"
                               ></label>
                               <div className="col-sm-8">
                                   <TextInput
                                       placeholder={`Type of Trader`}
                                       type="text"
                                       name="other_type_of_trader"
                                       value={values.other_type_of_trader}
                                       error={errors.other_type_of_trader}
                                       onChange={(e) =>handleTextChange("other_type_of_trader", e.target.value)}
                                   />
                               </div>
                           </div>    
                        ) : null}
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="name_of_respondant_premisis"
                            >   Owner Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={` Owner Name`}
                                    type="text"
                                    name="name_of_respondant_premisis"
                                    value={values.name_of_respondant_premisis}
                                    error={errors.name_of_respondant_premisis}
                                    onChange={(e) => handleTextChange("name_of_respondant_premisis", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="address_of_respondant_premisis"
                            >  Owner Address *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={` Owner Address`}
                                    type="text"
                                    name="address_of_respondant_premisis"
                                    value={values.address_of_respondant_premisis}
                                    error={errors.address_of_respondant_premisis}
                                    onChange={(e) => handleTextChange("address_of_respondant_premisis", e.target.value)}
                                />
                            </div>
                        </div>
                             <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_respondant"
                            >Name of the Respondant *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the respondant`}
                                    type="text"
                                    name="name_of_the_respondant"
                                    value={values.name_of_the_respondant}
                                    error={errors.name_of_the_respondant}
                                    onChange={(e) =>handleTextChange("name_of_the_respondant", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_respondant_residence"
                            >Address of the Respondant Residence *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Respondant Residence`}
                                    type="text"
                                    name="address_of_respondant_residence"
                                    value={values.address_of_respondant_residence}
                                    error={errors.address_of_respondant_residence}
                                    onChange={(e) =>handleTextChange("address_of_respondant_residence", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_documents_id_proof"
                            >Upload documents of ID Proof if any : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_documents_id_proof"
                                    error={errors.upload_documents_id_proof}
                                    onChange={(e) => handleTextChange("upload_documents_id_proof", e.target.files[0])}
                                />
                            </div>
                        </div>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="FSSAI_license_number"
                            >FSSAI License Number : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`FSSAI License Number`}
                                    type="text"
                                    name="FSSAI_license_number"
                                    value={values.FSSAI_license_number}
                                    error={errors.FSSAI_license_number}
                                    onChange={(e) =>handleTextChange("FSSAI_license_number", e.target.value)}
                                />
                            </div>
                        </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_document_of_license"
                            >Upload Document of License : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_document_of_license"
                                    error={errors.upload_document_of_license}
                                    onChange={(e) =>handleTextChange("upload_document_of_license", e.target.files[0])}
                                />
                            </div>
                        </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_notice_issued_date"
                            >Notice Issued Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Notice Issue Date`}
                                    name="date_notice_issued_date"
                                    value={values.date_notice_issued_date}
                                    error={errors.date_notice_issued_date}
                                    onChangeText={(text) =>handleTextChange("date_notice_issued_date", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="notice_issued_under_section"
                            >Notice Issued Under Section *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Notice Issued Under Section`}
                                    type="text"
                                    name="notice_issued_under_section"
                                    value={values.notice_issued_under_section}
                                    error={errors.notice_issued_under_section}
                                    onChange={(e) =>handleTextChange("notice_issued_under_section", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_direct_case_sent_to_do_on"
                            >Direct Case Documents and Evidence Sent to DO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Direct Case Sent to DO On`}
                                    name="date_direct_case_sent_to_do_on"
                                    value={values.date_direct_case_sent_to_do_on}
                                    error={errors.date_direct_case_sent_to_do_on}
                                    onChangeText={(text) =>handleTextChange("date_direct_case_sent_to_do_on", text)}
                                />
                            </div>
                        </div>
                       
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_approved_case_file_received_from_do_on"
                            >Approved Case File From DO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Approved Case File From DO On`}
                                    type="text"
                                    name="date_approved_case_file_received_from_do_on"
                                    value={values.date_approved_case_file_received_from_do_on}
                                    error={errors.date_approved_case_file_received_from_do_on}
                                    onChangeText={(text) =>handleTextChange("date_approved_case_file_received_from_do_on", text)}
                                />
                            </div>
                        </div> */}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="evidence_upload"
                            >Evidence Upload *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="evidence_upload"
                                    type="file"
                                    id="evidence_upload"
                                    error={errors.evidence_upload}
                                    onChange={(e) => handleTextChange('evidence_upload', e.target.files[0])}
                                />
                            </div>
                        </div> 
                        {props?.history?.location?.state?.action_t&&props?.history?.location?.state?.action_t=='case_submit'?
                    <> 
                    <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_direct_case_received_by_do_on"
                            >Direct Case file received on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Direct Case file received on`}
                                    name="date_direct_case_received_by_do_on"
                                    value={values.date_direct_case_received_by_do_on}
                                    error={errors.date_direct_case_received_by_do_on}
                                    onChangeText={(text) =>handleTextChange("date_direct_case_received_by_do_on", text)}
                                />
                            </div>
                        </div>
                      <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_converted"
                            >Case Result *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Converted`}
                                    name="case_converted"
                                    items={Converted}
                                    action="dropdown"
                                    value={values.case_converted}
                                    error={errors.case_converted}
                                    onChange={(text) =>handleTextChange("case_converted", text)}
                                />
                            </div>
                        </div>
                        { values && values.case_converted&& values.case_converted.includes('Case Converted')? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_category"
                            >Case Category: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Category`}
                                    name="case_category"
                                    items={Case}
                                    action="dropdown"
                                    value={values.case_category}
                                    error={errors.case_category}
                                    onChange={(text) =>handleTextChange("case_category", text)}
                                />
                            </div>
                            {values&&values.case_category==='Criminal'?<> 
                      <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="assign_file_to_seat"
                         >Assign file to  *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Assign file to`}
                                 name="assign_file_to_seat"
                                 items={asignSeat}
                                 action="dropdown"
                                 value={values.assign_file_to_seat}
                                 error={errors.assign_file_to_seat}
                                 onChange={(text) =>handleTextChange('assign_file_to_seat', text)}
                             />
                         </div>
                     </div> </>:null} 
                        </div></>:null}
        
                        </>
                        :null}
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_approved_on"
                            >Direct Case Approved On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Direct Case Approved On`}
                                    name="direct_case_approved_on"
                                    value={values.direct_case_approved_on}
                                    error={errors.direct_case_approved_on}
                                    onChangeText={(text) =>handleTextChange("direct_case_approved_on", text)}
                                />
                            </div>
                        </div> */}
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="direct_case_sent_on"
                            >Direct Case Sent To (APP_Lab/Commissionerate) On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Direct Case Sent to (APP Lab/Commissionerate) on`}
                                    name="direct_case_sent_on"
                                    value={values.direct_case_sent_on}
                                    error={errors.direct_case_sent_on}
                                    onChangeText={(text) =>handleTextChange("direct_case_sent_on", text)}
                                />
                            </div>
                        </div> */}
                            </>:null}
                        <div className="form-group">
                            <button
                                onClick={() => handlBack()}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                              {user&&user.user_types&&user.user_types.includes('fso')&&<button
                               onClick={hhandleSubmit}
                                className="btn btn-success"
                                style={{marginLeft:'20px'}}
                                type="button">
                                Save</button>}
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div> 
                        </>}
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFSOSampleReports: (data) => dispatch(createFSOSNonSampleReports(data)),
    onEditFSOSampleReports: (data, record_id) => dispatch(editFSONonSampleReports(data, record_id)),
    ongetLabs: (data) => dispatch(testingLab(data)),
    ongetSample: (data) => dispatch(createSampleSuccess(data)),
    ongetSampe:(data)=>dispatch(updateSample(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
    ongetAssignUser:(data)=>dispatch(getAsignUser(data)),
    onCreateAppeal:(data,id)=>dispatch(createAppeal(data,id)),
    ongetFoodCategory:(data,id)=>dispatch(getFoodCategory(data,id)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    users: state.home.users,
    wards: state.home.wards,
    traderTypes: state.home.traderTypes,
    wardsCategory:state.home.wardsCategory,
    foodTypes: state.home.foodTypes,
    fsoOfficers: state.home.fsoOfficers,
    fso_submit_data:state.home.fso_submit_data,
    sample_data:state.home.sample_data,
    lab_analyse_sample:state.home.lab_analyse_sample,


});
  
export default connect(mapStateToProps, mapDispatchToProps)(FSONonSampleReport);