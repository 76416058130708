import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const LABSampleDetails = (props) => {
    const { districts, traderTypes, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">LAB - Sample Details Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Sample Fit For Analysis </th>
                 <td className="text-left">{records.sample_fit_for_analysis}</td>
                </tr>
                {records.date_sample_received_on && <tr>
                  <th>First Part Sample Received On </th>
                 <td className="text-left">{moment(records.date_sample_received_on).format("l")}</td>
                </tr>}
               {records.sample_analysed && <tr>
                  <th>Sample Analysed</th>
                 <td className="text-left">{records.sample_analysed}</td>
                </tr>}
                {records.date_sample_analysed_date &&  <tr>
                 <th>Sample Analysed Date </th>
                 <td className="text-left">{moment(records.date_sample_analysed_date).format("l")}</td>
                </tr>}
              {records.result_number && <tr>
                  <th>Result Number </th>
                 <td className="text-left">{records.result_number}</td>
                </tr>}
               {records.result_details && <tr>
                  <th>Result Analysed Date </th>
                 <td className="text-left">{moment(records.result_details).format("l")}</td>
                </tr>}
                {records.result_type && <tr>
                  <th>Result Type</th>
                 <td className="text-left">{records.result_type}</td>
                </tr>}
                {records.other_result_type && <tr>
                  <th>Result Type - Other</th>
                 <td className="text-left">{records.other_result_type}</td>
                </tr>}
                {records.date_result_signed_date && <tr>
                  <th>Result Signed Date  </th>
                 <td className="text-left">{moment(records.date_result_signed_date).format("l")}</td>
                </tr>}
                {records.report_intimated_to_DO_by && <tr>
                  <th>Result Intimated To DO By  </th>
                 <td className="text-left">{moment(records.report_intimated_to_DO_by).format("l")}</td>
                </tr>}
                {records.date_report_intimated_to_DO_on && <tr>
                  <th>Report Intimted to DO On </th>
                 <td className="text-left">{moment(records.date_report_intimated_to_DO_on).format("l")}</td>
                </tr>}
                {records.upload_documents && <tr>
                  <th>Upload Documents </th>
                 <td className="text-left">{records.upload_documents}</td>
                </tr>}
                {records.date_delay_intimation && <tr>
                  <th>Intimation given to DO/Head Office On </th>
                 <td className="text-left">{moment(records.date_delay_intimation).format("l")}</td>
                </tr>}
                {records.delay_documents && <tr>
                  <th>Upload Documents </th>
                 <td className="text-left">{moment(records.delay_documents).format("l")}</td>
                </tr>}
                {records.date_intimation_sent_to_do_on && <tr>
                  <th>Intimation Sent to DO On </th>
                 <td className="text-left">{moment(records.date_intimation_sent_to_do_on).format("l")}</td>
                </tr>}
                {records.date_second_part_sample_received_on && <tr>
                  <th>Second Part Sample Received On</th>
                 <td className="text-left">{moment(records.date_second_part_sample_received_on).format("l")}</td>
                </tr>}
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(LABSampleDetails);