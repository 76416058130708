import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createFboReports, createFundDistributed, editFboReports , editFundDistributed, getDistrictWards, getFundDetails, getFundOverallDetails, getoverall, testingLab} from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";

const FinanceDistribute = (props) => {
    const { districts, user,wards, oncreatefundDistributed,ongetFund,wardsCategory,ongetOverallFund,onfoodlabs, oneditFunddistributed, traderTypes } = props;
    const [values, setValues] = useState({
        fund:'',
        distributed_to:'',
        district:'',
        lab:'',
        distributed_date:'',
        distributed_amount:'',
        distribute_attachment:'',
        comments:'',
    });
    const [errors, setErrors] = useState({});
    const [error, setError] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const[foodLabs, setFoodLabs]=useState([]);
    const[selectedfund, setSelectedfund]=useState();
    const[fundlist, setFundlist]=useState([]);
    const[overall, setOverall]=useState();
    let { slug } = useParams();
 const Distributed = [
    { id: "1", name: "District" },
    { id: "2", name: "Food Lab" },
    { id: "3", name: "Commissionerate" },
 ]
 

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line   
    }, [])
    useEffect(()=>{
        wardsCategory&&wardsCategory.map((o)=>{
            if(o.id == values.fso_area_code)
            return (
                setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.fso_area_code])
    districts&&districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getlabs=async()=>{
        const resp = await onfoodlabs()
        if(resp&&resp.response){
            setFoodLabs(resp.response)
        }
    }
    const getfunds=async()=>{
        const resp = await ongetFund()
        console.log(resp)
        if(resp&&resp.response){
            setFundlist(resp.response)
        }
    }
    useEffect(()=>{
        getlabs()
        getfunds()
        getoverall()
    },[])
        const getoverall=async()=>{
            const resp=await ongetOverallFund()
            console.log(resp)
            if(resp&&resp.response){setOverall(resp.response)}

        }
    const validateValues = (values) => {
        console.log(parseInt(values.distributed_amount),(selectedfund&&selectedfund.remaining_credit_amount))
        console.log(values&&values.distributed_amount&&parseInt(values.distributed_amount) > parseInt(selectedfund&&selectedfund.remaining_credit_amount))
        var updatedErrors = {};
        var keys = [
            'fund',
            'distributed_to',
            'distributed_amount',
        ];
        if(values&&values.distributed_to&&values.distributed_to=='District')keys.push('district')
        if(values&&values.distributed_to&&values.distributed_to=='Food Labs')keys.push('lab')
        if(values&&values.distributed_amount&&parseInt(values.distributed_amount) > parseInt(selectedfund&&selectedfund.remaining_credit_amount)){
            keys.push('distributed_amount')
            setError(true)
        } else setError(false)
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key== 'distributed_date') {
                    formData.append(key, moment(data[key]).format('YYYY-MM-DD'));
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await oneditFunddistributed(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/reports`);
            };

        } else {
            const data = { ...values}
            setDisableSubmit(true);
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key== 'distributed_date') {
                    formData.append(key, moment(data[key]).format('YYYY-MM-DD'));
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await oncreatefundDistributed(formData);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports");
            };
        }       
        
    }
    const getfun=async()=>{
        const resp = await ongetFund()
        const funlist=resp?.response
        if(funlist&&funlist?.length>0){
            let arr = funlist&&funlist.filter(i=>i.id==values.fund)[0]
            setSelectedfund(arr) 
        }
    }
    useEffect(()=>{
        getfun()
    },[values&&values.fund])
    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        if(key=='fund'){
            let arr = fundlist&&fundlist.filter(i=>i.id==value)[0]
            setSelectedfund(arr)
            console.log(arr)
        }
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Fund Distribution Report</h1>
            <div className="card shadow mb-4">
            <div className="row">
            <div className="container-fluid">
              <div className="card-deck">
            <div className="card bg-success">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {overall?.overall_credit
                        ? overall.overall_credit
                        : "0"}
                    </button>
                    <p className="text text-light">Fund Credited</p>
                  </div>
                </div>
                <div className="card bg-info">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {overall?.overall_distributed
                        ? overall.overall_distributed
                        : "0"}
                    </button>
                    <p className="text text-light">Fund Distributed</p>
                  </div>
                </div>
                <div className="card bg-success">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {overall?.remaining_credit
                        ? overall.remaining_credit
                        : "0"}
                    </button>
                    <p className="text text-light">Fund Remaining</p>
                  </div>
                </div>
                </div>
                </div>
                </div>
                <div className="card-body">
                    <form>
                        
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fund"
                            >Fund Name *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        name="fund"
                                        value={values.fund}
                                        error={errors.fund }
                                        items={fundlist}
                                        onChange={(value) => handleTextChange("fund", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="distributed_to"
                            >Fund Distributed to *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="distributed_to"
                                        action="dropdown"
                                        value={values.distributed_to}
                                        error={errors.distributed_to }
                                        items={Distributed}
                                        onChange={(value) => handleTextChange("distributed_to", value)}
                                />
                            </div>
                        </div> 
                      {values&&values.distributed_to.includes('District')&&(  <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>)}
                            {values&&values.distributed_to.includes('Food Lab')&&( <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="lab"
                            >Food lab *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        name="lab"
                                        action="dropdown"
                                        value={values.lab}
                                        error={errors.lab }
                                        items={foodLabs}
                                        onChange={(value) => handleTextChange("lab", value)}
                                />
                            </div>
                        </div>  )}
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="distributed_date"
                                >Distributed date : </label>
                                <div className="col-sm-8">
                                <Datepicker
                                            placeholder='Distributed Date'
                                            name="distributed_date"
                                            value={values.distributed_date}
                                            error={errors.distributed_date }
                                            onChangeText={(value) => handleTextChange("distributed_date", value)}
                                    />
                                </div>
                            </div>   
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="trader_category"
                                >Distributed Amount*: </label>
                                <div className="col-sm-8">
                                <TextInput
                                            placeholder='Distributed Amount'
                                            type="number"
                                            name="distributed_amount"
                                            value={values.distributed_amount}
                                            error={errors.distributed_amount }
                                            onChange={(e) => handleTextChange("distributed_amount", e.target.value)}
                                    />
                                </div>
                            </div>   
                            {error && <span style={{color:'red', textAlign:'center',marginLeft:'120px',fontSize:'13px',marginTop:'-22px',fontWeight:'bold'}}>Distributed amount should not exceed the available fund</span>}
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="comments"
                            >Comments: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Comments`}
                                    name="comments"
                                    value={values.comments}
                                    error={errors.comments}
                                    onChange={(e) => handleTextChange("comments", e.target.value)}
                                />
                            </div>
                        </div>  
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="distribute_attachment"
                            >Attachment: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Attachment`}
                                    type="file"
                                    name="distribute_attachment"
                                    id="distribute_attachment"
                                    error={errors.distribute_attachment}
                                    onChange={(e) => handleTextChange("distribute_attachment", e.target.files[0])}
                                />
                            </div>
                        </div>                   
                                 
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={error?error:disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    oncreatefundDistributed: (data) => dispatch(createFundDistributed(data)),
    oneditFunddistributed: (data, record_id) => dispatch(editFundDistributed(data, record_id)),
    onGetnewWards:(data)=>dispatch(getDistrictWards(data)),
    onfoodlabs:(data)=>dispatch(testingLab(data)),
    ongetFund:(data)=>dispatch(getFundDetails(data)),
    ongetOverallFund:(data)=>dispatch(getFundOverallDetails(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
    traderTypes: state.home.traderTypes,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(FinanceDistribute);