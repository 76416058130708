import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createTnDailyReports, editTnDailyReports, getDistrictWards } from "../../components/reducers/actions"
import Datepicker from '../../components/inputs/Datepicker'
import { useParams } from "react-router-dom";
import moment from 'moment'

const TnDailyReports = (props) => {
    const { districts, user, wards,ongetDist, onCreateTnDailyReports,wardsCategory, onEditTnDailyReports } = props;
    const [values, setValues] = useState({
        'name_of_the_fso_do': '',
        'date': '',
        'area_name': '',
        'area_code': '',
        'no_of_school_college_hostel_visited': '',
        'no_of_school_college_canteen_visited': '',
        'total_no_of_school_college_visited': '',
        'no_of_iec_given': '',
        'no_of_notice_issued': '',
        'no_of_visited_amma_unavakam': '',
        'no_of_iec_issued_amma_unavakam': '',
        'no_of_notice_issued_amma_unavakam': '',
        'no_of_ration_shop_visited': '',
        'no_of_notice_issued_ration_shop': '',
        'no_of_iec_issued_ration_shop': '',
        'no_of_tasmac_visited': '',
        'no_of_notice_issued_tasmac': '',
        'no_of_iec_given_tasmac': '',
        'no_of_hospital_canteen_visited': '',
        'no_of_notice_issued_hospital_canteen': '',
        'no_of_iec_given_hospital_canteen': '',
        'no_of_icds_visited': '',
        'no_of_noon_meals_centres_visited': '',
        'total_no_of_icds_noon_meals_centres_visited': '',
        'no_of_iec_given_icds_noon_meal_centres': '',
        'no_of_notice_issued_icds_noon_meal_centres': '',
        'no_of_inspected_fbo_premises': '',
        'no_of_iec_given_fbo_premises': '',
        'no_of_notice_issued_fbo_premises': '',
        'no_of_complaints_received_upto_the_date': '',
        'no_of_complaints_received_during_the_date': '',
        'no_of_complaints_attended': '',
        'no_of_complaints_pending': '',
        'no_of_iec_meetings_organized': '',
        'no_of_consumers_attended': '',
        'district': ''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if (user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'name_of_the_fso_do',
            'date',
            'area_name',
            'area_code',
            'no_of_school_college_hostel_visited',
            'no_of_school_college_canteen_visited',
            'total_no_of_school_college_visited', 
            'no_of_iec_given', 
            'no_of_notice_issued', 
            'no_of_visited_amma_unavakam', 
            'no_of_iec_issued_amma_unavakam',
             'no_of_notice_issued_amma_unavakam',
            'no_of_ration_shop_visited',
             'no_of_notice_issued_ration_shop', 
             'no_of_iec_issued_ration_shop',
              'no_of_tasmac_visited',
            'no_of_notice_issued_tasmac',
             'no_of_iec_given_tasmac',
              'no_of_hospital_canteen_visited', 
              'no_of_notice_issued_hospital_canteen',
            'no_of_iec_given_hospital_canteen',
             'no_of_icds_visited', 
             'no_of_noon_meals_centres_visited', 
             'total_no_of_icds_noon_meals_centres_visited',
            'no_of_iec_given_icds_noon_meal_centres',
             'no_of_notice_issued_icds_noon_meal_centres',
              'no_of_inspected_fbo_premises',
            'no_of_iec_given_fbo_premises', 
            'no_of_notice_issued_fbo_premises', 
            'no_of_complaints_received_upto_the_date',
            'no_of_complaints_received_during_the_date',
             'no_of_complaints_attended',
              'no_of_complaints_pending',
            'no_of_iec_meetings_organized',
             'no_of_consumers_attended', 
             'district',
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if (!values[key] || values[key] === '') {
                updatedErrors[key] = ' ';
            }
        });

        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values }
            const resp = await onEditTnDailyReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/tndaily-list/`);
            };

        } else {
            const data = { ...values }
            setDisableSubmit(true);
            const resp = await onCreateTnDailyReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">TN Foodsafety and Drug Administration Dept Daily Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area code *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    type="text"
                                    name="area_code"
                                    action="area_code"
                                    value={values.area_code}
                                    error={errors.area_code}
                                    items={wardsCategory}
                                    onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *</label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}

                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_fso_do"
                            >Name of the FSO / DO *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the FSO/DO`}
                                    type="text"
                                    name="name_of_the_fso_do"
                                    id="name_of_the_fso_do"
                                    value={values.name_of_the_fso_do}
                                    error={errors.name_of_the_fso_do}
                                    onChange={(e) => handleTextChange("name_of_the_fso_do", e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_school_college_hostel_visited"
                            >Number of School/College Hostel Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of School/College hostel inspected `}
                                    type="number"
                                    name="no_of_school_college_hostel_visited"
                                    id="no_of_school_college_hostel_visited"
                                    value={values.no_of_school_college_hostel_visited}
                                    error={errors.no_of_school_college_hostel_visited}
                                    onChange={(e) => handleTextChange("no_of_school_college_hostel_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_school_college_canteen_visited"
                            >Number of School/College Canteen Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of School/College Canteen inspected`}
                                    type="number"
                                    name="no_of_school_college_canteen_visited"
                                    id="no_of_school_college_canteen_visited"
                                    value={values.no_of_school_college_canteen_visited}
                                    error={errors.no_of_school_college_canteen_visited}
                                    onChange={(e) => handleTextChange("no_of_school_college_canteen_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="total_no_of_school_college_visited"
                            >Total Number of School/College Visited *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total Number of School/College visited`}
                                    type="number"
                                    name="total_no_of_school_college_visited"
                                    id="total_no_of_school_college_visited"
                                    value={values.total_no_of_school_college_visited}
                                    error={errors.total_no_of_school_college_visited}
                                    onChange={(e) => handleTextChange("total_no_of_school_college_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_given"
                            >Number of IEC Given *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of iec given`}
                                    type="number"
                                    name="no_of_iec_given"
                                    id="no_of_iec_given"
                                    value={values.no_of_iec_given}
                                    error={errors.no_of_iec_given}
                                    onChange={(e) => handleTextChange("no_of_iec_given", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued"
                            >Number of Notice Issued *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of notice issued`}
                                    type="number"
                                    name="no_of_notice_issued"
                                    id="no_of_notice_issued"
                                    value={values.no_of_notice_issued}
                                    error={errors.no_of_notice_issued}
                                    onChange={(e) => handleTextChange("no_of_notice_issued", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_visited_amma_unavakam"
                            >Number of Amma Unavakam Visited *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of amma unavakam visited`}
                                    type="number"
                                    name="no_of_visited_amma_unavakam"
                                    id="no_of_visited_amma_unavakam"
                                    value={values.no_of_visited_amma_unavakam}
                                    error={errors.no_of_visited_amma_unavakam}
                                    onChange={(e) => handleTextChange("no_of_visited_amma_unavakam", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_issued_amma_unavakam"
                            >Number of IEC Issued Amma Unavakam *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of IEC issued Amma Unavakam`}
                                    type="number"
                                    name="no_of_iec_issued_amma_unavakam"
                                    id="no_of_iec_issued_amma_unavakam"
                                    value={values.no_of_iec_issued_amma_unavakam}
                                    error={errors.no_of_iec_issued_amma_unavakam}
                                    onChange={(e) => handleTextChange("no_of_iec_issued_amma_unavakam", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_amma_unavakam"
                            >Number of Notice Issued Amma Unavakam *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Notice issued amma Unavakam`}
                                    type="number"
                                    name="no_of_notice_issued_amma_unavakam"
                                    id="no_of_notice_issued_amma_unavakam"
                                    value={values.no_of_notice_issued_amma_unavakam}
                                    error={errors.no_of_notice_issued_amma_unavakam}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_amma_unavakam", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_ration_shop_visited"
                            >Number of PDS/Co-Op/TNCSC Godowns shop's Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of PDS/Co-Op/TNCSC Godowns shop's Inspected`}
                                    type="number"
                                    name="no_of_ration_shop_visited"
                                    id="no_of_ration_shop_visited"
                                    value={values.no_of_ration_shop_visited}
                                    error={errors.no_of_ration_shop_visited}
                                    onChange={(e) => handleTextChange("no_of_ration_shop_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_issued_ration_shop"
                            >Number of IEC Issued to PDS/Co-Op Shop *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of IEC issued ration shop`}
                                    type="number"
                                    name="no_of_iec_issued_ration_shop"
                                    id="no_of_iec_issued_ration_shop"
                                    value={values.no_of_iec_issued_ration_shop}
                                    error={errors.no_of_iec_issued_ration_shop}
                                    onChange={(e) => handleTextChange("no_of_iec_issued_ration_shop", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_ration_shop"
                            >Number of Notice Issued to PDS/Co-Op shop *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Notice issued ration shop`}
                                    type="number"
                                    name="no_of_notice_issued_ration_shop"
                                    id="no_of_notice_issued_ration_shop"
                                    value={values.no_of_notice_issued_ration_shop}
                                    error={errors.no_of_notice_issued_ration_shop}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_ration_shop", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_tasmac_visited"
                            >Number of Tasmac shops Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of tasmac shops Inspected`}
                                    type="number"
                                    name="no_of_tasmac_visited"
                                    id="no_of_tasmac_visited"
                                    value={values.no_of_tasmac_visited}
                                    error={errors.no_of_tasmac_visited}
                                    onChange={(e) => handleTextChange("no_of_tasmac_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_tasmac"
                            >Number of Notice Issued Tasmac Shops *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Notice issued tasmac shops`}
                                    type="number"
                                    name="no_of_notice_issued_tasmac"
                                    id="no_of_notice_issued_tasmac"
                                    value={values.no_of_notice_issued_tasmac}
                                    error={errors.no_of_notice_issued_tasmac}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_tasmac", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_given_tasmac"
                            >Number of IEC Issued tasmac Shops *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of IEC Issued tasmac shops`}
                                    type="number"
                                    name="no_of_iec_given_tasmac"
                                    id="no_of_iec_given_tasmac"
                                    value={values.no_of_iec_given_tasmac}
                                    error={errors.no_of_iec_given_tasmac}
                                    onChange={(e) => handleTextChange("no_of_iec_given_tasmac", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_hospital_canteen_visited"
                            >Number of Hospital Canteen Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of hospital canteen Inspected`}
                                    type="number"
                                    name="no_of_hospital_canteen_visited"
                                    id="no_of_hospital_canteen_visited"
                                    value={values.no_of_hospital_canteen_visited}
                                    error={errors.no_of_hospital_canteen_visited}
                                    onChange={(e) => handleTextChange("no_of_hospital_canteen_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_hospital_canteen"
                            >Number of Notice  Hospital Canteen *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Notice  hospital canteen`}
                                    type="number"
                                    name="no_of_notice_issued_hospital_canteen"
                                    id="no_of_notice_issued_hospital_canteen"
                                    value={values.no_of_notice_issued_hospital_canteen}
                                    error={errors.no_of_notice_issued_hospital_canteen}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_hospital_canteen", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_given_hospital_canteen"
                            >Number of IEC Issued hospital canteen *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of IEC Issued hospital canteen`}
                                    type="number"
                                    name="no_of_iec_given_hospital_canteen"
                                    id="no_of_iec_given_hospital_canteen"
                                    value={values.no_of_iec_given_hospital_canteen}
                                    error={errors.no_of_iec_given_hospital_canteen}
                                    onChange={(e) => handleTextChange("no_of_iec_given_hospital_canteen", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_icds_visited"
                            >Number of ICDS Canteen Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of icds canteen Inspected`}
                                    type="number"
                                    name="no_of_icds_visited"
                                    id="no_of_icds_visited"
                                    value={values.no_of_icds_visited}
                                    error={errors.no_of_icds_visited}
                                    onChange={(e) => handleTextChange("no_of_icds_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_noon_meals_centres_visited"
                            >Number of Noon Meals Centres Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of noon meals centres inspected`}
                                    type="number"
                                    name="no_of_noon_meals_centres_visited"
                                    id="no_of_noon_meals_centres_visited"
                                    value={values.no_of_noon_meals_centres_visited}
                                    error={errors.no_of_noon_meals_centres_visited}
                                    onChange={(e) => handleTextChange("no_of_noon_meals_centres_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="total_no_of_icds_noon_meals_centres_visited"
                            >Total Number of ICDS / Noon meals Centre's visited *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total Number of icds/noon meals centres visited`}
                                    type="number"
                                    name="total_no_of_icds_noon_meals_centres_visited"
                                    id="total_no_of_icds_noon_meals_centres_visited"
                                    value={values.total_no_of_icds_noon_meals_centres_visited}
                                    error={errors.total_no_of_icds_noon_meals_centres_visited}
                                    onChange={(e) => handleTextChange("total_no_of_icds_noon_meals_centres_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_given_icds_noon_meal_centres"
                            >Number Of IEC Issued ICDS / Noon Meals Centre's *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of iec issued icds/noon meal centres`}
                                    type="number"
                                    name="no_of_iec_given_icds_noon_meal_centres"
                                    id="no_of_iec_given_icds_noon_meal_centres"
                                    value={values.no_of_iec_given_icds_noon_meal_centres}
                                    error={errors.no_of_iec_given_icds_noon_meal_centres}
                                    onChange={(e) => handleTextChange("no_of_iec_given_icds_noon_meal_centres", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_icds_noon_meal_centres"
                            >Number of Notice Issued ICDS / Noon meals Centre's *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of notice issued icds/noon meals centre's`}
                                    type="number"
                                    name="no_of_notice_issued_icds_noon_meal_centres"
                                    id="no_of_notice_issued_icds_noon_meal_centres"
                                    value={values.no_of_notice_issued_icds_noon_meal_centres}
                                    error={errors.no_of_notice_issued_icds_noon_meal_centres}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_icds_noon_meal_centres", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_inspected_fbo_premises"
                            >Number of Private FBO Premises Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of private fbo premises inspected`}
                                    type="number"
                                    name="no_of_inspected_fbo_premises"
                                    id="no_of_inspected_fbo_premises"
                                    value={values.no_of_inspected_fbo_premises}
                                    error={errors.no_of_inspected_fbo_premises}
                                    onChange={(e) => handleTextChange("no_of_inspected_fbo_premises", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_given_fbo_premises"
                            >Number of IEC Private FBO's *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of iec private fbo's`}
                                    type="number"
                                    name="no_of_iec_given_fbo_premises"
                                    id="no_of_iec_given_fbo_premises"
                                    value={values.no_of_iec_given_fbo_premises}
                                    error={errors.no_of_iec_given_fbo_premises}
                                    onChange={(e) => handleTextChange("no_of_iec_given_fbo_premises", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_notice_issued_fbo_premises"
                            >Number Of Notice Issued Private FBO Premises *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of notice issued private fbo premises`}
                                    type="number"
                                    name="no_of_notice_issued_fbo_premises"
                                    id="no_of_notice_issued_fbo_premises"
                                    value={values.no_of_notice_issued_fbo_premises}
                                    error={errors.no_of_notice_issued_fbo_premises}
                                    onChange={(e) => handleTextChange("no_of_notice_issued_fbo_premises", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_complaints_received_upto_the_date"
                            >Number Of Complaints Received Upto the date *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of complaints received upto the date`}
                                    type="number"
                                    name="no_of_complaints_received_upto_the_date"
                                    id="no_of_complaints_received_upto_the_date"
                                    value={values.no_of_complaints_received_upto_the_date}
                                    error={errors.no_of_complaints_received_upto_the_date}
                                    onChange={(e) => handleTextChange("no_of_complaints_received_upto_the_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_complaints_received_during_the_date"
                            >Number Of Complaints Received During the date *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of complaints received during the date`}
                                    type="number"
                                    name="no_of_complaints_received_during_the_date"
                                    id="no_of_complaints_received_during_the_date"
                                    value={values.no_of_complaints_received_during_the_date}
                                    error={errors.no_of_complaints_received_during_the_date}
                                    onChange={(e) => handleTextChange("no_of_complaints_received_during_the_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_complaints_attended"
                            >Number Of Complaints Attended *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of complaints attended`}
                                    type="number"
                                    name="no_of_complaints_attended"
                                    id="no_of_complaints_attended"
                                    value={values.no_of_complaints_attended}
                                    error={errors.no_of_complaints_attended}
                                    onChange={(e) => handleTextChange("no_of_complaints_attended", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_complaints_pending"
                            >Number Of Complaints Pending *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of complaints pending`}
                                    type="number"
                                    name="no_of_complaints_pending"
                                    id="no_of_complaints_pending"
                                    value={values.no_of_complaints_pending}
                                    error={errors.no_of_complaints_pending}
                                    onChange={(e) => handleTextChange("no_of_complaints_pending", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_iec_meetings_organized"
                            >Number Of IEC Meetings Organized *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of iec meetings organized`}
                                    type="number"
                                    name="no_of_iec_meetings_organized"
                                    id="no_of_iec_meetings_organized"
                                    value={values.no_of_iec_meetings_organized}
                                    error={errors.no_of_iec_meetings_organized}
                                    onChange={(e) => handleTextChange("no_of_iec_meetings_organized", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_consumers_attended"
                            >Number Of Consumers Attended *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of consumers attended`}
                                    type="number"
                                    name="no_of_consumers_attended"
                                    id="no_of_consumers_attended"
                                    value={values.no_of_consumers_attended}
                                    error={errors.no_of_consumers_attended}
                                    onChange={(e) => handleTextChange("no_of_consumers_attended", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateTnDailyReports: (data) => dispatch(createTnDailyReports(data)),
    onEditTnDailyReports: (data, record_id) => dispatch(editTnDailyReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});

export default connect(mapStateToProps, mapDispatchToProps)(TnDailyReports);