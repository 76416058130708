import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createLabDifferentReports, editLabDifferentReports, getDistrictWards, getFoodCategory } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";

const Category=[
    {id:'0', name:'1.1 Packed Milk(Milk and Milk Products)'},
    {id:'1', name:'1.2 Loose Milk(Milk and Milk Products)'},
    {id:'2', name:'1.3 Butter,Ghee,Ice-cream, other Milk Products(Milk and Milk Products)'},
    {id:'3', name:'2 Edible Oils,Fats & Vanaspati '},
    {id:'4', name:'3.1 Fresh Fruits and Vegetables(Fruits & Vegetables)'},
    {id:'5', name:'3.2 Processed Fruit & vegetables Products like Jam, Jellies, Dates etc(Fruits & Vegetables)'},
    {id:'6', name:'3.3 Pickles (Fruits & Vegetables)'},
    {id:'7', name:'4 Cereals, Cereals products & Pulses'},
    {id:'8', name:'5 Meat and Meat Products'},
    {id:'9', name:'6 Egg and Egg Products'},
    {id:'10', name:'7 Fish and Fish Products'},
    {id:'11', name:'8 Bakery & COnfectionery'},
    {id:'12', name:'9 Sweeteners including Honey and Sugar'},
    {id:'13', name:'10 Salt Including Iodised Salt'},
    {id:'14', name:'11.1 Whole (spices & Condiments)'},
    {id:'15', name:'11.2 Powder (spices & Condiments) '},
    {id:'16', name:'12.1 Alcoholic (Beverages)'},
    {id:'17', name:'12.2 Non-Alcoholic (Carbonated-Beverages)'},
    {id:'18', name:'12.3 Non-Alcoholic(Non-Carbonated) Including thermally processed fruits and vegetables juices etc (Beverages)'},
    {id:'19', name:'12.4 Mineral Water (Beverages)'},
    {id:'20', name:'12.5 Packaged Drinking Water (Beverages)'},
    {id:'21', name:'13 Savouries/Namkeens/Sweets'},
    {id:'22', name:'14 Tea,Coffee,Coco,Chicory & Infusion'},
    {id:'23', name:'15 Infant Milk and Substitutes'},
    {id:'23', name:'16 Infant Food (Weaning Food)'},
    {id:'24', name:'17 Supari, Pan-masala'},
    {id:'25', name:'18.1 Hotels/Restaurants (Prepared Food)'},
    {id:'26', name:'18.2 Caterer (Prepared Food)'},
    {id:'27', name:'18.3 Dhaba (Prepared Food)'},
    {id:'28', name:'18.4 Mid-day meal (Prepared Food)'},
    {id:'29', name:'19 Neutraceuticals/Health Supplements/FSMP/FSDU Food Products'},
    {id:'30', name:'20 Food Additives'},
    {id:'31', name:'21 Other Miscellaneous Food Articles'},
   
]
const Result = [
    {id:'0', name:'Confirm To Standards'},
    {id:'1', name:'Unsafe'},
    {id:'2', name:'Substranded'},
    {id:'3', name:'Labelling Defects'},
    {id:'4', name:'Misleading'},
    {id:'5', name:'Miscellaneous'},

]
const Person = [
    {id:'0', name:'FBO'},
    {id:'1', name:'Consumer'},
    {id:'2', name:'Hospital Sample'},
    {id:'3', name:'Port Health Organisation'},
    {id:'4', name:'FSSAI (Imported Food Sample)'},
    {id:'5', name:'TamilNadu Civil Supplies Corporation'},
    {id:'6', name:'Civil Supplies and Consumer protection Department'},
    {id:'7', name:'Southern Railway-Quality Control/ ACT'},
    {id:'8', name:'Salt (PHC)'},
    {id:'9', name:'District Consumer DisputeRedressal Forum'},
    {id:'10', name:'Private Samples'},
    {id:'11', name:'Toxy'},
    {id:'12', name:'Government Organisation / Survey'},
    {id:'13', name:'FSS Samples / CFS Samples'},
    {id:'14', name:'Mid Day Meak'},
    {id:'15', name:'ICDS'},
    {id:'16', name:'Other'},
]

const SampleAnalysed = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]


const DifferentSources = (props) => {
    const { districts, user,wards, traderTypes,wardsCategory,getDistrict, onCreateLabDifferentReports, onEditLabDifferentReports,getFoodCategory } = props;
    const [values, setValues] = useState({
        'district' : '',
        'area_code':'',
        'area_name':'',
       'sample_received_from':'',
       'category_of_sample':'',
       'sample_fit_for_analysis':'',
       'result_number':'',
       'result_type':'',
       'upload_documents':'',
       'other_sample_received_from':'',
       'lab_code':'',
       date_intimation_sent_to_sample_sent_person_on:'',
       date_result_intimated_to_sample_sent_person:'',
       date_result_sent_to_head_office_on:'',
       date_sample_received_date:'',
       date_analysed_date:'',
       date_result:'',
        });
    console.log(props.history.location.state)
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [foodCategory, setFoodCategory]= useState([])
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
   
    const getFoods=async()=>{
        const resp = await getFoodCategory()
        if(resp.response){
            setFoodCategory(resp.response)
        }
    }
    useEffect(()=>{
        getFoods()
    },[])
    // const getCl=()=>{
    //     setValues({...values,date_result:''})
    // }
    // useEffect(()=>{
    //     setTimeout(()=>{
    //     getCl()
    //     },1500)

    // },[values&&values])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
           
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
      wardsCategory&&wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
  
    const getDis=async(dat)=>{
        await getDistrict({district:dat})
    }
    useEffect(()=>{
        if(values&&values.district){
            getDis(values.district)
        }
    },[values.district])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
            'area_code',
            'area_name',
            'sample_received_from',
            'date_sample_received_date',
            'category_of_sample',
    //    'sample_fit_for_analysis',
       
       ];
       if(props.history?.location.state?.action_type&&props.history.location.state.action_type==='result')keys.push('upload_documents')
       if(props.history.location.state&&props.history.location.state?.action_type==='analyse_code')keys.push('lab_code')
       if(props.history.location.state&&props.history.location.state?.action_type==='analyse')keys.push('sample_fit_for_analysis')
       if(props.history.location.state&&props.history.location.state?.action_type==='analyse' && values&&values.sample_fit_for_analysis&&values.sample_fit_for_analysis=='No')keys.push('date_intimation_sent_to_sample_sent_person_on')
       if(props.history.location.state&&props.history.location.state?.action_type==='result')keys.push('result_number','result_type','date_analysed_date','date_result','result_type','date_result_intimated_to_sample_sent_person','date_result_sent_to_head_office_on',)
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {  
        if (props.history.location && props.history.location.state) {
            const data = {...values}
            console.log(data.date_result,typeof(data.date_result))
            if(props.history.location.state.action_type=='analyse_code'){
                data.status='Waiting for analysis'
            }
            else if(props.history.location.state.action_type=='analyse'&&values.sample_fit_for_analysis == 'Yes'){
                data.status='waiting for lab result'
            }
            else if(props.history.location.state.action_type=='analyse'&&values.sample_fit_for_analysis == 'No'){
                data.status='Sample unfit'
            } 
            else if(props.history.location.state.action_type=='result'){
                data.status='Sample Analysed'
            }
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditLabDifferentReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/dep-non-sample-reports`);
            };

        } else { 
            const data = { ...values}
            data.status='Submitted to lab'
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateLabDifferentReports(formData);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/dep-non-sample-reports");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Sample From Different Sources Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    placeholder={`Area name`}
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange('area_name', e.target.value)}
                                />
                            </div>
                        </div>
                        
                      
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="sample_received_from"
                            >Sample Received From *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample Received From`}
                                    name="sample_received_from"
                                    items={Person}
                                    action="dropdown"
                                    value={values.sample_received_from}
                                    error={errors.sample_received_from}
                                    onChange={(text) => handleTextChange("sample_received_from", text)}
                                />
                            </div>
                        </div> 
                        {values && values.sample_received_from.includes('Other') ?
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="other_sample_received_from"
                            ></label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sample Received From`}
                                    name="other_sample_received_from"
                                    value={values.other_sample_received_from}
                                    error={errors.other_sample_received_from}
                                    onChange={(e) => handleTextChange("other_sample_received_from", e.target.value)}
                                />
                            </div>
                        </div> 
                        : null }
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_sample_received_date"
                            >Sample Received Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Received Date`}
                                    name="date_sample_received_date"
                                    value={values.date_sample_received_date}
                                    error={errors.date_sample_received_date}
                                    onChangeText={(text) => handleTextChange("date_sample_received_date", text)}
                                />
                            </div>
                        </div>
                        
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="category_of_sample"
                            >Sample Category *: </label>
                            <div className="col-sm-8">
                                {/* <DropDownInput
                                    placeholder={`Sample Category`}
                                    name="category_of_sample"
                                    items={Category}
                                    action="dropdown"
                                    value={values.category_of_sample}
                                    error={errors.category_of_sample}
                                    onChange={(text) =>handleTextChange("category_of_sample", text)}
                                /> */}
                               {foodCategory&& foodCategory.length !==0&& <DropDownInput
                                    placeholder={`Sample Category`}
                                    name="category_of_sample"
                                    items={foodCategory}
                                    action="dropdown"
                                    value={values.category_of_sample}
                                    error={errors.category_of_sample}
                                    onChange={(text) =>handleTextChange("category_of_sample", text)}
                                />}
                            </div>
                        </div>   
                        {values && values.category_of_sample.includes('21 Other Miscellaneous Food Articles')?
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_category_of_sample"
                         ></label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Sample Category`}
                                 name="other_category_of_sample"
                                 value={values.other_category_of_sample}
                                 error={errors.other_category_of_sample}
                                 onChange={(e) =>handleTextChange("other_category_of_sample", e.target.value)}
                             />
                         </div>
                     </div>   
                        :null}  
                     {props.history?.location.state?.action_type&&props.history.location.state.action_type==='analyse_code'?<> 
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="lab_code"
                            >Lab Code *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    placeholder={`Lab code`}
                                    name="lab_code"
                                    id="lab_code"
                                    value={values.lab_code}
                                    error={errors.lab_code}
                                    onChange={(e) => handleTextChange('lab_code', e.target.value)}
                                />
                            </div>
                        </div></>:null}
                     {props.history?.location.state?.action_type&&props.history.location.state.action_type==='analyse'?<> 
                      <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_fit_for_analysis"
                            >Sample Fit For Analysis *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample Fit For Analysis`}
                                    type="text"
                                    name="sample_fit_for_analysis"
                                    items={SampleAnalysed}
                                    action="dropdown"
                                    value={values.sample_fit_for_analysis}
                                    error={errors.sample_fit_for_analysis}
                                    onChange={(text) =>handleTextChange("sample_fit_for_analysis", text)}
                                />
                            </div>
                        </div>
                        {values && values.sample_fit_for_analysis.includes('No')? 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_intimation_sent_to_sample_sent_person_on"
                            >Intimation Sent to Sample Sent Person / Concerned Department On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Intimation Sent to Sample Sent Person / Concerned Department On`}
                                    name="date_intimation_sent_to_sample_sent_person_on"
                                    value={values.date_intimation_sent_to_sample_sent_person_on}
                                    error={errors.date_intimation_sent_to_sample_sent_person_on}
                                    onChangeText={(text) =>handleTextChange("date_intimation_sent_to_sample_sent_person_on", text)}
                                />
                            </div>
                        </div> : null}
                         </>:null}  
                        {props.history.location?.state?.action_type&&props.history.location.state.action_type==='result'?
                        <>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_analysed_date"
                            >Sample Analysed Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Analysed Date`}
                                    name="date_analysed_date"
                                    value={values.date_analysed_date}
                                    error={errors.date_analysed_date}
                                    onChangeText={(text) => handleTextChange("date_analysed_date", text)}
                                />
                            </div>
                        </div>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result"
                            >Result Analysed Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Result Analysed Date`}
                                    name="date_result"
                                    value={values.date_result}
                                    error={errors.date_result}
                                    onChangeText={(text) =>handleTextChange("date_result", text)}
                                />
                            </div>
                        </div>  
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_number"
                            >Result Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Result Number`}
                                    name="result_number"
                                    type="text"
                                    value={values.result_number}
                                    error={errors.result_number}
                                    onChange={(e) =>handleTextChange("result_number", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_type"
                            >Result Type *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Result Type`}
                                    type="text"
                                    items={Result}
                                    action="dropdown"
                                    name="result_type"
                                    value={values.result_type}
                                    error={errors.result_type}
                                    onChange={(text) =>handleTextChange("result_type", text)}
                                />
                            </div>
                        </div> 
                        {values && values.result_type.includes('Miscellaneous')? 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_result_type"
                         > </label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Result Type`}
                                 type="text"
                                 name="other_result_type"
                                 value={values.other_result_type}
                                 error={errors.other_result_type}
                                 onChange={(e) =>handleTextChange("other_result_type", e.target.value)}
                             />
                         </div>
                     </div> 
                        : null}
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="date_result_intimated_to_sample_sent_person"
                         >Result Intimated to Sample Sent Person *: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`Result Intimated to Sample Sent Person`}
                                 type="text"
                                 name="date_result_intimated_to_sample_sent_person"
                                 value={values.date_result_intimated_to_sample_sent_person}
                                 error={errors.date_result_intimated_to_sample_sent_person}
                                 onChangeText={(text) =>handleTextChange("date_result_intimated_to_sample_sent_person", text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_result_sent_to_head_office_on"
                            >Result Sent to Head Office On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Result Sent to Head Office On`}
                                    name="date_result_sent_to_head_office_on"
                                    value={values.date_result_sent_to_head_office_on}
                                    error={errors.date_result_sent_to_head_office_on}
                                    onChangeText={(text) =>handleTextChange("date_result_sent_to_head_office_on", text)}
                                />
                            </div>
                        </div> 
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_documents"
                            >Upload Documents *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_documents"
                                    error={errors.upload_documents}
                                    onChange={(e) =>handleTextChange("upload_documents", e.target.files[0])}
                                />
                            </div>
                        </div>
                         
                        </>
                        : null}   
                        
                       
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/dep-non-sample-reports")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateLabDifferentReports: (data) => dispatch(createLabDifferentReports(data)),
    getDistrict: (data) => dispatch(getDistrictWards(data)),
    getFoodCategory: (data) => dispatch(getFoodCategory(data)),
    onEditLabDifferentReports: (data, record_id) => dispatch(editLabDifferentReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    traderTypes: state.home.traderTypes,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(DifferentSources);