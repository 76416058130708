import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createCreditFund, createFboReports, editCreditFund, editFboReports , getDistrictWards, getFinancelist, testingLab} from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const FinanceCredit = (props) => {
    const { districts, user,wards,onfoodlabs, onCreateFboReports,wardsCategory, onEditFboReports,ongetFinancelist, onCreateFund,onEditFund } = props;
    const [values, setValues] = useState({
            fund_name:'',
            report_year:'',
            allotted_from:'',
            allotted_date:'',
            allotted_amount:'',
            allotted_topic:'',
            other_allotted_from:'',
            mou:'',
            state_general_budget:'',
            announcement_year:'',
            announcement_number:'',

    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [labs, setlabs] = useState([]);
    const [list, setList]=useState([]);
    let { slug } = useParams();
    console.log(props)
    const Nam=[
        {id:'1',name:'Pay(AI 30101)'},
        {id:'2',name:'Medical Allowance(AI 30102)'},
        {id:'3',name:'Medical Charges(AI 30103)'},
        {id:'4',name:'Other Allowances(AI 30104)'},
        {id:'5',name:'House Rent Allowance(AI 30106)'},
        {id:'6',name:'Travel Concession(AI 30107)'},
        {id:'7',name:'City Compensatory Allowance(AI 30108)'},
        {id:'8',name:'Dearness Allowance(AI 30301)'},
        {id:'9',name:'Tour Travelling Allowances(AI 30401)'},
        {id:'10',name:'Transfer Travelling Allowances(AI 30402)'},
        {id:'11',name:'Fixed Travelling Allowances(AI 30403)'},
        {id:'12',name:'Telephone Charges(AI 30501)'},
        {id:'13',name:'Other Contingencies (AI 30502)'},
        {id:'14',name:'Electricity Charges(AI 30503)'},
        {id:'15',name:'Service Postage & Postal Expenditure(AI 30504)'},
        {id:'16',name:'Rent(AI 30601)'},
        {id:'17',name:'Advertisement Charges(AI 30801)'},
        {id:'18',name:'Machinary Purchase (AI 31901)'},
        {id:'19',name:'Purchase(AI 32101)'},
        {id:'20',name:'Maintenance(AI 32102)'},
        {id:'21',name:'Materials and supplies(AI 32401)'},
        {id:'22',name:'Pleader fee(AI 33301)'},
        {id:'23',name:'Contract Payment(AI 33304)'},
        {id:'24',name:'Petrolium oil and lubricant(AI 34501)'},
        {id:'25',name:'Festival Advances-Debit(AI 34901)'},
        {id:'26',name:'Festival Advances-Credit(AI 34902)'},
        {id:'27',name:'Claims under no fault liability-principal harges(AI 35101)'},
        {id:'28',name:'Prizes and Awards(AI 35901)'},
        {id:'29',name:'Cost of Books/Note Books/Slates, etc.(AI 36801)'},
        {id:'30',name:'Training(AI 37201)'},
        {id:'31',name:'Transport charges(AI 37301)'},
        {id:'32',name:'Purchase(AI 37601)'},
        {id:'33',name:'Maintenance(AI 37602)'},
        {id:'34',name:'Stationery(AI 37603)'},
    ]
 const Types = [
    { id: "1", name: "Central" },
    { id: "2", name: "FSSAI" },
    { id: "3", name: "State" },
    { id: "4", name: "NHM" },
    {id:'5',name:'TNHSRP'},
    { id: "6", name: "Others" },
 ]
 const Alloted = [
    { id: "1", name: "MOU" },
    { id: "2", name: "Announcement budget" },
    { id: "3", name: "State general budget" },
 ]
 const MOU = [
    { id: '0', name: 'State allotment' },
    { id: '1', name: 'Central allotment' },  
]
const Budget = [
    { id: '0', name: 'State allotment' },
    { id: '1', name: 'Central allotment' },  
]

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line   
    }, [])

const getlabs=async()=>{
    const resp=await onfoodlabs()
    if(resp.response)setlabs(resp.response)
}
useEffect(()=>{
   getlabs()
},[])

    useEffect(()=>{
       wardsCategory&&wardsCategory.map((o)=>{
            if(o.id == values.fso_area_code)
            return (
                setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.fso_area_code])
    districts&&districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'fund_name',
            'report_year',
            'allotted_from',
            'allotted_amount',
            'allotted_topic',
        ];
        if(values&&values.allotted_from&&values.allotted_from=='Others')keys.push('other_allotted_from')
        if(values&&values.allotted_topic&&values.allotted_topic=='MOU')keys.push('mou')
        if(values&&values.allotted_topic&&values.allotted_topic=='Announcement budget')keys.push('announcement_year','announcement_number')
        if(values&&values.allotted_topic&&values.allotted_topic=='State general budget')keys.push('state_general_budget')
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            data.report_year=moment(data.report_year).format('YYYY')
            data.announcement_year=moment(data.announcement_year).format('YYYY')
            data.allotted_date=moment(data.report_year).format('YYYY-MM-DD')
            const resp = await onEditFund(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/reports`);
            };

        } else {
            const data = { ...values}
            data.report_year=moment(data.report_year).format('YYYY')
            data.announcement_year=moment(data.announcement_year).format('YYYY')
            data.allotted_date=moment(data.report_year).format('YYYY-MM-DD')
            setDisableSubmit(true);
            const resp = await onCreateFund(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports");
            };
        }       
        
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Fund Credit Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fund_name"
                            >Fund Name *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                    placeholder={`Fund Name`}
                                    name="fund_name"
                                    id="fund_name"
                                    items={Nam}
                                    action="dropdown"
                                    value={values.fund_name}
                                    error={errors.fund_name}
                                    onChange={(text) => handleTextChange("fund_name", text)}
                                />
                            </div>
                        </div>  
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Entry date of report *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="report_year"
                            >Report year *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report year`}
                                    name="report_year"
                                    id="report_year"
                                    showYearPicker
                                    value={values.report_year}
                                    error={errors.report_year}
                                    onChangeText={(text) => handleTextChange('report_year', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="allotted_from"
                            >Fund Allotted from *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="allotted_from"
                                        action="dropdown"
                                        value={values.allotted_from}
                                        error={errors.allotted_from }
                                        items={Types}
                                        onChange={(value) => handleTextChange("allotted_from", value)}
                                />
                            </div>
                        </div>  
                        {values && values.allotted_from.includes('Others')&&(
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_allotted_from"
                            >Other Fund Allotted from *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Fund allotted from`}
                                    name="other_allotted_from"
                                    id="other_allotted_from"
                                    value={values.other_allotted_from}
                                    error={errors.other_allotted_from}
                                    onChange={(e) => handleTextChange("other_allotted_from", e.target.value)}
                                />
                            </div>
                        </div>  
                        )}                                       
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="allotted_date"
                            >Fund Allotted Date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Fund allotted date`}
                                    name="allotted_date"
                                    id="allotted_date"
                                    value={values.allotted_date}
                                    error={errors.allotted_date}
                                    onChangeText={(text) => handleTextChange('allotted_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="allotted_amount"
                            > Allotted Amount *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    type='number'
                                    placeholder={`Allotted amount`}
                                    name="allotted_amount"
                                    id="allotted_amount"
                                    value={values.allotted_amount}
                                    error={errors.allotted_amount}
                                    onChange={(e) => handleTextChange("allotted_amount", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="allotted_topic"
                            >Allotted Topic *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        name="mou"
                                        action="dropdown"
                                        value={values.allotted_topic}
                                        error={errors.allotted_topic }
                                        items={Alloted}
                                        onChange={(value) => handleTextChange("allotted_topic", value)}
                                />
                            </div>
                        </div> 
                         {values && values.allotted_topic.includes('MOU')&&(
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="mou"
                            >MOU *: </label>
                            <div className="col-sm-8">
                                 <DropDownInput
                                        name="mou"
                                        action="dropdown"
                                        value={values.mou}
                                        error={errors.mou }
                                        items={Budget}
                                        onChange={(value) => handleTextChange("mou", value)}
                                />
                            </div>
                        </div>  
                        )}  
                        {values && values.allotted_topic.includes('Announcement budget')&&(<>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="announcement_year"
                            >Announcement Year *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Announcement year`}
                                    name="announcement_year"
                                    id="announcement_year"
                                    showYearPicker
                                    value={values.announcement_year}
                                    error={errors.announcement_year}
                                    onChangeText={(text) => handleTextChange('announcement_year', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="allotted_date"
                            >Announcement Number *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    type='number'
                                    placeholder={`Announcement Number`}
                                    name="announcement_number"
                                    id="announcement_number"
                                    value={values.announcement_number}
                                    error={errors.announcement_number}
                                    onChange={(e) => handleTextChange("announcement_number", e.target.value)}
                                />
                            </div>
                        </div>
                        </>)}  
                        {values && values.allotted_topic.includes('State general budget')&&(
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="state_general_budget"
                            >State General Budget *: </label>
                            <div className="col-sm-8">
                                 {/* <DropDownInput
                                        name="mou"
                                        action="dropdown"
                                        value={values.mou}
                                        error={errors.mou }
                                        items={list}
                                        onChange={(value) => handleTextChange("mou", value)}
                                /> */}
                                <TextInput
                                    placeholder={`State general budget`}
                                    name="state_general_budget"
                                    id="state_general_budget"
                                    value={values.state_general_budget}
                                    error={errors.state_general_budget}
                                    onChange={(e) => handleTextChange("state_general_budget", e.target.value)}
                                />
                            </div>
                        </div>  
                        )}  
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFund: (data) => dispatch(createCreditFund(data)),
    onEditFund: (data, record_id) => dispatch(editCreditFund(data, record_id)),
    ongetFinancelist:(data)=>dispatch(getFinancelist(data)),
    onfoodlabs:(data)=>dispatch(testingLab(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
    traderTypes: state.home.traderTypes,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(FinanceCredit);