import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createFSOCaseReports, editFSOCaseReports } from "../../components/reducers/actions"
import Datepicker from '../../components/inputs/Datepicker'
import TextAreaInput from "../../components/inputs/TextAreaInput";
import { useParams } from "react-router-dom";
import moment from "moment";

const TypeOfTrader = [
    { id: '0', name: 'Manufacturer' },
    { id: '1', name: 'Retailer' },
    { id: '2', name: 'WholeSaler/Distributor/Repacker' },
    { id: '3', name: 'Transporter' },
    { id: '4', name: 'Others' },

]
const Judgement = [
    { id: '0', name: 'Convicted' },
    { id: '1', name: 'Aquited' },
]
const Case = [
    { id: '0', name: 'Civil' },
    { id: '1', name: 'Criminal' },
]
const Convicted = [
    { id: "0", name: "Penalty" },
    { id: "1", name: "Penalty with Imprisonment" }
]
const Appeal = [
    { id: '0', name: 'Yes' },
    { id: '1', name: 'No' },
]
const FSOCaseReport = (props) => {
    const { districts, user, wards,wardsCategory, onCreateFSOCaseReports, onEditFSOCaseReports } = props;
    const [values, setValues] = useState({
        'area_name': '',
        'area_code': '',
        'date': '',
        'type_of_case': '',
        'date_case_file_received_from_do': '',
        'date_ao_case_file_submitted_at_adjudication_court_date': '',
        'date_case_file_received_from_do_on': '',
        'date_case_file_submitted_to_court_app_on': '',
        'date_assistant_public_prosecutor_approved_on': '',
        'date_case_filed_at_open_court_on': '',
        'case_admitted_on': '',
        'case_file_number': '',
        'date_of_first_hearing': '',
        'case_judgement': '',
        'convicted': '',
        'penalty': '',
        'penalty_with_imprisonment': '',
        'appeal_preferred': '',
        'type_of_trader': '',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if (user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'area_name',
            'area_code',
            'date',
            'type_of_case',
            
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if (!values[key] || values[key] === '') {
                updatedErrors[key] = ' ';
            }
        });

        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values }
            // const formData = new FormData();
            // Object.keys(data).forEach((key) => {
            //     if (key.startsWith("date")) {
            //         formData.append(key, new Date(data[key]).toISOString());
            //     } else {
            //         formData.append(key, data[key]);
            //     }
            // });
            const resp = await onEditFSOCaseReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push(`/app/display-reports/fso_casedetails_list`);
            };
        } else {
            const data = { ...values }
            // const formData = new FormData();
            // Object.keys(data).forEach((key) => {
            //     if (key.startsWith("date")) {
            //         formData.append(key, new Date(data[key]).toISOString());
            //     } else {
            //         formData.append(key, data[key]);
            //     }
            // });
            setDisableSubmit(true);
            const resp = await onCreateFSOCaseReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Case Details Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    type="text"
                                    name="area_code"
                                    action="area_code"
                                    value={values.area_code}
                                    error={errors.area_code}
                                    items={wardsCategory}
                                    onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name `}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_case"
                            >Case Type *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Type`}
                                    name="type_of_case"
                                    id="type_of_case"
                                    items={Case}
                                    action="dropdown"
                                    value={values.type_of_case}
                                    error={errors.type_of_case}
                                    onChange={(text) => handleTextChange('type_of_case', text)}
                                />
                            </div>
                        </div>
                        {values && values.type_of_case.includes('Civil') ? <>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_case_file_received_from_do"
                                >Case File Received From DO on : </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Case File received on`}
                                        name="date_case_file_received_from_do"
                                        id="date_case_file_received_from_do"
                                        value={values.date_case_file_received_from_do}
                                        error={errors.date_case_file_received_from_do}
                                        onChangeText={(text) => handleTextChange("date_case_file_received_from_do", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_ao_case_file_submitted_at_adjudication_court_date"
                                >Case File submitted at Adjudicaion Court on: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Case File submitted at Adjudicaion Court on`}
                                        type="text"
                                        name="date_ao_case_file_submitted_at_adjudication_court_date"
                                        id="date_ao_case_file_submitted_at_adjudication_court_date"
                                        value={values.date_ao_case_file_submitted_at_adjudication_court_date}
                                        error={errors.date_ao_case_file_submitted_at_adjudication_court_date}
                                        onChangeText={(text) => handleTextChange("date_ao_case_file_submitted_at_adjudication_court_date", text)}
                                    />
                                </div>
                            </div>
                        </> : null}
                        {values && values.type_of_case.includes('Criminal') ? <>

                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_case_file_received_from_do_on"
                                >Case File Received From DO on: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Case File Received From DO on`}
                                        name="date_case_file_received_from_do_on"
                                        id="date_case_file_received_from_do_on"
                                        value={values.date_case_file_received_from_do_on}
                                        error={errors.date_case_file_received_from_do_on}
                                        onChangeText={(text) => handleTextChange("date_case_file_received_from_do_on", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_case_file_submitted_to_court_app_on"
                                >Case File Submitted To Court App On: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Case File Submitted to court App On`}
                                        name="date_case_file_submitted_to_court_app_on"
                                        id="date_case_file_submitted_to_court_app_on"
                                        value={values.date_case_file_submitted_to_court_app_on}
                                        error={errors.date_case_file_submitted_to_court_app_on}
                                        onChangeText={(text) => handleTextChange("date_case_file_submitted_to_court_app_on", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_assistant_public_prosecutor_approved_on"
                                >Assistant Public Prosecutor Approved Case File On : </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Assistant Public Prosecutor Approved case file on `}
                                        name="date_assistant_public_prosecutor_approved_on"
                                        id="date_assistant_public_prosecutor_approved_on"
                                        value={values.date_assistant_public_prosecutor_approved_on}
                                        error={errors.date_assistant_public_prosecutor_approved_on}
                                        onChangeText={(text) => handleTextChange("date_assistant_public_prosecutor_approved_on", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_case_filed_at_open_court_on"
                                >Case Filed at Open Court On : </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Case Filed at Open Court on `}
                                        name="date_case_filed_at_open_court_on"
                                        id="date_case_filed_at_open_court_on"
                                        value={values.date_case_filed_at_open_court_on}
                                        error={errors.date_case_filed_at_open_court_on}
                                        onChangeText={(text) => handleTextChange("date_case_filed_at_open_court_on", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type_of_respondent"
                                >Case Admitted On: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Case Admitted On`}
                                        name="case_admitted_on"
                                        id="case_admitted_on"
                                        value={values.case_admitted_on}
                                        error={errors.case_admitted_on}
                                        onChangeText={(text) => handleTextChange("case_admitted_on", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type_of_respondent"
                                > Case File Number:</label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`case file number`}
                                        type="text"
                                        name="case_file_number"
                                        id="case_file_number"
                                        value={values.case_file_number}
                                        error={errors.case_file_number}
                                        onChange={(e) => handleTextChange("case_file_number", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_of_first_hearing"
                                >Date Of First Hearing: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Date of first hearing`}
                                        name="date_of_first_hearing"
                                        id="date_of_first_hearing"
                                        value={values.date_of_first_hearing}
                                        error={errors.date_of_first_hearing}
                                        onChangeText={(text) => handleTextChange("date_of_first_hearing", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="case_judgement"
                                >Case Judgement: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Case Judgement`}
                                        type="text"
                                        name="case_judgement"
                                        id="case_judgement"
                                        items={Judgement}
                                        action="dropdown"
                                        value={values.case_judgement}
                                        error={errors.case_judgement}
                                        onChange={(text) => handleTextChange("case_judgement", text)}
                                    />
                                </div>
                            </div>
                            {values && values.case_judgement.includes('Convicted') ?
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="convicted"
                                    >Convicted with: </label>
                                    <div className="col-sm-8">
                                        <DropDownInput
                                            placeholder={`Convicted with`}
                                            type="text"
                                            name="convicted"
                                            id="convicted"
                                            items={Convicted}
                                            action="dropdown"
                                            value={values.convicted}
                                            error={errors.convicted}
                                            onChange={(text) => handleTextChange("convicted", text)}
                                        />
                                    </div>
                                </div>
                                : null}
                            {values && values.convicted.includes('Penalty') ?
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="penalty"
                                    >Penalty Amount : </label>
                                    <div className="col-sm-8">
                                        <TextInput
                                            placeholder={`Penalty`}
                                            type="number"
                                            name="Penalty amount"
                                            id="penalty"
                                            value={values.penalty}
                                            error={errors.penalty}
                                            onChange={(e) => handleTextChange("penalty", e.target.value)}
                                        />
                                    </div>
                                </div>
                                : null}
                            {values && values.convicted.includes('Penalty with Imprisonment') ?
                                <> <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="penalty_with_imprisonment"
                                    >Imprisonment Penalty Amount (in RS): </label>
                                    <div className="col-sm-8">
                                        <TextInput
                                            placeholder={`Penalty Amount`}
                                            type="number"
                                            name="penalty_with_imprisonment"
                                            id="penalty_with_imprisonment"
                                            value={values.penalty_with_imprisonment}
                                            error={errors.penalty_with_imprisonment}
                                            onChange={(e) => handleTextChange("penalty_with_imprisonment", e.target.value)}
                                        />
                                    </div>
                                </div>
                                    <div className="form-group row">
                                        <label className="control-label col-sm-4 text-label" htmlFor="penalty_with_imprisonment"
                                        >Any Appeal Preferred by FBO: </label>
                                        <div className="col-sm-8">
                                            <DropDownInput
                                                placeholder={`Any Appeal Preffered`}
                                                type="nummber"
                                                name="appeal_preferred"
                                                id="appeal_preferred"
                                                items={Appeal}
                                                action="dropdown"
                                                value={values.appeal_preferred}
                                                error={errors.appeal_preferred}
                                                onChange={(text) => handleTextChange("appeal_preferred", text)}
                                            />
                                        </div>
                                    </div>
                                </> : null}
                            {values && values.appeal_preferred.includes('Yes') ?
                                <>  <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="type_of_trader"
                                    >Type Of Trader : </label>
                                    <div className="col-sm-8">
                                        <DropDownInput
                                            placeholder={`Type of Trader`}
                                            type="text"
                                            name="type_of_trader"
                                            id="type_of_trader"
                                            items={TypeOfTrader}
                                            action="dropdown"
                                            value={values.type_of_trader}
                                            error={errors.type_of_trader}
                                            onChange={(text) => handleTextChange("type_of_trader", text)}
                                        />
                                    </div>
                                </div>
                                    {values && values.type_of_trader.includes('Others') ?
                                        <div className="form-group row">
                                            <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_trader"
                                            ></label>
                                            <div className="col-sm-8">
                                                <TextInput
                                                    placeholder={`Type of Trader`}
                                                    type="text"
                                                    name="other_type_of_trader"
                                                    id="other_type_of_trader"
                                                    values={values.other_type_of_trader}
                                                    error={errors.other_type_of_trader}
                                                    onChange={(e) => handleTextChange("other_type_of_trader", e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        : null}
                                </> : null}
                        </> : null}


                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFSOCaseReports: (data) => dispatch(createFSOCaseReports(data)),
    onEditFSOCaseReports: (data, record_id) => dispatch(editFSOCaseReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,


});

export default connect(mapStateToProps, mapDispatchToProps)(FSOCaseReport);