const config = {
  SITE_TITLE: process.env.REACT_APP_SITE_TITLE,
  API_URL: 'https://foodsafety.tn.gov.in/api/api/v1/',

  APP_TYPE: process.env.REACT_APP_TYPE,
};

const currentEnvironment = process.env.REACT_APP_ENV;

console.log('Current environment is: ', currentEnvironment);

const languageCode = {
  'english': 'en',
  'tamil': 'ta'
}

export const getLangCode = (lang) => {
  return languageCode[lang];
}

export default config;
