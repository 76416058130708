import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { Form } from 'react-bootstrap';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import { getBlogs, doSubscribe } from '../components/reducers/actions';
import TextInput from '../components/inputs/TextInput';
import { validateEmail } from '../utils/validateEmail';

const BlogList = (props) => {

  const { t } = useTranslation();
  const [featuredPost, setFeaturedPost] = useState([]);
  const [values, setValues] = useState({
    'email': ''
  });
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { onGetBlogs, blogs, onDoSubscribe } = props

  useEffect(() => {
    const getAssets = () => {
      onGetBlogs();
    }
    console.log(props)
    getAssets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (blogs) {
      const featurePosts = blogs.filter((item) => item.editors_pick);
      setFeaturedPost(featurePosts);
    }
  }, [blogs]);


  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ['email',];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === '') {
        updatedErrors[key] = ' ';
      }
    });

    if (values.email && !validateEmail(values.email)) {
      updatedErrors.email = 'Invalid email address';
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisableSubmit(true);
    var data = { ...values };
    const resp = await onDoSubscribe(data);
    if (resp && resp.response) {
      setValues({
        'email': ''
      });
      setErrors({});
      setDisableSubmit(false);
    }
  }

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    validateValues(updatedValues);
  }

  const blogList = blogs ? blogs.map((blog, idx) => {
    return <div key={idx} className="col-lg-3 mb-5">
      <Link to={
        {
          pathname: `/blogs/${blog.slug}`,
          state: {
            'blog': blog
          }
        }
      }>
        <div className="box">
          <img src={blog.image} alt="" />
          <div className="content">
            <h4 className="title">{blog.title}</h4>
            <p className="description">{blog.description}</p>
          </div>
        </div>
      </Link>
    </div>
  }) : null;

  return (
    <>
      <Header activeMenu="Blogs" />
      <div id="main" className="blogs__main">
        <section className="blog-landing-only">
          <div className="grid">
            <div className="row">
              <div className="col col-sm-6">
                <div className="featured__post">
                  {
                    featuredPost && featuredPost.length > 0 ? (
                      <div className="post-block">
                        <Link className="post-block-image" to={
                          {
                            pathname: `/blogs/${featuredPost[0].slug}`,
                            state: {
                              'blog': featuredPost[0]
                            }
                          }
                        }>
                          <img alt="" src={featuredPost[0].image} />
                        </Link>
                        <a href="/blogs" className="post-type" target="">
                          <i className="fa fa-book-open"></i>
                          <span>Article</span>
                        </a>
                        <h4>
                          <Link to={
                            {
                              pathname: `/blogs/${featuredPost[0].slug}`,
                              state: {
                                'blog': featuredPost[0]
                              }
                            }
                          } >
                            {featuredPost[0].title}
                          </Link>
                        </h4>
                        <p>{featuredPost[0].description}</p>
                      </div>
                    ) : null
                  }
                </div>
              </div>
              <div className="col col-sm-1"></div>
              <div className="col col-sm-5">
                <div className="promoted__blog">
                  <h1>{t('Editor’s picks')}</h1>
                  <div className="row">
                    {
                      featuredPost ? featuredPost.map((item, idx) => (
                        <div key={idx} className="col col-sm-6">
                          <h4 className="post-subcategory">{item.type_of_article}</h4>
                          <p>
                            <Link to={
                              {
                                pathname: `/blogs/${item.slug}`,
                                state: {
                                  'blog': item
                                }
                              }
                            } >
                              {item.title}
                            </Link>
                          </p>
                        </div>
                      )) : null
                    }
                  </div>
                </div>
                <div className="newsletter">
                  <div className="newsletter__content">
                    <h4>{t('Subscribe to The Library')}</h4>
                    <p className="h6">{t('The best source of information for customer service, sales tips, guides, and industry best practices. Join us.')}</p>
                  </div>
                  <Form onSubmit={handleSubmit} className="user newsletter__form">
                    <div className="form-group">
                      <TextInput
                        placeholder={t("Email")}
                        type='text'
                        name='email'
                        error={errors.email}
                        value={values.email}
                        onChange={(e) => handleTextChange('email', e.target.value)}
                      />
                    </div>
                    <button disabled={disableSubmit} type="submit" className="btn btn-primary btn-user btn-block mb-3">
                      {t('Subscribe')}
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="featured-services" className="blogs">
          <div>
            <header className="section-header">
              <h4>{t('Consumer')}</h4>
              <p></p>
            </header>
            <div className="row">
              {blogList}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  onGetBlogs: () => dispatch(getBlogs()),
  onDoSubscribe: (data) => dispatch(doSubscribe(data)),
});

const mapStateToProps = state => ({
  blogs: state.home.blogs,
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogList);
