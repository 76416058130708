import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createFoscorisDigitalReports, editFoscorisDigitalReports, getDistrictWards } from "../../components/reducers/actions"
import Datepicker from '../../components/inputs/Datepicker'
import { useParams } from "react-router-dom";
import moment from 'moment'

const Medium = [
    {id:"0", name:"Retail Store"},
    {id:"1", name:"Distributor"},
    {id:"2", name:"Whole Saler"},
    {id:"3", name:"Transporter"},
    {id:"4", name:"WhareHouse Storage"},
    {id:"5", name:"Others"},
   
]

const High = [
    
    {id:"0", name:"Manufacturer"},
    {id:"1", name:"Repacker"},
    {id:"2", name:"Food Processor"},
    {id:"3", name:"Prepared Food"},
    {id:"4", name:"Others"},
]

const Low = [
    {id:"0", name:"Petti Retailer"},
    {id:"1", name:"Mobile Food Vendor"},
    {id:"2", name:"Ittinerant"},
    {id:"3", name:"Howker"},
    {id:"4", name:"Tea Stall"},
    {id:"5", name:"Others"},
]

const FoscorisDigitalReports = (props) => {
    const { districts, user,wards, onCreateFoscorisDigitalReports,wardsCategory,ongetDist, onEditFoscorisDigitalReports } = props;
    const [values, setValues] = useState({
        'foscoris_digital_inspection_conducted_upto_year' : '',
        'area_name':'',
        'area_code':'',
        'no_of_inspections_conducted_through_foscoris':'',
        'inspection_during_the_month_high':'',
        'inspection_during_the_month_medium':'',
        'inspection_during_the_month_low':'',
        'remarks' :'',
        'inspection_high':'',
        'inspection_medium':'',
        'inspection_low':'',
        'district' : '',
        'date' : '',
        'other_medium':null,
        'other_high':null,
        'other_low':null

    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])


    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            console.log(recordData)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line    
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'area_name',
            'area_code',
            'no_of_inspections_conducted_through_foscoris',
            'district' ,
            'inspection_high',
            'inspection_medium',
            'inspection_low',
            'date'
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {  
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditFoscorisDigitalReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/foscorisdigital-list/`);
            };

        } else {     
            const data = { ...values}
            setDisableSubmit(true);
            console.log(data);
            const resp = await onCreateFoscorisDigitalReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Foscoris Digital Inspection Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do")|| user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name `}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_inspections_conducted_through_foscoris"
                            >Number Of Inspections Conducted Through Foscoris *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of inspectiions conducted through foscoris`}
                                    type="number"
                                    name="no_of_inspections_conducted_through_foscoris"
                                    id="no_of_inspections_conducted_through_foscoris"
                                    value={values.no_of_inspections_conducted_through_foscoris}
                                    error={errors.no_of_inspections_conducted_through_foscoris}
                                    onChange={(e) =>handleTextChange("no_of_inspections_conducted_through_foscoris", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspection_during_the_month_high"
                            >Foscoris Digital Inspection Conducted during the month(High Risk) *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`No of Foscoris Digital Inspection Conducted (High risk)`}
                                    type="number"
                                    name="inspection_during_the_month_high"
                                    id="inspection_during_the_month_high"
                                    items={High}
                                    action="dropdown"
                                    value={values.inspection_during_the_month_high}
                                    error={errors.inspection_during_the_month_high}
                                    onChange={(text) =>handleTextChange("inspection_during_the_month_high", text)}
                                />
                            </div>
                        </div>
                        {values && values.inspection_during_the_month_high.includes('Others') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_high"
                            ></label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Foscoris Digital Inspection Conducted (High risk)`}
                                    type="text"
                                    name="other_high"
                                    id="other_high"
                                    value={values.other_high}
                                    error={errors.other_high}
                                    onChange={(e) =>handleTextChange("other_high", e.target.value)}
                                />
                            </div>
                        </div>
                        ): null}
                        {values && values.inspection_during_the_month_high ? (
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspection_high"
                            > Number of Foscoris Digital Inspection Conducted during the month(High Risk) *:</label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of Foscoris Digital Inspection Conducted (High risk)`}
                                    type="number"
                                    name="inspection_high"
                                    id="inspection_high"
                                    value={values.inspection_high}
                                    error={errors.inspection_high}
                                    onChange={(e) =>handleTextChange("inspection_high", e.target.value)}
                                />
                            </div>
                        </div> ):null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspection_during_the_month_medium"
                            >Foscoris Digital Inspection Conducted during the month(Medium Risk) *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`No of Foscoris Digital Inspection Conducted (Medium risk)`}
                                    type="number"
                                    name="inspection_during_the_month_medium"
                                    id="inspection_during_the_month_medium"
                                    items={Medium}
                                    action="dropdown"
                                    value={values.inspection_during_the_month_medium}
                                    error={errors.inspection_during_the_month_medium}
                                    onChange={(text) =>handleTextChange("inspection_during_the_month_medium", text)}
                                />
                            </div>
                        </div>
                        {values && values.inspection_during_the_month_medium.includes('Others') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_medium"
                            ></label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Foscoris Digital Inspection Conducted (Medium risk)`}
                                    type="text"
                                    name="other_medium"
                                    id="other_medium"
                                    value={values.other_medium}
                                    error={errors.other_medium}
                                    onChange={(e) =>handleTextChange("other_medium", e.target.value)}
                                />
                            </div>
                        </div> 
                        ):null}
                        {values && values.inspection_during_the_month_medium ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspection_medium"
                            >Number Of Foscoris Digital Inspection Conducted during the month(Medium Risk) *:</label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of Foscoris Digital Inspection Conducted (Medium risk)`}
                                    type="number"
                                    name="inspection_medium"
                                    id="inspection_medium"
                                    value={values.inspection_medium}
                                    error={errors.inspection_medium}
                                    onChange={(e) =>handleTextChange("inspection_medium", e.target.value)}
                                />
                            </div>
                        </div>
                        ):null}
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspection_during_the_month_low"
                            >Foscoris Digital Inspection Conducted during the month(Low Risk) *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`No of Foscoris Digital Inspection Conducted (Low risk)`}
                                    type="number"
                                    name="inspection_during_the_month_low"
                                    id="inspection_during_the_month_low"
                                    items={Low}
                                    action="dropdown"
                                    value={values.inspection_during_the_month_low}
                                    error={errors.inspection_during_the_month_low}
                                    onChange={(text) =>handleTextChange("inspection_during_the_month_low", text)}
                                />
                            </div>
                        </div>
                        {values && values.inspection_during_the_month_low.includes('Others') ? (
                             <div className="form-group row">
                             <label className="control-label col-sm-4 text-label" htmlFor="other_low"
                             > </label>
                             <div className="col-sm-8">
                                 <TextInput
                                     placeholder={`Foscoris Digital Inspection Conducted (Low risk)`}
                                     type="text"
                                     name="other_low"
                                     id="other_low"
                                     values={values.other_low}
                                     error={errors.other_low}
                                     onChange={(e) =>handleTextChange("other_low", e.target.value)}
                                 />
                             </div>
                         </div>  
                        ):null}
                        {values && values.inspection_during_the_month_low ? (
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspection_low"
                            >Number of Foscoris Digital Inspection Conducted during the month(Low Risk) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of Foscoris Digital Inspection Conducted (Low risk)`}
                                    type="number"
                                    name="inspection_low"
                                    id="inspection_low"
                                    value={values.inspection_low}
                                    error={errors.inspection_low}
                                    onChange={(e) =>handleTextChange("inspection_low", e.target.value)}
                                />
                            </div>
                        </div>              
                       ):null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`remarks`}
                                    type="text"
                                    name="remarks"
                                    id="remarks"
                                    value={values.remarks}
                                    error={errors.remarks}
                                    onChange={(e) => handleTextChange("remarks", e.target.value)}
                                />
                            </div>
                        </div>                
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateFoscorisDigitalReports: (data) => dispatch(createFoscorisDigitalReports(data)),
    onEditFoscorisDigitalReports: (data, record_id) => dispatch(editFoscorisDigitalReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(FoscorisDigitalReports);