import React, { useState } from 'react'
import { connect } from 'react-redux'
import Header from '../../components/Header'
import DropDownInput from '../../components/inputs/DropDownInput'
import TextAreaInput from '../../components/inputs/TextAreaInput'
import TextInput from '../../components/inputs/TextInput'
import Datepicker from '../../components/inputs/Datepicker'
import moment from 'moment'
import { distributeStock, distributeStockcsv, getRemainingstock, postStock, postStockcsv } from '../../components/reducers/actions'
import { useEffect } from 'react'
import MaterialTable from "material-table";
import { ArrowBackIos } from '@material-ui/icons'

const Stock = (props) => {
    const {
        onpostStock,
        ongetStock,
        ondistributeStock,
        ongetpostCsv,
        ongetdistributecsv
    }=props
    const[add,setAdd]=useState(false)
    const[view,setView]=useState(false)
    const[report,setReport]=useState(false)
    const[assign,setAssign]=useState(false)
    const[rep1,setRep1]=useState(false)
    const[rep2,setRep2]=useState(false)
    const [errors, setErrors] = useState({});
    const [availabelItem,setAvailableItem]=useState([]);
    const [availabelItemD,setAvailableItemD]=useState([]);
    const [selectdistributedI, setSelectDistributeI]=useState();
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [ar1,setAr1]=useState([])
    const [ar2,setAr2]=useState([])
    const [disableSubmit1, setDisableSubmit1] = useState(true);
    const [amnt, setAmnt] = useState(false);
    const [report1, setReport1] = useState({
        fromdate: new Date(),
        todate: new Date(),
        type: '',
      });
      const [report2, setReport2] = useState({
        fromdate: new Date(),
        todate: new Date(),
        type: '',
      });
      const [tabl1,setTable1]=useState([])
      const [tabl2,setTable2]=useState([])
    const[values,setValues]=useState({
        item:'',
        other_item:'',
        type:'',
        received_purchased_from:'',
        date_received_purchased_date:'',
        purchased_amount:null,
        comments:'',
        bill_attachment:null,
        quantity_of_item:'',
        page_no:'',
        register_volume_number:'',
    });
    const [distribute,setDistribute]=useState({
        item:'',
        distributed_to:'',
        date_distributed:'',
        quanity_issued:'',
        page_no:'',
        register_volume_number:'',
        availableItem:[],
    })
    const [available,setAvailable]=useState({
        item:'',
        available_:''
    })
    const Types=[
        {id:1,name:'Purchased'},
        {id:2,name:'Received'},
    ]
    const itemlist=[
        {id:'0',name:'MOUSE PADS'},
        {id:'1',name:'BIG STAPLER HP45'},
        {id:'2',name:'SCISSORS MED'},
        {id:'3',name:'V7 HI TECH PEN'},
        {id:'4',name:'NEEDLE'},
        {id:'5',name:'NATRAJ ERASER'},
        {id:'6',name:'PAPER WEIGHT '},
        {id:'7',name:'STAPLER PIN 23/17'},
        {id:'8',name:'GODREJ LOCK 7 LEV'},
        {id:'9',name:'GODREJ LOCK 6 LEV'},
        {id:'10',name:'FEVISTICK'},
        {id:'11',name:'SPOON SMALL'},
        {id:'12',name:'SPOON BIG'},
        {id:'13',name:'CD POUCH'},
        {id:'14',name:'CAMLIN INK PEN'},
        {id:'15',name:'REG VER RULE 170 PAGE'},
        {id:'16',name:'REG VER RULE 85 PAGE'},
        {id:'17',name:'BOND UN RUL REG 384 PA'},
        {id:'18',name:'PERMANENT MAKER BLUE'},
        {id:'19',name:'PERMANENT MAKER GREEN'},
        {id:'20',name:'PERMANANET MARKER RED'},
        {id:'21',name:'PERMANENT MARKER BLACK'},
        {id:'22',name:'WHITE BOARD MARKER RED'},
        {id:'23',name:'WHITE BOARD MARKER GREEN'},
        {id:'24',name:'STEEL POKER KUTHU'},
        {id:'25',name:'CELLO TAPE 1 INCH'},
        {id:'26',name:'CD MARKER BLUE'},
        {id:'27',name:'SINGLE PUNCH'},
        {id:'28',name:'FLAG SMALL'},
        {id:'29',name:'STAPLER PIN 24/6'},
        {id:'30',name:'DVD RW BOX'},
        {id:'31',name:'ALL PIN KUNDU USI'},
        {id:'32',name:'FLAG BIG SIZE'},
        {id:'33',name:'AAA BATTERY'},
        {id:'34',name:'NUMBERING INK'},
        {id:'35',name:'STAMP PAD INK'},
        {id:'36',name:'CUP & SAUCER'},
        {id:'37',name:'SONY CD '},
        {id:'38',name:'MATCH BOX'},
        {id:'39',name:'STAPLER PIN 10 M'},
        {id:'40',name:'ROYAL NAPKIN PKT'},
        {id: 41, name: 'STAMP PAD SMALL'},
        {id: 41, name: 'CELLO GREEN BALL POINT'},
        {id: 43, name: 'KASPERSKY ANTIVIRUS'},
        {id: 44, name: 'APSARA BLACK PENCIL'},
        {id: 45, name: 'CARBON SHEET'},
        {id: 46, name: 'TABLETS'},
        {id: 47, name: 'NIKON CAMERA WITH BAG'},
        {id: 48, name: 'BSNL 32 GB TRANS'},
        {id: 49, name: 'BSNL SIM CARDS'},
        {id: 50, name: 'BOLERO DUP KEY'},
        {id: 51, name: 'BSNL DONGLE'},
        {id: 52, name: 'CALCULATOR'},
        {id: 53, name: 'AIR TEL SIM CARDS'},
        {id: 54, name: 'FRY OIL MONITOR'},
        {id: 55, name: '32 GB PEN DRIVE'},
        {id: 56, name: '16 GB PEN DRIVE'},
        {id: 50, name: '64 GB PEN DRIVE'},
        {id: 57, name: 'EXTERNAL HARD 1 TB'},
        {id: 58, name: 'EXTERNAL HARD 2 TB'},
        {id: 59, name: 'TURKEY TOWEL'},
        {id: 60, name: 'DELL LAPTOP'},
        {id: 61, name: 'KEY BOARD CLEAN BRUSH'},
        {id: 62, name: 'WALKIE TALKIE'},
        {id: 63, name: 'INK BOTTLES CAR PERFUME'},
        {id: 64, name: 'SKETCH PEN SET'},
        {id: 65, name: 'CD MARKER'},
        {id: 66, name: 'WHITE BOARD MARKER'},
        {id: 67, name: 'FLAG MEDIUM'},
        {id: 68, name: 'APSARA ERASER'},
        {id: 69, name: 'CUTTER BIG'},
        {id: 70, name: 'PLASTIC SCALE'},
        {id: 71, name: 'STEEL SCALE'},
        {id: 72, name: 'FABER CASTLE ERASER'},
        {id: 73, name: 'APSARA SMALL RUBBER'},
        {id: 74, name: 'DOUBLE SIDE TAPE'},
        {id: 75, name: 'SABEENA POWDER'},
        {id: 76, name: 'CELLO TAPE 2 INCH'},
        {id: 77, name: 'ADD GEL GREEN PEN'},
        {id: 78, name: 'UNIBALL BLUE PEN'},
        {id: 79, name: 'RED BALL POINT PEN'},
        {id: 80, name: 'ADD GEL RED PEN'},
        {id: 81, name: 'ADD GEL BLACK PEN'},
        {id: 82, name: 'REG RUL HORI 170'},
        {id: 83, name: 'UNIBALL VIOLET PEN'},
        {id: 84, name: 'UNIBALL GREEN PEN'},
        {id: 85, name: 'ADD GEL PEN'},
        {id: 86, name: 'HI TECH POINT GREEN'},
        {id: 87, name: 'TAG BUNDLE'},
        {id: 88, name: 'PREMIER FACE TISSUE'},
        {id: 89, name: 'WHITENER PEN'},
        {id: 90, name: 'SHORTHAND NOTE'},
        {id: 91, name: 'ADD GEL BLUE PEN'},
        {id: 92, name: 'GODREJ LOCK OLD'},
        {id: 93, name: 'STAPLER SMALL'},
        {id: 94, name: 'BINDER CLIPS'},
        {id: 95, name: 'NOVA PENCILS'},
        {id: 96, name: 'AA BATTERY'},
        {id: 97, name: 'SCISSORS METAL'},
        {id: 98, name: 'CALLING BELL'},
        {id: 99, name: 'NEEDLE'},
        {id: 100, name: 'PAPER SOFT BALL PEN'},
        { id: 101, name: 'STEEL TUMBLER' },
        { id: 102, name: 'TWINE THREAD' },
        { id: 103, name: 'CD MARKER BLACK' },
        { id: 104, name: 'SHARPNER' },
        { id: 105, name: 'WHITEBOARD MARKER BLACK' },
        { id: 106, name: 'POPY CAR PERFUME' },
        { id: 107, name: 'SCRIBLING PAD RULED' },
        { id: 108, name: 'CD MARKER GREEN' },
        { id: 109, name: 'LIZOL' },
        { id: 110, name: 'HARPIC' },
        { id: 111, name: 'PHENYOLE' },
        { id: 112, name: 'LAPTOP MOU' },
        { id: 113, name: 'LAPTOP BAG' },
        { id: 114, name: 'JUDGE VEHICLE TOYOTA KEY' },
        { id: 115, name: 'INNOVA CRYSTA DUP KEY' },
        { id: 116, name: 'Others' }
    ]

    const getStock=async()=>{
        const resp=await ongetStock()
        console.log(resp)
        if(resp&&resp.response){
            setAvailableItem(resp.response.data) 
            let ar=resp.response.data.map((i,indx)=>{return {'id':i.id,'name':i.item+'-'+i.remaining_stock}})
            setAvailableItemD(ar)
        }
    }
    useEffect(()=>{
       getStock()
    },[])
    const handleTextC = (key, value) => {
        const updatedValues = { ...report1, [key]: value };
        setReport1(updatedValues);
      };
      const handleTextCh = (key, value) => {
        const updatedValues = { ...report2, [key]: value };
        setReport2(updatedValues);
      };
      const TextChange=(id)=>{
        var i = id.replace(/_/g,' ')
        var text = i[0].toUpperCase() + i.substr(1);
        return text
      }
      const handleSubmit1 = async (event) => {
        const data = { ...report1 };
        const datas = {}
        datas.fromdate = moment(data.fromdate).format("YYYY-MM-DD");
        datas.todate = moment(data.todate).format("YYYY-MM-DD");
        datas.type=data.type
        console.log(data);
            const resp = await ongetpostCsv(datas);
            if (resp && resp.response) {
              console.log(resp.response);
              setTable1(resp.response.data)
              let arr = resp.response.data[0]
              const str = Object.entries(arr).map(([key, value]) => `${key}`);
             let dat= str.map((i)=>{
                if(i!=='tableData')
                return{
                'title':TextChange(i),
                'field':`${i}`,
                filtering:true,
                    cellStyle: {
                    textAlign: "center",
                    }, 
              }}).filter(i=>i)
              console.log(dat)
              setAr1(dat)
            }
      };
      const handleSubmit2 = async (event) => {
        const data = { ...report2 };
        const datas = {}
        datas.fromdate = moment(data.fromdate).format("YYYY-MM-DD");
        datas.todate = moment(data.todate).format("YYYY-MM-DD");
        datas.type=data.type
        console.log(data);
            const resp = await ongetdistributecsv(datas);
            if (resp && resp.response) {
              console.log(resp.response);
              setTable2(resp.response.data)
              let arr = resp.response.data[0]
              const str = Object.entries(arr).map(([key, value]) => `${key}`);
             let dat= str.map((i)=>{
                if(i!=='tableData')
                return{
                'title':TextChange(i),
                'field':`${i}`,
                filtering:true,
                    cellStyle: {
                    textAlign: "center",
                    }, 
              }}).filter(i=>i)
              console.log(dat)
              setAr2(dat)
            }
      };
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'item',
            'type',
            'received_purchased_from',
            'date_received_purchased_date',
            'quantity_of_item',
            'register_volume_number',
            'page_no',
        ];
        
        if(values&&values.type&&values.type=='Purchased')keys.push('purchased_amount')
        if(values&&values.item&&values.item=='Others')keys.push('other_item')
          var shouldDisableSubmit = true;
          console.log(values,keys)
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }
    const validateDis = (values) => {
        var updatedErrors = {};
        var keys = [
        'item',
        'distributed_to',
        'date_distributed',
        'quanity_issued',
        'page_no',
        'register_volume_number',
        ];
          var shouldDisableSubmit = true;
          if(values&&values.quanity_issued&&parseInt(values.quanity_issued) > parseInt(selectdistributedI&&selectdistributedI.remaining_stock)){
            keys.push('quanity_issued')
            setAmnt(true)
        }else setAmnt(false)
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
         
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit1(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }
    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        console.log(updatedValues)
        validateValues(updatedValues);
    }
     const handleItem = (key, value) => {
        const updatedValues = availabelItem&&availabelItem.filter(i=>i.id==value)[0];
        setAvailable(updatedValues);
    }
     const handleDistribute = (key, value) => {
        const updatedValues = { ...distribute, [key]: value };
        if(key=='item'){
            let arr = availabelItem&&availabelItem.filter(i=>i.id==value)[0]
            setSelectDistributeI(arr)
        }
        setDistribute(updatedValues);
        validateDis(updatedValues)
    }
    const getAvailable=async()=>{
       
    }
    const handleSubmits = async (event) => {
        const data = { ...distribute}
        let arr = availabelItem&&availabelItem.filter(i=>i.id==data.item)[0]
        data.item=arr.id
        data.date_distributed=moment(data.date_distributed).format("YYYY-MM-DD")
        setDisableSubmit(true);
        const resp = await ondistributeStock(data);
        if (resp) {
            console.log(resp.response);
           window.location.href="/app/stock/add"
        };
}
    const handleSubmit = async (event) => {
            const data = { ...values}
            data.date_received_purchased_date=moment(data.date_received_purchased_date).format("YYYY-MM-DD")
            data.item= data.item=='Others'?data.other_item:data.item
            setDisableSubmit(true);
            const resp = await onpostStock(data);
            if (resp) {
                console.log(resp.response);
                window.location.href="/app/stock/add"
            };
    }
  return (
    <>
    <div className='col-md-12 mx-auto mt-md-5 pt-md-5'>
        <h3 style={{textDecorationLine:'underline'}} className='text-center'>TNFSD STOCK MANAGEMENT</h3>
    </div>
    <div className='ml-md-5 pl-md-5'>
     {(add||view||assign||report||rep1||rep2 )? <button onClick={()=>{window.location.href='/app/stock/add'}} className='btn btn-secondary px-4 py-2'><ArrowBackIos />back </button>:null}
    </div>
   <div className='col-md-10 mx-auto mt-md-5'>
        {!add&&!view&&!assign&&!report && !rep1 && !rep2 && <div className='d-flex flex-row justify-content-center'>
            <button style={{background:'#1689FF',color:'#fff'}} onClick={()=>setAdd(true)} className='btn border m-3 p-2'>Add Stock</button>
            <button style={{background:'#1689FF',color:'#fff'}} onClick={()=>{
                setAssign(true)
                getAvailable()
                }} className='btn border m-3 p-2'>Distribute Stock</button>
            <button style={{background:'#1689FF',color:'#fff'}} onClick={()=>setView(true)} className='btn border m-3 p-2'>View Available Stock</button>
            <button style={{background:'#1689FF',color:'#fff'}} onClick={()=>setReport(true)} className='btn border m-3 p-2'>Reports</button>
        </div>}

        {rep1?<>
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}>Stock Details</h3>
        <div
          className="filter_container">
          <div >
            <label  htmlFor="date">From Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`From Date`}
                name="fromdate"
                id="fromdate"
                value={report1.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextC("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">To Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={report1.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextC("todate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">Select Type: </label>
            <div className="filter_input" >
          <DropDownInput
                                        name="type"
                                        placeholder='Select the type'
                                        action="dropdown"
                                        value={report1.type}
                                        error={errors.type }
                                        items={Types}
                                        onChange={(value) => handleTextC("type", value)}
                                />
            </div>
          </div>
          
          <div  style={{marginTop:'30px'}} className="form-group">
            <button
              onClick={handleSubmit1}
              type="button"
              className="btn btn-success ml-3"
            >
              View
            </button>
          </div>
        </div>
        {tabl1 ? (
          <div
            style={{ width: "90%", fontSize: "16px" }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={ar1}
              data={tabl1}
              options={{
                exportButton: {
                    csv: true,
                    pdf: false,
                    },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                  zIndex:0
                },
              }}
              title="Stock Details Report"
            />
          </div>
        ) : null}
      </div>
    </div>
        </>:null}

        {rep2?<>
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}>Stock Distributed Details</h3>
        <div
          className="filter_container">
          <div >
            <label  htmlFor="date">From Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`From Date`}
                name="fromdate"
                id="fromdate"
                value={report2.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextCh("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">To Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={report2.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextCh("todate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">Select Type: </label>
            <div className="filter_input" >
          <DropDownInput
                                        type="text"
                                        name="type"
                                        placeholder='Select the type'
                                        action="dropdown"
                                        value={report2.type}
                                        error={errors.type }
                                        items={Types}
                                        onChange={(value) => handleTextCh("type", value)}
                                />
            </div>
          </div>
          
          <div  style={{marginTop:'30px'}} className="form-group">
            <button
              onClick={handleSubmit2}
              type="button"
              className="btn btn-success ml-3"
            >
              View
            </button>
          </div>
        </div>
        {tabl2 ? (
          <div
            style={{ width: "90%", fontSize: "16px" }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={ar2}
              data={tabl2}
              options={{
                exportButton: {
                    csv: true,
                    pdf: false,
                    },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                  zIndex:0
                },
              }}
              title="Stock Distributed Details Report"
            />
          </div>
        ) : null}
      </div>
    </div>
        </>:null}
        {add?<>
                <div>
                    <h3 className='text-center' style={{textDecorationLine:'underline'}}>Add Stock</h3>
                    <div className="card-body">
                    <form>
                        
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="item"
                            >Select Item <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        name="fund"
                                        action='dropdown'
                                        placeholder="Select the item"
                                        value={values.item}
                                        error={errors.item }
                                        items={itemlist}
                                        onChange={(value) => handleTextChange("item", value)}
                                />
                            </div>
                        </div>  
                        {values && values.item&&values.item.includes('Others')?   <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_item"
                            >Other Item <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="text"
                                        placeholder='Other item'
                                        name="other_item"
                                        value={values.other_item}
                                        error={errors.other_item }
                                        onChange={(e) => handleTextChange("other_item", e.target.value)}
                                />
                            </div>
                        </div> :null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type"
                            >Item Received or Purchased <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="type"
                                        placeholder='Select the type'
                                        action="dropdown"
                                        value={values.type}
                                        error={errors.type }
                                        items={Types}
                                        onChange={(value) => handleTextChange("type", value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_received_purchased_date"
                            >Purchased / Received Date <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <Datepicker
                                        type="text"
                                        placeholder='Select date'
                                        name="date_received_purchased_date"
                                        value={values.date_received_purchased_date}
                                        error={errors.date_received_purchased_date }
                                        onChangeText={(value) => handleTextChange("date_received_purchased_date", value)}
                                />
                            </div>
                        </div> 
                        
                    <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="quantity_of_item"
                            > Item Quantity <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="number"
                                        placeholder='Item Quantity'
                                        name="other_item"
                                        value={values.quantity_of_item}
                                        error={errors.quantity_of_item }
                                        onChange={(e) => handleTextChange("quantity_of_item", e.target.value)}
                                />
                            </div>
                        </div>
                     <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="received_purchased_from"
                                >Item Purchased/Received From <span style={{color:'red'}}> *</span>: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Product purchased from`}
                                        type="text"
                                        name="received_purchased_from"
                                        value={values.received_purchased_from}
                                        error={errors.received_purchased_from}
                                        onChange={(e) => handleTextChange("received_purchased_from", e.target.value)}
                                    />
                                </div>
                            </div>
                            {values && values.type&&values.type=='Purchased'?   <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="purchased_amount"
                            >Purchased Amount <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="number"
                                        placeholder='Purchased amount'
                                        name="purchased_amount"
                                        value={values.purchased_amount}
                                        error={errors.purchased_amount }
                                        onChange={(e) => handleTextChange("purchased_amount", e.target.value)}
                                />
                            </div>
                        </div> :null}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="register_volume_number"
                            >Stock Register Volume Number <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        placeholder='Stock register volume number'
                                        name="register_volume_number"
                                        value={values.register_volume_number}
                                        error={errors.register_volume_number }
                                        onChange={(e) => handleTextChange("register_volume_number", e.target.value)}
                                />
                            </div>
                        </div>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="page_no"
                            >Page Number <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="number"
                                        placeholder='Page number'
                                        name="page_no"
                                        value={values.page_no}
                                        error={errors.page_no }
                                        onChange={(e) => handleTextChange("page_no", e.target.value)}
                                />
                            </div>
                        </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="comments"
                            >Comments: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Comments`}
                                    name="comments"
                                    value={values.comments}
                                    error={errors.comments}
                                    onChange={(e) => handleTextChange("comments", e.target.value)}
                                />
                            </div>
                        </div>  
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="bill_attachment"
                            >Bill or Invoice Upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Invoice or Bill Upload`}
                                    type="file"
                                    name="bill_attachment"
                                    id="bill_attachment"
                                    error={errors.bill_attachment}
                                    onChange={(e) => handleTextChange("bill_attachment", e.target.files[0])}
                                />
                            </div>
                        </div>                   
                                 
                        <div className="form-group mx-auto text-center mt-md-5 pt-md-3">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
                </div>
        </>:null}


        {view?<>
            <div>
                    <h3 className='text-center' style={{textDecorationLine:'underline'}}>Available Stock</h3>
                    <div className="card-body">
                    <form>
                        
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="item"
                            >Select Item <span style={{color:'red'}}> *</span>: </label>
                            <div style={{zIndex:999}} className="col-sm-8">
                            <DropDownInput
                                        name="fund"
                                        action='ss'
                                        placeholder="Select the item"
                                        value={available.item}
                                        error={errors.item }
                                        items={availabelItemD}
                                        onChange={(value) => handleItem("item", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="item"
                            ></label>
                            {available?.remaining_stock?<div className='col-sm-8 d-flex flex-row '>
                                <h5>{available?.item}:</h5>
                                <h5 className='ml-3'>{available?.remaining_stock} Available</h5>
                            </div>:null}         
                               </div>  
                    </form>
                </div>
                </div>
        </>:null}

  {report?<>
            <div>
                    <h3 className='text-center' style={{textDecorationLine:'underline'}}>Stock Reports</h3>
                    <div className="card-body d-flex flex-row flex-wrap col-md-8 mx-auto justify-content-center">
                    <div onClick={()=>{
                      setRep1(true)
                      setReport(false)
                      setRep2(false)
                      }} className='shadow-sm p-5 bg-white card rouded m-2 col-md-4'>Total Stocks Report</div>
                    <div  onClick={()=>{
                      setRep2(true)
                      setReport(false)
                      setRep1(false)
                      }} className='shadow-sm p-5 bg-white card rouded m-2 col-md-4'>Distributed Items Report</div>
                </div>
                </div>
        </>:null}

        {assign?<>
           <div>
                    <h3 className='text-center' style={{textDecorationLine:'underline'}}>Distribute Stock</h3>
                    <div className="card-body">
                    <form>
                        
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="item"
                            >Select Item <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        name="fund"
                                        action='ss'
                                        placeholder="Select the item"
                                        value={distribute.item}
                                        error={errors.item }
                                        items={availabelItemD}
                                        onChange={(value) => handleDistribute("item", value)}
                                />
                            </div>
                        </div>  
                       
                       <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="distributed_to"
                            >Distribute To <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="text"
                                        placeholder='Distributed to'
                                        name="distributed_to"
                                        value={distribute.distributed_to}
                                        error={errors.distributed_to }
                                        onChange={(e) => handleDistribute("distributed_to", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="quanity_issued"
                            > Quantity Distributed<span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="number"
                                        placeholder='Item Quantity'
                                        name="quanity_issued"
                                        value={distribute.quanity_issued}
                                        error={errors.quanity_issued }
                                        onChange={(e) => handleDistribute("quanity_issued", e.target.value)}
                                />
                            </div>
                        </div>
                        {amnt && <span style={{color:'red', textAlign:'center',marginLeft:'120px',fontSize:'13px',marginTop:'-22px',fontWeight:'bold'}}>Distributed stock should not exceed the available stock</span>}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_distributed"
                            >Distributed Date <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <Datepicker
                                        type="text"
                                        placeholder='Select date'
                                        name="date_distributed"
                                        value={distribute.date_distributed}
                                        error={errors.date_distributed }
                                        onChangeText={(value) => handleDistribute("date_distributed", value)}
                                />
                            </div>
                        </div> 
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="register_volume_number"
                            >Stock Register Volume Number <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        placeholder='Stock register volume number'
                                        name="register_volume_number"
                                        value={distribute.register_volume_number}
                                        error={errors.register_volume_number }
                                        onChange={(e) => handleDistribute("register_volume_number", e.target.value)}
                                />
                            </div>
                        </div>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="page_no"
                            >Page Number <span style={{color:'red'}}> *</span>: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="number"
                                        placeholder='Page number'
                                        name="page_no"
                                        value={distribute.page_no}
                                        error={errors.page_no }
                                        onChange={(e) => handleDistribute("page_no", e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group mx-auto text-center mt-md-5 pt-md-3">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit1}
                                onClick={handleSubmits}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
                </div>
        </>:null}
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
    onpostStock: (data) => dispatch(postStock(data)),
    ongetStock: (data) => dispatch(getRemainingstock(data)),
    ondistributeStock: (data) => dispatch(distributeStock(data)),
    ongetpostCsv: (data) => dispatch(postStockcsv(data)),
    ongetdistributecsv: (data) => dispatch(distributeStockcsv(data)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(Stock)
