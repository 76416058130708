import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker";
import {
  createFSOEPOReports,
  editFSOEPOReports,
  getDistrictWards,
} from "../../components/reducers/actions";
import { useParams } from "react-router-dom";
import moment from "moment";

const EPO = [
  { id: "0", name: "Prohibition Order" },
  { id: "1", name: "Emergency Prohibition Order" },
];
const Sanctioned = [
  { id: "0", name: "Accepted" },
  { id: "1", name: "Rejected" },
];
const FSOEPOReport = (props) => {
  const {
    districts,
    wards,
    user,
    onCreateFSOEPOReports,
    wardsCategory,
    onEditFSOEPOReports,
    ongetDist,
  } = props;
  const [values, setValues] = useState({
    district: "",
    date: "",
    area_code: "",
    area_name: "",
    order_type: "",
    date_file_received: "",
    date_file_sent: "",
    date_rectification_received: "",
    date_rectification_sent: "",
    date_rectification_received_1: "",
    date_rectification_sent_1: "",
    date_reply_accepted: "",
    date_reply_sent_to_commissionerate: "",
    date_release_received_on: "",
    date_release_sent_on: "",
    date_released_on: "",
    upload_documents: "",
    date_prohibition_order_issued_to_fbo_on: "",
    upload_doc: "",
    upload_docs: "",
    epo_sanctioned: "",
    date_epo_sanctioned_on: "",
    date_epo_received_by_do: "",
    date_sent_to_fso_on: "",
    date_epn_sent_to_fbo_on: "",
    date_received_epo_to_fbo_on: "",
    date_epn_issued_to_fbo_on: "",
    date_epo_order_sent_to_fso_on: "",
    date_order_request_to_comissionerate_on: "",
    date_order_received_by_do_on: "",
    date_do_reply: "",
    date_epn_file_do: "",
    date_epn_receive_do: "",
  });
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  let { slug } = useParams();

  districts.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const getDis=async(data)=>{
    await ongetDist({district:data})
   }
   useEffect(()=>{
     if(values.district&&values.district){
       getDis(values.district)
     }
   },[values.district&&values.district])

  useEffect(() => {
    wardsCategory.map((o) => {
      if (o.id == values.area_code)
        return setValues({
          ...values,
          area_name: o.ward_name,
        });
    });
  }, [values.area_code]);
  useEffect(() => {
    if (props.history.location && props.history.location.state) {
      const recordData = props.history.location.state.values;
      console.log(recordData);
      setValues((valueState) => {
        Object.assign(valueState, recordData);
        return valueState;
      });
    } else if (slug) {
      console.log(slug);
    }
    if (
      (user && user.user_types && user.user_types.includes("do")) ||
      (user && user.user_types && user.user_types.includes("fso"))
    ) {
      const userData = { ...values, district: user.district };
      setValues(userData);
    }
    // eslint-disable-next-line
  }, []);

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = [
      "district",
      "date",
      "area_code",
      "area_name",
      "order_type",
      // "date_file_received",
      // "date_file_sent",
      // "date_rectification_received",
      // "date_rectification_sent",
      // "date_reply_accepted",
      // "date_reply_sent_to_commissionerate",
      // "date_release_received_on",
      // "date_release_sent_on",
      // "date_released_on",
    ];
    if(values&&values.order_type&&values.order_type=='Prohibition Order')keys.push(
      'date_file_sent','date_order_received_by_do_on','date_file_received','date_action_taken','date_rectification_received',
      'date_rectification_sent','date_reply_accepted','date_reply_sent_to_commissionerate','epo_sanctioned','date_epo_sanctioned_on',
      'date_epo_received_by_do','date_release_received_on','date_release_sent_on','date_released_on'
    )
    if(values&&values.order_type&&values.order_type=='Emergency Prohibition Order')keys.push('date_order_request_to_comissionerate_on',
      'date_epn_file_do','date_epn_receive_do','date_epo_order_sent_to_fso_on','date_epn_sent_to_fbo_on',
      'date_rectification_received_1','date_rectification_sent_1','epo_sanctioned','date_epo_sanctioned_on',
      'date_epo_received_by_do','date_release_received_on','date_release_sent_on','date_released_on',
      )
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });
    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };

  const handleSubmit = async (event) => {
    if (props.history.location && props.history.location.state) {
      const data = { ...values };
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
          formData.append(key, new Date(data[key]).toISOString());
        } else {
          formData.append(key, data[key]);
        }
      });
      const resp = await onEditFSOEPOReports(formData, values.id);
      if (resp && resp.response) {
        console.log(resp.response);
        props.history.push(`/app/display-reports/fso-EPO-list/`);
      }
    } else {
      const data = { ...values };
      console.log(data);
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key.startsWith("date")&&(data[key]!== null&&data[key]!=='')) {
          formData.append(key, new Date(data[key]).toISOString());
        } else {
          formData.append(key, data[key]);
        }
      });
      setDisableSubmit(true);
      console.log("frorm", formData);
      const resp = await onCreateFSOEPOReports(formData);
      if (resp && resp.response) {
        console.log(resp.response);
        props.history.push("/app/reports/");
      }
    }
  };

  const handleTextChange = (key, value) => {
    console.log("::", key, value);
    const updatedValues = { ...values, [key]: value };
    console.log(updatedValues);
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">
        {" "}
        Emergency Prohibition Order Report
      </h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <form>
            {(user && user.user_types && user.user_types.includes("do")) ||
            (user && user.user_types && user.user_types.includes("fso")) ? (
              <div className="form-group row">
                <label
                  className="control-label col-sm-4 text-label"
                  htmlFor="district"
                >
                  District :{" "}
                </label>
                <div className="col-sm-8">
                  <DropDownInput
                    type="text"
                    isDisabled
                    name="district"
                    value={values.district}
                    error={errors.district}
                    items={districts}
                    onChange={(value) => handleTextChange("district", value)}
                  />
                </div>
              </div>
            ) : (
              <div className="form-group row">
                <label
                  className="control-label col-sm-4 text-label"
                  htmlFor="district"
                >
                  District :{" "}
                </label>
                <div className="col-sm-8">
                  <DropDownInput
                    placeholder={`District`}
                    type="text"
                    name="district"
                    value={values.district}
                    error={errors.district}
                    items={districts}
                    onChange={(text) => handleTextChange("district", text)}
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <label
                className="control-label col-sm-4 text-label"
                htmlFor="area_code"
              >
                Area Code *:{" "}
              </label>
              <div className="col-sm-8">
                <DropDownInput
                  type="text"
                  name="area_code"
                  action="area_code"
                  value={values.area_code}
                  error={errors.area_code}
                  items={wardsCategory}
                  onChange={(value) => handleTextChange("area_code", value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                className="control-label col-sm-4 text-label"
                htmlFor="area_name"
              >
                Area Name *:{" "}
              </label>
              <div className="col-sm-8">
                <TextInput
                  placeholder={`Area Name`}
                  type="text"
                  name="area_name"
                  value={values.area_name}
                  error={errors.area_name}
                  onChange={(e) =>
                    handleTextChange("area_name", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                className="control-label col-sm-4 text-label"
                htmlFor="date"
              >
                Date *:{" "}
              </label>
              <div className="col-sm-8">
                <Datepicker
                  placeholder={`Date`}
                  name="date"
                  id="date"
                  value={values.date}
                  error={errors.date}
                  onChangeText={(text) => handleTextChange("date", text)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                className="control-label col-sm-4 text-label"
                htmlFor="order_type"
              >
                Order Type *:{" "}
              </label>
              <div className="col-sm-8">
                <DropDownInput
                  placeholder={`Order Type`}
                  name="order_type"
                  items={EPO}
                  action="dropdown"
                  value={values.order_type}
                  error={errors.order_type}
                  onChange={(text) => handleTextChange("order_type", text)}
                />
              </div>
            </div>
            {values && values.order_type==="Prohibition Order" && (
              <>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_file_sent"
                  >
                    Notice and Prohibition File Sent to DO On *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`Notice & Prohibition File Sent to DO On`}
                      name="date_file_sent"
                      value={values.date_file_sent}
                      error={errors.date_file_sent}
                      onChangeText={(text) =>
                        handleTextChange("date_file_sent", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_order_received_by_do_on"
                  >
                    Prohibition Approval file Received From DO On *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN File Received From DO On`}
                      name="date_order_received_by_do_on"
                      value={values.date_order_received_by_do_on}
                      error={errors.date_order_received_by_do_on}
                      onChangeText={(text) =>
                        handleTextChange("date_order_received_by_do_on", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_file_received"
                  >
                    Prohibition Order issued to fbo on *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`Prohibition Order issued to fbo on`}
                      name="date_file_received"
                      value={values.date_file_received}
                      error={errors.date_file_received}
                      onChangeText={(text) =>
                        handleTextChange("date_file_received", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="upload_doc"
                  >
                    Upload Document:{" "}
                  </label>
                  <div className="col-sm-8">
                    <TextInput
                      placeholder={`Upload Document`}
                      type="file"
                      name="upload_doc"
                      error={errors.upload_doc}
                      onChange={(e) =>
                        handleTextChange("upload_doc", e.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_action_taken"
                  >
                    Action Taken On *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`Action taken on`}
                      name="file_recommended"
                      value={values.date_action_taken}
                      error={errors.date_action_taken}
                      onChangeText={(text) =>
                        handleTextChange("date_action_taken", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_rectification_received"
                  >
                    Violation Rectified Reply Received from FBO On *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`Violation Rectified Reply Received from FBO On`}
                      name="file_recommended"
                      value={values.date_rectification_received}
                      error={errors.date_rectification_received}
                      onChangeText={(text) =>
                        handleTextChange("date_rectification_received", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_rectification_sent"
                  >
                    Violation Rectified Reply sent to DO On *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`Violation Rectified Reply sent to DO On`}
                      name="date_rectification_sent"
                      value={values.date_rectification_sent}
                      error={errors.date_rectification_sent}
                      onChangeText={(text) =>
                        handleTextChange("date_rectification_sent", text)
                      }
                    />
                  </div>
                </div>{" "}
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_reply_accepted"
                  >
                    EPN Notice sent to FBO on *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN Notice sent to FBO on`}
                      name="date_reply_accepted"
                      value={values.date_reply_accepted}
                      error={errors.date_reply_accepted}
                      onChangeText={(text) =>
                        handleTextChange("date_reply_accepted", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="upload_docs"
                  >
                    Upload Document:{" "}
                  </label>
                  <div className="col-sm-8">
                    <TextInput
                      placeholder={`Upload Document`}
                      type="file"
                      name="upload_docs"
                      error={errors.upload_docs}
                      onChange={(e) =>
                        handleTextChange("upload_docs", e.target.files[0])
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_reply_sent_to_commissionerate"
                  >
                    EPN request sent to commissionerate on *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN request sent to commissionerate on`}
                      name="date_reply_sent_to_commissionerate"
                      value={values.date_reply_sent_to_commissionerate}
                      error={errors.date_reply_sent_to_commissionerate}
                      onChangeText={(text) =>
                        handleTextChange(
                          "date_reply_sent_to_commissionerate",
                          text
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="epo_sanctioned"
                  >
                    EPN request Accepted/Rejeccted by commissionerate *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <DropDownInput
                      placeholder={`EPN request sent to commissionerate on`}
                      name="epo_sanctioned"
                      items={Sanctioned}
                      action="dropdown"
                      value={values.epo_sanctioned}
                      error={errors.epo_sanctioned}
                      onChange={(text) =>
                        handleTextChange("epo_sanctioned", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_epo_sanctioned_on"
                  >
                    EPN Sanctioned by Commissionerate on *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN Sanctioned by Commissionerate on `}
                      name="date_epo_sanctioned_on"
                      value={values.date_epo_sanctioned_on}
                      error={errors.date_epo_sanctioned_on}
                      onChangeText={(text) =>
                        handleTextChange("date_epo_sanctioned_on", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_epo_received_by_do"
                  >
                    EPN Received by DO on *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN Received by DO on  `}
                      name="date_epo_received_by_do"
                      value={values.date_epo_received_by_do}
                      error={errors.date_epo_received_by_do}
                      onChangeText={(text) =>
                        handleTextChange("date_epo_received_by_do", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_release_received_on"
                  >
                    EPN Release Approval From Commissionerate Received on *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN Release Approval From Commissionerate Received on`}
                      name="date_reply_accepted"
                      value={values.date_release_received_on}
                      error={errors.date_release_received_on}
                      onChangeText={(text) =>
                        handleTextChange("date_release_received_on", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_release_sent_on"
                  >
                    EPN Release sent to FBO On *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN Release sent to FBO On`}
                      name="date_reply_accepted"
                      value={values.date_release_sent_on}
                      error={errors.date_release_sent_on}
                      onChangeText={(text) =>
                        handleTextChange("date_release_sent_on", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="date_released_on"
                  >
                    EPN Released On *:{" "}
                  </label>
                  <div className="col-sm-8">
                    <Datepicker
                      placeholder={`EPN Released On`}
                      name="date_reply_accepted"
                      value={values.date_released_on}
                      error={errors.date_released_on}
                      onChangeText={(text) =>
                        handleTextChange("date_released_on", text)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    className="control-label col-sm-4 text-label"
                    htmlFor="upload_documents"
                  >
                    Upload Documents:{" "}
                  </label>
                  <div className="col-sm-8">
                    <TextInput
                      placeholder={`Upload Documents`}
                      name="upload_documents"
                      type="file"
                      error={errors.upload_documents}
                      onChange={(e) =>
                        handleTextChange("upload_documents", e.target.files[0])
                      }
                    />
                  </div>
                </div>{" "}
              </>
            )}
            {values &&
              values.order_type.includes("Emergency Prohibition Order") && (
                <>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_order_request_to_comissionerate_on"
                    >
                      EPN file request sent to comissionerate on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN file request sent to comissionerate on`}
                        name="date_order_request_to_comissionerate_on"
                        value={values.date_order_request_to_comissionerate_on}
                        error={errors.date_order_request_to_comissionerate_on}
                        onChangeText={(text) =>
                          handleTextChange(
                            "date_order_request_to_comissionerate_on",
                            text
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_epn_file_do"
                    >
                      EPN file sent to DO on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN file sent to DO on`}
                        name="date_reply_accepted"
                        value={values.date_epn_file_do}
                        error={errors.date_epn_file_do}
                        onChangeText={(text) =>
                          handleTextChange("date_epn_file_do", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_epn_receive_do"
                    >
                      EPN file received by DO on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN file received by DO on`}
                        name="date_epn_receive_do"
                        value={values.date_epn_receive_do}
                        error={errors.date_epn_receive_do}
                        onChangeText={(text) =>
                          handleTextChange("date_epn_receive_do", text)
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_epo_order_sent_to_fso_on"
                    >
                      EPN file issued to FSO on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN file issued to FSO on`}
                        name="date_epo_order_sent_to_fso_on"
                        value={values.date_epo_order_sent_to_fso_on}
                        error={errors.date_epo_order_sent_to_fso_on}
                        onChangeText={(text) =>
                          handleTextChange("date_epo_order_sent_to_fso_on", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_epn_sent_to_fbo_on"
                    >
                      EPN file issued to FBO on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN file issued to FBO on`}
                        name="date_epn_sent_to_fbo_on"
                        value={values.date_epn_sent_to_fbo_on}
                        error={errors.date_epn_sent_to_fbo_on}
                        onChangeText={(text) =>
                          handleTextChange("date_epn_sent_to_fbo_on", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_rectification_received_1"
                    >
                      Violation Rectified Reply Received from FBO On *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`Violation Rectified Reply Received from FBO On`}
                        name="file_recommended"
                        value={values.date_rectification_received_1}
                        error={errors.date_rectification_received_1}
                        onChangeText={(text) =>
                          handleTextChange("date_rectification_received_1", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_rectification_sent_1"
                    >
                      Violation Rectified Reply sent to DO On *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`Violation Rectified Reply sent to DO On`}
                        name="date_rectification_sent_1"
                        value={values.date_rectification_sent_1}
                        error={errors.date_rectification_sent_1}
                        onChangeText={(text) =>
                          handleTextChange("date_rectification_sent_1", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="epo_sanctioned"
                    >
                      EPN request Accepted/Rejeccted by commissionerate *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <DropDownInput
                        placeholder={`EPN request sent to commissionerate on`}
                        name="date_reply_sent_to_commissionerate"
                        items={Sanctioned}
                        action="dropdown"
                        value={values.epo_sanctioned}
                        error={errors.epo_sanctioned}
                        onChange={(text) =>
                          handleTextChange("epo_sanctioned", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_epo_sanctioned_on"
                    >
                      EPN Sanctioned by Commissionerate on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN Sanctioned by Commissionerate on `}
                        name="date_epo_sanctioned_on"
                        value={values.date_epo_sanctioned_on}
                        error={errors.date_epo_sanctioned_on}
                        onChangeText={(text) =>
                          handleTextChange("date_epo_sanctioned_on", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_epo_received_by_do"
                    >
                      EPN Received by DO on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN Received by DO on  `}
                        name="date_epo_received_by_do"
                        value={values.date_epo_received_by_do}
                        error={errors.date_epo_received_by_do}
                        onChangeText={(text) =>
                          handleTextChange("date_epo_received_by_do", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_release_received_on"
                    >
                      EPN Release Approval From Commissionerate Received on *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN Release Approval From Commissionerate Received on`}
                        name="date_reply_accepted"
                        value={values.date_release_received_on}
                        error={errors.date_release_received_on}
                        onChangeText={(text) =>
                          handleTextChange("date_release_received_on", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_release_sent_on"
                    >
                      EPN Release sent to FBO On *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN Release sent to FBO On`}
                        name="date_reply_accepted"
                        value={values.date_release_sent_on}
                        error={errors.date_release_sent_on}
                        onChangeText={(text) =>
                          handleTextChange("date_release_sent_on", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="date_released_on"
                    >
                      EPN Released On *:{" "}
                    </label>
                    <div className="col-sm-8">
                      <Datepicker
                        placeholder={`EPN Released On`}
                        name="date_reply_accepted"
                        value={values.date_released_on}
                        error={errors.date_released_on}
                        onChangeText={(text) =>
                          handleTextChange("date_released_on", text)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="control-label col-sm-4 text-label"
                      htmlFor="upload_documents"
                    >
                      Upload Documents:{" "}
                    </label>
                    <div className="col-sm-8">
                      <TextInput
                        placeholder={`Upload Documents`}
                        name="upload_documents"
                        type="file"
                        error={errors.upload_documents}
                        onChange={(e) =>
                          handleTextChange(
                            "upload_documents",
                            e.target.files[0]
                          )
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            <div className="form-group">
              <button
                onClick={() => props.history.push("/app/reports/")}
                className="btn btn-success"
                type="button"
              >
                Cancel
              </button>
              <button
                disabled={disableSubmit}
                onClick={handleSubmit}
                type="button"
                className="btn btn-success ml-3"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onCreateFSOEPOReports: (data) => dispatch(createFSOEPOReports(data)),
  onEditFSOEPOReports: (data, record_id) =>
    dispatch(editFSOEPOReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  wards: state.home.wards,
  wardsCategory: state.home.wardsCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(FSOEPOReport);
