import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createInformationEducationReports, editInformationEducationReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment'

const Type=[
    {id:"0", name:"IEC"},
    {id:"1", name:"Motivation"}
]
const options = [
    {id:"0", name: "DART"},
    {id:"1", name: "SNF"},
    {id:"2", name: "Rally Conducted"},
    {id:"3", name: "Drawing/Speech Competition"},
    {id:"4", name: "Drama/Video"},
    {id:"5", name: "Fostac Training"},
    {id:'6', name:"Others"}
]
const options1 = [
    {id:"0", name:"Hygine Rating Motivated"},
    {id:"1", name:"ERC Motivated"},
    {id:"2", name:"BHOG Motivated"},
    {id:"3", name:"Clean Street Food Motivated"},
    {id:"4", name:"Clean Vegetable Motivated"},
    {id:"5", name:"RUCO"},
    {id:"6", name:"Others"}


]
const InformationEducationReport = (props) => {
    const { districts, user,wards, onCreateInformationEducationReports,ongetDist ,wardsCategory,onEditInformationEducationReports } = props;
    const [values, setValues] = useState({
       
        'area_name':'',
        'area_code':'',
        'district' : '', 
        'date' : '',
        'type':'',
        'type_options':'',
        'name_of_the_subject_IEC_conducted':null,
        'no_of_camp_conducted':null,
        'no_of_participants_participated':null,
        'no_of_participants_motivated':null,
        'no_of_self_assesment_received':null,
        'other_iec':null,
        'other_motivation':null,
        'events_conducted_1':'',

    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [field, setField] = useState(false);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [ 'area_name',
        'area_code',
        'district', 
        'date',
        'type',
        'type_options',
        'events_conducted_1',
       ];
       if(values&&values.type&&values.type=='IEC')keys.push('type_options')
       if(values&&values.type&&values.type=='IEC'&&values.type_options&&values.type_options)keys.push('name_of_the_subject_IEC_conducted','no_of_camp_conducted','no_of_participants_participated')
       if(values&&values.type&&values.type=='Motivation')keys.push('type_options')
       if(values&&values.type&&values.type=='Motivation'&&values.type_options&&values.type_options)keys.push('no_of_participants_motivated')

        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditInformationEducationReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/information-education-list/`);
            };

        } else {       
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateInformationEducationReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    const handleBlur = ()=>{
        if(values.activities === "9"){
                setField(true)
                console.log("set field");
        }
        else setField(false)
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Information Education and Communication Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>  
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="events_conducted_1"
                            >Number of events conducted during the month *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of events conducted during the month `}
                                    type="number"
                                    name="events_conducted_1"
                                    id="events_conducted_1"
                                    value={values.events_conducted_1}
                                    error={errors.events_conducted_1}
                                    onChange={(e) => handleTextChange("events_conducted_1", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type"
                            >Type *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type`}
                                    type="text"
                                    name="type"
                                    id="type"
                                    action="dropdown"
                                    items={Type}
                                    value={values.type}
                                    error={errors.type}
                                    onChange={(text) => handleTextChange("type", text)}
                                />
                            </div>
                        </div>  
                        {values && values.type.includes('IEC') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_options"
                            >Type Options *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type options`}
                                    type="text"
                                    name="type_options"
                                    id="type_options"
                                    action="dropdown"
                                    items={options}
                                    value={values.type_options}
                                    error={errors.type_options}
                                    onChange={(text) => handleTextChange("type_options", text)}
                                />
                            </div>
                        </div>  
                        ):null}
                       {values && values.type_options.includes('Others')  && values.type.includes('IEC') ? (
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_iec"
                            >  </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Other IEC`}
                                    type="text"
                                    name="other_iec"
                                    id="other_iec"
                                    value={values.other_iec}
                                    error={errors.other_iec}
                                    onChange={(e) => handleTextChange("other_iec", e.target.value)}
                                />
                            </div>
                        </div>  
                       ):null}
                        {values && values.type.includes('IEC') && values.type_options ? (<div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_subject_IEC_conducted"
                            >Name Of the Subject Covered in IEC *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the subject covered in IEC`}
                                    type="text"
                                    name="name_of_the_subject_IEC_conducted"
                                    id="name_of_the_subject_IEC_conducted"
                                    value={values.name_of_the_subject_IEC_conducted}
                                    error={errors.name_of_the_subject_IEC_conducted}
                                    onChange={(e) => handleTextChange("name_of_the_subject_IEC_conducted", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_camp_conducted"
                            >Number Of Camps Conducted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of camps conducted`}
                                    type="number"
                                    name="no_of_camp_conducted"
                                    id="no_of_camp_conducted"
                                    value={values.no_of_camp_conducted}
                                    error={errors.no_of_camp_conducted}
                                    onChange={(e) => handleTextChange("no_of_camp_conducted", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_participants_participated"
                            >Number Of Participants Participated *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of participants participated`}
                                    type="number"
                                    name="no_of_participants_participated"
                                    id="no_of_camp_conducted"
                                    value={values.no_of_participants_participated}
                                    error={errors.no_of_participants_participated}
                                    onChange={(e) => handleTextChange("no_of_participants_participated", e.target.value)}
                                />
                            </div>
                        </div> </div>) : null}
                            { values && values.type.includes('Motivation') ? (
                                 <div className="form-group row">
                                 <label className="control-label col-sm-4 text-label" htmlFor="type_options"
                                 >Type Options *: </label>
                                 <div className="col-sm-8">
                                     <DropDownInput
                                         placeholder={`Type options`}
                                         type="text"
                                         name="type_options"
                                         id="type_options"
                                         action="dropdown"
                                         items={options1}
                                         value={values.type_options}
                                         error={errors.type_options}
                                         onChange={(text) => handleTextChange("type_options", text)}
                                     />
                                 </div>
                             </div>  
                            ) :null}
                         {values && values.type_options.includes('Others')&&values.type.includes('Motivation') ? (
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_motivation"
                            >  </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Other Motivation`}
                                    type="text"
                                    name="other_motivation"
                                    id="other_motivation"
                                    value={values.other_motivation}
                                    error={errors.other_motivation}
                                    onChange={(e) => handleTextChange("other_motivation", e.target.value)}
                                />
                            </div>
                        </div>  
                       ):null}
                            {values && values.type.includes('Motivation') && values.type_options ? ( <div>
                             <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_participants_motivated"
                            >Number Of Participants *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of participants`}
                                    type="number"
                                    name="no_of_participants_motivated"
                                    id="no_of_participants_motivated"
                                    value={values.no_of_participants_motivated}
                                    error={errors.no_of_participants_motivated}
                                    onChange={(e) => handleTextChange("no_of_participants_motivated", e.target.value)}
                                />
                            </div>
                        </div> 
                         </div>):null}
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateInformationEducationReports: (data) => dispatch(createInformationEducationReports(data)),
    onEditInformationEducationReports: (data, record_id) => dispatch(editInformationEducationReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(InformationEducationReport);