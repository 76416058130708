import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import ModelPopup from "../../components/ModelPopup";
import { Link } from "react-router-dom";
import {
  addFboDetails,
  addNSFboDetails,
  deleteComplaint,
  editFSONonSampleReports,
  getComplaints,
  getFsoOfficers,
  getLabDifferentReports,
  getNonSampleStatus,
  getSampleStatus,
  updateCivil,
  updateCriminal,
  updateSample,
  updateSampleList,
} from "../../components/reducers/actions";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/FsoAccordion/FsoAccordion";
import Table from "../../components/Table/Table";
import LoadingOverlay from "react-loading-overlay";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import TextInput from "../../components/inputs/TextInput";
import Datepicker from "../../components/inputs/Datepicker";
const TypeOfTraders = [
  { id: "0", name: "Manufacturer" },
  { id: "1", name: "Retailer" },
  { id: "2", name: "WholeSaler/Distributor/Repacker" },
  { id: "3", name: "Transporter" },
  // { id: "4", name: "Others" },
];
const NSEntry = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const {
    user,
    onComplaints,
    onGetFsoOfficers,
    sample_data,
    fso_submit_data,
    lab_analyse_sample,
    req_sample,
    resultObtained,
    criminalResults,
    civilResults,
    ongetSample,
    overall_Sample,
    districts,
    ongetDLabs,
    ongetFso,
    fsoOfficers,
    onaddFbo,
  onEditFSOSampleReports,

  } = props;
  console.log(props);
  const [activeTab, setActiveTab] = useState(
    user && user.user_types.includes("fso") ? "1" : "1"
  );
  const { t, i18n } = useTranslation();
  const [diffSample, setDiffSample]=useState([]);
  const[toggleAction,setToggleAction]=useState(false)
  const [pageClosed, setPageClosed] = useState(1);
  const [pageAssign, setPageAssign] = useState(1);
  const [pageAssignfso, setPageAssignfso] = useState(1);
  const [selectedFso, setSelectedFso] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagelist, setPageList] = useState(1);
  const [pageAnalyse, setPageAnalyse] = useState(1);
  const [pageResult, setPageResult] = useState(1);
  const [pageWaitingresult, setPageWaitingResult] = useState(1);
  const [pageCivil, setPageCivil] = useState(1);
  const [pageCivilWait, setPageCivilWait] = useState(1);
  const [pageCriminal, setPageCriminal] = useState(1);
  const [pageCriminalWait, setPageCriminalWait] = useState(1);
  const [activeAccordion, setActiveAccordion] = useState(-1);
  const [Addmodal, setAddModal] = useState(false);
  const [id, setId]=useState();
  const [ids, setIds]=useState();
  const [fsoId, setFsoOId]=useState();
  const [values, setValues] = useState([
    {
      type_of_trader: "",
      trader_name: "",
      trader_address: "",
      upload_docs: null,
    },
  ]);
  const countPerPage = 10;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  useEffect(() => {
    if (user && user.user_types.includes("do")) {
      onGetFsoOfficers({
        fso_category: true,
      });
      if (props.history.location && props.history.location.state) {
        setActiveTab(props.history.location.state.tab);
      }
    }
  }, []);

  const loadPageWaitingResult = async () => {
    setLoading(true);
    if (activeTab === "4"&&user&&user.user_types&&user.user_types.includes('do')) {
      await ongetSample({
        page: pageWaitingresult,
        per_page: countPerPage,
        is_base64: true,
        status:"Result submitted to DO",
      });
      setLoading(false);
    }
  };
  const loadSamples = async () => {
    setLoading(true);
    if (activeTab === "1"&&user&&user.user_types&&user.user_types.includes('do')) {
      await ongetSample({
        page: pagelist,
        per_page: countPerPage,
        is_base64: true,
        status:'all',
      });
      setLoading(false);
    }
    if (activeTab === "1"&&user&&user.user_types&&user.user_types.includes('fso')) {
      await ongetSample({
        page: pagelist,
        per_page: countPerPage,
        is_base64: true,
        status:'all',
      });
      setLoading(false);
    }
    if (activeTab === "1"&&user&&user.user_types&&user.user_types.includes('cfa')) {
      const resp = await ongetDLabs({status:'Submitted to lab'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);
    }
  };

  const loadAnalyseSample = async () => {
    setLoading(true);
    if (activeTab === "2"&&user&&user.user_types&&user.user_types.includes('fso')) {
      await ongetSample({
        page: pageAnalyse,
        per_page: countPerPage,
        is_base64: true,
        status:'Waiting for DO Approval',
      });
      setLoading(false);
    }
    if (activeTab === "2"&&user.user_types&&user.user_types.includes('do')) {
      await ongetSample({
        page: pageAnalyse,
        per_page: countPerPage,
        is_base64: true,
        status:'Waiting for DO Approval',
      });
      setLoading(false);
    }
    if (activeTab === "2"&&user&&user.user_types&&user.user_types.includes('cfa')) {
      const resp = await ongetDLabs({status:'Waiting for analysis'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);
    }
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('cfa')) {
      const resp = await ongetDLabs({status:'waiting for lab result'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);
    }
    if (activeTab === "4"&&user&&user.user_types&&user.user_types.includes('cfa')) {
      const resp = await ongetDLabs({status:'all'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);
    }
  };
  const loadSampless = async () => {
    setLoading(true);
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('do')) {
      await ongetSample({
        page: pageResult,
        per_page: countPerPage,
        is_base64: true,
        status:'waiting for another part sample',
      });
      setLoading(false);
    }
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('fso')) {
      await ongetSample({
        page: pageResult,
        per_page: countPerPage,
        is_base64: true,
        status:'Waiting for submit',
      });
      setLoading(false);
    }
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('cfa')) {
      const resp = await ongetDLabs({status:'waiting for lab result'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);
    }
  };
 

  useEffect(() => {
    loadSampless();
  }, [pageAssign, activeTab]);
  useEffect(() => {
    loadSamples();
  }, [pagelist, activeTab]);
  useEffect(() => {
    loadPageWaitingResult();
  }, [pageWaitingresult, activeTab]);
  useEffect(() => {
    loadAnalyseSample();
  }, [pageAnalyse, activeTab]);
  useEffect(()=>{
    if(user&&user.district&&user.district){
      getOfficer(user.district)
    }
  },[user&&user])
  const getOfficer=async(id)=>{
    const data={district_id:id}
   const resp = await ongetFso(data)
   console.log('resp', resp,data)
  }
  const setRowid=(id)=>{
    setAddModal(true)
    setId(id)
  }
  const toBase64 = file => new Promise((resolve, reject) => {
    let baseURL = "";
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
  const conver =async(dat)=>{
    var data=''
    var datas =await toBase64(dat).then(result => {
     data  = result;
    })
    .catch(err => {
      console.log(err);
    });
    return data
  }
  const submitFbo=async()=>{
    const data = [...values]
  const resp = await onaddFbo(data,id)
  if(resp){
    console.log( 'sub' ,resp)
    setAddModal(false)
    props.history.push('/app/dep-non-sample-reports')
  }
  }
  const handleActionChange = (e, index) => {
    const { name, value } = e.target;
    const valuess = [...values];
    if (e.target.name == "upload_docs") {
      value = e.target.files[0];
    }
    valuess[index][name] = value;
    setValues(valuess);
  };
  const setRows=(id)=>{
    setIds(id)
    setToggleAction(true)
  }
  const handlePlus = () => {
    setValues([
      ...values,
      {
        type_of_trader: "",
        trader_name: "",
        trader_address: "",
        upload_docs: "",
      },
    ]);
  };
  const handleLess = (index) => {
    const valuess = [...values];
    valuess.splice(index, 1);
    setValues(valuess);
  };
  const handleAssignFso=(text,txt)=>{
    setFsoOId(txt)
 }

const submitFso=async()=>{
var data = new FormData()
data.append('fso',parseInt(fsoId))
console.log(fsoId)
const resp=await onEditFSOSampleReports(data,ids)
console.log(resp)
if(resp&&resp.data == 'success'){
props.history.push('/app/dep-non-sample-reports')
}
}


  const customStyles = {
    rows: {
      style: {
        minHeight: "82px",
        fontSize: "18px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        minHeight: "72px",
        marginTop: "20px",
        fontWeight: "bold",
        color: "#1589FF",
        fontSize: "18px",
        background: "#e1eef0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };
  var column = [
    {
      name: "District",
      cell: (row) => <div>{getDistrictName(row.district)}</div>,
      selector: "sample_number_given_by_do",
      sortable: true,
    },
    {
      name: "Sample Received date",
      cell: (row) => (
        <div>
        {row.date_sample_received_date? moment(row.date_sample_received_date).format("DD/MM/YYYY"):'-'}
        </div>
      ),
      selector: "date_sample_lifted_date_and_time",
      sortable: true,
    },
    {
      name: "Lab Code",
      cell: (row) => <div>{row.lab_code?row.lab_code:'-'}</div>,
      selector: "lab_code",
      sortable: true,
    },
    {
      name: "Case file Status",
      cell: (row) => <div>{row.status?row.status:'-'}</div>,
      selector: "sample_status",
      sortable: true,
    },
    {
      name: "Result Type",
      cell: (row) => <div>{row.result_type?row.result_type:'-'}</div>,
      selector: "result_type",
      sortable: true,
    },
    {
      name: t("Action"),
      cell: (row) => (
        <div className="action__area">
            {activeTab == "1" ? (
            <>
              {user &&
                user.user_types &&
                user.user_types.includes("cfa") &&
                !row.result_docs && (
                  <Link
                    to={{
                      pathname: `/app/reports/lab-different-reports`,
                      state: {
                        values: row,
                        action_type: "analyse_code",
                        showLess:true,
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px" }}
                      className="btn btn-info btn-sm"
                    >
                       Add sample code
                    </Button>
                  </Link>
                )}
            </>
          ) : null}
           {activeTab == "2" ? (
            <>
              {user && user.user_types && user.user_types.includes("cfa") ? (
                <Link
                  to={{
                    pathname: `/app/reports/lab-different-reports`,
                    state: {
                      values: row,
                      action_type:"analyse",
                      // showLess:true,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                   Analyse Sample
                  </Button>
                </Link>
              ) : null}
            </>
          ) : null}
                        {user && user.user_types && user.user_types.includes("do") ? (

          <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        action_t: "case_submit",
                        // showLess:true,
                        action_type:'sample_fit',
                        action:'add_result',
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", marginLeft: "3px" }}
                      className="btn btn-info btn-sm"
                     >
                        convert case
                    </Button>
                  </Link>):null}
          {activeTab == "3" ? (
            <>
              {user && user.user_types && user.user_types.includes("cfa") ? (
                <Link
                  to={{
                    pathname: `/app/reports/lab-different-reports`,
                    state: {
                      values: row,
                      action_type:"result",
                      showLess:true,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                  Add result
                  </Button>
                </Link>
              ) : null}
             
            </>
          ) : null}
          {activeTab == "4" ? (
            <>
              {user && user.user_types && user.user_types.includes("cfa") && row.upload_documents? (
               
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                    onClick={()=>window.open(row.upload_documents)}
                  >
                 View Result
                  </Button>
              
              ) : null}
            </>
          ) : null}
        </div>)}
  ]
  const getDistrictName =(id)=>{
    var nam = ''
    let data = districts && districts.filter(i=>id==i.id)[0]
    nam=data.name
    return nam
} 
  var columns = [
    {
      name: "District",
      cell: (row) => <div>{getDistrictName(row.district)}</div>,
      selector: "sample_number_given_by_do",
      sortable: true,
    },
    {
      name: "FSO",
      cell: (row) => <div>{row.fso_name}</div>,
      selector: "assigned_fso",
      sortable: true,
    },
    {
      name: "Sample Type",
      cell: (row) => <div>{row.type_of_sample_lifted?row.type_of_sample_lifted:'-'}</div>,
      selector: "type_of_sample_lifted",
      sortable: true,
    },
    {
      name: "Sample Status",
      cell: (row) => <div>{row.status_of_report}</div>,
      selector: "sample_status",
      sortable: true,
    },
    {
      name: t("Action"),
      cell: (row) => (
        <div className="action__area">
          {activeTab == "1" ? (
            <>
              {user &&
                user.user_types &&
                user.user_types.includes("cfa") &&
                !row.result_docs && (
                  <Link
                    to={{
                      pathname: `/app/reports/lab-different-reports`,
                      state: {
                        values: row,
                        action_type:
                          row.status_of_report == "Waiting for analysis"
                            ? "sample_fit"
                            : "analyse",
                            showLess:true,
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px" }}
                      className="btn btn-info btn-sm"
                    >
                      {row.status_of_report == "Waiting for analysis"
                        ? "Analyse Sample"
                        : row.status_of_report == "waiting for lab result"
                        ? "Add result"
                        : "Add sample code"}
                    </Button>
                  </Link>
                )}
                {(user &&
                user.user_types &&
                user.user_types.includes("fso")||user &&
                user.user_types &&
                user.user_types.includes("do")) && (row.status_of_report!=='Final Judgement'&&row.status_of_report!=='Final Judgement_')&&
                 (
                  
                    <Button
                      style={{ fontSize: "12px",margin:'2px' }}
                      className="btn btn-info btn-sm"
                      onClick={()=>setRowid(row.id)}
                    >
                      Add FBO
                    </Button>
                )}
                 {(user &&
                user.user_types &&
                user.user_types.includes("fso")||user &&
                user.user_types &&
                user.user_types.includes("do")) && (row.status_of_report!=='Final Judgement'&&row.status_of_report!=='Final Judgement_')&&
                 (
                  
                    <Button
                      style={{ fontSize: "12px",margin:'2px' }}
                      className="btn btn-info btn-sm"
                      onClick={() => setRows(row.id)}
                    >
                      Reassign to FSO
                    </Button>
                )}
            </>
          ) : null}
          {activeTab == "2" ? (
            <>
              {user && user.user_types && user.user_types.includes("do") ? (
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports/`,
                    state: {
                      values: row,
                      action_t:"case_submit",
                      showLess:true,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                  Convert Case
                  </Button>
                </Link>
              ) : null}
            </>
          ) : null}
            {activeTab == "3" ? (
            <>
              {user && user.user_types && user.user_types.includes("cfa") ? (
                <Link
                  to={{
                    pathname: `/app/reports/lab-different-reports`,
                    state: {
                      values: row,
                      action_type:"sample_fit",
                      showLess:true,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                  Add result
                  </Button>
                </Link>
              ) : null}
               {user &&
                user.user_types &&
                user.user_types.includes("fso") &&
                <Link 
                to={{
                  pathname: `/app/reports/fso_non_sample_reports/`,
                  state: {
                    values: row,
                    showLess:true,
                    admore:true,
                  },
                }}
                >
                <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                   Add More details
                  </Button>
                </Link>}
            </>
          ) : null}
         
          {activeTab == "4" ? (
            <div>
                  {user &&
                        user.user_types &&
                        user.user_types.includes("do") ?<> 
                   {row.upload_sample_documents && <Button
                      className="btn btn-info btn-sm"
                      style={{ fontSize: "12px", margin: "2px" }}
                      onClick={() =>
                        window.open(row.upload_sample_documents)
                      }
                    >
                      View Result
                    </Button>}
                  <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports/`,
                      state: {
                        values: row,
                        action_t: "case_submit",
                        showLess:true,
                        action_type:'sample_fit',
                        action:'add_result',
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", marginLeft: "3px" }}
                      className="btn btn-info btn-sm"
                     >
                        convert case
                    </Button>
                  </Link>
                  <Link
                    to={{
                      pathname:`/app/reports/fso_sample_reports`,
                      state: {
                        values: row,
                        sampleAppeal: true,
                        showLess:true
                      },

                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", marginLeft: "3px" }}
                      className="btn btn-info btn-sm"
                    >
                      {user &&
                        user.user_types &&
                        user.user_types.includes("do") &&
                        activeTab === "4" &&
                        "Appeal request"}
                    </Button>
                  </Link></>:null}
            </div>  
          ) : null}
        </div>
      ),
    },
  ];

  const toggle = () => setModal(!modal);
  const handleDrp = (value, index) => {
    const filter = [...values];
    filter[index].type_of_trader = value;
    setValues(filter);
  };
  const handleDate = (value, index) => {
    const filter = [...values];
    filter[index].date_V_A_sent_on = value;
    setValues(filter);
  };
  const handleFile = async(e, index) => {
    const filter = [...values];
    filter[index].upload_docs = await conver(e.target.files[0]);
    setValues(filter);
  };
  return (
    <div className="container-fluid">
      {/* {modal ? (
        <ModelPopup
          modal={modal}
          data={selectedItem}
          handleDeleteSubmit={handleDeleteSubmit}
          title="Complaint"
          toggle={toggle}
        ></ModelPopup>
      ) : null} */}
       {Addmodal ? (
        <Modal isOpen={Addmodal}>
          <ModalHeader style={{ fontSize: "12px" }}>
            Add FBO Details
          </ModalHeader>
          <ModalBody>
            <div>
              {values.map((x, i) => {
                return (
                  <>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="type_of_trader"
                      >
                        Type of trader:{" "}
                      </label>
                      <div className="col-sm-8">
                        <DropDownInput
                          placeholder={`Type of Trader`}
                          type="text"
                          items={TypeOfTraders}
                          action="dropdown"
                          name="type_of_trader"
                          value={x.type_of_trader}
                          onChange={(text) => handleDrp(text, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                        Trader name:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextInput
                          placeholder={`Inspected Premisis Owner Name`}
                          type="text"
                          name="trader_name"
                          value={x.trader_name}
                          onChange={(e) => handleActionChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="address_of_respondant_premisis"
                      >
                        {" "}
                        Trader address:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextAreaInput
                          placeholder={`Inspected Premisis Owner Address`}
                          type="text"
                          name="trader_address"
                          value={x.trader_address}
                          onChange={(e) => handleActionChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                        Trader documents:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextInput
                          placeholder={`Inspected Premisis Owner Name`}
                          type="file"
                          name="upload_docs"
                          // value={x.upload_docs}
                          onChange={(e) => handleFile(e, i)}
                        />
                      </div>
                    </div>
                    {/* <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                       V-A sent on:{" "}
                      </label>
                      <div className="col-sm-8">
                        <Datepicker
                          placeholder={`V-A sent on`}
                          type="file"
                          name="date_V_A_sent_on"
                          value={x.date_V_A_sent_on}
                          onChangeText={(text) => handleDate(text, i)}
                        />
                      </div>
                    </div> */}
                    {i !== values.length - 1 && (
                      <button
                        style={{ float: "right" }}
                        className="btn btn-warning btn-sm btn-circle"
                        onClick={() => handleLess(i)}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    )}
                    {values.length - 1 === i && (
                      <button
                        style={{ float: "right" }}
                        className="btn btn-success btn-sm btn-circle"
                        onClick={handlePlus}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    )}
                  </>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="form-group">
              <button
                onClick={() => setAddModal(false)}
                className="btn btn-success"
                type="button"
              >
                Cancel
              </button>
              <button
                // disabled={disableSubmit}
                onClick={submitFbo}
                type="button"
                className="btn btn-success ml-3"
              >
                Submit
              </button>
            </div>
          </ModalFooter>
        </Modal>
      ) : null}
      {toggleAction ? (
          <Modal isOpen={toggleAction}>
            <ModalHeader toggle={() => setToggleAction(false)}>
              {t("Actions")}
            </ModalHeader>
            <ModalBody>
              <div>
                  <>
                    <div className="form-group">
                      <DropDownInput
                        type="text"
                        items={fsoOfficers}
                        name="assigned_fso"
                        action='fsos'
                        placeholder="Select food safety officer"
                        value={values.assigned_fso}
                        // valueId={true}
                        onChange={(text) =>
                          handleAssignFso("assigned_fso", text)
                        }
                      />
                    </div>
                    {/* <div className="form-group">
                      <TextAreaInput
                        placeholder={"Remarks"}
                        name="reason"
                        value={values.reason}
                        onChange={(e) =>
                          handleAssignFso("reason", e.target.value)
                        }
                      />
                    </div> */}
                  </>
                </div>
                </ModalBody>
                <ModalFooter>
            <div className="form-group">
              <button
                // disabled={disableSubmit}
                onClick={submitFso}
                type="button"
                className="btn btn-success ml-3"
              >
                Submit
              </button>
            </div>
          </ModalFooter>
                </Modal>):null}
      <div className="mb-4">
        <div className="py-3">
          {/* <h1 className="h4 mb-4 text-gray-800">{t("Sample")}</h1> */}
          {((user && user.user_types.includes("fso"))||(user && user.user_types.includes("ho")))? (
            <>
              <Link
                className="btn btn-primary-green pull-right"
                to="/app/reports/fso_non_sample_reports/"
              >
                Add Non Sample
              </Link>
            </>
          ) : null}
           {user && user.user_types.includes("cfa") ? (
            <>
              <Link
                className="btn btn-primary-green pull-right"
                to="/app/reports/lab-different-reports"
              >
                Add New Sample
              </Link>
            </>
          ) : null}
        </div>
        <div
          className="mt-5"
          style={{ fontSize: i18n.language === "ta" ? 15 : null }}
        >
          <Nav tabs>
            {user && user.user_types.includes("fso") ? (
              <>
               <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                   All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Submitted Data's 
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Data to be sent
                  </NavLink>
                </NavItem>
                
              </>
            ) : null}
            {user && user.user_types.includes("do") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Waiting for DO approval
                  </NavLink>
                </NavItem>
                
              </>
            ) : null}
             {user && user.user_types.includes("cfa") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Sample(s) to be analysed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Sample(s) waiting for analysis
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                   Sample(s) waiting for result
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                   Sample from Different Sources
                  </NavLink>
                </NavItem>
              </>
            ) : null}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {(user && user.user_types && user.user_types.includes('do')||user && user.user_types && user.user_types.includes('fso')) ? <>
                  {overall_Sample && overall_Sample ? 
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={overall_Sample}
                          count={overall_Sample.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          pageFalse
                          handlePageChange={(page) => setPageList(page)}
                        />
                      </div>
                     : null}
                </> :null}
                {user && user.user_types && user.user_types.includes('cfa') ? <>
                  {diffSample && diffSample ? 
                      <div className="card-body">
                        <Table
                          columns={column}
                          data={diffSample}
                          count={diffSample.length}
                          pageFalse
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageList(page)}
                        />
                      </div>
                     : null}
                </> :null}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                    {user &&
                      user.user_types &&
                      user.user_types.includes("fso") ? (
                        <>
                          {fso_submit_data && fso_submit_data ? (
                            <div className="card-body">
                              <Table
                                columns={columns}
                                data={fso_submit_data}
                                pageFalse
                                count={fso_submit_data.length}
                                countPerPage={countPerPage}
                                customStyles={customStyles}
                                handlePageChange={(page) => setPageAnalyse(page)}
                              />
                            </div>
                          ) :null}</>):null}
                          {user &&
                      user.user_types &&
                      user.user_types.includes("do") ? (
                        <>
                          {fso_submit_data && fso_submit_data ? (
                            <div className="card-body">
                              <Table
                                columns={columns}
                                data={fso_submit_data}
                                pageFalse
                                count={fso_submit_data.length}
                                countPerPage={countPerPage}
                                customStyles={customStyles}
                                handlePageChange={(page) => setPageAnalyse(page)}
                              />
                            </div>
                          ) :null}</>):null}
                           {user &&
                      user.user_types &&
                      user.user_types.includes("cfa") ? (
                        <>
                          {diffSample && diffSample ? (
                            <div className="card-body">
                              <Table
                                columns={column}
                                data={diffSample}
                                pageFalse
                                count={diffSample.length}
                                countPerPage={countPerPage}
                                customStyles={customStyles}
                                handlePageChange={(page) => setPageAnalyse(page)}
                              />
                            </div>
                          ) :null}</>):null}
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                    
                           {user &&
                      user.user_types &&
                      user.user_types.includes("cfa") ? (
                        <>
                          {diffSample && diffSample ? (
                            <div className="card-body">
                              <Table
                                columns={column}
                                data={diffSample}
                                pageFalse
                                count={diffSample.length}
                                countPerPage={countPerPage}
                                customStyles={customStyles}
                                handlePageChange={(page) => setPageAnalyse(page)}
                              />
                            </div>
                          ) :null}</>):null}
                           {user && user.user_types.includes("fso") && (
                    <>
                      {sample_data && sample_data ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={sample_data}
                            pageFalse
                            count={sample_data.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageAssign(page)}
                          />
                        </div>
                      ) : null}</>)}
              </div>
            </TabPane>
            <TabPane tabId="4">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                  
                           {user &&
                      user.user_types &&
                      user.user_types.includes("cfa") ? (
                        <>
                          {diffSample && diffSample ? (
                            <div className="card-body">
                              <Table
                                columns={column}
                                data={diffSample}
                                pageFalse
                                count={diffSample.length}
                                countPerPage={countPerPage}
                                customStyles={customStyles}
                                handlePageChange={(page) => setPageAnalyse(page)}
                              />
                            </div>
                          ) :null}</>):null}
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ongetSample: (data) => dispatch(getNonSampleStatus(data)),
  onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
  ongetSampe: (data) => dispatch(updateSample(data)),
  ongetSampelist: (data) => dispatch(updateSampleList(data)),
  ongetCivil: (data) => dispatch(updateCivil(data)),
  ongetCriminal: (data) => dispatch(updateCriminal(data)),
  ongetDLabs: (data) => dispatch(getLabDifferentReports(data)),
  onaddFbo: (data,id) => dispatch(addNSFboDetails(data,id)),
  ongetFso: (data) => dispatch(getFsoOfficers(data)),
  onEditFSOSampleReports: (data, record_id) => dispatch(editFSONonSampleReports(data, record_id)),


});

const mapStateToProps = (state) => ({
  user: state.home.user,
  districts: state.home.districts,
  complaints: state.home.complaints,
  assignComplaints: state.home.assignComplaints,
  closedComplaints: state.home.closedComplaints,
  unasignComplaints: state.home.unasignComplaints,
  waitingComplaints: state.home.waitingComplaints,
  foodIssues: state.home.foodIssues,
  fsoCategory: state.home.fsoCategory,
  assignfsoComplaints: state.home.assignfsoComplaints,
  sample_data: state.home.sample_data,
  list_sample: state.home.list_sample,
  fso_submit_data: state.home.fso_submit_data,
  req_sample: state.home.req_sample,
  lab_analyse_sample: state.home.lab_analyse_sample,
  resultObtained: state.home.resultObtained,
  civilResults: state.home.civilResults,
  criminalResults: state.home.criminalResults,
  overall_Sample:state.home.overall_Sample,
  fsoOfficers: state.home.fsoOfficers,

});

export default connect(mapStateToProps, mapDispatchToProps)(NSEntry);
