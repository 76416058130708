import React from "react";
import {
  getFilterData,
  getFsoOfficers,
  getoverall,
  getoverallWithout,
  userDirectory,
  getMasterSuccess,
  lineReportOpen,
  lineReportClosed,
  getDownloadfeedbackreport,

} from "../../components/reducers/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import Header from "../../components/Header";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { date } from "yup";
import Datepicker from "../../components/inputs/Datepicker";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextInput from "../../components/inputs/TextInput";
import moment from "moment";

const Feedbackreportlist = (props) => {
  const { t, i18n } = useTranslation();
  const {
    ongetOverall,
    ongetOverallWithout,
    traderTypes,
    ongetFso,
    foodIssues,
    districts,
    foodTypes,
    fsoOfficers,
    ongetfeedback,
    user,
  } = props;
  const [users, setUsers] = useState();
  const [errors, setErrors] = useState({});
  const [sho,setSho]=useState(false)
  const [Arr, setArr]=useState([])
  const [filt, setFilt] = useState();
  const [duplicateDistrict, setDuplicateDistrict] = useState([]);
  const [values, setValues] = useState({
    fromdate: new Date(),
    todate: new Date(),
    district: null,
  });
  const Status = [
    {id:'0', name:'Waiting to Allocate'},
    {id:'1', name:`Waiting for do's approval`},
    {id:'2',name:'Inprogress'},
    {id:'3',name:'Closed'},
    ]
  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
  };
  const TextChange=(id)=>{
    var i = id.replace(/_/g,' ')
    var text = i[0].toUpperCase() + i.substr(1);
    return text
  }
  const handleSubmit = async (event) => {
    const data = { ...values };
    const datas = {}
    datas.fromdate = moment(data.fromdate).format("YYYY-MM-DD");
    datas.todate = moment(data.todate).format("YYYY-MM-DD");
    console.log(data);
        const resp = await ongetfeedback(datas);
        if (resp && resp.response) {
          console.log(resp.response);
          setUsers(resp.response.data)
          let arr = resp.response.data[0]
          const str = Object.entries(arr).map(([key, value]) => `${key}`);
         let dat= str.map((i)=>{
            if(i!=='tableData')
            return{
            'title':TextChange(i),
            'field':`${i}`,
            filtering:true,
                cellStyle: {
                textAlign: "center",
                }, 
          }}).filter(i=>i)
          console.log(dat)
          setArr(dat)
        }
  };
  const getOfficer=async(id)=>{
    const data={district_id:id}
   const resp = await ongetFso(data)
   console.log('resp', resp,data)
  }
    useEffect(()=>{
      if(values.district){
        getOfficer(values.district)
      }
    },[values.district])
    useEffect(()=>{
      var arr = [...districts]
      arr.unshift({id:'all',name:'All'})
      setDuplicateDistrict(arr)
    },[districts&&districts])
  useEffect(() => {
    if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
      const userData = { ...values, district: user.district };
      setValues(userData);
  }
  
  if(user && user.user_types && user.user_types.includes("fso")){
    const userData = { ...values, district: user.district, fso: user.id };
    setValues(userData);
  }
    const getfs = async () => {
      await ongetFso();
    };
    getfs();
  }, []);

  const colDetails = [
    {
       title:"Name",
       field:'created_by' ,
       filtering:false,
       cellStyle: {
         textAlign: "center",
       },
     },
     {
       title:"Phone Number",
       field:'phone_number' ,
       filtering:false,
       cellStyle: {
         textAlign: "center",
       },
     },
     {
       title:"Feedback",
       field:'feedback' ,
       filtering:false,
       cellStyle: {
         textAlign: "center",
       },
     },
     {
        title:"Created On",
        field:'created_at' ,
        filtering:false,
        cellStyle: {
          textAlign: "center",
        },
      },
    
]

  return (
    <div>
      <div>
      <h3 style={{marginLeft:30, textDecoration:'underline'}}>User Feedback Report Details</h3>

        <div
          className="filter_container"
        >
          <div >
            <label  htmlFor="date">From Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`From Date`}
                name="fromdate"
                id="fromdate"
                value={values.fromdate}
                error={errors.fromdate}
                onChangeText={(text) => handleTextChange("fromdate", text)}
              />
            </div>
          </div>
          <div >
            <label htmlFor="date">To Date: </label>
            <div className="filter_input" >
              <Datepicker
                placeholder={`To Date`}
                name="todate"
                id="todate"
                value={values.todate}
                error={errors.todate}
                onChangeText={(text) => handleTextChange("todate", text)}
              />
            </div>
          </div>
         
          
          <div  style={{marginTop:'30px'}} className="form-group">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success ml-3"
            >
              View
            </button>
          </div>
        </div>
        {users ? (
          <div
            style={{ width: "90%", fontSize: "16px" }}
            className="table_top use_dir"
          >
            <MaterialTable
              columns={colDetails}
              data={users}
              options={{
                exportButton: {
                csv: true,
                pdf: false,
                },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",
                  zIndex:0
                },
              }}
              title="Feedback Report"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetOverall: (data) => dispatch(getoverall(data)),
  ongetfeedback: (data) => dispatch(getDownloadfeedbackreport(data)),
  ongetOverallWithout: (data) => dispatch(getoverallWithout(data)),
  ongetFso: (data) => dispatch(getFsoOfficers(data)),
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  traderTypes: state.home.traderTypes,
  foodIssues: state.home.foodIssues,
  foodTypes: state.home.foodTypes,
  fsoOfficers: state.home.fsoOfficers,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedbackreportlist);
