import React, { useState } from "react";

const PasswordInput = (props) => {
  const [icon, setIcon] = useState("fa fa-eye-slash");
  const [hidePassword, setHidePassword] = useState(true);

  const errorMessage = props.error ? (
    <span className="error-message">{props.error}</span>
  ) : (
    <span className="error-message"></span>
  );

  const changeIcon = () => {
    const newIcon = icon === "fa fa-eye" ? "fa fa-eye-slash" : "fa fa-eye";
    setIcon(newIcon);
    setHidePassword(!hidePassword);
  };

  return (
    <>
      <div className="password-input">
        <input
          className="form-control form-control-user"
          placeholder={props.placeholder}
          type={hidePassword ? "password" : props.type}
          autoComplete="off"
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
        <i
          onClick={() => changeIcon()}
          className={`${icon} ${
            props.appIcon ? "password-icon-app" : "password-icon"
          }`}
        ></i>
      </div>
      {errorMessage}
    </>
  );
};

export default PasswordInput;
