import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as d3 from "d3";
import {nest} from "d3-collection"

const FoodIssueChart = ({ data }) => {
  console.log(data)
  const [keys, setKeys] = useState([]);
  const[show, setShow]=useState(false);
  const foodIssuePieChart = useRef();
  useEffect(() => {
      if (data) {
        data = nest()
          .key(function (d) {
            return d.issue_of_food__name;
          })
          .rollup(function (v) {
            return d3.sum(v, function (d) {
              return d.count;
            });
          })
          .entries(data)
          .sort(function (a, b) {
            return d3.descending(a.value, b.value);
          })
        .slice(0, 10)
        const map1 = data.map((x) => x.key);
        setKeys(map1);
      }

    // eslint-disable-next-line
  },[data]);
  useEffect(()=>{
    setShow(false)
    setTimeout(()=>{
      setShow(true)
    },1000)
  },[data])


  useEffect(() => {
    // Get positions for each data object
    const piedata = d3.pie().value((d) => d.value)(data);
    // Define arcs for graphing
    const arc = d3.arc().innerRadius(0).outerRadius(75);
    const colors = d3
      .scaleOrdinal()
      .range(
        // d3
        //   .quantize((t) => d3.interpolateCool(t * 0.8 + 0.1), data.length)
        //   .reverse()
        ['#f0c4c4','#f0ebc4','#cbf0c4', '#c4ecf0','#c4c6f0', '#edc4f0','#74d094', '#74b9d0', '#9274d0', '#e79dc3']

      );
    // Define the size and position of svg
    const svg = d3
      .select(foodIssuePieChart.current)
      .attr("width", 300)
      .attr("height", 230)
      .append("g")
      .attr("transform", "translate(75, 95)");
    // Draw pie
    svg
      .append("g")
      .selectAll("path")
      .data(piedata)
      .join("path")
      .attr("d", arc)
      .attr("fill", (d, i) => colors(i))
      .attr("stroke", "white")
      .on("mouseover", function (e, d) {
        d3.select("#foodissue_tip")
          .style("left", e.pageX -700 + "px")
          .style("top", e.pageY -400 + "px")
          .style("opacity", 1)
          .select("#value")
          .text(d.data.value);
        d3.select("#foodissue_tip")
          .select("#label")
          .text(d.data.key);
      })
      .on("mouseout", function () {
        // Hide the tooltip
        d3.select("#foodissue_tip").style("opacity", 0);
      });
    var SVG = d3.select("#foodissue_pie");
    // Usually you have a color scale in your chart already
    var color = d3
      .scaleOrdinal()
      .domain(keys)
      .range(
        // d3
        //   .quantize((t) => d3.interpolateCool(t * 0.8 + 0.1), data.length)
        //   .reverse()
        ['#606060']
      );
    // Add one dot in the legend for each name.
    var size = 10;
    SVG.selectAll("mydots")
      .data(keys)
      .enter()
      .append("rect")
      .attr("x", 170)
      .attr("y", function (d, i) {
        return 30 + i * (size + 5);
      }) // 100 is where the first dot appears. 25 is the distance between dots
      .attr("width", size)
      .attr("height", size)
      .style("fill",
      //  function (d) {
      //   return color(d);
      // }
      d3
      .scaleOrdinal()
      .range(
        ['#f0c4c4','#f0ebc4','#cbf0c4', '#c4ecf0','#c4c6f0', '#edc4f0','#74d094', '#74b9d0', '#9274d0', '#e79dc3']
      )
      );
    // Add one dot in the legend for each name.
    SVG.selectAll("mylabels")
      .data(keys)
      .enter()
      .append("text")
      .attr("x", 170 + size * 1.5)
      .attr("y", function (d, i) {
        return 30 + i * (size + 5) + size / 2;
      }) // 100 is where the first dot appears. 25 is the distance between dots
      .style("fill", 
      function (d) {
        return color(d);
      }
      )
      .text(function (d) {
        return d;
      })
      .attr("text-anchor", "left")
      .style("alignment-baseline", "right");
  });
  return (
   <div className="card">
      <div className="card-body" id="foodIssuePieChart">
        <h4 className="heading">Complaints by Food Issues</h4>
        <div id="foodissue_tip" className="hidden">
          <p>
            <span id="label">
              <strong> test</strong>
            </span>
          </p>
          <p>
            <span id="value">100</span>
          </p>
        </div>
      { data  ?  <svg id="foodissue_pie" ref={foodIssuePieChart}></svg>:
      <>
      <div style={{marginTop:90, marginBottom:90}}>No Data Available</div>
      </>}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({});
const mapStateToProps = (state) => ({
  dashboard: state.home.dashboard,
});
export default connect(mapStateToProps, mapDispatchToProps)(FoodIssueChart);