import {
  LOGIN_SUCCESS,
  GET_MASTER_DATA,
  LOGIN_FAILURE,
  GET_COMPLAINTS,
  GET_COMMENTS,
  GET_BANNERS,
  GET_NOTIFICATIONS,
  UPDATE_LANGUAGE,
  GET_FAQ_CATEGORY,
  GET_FAQS,
  GET_BLOGS,
  GET_INSPECTION,
  GET_ASSIGN_COMPLAINTS,
  GET_UNASSIGN_COMPLAINTS,
  GET_CLOSED_COMPLAINTS,
  GET_WAITING_COMPLAINTS,
  GET_ASSIGN_FSO_COMPLAINTS,
  GET_FSO_CATEGORY,
  GET_FSO_OFFICERS,
  GET_USERS,
  GET_DO_CONTACTS,
  SET_LOADING,
  GET_DASHBOARD,
  GET_WARDS,
  GET_WARDS_CATEGORY,
  GET_DISTRICT,
  CHANG_SAMPLE,
  CHAN_SAMPLE,
  CREATE_SAMPLE,
  GET_USERS_VIEW,
  ANALYSE_SAMPLE,
  REQ_SAMPLE,
  RES_OBTAINED,
  CIVIL_CASE,
  CRIMINAL_CASE,
  ALL_SAMPLE,
} from './constants';

const INITIAL_STATE = {
  user: null,
  users: null,
  banners: null,
  notifications: null,
  faqs: null,
  faqCategories: null,
  blogs: null,
  error: null,
  events: null,
  complaints: null,
  inspection: null,
  comments: null,
  traderTypes: null,
  foodTypes: null,
  foodConditions: null,
  foodIssues: null,
  districts: null,
  unasignComplaints: null,
  waitingComplaints: null,
  closedComplaints: null,
  assignComplaints: null,
  assignfsoComplaints: null,
  fsoOfficers: null,
  fsoCategory: null,
  doContacts: null,
  loading: false,
  dashboard: null,
  districtwards: null,
  wardsCategory:null,
  getdistrict:null,
  sample_data:null,
  list_sample:null,
  fso_submit_data:null,
  lab_analyse_sample:[],
  req_sample:[],
  resultObtained:null,
  civilResults:null,
  criminalResults:null,
  overall_Sample:null,
};

const reducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case GET_MASTER_DATA:
      return {
        ...state,
        traderTypes: action.payload.trader_types ? action.payload.trader_types : null,
        foodTypes: action.payload.food_types ? action.payload.food_types : null,
        foodConditions: action.payload.food_conditions ? action.payload.food_conditions : null,
        foodIssues: action.payload.food_issues ? action.payload.food_issues : null,
        districts: action.payload.districts ? action.payload.districts : null,
        wards: action.payload.wards ? action.payload.wards : null,
        wardsCategory: action.payload.user_ward ? action.payload.user_ward : null,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
      case GET_USERS_VIEW:
        return {
          ...state,
          users: action.payload,
        };
    case GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
      };
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case GET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case GET_INSPECTION:
      return {
        ...state,
        inspection: action.payload,
      };
    case GET_BANNERS:
      return {
        ...state,
        banners: action.payload,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_FAQS:
      return {
        ...state,
        faqs: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_FAQ_CATEGORY:
      return {
        ...state,
        faqCategories: action.payload,
      };
    case UPDATE_LANGUAGE:
      return {
        ...state,
        user: {
          ...state.user,
          language: action.payload
        },
      };
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
      };
    case GET_ASSIGN_COMPLAINTS:
      return {
        ...state,
        assignComplaints: action.payload,
      };
    case GET_CLOSED_COMPLAINTS:
      return {
        ...state,
        closedComplaints: action.payload,
      };
    case GET_UNASSIGN_COMPLAINTS:
      return {
        ...state,
        unasignComplaints: action.payload,
      };
    case GET_WAITING_COMPLAINTS:
      return {
        ...state,
        waitingComplaints: action.payload,
      };
    case GET_ASSIGN_FSO_COMPLAINTS:
      return {
        ...state,
        assignfsoComplaints: action.payload,
      };
    case GET_FSO_OFFICERS:
      return {
        ...state,
        fsoOfficers: action.payload,
      };
    case GET_FSO_CATEGORY:
      return {
        ...state,
        fsoCategory: action.payload,
      };
      case GET_WARDS:
      return {
        ...state,
        districtwards: action.payload,
      };
      case GET_DISTRICT:
        return {
          ...state,
          getdistrict: action.payload,
        };
    case GET_WARDS_CATEGORY:
      return {
        ...state,
        wardsCategory: action.payload,
      };
    case GET_DO_CONTACTS:
      return {
        ...state,
        doContacts: action.payload,
      };
      case CHANG_SAMPLE:
        return {
          ...state,
          sample_data: action.payload,
        };
        case CHAN_SAMPLE:
        return {
          ...state,
          list_sample: action.payload,
        };
        case CREATE_SAMPLE:
        return {
          ...state,
          fso_submit_data:action.payload,
        };
        case ANALYSE_SAMPLE:
          return {
            ...state,
            lab_analyse_sample:action.payload,
          };
          case REQ_SAMPLE:
          return {
            ...state,
            req_sample:action.payload,
          };
          case RES_OBTAINED:
            return {
              ...state,
              resultObtained:action.payload,
            };
            case CIVIL_CASE:
            return {
              ...state,
              civilResults:action.payload,
            };
            case CRIMINAL_CASE:
              return {
                ...state,
                criminalResults:action.payload,
              };
              case ALL_SAMPLE:
              return {
                ...state,
                overall_Sample:action.payload,
              };
    default:
      return state
  }
};

export default reducers;