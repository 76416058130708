import React from "react";
import { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const Reports = (props) => {
const[activeTab,setActiveTab]=useState('2')
const { t, i18n } = useTranslation();
const{user}=props;


const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


    const otherReports=(user && user.user_types && user.user_types.includes("cfa"))?[
        {
            label: "Sample Analysed Line Report",
            name: "/app/finance-credit-report",
        },
        {
            label: "Sample Analysed Summary Report",
            name: "/app/finance-credit-report",
        },
        {
            label: "Sample From Different Sources Summary Report",
            name: "/app/finance-credit-report",
        },
        {
            label: "Sample From Different Sources Line Report",
            name: "/app/finance-credit-report",
        },
    ]:(user && user.user_types && user.user_types.includes("labapp"))?[
        {
            label: "Sample Analysed Line Report",
            name: "/app/report-summary-civilall/",
        },
        
    ]:( (user && user.user_types && user.user_types.includes("oneapp"))||
    (user && user.user_types && user.user_types.includes("twoapp")))?[
        {
            label: "Sample Analysed Line Report",
            name: "/app/report-summary-criminalall/",
        },
    ]:[]

    const financereports =(user && user.user_types && user.user_types.includes("ho"))|| 
    (user && user.user_types && user.user_types.includes("dd"))||
    (user && user.user_types && user.user_types.includes("commissioner"))||
    (user && user.user_types && user.user_types.includes("ac")) ?  [
        {
            label: "Fund Credits Entry Report",
            name: "/app/finance-credit-report",
        },
        {
            label: "Fund Distribute Report",
            name: "/app/finance-distribute-report",
        },
        {
            label: "Fund Spended Report",
            name: "/app/finance-spend-report",
        },
        {
            label: "Fund Return Report",
            name: "/app/finance-return-report",
        },
         
    ]:[
        {
            label: "Fund Spended Report",
            name: "/app/finance-spend-report",
        },
        {
            label: "Fund Return Report",
            name: "/app/finance-return-report",
        },
    ]
    const financelist =    (user && user.user_types && user.user_types.includes("ho"))|| 
    (user && user.user_types && user.user_types.includes("dd"))||
    (user && user.user_types && user.user_types.includes("commissioner"))||
    (user && user.user_types && user.user_types.includes("ac")) ? [
     
           { label: "Fund Credits Entry List",
            name: "/app/finance-credit-report-list",
        },
        {
            label: "Fund Distribute Repor List",
            name: "/app/finance-distribute-report-list",
        },
        {
            label: "Fund Spended Report List",
            name: "/app/finance-spend-report-list",
        },
        {
            label: "Fund Return Report List",
            name: "/app/finance-return-report-list",
        },
         
    ]:[
        {
            label: "Fund Spended Report List",
            name: "/app/finance-spend-report-list",
        },
        {
            label: "Fund Return Report List",
            name: "/app/finance-return-report-list",
        },
    ]
    const reportNames = [
        {
            label: "FBO Enumeration Report",
            name: "/app/reports/fbo-report",
        },
        {
            label: "Licencing Report",
            name: "/app/reports/licencing-reports/",
        },
        {
            label: "Registration Report",
            name: "/app/reports/registration-reports",
        },
        {
            label: "FSO EPO Report",
            name: "/app/reports/fso-EPO-reports",
        },
        {
            label: "DO EPO Report",
            name: "/app/reports/do-EPO-reports",
        },
        {
            label: "Commissioner EPO Report",
            name: "/app/reports/comissioner-EPO-reports",
        },
    
        // {
        //     label: "Commisionerate EPO Report",
        //     name: "/app/reports/comissionerate-EPO-reports",
        // },
        
       
        // {
        //     label: "IEC Report",
        //     name: "/app/reports/iec-report",
        // },
        {
            label: "Slaughter House Report",
            name: "/app/reports/slaughter-reports",
        },
        {
            label: "Ripening of Fruits with Carbide Stone/Ethyline Gas Report",
            name: "/app/reports/ripening-mangoes-reports",
        },
        // {
        //     label: "FSSA-ACT Samples Report",
        //     name: "/app/reports/fssaact-reports",
        // },
      
       
       
        {
            label: "Packaged Drinking Water Report",
            name: "/app/reports/pdw-reports",
        },
      
        {
            label: "Hotels and Restaurants Inspection Report",
            name: "/app/reports/hotels-and-restaurants-reports",
        },
       
        // {
        //     label: "Special Programme Report",
        //     name: "/app/reports/special-program-report",
        // },
        {
            label: "School/College Inspection Report",
            name: "/app/reports/school-reports",
        },
        {
            label: "Tour Journal Report",
            name: "/app/reports/tourjournal-reports",
        },
        {
            label: "Foscoris Digital Inspection Report",
            name: "/app/reports/foscorisdigital-reports",
        },
        {
            label: "Compound Penalty Report",
            name: "/app/reports/compound-penalty-reports",
        },
        {
            label: "TN Foodsafety and Drug Administration Dept Daily Report",
            name: "/app/reports/tndaily-reports",
        },
        {
            label: "Enumeration Data Report",
            name: "/app/reports/enumeration-data-reports",
        },
        {
            label: "Information Education and communication Report",
            name: "/app/reports/information-education-reports",
        },
        {
            label: "Notice Issued Report",
            name: "/app/reports/notice-issued-reports",
        },
        // {
        //     label: "L and R Enumeration of FBO's Report",
        //     name: "/app/reports/enumeration-of-fbo-reports",
        // },
        {
            label: "Eat Right Training Toolkit Report",
            name: "/app/reports/eatright-training-reports/",  
        },
        {
            label: "Panmasala and Gutkha Action Taken Report",
            name: "/app/reports/panmasala-reports",
        },
        // {
        //     label: "Criminal Cases (Appeal Cases) Report",
        //     name: "/app/reports/high-courtcase-reports",
        // },
        // {
        //     label: "Civil Cases Report",
        //     name: "/app/reports/adjudication-case-reports",
        // },
        // {
        //     label: "Adjudication Appeal Report",
        //     name: "/app/reports/adjudication-appeal-reports",
        // },
        // {
        //     label: "Surveillance Sample Lifted Report",
        //     name: "/app/reports/ssc-reports",
        // },
        {
            label: "Advance Tour and Programme Report",
            name: "/app/reports/tourprogram-reports",
        },
        // {
        //     label: "Monthly License Report",
        //     name: "/app/reports/licensemonthly-reports",
        // },
       
        {
            label: "Tasmac shops and Bar Inspection Report",
            name: "/app/reports/tasmac-reports",
        },
        {
            label: "DLAC Report",
            name: "/app/reports/dlac-reports",
        },
     
        {
            label: "Food Fortification Report",
            name: "/app/reports/food-fortification-reports",
        },
       
        {
            label: "Food Product Seizure Report",
            name: "/app/reports/seizure-reports",
        },
       
        {
            label: "Vehicle Inspection Report",
            name: "/app/reports/vehicle-inspection-reports",
        },
        // {
        //     label: "Complaints Report",
        //     name: "/app/reports/complaint-reports",
        // },
        // {
        //     label: "Expected inspection Report",
        //     name: "/app/reports/expected-inspection-reports",
        // },
        // {
        //     label: "Food Wrapped in Newspaper inspection Report",
        //     name: "/app/reports/food-in-newspaper-reports",
        // },
        
        {
            label: "Special Drive Of L and R Report",
            name: "/app/reports/special-drive-reports/",
        } ,  
        {
            label: "Hygine Rating Report",
            name: "/app/reports/hygine-rating-reports/",
        },
        {
            label: "FOSTAC  Training Report",
            name: "/app/reports/fostac-training-reports/",
        },   
        {
            label: "Clean Street Food Hub Certification Report",
            name: "/app/reports/clean-food-reports/",  
        },
        {
            label: "Clean Fruits/Vegetables Certification Report",
            name: "/app/reports/market-reports/",  
        },
        {
            label: "Blissful Hygine Offering to God Certification",
            name: "/app/reports/worship-place-reports/",  
        },
      
        {
            label: "Eat Right School Certification Report",
            name: "/app/reports/eatright-school-reports/",  
        },
        {
            label: "Eat Right Campus Certification Report",
            name: "/app/reports/eatright-campus-reports/",  
        },
        {
            label: "RUCO Certification Report",
            name: "/app/reports/ruco-certification-reports/",  
        },
        {
            label: "RUCO Collection And Conversion Of Biodiesel Report",
            name: "/app/reports/ruco-biodiesel-reports/",  
        },
        {
            label: "UCO Aggregators Data Report",
            name: "/app/reports/uco-reports/",  
        },
        {
            label: "Save Food/Share Food Report",
            name: "/app/reports/food-waste-reports/",  
        },
        {
            label: "No Food Waste Agency Report",
            name: "/app/reports/food-collection-reports/",  
        },
        {
            label: "ERC Portal Uploaded Report",
            name: "/app/reports/erc-portal-reports/",  
        },
        {
            label: "ERC Activity Report",
            name: "/app/reports/number-reports/",  
        }                    
    ]
    const samplereports=[
        {label:'Overall Sample Details Report',
        name:'/app/display-reports/sample-filter-list/'
        },
        {label:'Overall Non-Sample Details Report',
        name:'/app/display-reports/sample-action-non-list/'
        },
    ]
    const samplesreports=[
        // {label:'Sample Collection Report',
        // name:'/app/reports/summary-sample-collected/'
        // },
        // {label:'Sample Analysed Report',
        // name:'/app/reports/summary-sample-analysed/'
        // },
        // {label:'Prosecution Sanction Report',
        // name:'/app/reports/summary-prosecution/'
        // },
        // {label:'Act Samples Report',
        // name:'/app/reports/summary-act/'
        // },
        {label:'Sample Details Report',
        name:'/app/display-reports/sampleline-filter-list/'
        },
       
        {label:'Non-Sample Details Report',
        name:'/app/display-reports/samplenonline-filter-list/'
        },
    ]
    const Casereports=[
        
        // {label:'Case details report of Enforcement Samples',
        // name:'/app/reports/summary-case-enforcement/'
        // }, 
        {label:'Case Details Report',
        name:'/app/display-reports/caseline-filter-list/'
        },
        {label:'Direct Case Details Report',
        name:'/app/display-reports/casedirectline-filter-list/'
        },
        
    ]
    const reportNames2 =user && user.user_types && user.user_types.includes("ho")? [
        {
            label: "Master Data Report",
            name: "/app/reports/complaint-list/",
        },
        {
            label: "Over-All Report",
            name: "/app/reports/complaint-action/",
        },
        {
            label: "Fine Details Report ",
            name: "/app/reports/complaint-status/",
        },
    
        {
            label: "Seizure Details Report",
            name: "/app/reports/complaint-seized/",
        },
        {
            label: "Notice Issued Details Report ",
            name: "/app/reports/complaint-notice/",
        },
        {
            label: "Sample Lifted Details Report",
            name: "/app/reports/complaint-sample/",
        },
        {
            label: "Closed Complaint Report",
            name: "/app/reports/complaint-closed/",
        },
        {
            label: "Open complaint detail numbering report ",
            name: "/app/reports/line-open-list/",
        },
        {
            label: "Closed complaint detail numbering report",
            name: "/app/reports/line-closed-list/",
        }, 
       {
            label: "Contact us report",
            name: "/app/reports/contact-us-reports-list/",
        },
        {
            label: "Users Feedback report",
            name: "/app/reports/feedback-reports-list/",
        },
        {
            label: "Subribed to blogs report",
            name: "/app/reports/blog-reports-list/",
        }
    ]:[
        {
            label: "Master Data Report",
            name: "/app/reports/complaint-list/",
        },
        {
            label: "Over-All Report",
            name: "/app/reports/complaint-action/",
        },
        {
            label: "Fine Details Report ",
            name: "/app/reports/complaint-status/",
        },
    
        {
            label: "Seizure Details Report",
            name: "/app/reports/complaint-seized/",
        },
        {
            label: "Notice Issued Details Report ",
            name: "/app/reports/complaint-notice/",
        },
        {
            label: "Sample Lifted Details Report",
            name: "/app/reports/complaint-sample/",
        },
        {
            label: "Closed Complaint Report",
            name: "/app/reports/complaint-closed/",
        },
        {
            label: "Open complaint detail numbering report ",
            name: "/app/reports/line-open-list/",
        },
        {
            label: "Closed complaint detail numbering report",
            name: "/app/reports/line-closed-list/",
        },
    ]
    const Summarygeneral = [
        {label:'FBO Enumeration Summary Report',name:'/app/reports/summary-fbo/'},
        {label:'Licencing Summary Report',name:'/app/reports/summary-licnese/'},
        {label:'Registration Summary Report',name:'/app/reports/summary-registration/'},
        {label:'FSO EPO Summary Report',name:'/app/reports/summary-fso-epo/'},
        {label:'DO EPO Summary Report',name:'/app/reports/summary-do-epo/'},
        {label:'Commissioner EPO Summary Report',name:'/app/reports/summary-commissioner-epo/'},
        {label:'Slaughter House Summary Report',name:'/app/reports/summary-slaughter/'},
        {label:'Ripening of Fruits with Carbide Stone/Ethyline Gas Summary Report',name:'/app/reports/summary-fruits/'},
        {label:'Packaged Drinking Water Summary Report',name:'/app/reports/summary-pdw/'},
        {label:'Hotels and Restaurants Inspection Summary Report',name:'/app/reports/summary-hotel/'},
        {label:'School/College Inspection Summary Report',name:'/app/reports/summary-school/'},
        {label:'Tour Journal Summary Report',name:'/app/reports/summary-tourjournal/'},
        {label:'Foscoris Digital Inspection Summary Report',name:'/app/reports/summary-foscoris/'},
        {label:'Compound Penalty Summary Report',name:'/app/reports/summary-compound/'},
        {label:'TN Foodsafety and Drug Administration Dept Daily Summary Report',name:'/app/reports/summary-daily/'},
        {label:'Enumeration Data Summary Report',name:'/app/reports/summary-enumeration/'},
        {label:'Information Education and communication Summary Report',name:'/app/reports/summary-iec/'},
        {label:'Notice Issued Summary Report',name:'/app/reports/summary-notice/'},
        {label:'Eat Right Training Toolkit Summary Report',name:'/app/reports/summary-toolkit/'},
        {label:'Panmasala and Gutkha Action Taken Summary Report',name:'/app/reports/summary-panmasala/'},
        {label:'Advance Tour and Programme Summary Report',name:'/app/reports/summary-advance-tour/'},
        {label:'Tasmac shops and Bar Inspection Summary Report',name:'/app/reports/summary-tasmac/'},
        {label:'DLAC Summary Report',name:'/app/reports/summary-dlac/'},
        {label:'Food Fortification Summary Report',name:'/app/reports/summary-fortification/'},
        {label:'Food Product Seizure Summary Report',name:'/app/reports/summary-seizure/'},
        {label:'Vehicle Inspection Summary Report',name:'/app/reports/summary-vehicle/'},
        {label:'Special Drive Of L and R Summary Report',name:'/app/reports/summary-specialdrive/'},
        {label:'Hygine Rating Summary Report',name:'/app/reports/summary-hygine/'},
        {label:'FOSTAC Training Summary Report',name:'/app/reports/summary-fostac/'},
        {label:'Clean Street Food Hub Certification Summary Report',name:'/app/reports/summary-food/'},
        {label:'Clean Fruits/Vegetables Certification Summary Report',name:'/app/reports/summary-vegetable/'},
        {label:'Blissful Hygine Offering to God Summary Report',name:'/app/reports/summary-bhog/'},
        {label:'Eat Right School Certification Summary Report',name:'/app/reports/summary-erc-school/'},
        {label:'Eat Right Campus Certification Summary Report',name:'/app/reports/summary-campus/'},
        {label:'RUCO Certification Summary Report',name:'/app/reports/summary-ruco/'},
        {label:'RUCO Collection And Conversion Of Biodiesel Summary Report',name:'/app/reports/summary-biodiesel/'},
        {label:'UCO Aggregators Data Summary Report',name:'/app/reports/summary-uco/'},
        {label:'Save Food/Share Food Summary Report',name:'/app/reports/summary-sfsf/'},
        {label:'No Food Waste Agency Summary Report',name:'/app/reports/summary-nfw/'},
        {label:'ERC Portal Uploaded Summary Report',name:'/app/reports/summary-ercportal/'},
        {label:'ERC Activity Summary Report',name:'/app/reports/summary-ercactivity/'},
    ]
    const summaryreport =[
        {label:'Food Issue Summary Report',name:'/app/reports/summary-issue/'},
        {label:'Tradertypes Summary Report',name:'/app/reports/summary-trader/'},
        {label:'Complaintclosure Summary Report',name:'/app/reports/summary-complaints/'},
        // {label:'Sample Summary Report',name:'/app/reports/summary-samples/'},
        {label:'Direct Sample Summary Report',name:'/app/reports/summary-non-sample/'},
        // {label:'Fund Summary Report',name:'/app/reports/summary-fund/'},
        {label:'Food Seizure Summary Report',name:'/app/reports/summary-seizure/'},
        {label:'Fine Summary Report',name:'/app/reports/summary-fine/'},
        {label:'Total Fine Summary Report',name:'/app/reports/summary-fine-total/'},
        {label:'Notice issued Summary Report',name:'/app/reports/summary-notice-issued/'},
        {label:'Sample lifted  Summary Report',name:'/app/reports/summary-sample-lifted/'},

    ]

    const reportNames1 = [
        {
            label: "FBO Enumeration Report List",
            name: "/app/display-reports/fbo-list",
        },
        {
            label: "Licencing Report List",
            name: "/app/display-reports/licensing-list",
        },
        {
            label: "Registration Report List",
            name: "/app/display-reports/registration-list",
        },
        {
            label: "FSO EPO Report List",
            name: "/app/display-reports/fso-EPO-list",
        },
        {
            label: "DO EPO Report List",
            name: "/app/display-reports/do-EPO-list",
        },
        {
            label: "Commissioner EPO Report List",
            name: "/app/display-reports/comissioner-EPO-list",
        },
        // {
        //     label: "Commissionerate EPO Report List",
        //     name: "/app/display-reports/comissionerate-EPO-list",
        // },
       
        // {
        //     label: "IEC Report List",
        //     name: "/app/display-reports/iec-list",
        // },
       
        {
            label: "Slaughter House Report List",
            name: "/app/display-reports/slaughter-list",
        },
        {
            label: "Ripening of Fruits with Carbide Stone/Gas Report List",
            name: "/app/display-reports/ripening-mangoes-list",
        },
        // {
        //     label: "FSSA Act Sample List",
        //     name: "/app/display-reports/fssaact-list",
        // },
        
       
        {
            label: "Packaged Drinking Water Report List",
            name: "/app/display-reports/pdw-list",
        },
        {
            label: "Hotels and Restaurants Inspection Report List",
            name: "/app/display-reports/hotels-and-restaurants-list",
        },
     
        // {
        //     label: "Special Programme Report List",
        //     name: "/app/display-reports/special-program-list",
        // },       
        {
            label: "School/College Inspection Report List",
            name: "/app/display-reports/school-list",
        },
        {
            label: "Tour Journal Report List",
            name: "/app/display-reports/tourjournal-list",
        },
        {
            label: "Foscoris Digital Inspection Report List",
            name: "/app/display-reports/foscorisdigital-list",
        },
        {
            label: "Compound Penalty Report List",
            name: "/app/display-reports/compound-penalty-list",
        },
        {
            label: "TN Foodsafety and Drug Administration Dept Daily Report List",
            name: "/app/display-reports/tndaily-list",
        },
        {
            label: "Enumeration Data Report List",
            name: "/app/display-reports/enumeration-data-list",
        },
        {
            label: "Information Education and communication  Report List",
            name: "/app/display-reports/information-education-list",
        },
        {
            label: "Notice Issued Report List",
            name: "/app/display-reports/notice-issued-list",
        },
        // {
        //     label: "L and R Enumeration of FBO's Report List",
        //     name: "/app/display-reports/enumeration-of-fbo-list",
        // },
        {
            label: "Eat Right Training Toolkit Report List",
            name: "/app/display-reports/eatright-training-list/",  
        },
        {
            label: "Panmasala and Gutkha Action Taken Report List",
            name: "/app/display-reports/panmasala-list",
        },
        // {
        //     label: "Criminal Cases (Appeal Cases) Report List",
        //     name: "/app/display-reports/high-courtcase-list",
        // },
        // {
        //     label: "Civil Case Report List",
        //     name: "/app/display-reports/adjudication-case-list",
        // },
        // {
        //     label: "Adjudication Appeal Report List",
        //     name: "/app/display-reports/adjudication-appeal-list",
        // },
        // {
        //     label: "Surveillance Sample Collected Report List",
        //     name: "/app/display-reports/ssc-list",
        // },
        {
            label: "Advance Tour and Programme Report List",
            name: "/app/display-reports/tourprogram-list",
        },
       
        {
            label: "Tasmac shops and Bar Inspection Report List",
            name: "/app/display-reports/tasmac-list",
        },
        {
            label: "DLAC Report List",
            name: "/app/display-reports/dlac-list",
        },
       
        {
            label: "Food Fortification Report List",
            name: "/app/display-reports/food-fortification-list",
        },
        
        {
            label: "Food Product Seizure Report List",
            name: "/app/display-reports/seizure-list",
        },
     
        {
            label: "Vehicle Inspection Report List",
            name: "/app/display-reports/vehicle-inspection-list",
        },
        // {
        //     label: "FSSA-ACT Samples Report List",
        //     name: "/app/display-reports/fssaact-list",
        // },
       
        {
            label: "Special Drive Of L and R Report List",
            name: "/app/display-reports/special-drive-list",
        },
        {
            label: "Hygine Rating Report List",
            name: "/app/display-reports/hygine-rating-list/",
        },
        {
            label: "FoSTaC Training Report List",
            name: "/app/display-reports/fostac-training-list/",
        },   
        {
            label: "Clean Street Food Hub Certification Report List",
            name: "/app/display-reports/clean-food-list/",  
        },
        {
            label: "Clean Fruits/Vegetables Certification Report List ",
            name: "/app/display-reports/market-list/",  
        },
        {
            label: "Blissful Hygine Offering to God Certification Report List",
            name: "/app/display-reports/worship-place-list/",  
        },
        
        {
            label: "Eat Right School Certification Report List",
            name: "/app/display-reports/eatright-school-list/",  
        },
        {
            label: "Eat Right Campus Certification Report List",
            name: "/app/display-reports/eatright-campus-list/",  
        },
        {
            label: "RUCO Certification Report List",
            name: "/app/display-reports/ruco-certification-list/",  
        },
        {
            label: "RUCO Collection And Conversion Of Biodiesel Report List",
            name: "/app/display-reports/ruco-biodiesel-list/",  
        },
        {
            label: "UCO Aggregators Data Report List",
            name: "/app/display-reports/uco-list/",  
        },
        {
            label: "Save Food/Share Food Report List",
            name: "/app/display-reports/food-waste-list/",  
        },
        {
            label: "No Food Waste Agency Report List",
            name: "/app/display-reports/food-collection-list/",  
        },
        {
            label: "ERC Portal Uploaded Report List",
            name: "/app/display-reports/erc-portal-list/",  
        },
        {
            label: "ERC Activity Report List",
            name: "/app/display-reports/number-list/",  
        } ,               
       
        // {
        //     label: "Monthly Registration Report List",
        //     name: "/app/display-reports/regmonthly-list",
        // },
 
        // {
        //     label: "Monthly License Report List",
        //     name: "/app/display-reports/licensemonthly-list",
        // },

        // {
        //     label: "FOSTAC  Training Report List",
        //     name: "/app/display-reports/fostac-training-list",
        // },
        
        // {
        //     label: "Expected inspection Report List",
        //     name: "/app/display-reports/expected-inspection-list",
        // },
        // {
        //     label: "Food Wrapped in Newspaper inspection Report List",
        //     name: "/app/display-reports/food-in-newspaper-list",
        // },
             
    ]
    const renderCard = (card, index) => {
        return (
            <div className={index==0?"pt-3":''} key={index}>
                    <div>
                        <li style={{color:'#0096FF'}}> 
                            <a style={{fontSize:'16px',color:'#0096FF'}} href={card.name}>{card.label}</a>
                        </li>
                </div>
            </div>
        )
    }

    return (<>
     <div
          className="mt-5 mb-5 ml-3"
          style={{ fontSize: i18n.language === "ta" ? 15 : null }}
        >
             <Nav tabs>
              {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                 {(user && user.user_types && user.user_types.includes("ho"))|| 
                    (user && user.user_types && user.user_types.includes("fso"))|| 
                    (user && user.user_types && user.user_types.includes("so"))|| 
                    (user && user.user_types && user.user_types.includes("do"))||
                    (user && user.user_types && user.user_types.includes("AO"))||
                    (user && user.user_types && user.user_types.includes("dd"))||
                    (user && user.user_types && user.user_types.includes("oneapp"))||
                    (user && user.user_types && user.user_types.includes("twoapp"))||
                    (user && user.user_types && user.user_types.includes("labapp"))||
                    (user && user.user_types && user.user_types.includes("commissioner"))||
                    (user && user.user_types && user.user_types.includes("ac"))?  'General Reports':'Report Section'}
                  </NavLink>
                </NavItem> */}
                
                   {(user && user.user_types && user.user_types.includes("ho"))|| 
                    (user && user.user_types && user.user_types.includes("fso"))|| 
                    (user && user.user_types && user.user_types.includes("so"))|| 
                    (user && user.user_types && user.user_types.includes("do"))||
                    // (user && user.user_types && user.user_types.includes("AO"))||
                    // (user && user.user_types && user.user_types.includes("dd"))||
                    // (user && user.user_types && user.user_types.includes("oneapp"))||
                    // (user && user.user_types && user.user_types.includes("twoapp"))||
                    // (user && user.user_types && user.user_types.includes("labapp"))||
                    (user && user.user_types && user.user_types.includes("commissioner"))||
                    (user && user.user_types && user.user_types.includes("ac"))? <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Complaint Reports
                  </NavLink>
                  
                </NavItem>:null}
               {/* {(user && user.user_types && user.user_types.includes("ho"))|| 
                    (user && user.user_types && user.user_types.includes("fso"))|| 
                    (user && user.user_types && user.user_types.includes("so"))|| 
                    (user && user.user_types && user.user_types.includes("do"))||
                    (user && user.user_types && user.user_types.includes("commissioner"))||
                    (user && user.user_types && user.user_types.includes("ac"))? <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Fund Reports
                  </NavLink>
                </NavItem>:null} */}
               {(user && user.user_types && user.user_types.includes("ho"))|| 
                (user && user.user_types && user.user_types.includes("fso"))|| 
                (user && user.user_types && user.user_types.includes("so"))|| 
                (user && user.user_types && user.user_types.includes("do"))||
                // (user && user.user_types && user.user_types.includes("AO"))||
                // (user && user.user_types && user.user_types.includes("dd"))||
                // (user && user.user_types && user.user_types.includes("oneapp"))||
                // (user && user.user_types && user.user_types.includes("twoapp"))||
                // (user && user.user_types && user.user_types.includes("labapp"))||
                (user && user.user_types && user.user_types.includes("commissioner"))||
                (user && user.user_types && user.user_types.includes("ac"))? <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Summary Reports
                  </NavLink>
                </NavItem>:null}
              {(user && user.user_types && user.user_types.includes("ho"))|| 
                (user && user.user_types && user.user_types.includes("fso"))|| 
                (user && user.user_types && user.user_types.includes("so"))|| 
                (user && user.user_types && user.user_types.includes("do"))||
                // (user && user.user_types && user.user_types.includes("AO"))||
                // (user && user.user_types && user.user_types.includes("dd"))||
                // (user && user.user_types && user.user_types.includes("oneapp"))||
                // (user && user.user_types && user.user_types.includes("twoapp"))||
                // (user && user.user_types && user.user_types.includes("labapp"))||
                (user && user.user_types && user.user_types.includes("commissioner"))||
                (user && user.user_types && user.user_types.includes("ac"))?  <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Sample Reports
                  </NavLink>
                </NavItem>:null}
                </Nav>
              <TabContent activeTab={activeTab}>
           {(user && user.user_types && user.user_types.includes("ho"))|| 
            (user && user.user_types && user.user_types.includes("fso"))|| 
            (user && user.user_types && user.user_types.includes("so"))|| 
            (user && user.user_types && user.user_types.includes("do"))||
            // (user && user.user_types && user.user_types.includes("AO"))||
            // (user && user.user_types && user.user_types.includes("dd"))||
            // (user && user.user_types && user.user_types.includes("oneapp"))||
            // (user && user.user_types && user.user_types.includes("twoapp"))||
            // (user && user.user_types && user.user_types.includes("labapp"))||
            (user && user.user_types && user.user_types.includes("commissioner"))||
            (user && user.user_types && user.user_types.includes("ac"))? <TabPane tabId="1" >
            <div className="container-fluid mt-5 pt-4 d-flex flex-direction-column">
            <div className="ml-3">
                <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Reports Entry</span>
                {reportNames.map(renderCard)}
                </div>  
            <div className="ml-5"><span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Reports Edit/View</span>
                {reportNames1.map(renderCard)}</div>                 
                 </div>  
            </TabPane>:(user && user.user_types && user.user_types.includes("cfa"))? <TabPane tabId="1" >
            <div className="container-fluid mt-5 pt-4 d-flex flex-direction-column">
            <div className="ml-3">
                {/* <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Reports Entry</span> */}
                {otherReports&&otherReports.length>0&&otherReports.map(renderCard)}
                </div>                  
                 </div>  
            </TabPane>:null}
            
           {(user && user.user_types && user.user_types.includes("ho"))|| 
            (user && user.user_types && user.user_types.includes("fso"))|| 
            (user && user.user_types && user.user_types.includes("so"))|| 
            (user && user.user_types && user.user_types.includes("do"))||
            // (user && user.user_types && user.user_types.includes("AO"))||
            // (user && user.user_types && user.user_types.includes("dd"))||
            // (user && user.user_types && user.user_types.includes("oneapp"))||
            // (user && user.user_types && user.user_types.includes("twoapp"))||
            // (user && user.user_types && user.user_types.includes("labapp"))||
            (user && user.user_types && user.user_types.includes("commissioner"))||
            (user && user.user_types && user.user_types.includes("ac")) ?<TabPane tabId="2">
            <div className="container-fluid mt-5 pt-4 d-flex flex-direction-column">
            <div className="ml-3">
            <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Master Complaints Report Details</span>
                {reportNames2.map(renderCard)}</div>                  
        </div>  
            </TabPane>:null}

           {(user && user.user_types && user.user_types.includes("ho"))|| 
            (user && user.user_types && user.user_types.includes("fso"))|| 
            (user && user.user_types && user.user_types.includes("so"))|| 
            (user && user.user_types && user.user_types.includes("do"))||
            // (user && user.user_types && user.user_types.includes("AO"))||
            // (user && user.user_types && user.user_types.includes("dd"))||
            // (user && user.user_types && user.user_types.includes("oneapp"))||
            // (user && user.user_types && user.user_types.includes("twoapp"))||
            // (user && user.user_types && user.user_types.includes("labapp"))||
            (user && user.user_types && user.user_types.includes("commissioner"))||
            (user && user.user_types && user.user_types.includes("ac"))? <TabPane tabId="3">
            <div className="container-fluid mt-5 pt-4 d-flex flex-direction-column">
            <div className="ml-3">
            <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Fund Report Entry</span>
                {financereports.map(renderCard)}</div>                  
            <div className="ml-5">
            <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Fund Report Edit/View</span>
                {financelist.map(renderCard)}</div>                  
        </div>  
            </TabPane>:null}
           {(user && user.user_types && user.user_types.includes("ho"))|| 
            (user && user.user_types && user.user_types.includes("fso"))|| 
            (user && user.user_types && user.user_types.includes("so"))|| 
            (user && user.user_types && user.user_types.includes("do"))||
            // (user && user.user_types && user.user_types.includes("AO"))||
            // (user && user.user_types && user.user_types.includes("dd"))||
            // (user && user.user_types && user.user_types.includes("oneapp"))||
            // (user && user.user_types && user.user_types.includes("twoapp"))||
            // (user && user.user_types && user.user_types.includes("labapp"))||
            (user && user.user_types && user.user_types.includes("commissioner"))||
            (user && user.user_types && user.user_types.includes("ac"))? <TabPane tabId="5">
            <div className="container-fluid mt-5 pt-4 d-flex flex-direction-column">
            <div className="ml-3">
           <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Overall Sample/Non Sample Details Report</span>
           {samplereports.map(renderCard)}</div>  
           <div className="ml-5">
            <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Sample/Non Sample Reports</span>
                {samplesreports.map(renderCard)}</div>  
                <div className="ml-5">
            <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Case Details Reports</span>
                {Casereports.map(renderCard)}</div>                        
        </div>  
            </TabPane>:null}
            {(user && user.user_types && user.user_types.includes("ho"))|| 
            (user && user.user_types && user.user_types.includes("fso"))|| 
            (user && user.user_types && user.user_types.includes("so"))|| 
            (user && user.user_types && user.user_types.includes("do"))||
            // (user && user.user_types && user.user_types.includes("AO"))||
            // (user && user.user_types && user.user_types.includes("dd"))||
            // (user && user.user_types && user.user_types.includes("oneapp"))||
            // (user && user.user_types && user.user_types.includes("twoapp"))||
            // (user && user.user_types && user.user_types.includes("labapp"))||
            (user && user.user_types && user.user_types.includes("commissioner"))||
            (user && user.user_types && user.user_types.includes("ac"))? <TabPane tabId="4">
            <div className="container-fluid mt-5 pt-4 d-flex flex-direction-column">
            <div className="ml-3">
           <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>Summary Report</span>
           {summaryreport.map(renderCard)}</div>   
           {/* <div className="ml-5">
            <span style={{fontSize:'15px',fontWeight:'bold',textDecoration:'underline',color:'#000'}}>General Summary Reports</span>
                {Summarygeneral.map(renderCard)}</div>                   */}
        </div>         
            </TabPane>:null}
       </TabContent>
       </div>
        </>      
    )
}
const mapDispatchToProps = (dispatch) => ({ });
  
  const mapStateToProps = (state) => ({
    user: state.home.user,
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
