import React, { useState } from "react";
import { useEffect } from "react";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { bannedProducts, getBanned } from "../../components/reducers/actions";
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'justify',
  padding:'24px',
  fontSize:'25px',
  color:"#1689FF"
}));
const BannedProducts = (props) => {
  const {ongetBanned,user } = props;
  const { t, i18n } = useTranslation();
  const [product, setProduct]=useState([])

  const getProduct = async () => {
      const resp = await ongetBanned();
      if(resp.response){
      console.log(resp)
      setProduct(resp.response)
    }
  };

  useEffect(() => {
    getProduct()
  }, [props]);
  useEffect(() => {
    getProduct()
  }, [user]);
  

  return (
    <div  style={{ marginBottom:'200px'}}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">{t("BP")}</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="col-md-10 mx-md-auto mx-0" style={{marginTop:'50px', }}>
          {/* <Box style={{margin:10}} sx={{ width: '100%' }}> */}
          <Grid container rowSpacing={2} spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} >
          { product && product.map((i, index)=>{
            return(
            <Grid className="" item xs={4} sm={4} md={6} key={index}>
              <Item  onClick={()=>props.history.push({pathname:'/banned-item',state:{'item':i.id}})}>
              <div  className="d-flex flex-row justify-content-between align-self-center">
              <p className="d-inline-block">{i.category}</p>
              <p className="pl-md-0 pl-4" style={{width:'40px',margin:'4px',marginRight:'12px'}}><i style={{alignSelf:'center'}} class="fas fa-chevron-circle-right"></i></p>
              </div>
              </Item>
            </Grid>
             )
            })}
          </Grid>
        {/* </Box> */}
        </div>
     

    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetBannedProducts: (data) => dispatch(bannedProducts(data)),
  ongetBanned:(data)=>dispatch(getBanned(data)),

});

const mapStateToProps = (state) => ({
  user:state.home.user
});

export default connect(mapStateToProps, mapDispatchToProps)(BannedProducts);
