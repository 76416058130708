import React from 'react';
import OtpInput from 'react-otp-input';

const OtpInputComponent = (props) => {

  const { values, setValues } = props;

  const otpChange = (otpValue) => {
    setValues('otp', otpValue);
  }

  return (
    <div className="otp-input">
      <OtpInput
        value={values.otp}
        inputStyle='inputStyle'
        onChange={otpChange}
        numInputs={6}
      />
    </div>
  );
}

export default OtpInputComponent;
