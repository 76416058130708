import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const PdwDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }   
        // eslint-disable-next-line 
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Packed Drinking Water Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Month</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                 <tr>
                  <th>Type</th>
                 <td className="text-left">{records.type}</td>
                </tr>
                 <tr>
                  <th>BIS Number</th>
                 <td className="text-left">{records.bis_no}</td>
                </tr>
                <tr>
                  <th>Name and Address</th>
                 <td className="text-left">{records.name_and_address}</td>
                </tr>
                
                <tr>
                  <th>License and Registration number</th>
                 <td className="text-left">{records.license_registration_number}</td>
                </tr>
                <tr>
                  <th>Total Number of Units</th>
                 <td className="text-left">{records.total_no_of_units}</td>
                </tr>
                <tr>
                  <th>Total Number of EPO/PO issued</th>
                 <td className="text-left">{records.no_of_epo_po_issued}</td>
                </tr>
                <tr>
                  <th>Number of Units Functioning</th>
                 <td className="text-left">{records.units_functioning}</td>
                </tr>
                <tr>
                  <th>Number of units having Valid BIS</th>
                   <td className="text-left">{records.having_bis}</td>
                </tr>
                <tr>
                <th>Units Functioning without PWD/NOC</th>
                <td className="text-left">{records.functioning_with_pwd}</td>
              </tr>
              <tr>
                <th>Number of Units Functioning without BIS</th>
                <td className="text-left">{records.functioning_without_bis}</td>
              </tr>              
              <tr>
                <th>Number of units Functioning with Valid FSSAI License/Registration</th>
                <td className="text-left">{records.having_fssai_license}</td>
              </tr>
              <tr>
                <th>Number of Units Functioning without FSSAI LICENSE</th>
                <td className="text-left">{records.functioning_without_fssai_license}</td>
              </tr>
              <tr>
                <th>Number of Units Functioning with Court order</th>
                <td className="text-left">{records.functioning_with_courtorder}</td>
              </tr>
              <tr>
                <th>Number of units not Functioning</th>
                <td className="text-left">{records.units_not_functioning}</td>
              </tr>
              <tr>
                <th>Units Functioning without bis</th>
                <td className="text-left">{records.functioning_without_bis}</td>
              </tr>
              <tr>
                <th>Number of Units Functioning without PWD/NOC</th>
                <td className="text-left">{records.functioning_without_pwd_noc}</td>
              </tr>
   
                <tr>
                  <th>Number of Units Functioning without FSSAI License/Registration</th>
                  <td className="text-left">{records.functioning_without_fssai_license}</td>
                </tr>
             
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(PdwDetails);