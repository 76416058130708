import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { analyseSuccess, createLabSampleReports, createSampleSuccess, editLabSampleReports, reqSample, resultUpadte } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";


const Result = [
    {id:'0', name:'Confirm To Standards'},
    {id:'1', name:'Unsafe'},
    {id:'2', name:'Substranded'},
    {id:'3', name:'Labelling Defects'},
    {id:'4', name:'Misleading'},
    {id:'5', name:'Miscellaneous'},

]
const SampleSent = [
    {id:'0', name:'Courirer'},
    {id:'1', name:'Postal'},
    {id:'2', name:'Email'},
]

const SampleAnalysed = [
    {id:'0', name:'Analysed Within 14Days'},
    {id:'1', name:'Delay'},
]
const Fit = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]

const SampleAnalysisReport = (props) => {
    const { districts, user,wards, traderTypes,resultObtained,onupdataresData,onupdataData,wardsCategory,onAnalyseReport,fso_submit_data,req_sample,lab_analyse_sample,onCreateAalayseReport,onCreateReqReport, onCreateLabSampleReports, onEditLabSampleReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'area_code':'',
        'area_name':'',
       'sample_analysed':'',
       'result_number':'',
       'result_details':'',
       'result_type':'',
       'result_intimated_to_DO_by':'',
       'delay_documents':'',
       'upload':'',
       'sample_fit_for_analysis':'',
        'add_result':false,
        'sample_f':false,
        'sample_code':'',
        'slip_matched':'',
        });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    useEffect(() => {
        console.log(props)
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state?.values?props.history.location.state?.values:props.history.location.state?.complaint;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            if(props.history.location.state.action=='add_result')setValues({...values,add_result:true})
            if(props.history.location.state.action_type=='sample_fit')setValues({...values,sample_f:true})

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name,
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
            // 'area_code',
            'area_name',
            // 'date',
            // 'sample_fit_for_analysis',
           
       ];
       if(props?.history?.location?.state?.action=='add_result')keys.push('upload_sample_documents')
       if(values&&values.type_of_sample_lifted)keys.push('sample_number_given_by_do','date_sample_lifted_date_and_time')
       if(props?.history?.location?.state?.valdate)keys.push('date_sample_received_on','sample_code')
       if(props?.history?.location?.state?.s_fit)keys.push('sample_fit_for_analysis')
       if(props?.history?.location?.state?.rs_fit)keys.push('result_number', 'result_type','date_result_signed_date', 'date_report_intimated_to_DO_on')
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            if(props.history.location.state.action_type==='analyse'){
                let id=props.history.location.state.complaint?.sample_number_given_by_do
                    let index = fso_submit_data.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter((i=>i))[0]
                    let changeData=fso_submit_data
                    changeData[index?index:0].sample_status="Waiting for analysis"
                    changeData[index?index:0].date_sample_received_on=values.date_sample_received_on
                    changeData[index?index:0].sample_code=values.sample_code
                    await onupdataData(changeData)
            }
          else{
            if(values&&values.add_result){
                let id=props.history.location.state.complaint?.sample_number_given_by_do
                let result=lab_analyse_sample.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter((i=>i))[0]
                let resultData = lab_analyse_sample
                resultData[result?result:0].sample_status = 'Result submitted to DO'
                await onAnalyseReport(resultData)
                let resultD = resultData[result?result:0]
                resultD.result_docs = values.upload_sample_documents
                let dt_res = []
                console.log(dt_res, resultD)
                dt_res.push(resultD)
                await onupdataresData(dt_res)
                let index = fso_submit_data.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter((i=>i))[0]
                    let changeData=fso_submit_data
                    changeData[index?index:0].sample_status='Result submitted to DO'
                    changeData[index?index:0].date_sample_analysed_date=values.date_sample_analysed_date
                    changeData[index?index:0].date_result_signed_date=values.date_result_signed_date
                    changeData[index?index:0].result_number=values.result_number
                    changeData[index?index:0].result_type=values.result_type
                    changeData[index?index:0].date_intimation_sent_to_do_on=values.date_intimation_sent_to_do_on
                    changeData[index?index:0].result_docs= values.upload_sample_documents
                    await onupdataData(changeData)
            }
            else{
                if(values.sample_fit_for_analysis === 'Yes'){
                    let id=props.history.location.state.complaint?.sample_number_given_by_do
                    let dat=lab_analyse_sample
                    data.sample_status="waiting for lab result"
                    dat.push(data)
                    await onCreateAalayseReport(dat)
                    let index = fso_submit_data.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter((i=>i))[0]
                    let changeData=fso_submit_data
                    changeData[index?index:0].sample_status='waiting for lab result'
                    changeData[index?index:0].date_sample_received_on=values.date_sample_received_on
                    await onupdataData(changeData)
                }
                else{
                    let id=props.history.location.state.complaint?.sample_number_given_by_do
                    let dat=req_sample
                    data.sample_status="waiting for another part sample"
                    dat.push(data)
                    await onCreateReqReport(dat)
                    let index = fso_submit_data.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter((i=>i))[0]
                    let changeData=fso_submit_data
                    changeData[index?index:0].sample_status='waiting for another part sample'
                    changeData[index?index:0].date_sample_received_on=values.date_sample_received_on
                    await onupdataData(changeData)

                }
          } 
               
            }
               
            // const resp = await onEditLabSampleReports(formData, values.id);
            // if (resp && resp.response) {
            //     console.log(resp.response);
            //     props.history.push(`/app/display-reports/lab-sample-list/`);
            // };

        } else { 
            const data = { ...values}
            console.log(data)
            setDisableSubmit(true);
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onCreateLabSampleReports(formData);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800"> Sample Details Report</h1>
            <div className="card shadow mb-4">
                {<>Sample Number :{' '}{props.history.location.state&&props.history.location.state.complaint?.sample_number_given_by_do}</>}
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                           <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    placeholder={`Area name`}
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange('area_name', e.target.value)}
                                />
                            </div>
                        </div>
                 
                     <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="date_sample_received_on"
                            > First Part Sample Received On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Received On`}
                                    name="date_sample_received_on"
                                    value={values.date_sample_received_on}
                                    error={errors.date_sample_received_on}
                                    onChangeText={(text) => handleTextChange("date_sample_received_on", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="sample_code"
                            > Sample code / Lab internal code *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sample code / Lab internal code`}
                                    name="sample_code"
                                    value={values.sample_code}
                                    error={errors.sample_code}
                                    onChange={(e) => handleTextChange("sample_code", e.target.value)}
                                />
                            </div>
                        </div> 


                        {values&&values.sample_f?<>
                            <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="sample_fit_for_analysis"
                            >Sample Fit For Analysis *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample fit for analysis`}
                                    name="sample_fit_for_analysis"
                                    items={Fit}
                                    action="dropdown"
                                    value={values.sample_fit_for_analysis}
                                    error={errors.sample_fit_for_analysis}
                                    onChange={(text) => handleTextChange("sample_fit_for_analysis", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="slip_matched"
                            >Sample matching with slip *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample matching with slip`}
                                    name="slip_matched"
                                    items={Fit}
                                    action="dropdown"
                                    value={values.slip_matched}
                                    error={errors.slip_matched}
                                    onChange={(text) => handleTextChange("slip_matched", text)}
                                />
                            </div>
                        </div>
                         </>:null}
                        {values && values.sample_fit_for_analysis? <> 
                        
                       
                       
                        {/* <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="sample_analysed"
                            >Sample Analysed: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample Analysed`}
                                    type="text"
                                    action="dropdown"
                                    items={SampleAnalysed}
                                    name="sample_analysed"
                                    value={values.sample_analysed}
                                    error={errors.sample_analysed}
                                    onChange={(text) => handleTextChange("sample_analysed", text)}
                                />
                            </div>
                        </div> */}
                        {/* {values && values.sample_analysed.includes('Analysed Within 14Days') ? */}
                        {values && values.add_result ?
                        <>
                           
                         {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_sample_analysed_date"
                            >Sample Analysed Date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Analysed Date`}
                                    name="date_sample_analysed_date"
                                    value={values.date_sample_analysed_date}
                                    error={errors.date_sample_analysed_date}
                                    onChangeText={(text) =>handleTextChange("date_sample_analysed_date", text)}
                                />
                            </div>
                        </div>      */}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_number"
                            >Result Number : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Result Number`}
                                    type="text"
                                    name="result_number"
                                    value={values.result_number}
                                    error={errors.result_number}
                                    onChange={(e) =>handleTextChange("result_number", e.target.value)}
                                />
                            </div>
                        </div>     
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_details"
                            >Result Analysed Date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Result Analysed Date`}
                                    name="result_details"
                                    value={values.result_details}
                                    error={errors.result_details}
                                    onChangeText={(text) => handleTextChange("result_details", text)}
                                />
                            </div>
                        </div> */}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_type"
                            >Result Type : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Result Type`}
                                    type="text"
                                    name="result_type"
                                    items={Result}
                                    action="dropdown"
                                    value={values.result_type}
                                    error={errors.result_type}
                                    onChange={(text) =>handleTextChange("result_type", text)}
                                />
                            </div>
                        </div>  
                        {values && values.result_type.includes('Miscellaneous')? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_result_type"
                            ></label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Result Type`}
                                    type="text"
                                    name="other_result_type"
                                    error={errors.other_result_type}
                                    value={values.other_result_type}
                                    onChange={(e) =>handleTextChange("other_result_type", e.target.value)}
                                />
                            </div>
                        </div>  
                         </> : null}  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_result_signed_date"
                            >Result Signed Date : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Result Signed Date`}
                                    name="date_result_signed_date"
                                    value={values.date_result_signed_date}
                                    error={errors.date_result_signed_date}
                                    onChangeText={(text) =>handleTextChange("date_result_signed_date", text)}
                                />
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="report_intimated_to_DO_by"
                            >Result Intimated To DO By : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Report Intimated to DO by`}
                                    type="text"
                                    items={SampleSent}
                                    action="dropdown"
                                    name="report_intimated_to_DO_by"
                                    value={values.report_intimated_to_DO_by}
                                    error={errors.report_intimated_to_DO_by}
                                    onChange={(text) =>handleTextChange("report_intimated_to_DO_by", text)}
                                />
                            </div>
                        </div> 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="date_report_intimated_to_DO_on"
                         >Report Intimted to DO On : </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`Report Intimated to DO On`}
                                 type="text"
                                 name="date_report_intimated_to_DO_on"
                                 value={values.date_report_intimated_to_DO_on}
                                 error={errors.date_report_intimated_to_DO_on}
                                 onChangeText={(text) =>handleTextChange("date_report_intimated_to_DO_on", text)}
                             />
                         </div>
                     </div> */}
                        
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_sample_documents"
                            >Upload Documents: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_sample_documents"
                                    error={errors.upload_sample_documents}
                                    onChange={(e) =>handleTextChange("upload_sample_documents", e.target.files[0])}
                                />
                            </div>
                        </div>
                         </>
                        : null}
                        {values && values.sample_analysed.includes('Delay') ? 
                        <> 
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_delay_intimation"
                            >Intimation given to DO/Head Office On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Intimation given to DO/Head Office On`}
                                    name="date_delay_intimation"
                                    value={values.date_delay_intimation}
                                    error={errors.date_delay_intimation}
                                    onChangeText={(text) =>handleTextChange("date_delay_intimation", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="delay_documents"
                            >Upload Documents: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="delay_documents"
                                    error={errors.delay_documents}
                                    onChange={(e) =>handleTextChange("delay_documents", e.target.files[0])}
                                />
                            </div>
                        </div>
                        </>
                        : null}
                         </> : null}
                           {values && values.sample_fit_for_analysis.includes('No0')? 
                           <>
                             <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_intimation_sent_to_do_on"
                            >Intimation Sent to DO On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Intimation Sent to DO On`}
                                    name="date_intimation_sent_to_do_on"
                                    value={values.date_intimation_sent_to_do_on}
                                    error={errors.date_intimation_sent_to_do_on}
                                    onChangeText={(text) =>handleTextChange("date_intimation_sent_to_do_on", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_second_part_sample_received_on"
                            >Second Part Sample Received On : </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Second Part Sample Received On`}
                                    name="date_second_part_sample_received_on"
                                    value={values.date_second_part_sample_received_on}
                                    error={errors.date_second_part_sample_received_on}
                                    onChangeText={(text) =>handleTextChange("date_second_part_sample_received_on", text)}
                                />
                            </div>
                        </div> 

                           </>
                           :null} 
                            
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateLabSampleReports: (data) => dispatch(createLabSampleReports(data)),
    onEditLabSampleReports: (data, record_id) => dispatch(editLabSampleReports(data, record_id)),
    onCreateAalayseReport: (data, record_id) => dispatch(analyseSuccess(data, record_id)),
    onCreateReqReport: (data, record_id) => dispatch(reqSample(data, record_id)),
    onAnalyseReport: (data, record_id) => dispatch(analyseSuccess(data, record_id)),
    onupdataData: (data, record_id) => dispatch(createSampleSuccess(data, record_id)),
    onupdataresData: (data, record_id) => dispatch(resultUpadte(data, record_id))

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    traderTypes: state.home.traderTypes,
    wardsCategory:state.home.wardsCategory,
    lab_analyse_sample:state.home.lab_analyse_sample,
    req_sample:state.home.req_sample,
    fso_submit_data:state.home.fso_submit_data,
    resultObtained:state.home.resultObtained,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(SampleAnalysisReport);