import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { downloadUCOReports, getDistrictWards, getUCOReports } from "../../components/reducers/actions"
import DataTable from "react-data-table-component";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { useSSR } from "react-i18next";
import { CSVLink } from "react-csv";
import MaterialTable from "material-table";
const AppList = (props) => {
  const { data, handleSelectRow } = props;
  return (
    <button onClick={() => handleSelectRow(data)}>
    </button>
  );
};
const ViewButton =(props) =>{
  const { data, handleSelectRow } = props;
  return(
  <button className="btn btn-primary btn-edit" onClick={()=> handleSelectRow(data)} > View
    <i className="fas fa-eye icon-edit" aria-hidden="true" ></i>
    </button>
  );
};
const EditButton = (props) => {
  const { data, handleEdit, handleSelectRow } = props;
  return (
    <button className="btn btn-primary btn-edit" onClick={() => handleEdit(data)}>
      Edit<i className="fas fa-pencil-alt icon-edit" aria-hidden="true"></i>
    </button>
  );
};

const UCOList = (props) => {
    const { districts, ongetUCOReports, user, wards,ondownloadgetUCOReports,ongetDist,wardsCategory  } = props;
    const [values, setValues] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);
    const [page, setPage] = useState(1);
    const [District, setDistrict] = useState({})
    const countPerPage = 10;
    const [filterData, setFilterData] = useState();
    const dref=useRef();
    const [downloadData, setDownloadData]=useState([])
    const [download, setDownload]=useState(false);
    const [duplicateDistrict, setDuplicateDistrict] = useState([]);
    const reportList = async () => {
      const resp = await ongetUCOReports({
        page: page,
        per_page: countPerPage,
      });
      if(resp && resp.response) {
        const data = resp.response
        setValues(data)
      }      
    };
    useEffect(() => {
      if (dref && dref.current && download) {
          dref.current.link.click();
          setDownload(false)
      }
    }, [download]);
    useEffect(()=>{
      var arr = [...districts]
      arr.unshift({id:'all',name:'All'})
      setDuplicateDistrict(arr)
    },[districts&&districts])
  
    useEffect(() => {
      if((user && user.user_types && user.user_types.includes("do")) || (user && user.user_types && user.user_types.includes("fso"))) {
        setDistrict(user.district);
    }
      reportList();
       // eslint-disable-next-line 
    }, [page]);

    const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        fontSize: "18px",
        paddingLeft: "30px",
        paddingRight: "30px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        color: "#45b151",
        paddingLeft: "25px",
        paddingRight: "30px",
        fontSize: "16px",
        background: "rgba(70,179,84,.10196078431372549)",
      },
    },
    cells: {
      style: {
        paddingLeft: "25px",
      },
    },
  };

    const getDistrict = (district) => {
      let districtName = null;
      districts &&
        districts.forEach((item) => {
          if (item.id === district) {
            districtName = item.name;
          }
        });
      return districtName;
    };

    const columns = [
      {
        omit: true,
        cell: (row) => <AppList data={row} handleSelectRow={handleSelectRow} />,
        selector: "id",
        sortable: true,
      },
      {
        name: "District",
        cell: (row) => getDistrict(row.district),
        selector: "district",
        sortable: true
      },

      
      {
        name: "Created At",
        cell: (row) =>
         row.created_at && moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm"),
        selector: "created_at",
        sortable: true,
      },
      {
        name:"Last Edited",
       cell:(row) =>
       row.last_updated_at && row.last_updated_at ? moment(row.last_updated_at.toString()).format("DD-MM-YYYY hh:mm") : '-',
        selector: "Last_edited",
        sortable:true,
      },
      {
        name: "Action",
        cell: (row) => 
        moment().diff(moment(row.created_at.toString()), 'hours') < 24 ? 
        (<><EditButton data={row} handleEdit={handleEdit} /> <ViewButton data={row} handleSelectRow={handleSelectRow} /></>) : <ViewButton data={row} handleSelectRow={handleSelectRow} />,
        sortable: true,
      }
    ];

    const handleTextChange = (key, value) => {
      if(key === 'date') {
        const updatedValues = { ...searchFilter, [key]: moment(value).format() };
        setSearchFilter(updatedValues);
      }
      const updatedValues = { ...searchFilter, [key]: value };
      setSearchFilter(updatedValues);
    }
    const handleDownload =()=>{
      if(searchFilter) {
        async function fetchData() {
          const resp = await ondownloadgetUCOReports({
            district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
            fromdate: moment(searchFilter.fromdate||new Date()).format('YYYY-MM-DD'),
            todate:moment(searchFilter.todate||new Date()).format('YYYY-MM-DD'),
          area_code:searchFilter.area_code,

          });
          if(resp && resp.response) {
            const data = resp.response
            setDownloadData(data)
            setDownload(true)
          }
        }
        fetchData();
      }
    }
    const handleFlick = () => {
      setFilterData();
      if (searchFilter) {
        async function fetchData() {
          const resp = await ongetUCOReports({
            district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
            fromdate: moment(searchFilter.fromdate||new Date()).format('YYYY-MM-DD'),
            todate:moment(searchFilter.todate||new Date()).format('YYYY-MM-DD'),
          area_code:searchFilter.area_code,
  
          });
          if (resp && resp.response) {
            const data = resp.response;
            console.log(data);
            setValues(data.results);
          }
        }
        fetchData();
      }
    };
  
    const handleClick = () => {
      setValues([])
      if(searchFilter) {
        async function fetchData() {
          const resp = await ondownloadgetUCOReports({
            district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
            fromdate: moment(searchFilter.fromdate||new Date()).format('YYYY-MM-DD'),
            todate:moment(searchFilter.todate||new Date()).format('YYYY-MM-DD'),
          area_code:searchFilter.area_code,
          is_json:true,

          });
          if(resp && resp.response) {
            const data = resp.response
            console.log(data)
            setFilterData(data.data)
          }
        }
        fetchData();
      }
    }
    const colDetails = [
      {
        title: "District",
        field: "district",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Record Date",
        field: "date",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      // {
      //   title: "Area Name",
      //   field: "area_name",
      //   filtering: false,
      //   cellStyle: {
      //     textAlign: "center",
      //   },
      // },
      // {
      //   title: "Area code",
      //   field: "area_code",
      //   filtering: false,
      //   cellStyle: {
      //     textAlign: "center",
      //   },
      // },
      {
        title: "Name of Aggregato",
        field: "name_of_aggregator",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Name of Aggregato",
        field: "name_of_aggregator",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Address of Aggregator",
        field: "address_of_aggregator",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },      {
        title: "Contact Numbe",
        field: "contact_number",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Email",
        field: "email",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Date of Collection",
        field: "date_of_collection",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Quantity Collected",
        field: "quantity_collected",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
      {
        title: "Date of UCO Sent",
        field: "date_of_uco_sent",
        filtering: false,
        cellStyle: {
          textAlign: "center",
        },
      },
    ];
    const handleEdit = (data) => {
      console.log(data);
      props.history.push(`/app/reports/uco-reports/${data.id}/edit`, { values: data });
    };

    const handleSelectRow = (data) => {
      console.log(data);
      props.history.push(`/app/display-reports/uco-list/${data.id}`, { records: data });
    };

    return (
        
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">UCO Certification Reports List</h1>
            <div className="main">
                <div className="row" style={{ marginBottom : 20 }}>
                {((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))? (
                <div className="col-sm-2">  <DropDownInput
                      placeholder={` District`}
                      type="text"
                      isDisabled
                      name="district"
                      value={District}
                      items={districts}
                      onChange={(value) => handleTextChange("district", value)}
                    />
                  </div>):(
                      <div className="col-sm-2">
                      <DropDownInput
                        placeholder={` District`}
                        type="text"
                        name="district"
                        action="district"
                        style
                        value={searchFilter.district}
                        items={duplicateDistrict}
                        onChange={(text) => handleTextChange("district", text)}
                      />
                    </div>
                  )}

                  <div className="col-sm-2">
                    <DropDownInput
                      placeholder={`Area Code`}
                      type="text"
                      name="area_code"
                      action="area_code"
                      value={searchFilter.area_code}
                      items={wardsCategory}
                      onChange={(text) => handleTextChange("area_code", text)}
                    />
                  </div>
                  {/* <div className="col-sm-2">
                    <DropDownInput
                      placeholder={`Area Name`}
                      type="text"
                      name="area_name"
                      action="district"
                      value={searchFilter.wards}
                      items={wards}
                      onChange={(text) => handleTextChange("area_name", text)}
                    />
                  </div> */}
                  
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`From Date`}
                        name="fromdate"
                        value={searchFilter.fromdate}
                        onChangeText={(text) => handleTextChange('fromdate', text)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`To Date`}
                        name="todate"
                        value={searchFilter.todate}
                        onChangeText={(text) => handleTextChange('todate', text)}
                    />
                  </div>
                  <div
          style={{
            float: "right",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "30px",
            marginRight: "60px",
            marginBottom: "40px",
          }}
        >

                  <div className="col-sm-1">
                    <button
                        type="button"
                        className="btn btn-secondary ml-3"
                        onClick={handleClick}>
                    View</button>
                  </div>
                  <div className="col-sm-6">
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleFlick}
            >
              View & Edit
            </button>
          </div>
          <div className="col-sm-1 ml-md-n5">
            <button
              style={{ background: "#44B150" }}
              type="button"
              className="btn btn-secondary ml-md-n3 ml-1"
              onClick={handleDownload}
            >
              Download
            </button>
                    <CSVLink 
                        data={downloadData}
                        ref={dref}
                        filename={'UCODetails.csv'}
                        // target='_blank'
                        
                        />
                  </div>
                  </div>
                  

                </div>
            {values.length>0?  <DataTable
                  columns={columns}
                  data={values}             
                  onRowClicked={handleSelectRow}
                  pagination
                  // paginationServer
                  paginationPerPage={countPerPage}
                  paginationTotalRows={values.count}
                  customStyles={customStyles}
                  noHeader
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  highlightOnHover
                  noDataComponent={
                    <div style={{ color: "#858796", marginTop: 20 }}>
                      No data available in table
                    </div>
                  }
                  onChangePage={(page) => setPage(page)}
              />:null}
               {filterData ? (
          <MaterialTable
            style={{width:'100%'}}
            columns={colDetails}
            data={filterData}
            options={{
              exportButton: {
       csv: true,
       pdf: false,
    },
              search: true,
              filtering: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
                borderColor: "#fff",
                textAlign: "center",zIndex:0
              },
            }}
            title="UCO List"
          />
        ) : null}

            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    ongetUCOReports: (data) => dispatch(getUCOReports(data)),
    ondownloadgetUCOReports: (data) => dispatch(downloadUCOReports(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(UCOList);