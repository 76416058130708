import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const TasmacDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Tasmac Shops and Bar Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Name of the FSO / DO</th>
                 <td className="text-left">{records.name_of_the_fso_do}</td>
                </tr>
                <tr>
                  <th>Designation</th>
                 <td className="text-left">{records.designation}</td>
                </tr>
                <tr>
                <th>Number of License/Registration</th>
                <td className="text-left">{records.no_of_lic_reg}</td>
              </tr> 
              <tr>
                <th>Number of Shop's Inspected</th>
                <td className="text-left">{records.no_of_inspections_shops}</td>
              </tr>              
              <tr>
                <th>Number of Shop's With Valid License/Registration</th>
                <td className="text-left">{records.license_registration}</td>
              </tr>
              <tr>
                <th>Number of Notice Issued</th>
                <td className="text-left">{records.no_of_notice_issued_shops}</td>
              </tr>
              <tr>
                <th>Number of Surveillance Sample Lifted</th>
                <td className="text-left">{records.surveillance_sample_shops}</td>
              </tr>
              <tr>
                <th>Number of Act Sample Lifted </th>
                <td className="text-left">{records.act_sample_shops}</td>
              </tr>
              <tr>
                <th>Quantity of expired products seized from Tasmac shops</th>
                <td className="text-left">{records.seized_from_shop}</td>
              </tr>
              <tr>
                <th>Number of Bar's Inspected</th>
                <td className="text-left">{records.no_of_inspections_bars}</td>
              </tr>
              <tr>
                <th>Number of Notice Issued Bar's</th>
                <td className="text-left">{records.no_of_notice_issued_bars}</td>
              </tr>
              <tr>
                <th>Number of Surveillance Sample Lifted</th>
                <td className="text-left">{records.surveillance_sample_bars}</td>
              </tr><tr>
                <th>Number of bar's with Act Sample Lifted </th>
                <td className="text-left">{records.act_sample_bars}</td>
              </tr>
              <tr>
                <th>Quantity of Expired/Spoiled food seized from Bars</th>
                <td className="text-left">{records.seized_from_bar}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(TasmacDetails);