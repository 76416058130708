import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const SpecialDriveDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Special Drive of L and R Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              {/* <tr>
                <th>Actions taken to sensitize the FBO during month</th>
                <td className="text-left">{records.action_taken_to_sensitize_the_fbo_during_month}</td>
              </tr>               */}
              <tr>
                <th>Date of special awareness drive</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.camp_details, ((item) => {return(<><li>{moment(item.date_of_special_awareness_drive).format("l")}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Number of license applied during special drive</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.camp_details, ((item) => {return(<><li>{item.no_of_license_applied_during_special_drive}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Number of license issued during special drive </th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.camp_details, ((item) => {return(<><li>{item.no_of_license_issued_during_special_drive}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Number of registration applied during special drive</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.camp_details, ((item) => {return(<><li>{item.no_of_registration_applied_during_special_drive}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Number of registration issued during special drive</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.camp_details, ((item) => {return(<><li>{item.no_of_registration_issued_during_special_drive}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Date of sensitize</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.drives_to_sensitize, ((item) => {return(<><li>{moment(item.date_of_awareness_drive_to_sensitize_consumers).format("l")}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Number of consumer queries received </th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.drives_to_sensitize, ((item) => {return(<><li>{item.no_of_consumer_queries_received}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Number of consumer queries action taken</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.drives_to_sensitize, ((item) => {return(<><li>{item.no_of_consumer_queries_action_taken}</li> </>) }))}</td>
              </tr>
              {/* <tr>
                <th>Number of defaulting FBO's identified for obtained Regn.Certificate</th>
                <td className="text-left">{records.no_of_fbos_obtained_reg_certificate_despite_license_identified}</td>
              </tr>
              <tr>
                <th>Action initiated for number of defaulting FBO's for obtained Regn.Certificate</th>
                <td className="text-left">{records.action_initiated_fbos_obtained_reg_certificate_license}</td>
              </tr>
              <tr>
                <th>Number of cases launched for FBO's who obtained Regn.Certificate</th>
                <td className="text-left">{records.no_of_cases_fbos_obtained_reg_certificate}</td>
              </tr>
              <tr>
                <th>Number of convictions imposed FBO's for obtained Regn.Certificate</th>
                <td className="text-left">{records.no_of_convictions_fbos_obtained_reg_certificate}</td>
              </tr>
              <tr>
                <th>Penalty Amount raised from FBO's for obtained Regn.Certificate(in Rs.)</th>
                <td className="text-left">{records.amount_raised_fbos_for_obtained_reg_certificate}</td>
              </tr>
              <tr>
                <th>Number of defaulting FBO's identified carrying businesses with expired License</th>
                <td className="text-left">{records.no_of_fbos_carrying_business_with_expired_license_identified}</td>
              </tr>
              <tr>
                <th>Action initiated for number of FBO's carrying businesses with expired License</th>
                <td className="text-left">{records.action_initiated_fbos_carrying_business_with_expired_license}</td>
              </tr>
              <tr>
                <th>Number of cases launched for FBO's carrying businesses with expired License</th>
                <td className="text-left">{records.no_of_cases_fbos_carrying_business_with_expired_license}</td>
              </tr>
              <tr>
                <th>Number of convictions imposed for FBO's carrying businesses with expired License</th>
                <td className="text-left">{records.no_of_convictions_fbos_carrying_business_with_expired_license}</td>
              </tr>
              <tr>
                <th>Penalty Amount raised from FBO's for carrying businesses with expired License (in Rs.)</th>
                <td className="text-left">{records.amount_raised_fbos_for_carrying_business_with_expired_license}</td>
              </tr>
              <tr>
                <th>Number of FBO's identified for obtained fresh License instead of renewal</th>
                <td className="text-left">{records.no_of_fbos_obtained_fresh_license_instead_of_renewal_identified}</td>
              </tr>
              <tr>
                <th>Action initiated for number of FBO's for obtained fresh License instead of renewal</th>
                <td className="text-left">{records.action_initiated_fbos_obtained_fresh_license_instead_of_renewal}</td>
              </tr>
              <tr>
                <th>Number of cases launched for FBO's who obtained fresh License instead of renewal</th>
                <td className="text-left">{records.no_of_cases_fbos_obtained_fresh_license_renewal}</td>
              </tr>
              <tr>
                <th>Number of convictions imposed for FBO's who obtained fresh License instead of renewal</th>
                <td className="text-left">{records.no_of_convictions_fbos_obtained_fresh_license_renewal}</td>
              </tr>
              <tr>
                <th>Penalty Amount raised from FBO's for obtained fresh License instead of renewal (in Rs.)</th>
                <td className="text-left">{records.amount_raised_fbos_for_obtained_fresh_license_renewal}</td>
              </tr>
              <tr>
                <th>Number of FBO's identified for manufacturing food products which are endorsed on their License</th>
                <td className="text-left">{records.no_of_fbos_processing_food_products_license_identified}</td>
              </tr>
              <tr>
                <th>Action initiated for number of FBO's for manufacturing food products which are endorsed on their License</th>
                <td className="text-left">{records.action_initiated_fbos_processing_food_products_license}</td>
              </tr>
              <tr>
                <th>Number of cases launched for FBO's who manufacturing food products which are endorsed on their License</th>
                <td className="text-left">{records.no_of_cases_fbos_processing_food_products_license}</td>
              </tr>
              <tr>
                <th>Number of convictions imposed for FBO's who manufacturing food products which are endorsed on their License</th>
                <td className="text-left">{records.no_of_convictions_fbos_processing_food_products_license}</td>
              </tr> */}
              {/* <tr>
                <th>Penalty Amount raised from FBO's for manufacturing food products which are endorsed on their License(in Rs.)</th>
                <td className="text-left">{records.amount_raised_fbos_for_processing_food_products_license}</td>
              </tr> */}
              {/* {records.instructions_have_been_issued_to_fbos_to_maintain_data === true ? (
                <tr>
                  <th>Instructions in this regard have been issued to FSO's to maintain data and submit a monthly report</th>
                  <td className="text-left">Yes</td>
                </tr>
              ) : (
                <tr>
                  <th>Instructions in this regard have been issued to FSO's to maintain data and submit a monthly report</th>
                  <td className="text-left">No</td>
                </tr>
              )}
              <tr>
                <th>Total Number of FBO's in position</th>
                <td className="text-left">{records.total_no_of_fsos_in_position}</td>
              </tr>
              <tr>
                <th>Number of FSO's maintaining database of the food businesses in their area</th>
                <td className="text-left">
                  {records.no_of_fsos_maintaining_database_and_submitting_monthly_reports}</td>
              </tr> */}
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialDriveDetails);