import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createTourJournalReports, editTourJournalReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import TextInput from "../../components/inputs/TextInput";

const TourJournal = (props) => {
    const { districts, user,wards,wardsCategory, onCreateTourJournalReports,ongetDist, onEditTourJournalReports } = props;
    const [values, setValues] = useState({
        'area_name':'',
        'area_code':'',
        'designation':'',
        'name':'',

    });
    const [tourjournal, setTourJournal] = useState([{
        date:'',
        date_to_date:'',
        purpose_of_visit:'',
        name_of_the_area:'',
        time_duration:'',
        remarks:'',
        head_quatres:'',

    }])
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            setTourJournal(recordData.tourjournal)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line   
    }, [])
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])

    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
        'district',
        'date',
        'date_to_date',
        'designation',
        'name',
        'area_name',
        'area_code',
        'purpose_of_visit',
        'name_of_the_area',
        'time_duration',
        'remarks' 
    ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values,
                'tourjournal':tourjournal
            }
            console.log(values, tourjournal);
            console.log(data);
            setDisableSubmit(true);
            const resp = await onEditTourJournalReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/tourjournal-list/`);
            };

        } else {
            const data = { ...values,
                'tourjournal':tourjournal
            }
            console.log(values, tourjournal);
            console.log(data);
            setDisableSubmit(true);
            const resp = await onCreateTourJournalReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }       
        
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const tourjournalList = [...tourjournal];
        tourjournalList[index][name] = value;
        console.log(tourjournalList);
        setTourJournal(tourjournalList);
        setDisableSubmit(false)
    };
    const handleTourRemoveClick = index => {
        const tourjournalList = [...tourjournal];
        tourjournalList.splice(index, 1);
        setTourJournal(tourjournalList);
    };
    const handleTourAddClick = () => {
        setTourJournal([
        ...tourjournal,
        {
            date:'',
        date_to_date:'',
        purpose_of_visit:'',
        name_of_the_area:'',
        time_duration:'',
        remarks:''
        }
        ]);
    };

    return (
        <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Tour Journal Report</h1>
        <div className="card shadow mb-4">
            <div className="card-body">
                <table className="table table-bordered">
                    <tbody>
                    {user && user.user_types && user.user_types.includes("do")|| user && user.user_types && user.user_types.includes("fso") ? (
                    <tr className="col-sm-12">
                        <th colspan="2" >District : </th>
                        <td colSpan="6" className="input-label">
                            <DropDownInput
                                type="text" isDisabled
                                name="district"
                                value={values.district}
                                error={errors.district}
                                items={districts}
                                onChange={(value) => handleTextChange("district", value)}
                            />
                        </td>
                    </tr>

                    ) : (
                    <tr>
                        <th>District *: </th>
                        <td colSpan="6" className="input-label">
                            <DropDownInput
                                placeholder={`District`}
                                type="text"
                                name="district"
                                value={values.district}
                                error={errors.district}
                                items={districts}
                                onChange={(text) => handleTextChange("district", text)}
                            />
                        </td>
                        
                    </tr>
                    )}
                    <tr>
                        <th>Area Code *</th>
                        <th>Area Name *</th>
                        <th colSpan="4">Name of the Official *</th>
                        <th colSpan="4">Designation *</th>
                       </tr>
                       <tr> 
                           <td>  <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                /></td>
                            <td>  <TextInput
                                    placeholder={`Area Name`}
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange('area_name', e.target.value)}
                                /></td>
                            <td colSpan="4"> <TextInput
                                    placeholder={`Name of the official`}
                                    name="name"
                                    id="name"
                                    value={values.name}
                                    error={errors.name}
                                    onChange={(e) => handleTextChange('name', e.target.value)}
                                /></td>
                            <td colSpan="4"> <TextInput
                                    placeholder={`Designation`}
                                    name="designation"
                                    id="designation"
                                    value={values.designation}
                                    error={errors.designation}
                                    onChange={(e) => handleTextChange('designation', e.target.value)}
                                /></td>
                       </tr>
                    <tr>
                            <th >Date *</th>
                            <th  colSpan="2">Name of the headquarters *</th>
                            <th  colSpan="2">Time duration *</th>
                            <th colSpan="2" >Name of the area visited *</th>
                            <th colSpan="2" >Purpose of visit *</th>
                            <th colSpan="4" >Remarks *</th>
                        </tr>
		                {tourjournal.map((x, i) => {
                            return (
                                <tr>
                                  
                                    <td>
                                   <label>From Date</label> <input type="date"
                                        className="form-control input-label"
                                        placeholder={` From Date`}
                                        name="date"
                                        value={x.date}
                                        error={errors.date}
                                        onChange={e => handleInputChange(e, i)}
                                    /> <br/>
                                     <label>To Date </label><input type="date"
                                        className="form-control input-label"
                                        placeholder={` To Date`}
                                        name="date_to_date"
                                        value={x.date_to_date}
                                        error={errors.date_to_date}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                
                                    <td colSpan="2">
                                    <input className="form-control input-label"
                                        type="text"
                                        name="head_quatres"
                                        placeholder="Name of the headquarters"
                                        value={x.head_quatres}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                    <td colSpan="2">
                                    <input className="form-control input-label"
                                        type="text"
                                        name="time_duration"
                                        placeholder="Time Duration"
                                        value={x.time_duration}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                    <td colSpan="2">
                                    <input className="form-control input-label"
                                        type="text"
                                        name="name_of_the_area"
                                        placeholder="Name of the area visited"
                                        value={x.name_of_the_area}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                    <td colSpan="2">
                                    <input className="form-control input-label"
                                        type="text"
                                        name="purpose_of_visit"
                                        placeholder="Purpose of visit"
                                        value={x.purpose_of_visit}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    </td>
                                  
                                    <td colSpan="4">
                                        <input className="form-control input-label margin-class"
                                            type="text"
                                            name="remarks"
                                            placeholder="Remarks"
                                            value={x.remarks}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                        {i !== tourjournal.length - 1 && (
                                        <button className="btn btn-warning btn-sm btn-circle btn-plus" onClick={() => handleTourRemoveClick(i)}>
                                            <i className="fa fa-minus " aria-hidden="true"></i>
                                        </button>
                                        )}
                                        {tourjournal.length - 1 === i && (
                                        <button className="btn btn-success btn-sm btn-circle btn-plus" onClick={handleTourAddClick}>
                                        <i className="fa fa-plus " aria-hidden="true"></i>
                                        </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        
                    
                    </tbody>
                    </table>
                    <button
                        onClick={() => props.history.push("/app/reports/")}
                        className="btn btn-success"
                        type="button">
                    Cancel</button>
                    <button
                        disabled={disableSubmit}
                        onClick={handleSubmit}
                        type="button"
                        className="btn btn-success ml-3">
                    Submit</button>
            </div>
        </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
    onCreateTourJournalReports: (data) => dispatch(createTourJournalReports(data)),
    onEditTourJournalReports: (data, record_id) => dispatch(editTourJournalReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(TourJournal);