import React from "react";
import Select from "react-select";
const customStyles = {
  container: (provided, state) => ({
    ...provided,
  }),
}
const customStyless = {
  container: (provided, state) => ({
    ...provided,
  }),
}
const DropDownInput = (props) => {
  console.log(props)
  const itemValues = [];
  props.items &&
    props.items.forEach((item) => {
      if (props.action === "language") {
        itemValues.push({
          label: item.label,
          value: item.value,
        });
      } else if (props.action === "wards") {
        itemValues.push({
          label: item.ward_name,
          value: item.id,
        });
      } 
      else if (props.action === "area_code") {
        itemValues.push({
          label: item.ward_number,
          value: item.id,
        });
      } 
      else if (props.action === true) {
        itemValues.push({
          label: item.name,
          value: item.name,
        });
      }else if(props.action === "ssc_report_result") {
        itemValues.push({
          label: item.label,
          value: item.name,
        });
      } else if(props.action === "fso") {
        itemValues.push({
          label: item.fso.name,
          value: item.fso.id,
        });
      } 
      else if(props.action === "fsos") {
        itemValues.push({
          label: item.fso?.name?item.fso.name:item.name,
          value: item.fso?.id?item.fso.id:item.id,
        });
      }
      else if(props.action === "dropdown") {
        itemValues.push({
          label: item.name,
          value: item.name,
        });
      }else {
        itemValues.push({
          label: item.name,
          value: item.id,
        });
      }
    });
  const errorMessage = props.error ? (
    <span className="error-message">{props.error}</span>
  ) : (
    <span className="error-message"></span>
  );

  const handleChange = (item) => {
    // console.log(item)
    if (props.action === "wards") {
      let wardList = [];
      item && item.map((o) => wardList.push(o.value));
      props.onChange(wardList);
    } 
    else if (props.action === true) {
      let actionList = [];
      item && item.map((o) => actionList.push(o.value));
      props.onChange(actionList);
    }
    else if (props.action == "district") {
      let districtList = [];
      item && item.map((o) => districtList.push(o.value));
      props.onChange(districtList);
    } else if (props.action === "fso") {
      let fsoList = [];
      item && item.map((o) => fsoList.push(o.value));
      props.onChange(fsoList);
    } 
    else {
      props.onChange(item.value);
    }
  };

  return (
    <>
      <Select
       styles={props.style?customStyles:customStyless}
        placeholder={props.placeholder}
        isMulti=  { (props.action === "wards") || (props.action === "district") 
        || (props.action === "fso") || (props.action === true) ? true : false }
        isDisabled={props.isDisabled}
        value={
          props.items && props.action === "language"
            ? props.items.map((item) => {
                if (props.value && item.value === props.value) {
                  return item;
                }
                return false;
              })
            : props.action === "wards"
            ? props.value &&
              props.value.map((item) => {
                let wardItem = props.items.find((o) => o.id == item);
                return {
                  value: wardItem.id,
                  label: wardItem.ward_name,
                };
              })
              : props.action === true
              ? props.value && 
              props.value.map((item) => {
               let actionItem = props.items.find((o) => o.name === item);
                  return {
                    value: actionItem.name,
                    label: actionItem.name,
                  };
                  })
            : props.action === "district"
            ? props.value &&
              props.value.map((item) => {
                let districtName = props.items.find((o) => o.id == item);
                return {
                  value: districtName.id,
                  label: districtName.name,
                };
              })
            :  props.action === "fso"
            ? props.value &&
            props.value.map((item) => {
              let fsoName = props.items && props.items.find((o) => o.fso.id == item);
              // console.log(props.items.find((o)=>o.fso.id), item)
              return {
                value: fsoName.fso.id,
                label: fsoName.fso.name,
              };
              
              })
              :  props.action === "fsos"
              ? props.value &&
              props.items.map((item) => {
                if (item.fso.id === props.value) {
                  return {
                  value: item.fso.id,
                  label: item.fso.name,
                };
              }
              return false;
                })
            : props.action === "area_code"
            ? props.value && 
              props.items.map((item) => {
                if (item.id == props.value) {
                  return {
                    value: item.id,
                    label: item.ward_number,
                  };
                }
                return false;
              })
              
            : props.action === "ssc_report_result"
            ? props.value && 
            props.items.map((item) => {
              if (item.name === props.value) {
                return {
                  value: item.name,
                  label: item.label,
                };
              }
              return false;
            })
            : props.action === "dropdown"
            ? props.value && 
            props.items.map((item) => {
              if (item.name === props.value) {
                return {
                  value: item.name,
                  label: item.name,
                };
              }
              return false;
            })
           
          
            : props.items &&
              props.items.map((item) => {
                if (item.id === props.value) {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                }
                return false;
              })
        }
        onChange={handleChange}
        isSearchable={true}
        options={itemValues}
      />
      {errorMessage}
    </>
  );
};

export default DropDownInput;