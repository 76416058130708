import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = (props) => {
  const errorMessage = props.error ? <span className="error-message">{props.error}</span> : <span className="error-message"></span>;
  return (
    <>
      {props.showMonthYearPicker ? (
        <DatePicker
          placeholderText={props.placeholder}
          selected={props.value && typeof (props.value) === 'string' ? moment(props.value).toDate() :  props.value}
          className="form-control"
          dateFormat={props.format?'MMMM':"MM/yyyy"}
          showMonthYearPicker
          disabled={props.disabled}
          onChange={props.onChangeText}
          maxDate={props.maximumDate ? props.maximumDate : new Date()}
        />
      ) : (
        <>
          {props.showTimeSelect ? (
            <DatePicker
              placeholderText={props.placeholder}
              selected={props.value && typeof (props.value) === 'string' ? moment(props.value).toDate() : props.value}
              className="form-control"
              showTimeSelect
              disabled={props.disabled}
              dateFormat="MMMM d, yyyy h:mm aa"
              onChange={props.onChangeText}
              maxDate={props.maximumDate?props.maximumDate : new Date()}
            />
          ) :
          props.showYearPicker? (
            <DatePicker
              placeholderText={props.placeholder}
              selected={props.value && typeof (props.value) === 'string' ? moment(props.value).toDate() : props.value}
              className="form-control"
              showYearPicker
              dateFormat="yyyy"
              yearItemNumber={10}
              disabled={props.disabled}
              onChange={props.onChangeText}
              maxDate={props.maximumDate?props.maximumDate : new Date()}
            />
           
          ):
          <DatePicker
            placeholderText={props.placeholder}
            selected={props.value && typeof (props.value) === 'string' ? moment(props.value).toDate() : props.value}
            className="form-control"
            dateFormat='dd/MM/yyyy'
            disabled={props.disabled}
            onChange={props.onChangeText}
            maxDate={props.maximumDate ? props.maximumDate : new Date()}
          />}

        </>
      )}

      {errorMessage}
    </>
  )
}

export default Datepicker;