import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import ModelPopup from "../../components/ModelPopup";
import { Link } from "react-router-dom";
import {
  deleteComplaint,
  getComplaints,
  getFsoOfficers,
  getLabDifferentReports,
  getNonSampleStatus,
  getSampleStatus,
  updateCivil,
  updateCriminal,
  updateSample,
  updateSampleList,
} from "../../components/reducers/actions";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/FsoAccordion/FsoAccordion";
import Table from "../../components/Table/Table";
import LoadingOverlay from "react-loading-overlay";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import TextInput from "../../components/inputs/TextInput";
const TypeOfTraders = [
  { id: "0", name: "Manufacturer" },
  { id: "1", name: "Retailer" },
  { id: "2", name: "WholeSaler/Distributor/Repacker" },
  { id: "3", name: "Transporter" },
  { id: "4", name: "Others" },
];
const NonSamplingCase = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const {
    user,
    onGetFsoOfficers,
    sample_data,
    req_sample,
    resultObtained,
    criminalResults,
    civilResults,
    ongetSample,
    overall_Sample,
    districts,
    ongetDLabs,
  } = props;
  console.log(props);
  const [activeTab, setActiveTab] = useState("1");
  const { t, i18n } = useTranslation();
  const [diffSample, setDiffSample]=useState([]);
  const [pageClosed, setPageClosed] = useState(1);
  const [pageAssign, setPageAssign] = useState(1);
  const [pageAssignfso, setPageAssignfso] = useState(1);
  const [selectedFso, setSelectedFso] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagelist, setPageList] = useState(1);
  const [pageAnalyse, setPageAnalyse] = useState(1);
  const [pageResult, setPageResult] = useState(1);
  const [pageWaitingresult, setPageWaitingResult] = useState(1);
  const [pageCivil, setPageCivil] = useState(1);
  const [pageCivilWait, setPageCivilWait] = useState(1);
  const [pageCriminal, setPageCriminal] = useState(1);
  const [pageFile, setPageFile] = useState(1);
  const [civilResult, setCivilResult] = useState(1);
  const [pageCriminalWait, setPageCriminalWait] = useState(1);
  const [activeAccordion, setActiveAccordion] = useState(-1);
  const [Addmodal, setAddModal] = useState(false);
  const [values, setValues] = useState([
    {
      type_of_trader: "",
      trader_name: "",
      trader_address: "",
      upload_docs: "",
    },
  ]);
  const countPerPage = 10;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(()=>{
    if(user&&user){
      user &&user.user_types&& user.user_types.includes("fso") ? setActiveTab("3") 
      :user &&user.user_types&& user.user_types.includes("do") ?setActiveTab('4')
      :user &&user.user_types&& user.user_types.includes("twoapp") ? setActiveTab('2')
      :user &&user.user_types&& user.user_types.includes("oneapp") ? setActiveTab('2')
      :user &&user.user_types&& user.user_types.includes("dd") ? setActiveTab('3')
      :user &&user.user_types&& user.user_types.includes("AO") ? setActiveTab('1')
      :user &&user.user_types&& user.user_types.includes("so") ? setActiveTab('5')
      :user &&user.user_types&& user.user_types.includes("ac") ? setActiveTab('3')
      :user &&user.user_types&& user.user_types.includes("ho") ? setActiveTab('3')
      :user &&user.user_types&& user.user_types.includes("apt") ? setActiveTab('3')
      :user &&user.user_types&& user.user_types.includes("commissioner") ? setActiveTab('3'):setActiveTab("1")
    }
  },[user])
  useEffect(() => {
    if (user && user.user_types.includes("do")) {
      onGetFsoOfficers({
        fso_category: true,
      });
      if (props.history.location && props.history.location.state) {
        setActiveTab(props.history.location.state.tab);
      }
    }
    // eslint-disable-next-line
  }, []);

  const getDistrictName =(id)=>{
    var nam = ''
    let data = districts && districts.filter(i=>id==i.id)[0]
    nam=data.name
    return nam
} 
  const loadPageWaitingResult = async () => {
    setLoading(true);
    if (activeTab === "4" &&user&&user.user_types&&user.user_types.includes('cfa')) {
      await ongetSample({
        page: pageWaitingresult,
        per_page: countPerPage,
        is_base64: true,
        status: "Result submitted to DO",
      });
      setLoading(false);
    }
  };
  const handleActionChange = (e, index) => {
    const { name, value } = e.target;
    const valuess = [...values];
    if (e.target.name == "upload_docs") {
      value = e.target.files[0];
    }
    valuess[index][name] = value;
    setValues(valuess);
  };
  const handlePlus = () => {
    setValues([
      ...values,
      {
        type_of_trader: "",
        trader_name: "",
        trader_address: "",
        upload_docs: "",
      },
    ]);
  };
  const handleLess = (index) => {
    const valuess = [...values];
    valuess.splice(index, 1);
    setValues(valuess);
  };
  const loadSamples = async () => {
    setLoading(true);
    if (activeTab === "1") {
      await ongetSample({
        page: pagelist,
        per_page: countPerPage,
        is_base64: true,
        status:(user&&user.user_types&&user.user_types.includes('do')||user&&user.user_types&&user.user_types.includes('fso')||user&&user.user_types&&user.user_types.includes('ho'))?'all':"Submitted to lab",
      });
      setLoading(false);
    }
  };

  const loadAnalyseSample = async () => {
    setLoading(true);
    if (activeTab === "2"&&user&&user.user_types&&user.user_types.includes('cfa')) {
      await ongetSample({
        page: pageAnalyse,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for analysis",
      });
      setLoading(false);
    }
  };
  const loadResult = async () => {
    setLoading(true);
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('cfa')) {
      await ongetSample({
        page: pageResult,
        per_page: countPerPage,
        is_base64: true,
        status: "waiting for lab result",
      });
      setLoading(false);
    }
  };
  const loadFile = async () => {
    setLoading(true);
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('fso')) {
      await ongetSample({
        page: pageFile,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for fso to submit file to AO",
      });
      setLoading(false);
    }
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('AO')) {
      await ongetSample({
        page: pageFile,
        per_page: countPerPage,
        is_base64: true,
        status: "Case filed at AO court",
      });
      setLoading(false);
    }
    if (activeTab === "4"&&user&&user.user_types&&user.user_types.includes('AO')) {
      await ongetSample({
        page: pageFile,
        per_page: countPerPage,
        is_base64: true,
        status: "Final Judgement (Civil)",
      });
      setLoading(false);
    }
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('apt')) {
      await ongetSample({
        page: pageFile,
        per_page: countPerPage,
        is_base64: true,
        status: "Final Judgement (Civil)",
      });
      setLoading(false);
    }
    if (activeTab === "4"&&user&&user.user_types&&user.user_types.includes('apt')) {
      await ongetSample({
        page: pageFile,
        per_page: countPerPage,
        is_base64: true,
        status: "Appeal Case filed at court",
      });
      setLoading(false);
    }
    if (activeTab === "5"&&user&&user.user_types&&user.user_types.includes('apt')) {
      await ongetSample({
        page: pageFile,
        per_page: countPerPage,
        is_base64: true,
        status: "Appeal case hearing",
      });
      setLoading(false);
    }
  };
  const loadWaitingCivil=async()=>{
    setLoading(true);
    if (activeTab === "5"&&user&&user.user_types&&user.user_types.includes('do')) {
      await ongetSample({
        page: pageCivilWait,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for DO final Approval",
      });
      setLoading(false);
    }
  }
  const loadCivil = async () => {
    setLoading(true);
    if (activeTab === "1" && user&&user.user_types&&user.user_types.includes('AO')) {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status:'File submitted to AO'
      });
      setLoading(false);
    }
    if (activeTab === "4" && (user&&user.user_types&&user.user_types.includes('ho')|| user&&user.user_types&&user.user_types.includes('ac'))|| user&&user.user_types&&user.user_types.includes('dd')|| user&&user.user_types&&user.user_types.includes('commissioner')) {
      const resp = await ongetDLabs({status:'all'})
      console.log(resp)
      if(resp&&resp.response){
        setDiffSample(resp.response.results)
      }
    setLoading(false);

    }
    if (activeTab === "5" && user&&user.user_types&&user.user_types.includes('AO')) {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status:'Hearing (Civil)'
      });
      setLoading(false);
    }
    if (activeTab === "1" && user&&user.user_types&&user.user_types.includes('labapp')) {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status:`Waiting for APP(Lab) Approval`,
        status_civ:true,
      });
      setLoading(false);
    }
    if (activeTab === "5" && user&&user.user_types&&user.user_types.includes('so')) {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status:`all`,
      });
      setLoading(false);
    }
    if (activeTab === "3" && (user&&user.user_types&&user.user_types.includes('dd')||user&&user.user_types&&user.user_types.includes('ac')||user&&user.user_types&&user.user_types.includes('ho')||user&&user.user_types&&user.user_types.includes('commissioner'))) {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status:`all`
      });
      setLoading(false);
    }
    if (activeTab === "1" && user&&user.user_types&&user.user_types.includes('ho')) {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status:`all`
      });
      setLoading(false);
    }
    if (activeTab === "5"&& user&&user.user_types&&user.user_types.includes('do')) {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for DO final approval",
      });
      setLoading(false);
    }
  };
  const loadCivilFinal = async () => {
    setLoading(true);
    if (activeTab === "5") {
      await ongetSample({
        page: pageCivil,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for DO final approval",
      });
      setLoading(false);
    }
  };
  const loadCriminal = async () => {
    setLoading(true);
    if(activeTab==='2'&&user&&user.user_types&&user.user_types.includes('ho')){
      
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "all",
      });
    }
    setLoading(false);
    setLoading(true);
    if(activeTab==='4'&&user&&user.user_types&&user.user_types.includes('so')){
      
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for section final approval",
      });
    }
    setLoading(false);
    if(activeTab==='4'&&user&&user.user_types&&user.user_types.includes('oneapp')){
      
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Case filed at court",
      });
    }
    setLoading(false);
    if(activeTab==='4'&&user&&user.user_types&&user.user_types.includes('twoapp')){
      
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Case filed at court",
      });
    }
    setLoading(false);
    if(activeTab==='5'&&(user&&user.user_types&&user.user_types.includes('twoapp')||user&&user.user_types&&user.user_types.includes('oneapp'))){
      
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Final Judgement (Criminal)",
        status_check:true
      });
    }
    setLoading(false);
    if(activeTab==='6'&&(user&&user.user_types&&user.user_types.includes('twoapp')||user&&user.user_types&&user.user_types.includes('oneapp'))){
      
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Hearing (Criminal)",
        status_check:true
      });
    }
    setLoading(false);
    if(activeTab=='5'&&user&&user.user_types&&user.user_types.includes('fso')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: `Waiting for prosecution approval to file case`,
      });
    }
    setLoading(false);
    if(activeTab=='6'&&user&&user.user_types&&user.user_types.includes('fso')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: `Case filed at court`,
      });
    }
    setLoading(false);
    if(activeTab=='7'&&user&&user.user_types&&user.user_types.includes('fso')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Final Judgement (Criminal)",
      });
    }
    setLoading(false);
    if(activeTab=='8'&&user&&user.user_types&&user.user_types.includes('fso')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Hearing (Criminal)",
      });
    }
    setLoading(false);
    if(activeTab=='6'&&user&&user.user_types&&user.user_types.includes('do')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for DO final Approval",
      });
    }
    setLoading(false);
    if(activeTab=='4'&&user&&user.user_types&&user.user_types.includes('do')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for DO clarification",
      });
    }
    setLoading(false);
    if(activeTab=='4'&&user&&user.user_types&&user.user_types.includes('fso')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for fso to submit file to APP",
      });
    }
    setLoading(false);
    if(activeTab=='3'&&user&&user.user_types&&user.user_types.includes('oneapp')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: `Waiting for prosecution officer to file case`,
      });
    }
    setLoading(false);
    if(activeTab=='3'&&user&&user.user_types&&user.user_types.includes('twoapp')){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: `Waiting for prosecution officer to file case`,
      });
    }
    setLoading(false);
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('so')) {
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "File submitted to section",
      });
      setLoading(false);
    }
    if (activeTab === "2"&&user&&user.user_types&&user.user_types.includes('dd')) {
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: "Waiting for DD Approval",
      });
      setLoading(false);
    }
  };
  const loadCriminalReport = async () => {
    setLoading(true);
    
    if (activeTab === "2"&& user&&user.user_types&&user.user_types.includes('ac')) {
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: `Waiting for AC Approval`,
        status_check:false,
      })
      setLoading(false);
    }
    if (activeTab === "2"&& user&&user.user_types&&user.user_types.includes('commissioner')) {
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: `Waiting for Commissioner Approval`,
        status_check:false,
      })
      setLoading(false);
    }
    if (activeTab === "2"&& (user&&user.user_types&&user.user_types.includes('twoapp')||user&&user.user_types&&user.user_types.includes('oneapp'))){
      await ongetSample({
        page: pageCriminal,
        per_page: countPerPage,
        is_base64: true,
        status: 'Waiting for Prosecution Approval',
        status_check:false,
      });
      setLoading(false);
    } 
  };
  const loadCivilResult = async () => {
    setLoading(true);
    if (activeTab === "3"&&user&&user.user_types&&user.user_types.includes('ao')) {
      await ongetSample({
        page: civilResult,
        per_page: countPerPage,
        is_base64: true,
        status: "Case filed at AO court",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCivil();
  }, [pageCivil, activeTab]);

  useEffect(() => {
   loadCivilResult();
  }, [civilResult, activeTab]);
  useEffect(() => {
    loadSamples();
  }, [pagelist, activeTab]);
  useEffect(() => {
   loadFile();
  }, [pageFile, activeTab]);
  useEffect(() => {
    loadWaitingCivil();
  }, [pageCivilWait, activeTab]);
  useEffect(() => {
    loadCivil();
  }, [pageCivil, activeTab]);
  useEffect(() => {
    loadCriminal();
    loadCriminalReport();
  }, [pageCriminal, activeTab]);
  useEffect(() => {
    loadPageWaitingResult();
  }, [pageWaitingresult, activeTab]);

  useEffect(()=>{
    loadResult();
  },[pageResult,activeTab])

  useEffect(() => {
    loadAnalyseSample();
  }, [pageAnalyse, activeTab]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "82px",
        fontSize: "18px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        minHeight: "72px",
        marginTop: "20px",
        fontWeight: "bold",
        color: "#1589FF",
        fontSize: "18px",
        background: "#e1eef0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };
  var column = [
    {
      name: "District",
      cell: (row) => <div>{getDistrictName(row.district)}</div>,
      selector: "sample_number_given_by_do",
      sortable: true,
    },
    {
      name: "Sample Received date",
      cell: (row) => (
        <div>
        {row.date_sample_received_date? moment(row.date_sample_received_date).format("DD/MM/YYYY"):'-'}
        </div>
      ),
      selector: "date_sample_lifted_date_and_time",
      sortable: true,
    },
    {
      name: "Lab Code",
      cell: (row) => <div>{row.lab_code?row.lab_code:'-'}</div>,
      selector: "lab_code",
      sortable: true,
    },
    {
      name: "Sample Status",
      cell: (row) => <div>{row.status?row.status:'-'}</div>,
      selector: "sample_status",
      sortable: true,
    },
    {
      name: "Result Type",
      cell: (row) => <div>{row.result_type?row.result_type:'-'}</div>,
      selector: "result_type",
      sortable: true,
    },
    {
      name: t("Action"),
      cell: (row) => (
        <div className="action__area">
            
          {activeTab == "4" ? (
            <>
              {row.upload_documents? (
               
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                    onClick={()=>window.open(row.upload_documents)}
                  >
                 View Result
                  </Button>
              
              ) : null}
            </>
          ) : null}
        </div>)}
  ]
  var columns = [
    // {
    //   name: "Sample number",
    //   cell: (row) => <div>{row.sample_number_given_by_do}</div>,
    //   selector: "sample_number_given_by_do",
    //   sortable: true,
    // },
    {
      name: "District",
      cell: (row) => <div>{getDistrictName(row.district)}</div>,
      selector: "sample_number_given_by_do",
      sortable: true,
    },
    {
      name: "FSO",
      cell: (row) => <div>{row.fso_name}</div>,
      selector: "assigned_fso",
      sortable: true,
    },
    {
      name: "Case Type",
      cell: (row) => <div>{row.case_category?row.case_category:'-'}</div>,
      selector: "assigned_fso",
      sortable: true,
    },
    // {
    //   name: "Sample Lifted date",
    //   cell: (row) => (
    //     <div>
    //       {moment(row.date_sample_lifted_date_and_time).format("DD/MM/YYYY")}
    //     </div>
    //   ),
    //   selector: "date_sample_lifted_date_and_time",
    //   sortable: true,
    // },
    {
      name: "Sample Status",
      cell: (row) => <div>{row.status_of_report=='Final Judgement_'?'Final Judgement':row.status_of_report=='Hearing_'?'Hearing':row.status_of_report}</div>,
      selector: "sample_status",
      sortable: true,
    },
    user && user.user_types && !user.user_types.includes("ho") && {
      name: t("Action"),
      cell: (row) => (
        <div className="action__area">
          {activeTab == "1" ? (
            <>
              {user && user.user_types && user.user_types.includes("oneapp") && (
                <>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report == `Waiting for ${user&&user.name&&user.name} approval` ? (
                    <>
                      <Link
                        to={{
                          pathname:`/app/reports/fso_non_sample_reports/`,
                          state: {
                            values: row,
                            action: "approve",
                          },
                        }}
                      >
                        <Button
                          style={{ fontSize: "12px", margin: "2px" }}
                          className="btn btn-info btn-sm"
                        >
                          Approve/Decline
                        </Button>
                      </Link>
                    </>
                  ) : null}
                </>
              )}
               {user && user.user_types && user.user_types.includes("labapp") && (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report == `Waiting for APP(Lab) Approval` ? (
                    <>
                      <Link
                        to={{
                          pathname: `/app/reports/fso_non_sample_reports/`,
                          state: {
                            values: row,
                            appIIlab:true
                          },
                        }}
                      >
                        <Button
                          style={{ fontSize: "12px", margin: "2px" }}
                          className="btn btn-info btn-sm"
                        >
                          Approve/Decline
                        </Button>
                      </Link>
                    </>
                  ) : null}
                </div>
              )}
              {user &&
              user.user_types &&
              user.user_types.includes("twoapp") &&
              activeTab == "1" ? (
                <>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === `Waiting for ${user&&user.name&&user.name} approval` ? (
                    <Link
                      to={{
                        pathname: `/app/display-reports/appt`,
                        state: {
                          values: row,
                          action: "approve",
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </>
              ) : null}
               
                  {user &&
              user.user_types &&
              user.user_types.includes("AO") &&
              activeTab == "1" ? (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>

                  {row.status_of_report === "File submitted to AO" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          showLess:true,
                          ao:true
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                      File Case
                      </Button>
                    </Link>
                  ) : null}
                  {row.status_of_report === "Case filed at AO court" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          showLess:true,
                          ao:true
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                      Enter case details
                      </Button>
                    </Link>
                  ) : null}
                </div>
              ) : null}
               {user &&
              user.user_types &&
              user.user_types.includes("so") &&
              activeTab == "1" ? (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === "File submitted to section" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          showLess:true,
                          action_type:'sample_fit',
                          action:'add_result',
                          section:true
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
              Assign file number            
             </Button>
                    </Link>
                  ) : null}
                </div>
              ) : null}
              {user &&
                user.user_types &&
                user.user_types.includes("cfa") &&
                !row.result_docs && (
                  <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        action_type:
                          row.status_of_report == "Waiting for analysis"
                            ? "sample_fit"
                            : "analyse",
                            showLess:true,
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px" }}
                      className="btn btn-info btn-sm"
                    >
                      {row.status_of_report == "Waiting for analysis"
                        ? "Analyse Sample"
                        : row.status_of_report == "waiting for lab result"
                        ? "Add result"
                        : "Add sample code"}
                    </Button>
                  </Link>
                )}
            </>
          ) : null}
          {activeTab == "2" ? (
            <>
              {user && user.user_types && user.user_types.includes("fso") && (
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports`,
                    state: {
                      complaint: row,
                      action: "Submit_to_lab",
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                    
                  >
                    {user &&
                      user.user_types &&
                      user.user_types.includes("fso") &&
                      "Submit to lab"}
                  </Button>
                </Link>
              )}
              {user && user.user_types && user.user_types.includes("dd") ? (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === "Waiting for DD Approval" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          dd: true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </div>
              ) : null}
              {user &&
              user.user_types &&
              user.user_types.includes("commissioner") ? (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === "Waiting for Commissioner Approval" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          commissioner: true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </div>
              ) : null}
              {user &&
              user.user_types &&
              user.user_types.includes("commissionerate") &&
              activeTab == "2" ? (
                <>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === "Waiting for APP-I approval" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          action: "approve",
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </>
              ) : null}
              {user &&
              user.user_types &&
              user.user_types.includes("ac") &&
              activeTab == "2" ? (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report ===
                  "Waiting for AC Approval" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          ac: true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </div>
              ) : null}
              
              {user &&
              user.user_types &&
              user.user_types.includes("oneapp") &&
              activeTab == "2" ? (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === "waiting for Prosecution Approval" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          appII: true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </div>
              ) : null}
              {user &&
              user.user_types &&
              user.user_types.includes("twoapp") &&
              activeTab == "2" ? (
                <div>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === `Waiting for Prosecution Approval` ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          appII: true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </div>
              ) : null}
              {user && user.user_types && user.user_types.includes("cfa") ? (
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports`,
                    state: {
                      values: row,
                      action_type:"sample_fit",
                      showLess:true,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                   Analyse Sample
                  </Button>
                </Link>
              ) : null}
            </>
          ) : null}
          {activeTab == "3" ? (
            <>
             {user && user.user_types && user.user_types.includes("cfa") ? (
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports`,
                    state: {
                      values: row,
                      action: "add_result",
                      showLess:true
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                    Add result
                  </Button>
                </Link>
              ) : null}
              {user && user.user_types && user.user_types.includes("apt") ? (
                <Link
                  to={{
                    pathname: `/app/reports/fso_sample_reports`,
                    state: {
                      values: row,
                      // action: "add_result",
                      appealApp:true
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                   Appeal Request
                  </Button>
                </Link>
              ) : null}
                {user && user.user_types && user.user_types.includes("so") ? (
                  <div>  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports`,
                    state: {
                      values: row,
                      showLess:true,
                      section:true,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px",margin: "2px" }}
                    className="btn btn-info btn-sm"
                  >
                Assign file number          
           </Button>
                </Link></div>
              ) : null}
              {((user && user.user_types && user.user_types.includes("oneapp"))||(user && user.user_types && user.user_types.includes("twoapp"))) ? (
                  <div>  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports`,
                    state: {
                      values: row,
                      appIIFinal:true
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px",margin: "2px" }}
                    className="btn btn-info btn-sm"
                  >
                     File Case
                  </Button>
                </Link></div>
              ) : null}
              {/* {user && user.user_types && user.user_types.includes("do") ? (
                <Link
                  to={{
                    pathname: `/app/reports/do_damaged_reports`,
                    state: {
                      values: row,
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px" }}
                    className="btn btn-info btn-sm"
                  >
                    Send another part sample
                  </Button>
                </Link>
              ) : null} */}
              {user && user.user_types && user.user_types.includes("fso") && (
                 <Link
                 to={{
                   pathname: `/app/reports/fso_non_sample_reports`,
                   state: {
                     values: row,
                     showLess:true,
                     fso:true,
                   },
                 }}>
                <Button
                  style={{ fontSize: "12px", marginLeft: "3px" }}
                  className="btn btn-info btn-sm"
                >
              Submit file to AO
                </Button>
                </Link>
              )}
                 {user && user.user_types && user.user_types.includes("AO") && (<>
                 <Link
                 to={{
                   pathname: `/app/reports/fso_non_sample_reports`,
                   state: {
                     values: row,
                     showLess:true,
                     ao:true,
                     aoResult:true,
                   },
                 }}>
                <Button
                  style={{ fontSize: "12px", marginLeft: "3px" }}
                  className="btn btn-info btn-sm"
                >
                Judgement Result
                </Button>
                </Link>
               {row.status_of_report=='Final Judgement'&&  <Link
                 to={{
                   pathname: `/app/reports/fso_non_sample_reports`,
                   state: {
                     values: row,
                     showLess:true,
                     ao:true,
                     aoResult:true,
                   },
                 }}>
                <Button
                  style={{ fontSize: "12px", marginLeft: "3px" }}
                  className="btn btn-info btn-sm"
                >
                Appeal Request
                </Button>
                </Link>}</>
              )}
            </>
          ) : null}
          {activeTab == "4" ? (
            <div>
               {user &&
              user.user_types &&
              user.user_types.includes("apt") &&
              activeTab == "4" ? (
                <>
                    <Link
                      to={{
                        pathname: `/app/reports/fso_sample_reports`,
                        state: {
                          values: row,
                          ao:true,
                          aoResult:true,
                          appealRes:true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                      Add Judgement
                      </Button>
                    </Link>
                </>
              ) : null}
              {activeTab && activeTab === "4" ? (
                <>
                  {row.upload_sample_documents && user &&
                        user.user_types &&
                        user.user_types.includes("do") ? (
                    <Button
                      className="btn btn-info btn-sm"
                      style={{ fontSize: "12px", margin: "2px" }}
                      onClick={() =>
                        window.open(row.upload_sample_documents)
                      }
                    >
                      View Result
                    </Button>
                  ) : null}
                {user &&
                        user.user_types &&
                        user.user_types.includes("do")&&row.status_of_report=='Waiting for DO clarification' ?    <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        clarify:true,
                      },
                    }}
                  >
                    <Button
                      style={{ fontSize: "12px", marginLeft: "3px" }}
                      className="btn btn-info btn-sm"
                     >
                     Send Clarification
                    </Button>
                  </Link>:null}
                 
                </>
              ) : null}
               {((user && user.user_types && user.user_types.includes("oneapp"))||(user && user.user_types && user.user_types.includes("twoapp"))) ? (
                  <>  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports`,
                    state: {
                      values: row,
                      resultAPP:true
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px",margin: "2px" }}
                    className="btn btn-info btn-sm"
                  >
                    Judgement Result
                  </Button>
                </Link></>
              ) : null}
                {user &&
              user.user_types &&
              user.user_types.includes("fso") &&
              activeTab == "4" ? (
                <>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={() =>
                        window.open(row.upload_sample_documents)
                      }
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === "Waiting for fso to submit file to APP" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          fsoFinal:true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                       Send File to APP
                      </Button>
                    </Link>
                  ) : null}
                </>
              ) : null}
               {(user &&
                user.user_types &&
                user.user_types.includes("AO")) &&
                (
                    <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        aoAppeal:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                    Appeal Request
                  </Button>
                  </Link>
                )}
              {user &&
              user.user_types &&
              user.user_types.includes("so") &&
              activeTab == "4" ? (
                <>
                  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={() =>
                        window.open(row.upload_sample_documents)
                      }
                    >
                      View result
                    </Button>
                  </Link>
                  {row.status_of_report === "Waiting for section final approval" ? (
                    <Link
                      to={{
                        pathname: `/app/reports/fso_non_sample_reports`,
                        state: {
                          values: row,
                          sectionFinal:true,
                        },
                      }}
                    >
                      <Button
                        style={{ fontSize: "12px", margin: "2px" }}
                        className="btn btn-info btn-sm"
                      >
                        Approve/Decline
                      </Button>
                    </Link>
                  ) : null}
                </>
              ) : null}

            </div>
          ) : null}
          {activeTab == "5" ? (
            <>
             {user &&
                user.user_types &&
                user.user_types.includes("apt") &&
                (
                    <Link
                    to={{
                      pathname: `/app/reports/fso_sample_reports`,
                      state: {
                        values: row,
                        showLess:true,
                        // do:true,
                        hearing_:true,
                        apt:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                   Next hearing date
                  </Button>
                  </Link>
                )}
              {user &&
                user.user_types &&
                user.user_types.includes("do") &&
                (
                    <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        showLess:true,
                        do:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                    Send case file to fso
                  </Button>
                  </Link>
                )}
                {(user &&
                user.user_types &&
                user.user_types.includes("oneapp")||user &&
                  user.user_types &&
                  user.user_types.includes("twoapp")) &&
                (
                    <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        appealApp:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                    Appeal Request
                  </Button>
                  </Link>
                )}
                {user &&
                user.user_types &&
                user.user_types.includes("AO")&&
                (
                    <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        hearing_:true,
                        // showLess:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                   Judgement Result
                  </Button>
                  </Link>
                )}
                {(user && user.user_types && user.user_types.includes("fso")) ? (
                  <div>  <Link>
                    <Button
                      style={{ fontSize: "12px", margin: "2px" }}
                      className="btn btn-info btn-sm"
                      onClick={()=>window.open(row.upload_sample_documents)}
                    >
                      View result
                    </Button>
                  </Link>
                <Link
                  to={{
                    pathname: `/app/reports/fso_non_sample_reports`,
                    state: {
                      values: row,
                      appIIFinal:true
                    },
                  }}
                >
                  <Button
                    style={{ fontSize: "12px",margin: "2px" }}
                    className="btn btn-info btn-sm"
                  >
                     File Case
                  </Button>
                </Link></div>
              ) : null}
                
            </>
          ) : null}
          {activeTab == "6" ? (
            <div>
              {user &&
                user.user_types &&
                user.user_types.includes("do") &&
                row.status_of_report == "Waiting for DO final Approval" && (
                  <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        showLess:true,
                        doFinal:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                    Send case file to fso
                  </Button>
                  </Link>
                )}
                {(user &&
                user.user_types &&
                user.user_types.includes("oneapp")||user &&
                  user.user_types &&
                  user.user_types.includes("twoapp")) &&
                  <div>
                    <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        hearing_:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                    Next hearing date
                  </Button>
                  </Link>
                </div>}
                {user &&
                user.user_types &&
                user.user_types.includes("fso") &&
                (
                   <Link
                   to={{
                     pathname: `/app/reports/fso_non_sample_reports`,
                     state: {
                       values: row,
                       resultAPP:true
                     },
                   }}
                 >
                   <Button
                     style={{ fontSize: "12px",margin: "2px" }}
                     className="btn btn-info btn-sm"
                   >
                     Judgement Result
                   </Button>
                 </Link>
                )}
            </div>

          ) : null}
           {activeTab === '7'?<>
           {user &&
                user.user_types &&
                user.user_types.includes("fso") &&
                  <div>
                  <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        appealApp:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                    Appeal Request
                  </Button>
                  </Link>
                </div>}
           </>:null}
           {activeTab === '8'?<>
           {user &&
                user.user_types &&
                user.user_types.includes("fso") &&
                  <div>
                    <Link
                    to={{
                      pathname: `/app/reports/fso_non_sample_reports`,
                      state: {
                        values: row,
                        hearing_:true,
                      },
                    }}>
                  <Button
                    style={{ fontSize: "12px", marginLeft: "3px" }}
                    className="btn btn-info btn-sm"
                  >
                    Judgement Result
                  </Button>
                  </Link>
                 
                </div>}
           </>:null}
           
        </div>
      ),
    },
  ];


  const handleDrp = (value, index) => {
    const filter = [...values];
    filter[index].type_of_trader = value;
    setValues(filter);
  };
  return (
    <div className="container-fluid">
      {/* {modal ? (
        <ModelPopup
          modal={modal}
          data={selectedItem}
          handleDeleteSubmit={handleDeleteSubmit}
          title="Complaint"
          toggle={toggle}
        ></ModelPopup>
      ) : null} */}
       {(user && user.user_types.includes("ho"))? (
            <>
              <Link
                className="btn btn-primary-green pull-right"
                to="/app/reports/fso_non_sample_reports/"
              >
                Add Non Sample
              </Link>
            </>
          ) : null}
      {Addmodal ? (
        <Modal isOpen={Addmodal}>
          <ModalHeader style={{ fontSize: "12px" }}>
            Add FBO Details
          </ModalHeader>
          <ModalBody>
            <div>
              {values.map((x, i) => {
                return (
                  <>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="type_of_trader"
                      >
                        Type of trader:{" "}
                      </label>
                      <div className="col-sm-8">
                        <DropDownInput
                          placeholder={`Type of Trader`}
                          type="text"
                          items={TypeOfTraders}
                          action="dropdown"
                          name="type_of_trader"
                          value={x.type_of_trader}
                          onChange={(text) => handleDrp(text, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                        Trader name:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextInput
                          placeholder={`Inspected Premisis Owner Name`}
                          type="text"
                          name="trader_name"
                          value={x.trader_name}
                          onChange={(e) => handleActionChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="address_of_respondant_premisis"
                      >
                        {" "}
                        Trader address:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextAreaInput
                          placeholder={`Inspected Premisis Owner Address`}
                          type="text"
                          name="trader_address"
                          value={x.trader_address}
                          onChange={(e) => handleActionChange(e, i)}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="control-label col-sm-4 text-label"
                        htmlFor="name_of_respondant_premisis"
                      >
                        Trader documents:{" "}
                      </label>
                      <div className="col-sm-8">
                        <TextInput
                          placeholder={`Inspected Premisis Owner Name`}
                          type="file"
                          name="trader_name"
                          // value={x.upload_docs}
                          onChange={(e) => handleActionChange(e, i)}
                        />
                      </div>
                    </div>
                    {i !== values.length - 1 && (
                      <button
                        style={{ float: "right" }}
                        className="btn btn-warning btn-sm btn-circle"
                        onClick={() => handleLess(i)}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                    )}
                    {values.length - 1 === i && (
                      <button
                        style={{ float: "right" }}
                        className="btn btn-success btn-sm btn-circle"
                        onClick={handlePlus}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    )}
                  </>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="form-group">
              <button
                onClick={() => setAddModal(false)}
                className="btn btn-success"
                type="button"
              >
                Cancel
              </button>
              <button
                // disabled={disableSubmit}
                // onClick={handleSubmit}
                type="button"
                className="btn btn-success ml-3"
              >
                Submit
              </button>
            </div>
          </ModalFooter>
        </Modal>
      ) : null}
      <div className="mb-4">
        <div className="py-3">
          {/* <h1 className="h4 mb-4 text-gray-800">{t("Sample")}</h1> */}
          {/* {user && user.user_types.includes("fso") ? (
            <>
              <Link
                className="btn btn-primary-green pull-right"
                to="/app/dep-add-sample-reports/"
              >
                Add New Sample
              </Link>
            </>
          ) : null} */}
         
        </div>
        <div
          className="mt-5"
          style={{ fontSize: i18n.language === "ta" ? 15 : null }}
        >
          <Nav tabs>
            {user && user.user_types.includes("fso") ? (
              <>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Cases
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Cases
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Civil cases waiting for case filing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Criminal cases 
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Criminal cases waiting for case filing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                    Criminal cases waiting for judgement
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => {
                      toggleTab("7");
                    }}
                  >
                   Appeal Request
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "8" })}
                    onClick={() => {
                      toggleTab("8");
                    }}
                  >
                  Hearing Details
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            {user && user.user_types.includes("apt") ? (
              <>
               <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
               Civil cases appeal request 
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Civil cases waiting for final result
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Civil cases hearing details
                  </NavLink>
                </NavItem>
              </>
            ) : null}
            {user && user.user_types.includes("oneapp") ? (
              <>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Criminal cases waiting for case filing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Criminal cases waiting for judgement
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                   Appeal Request
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                  Hearing Details
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            {user && user.user_types.includes("ac") ? (
              <>
              <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
               {user && user.user_types.includes("AO") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Civil case waiting for judgement
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Appeal request
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                   Hearing Details
                  </NavLink>
                </NavItem>
              </>
            ) : null}
             {user && user.user_types.includes("so") ? (
              <>
              <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                   All
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Civil Case waiting for case filing
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Criminal Case waiting for case filing
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            {user && user.user_types.includes("ho") ? (
              <>
               <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    SampleDetails
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Sample Details
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            {user && user.user_types.includes("commissioner") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            {user && user.user_types.includes("dd") ? (
              <>
               <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            {user && user.user_types.includes("twoapp") ? (
              <>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Criminal cases waiting for case filing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Criminal cases waiting for judgement
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                   Appeal Request
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                  Hearing Details
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
             {user && user.user_types.includes("labapp") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil Case
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal Case
                  </NavLink>
                </NavItem> */}

              </>
            ) : null}
            {user && user.user_types.includes("do") ? (
              <>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Civil cases
                  </NavLink>
                </NavItem>
               
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Criminal cases
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Civil cases waiting for clarification
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Criminal cases waiting for clarification
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    Civil cases waiting for do final approval
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                    Criminal cases waiting for do final approval
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "7" })}
                    onClick={() => {
                      toggleTab("7");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            {user && user.user_types.includes("cfa") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    Sample(s) to be analysed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Sample(s) waiting for analysis
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                   Sample(s) waiting for result
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Second part sample requested
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                     Sample from Different Sources
                  </NavLink>
                </NavItem> */}
              </>
            ) : null}
            
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
           
                  <>
                    {civilResults && civilResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={civilResults}
                          pageFalse
                          count={civilResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCivil(page)}
                        />
                      </div>
                    ) : null}
                     {activeTab=='1'&&user&&user.user_types&&user.user_types.includes('ho')?<>
                     {overall_Sample && overall_Sample ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={overall_Sample}
                          pageFalse
                          count={overall_Sample.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCivil(page)}
                        />
                      </div>
                    ) : null}
                </>:null}
                  </>
               
               
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {/* {(user&&user.user_types&&user.user_types.includes('dd'))?<>
                {civilResults && civilResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={civilResults}
                          count={civilResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                </>: */}
                  <>
                    {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null} 
                  </>
                  {/* } */}
                
              </div>
            </TabPane>

              <TabPane tabId="3">
                <div className="card shadow mb-4">
                  <LoadingOverlay
                    active={loading}
                    className="secondary__loader"
                    spinner
                    text="Loading..."
                  />
                   {user && user.user_types.includes("do") ? (<>
                    {civilResults && civilResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={civilResults}
                            pageFalse
                            count={civilResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                   </>):null}
                   {activeTab=='3'&&(user&&user.user_types&&user.user_types.includes('dd')||user&&user.user_types&&user.user_types.includes('ac')||user&&user.user_types&&user.user_types.includes('commissioner')||user&&user.user_types&&user.user_types.includes('ho'))?<>
                {overall_Sample && overall_Sample ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={overall_Sample}
                          pageFalse
                          count={overall_Sample.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCivil(page)}
                        />
                      </div>
                    ) : null}
                </>:null}
                {user && user.user_types.includes("apt")&&<>
                      {civilResults && civilResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={civilResults}
                            pageFalse
                            count={civilResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                  {user && user.user_types.includes("fso") && (
                    <>
                      {civilResults && civilResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={civilResults}
                            pageFalse
                            count={civilResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}</>)}
                      {user && user.user_types.includes("AO")&&<>
                      {civilResults && civilResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={civilResults}
                            pageFalse
                            count={civilResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                      {user && user.user_types.includes("so")&&<>
                      {criminalResults && criminalResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={criminalResults}
                            pageFalse
                            count={criminalResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                      {(user && user.user_types.includes("twoapp") || user && user.user_types.includes("oneapp"))&&<>
                      {criminalResults && criminalResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={criminalResults}
                            pageFalse
                            count={criminalResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                      {user && user.user_types.includes("cfa")&&<>
                      {sample_data && sample_data ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={sample_data}
                            pageFalse
                            count={sample_data.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                </div>
              </TabPane>
            <TabPane tabId="4">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                 {user && user.user_types && user.user_types.includes("do") ? (<>
                  {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                 </>):null}
                 {user && user.user_types && user.user_types.includes("apt") ? (
                  <>
                      {civilResults && civilResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={civilResults}
                            pageFalse
                            count={civilResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                      </>):null}
                {user && user.user_types && user.user_types.includes("fso") ? (
                  <>
                    {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                   </>):null}
                   {(user && user.user_types.includes("oneapp") || user && user.user_types.includes("twoapp"))&&<>
                      {criminalResults && criminalResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={criminalResults}
                            pageFalse
                            count={criminalResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageCriminal(page)}
                          />
                        </div>
                      ) : null}
                      </>}
                      {user && user.user_types && user.user_types.includes("AO") ? (
                  <>
                      {civilResults && civilResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={civilResults}
                            pageFalse
                            count={civilResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}
                      </>):null}
                   {user && user.user_types && user.user_types.includes("so") ? (
                  <>
                    {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                   </>):null}
                   {activeTab=='4'&&(user&&user.user_types&&user.user_types.includes('dd')||user&&user.user_types&&user.user_types.includes('ac')||user&&user.user_types&&user.user_types.includes('commissioner'))?<>
                {diffSample && diffSample ? (
                      <div className="card-body">
                        <Table
                          columns={column}
                          data={diffSample}
                          pageFalse
                          count={diffSample.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCivil(page)}
                        />
                      </div>
                    ) : null}
                </>:null}
              </div>
            </TabPane>
            <TabPane tabId="5">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                 {(user && user.user_types && user.user_types.includes("oneapp")||user && user.user_types && user.user_types.includes("twoapp")) ? (
                  <>
                    {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                    </>):null}
                    {(user && user.user_types && user.user_types.includes("do")) ? 
<>
                {civilResults && civilResults ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={civilResults}
                      pageFalse
                      count={civilResults.length}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageCivil(page)}
                    />
                  </div>
                ) : null}
                </>:null}
                {user && user.user_types.includes("apt")&&<>
                      {civilResults && civilResults ? (
                        <div className="card-body">
                          <Table
                            columns={columns}
                            data={civilResults}
                            pageFalse
                            count={civilResults.length}
                            countPerPage={countPerPage}
                            customStyles={customStyles}
                            handlePageChange={(page) => setPageFile(page)}
                          />
                        </div>
                      ) : null}</>}
                {(user && user.user_types && user.user_types.includes("AO")) ? 
<>
                {civilResults && civilResults ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={civilResults}
                      pageFalse
                      count={civilResults.length}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageCivil(page)}
                    />
                  </div>
                ) : null}
                </>:null}
                    {(user && user.user_types && user.user_types.includes("so")) ? 
<>
                {overall_Sample && overall_Sample ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={overall_Sample}
                      pageFalse
                      count={overall_Sample.length}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageCivil(page)}
                    />
                  </div>
                ) : null}
                </>:null}
                {user && user.user_types && user.user_types.includes("fso") ? (
                  <>
                    {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                   </>):null}
              </div>
            </TabPane>
            <TabPane tabId="6">
              <div className="card shadow mb-2">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />

                {criminalResults && criminalResults ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={criminalResults}
                      pageFalse
                      count={criminalResults.length}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageCriminal(page)}
                    />
                  </div>
                ) : null}
              </div>
            </TabPane>
            <TabPane tabId="7">
              <div className="card shadow mb-2">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {(user && user.user_types && user.user_types.includes("do")) ? 
                <>
                {diffSample && diffSample ? (
                  <div className="card-body">
                    <Table
                      columns={column}
                      data={diffSample}
                      pageFalse
                      count={diffSample.length}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageCriminal(page)}
                    />
                  </div>
                ) : null}</>:null}
                 {user && user.user_types && user.user_types.includes("fso") ? (
                  <>
                    {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                   </>):null}
              </div>
            </TabPane>
            <TabPane tabId="8">
              <div className="card shadow mb-2">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {(user && user.user_types && user.user_types.includes("do")) ? 
                <>
                {diffSample && diffSample ? (
                  <div className="card-body">
                    <Table
                      columns={column}
                      data={diffSample}
                      pageFalse
                      count={diffSample.length}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageCriminal(page)}
                    />
                  </div>
                ) : null}</>:null}
                 {user && user.user_types && user.user_types.includes("fso") ? (
                  <>
                    {criminalResults && criminalResults ? (
                      <div className="card-body">
                        <Table
                          columns={columns}
                          data={criminalResults}
                          pageFalse
                          count={criminalResults.length}
                          countPerPage={countPerPage}
                          customStyles={customStyles}
                          handlePageChange={(page) => setPageCriminal(page)}
                        />
                      </div>
                    ) : null}
                   </>):null}
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ongetSample: (data) => dispatch(getNonSampleStatus(data)),
  onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
  ongetSampe: (data) => dispatch(updateSample(data)),
  ongetSampelist: (data) => dispatch(updateSampleList(data)),
  ongetCivil: (data) => dispatch(updateCivil(data)),
  ongetCriminal: (data) => dispatch(updateCriminal(data)),
  ongetDLabs: (data) => dispatch(getLabDifferentReports(data)),

});

const mapStateToProps = (state) => ({
  user: state.home.user,
  complaints: state.home.complaints,
  assignComplaints: state.home.assignComplaints,
  closedComplaints: state.home.closedComplaints,
  unasignComplaints: state.home.unasignComplaints,
  waitingComplaints: state.home.waitingComplaints,
  foodIssues: state.home.foodIssues,
  fsoCategory: state.home.fsoCategory,
  assignfsoComplaints: state.home.assignfsoComplaints,
  sample_data: state.home.sample_data,
  list_sample: state.home.list_sample,
  fso_submit_data: state.home.fso_submit_data,
  req_sample: state.home.req_sample,
  lab_analyse_sample: state.home.lab_analyse_sample,
  resultObtained: state.home.resultObtained,
  civilResults: state.home.civilResults,
  criminalResults: state.home.criminalResults,
  overall_Sample:state.home.overall_Sample,
  districts: state.home.districts,

});

export default connect(mapStateToProps, mapDispatchToProps)(NonSamplingCase);
