import React, { useState } from 'react';
import Header from '../components/Header';
import { connect } from "react-redux";
import { Form } from 'react-bootstrap';
import toast from 'react-toast-notification';
import TextInput from '../components/inputs/TextInput';
import TextAreaInput from '../components/inputs/TextAreaInput';
import { validateEmail } from '../utils/validateEmail';
import { useTranslation } from 'react-i18next';
import { createContact } from '../components/reducers/actions';

const ContactUs = (props) => {

  const [values, setValues] = useState({
    'name': '',
    'email': '',
    'phone_number': '',
    'message': ''
  });
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);

  const { onCreateContact } = props
  const { t, i18n } = useTranslation();

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ['name', 'email', 'phone_number', 'message'];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === '') {
        updatedErrors[key] = ' ';
      }
    });

    if (values.phone_number && (!values.phone_number || values.phone_number.length !== 10)) {
      updatedErrors.phone_number = 'Invalid phone number';
    }

    if (values.email && !validateEmail(values.email)) {
      updatedErrors.email = 'Invalid email address';
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisableSubmit(true);
    var data = { ...values };
    const resp = await onCreateContact(data);
    if (resp && resp.response) {
      toast.success(i18n.t('Thank you for filling out your information!'));
      setValues({
        'name': '',
        'email': '',
        'phone_number': '',
        'message': ''
      });
      setErrors({});
      setDisableSubmit(true);
    }
  }

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    validateValues(updatedValues);
  }

  return (
    <>
      <Header activeMenu="Contact us" />
      <div id="main">
        <section>
          <div className="contact__list__main">
            <h2>{t('Contact us')}</h2>
            <p style={{textAlign:'center'}}>For Food and food product related complaints, <a style={{color:'blueviolet'}} href="/login">click here </a> </p>
            <div className="contact__form">
              <Form className="user" onSubmit={handleSubmit}>
                <div className="form-group">
                  <TextInput
                    placeholder={t("Name")}
                    type='text'
                    name='name'
                    error={errors.name}
                    value={values.name}
                    onChange={(e) => handleTextChange('name', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    placeholder={t("Email")}
                    type='text'
                    name='email'
                    error={errors.email}
                    value={values.email}
                    onChange={(e) => handleTextChange('email', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    placeholder={t("Phone number")}
                    type='text'
                    name='phone_number'
                    error={errors.phone_number}
                    value={values.phone_number}
                    onChange={(e) => handleTextChange('phone_number', e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <TextAreaInput
                    placeholder={t("Message")}
                    name='message'
                    error={errors.message}
                    value={values.message}
                    onChange={(e) => handleTextChange('message', e.target.value)}
                  />
                </div>
                <button disabled={disableSubmit} type="submit" className="btn btn-primary btn-user btn-block mb-3">
                  {t('Submit')}
                </button>
              </Form>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  onCreateContact: (data) => dispatch(createContact(data)),
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);