import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const TourDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            console.log(recordData)
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Tour Journal Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Name of the Official</th>
                <td className="text-left">{records.name}</td>
              </tr>
              <tr>
                <th>Designation</th>
                <td className="text-left">{records.designation}</td>
              </tr>
              <tr>
              <th> Date</th>
              <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.tourjournal, ((item, indx) => {return(<><ul>{indx + 1}. From: {item.date} - To: {item.date_to_date}</ul> </>) }))}</td>
              </tr>
              {/* <tr>
              <th>To Date</th>
              <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.tourjournal,  ((item) => {return(<><li>{item.date_to_date}</li> </>) }))}</td>
              </tr> */}
              <tr>
                <th>Name of the area to be visited</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.tourjournal, ((item, indx) => {return(<><ul>{indx + 1}. {item.name_of_the_area}</ul> </>) }))}</td>
              </tr>
              <tr>
                <th>Purpose of visit</th>
                  <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.tourjournal, ((item, indx) => {return(<><ul>{indx + 1}. {item.purpose_of_visit}</ul> </>) }))}</td>
              </tr>
              <tr>
                <th>Time Duration</th>
                  <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.tourjournal, ((item, indx) => {return(<><ul>{indx + 1}. {item.time_duration}</ul> </>) }))}</td>
              </tr>
              <tr>
                <th>Remarks</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.tourjournal, ((item, indx) => {return(<><ul>{indx + 1}. {item.remarks}</ul> </>) }))}</td>
              </tr>   
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(TourDetails);