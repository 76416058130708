import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createEnumerationOfFBOReport, editEnumerationOfFBOReport, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment'

const EnumerationOfFBOReport = (props) => {
    const { districts, user, wards, onCreateEnumerationOfFBOReport,wardsCategory, onEditEnumerationOfFBOReport, onGetDist } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'fso_area_code' : '',
        'area_name':'',
        'no_of_enumeration_of_fbos_license' : '',
        'no_of_enumeration_of_fbos_registration' : '',
        'total_no_of_enumeration_of_fbos' : '',
        'active_license_as_on_date' : '',
        'active_registration_as_on_date' : '',
        'total_active_license_and_registration_as_on_date' : ''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
    
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            // const WardDistrict = onGetDist()
            // console.log(WardDistrict)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
            // const WardDistrict = onGetDist()
            // console.log(WardDistrict)
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.fso_area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.fso_area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 
        'date','area_name', 
        'fso_area_code',
         'no_of_enumeration_of_fbos_license',
        'no_of_enumeration_of_fbos_registration',
         'total_no_of_enumeration_of_fbos',
        'active_license_as_on_date',
         'active_registration_as_on_date',
        'total_active_license_and_registration_as_on_date'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {   
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditEnumerationOfFBOReport(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/enumeration-of-fbo-list/`);
            };

        } else {    
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateEnumerationOfFBOReport(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">L and R Enumeration of FBO's Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fso_area_code"
                            >Area code *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    type="text"
                                    name="fso_area_code"
                                    action="area_code"
                                    value={values.fso_area_code}
                                    error={errors.fso_area_code}
                                    items={wardsCategory}
                                    onChange={(value) => handleTextChange("fso_area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                   value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_enumeration_of_fbos_license"
                            >Number of enumeration of FBO's license *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of enumeration of FBO's license`}
                                    type="number"
                                    name="no_of_enumeration_of_fbos_license"
                                    value={values.no_of_enumeration_of_fbos_license}
                                    error={errors.no_of_enumeration_of_fbos_license}
                                    onChange={(e) =>handleTextChange("no_of_enumeration_of_fbos_license", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_enumeration_of_fbos_registration"
                            >Number of enumeration of FBO's registration *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of enumeration of FBO's registration`}
                                    type="number"
                                    name="no_of_enumeration_of_fbos_registration"
                                    value={values.no_of_enumeration_of_fbos_registration}
                                    error={errors.no_of_enumeration_of_fbos_registration}
                                    onChange={(e) =>handleTextChange("no_of_enumeration_of_fbos_registration", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="total_no_of_enumeration_of_fbos"
                            >Total number of enumeration of FBO's (Street vendors and non-street vendors) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total number of enumeration of FBO's`}
                                    type="number"
                                    name="total_no_of_enumeration_of_fbos"
                                    value={values.total_no_of_enumeration_of_fbos}
                                    error={errors.total_no_of_enumeration_of_fbos}
                                    onChange={(e) =>handleTextChange("total_no_of_enumeration_of_fbos", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="active_license_as_on_date"
                            >Active license as on date *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Active license as on date`}
                                    type="number"
                                    name="active_license_as_on_date"
                                    value={values.active_license_as_on_date}
                                    error={errors.active_license_as_on_date}
                                    onChange={(e) =>handleTextChange("active_license_as_on_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="active_registration_as_on_date"
                            >Active Registration as on date *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Active Registration as on date`}
                                    type="number"
                                    name="active_registration_as_on_date"
                                    value={values.active_registration_as_on_date}
                                    error={errors.active_registration_as_on_date}
                                    onChange={(e) =>handleTextChange("active_registration_as_on_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="total_active_license_and_registration_as_on_date"
                            >Total active license and registration as on date *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total active license and registration as on date`}
                                    type="number"
                                    name="total_active_license_and_registration_as_on_date"
                                    value={values.total_active_license_and_registration_as_on_date}
                                    error={errors.total_active_license_and_registration_as_on_date}
                                    onChange={(e) =>handleTextChange("total_active_license_and_registration_as_on_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateEnumerationOfFBOReport: (data) => dispatch(createEnumerationOfFBOReport(data)),
    onEditEnumerationOfFBOReport: (data, record_id) => dispatch(editEnumerationOfFBOReport(data, record_id)),
    onGetDist:(data)=>dispatch(getDistrictWards(data))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(EnumerationOfFBOReport);