import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  downloadVehicleInspectionReports,
  getDistrictWards,
  getFoodCategory,
  getVehicleInspectionReports,
} from "../../components/reducers/actions";
import DataTable from "react-data-table-component";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker";
import { useSSR } from "react-i18next";
import TextInput from "../../components/inputs/TextInput";
import { CSVLink } from "react-csv";
import MaterialTable from "material-table";

const AppList = (props) => {
  const { data, handleSelectRow } = props;
  return <button onClick={() => handleSelectRow(data)}></button>;
};
const ViewButton = (props) => {
  const { data, handleSelectRow } = props;
  return (
    <button
      className="btn btn-primary btn-edit"
      onClick={() => handleSelectRow(data)}
    >
      {" "}
      View
      <i className="fas fa-eye icon-edit" aria-hidden="true"></i>
    </button>
  );
};
const EditButton = (props) => {
  const { data, handleEdit, handleSelectRow } = props;
  return (
    <button
      className="btn btn-primary btn-edit"
      onClick={() => handleEdit(data)}
    >
      Edit<i className="fas fa-pencil-alt icon-edit" aria-hidden="true"></i>
    </button>
  );
};

const VehicleList = (props) => {
  const {
    districts,
    getVehicleInspectionReports,
    wardsCategory,
    user,
    wards,
    getdownloadVehicleInspectionReports,
    ongetDist,
    ongetFoodCategory,
  } = props;
  const [values, setValues] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [District, setDistrict] = useState({});
  const countPerPage = 10;
  const dref = useRef();
  const [downloadData, setDownloadData] = useState([]);
  const [download, setDownload] = useState(false);
  const [filterData, setFilterData] = useState();
  const [foodCategory, setFoodCategory]=useState([]);
  const [duplicateDistrict, setDuplicateDistrict] = useState([]);
  const reportList = async () => {
    const resp = await getVehicleInspectionReports({
      page: page,
      per_page: countPerPage,
    });
    console.log(resp);
    if (resp && resp.response) {
      const data = resp.response;
      console.log(data);
      setValues(data);
    }
  };
  useEffect(() => {
    if (dref && dref.current && download) {
      dref.current.link.click();
      setDownload(false);
    }
  }, [download]);
  useEffect(() => {
    if (
      (user && user.user_types && user.user_types.includes("do")) ||
      (user && user.user_types && user.user_types.includes("fso"))
    ) {
      setDistrict(user.district);
    }
    reportList();
    // eslint-disable-next-line
  }, [page]);
  useEffect(()=>{
    var arr = [...districts]
    arr.unshift({id:'all',name:'All'})
    setDuplicateDistrict(arr)
  },[districts&&districts])


  useEffect(() => {
    wardsCategory.map((o) => {
      if (o.id == searchFilter.area_code)
        return setSearchFilter({
          ...searchFilter,
          area_name: o.ward_name,
        });
    });
  }, [searchFilter.area_code]);
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        fontSize: "18px",
        paddingLeft: "30px",
        paddingRight: "30px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        color: "#45b151",
        paddingLeft: "25px",
        paddingRight: "30px",
        fontSize: "16px",
        background: "rgba(70,179,84,.10196078431372549)",
      },
    },
    cells: {
      style: {
        paddingLeft: "25px",
      },
    },
  };

  const getDistrict = (district) => {
    let districtName = null;
    districts &&
      districts.forEach((item) => {
        if (item.id === district) {
          districtName = item.name;
        }
      });
    return districtName;
  };
  const getDis = async (data) => {
    if (
      (user && user.user_types && user.user_types.includes("do")) ||
      (user && user.user_types && user.user_types.includes("fso"))
    ) {
      var dat = data;
    } else dat = data[0];
    await ongetDist({ district: dat });
  };
  useEffect(() => {
    if (searchFilter.district && searchFilter.district) {
      getDis(searchFilter.district);
    }
  }, [searchFilter && searchFilter.district]);

  const columns = [
    {
      omit: true,
      cell: (row) => <AppList data={row} handleSelectRow={handleSelectRow} />,
      selector: "id",
      sortable: true,
    },
    {
      name: "District",
      cell: (row) => getDistrict(row.district),
      selector: "district",
      sortable: true,
    },

    {
      name: "Created At",
      cell: (row) =>
        row.created_at &&
        moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm"),
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Last Edited",
      cell: (row) =>
        row.last_updated_at && row.last_updated_at
          ? moment(row.last_updated_at.toString()).format("DD-MM-YYYY hh:mm")
          : "-",
      selector: "Last_edited",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) =>
        moment().diff(moment(row.created_at.toString()), "hours") < 24 ? (
          <>
            <EditButton data={row} handleEdit={handleEdit} />{" "}
            <ViewButton data={row} handleSelectRow={handleSelectRow} />
          </>
        ) : (
          <ViewButton data={row} handleSelectRow={handleSelectRow} />
        ),
      sortable: true,
    },
  ];
  const Destroy = [
    { id: "0", name: "All" },
    { id: "0", name: "Yes" },
    { id: "1", name: "No" },
  ];
  const Seizure = [
    { id: "0", name: "All" },
    { id: "0", name: "FSO" },
    { id: "1", name: "DO" },
    { id: "2", name: "FBO" },
    { id: "3", name: "Judicial Magistrate" },
    { id: "4", name: "Other Official" },
  ];
  const Default = [
    { id: "1", name: "All" },
    { id: "2", name: "Misbranded" },
    { id: "3", name: "Expiry" },
  ];
  const seized = [
    { id: "0", name: "All" },
    { id: "0", name: "Food Only" },
    { id: "1", name: "Vehicle and Food" },
  ];
  const Rc = [
    { id: "0", name: "All" },
    { id: "0", name: "Yes" },
    { id: "1", name: "No" },
  ];
  const FoodType = [
    { id: "0", name: "All" },
    { id: "0", name: "Pre Packed" },
    { id: "1", name: "Loose Condition" },
  ];
  const colDetails = [
    {
      title: "District",
      field: "district",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Record Date",
      field: "date_of_inspection",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Area Name",
      field: "area_name",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Area code",
      field: "area_code",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Place Of Inpsection",
      field: "place_of_inpsection",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Default Found",
      field: "default_found",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Name Seized Under Custody Of",
      field: "name_seized_under_custody_of",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "DesigFso Name Seized Under Custody Ofnation",
      field: "fso_name_seized_under_custody_of",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Vehicle Number",
      field: "vehicle_number",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Owner Name",
      field: "owner_name",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Owner Address",
      field: "owner_address",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Food Type",
      field: "food_type",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Driver Name",
      field: "driver_name",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Driver Address",
      field: "driver_address",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Sample Lifted",
      field: "sample_lifted",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Food Destroyed",
      field: "food_destroyed",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Coordinated Officers",
      field: "coordinated_officers",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Food Category",
      field: "food_category",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Other Food Category",
      field: "other_food_category",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Batch No",
      field: "batch_no",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Packed Dates",
      field: "packed_date",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Packed Address",
      field: "packed_address",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Consignment Name",
      field: "consignment_name",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Seized Under Custody Of",
      field: "seized_under_custody_of",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Consignment Address",
      field: "consignment_address",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },{
      title: "Other Seized Under Custody Of",
      field: "other_seized_under_custody_of",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Seized With",
      field: "seized_with",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Type Of Vehicle",
      field: "type_of_vehicle",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Rc Available",
      field: "rc_available",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "License Available",
      field: "license_available",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Return Property",
      field: "return_property",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Lic Number",
      field: "lic_number",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Food Name",
      field: "food_name",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Food Value",
      field: "food_value",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Food quantity",
      field: "food_quantity",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Worker Name",
      field: "worker_name",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Worker Address",
      field: "worker_address",
      filtering: false,
      cellStyle: {
        textAlign: "center",
      },
    },
  ];
  const getFood=async()=>{
    const resp = await ongetFoodCategory()
    if(resp&&resp.response){
      let res=resp.response
      res.unshift({id:'all',name:'All'})
        setFoodCategory(res)
    }
}
useEffect(()=>{
    getFood()
},[])
  const handleTextChange = (key, value) => {
    if (key === "date") {
      const updatedValues = { ...searchFilter, [key]: moment(value).format() };
      setSearchFilter(updatedValues);
    }
    const updatedValues = { ...searchFilter, [key]: value };
    setSearchFilter(updatedValues);
  };
  const handleDownload = () => {
    if (searchFilter) {
      async function fetchData() {
        const resp = await getdownloadVehicleInspectionReports({
          district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
          fromdate: moment(searchFilter.fromdate||new Date()).format("YYYY-MM-DD"),
          todate: moment(searchFilter.todate||new Date()).format("YYYY-MM-DD"),
          area_code: searchFilter.area_code,
          food_category:searchFilter.food_category&&searchFilter.food_category=='All'?'':searchFilter.food_category,
          seized_under_custody_of:searchFilter.seized_under_custody_of&&searchFilter.seized_under_custody_of=='All'?'':searchFilter.seized_under_custody_of,
          seized_with:searchFilter.seized_with&&searchFilter.seized_with=='All'?'':searchFilter.seized_with,
          default_found:searchFilter.default_found&&searchFilter.default_found=='All'?'':searchFilter.default_found,
        });
        if (resp && resp.response) {
          const data = resp.response;
          setDownloadData(data);
          setDownload(true);
        }
      }
      fetchData();
    }
  };
  const handleFlick = () => {
    setFilterData();
    if (searchFilter) {
      async function fetchData() {
        const resp = await getVehicleInspectionReports({
          district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
          fromdate: moment(searchFilter.fromdate||new Date()).format("YYYY-MM-DD"),
          todate: moment(searchFilter.todate||new Date()).format("YYYY-MM-DD"),
          area_code: searchFilter.area_code,
          food_category:searchFilter.food_category&&searchFilter.food_category=='All'?'':searchFilter.food_category,
          seized_under_custody_of:searchFilter.seized_under_custody_of&&searchFilter.seized_under_custody_of=='All'?'':searchFilter.seized_under_custody_of,
          seized_with:searchFilter.seized_with&&searchFilter.seized_with=='All'?'':searchFilter.seized_with,
          default_found:searchFilter.default_found&&searchFilter.default_found=='All'?'':searchFilter.default_found,
        });
        if (resp && resp.response) {
          const data = resp.response;
          console.log(data);
          setValues(data.results);
        }
      }
      fetchData();
    }
  };

  const handleClick = () => {
    setValues([])
    if (searchFilter) {
      async function fetchData() {
        const resp = await getdownloadVehicleInspectionReports({
          district: ((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))?[District]:searchFilter?.district?searchFilter?.district.filter(i=>i!=='all')[0]?searchFilter?.district.filter(i=>i!=='all'):null:null,
          fromdate: moment(searchFilter.fromdate||new Date()).format("YYYY-MM-DD"),
          todate: moment(searchFilter.todate||new Date()).format("YYYY-MM-DD"),
          area_code: searchFilter.area_code,
          food_category:searchFilter.food_category&&searchFilter.food_category=='All'?'':searchFilter.food_category,
          seized_under_custody_of:searchFilter.seized_under_custody_of&&searchFilter.seized_under_custody_of=='All'?'':searchFilter.seized_under_custody_of,
          seized_with:searchFilter.seized_with&&searchFilter.seized_with=='All'?'':searchFilter.seized_with,
          default_found:searchFilter.default_found&&searchFilter.default_found=='All'?'':searchFilter.default_found,
          is_json:true,
        });
        if (resp && resp.response) {
          const data = resp.response;
          let food_name=data.data.map((i)=>i.product_details.map(a=>`${a.name_of_food_seized+','+' '}`))
          let food_value=data.data.map((i)=>i.product_details.map(a=>`${a.value_of_food_seized+','+' '}`))
          let food_quantity=data.data.map((i)=>i.product_details.map(a=>`${a.quantity_of_food_seized+','+' '}`))
          let worker_name=data.data.map((i)=>i.worker_details.map(a=>`${a.worker_name+','+' '}`))
          let worker_address=data.data.map((i)=>i.worker_details.map(a=>`${a.worker_address+','+' '}`))
         let arrData=data.data.map((i,indx) =>{return{...i,food_name:[food_name[indx]],food_value:[food_value[indx]],food_quantity:[food_quantity[indx]],worker_name:[worker_name[indx]],worker_address:[worker_address[indx]]}})
         console.log(arrData)
          setFilterData(arrData);
        }
      }
      fetchData();
    }
  };

  const handleEdit = (data) => {
    console.log(data);
    props.history.push(
      `/app/reports/vehicle-inspection-reports/${data.id}/edit`,
      { values: data }
    );
  };

  const handleSelectRow = (data) => {
    console.log(data);
    props.history.push(
      `/app/display-reports/vehicle-inspection-list/${data.id}`,
      { records: data }
    );
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Vehicle Inspection Reports List</h1>
      <div className="main">
        <div className="row" style={{ marginBottom: 20 }}>
        {((user && user.user_types && user.user_types.includes("do"))||user && user.user_types && user.user_types.includes("fso"))? (
                <div className="col-sm-2">  <DropDownInput
                      placeholder={` District`}
                      type="text"
                      isDisabled
                      name="district"
                      value={District}
                      items={districts}
                      onChange={(value) => handleTextChange("district", value)}
                    />
                  </div>):(
                      <div className="col-sm-2">
                      <DropDownInput
                        placeholder={` District`}
                        type="text"
                        name="district"
                        action="district"
                        style
                        value={searchFilter.district}
                        items={duplicateDistrict}
                        onChange={(text) => handleTextChange("district", text)}
                      />
                    </div>
                  )}
          <div className="col-sm-2">
            <DropDownInput
              placeholder={`Area Code`}
              type="text"
              name="area_code"
              action="area_code"
              value={searchFilter.area_code}
              items={wardsCategory}
              onChange={(text) => handleTextChange("area_code", text)}
            />
          </div>

          {/* <div className="col-sm-2">
                    <TextInput
                      placeholder={`Area Name`}
                      type="text"
                      name="area_name"
                      value={searchFilter.area_name}
                      onChange={(e) => handleTextChange("area_name", e.target.value)}
                    />
                  </div> */}
          <div className="col-sm-2">
            <Datepicker
              placeholder={`From Date`}
              name="fromdate"
              value={searchFilter.fromdate}
              onChangeText={(text) => handleTextChange("fromdate", text)}
            />
          </div>
          <div className="col-sm-2">
            <Datepicker
              placeholder={`To Date`}
              name="todate"
              value={searchFilter.todate}
              onChangeText={(text) => handleTextChange("todate", text)}
            />
          </div>
          <div className="col-sm-3">
            <DropDownInput
              placeholder={`Violation Found`}
              items={Default}
              action="dropdown"
              name="default_found"
              value={searchFilter.default_found}
              onChange={(text) => handleTextChange("default_found", text)}
            />
          </div>
          {foodCategory && foodCategory.length !== 0 && (
             <div style={{marginTop:'30px'}} className="col-sm-3">
            <DropDownInput
              placeholder={`Food Category`}
              items={foodCategory}
              action="dropdown"
              name="food_category"
              value={searchFilter.food_category}
              onChange={(text) => handleTextChange("food_category", text)}
            />
            </div>
          )}
           <div style={{marginTop:'30px'}} className="col-sm-4">
          <DropDownInput
            placeholder={`Seized Under Custody Of`}
            name="seized_under_custody_of"
            items={Seizure}
            action="dropdown"
            value={searchFilter.seized_under_custody_of}
            onChange={(text) =>
              handleTextChange("seized_under_custody_of", text)
            }
          /></div>
           <div style={{marginTop:'30px'}} className="col-sm-2">
          <DropDownInput
            placeholder={`Seized with`}
            name="seized_with"
            items={seized}
            action="dropdown"
            value={searchFilter.seized_with}
            onChange={(text) => handleTextChange("seized_with", text)}
          />
          </div>
          <div
            style={{
              float: "right",
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "30px",
              marginRight: "60px",
              marginBottom: "40px",
            }}
          >
            <div className="col-sm-1">
              <button
                type="button"
                className="btn btn-secondary ml-3"
                onClick={handleClick}
              >
                View
              </button>
            </div>
            <div className="col-sm-6">
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={handleFlick}
            >
              View & Edit
            </button>
          </div>
          <div className="col-sm-1 ml-md-n5">
            <button
              style={{ background: "#44B150" }}
              type="button"
              className="btn btn-secondary ml-md-n3 ml-1"
              onClick={handleDownload}
            >
              Download
            </button>
              <CSVLink
                data={downloadData}
                ref={dref}
                filename={"VehicleInspection.csv"}
                // target='_blank'
              />
            </div>
          </div>
        </div>
        {values.length > 0 ? (
          <DataTable
            columns={columns}
            data={values}
            onRowClicked={handleSelectRow}
            pagination
            // paginationServer
            paginationPerPage={countPerPage}
            paginationTotalRows={values.count}
            customStyles={customStyles}
            noHeader
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            highlightOnHover
            noDataComponent={
              <div style={{ color: "#858796", marginTop: 20 }}>
                No data available in table
              </div>
            }
            onChangePage={(page) => setPage(page)}
          />
        ) : null}
        {filterData? (
          <MaterialTable
            style={{ width: "100%" }}
            columns={colDetails}
            data={filterData}
            options={{
              exportButton: {
       csv: true,
       pdf: false,
    },
              search: true,
              filtering: true,
              pageSize: 20,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
              headerStyle: {
                backgroundColor: "#01579b",
                color: "#FFF",
                borderColor: "#fff",
                textAlign: "center",zIndex:0
              },
            }}
            title="Vehicle Inspection List"
          />
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getVehicleInspectionReports: (data) =>
    dispatch(getVehicleInspectionReports(data)),
  getdownloadVehicleInspectionReports: (data) =>
    dispatch(downloadVehicleInspectionReports(data)),
  ongetDist: (data) => dispatch(getDistrictWards(data)),
  ongetFoodCategory: (data) => dispatch(getFoodCategory(data))

});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  wardsCategory: state.home.wardsCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleList);
