import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Layout from './containers/Layout';
import PrivateRoute from './components/PrivateRoute';
import Main from './containers/Main';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";

const App = (props) => {

  const { t } = useTranslation();

  useEffect(() => {
    const currentEnvironment = process.env.REACT_APP_ENV;
    if (currentEnvironment === 'prod') {
      ReactGA.initialize('UA-185885563-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [])

  return (
    <>
      <LoadingOverlay active={props.loading} spinner text="Loading..." />
      <Router>
        <Switch>
          <PrivateRoute path="/app/" component={Layout} />
          <Route exact path="/" component={Main} />
          <Route path="*" component={Main} />
        </Switch>
      </Router>
      <CookieConsent
        style={{ background: "#2B373B", fontFamily: 'Josefin Sans' }}
        buttonStyle={{ color: "#fff", background: '#28a745', padding: '5px 25px', fontSize: "16px" }}
      >
        {t('This website uses cookies to enhance the user experience.')}
      </CookieConsent>
    </>
  );
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({
  loading: state.home.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);