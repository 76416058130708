import React, { useState } from "react";
import { connect } from "react-redux";
import { Image, Form, Alert } from "react-bootstrap";
import PasswordInput from "../components/inputs/PasswordInput";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { doGeneratePassword } from "../components/reducers/actions";
import { useParams } from "react-router-dom";
import blue from '../assets/img/bluelogo.jpg'

const GeneratePassword = (props) => {
  const { onGeneratePassword } = props;
  const [values, setValues] = useState({
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [requestErrors, setRequestErrors] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const { t } = useTranslation();
  let { user_id } = useParams();
  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ["confirm_password", "password"];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    if (values.password && values.password.length < 6) {
      updatedErrors.password = t("Minimum 6 characters required");
    }

    if (
      values.password &&
      values.confirm_password &&
      values.confirm_password !== values.password
    ) {
      updatedErrors.confirm_password = t(
        "Confirm password not match with password"
      );
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var data = {
      ...values,
      user_id: user_id,
      isResetPassword: props.isResetPassword,
    };
    console.log(data);
    const resp = await onGeneratePassword(data);
    if (resp && resp.data === "success") {
      props.history.push("/login");
    } else if (resp && resp.error) {
      let message = "Something went wrong. Please try again";
      if (resp.error && resp.error.data && resp.error.data.non_field_errors) {
        message = resp.error.data.non_field_errors[0];
      } else if (resp.error && resp.error.data && resp.error.data.message) {
        message = resp.error.data.message;
      }
      setRequestErrors(message);
    }
  };

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    setRequestErrors(null);
    validateValues(updatedValues);
  };

  return (
    <>
      <Header />
      <div className="container p-5">
        <div className="card o-hidden border-0 shadow-lg my-4">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <Image
                  style={{ width: "100%", height: "auto" }}
                  src={blue}
                ></Image>
              </div>
              <div className="col-lg-6">
                <div className="p-5 my-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">{t(props.title)}!</h1>
                  </div>
                  <div>
                    {requestErrors ? (
                      <Alert variant="danger">{requestErrors}</Alert>
                    ) : null}
                  </div>
                  <Form className="user" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <PasswordInput
                        placeholder={t("Password")}
                        type="text"
                        name="password"
                        error={errors.password}
                        value={values.password}
                        onChange={(e) =>
                          handleTextChange("password", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <PasswordInput
                        placeholder={t("Confirm Password")}
                        type="text"
                        name="confirm_password"
                        error={errors.confirm_password}
                        value={values.confirm_password}
                        onChange={(e) =>
                          handleTextChange("confirm_password", e.target.value)
                        }
                      />
                    </div>
                    <button
                      disabled={disableSubmit}
                      type="submit"
                      className="btn btn-primary-green btn-user btn-block mb-3"
                    >
                      {t("Submit")}
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGeneratePassword: (data) => dispatch(doGeneratePassword(data)),
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePassword);
