import React from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

const GoogleMapView = withGoogleMap((props) => {

  const { region, markerCoordinates, multiple, setMarkerCoordinates } = props;

  const marker = markerCoordinates !== null && multiple ? markerCoordinates.map((coordinate, idx) => {
    return <Marker
      key={idx}
      draggable
      position={coordinate}
      onDragEnd={(e) => {
        console.log("Drag end called");
        const newMarkerCoordinates = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        }
        setMarkerCoordinates(newMarkerCoordinates)
      }}>
      <InfoWindow>
        <div style={{ color: '#000' }}>
          {coordinate.title}
        </div>
      </InfoWindow>
    </Marker>
  }) : null;

  return (
    <GoogleMap defaultZoom={8} center={region}>
      {
        multiple ? marker : <Marker
          draggable
          position={markerCoordinates}
          onDragEnd={(e) => {
            console.log("Drag end called");
            const newMarkerCoordinates = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng()
            }
            setMarkerCoordinates(newMarkerCoordinates)
          }} />
      }
    </GoogleMap>
  )
});

export default GoogleMapView;