import React from "react";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { circularFoodsafety, getBooks } from "../../components/reducers/actions";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { saveAs } from "file-saver";

const Books = (props) => {
  const { t, i18n } = useTranslation();
  const [product, setProduct]=useState([])
  const { ongetBooks, user } = props;

  const getProduct = async () => {
    const resp = await ongetBooks();
   
    if(resp.response){
      setProduct(resp.response.Book_Food)
    }
    else{
      console.log('error')
    }
  };
  useEffect(() => {
  getProduct()
  }, []);
  useEffect(() => {
    getProduct()
    }, [user]);
    const Download = (props) => {
      const saveFile = () => {
        saveAs(
          props.data.attachment,
          props.data.file_name
        );
      };
      return (
        <div style={{width:'80px'}} className="download_btn">
          <a href={props.data.attachment} target="_blank">
            {" "}
            View
          </a>
          <b>/</b>
          <div>
            <a
              onClick={()=>{saveFile(props)}}
              style={{textDecoration:'underline',color:'blue'}}
            >
            Download
            </a>
          </div>
        </div>
      );
    };
  return (
    <div style={{marginBottom:'350px'}}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">
                {t("Books")}
              </h1>
              {/* <p className="page-header-ui-text mb-0">{t("FSDCU")}</p> */}
            </div>
          </div>
        </div>
      </header>
      <div style={{width:'55%'}} className="table_top">
        {product && <>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>S.No</th>
              <th style={{textAlign:'center'}}>{t("Books")}</th>
              {/* <th>{t("Circular Date")}</th> */}
              <th tyle={{textAlign:'center'}}>{t("Download/View")}</th>
            </tr>
          </thead>
          <tbody>
            {product.map((item, index) => (
              <tr>
                <td>{index+1}</td>
                <td>{item.title}</td>
                {/* <td>{moment(item.circular_date).format("l")}</td> */}
                <td tyle={{textAlign:'center'}}>
                  <Download data={item} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </>}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ongetBooks: (data) => dispatch(getBooks(data)),
});

const mapStateToProps = (state) => ({
  user:state.home.user
});
export default connect(mapStateToProps, mapDispatchToProps)(Books);
