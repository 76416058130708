import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const FoodFortificationDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            console.log(recordData)
        } else if (slug) {
            console.log(slug);
        }   
        // eslint-disable-next-line 
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Food Fortification Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              {/* <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr> */}
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                {/* <tr>
                  <th>Month</th>
                 <td className="text-left">{moment(records.month).format("MMMM")}</td>
                </tr> */}
                <tr>
                  <th>Training Given To</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.training_given_to, ((item) => 
                {
                  if(item.check == true)
                  return(<>
                <li>{item.name}</li> </>) }))}</td>
                </tr>
                {/* <tr>
                  <th>Number of meetings conducted during the month</th>
                   <td className="text-left">{records.meeting_during_the_month}</td>
                </tr>
                <tr>
                  <th>Number of meetings conducted upto previous month</th>
                   <td className="text-left">{records.meeting_upto_the_month}</td>
                </tr>
                <tr>
                  <th>Number of meeting conducted upto the month</th>
                   <td className="text-left">{records.meeting_previous_month}</td>
                </tr> */}
                <tr>
                  <th>Category</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.category, ((item) => {return(<><li>{item}</li> </>) }))}</td>
                </tr>
                {records.other_category && <tr>
                  <th>Category-Others</th>
                   <td className="text-left">{records.other_category}</td>
                </tr>}
                { records.no_of_fso_meetings && <tr>
                  <th>Number of FSO Meetings Conducted</th>
                   <td className="text-left">{records.no_of_fso_meetings}</td>
                </tr>}
               {records.no_of_fso_trained &&  <tr>
                  <th>Number of FSO's Participated </th>
                   <td className="text-left">{records.no_of_fso_trained}</td>
                </tr>}
               {records.no_of_fbo_meetings && <tr>
                  <th>Number of FBO Meetings Conducted </th>
                   <td className="text-left">{records.no_of_fbo_meetings}</td>
                </tr>}
                {records.no_of_fbos_training && <tr>
                  <th>Number of FBO's Participated</th>
                   <td className="text-left">{records.no_of_fbos_training}</td>
                </tr>}
                {records.no_of_interdepartmental_meetings_conducted && <tr>
                  <th>Number of Interdepartmental Meetings Conducted </th>
                   <td className="text-left">{records.no_of_interdepartmental_meetings_conducted}</td>
                </tr>}
                {records.no_of_participants_participated_interdepartmental && <tr>
                  <th>Number of Participants Participated </th>
                   <td className="text-left">{records.no_of_participants_participated_interdepartmental}</td>
                </tr>}
              {records.no_of_conusmer_meetings && <tr>
                <th>Number of Consumer Meetings Conducted </th>
                <td className="text-left">{records.no_of_conusmer_meetings}</td>
              </tr>  }            
            {records.no_of_consumer_trained &&  <tr>
                <th>Number of Consumer Participated</th>
                <td className="text-left">{records.no_of_consumer_trained}</td>
              </tr>}
              {records.lab_person_meetings &&  <tr>
                <th>Number of Lab Person Meetings Conducted</th>
                <td className="text-left">{records.lab_person_meetings}</td>
              </tr>}
              {records.lab_person_trained &&  <tr>
                <th>Number of Lab Person Trained</th>
                <td className="text-left">{records.lab_person_trained}</td>
              </tr>}
              {records.food_fortification_coverage &&  <tr>
                <th>Number of Food Fortification Coverage</th>
                <td className="text-left">{records.food_fortification_coverage}</td>
              </tr>}
              {records.meeting_during_the_month &&  <tr>
                <th>Number of Meetings conducted during the month</th>
                <td className="text-left">{records.meeting_during_the_month}</td>
              </tr>}
              {records.participant_during_the_month &&  <tr>
                <th>Number of participants participated during the month </th>
                <td className="text-left">{records.participant_during_the_month}</td>
              </tr>}
              
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodFortificationDetails);