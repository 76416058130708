import React, { useState, useRef, useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import OtpInputComponent from '../components/inputs/OtpInputComponent';
import Header from '../components/Header';
import { reSendOtp, doOtpVerification, doDirectLogin, getMasterData, getDashboard } from '../components/reducers/actions';
import toast from 'react-toast-notification';
import blue from '../assets/img/bluelogo.jpg'


const OTPVerfication = (props) => {

  const refButton = useRef();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [values, setValues] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('9487048147');

  useEffect(() => {
    if (props.history && props.history.location.state) {
      const phone_number = props.history.location.state.phone_number;
      console.log(props.history.location.state.phone_number)
      setPhoneNumber(phone_number);
    }
  }, [props])

  const validateValues = (values) => {
    if (!values.otp || values.otp.length !== 6) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }

  const resendOTP = async (phoneNumber) => {
    console.log("Resend OTP called");
    const data = { phone_number: phoneNumber };
    const resp = await props.onResendOtp(data);
    if (resp && resp.response) {
      console.log(resp.response);
    }
    else {
      console.log(resp.error);
    }
  }

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    validateValues(updatedValues);
  }

  const handleSubmitButton = async (e) => {
    e.preventDefault();
    const updatedValues = { ...values, phone_number: phoneNumber, 'login': true };
    const resp = await props.onOtpVerify(updatedValues);
    if (resp && resp.response) {
      const resp = await props.onDirectLogin(values);
      if (resp.response) {
        toast.success('OTP verified successfully.');
        props.onGetMasterData();
        const data={}
        props.ongetDashhboard(data);
        props.history.push('/app')
      }
      else {
        props.history.push('/login/')
      }
    }
    else {
      console.log(resp.error);
    }
  }

  return (
    <>
      <Header />
      <div className="container p-5">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <Image style={{ width: '100%', height: 'auto' }} src={blue}>
                </Image>
              </div>
              <div className="col-lg-6">
                <div className="p-5 my-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Verify your OTP</h1>
                    <p>We have sent an OTP to xxxxxx{phoneNumber.substr(phoneNumber.length - 4)}</p>
                  </div>
                  <form onSubmit={handleSubmitButton} className="user">
                    <div className="form-group">
                      <OtpInputComponent
                        values={values}
                        setValues={handleTextChange}
                      />
                    </div>
                    <button type="submit" ref={refButton} disabled={disableSubmit} className="btn btn-primary btn-user btn-block">
                      Submit
                  					</button>
                    <hr />
                    <div className="text-center">
                      <p>Didn't receive the code?</p>
                      <Link className="small" onClick={() => resendOTP(phoneNumber)} to="#">Resend OTP</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

OTPVerfication.propsType = {
  onRegister: PropTypes.func,
};


const mapDispatchToProps = dispatch => ({
  onResendOtp: (data) => dispatch(reSendOtp(data)),
  onOtpVerify: (data) => dispatch(doOtpVerification(data)),
  onGetMasterData: () => dispatch(getMasterData()),
  ongetDashhboard:(data)=>dispatch(getDashboard(data)),
  onDirectLogin: (data) => dispatch(doDirectLogin(data)),
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(OTPVerfication);