import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createNoticeIssuedReports, editNoticeIssuedReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from "moment";

const FBO = [
    {id:"0", name:"Retailer"},
    {id:"1", name:"Distributor"},
    {id:"2", name:"Manufacturer"},
    {id:"3", name:"Transporter"},
    {id:"4", name:"Wholesaler"},
   ]
const sec = [
    {id:"0", name:"Section-32"},
    {id:"1", name:"Section-55"},
    {id:"2", name:"Section-69"},
    {id:"3", name:"Others"}
]
const Notice = [
    {id:"0", name:"Violation Rectified"},
    {id:"1", name:"Notice Recommended To DO"}
]

const NoticeIssuedReport = (props) => {
    const { districts, user,wards, onCreateNoticeIssuedReports,wardsCategory,ongetDist, onEditNoticeIssuedReports } = props;
    const [values, setValues] = useState({
       
        'area_name':'',
        'area_code':'',
        'district' : '', 
        'date':'',
        'name_of_the_fbo':'',
        'address_of_the_fbo':'',
        'name_of_the_shop':'',
        'license_registration':'',
        'name_of_the_notice_issued':'',
        'address_of_the_notice_issued':'',
        'Date_of_notice_issued':'',
        'time_limit':'',
        'reply_received':'',
        'reinspection_date':'',
        'fbo_type':'',
        'notice_issued_under':'',
        'default_rectified_notice_to_do':'',
        'recommended_to_do_date':'',
        'action_taken':'',
        'action_taken_date':'',
        'default_rectified':'',
        'remarks':''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [field, setField] = useState(false);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [ 
            'area_name',
            'area_code',
            'district', 
            'date',
            'fbo_type',
            'name_of_the_fbo',
            'address_of_the_fbo',
            'name_of_the_shop',
            'license_registration',
            'notice_issued_under',
            'name_of_the_notice_issued',
            'address_of_the_notice_issued',
            'Date_of_notice_issued',
            'time_limit',
            'reply_received',
            'reinspection_date',
            'default_rectified_notice_to_do',
            ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditNoticeIssuedReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/notice-issued-list/`);
            };

        } else {       
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateNoticeIssuedReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    const handleBlur = ()=>{
        if(values.activities === "9"){
                setField(true)
                console.log("set field");
        }
        else setField(false)
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Notice Issued Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    type="text"
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange("date", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fbo_type"
                            >FBO Category *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`FBO Category`}
                                    type="text"
                                    name="fbo_type"
                                    id="fbo_type"
                                    action="dropdown"
                                    items={FBO}
                                    value={values.fbo_type}
                                    error={errors.fbo_type}
                                    onChange={(text) => handleTextChange("fbo_type", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_shop"
                            >Name Of the Incharge *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Incharge`}
                                    type="text"
                                    name="name_of_the_shop"
                                    id="name_of_the_shop"
                                    value={values.name_of_the_shop}
                                    error={errors.name_of_the_shop}
                                    onChange={(e) => handleTextChange("name_of_the_shop", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_fbo"
                            >Name Of the Owner/Partner/Person In FBO Premisis *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name Of the Owner/Partner/Person In FBO Premisis`}
                                    type="text"
                                    name="name_of_the_fbo"
                                    id="name_of_the_fbo"
                                    value={values.name_of_the_fbo}
                                    error={errors.name_of_the_fbo}
                                    onChange={(e) => handleTextChange("name_of_the_fbo", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_fbo"
                            >Address Of the FBO Premisis *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the FBO premisis`}
                                    type="text"
                                    name="address_of_the_fbo"
                                    id="address_of_the_fbo"
                                    value={values.address_of_the_fbo}
                                    error={errors.address_of_the_fbo}
                                    onChange={(e) => handleTextChange("address_of_the_fbo", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration"
                            >License/Registration Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`License/Registration Number`}
                                    type="text"
                                    name="license_registration"
                                    id="license_registration"
                                    value={values.license_registration}
                                    error={errors.license_registration}
                                    onChange={(e) => handleTextChange("license_registration", e.target.value)}
                                />
                            </div>
                        </div> 
                        
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="notice_issued_under"
                            >Notice Issued Under Section *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Notice Issued Under Section`}
                                    type="number"
                                    name="notice_issued_under"
                                    id="notice_issued_under"
                                    items={sec}
                                    action="dropdown"
                                    value={values.notice_issued_under}
                                    error={errors.notice_issued_under}
                                    onChange={(text) => handleTextChange("notice_issued_under", text)}
                                />
                            </div>
                        </div> 
                        {values && values.notice_issued_under ? (<div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_notice_issued"
                            >Name Of The Notice Issued *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the notice issued`}
                                    type="text"
                                    name="name_of_the_notice_issued"
                                    id="name_of_the_notice_issued"
                                    value={values.name_of_the_notice_issued}
                                    error={errors.name_of_the_notice_issued}
                                    onChange={(e) => handleTextChange("name_of_the_notice_issued", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_notice_issued"
                            >Address Of The Notice Issued *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address Of the notice issued`}
                                    type="text"
                                    name="address_of_the_notice_issued"
                                    id="address_of_the_notice_issued"
                                    value={values.address_of_the_notice_issued}
                                    error={errors.address_of_the_notice_issued}
                                    onChange={(e) => handleTextChange("address_of_the_notice_issued", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="time_limit"
                            >Time Limit Issued For Reply *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Time limit Issued for reply`}
                                    type="text"
                                    name="time_limit"
                                    id="time_limit"
                                    value={values.time_limit}
                                    error={errors.time_limit}
                                    onChange={(e) => handleTextChange("time_limit", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Date_of_notice_issued"
                            >Date Of Notice Issued *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date Of Notice Issued`}
                                    type="text"
                                    name="Date_of_notice_issued"
                                    id="Date_of_notice_issued"
                                    value={values.Date_of_notice_issued}
                                    error={errors.Date_of_notice_issued}
                                    onChangeText={(text) => handleTextChange("Date_of_notice_issued", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="reply_received"
                            >Reply Received On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Reply Received`}
                                    type="text"
                                    name="reply_received"
                                    id="reply_received"
                                    value={values.reply_received}
                                    error={errors.reply_received}
                                    onChangeText={(text) => handleTextChange("reply_received", text)}
                                />
                            </div>
                        </div> 
                        
                         </div> ): null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="reinspection_date"
                            >Reinspection Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Reinspection Date`}
                                   name="reinspection_date"
                                    id="reinspection_date"
                                    value={values.reinspection_date}
                                    error={errors.reinspection_date}
                                    onChangeText={(text) => handleTextChange("reinspection_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="default_rectified_notice_to_do"
                            >Whether Violations Rectified *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Select`}
                                    type="text"
                                    name="default_rectified_notice_to_do"
                                    id="default_rectified_notice_to_do"
                                    items={Notice}
                                    action="dropdown"
                                    value={values.default_rectified_notice_to_do}
                                    error={errors.default_rectified_notice_to_do}
                                    onChange={(text) => handleTextChange("default_rectified_notice_to_do", text)}
                                />
                            </div>
                        </div> 
                       {values && values.default_rectified_notice_to_do.includes('Notice Recommended To DO')? <>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="recommended_to_do_date"
                            >Recommended to DO On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Recommended to DO on`}
                                    type="text"
                                    name="recommended_to_do_date"
                                    id="recommended_to_do_date"
                                    value={values.recommended_to_do_date}
                                    error={errors.recommended_to_do_date}
                                    onChangeText={(text) => handleTextChange("recommended_to_do_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="action_taken"
                            >Action Taken: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Action Taken`}
                                    type="text"
                                    name="action_taken"
                                    id="action_taken"
                                    value={values.action_taken}
                                    error={errors.action_taken}
                                    onChange={(e) => handleTextChange("action_taken", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="action_taken_date"
                            >Action Taken Date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Action taken Date`}
                                    type="text"
                                    name="action_taken_date"
                                    id="action_taken_date"
                                    value={values.action_taken_date}
                                    error={errors.action_taken_date}
                                    onChangeText={(text) => handleTextChange("action_taken_date", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="default_rectified"
                            >Violation Rectified Date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Violation rectified`}
                                    type="text"
                                    name="default_rectified"
                                    id="default_rectified"
                                    value={values.default_rectified}
                                    error={errors.default_rectified}
                                    onChangeText={(text) => handleTextChange("default_rectified", text)}
                                />
                            </div>
                        </div>
                       
                       </>: null} 
                       <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    type="text"
                                    name="remarks"
                                    id="remarks"
                                    value={values.remarks}
                                    error={errors.remarks}
                                    onChange={(e) => handleTextChange("remarks", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateNoticeIssuedReports: (data) => dispatch(createNoticeIssuedReports(data)),
    onEditNoticeIssuedReports: (data, record_id) => dispatch(editNoticeIssuedReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(NoticeIssuedReport);