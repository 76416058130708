import React from 'react';
import Header from '../../components/Header';
import logo from '../../assets/img/logo.png'
import whatsapp from '../../assets/img/whatsapp.png'
import mail from '../../assets/img/e-mail.png'
import MobileApp from '../../assets/img/android-logo.png'
import ios from '../../assets/img/apple.png'
import Complaint from '../../assets/img/web-link.png'
import othher from '../../assets/img/othher.jpg'
import { useTranslation } from "react-i18next";


const Redressal = (props)=>{
  const { t, i18n } = useTranslation();

    const Card =(props)=> {
        
          return(
              
               props.btn2 ? <>
               <div className='red_apps'>
               <h4>{t("Consumer Mobile App")}</h4>
               <div className='row_img'>
                 <div onClick={()=>window.open(props.url)} className='red_img'>
                  <img src={props.img} />
                 </div>
                 <div onClick={()=>window.open(props.url2)} className='red_img'>
                 <img  src={props.img2} />
                 </div>
                 </div>
               </div>
               </> :
               <>
               <div onClick={()=>window.open(props.url)} className="red_card">
               <img src={props.img} />
                <div className="red_card-body">
                <h5>{props.title}</h5>
                <h5>{props.data}</h5>
                </div>
                </div>
              </>
          )
      }
      
    return(
        <div style={{ marginBottom:'100px'}} >
           <Header/>
           <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">
                {t("Raise Your Concern")}
              </h1>
              <p className="page-header-ui-text mb-0">
                {t("Queries, Complaints, Requests and Suggestions")}
              </p>
            </div>
          </div>
        </div>
      </header>
           <div className="red_container">
             <div className='red_cards'>
              
                  <Card
                img={MobileApp}
                img2={ios}
                title={t('Mobile App')}
                data={null}
                button='Get Android App' 
                url="https://play.google.com/store/apps/details?id=com.fsopublicapp"
                url2="https://apps.apple.com/by/app/tn-food-safety-consumer-app/id1565948095"
                btn2={true}
                />
                <Card
                img={mail}
                title={t('Email')}
                button='Draft a Mail'
                data="unavupukar@gmail.com"
                url="mailto:unavupukar@gmail.com"
                />
            </div>  
            <div className='red_cards'>
               <Card
                img={Complaint}
                title={t('website')}
                button='Raise Your Complaint' 
                data="https://foodsafety.tn.gov.in/"
                url="/login"
                />
               <Card
                img={whatsapp}
                title={t('Whatsapp Complaint')}
                button="Open Whatsapp"
                data="+91-9444042322"
                url="https://api.whatsapp.com/send?phone=919444042322"
                />
            </div>  
            <div style={{margin:'0 auto'}} className='red_cards'>
               <Card
                img={othher}
                title={t('For other than Tamil Nadu Complaints')}
                button={t('For other than Tamil Nadu Complaints')}
                data=""
                url="https://play.google.com/store/apps/details?id=safety.connect.food"
                />
             </div>
           </div>
        </div>
    )
}


export default Redressal;

