import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getDashboard,
  getDashboarddistrict,
  getDashboardSucces,
  getFsoOfficers,
  setLoading,
} from "../components/reducers/actions";
import DropDownInput from "../components/inputs/DropDownInput";
import LoadingOverlay from "react-loading-overlay";
import FBOPieChart from "./Charts/FBOPieChart";
import ActionTakenBarChart from "./Charts/ActionTakenBarChart";
import ClosedLineChart from "./Charts/ClosedLineChart";
import ReallocationLineChart from "./Charts/ReallocationLineChart";
import FoodIssuePieChart from "./Charts/FoodIssuePieChart";
import FSOBarChart from "./Charts/FSOBarChart";
import DistrictBarChart from "./Charts/DistrictBarChart";
import ClosureComplaintBarChart from "./Charts/ClosureComplaintBarChart";
import Datepicker from "../components/inputs/Datepicker";
import moment from "moment";

const Dashboard = (props) => {
  const {
    districts,
    onGetDashboard,
    user,
    onGetFsoOfficers,
    fsoOfficers,
    onLoading,
    dashboard,
    onGetDashboarddist,
    onGetDash
  } = props;
  const [values, setValues] = useState({});
  const [show, setShow]=useState(false);
  const [searchFilter, setSearchFilter] = useState({});
  const [loading, setLoading] = useState(true);
  const [char, setChar] = useState(true)
  useEffect(() => {
    if (user && user.user_types && user.user_types.includes("do")) {
      const getFsoList = async () => {
        await onGetFsoOfficers({});
      };
      getFsoList();
    }
    const dashboardData = async () => {
      setChar(false)
      const resp = await onGetDashboard({});
      if (resp && resp.response) {
        setTimeout(()=>{
          setChar(true)
          setLoading(false)
         },1000) 
        const data = resp.response;
        setValues(data);
        console.log(data);
        onLoading(false);
      }
    };
    dashboardData();
    // eslint-disable-next-line
  }, []);

  const getDs=async(id)=>{
   setChar(false)
   await onGetDash({})
    setValues([])
    setShow(true)
    let req_filter={district_id__in:''}
    req_filter.district_id__in=[id]
    const data={"req_filter":req_filter} 
    const resp = await onGetDashboarddist(data);
    if (resp && resp.response) {
     setTimeout(()=>{
      setChar(true)
      setLoading(false)
     },1000) 
     
      const data = resp.response.list_data;
      console.log(data);
      setValues({...values,list_data:data});
    }
  }

  const handleTextChange = (key, value) => {
    const updatedValues = { ...searchFilter, [key]: value };
    setSearchFilter(updatedValues);
  };

  const handleClick = async() => {
    setChar(false)

    setLoading(true)
    console.log(searchFilter)
    if (searchFilter.district && searchFilter.district?.length !==0) {
      const req_filter={};
      const data={req_filter}
      req_filter.district_id__in = [searchFilter.district]
      if(searchFilter.from_date && searchFilter.to_date){
        var dt = moment(searchFilter.to_date).format('DD-MM-YYYY')
        var new_date = moment(dt, "DD-MM-YYYY").add(1,'days').format("YYYY-MM-DD");
        // req_filter.created_at__range=[moment(searchFilter.from_date).format('YYYY-MM-DD'), new_date ]
        data.from_date=moment(searchFilter.from_date).format('YYYY-MM-DD')
        data.to_date=new_date
      }
      // async function fetchData() {
        
        const resp = await onGetDashboarddist(data);
        if (resp && resp.response) {
          setTimeout(()=>{
            setChar(true)
            setLoading(false)
           },1000) 
          
          const data = resp.response;
          console.log(data);
          setValues(data);
          setShow(true)

        }
      // }
      // fetchData();
    } else if (searchFilter.fso) {
      const req_filter={};
      const data={req_filter}
      req_filter.assigned_to =  JSON.stringify(searchFilter.fso) 
      if(searchFilter.from_date && searchFilter.to_date){
        var dt = moment(searchFilter.to_date).format('DD-MM-YYYY')
      var new_date = moment(dt, "DD-MM-YYYY").add(1,'days').format("YYYY-MM-DD");
        // req_filter.created_at__range=[moment(searchFilter.from_date).format('YYYY-MM-DD'), new_date ]
        data.from_date=moment(searchFilter.from_date).format('YYYY-MM-DD')
        data.to_date=new_date
      }

      console.log(data)
      // async function fetchData() {
        const resp = await onGetDashboard(data);
        if (resp && resp.response) {
          setTimeout(()=>{
            setChar(true)
            setLoading(false)
           },1000) 
         
          const data = resp.response;
          console.log(data);
          setValues(data);
        }
      }
    //   fetchData();
    // }
    else if(searchFilter.from_date && searchFilter.to_date){
      var dt = moment(searchFilter.to_date).format('DD-MM-YYYY')
      var new_date = moment(dt, "DD-MM-YYYY").add(1,'days').format("YYYY-MM-DD");
      const req_filter={}
      const data ={req_filter}
      // req_filter.created_at__range=[moment(searchFilter.from_date).format('YYYY-MM-DD'), new_date ]
      data.from_date=moment(searchFilter.from_date).format('YYYY-MM-DD')
      data.to_date=new_date
      console.log(data)
      const resp = await onGetDashboard(data);
        if (resp && resp.response) {
          setTimeout(()=>{
            setChar(true)
            setLoading(false)
           },1000) 
         
          const data = resp.response;
          console.log(data);
          setValues(data);
        }
    }
  };

  return (
    <div className="container-fluid">
      <div className="col-sm-12" style={{ padding: 0, margin: 0 }}>
        <LoadingOverlay
          active={loading}
          className="secondary__loader"
          spinner
          text="Loading..."
        />
        <div className="row">
          <div className="col-sm-3">
            <h1>Dashboard</h1>
          </div>
          {user && user.user_types && user.user_types.includes("ho") ? (<>
            <div className="col-sm-2">
              <Datepicker
                placeholder={ `Complaint From`}
                name="from_date"
                maximumDate={moment().toDate()}
                value={searchFilter.from_date}
                onChangeText={(text) => handleTextChange("from_date", text)}
              />
            </div>  <div className="col-sm-2">
              <Datepicker
                placeholder={`Complaint To `}
                name="to_date"
                maximumDate={moment().toDate()}
                value={searchFilter.to_date}
                onChangeText={(text) => handleTextChange("to_date", text)}
              />
            </div> 
            <div className="col-sm-3">
              <DropDownInput
                placeholder={`Select District`}
                type="text"
                name="district"
                items={districts}
                value={searchFilter.district}
                // action="district"
                onChange={(value) => handleTextChange("district", value)}
              />
            </div>
            
            </>
          ) : user && user.user_types && user.user_types.includes("do") ? (<>
            <div className="col-sm-2">
              <Datepicker
                placeholder={`Complaint From `}
                type="text"
                name="from_date"
                maximumDate={moment().toDate()}
                value={searchFilter.from_date }
                onChangeText={(text) => handleTextChange("from_date", text)}
              />
            </div>  <div className="col-sm-2">

              <Datepicker
                placeholder={`Complaint To`}
                type="text"
                name="to_date"
                maximumDate={moment().toDate()}
                value={searchFilter.to_date}
                onChangeText={(text) => handleTextChange("to_date", text)}
              />
            </div> 
            <div className="col-sm-3">
              <DropDownInput
                placeholder={`Select FSO`}
                type="text"
                name="fso"
                items={fsoOfficers}
                value={searchFilter.fso}
                action="fsos"
                onChange={(text) => handleTextChange("fso", text)}
              />
            </div>    
            </>
          ) : null}
          <div className="col-sm-2">
            <button
              type="button"
              className="btn btn-success ml-3"
              onClick={handleClick}
            >
              Search
            </button>
          </div>
        </div>
        <div className="row">
          {values && values.list_data ? (
            <div className="container-fluid">
              <div className="card-deck">
              <div className="card bg-success">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {values.overall_complaints
                        ? values.overall_complaints
                        : "0"}
                    </button>
                    <p className="text text-light">Total complaints</p>
                  </div>
                </div>
                <div className="card bg-info">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {values.all_open_complaints
                        ? values.all_open_complaints
                        : "0"}
                    </button>
                    <p className="text text-light">All open complaints</p>
                  </div>
                </div>
                <div className="card bg-success">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {values.pending_allocation
                        ? values.pending_allocation
                        : "0"}
                    </button>
                    <p className="text text-light">Pending allocation</p>
                  </div>
                </div>
                <div className="card bg-info">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {values.complaints_awaiting_inspection
                        ? values.complaints_awaiting_inspection
                        : "0"}
                    </button>
                    <p className="text text-light">
                      Complaints Awaiting Inspection
                    </p>
                  </div>
                </div>
                <div className="card bg-success">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {values.complaints_awaiting_approval
                        ? values.complaints_awaiting_approval
                        : "0"}
                    </button>
                    <p className="text text-light">
                      Complaints Awaiting Approval
                    </p>
                  </div>
                </div>
                <div className="card bg-info">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {values.closed_complaints
                        ? values.closed_complaints
                        : "0"}
                    </button>
                    <p className="text text-light">
                      Complaints closed this month
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row" style={{ marginTop: 20 }}>
        {char&& <div className="col-sm-4">
            <FBOPieChart
              data={
                dashboard && dashboard.chart_data
                  ? dashboard.chart_data.trader_type
                  : []
              }
            />
          </div>}
          {char&&<div className="col-sm-4">
            <FoodIssuePieChart
              data={
                dashboard && dashboard.chart_data
                  ? dashboard.chart_data.issue_of_food
                  : []
              }
            />
          </div>}
          {char&& <div className="col-sm-4">
            <ClosureComplaintBarChart
              data={
                dashboard && dashboard.chart_data
                  ? dashboard.chart_data.closure_complaints_by_days
                  : []
              }
            />
          </div>}
        </div>
        <div className="row" style={{ marginTop: 20 }}>
        {char&&<div className="col-sm-4">
            <ClosedLineChart
              data={
                dashboard && dashboard.chart_data
                  ? dashboard.chart_data.closed_complaints_by_date
                  : []
              }
            />
          </div>}
          {char&&<div className="col-sm-4">
            <ReallocationLineChart
              data={
                dashboard && dashboard.chart_data
                  ? dashboard.chart_data.reallocated_by_date
                  : []
              }
            />
          </div>}
          {char&&<div className="col-sm-4">
            <ActionTakenBarChart
              data={
                dashboard && dashboard.chart_data
                  ? dashboard.chart_data.closure_status_by_action_taken
                  : []
              }
            />
          </div>}
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          {user && user.user_types && user.user_types.includes("ho") ? (
            <div className="col-sm-12">
            {char&& dashboard && dashboard?.chart_data
                    &&dashboard?.chart_data?.complaint_status_by_district?.length>0 &&
                     <DistrictBarChart
                data={
                  dashboard && dashboard.chart_data
                    ? dashboard.chart_data.complaint_status_by_district
                    : []
                }
              />}
              { char&&dashboard && dashboard?.chart_data&&dashboard?.chart_data?.complaint_status_by_fso?.length>0&&
                <FSOBarChart
                data={
                  dashboard && dashboard.chart_data
                    ? dashboard.chart_data.complaint_status_by_fso
                    : []
                }
              />
              }
            </div>
          ) : user && user.user_types && user.user_types.includes("do") ? (
            <div className="col-sm-12">
              <FSOBarChart
                data={
                  dashboard && dashboard.chart_data
                    ? dashboard.chart_data.complaint_status_by_fso
                    : []
                }
              />
            </div>
          ) : null}
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          {values && values.list_data ? (
            <div className="container-fluid">
              <table className="table table-responsive table-hover table-bordered">
                <thead>
                 {show?<tr>
                  <th>Food Safety Officer</th>
                  <th>All Open Complaints</th>
                    <th>Pending allocation</th>
                    <th>Complaints awaiting inspection</th>
                    <th>Complaints awaiting approval</th>
                    <th>Complaints closed this month</th>
                 </tr>: <tr className="bg-secondary text-light">
                    {user &&
                    user.user_types &&
                    user.user_types.includes("ho") ? (
                      <th>District</th>
                    ) : user &&
                      user.user_types &&
                      user.user_types.includes("do") ? (
                      <th>Food Safety Officer</th>
                    ) : null}
                    <th>All Open Complaints</th>
                    <th>Pending allocation</th>
                    <th>Complaints awaiting inspection</th>
                    <th>Complaints awaiting approval</th>
                    <th>Complaints closed this month</th>
                  </tr>}
                </thead>
                {show?
                <tbody>
                  { values&&values.list_data&&values.list_data.map((item) => (
                        <tr>
                          <td>{item?.fso.name}</td>
                          <td>{item.all_open_complaints}</td>
                          <td>{item.pending_allocation}</td>
                          <td>{item.complaints_awaiting_inspection}</td>
                          <td>{item.complaints_awaiting_approval}</td>
                          <td>{item.closed_complaints}</td>
                        </tr>
                      ))}
                </tbody>:
                 values && values.list_data ? (
                  user && user.user_types && user.user_types.includes("ho") ? (
                    <tbody>
                      {values.list_data.map((item) => (
                        <tr>
                          <td onClick={()=>getDs(item?.district?.id)}>{item?.district?.name}</td>
                          <td>{item.all_open_complaints}</td>
                          <td>{item.pending_allocation}</td>
                          <td>{item.complaints_awaiting_inspection}</td>
                          <td>{item.complaints_awaiting_approval}</td>
                          <td>{item.closed_complaints}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : user &&
                    user.user_types &&
                    user.user_types.includes("do") ? (
                    <tbody>
                      {values.list_data.map((item) => (
                        <tr>
                          <td>{item.fso.name}</td>
                          <td>{item.all_open_complaints}</td>
                          <td>{item.pending_allocation}</td>
                          <td>{item.complaints_awaiting_inspection}</td>
                          <td>{item.complaints_awaiting_approval}</td>
                          <td>{item.closed_complaints}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : null
                ) : (
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan="6">
                        No data available in table
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetDashboard: (data) => dispatch(getDashboard(data)),
  onGetDashboarddist: (data) => dispatch(getDashboarddistrict(data)),
  onGetDash: (data) => dispatch(getDashboardSucces(data)),
  onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
  onLoading: (data) => dispatch(setLoading(data)),
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  fsoOfficers: state.home.fsoOfficers,
  dashboard: state.home.dashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
