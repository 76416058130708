import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createRipeningMangoesReports, editRipeningMangoesReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment'

const Fruits=[
    {id:"0", name:"Mangoes"},
    {id:"1", name:"Banana"},
    {id:"2", name:"Others"},
]
const Sample_type=[
    {id:"0", name:"Surveillance"},
    {id:"1", name:"Enforcement"},
]
const RipeningMangoesReports = (props) => {
    const { districts, user,wards, onCreateRipeningMangoesReports,wardsCategory,ongetDist, onEditRipeningMangoesReports } = props;
    const [values, setValues] = useState({
        'month' : '',
        'area_name':'',
        'area_code':'',
        'type_of_fruits':'',
        'other_type_of_fruits':'',
        'no_of_godowns_storage_points_shops_inspected':'',
        'no_of_godowns_ripening_mangoes_with_carbide_gas':'',
        'quantity_of_mangoes_seized':'',
        'approximate_value':'',
        'sample_type':'',
        'no_of_sample_lifted':'',
        'quantity_of_carbide_stones_found_and_seized':'',
        'action_taken':'',
        'district' : '',
        'date' : '',
        'upload_docs':'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
           'area_code',
           'area_name',
            'sample_type',
           'type_of_fruits',
           'no_of_godowns_storage_points_shops_inspected',
           'no_of_godowns_ripening_mangoes_with_carbide_gas',
           'quantity_of_mangoes_seized', 
           'approximate_value',
           'quantity_of_carbide_stones_found_and_seized',
           'action_taken',
           'district',
            'date'
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {   
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            console.log(data);
            const resp = await onEditRipeningMangoesReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/ripening-mangoes-list/`);
            };

        } else {    
            const data = { ...values}
            console.log('::', data);
            setDisableSubmit(true);
            const resp = await onCreateRipeningMangoesReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Ripening Of Fruits Reports</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>     
                         
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                   value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_fruits"
                            >Type of fruits *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of fruits`}
                                    type="text"
                                    name="type_of_fruits"
                                    id="type_of_fruits"
                                    action="dropdown"
                                    value={values.type_of_fruits}
                                    error={errors.type_of_fruits}
                                    items={Fruits}
                                    onChange={(text) => handleTextChange("type_of_fruits", text)}
                                />
                            </div>
                        </div>   
                          {values && values.type_of_fruits.includes('Others') ? (
                              <div className="form-group row">
                              <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_fruits"
                              ></label>
                              <div className="col-sm-8">
                                  <TextInput
                                      placeholder={`Type of Fruits`}
                                      type="text"
                                      name="other_type_of_fruits"
                                      id="other_type_of_fruits"
                                      value={values.other_type_of_fruits}
                                      error={errors.other_type_of_fruits}
                                      onChange={(e) => handleTextChange("other_type_of_fruits", e.target.value)}
                                  />
                              </div>
                          </div>
                          ): null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_godowns_storage_points_shops_inspected"
                            >Number of Godown's, storage points, FBO Premises etc Inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total Number of Inspection`}
                                    type="number"
                                    name="no_of_godowns_storage_points_shops_inspected"
                                    id="no_of_godowns_storage_points_shops_inspected"
                                    value={values.no_of_godowns_storage_points_shops_inspected}
                                    error={errors.no_of_godowns_storage_points_shops_inspected}
                                    onChange={(e) => handleTextChange("no_of_godowns_storage_points_shops_inspected", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_godowns_ripening_mangoes_with_carbide_gas"
                            >Number of Godowns, storage points, shops found using carbide stone/Ethylene liquid for ripening of Fruits *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of shop(s) found`}
                                    type="number"
                                    name="no_of_godowns_ripening_mangoes_with_carbide_gas"
                                    id="no_of_godowns_ripening_mangoes_with_carbide_gas"
                                    value={values.no_of_godowns_ripening_mangoes_with_carbide_gas}
                                    error={errors.no_of_godowns_ripening_mangoes_with_carbide_gas}
                                    onChange={(e) => handleTextChange("no_of_godowns_ripening_mangoes_with_carbide_gas", e.target.value)}
                                />
                            </div>
                        </div>                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_type"
                            >Sample Type *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample Type`}
                                    type="number"
                                    name="sample_type"
                                    id="sample_type"
                                    action="dropdown"
                                    items={Sample_type}
                                    value={values.sample_type}
                                    error={errors.sample_type}
                                    onChange={(text) =>handleTextChange("sample_type", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_sample_lifted"
                            >Number Of Sample Lifted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of sample lifted`}
                                    type="number"
                                    name="no_of_sample_lifted"
                                    id="no_of_sample_lifted"
                                    value={values.no_of_sample_lifted}
                                    error={errors.no_of_sample_lifted}
                                    onChange={(e) =>handleTextChange("no_of_sample_lifted", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="quantity_of_mangoes_seized"
                            >Quantity Of Fruits Seized (in Kg's) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Quantity of fruits seized`}
                                    type="number"
                                    name="quantity_of_mangoes_seized"
                                    id="quantity_of_mangoes_seized"
                                    value={values.quantity_of_mangoes_seized}
                                    error={errors.quantity_of_mangoes_seized}
                                    onChange={(e) =>handleTextChange("quantity_of_mangoes_seized", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="quantity_of_carbide_stones_found_and_seized"
                            >Quantity of artificial ripening with chemicals Seized (in Kg's) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Quantity of artificial ripening with chemicals`}
                                    type="number"
                                    name="quantity_of_carbide_stones_found_and_seized"
                                    id="quantity_of_carbide_stones_found_and_seized"
                                    value={values.quantity_of_carbide_stones_found_and_seized}
                                    error={errors.quantity_of_carbide_stones_found_and_seized}
                                    onChange={(e) => handleTextChange("quantity_of_carbide_stones_found_and_seized", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="approximate_value"
                            >Approximate value (in RS) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Approximate value`}
                                    type="number"
                                    name="approximate_value"
                                    id="approximate_value"
                                    value={values.approximate_value}
                                    error={errors.approximate_value}
                                    onChange={(e) => handleTextChange("approximate_value", e.target.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="action_taken"
                            >Action Taken *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Action taken`}
                                    type="text"
                                    name="action_taken"
                                    id="action_taken"
                                    value={values.action_taken}
                                    error={errors.action_taken}
                                    onChange={(e) => handleTextChange("action_taken", e.target.value)}
                                />
                            </div>
                        </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_docs"
                            >Upload Documents: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_docs"
                                    error={errors.upload_docs}
                                    onChange={(e) =>handleTextChange("upload_docs", e.target.files[0])}
                                />
                            </div>
                        </div>            
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                 disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateRipeningMangoesReports: (data) => dispatch(createRipeningMangoesReports(data)),
    onEditRipeningMangoesReports: (data, record_id) => dispatch(editRipeningMangoesReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),


});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(RipeningMangoesReports);