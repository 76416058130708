import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createAdjudicationReports, editAdjudicationReports } from "../../components/reducers/actions"
import Datepicker from '../../components/inputs/Datepicker'
import TextAreaInput from "../../components/inputs/TextAreaInput";
import { useParams } from "react-router-dom";
import moment from "moment";



const option=[
    {id:"1", name:"Convicted with penalty"},
    {id:"2", name:"Acquitted"}
]
const Penalty = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const Offence = [
    {id:"0", name:"misbranded"},
    {id:"1", name:"substranded"},
    {id:"2", name:"others"}
]
const Types = [
    {id:"Retailer", name:"Retailer" },
    {id:"Distributor", name:"Distributor"},
    {id:"Wholesaler", name:"Wholesaler"},
    {id:"Transporter", name:"Transporter"},
    {id:"Manufacturer", name:"Manufacturer"},
    {id:"Others", name:"Others"},
   ]
const AdjudicationReports = (props) => {
    const { districts, user,wards,wardsCategory, onCreateAdjudicationReports, onEditAdjudicationReports } = props;
    const [values, setValues] = useState({
        'case_no' : '',
        'area_name':'',
        'area_code':'',
        'date':'',
        'type_of_respondent':'',
        'name_of_petitioner_fso_do':'',
        'address_of_petitioner_fso_do':'',
        'name_of_respondent_fbo':'',
        'address_of_respondent_fbo':'',
        'nature_of_offence':'',
        'case_history' :'',
        'district' : '',
        'section':'',
        'other_sample_report':'',
        'other_type_of_respondent':'',
        'sample_report':'',
        'other_nature_of_offence':'',
        'penalty_amount':'',
        'judgement_status':'',
        'sample_number':'',
        'penalty_paid':'',
        'upload_receipt':'',
        'petition_filed':'',
        'upload_petiton':'',
        'where_petition_filed':'',

        });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'case_no',
        'area_name',
        'area_code',
        'date',
        'type_of_respondent',
        'name_of_petitioner_fso_do',
        'address_of_petitioner_fso_do',
        'name_of_respondent_fbo',
        'address_of_respondent_fbo',
        'nature_of_offence',
        'date_of_launched',
        'date_of_1st_hearing',
        'date_of_next_hearing',
        'case_history',
        'district',
        'section',
        'date_of_case_filing',
        'sample_report',
        'date_of_sample_lifted',
        'judgement_status',
        
      
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditAdjudicationReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push(`/app/display-reports/adjudication-case-list`);
            };
        } else {       
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            console.log(data)
            const resp = await onCreateAdjudicationReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Civil Case Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div> 
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name `}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>        
                              
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_sample_lifted"
                            >Date of sample lifted *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of sample lifted`}
                                    name="date_of_sample_lifted"
                                    id="date_of_sample_lifted"
                                    value={values.date_of_sample_lifted}
                                    error={errors.date_of_sample_lifted}
                                    onChangeText={(text) => handleTextChange('date_of_sample_lifted', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_number"
                            >Sample Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sample number`}
                                    type="number"
                                    name="sample_number"
                                    id="sample_number"
                                    value={values.sample_number}
                                    error={errors.sample_number}
                                    onChange={(e) => handleTextChange("sample_number", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_report"
                            >Sample Report *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Sample report`}
                                    type="text"
                                    name="sample_report"
                                    id="sample_report"
                                    items={Offence}
                                    action="dropdown"
                                    value={values.sample_report}
                                    error={errors.sample_report}
                                    onChange={(text) => handleTextChange("sample_report", text)}
                                />
                            </div>
                        </div>
                        {values && values.sample_report.includes('others') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_sample_report"
                            ></label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sample Report`}
                                    type="text"
                                    name="other_sample_report"
                                    id="other_sample_report"
                                    value={values.other_sample_report}
                                    error={errors.other_sample_report}
                                    onChange={(e) => handleTextChange("other_sample_report", e.target.value)}
                                />
                            </div>
                        </div>
                        ):null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_no"
                            >Case No *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case No`}
                                    type="text"
                                    name="case_no"
                                    id="case_no"
                                    value={values.case_no}
                                    error={errors.case_no}
                                    onChange={(e) => handleTextChange("case_no", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_petitioner_fso_do"
                            >Name Of the Petitioner/FSO/DO *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the petitioner/FSO/DO `}
                                    type="text"
                                    name="name_of_petitioner_fso_do"
                                    id="name_of_petitioner_fso_do"
                                    value={values.name_of_petitioner_fso_do}
                                    error={errors.name_of_petitioner_fso_do}
                                    onChange={(e) => handleTextChange("name_of_petitioner_fso_do", e.target.value)}
                                />
                            </div>
                        </div>              
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_petitioner_fso_do"
                            >Address Of the Petitioner/FSO/DO *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the petitioner/FSO/DO `}
                                    type="text"
                                    name="address_of_petitioner_fso_do"
                                    id="address_of_petitioner_fso_do"
                                    value={values.address_of_petitioner_fso_do}
                                    error={errors.address_of_petitioner_fso_do}
                                    onChange={(e) => handleTextChange("address_of_petitioner_fso_do", e.target.value)}
                                />
                            </div>
                        </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_respondent"
                            >Type of the Respondent *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of the Respondent`}
                                    type="text"
                                    name="type_of_respondent"
                                    id="type_of_respondent"
                                    action="dropdown"
                                    value={values.type_of_respondent}
                                    error={errors.type_of_respondent}
                                    items={Types}
                                    onChange={(value) =>handleTextChange("type_of_respondent", value)}
                                />
                            </div>
                        </div>   
                        {values && values.type_of_respondent.includes("Others")? 
                             <div className="form-group row">
                             <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_respondent"
                             > </label>
                             <div className="col-sm-8">
                                 <TextInput
                                     placeholder={`Type of the Respondent`}
                                     type="text"
                                     name="other_type_of_respondent"
                                     id="other_type_of_respondent"
                                     value={values.other_type_of_respondent}
                                     error={errors.other_type_of_respondent}
                                     items={Types}
                                     onChange={(e) =>handleTextChange("other_type_of_respondent", e.target.value)}
                                 />
                             </div>
                         </div>    
                        :null}                
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_respondent_fbo"
                            >Name Of the Respondent/FBO *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Respondent/FBO`}
                                    type="text"
                                    name="name_of_respondent_fbo"
                                    id="name_of_respondent_fbo"
                                    value={values.name_of_respondent_fbo}
                                    error={errors.name_of_respondent_fbo}
                                    onChange={(e) =>handleTextChange("name_of_respondent_fbo", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_respondent_fbo"
                            >Address Of the Respondent/FBO *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the Respondent/FBO`}
                                    type="text"
                                    name="address_of_respondent_fbo"
                                    id="address_of_respondent_fbo"
                                    value={values.address_of_respondent_fbo}
                                    error={errors.address_of_respondent_fbo}
                                    onChange={(e) =>handleTextChange("address_of_respondent_fbo", e.target.value)}
                                />
                            </div>
                        </div>
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="nature_of_offence"
                            >Nature Of Offence *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Nature of offence`}
                                    type="text"
                                    name="nature_of_offence"
                                    id="nature_of_offence"
                                    items={Offence}
                                    action="dropdown"
                                    value={values.nature_of_offence}
                                    error={errors.nature_of_offence}
                                    onChange={(text) => handleTextChange("nature_of_offence", text)}
                                />
                            </div>
                        </div>
                        {values && values.nature_of_offence.includes('others') ? (
                             <div className="form-group row">
                             <label className="control-label col-sm-4 text-label" htmlFor="other_nature_of_offence"
                             > </label>
                             <div className="col-sm-8">
                                 <TextInput
                                     placeholder={`Nature of offence`}
                                     type="text"
                                     name="other_nature_of_offence"
                                     id="other_nature_of_offence"
                                     value={values.other_nature_of_offence}
                                     error={errors.other_nature_of_offence}
                                     onChange={(e) => handleTextChange("other_nature_of_offence", e.target.value)}
                                 />
                             </div>
                         </div>
                         
                        ):null}
                         <div className="form-group row">
                             <label className="control-label col-sm-4 text-label" htmlFor="section"
                             >Case Filed Under Section *: </label>
                             <div className="col-sm-8">
                                 <TextInput
                                     placeholder={`Case Filed Under Section`}
                                     type="text"
                                     name="section"
                                     id="section"
                                     error={errors.section}
                                     value={values.section}
                                     onChange={(e) => handleTextChange("section", e.target.value)}
                                 />
                             </div>
                         </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_launched"
                            >Date Of Presentation *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date Of Launched`}
                                    name="date_of_launched"
                                    id="date_of_launched"
                                    value={values.date_of_launched}
                                    error={errors.date_of_launched}
                                    onChangeText={(text) => handleTextChange('date_of_launched', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_case_filing"
                            >Date Of Case Filing *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date Of Case Filing`}
                                    name="date_of_case_filing"
                                    id="date_of_case_filing"
                                    value={values.date_of_case_filing}
                                    error={errors.date_of_case_filing}
                                    onChangeText={(text) => handleTextChange('date_of_case_filing', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_1st_hearing"
                            >Date Of 1st Hearing *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date Of 1st Hearing`}
                                    name="date_of_1st_hearing"
                                    id="date_of_1st_hearing"
                                    value={values.date_of_1st_hearing}
                                    error={errors.date_of_1st_hearing}
                                    onChangeText={(text) => handleTextChange('date_of_1st_hearing', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_next_hearing"
                            >Date Of next Hearing *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date Of next Hearing`}
                                    name="date_of_next_hearing"
                                    id="date_of_next_hearing"
                                    value={values.date_of_next_hearing}
                                    error={errors.date_of_next_hearing}
                                    onChangeText={(text) => handleTextChange('date_of_next_hearing', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_history"
                            >Case History *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Case History`}
                                    type="text"
                                    name="case_history"
                                    id="case_history"
                                    value={values.case_history}
                                    error={errors.case_history}
                                    onChange={(e) => handleTextChange("case_history", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="judgement_status"
                            >Judgement status *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        placeholder={`Judgement Status`}
                                        type="text"
                                        name="judgement_status"
                                        value={values.judgement_status}
                                        error={errors.judgement_status}
                                        items={option}
                                        action="dropdown"
                                        onChange={(text) => handleTextChange("judgement_status", text)}
                                    />
                            </div>
                        </div>
                        { values && values.judgement_status.includes('Convicted with penalty') ? (
                          <>  
                           <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="penalty_paid"
                            >Penalty paid : </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        placeholder={`Penalty Paid`}
                                        type="text"
                                        name="penalty_paid"
                                        value={values.penalty_paid}
                                        error={errors.penalty_paid}
                                        items={Penalty}
                                        action="dropdown"
                                        onChange={(text) => handleTextChange("penalty_paid", text)}
                                    />
                            </div>
                        </div>
                        {values && values.penalty_paid.includes('Yes')? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="penalty_amount"
                            >Penalty Amount : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`penalty amount`}
                                    type="number"
                                    name="penalty_amount"
                                    id="penalty_ammount"
                                    value={values.penalty_amount}
                                    error={errors.penalty_amount}
                                    onChange={(e) => handleTextChange("penalty_amount", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_receipt"
                            >Upload Receipt *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Receipt`}
                                    type="file"
                                    name="upload_receipt"
                                    id="upload_receipt"
                                    error={errors.upload_receipt}
                                    onChange={(e) => handleTextChange("upload_receipt", e.target.files[0])}
                                />
                            </div>
                        </div>
                         </> : null}
                         {values && values.penalty_paid.includes('No') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="petition_filed"
                            >Whether Petition Filed Under Sec 96 : </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        placeholder={`Petition Filed`}
                                        type="text"
                                        name="petition_filed"
                                        value={values.petition_filed}
                                        error={errors.petition_filed}
                                        items={Penalty}
                                        action="dropdown"
                                        onChange={(text) => handleTextChange("petition_filed", text)}
                                    />
                            </div>
                        </div>
                        {values && values.petition_filed.includes('Yes') ? 
                          <div className="form-group row">
                          <label className="control-label col-sm-4 text-label" htmlFor="upload_petiton"
                          >Upload Petition Document : </label>
                          <div className="col-sm-8">
                              <TextInput
                                  placeholder={`Upload Petition Document`}
                                  type="file"
                                  name="upload_petiton"
                                  id="upload_petiton"
                                  value={values.upload_petiton}
                                  error={errors.upload_petiton}
                                  onChange={(e) => handleTextChange("upload_petiton", e.target.value)}
                              />
                          </div>
                      </div>
                        : null}
                          {values && values.petition_filed.includes('No') ? 
                          <div className="form-group row">
                          <label className="control-label col-sm-4 text-label" htmlFor="where_petition_filed"
                          >Petition Filed At : </label>
                          <div className="col-sm-8">
                              <TextInput
                                  placeholder={`Petition Filed at`}
                                  type="text"
                                  name="where_petition_filed"
                                  id="where_petition_filed"
                                  value={values.where_petition_filed}
                                  error={errors.where_petition_filed}
                                  onChange={(e) => handleTextChange("where_petition_filed", e.target.value)}
                              />
                          </div>
                      </div>
                        : null}
                          </> : null}
                        </>
                        ): null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_document_every_orders_of_adjudication_officer"
                            >Upload Document (For Every Order of Adjudication Officer) </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`upload Your Document`}
                                    type="file"
                                    name="upload_document_every_orders_of_adjudication_officer"
                                    id="upload_document_every_orders_of_adjudication_officer"
                                    error={errors.upload_document_every_orders_of_adjudication_officer}
                                    onChange={(e) => handleTextChange("upload_document_every_orders_of_adjudication_officer", e.target.files[0])}
                                />
                            </div>
                        </div>                
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateAdjudicationReports: (data) => dispatch(createAdjudicationReports(data)),
    onEditAdjudicationReports: (data, record_id) => dispatch(editAdjudicationReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,


});
  
export default connect(mapStateToProps, mapDispatchToProps)(AdjudicationReports);