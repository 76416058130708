import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const FSOEPODetails = (props) => {
    const { districts, traderTypes, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

    
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">FSO EPO Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Order Type</th>
                 <td className="text-left">{records.order_type}</td>
                </tr>
                <tr>
                  <th>Notice and Prohibition File Sent to DO On</th>
                 <td className="text-left">{moment(records.date_file_sent).format("l")}</td>
                </tr>
                
              <tr>
                <th>Prohibition Approval file Received From DO On</th>
                <td className="text-left">{moment(records.date_file_received).format("l")}</td>
              </tr>              
             
              <tr>
                <th>Action Taken On</th>
                <td className="text-left">{moment(records.date_action_taken).format("l")}</td>
              </tr>
              <tr>
                <th>Violation Rectified Reply Received from FBO On</th>
                <td className="text-left">{moment(records.date_rectification_received).format("l")}</td>
              </tr>
              <tr>
                <th>Violation Rectified Reply sent to DO On</th>
                <td className="text-left">{moment(records.date_rectification_sent).format("l")}</td>
              </tr>
              {records&&records.date_rectification_received_1&&records.date_rectification_received_1&&<tr>
                <th>Date rectification received</th>
                <td className="text-left">{moment(records.date_rectification_received_1).format("l")}</td>
              </tr>}
              {records&&records.date_file_sent_1&&records.date_file_sent_1&&<tr>
                <th>Date file sent</th>
                <td className="text-left">{moment(records.date_file_sent_1).format("l")}</td>
              </tr>}
              
              <tr>
                <th>FBO Reply Accepted by DO on</th>
                <td className="text-left">{moment(records.date_reply_accepted).format("l")}</td>
              </tr>
              <tr>
                <th>FBO Reply for EPO sent to Commissionerate on</th>
                <td className="text-left">{moment(records.date_reply_sent_to_commissionerate).format("l")}</td>
              </tr>
              <tr>
                <th>EPO Release Approval From Commissionerate Received on</th>
                <td className="text-left">{moment(records.date_release_received_on).format("l")}</td>
              </tr>
              <tr>
                <th>EPO/PO Release sent to FBO On</th>
                <td className="text-left">{moment(records.date_release_sent_on).format("l")}</td>
              </tr> 
              <tr>
                <th>EPO/PO Released On</th>
                <td className="text-left">{moment(records.date_released_on).format("l")}</td>
              </tr>
              {records&&records.upload_documents&&records.upload_documents&&<tr>
                <th>Upload Documents</th>
                <td className="text-left">{records.upload_documents}</td>
              </tr>}
              {records && records.date_prohibition_order_issued_to_fbo_on&& records.date_prohibition_order_issued_to_fbo_on&&
              <tr>
              <th>Prohibition order issued to fbo on</th>
              <td className="text-left">{moment(records.date_prohibition_order_issued_to_fbo_on).format("l")}</td>
            </tr>}
            {records && records.upload_doc&& records.upload_doc&&
              <tr>
              <th>Prohibition Document</th>
              <td className="text-left">{records.upload_doc}</td>
            </tr>}
            {records && records.upload_doc&& records.upload_doc&&
              <tr>
              <th>Prohibition Document</th>
              <td className="text-left">{records.upload_doc}</td>
            </tr>}
            {records && records.upload_docs&& records.upload_docs&&
              <tr>
              <th>Prohibition Document</th>
              <td className="text-left">{records.upload_docs}</td>
            </tr>}
            
            {records && records.epo_sanctioned&& records.epo_sanctioned&&
              <tr>
              <th>EPO Sanction/Rejected</th>
              <td className="text-left">{records.epo_sanctioned}</td>
            </tr>}
            {records && records.date_epo_sanctioned_on&& records.date_epo_sanctioned_on&&
              <tr>
              <th>EPO Sanction on</th>
              <td className="text-left">{moment(records.date_epo_sanctioned_on).format('l')}</td>
            </tr>} 
            {records && records.date_sent_to_fso_on&& records.date_sent_to_fso_on&&
              <tr>
              <th>EPO File sent to FSO on</th>
              <td className="text-left">{moment(records.date_sent_to_fso_on).format('l')}</td>
            </tr>} 
            {records && records.date_epn_sent_to_fbo_on&& records.date_epn_sent_to_fbo_on&&
              <tr>
              <th>EPO File sent to FBO on</th>
              <td className="text-left">{moment(records.date_epn_sent_to_fbo_on).format('l')}</td>
            </tr>}
            {records && records.date_received_epo_to_fbo_on&& records.date_received_epo_to_fbo_on&&
              <tr>
              <th>EPO File received from FBO on</th>
              <td className="text-left">{moment(records.date_received_epo_to_fbo_on).format('l')}</td>
            </tr>}
            {records && records.date_epn_issued_to_fbo_on&& records.date_epn_issued_to_fbo_on&&
              <tr>
              <th>EPO File issued to FBO on</th>
              <td className="text-left">{moment(records.date_epn_issued_to_fbo_on).format('l')}</td>
            </tr>}
            {records && records.date_epo_order_sent_to_fso_on&& records.date_epo_order_sent_to_fso_on&&
              <tr>
              <th>EPO Final File issued to FSO on</th>
              <td className="text-left">{moment(records.date_epo_order_sent_to_fso_on).format('l')}</td>
            </tr>}
            {records && records.date_order_request_to_comissionerate_on&& records.date_order_request_to_comissionerate_on&&
              <tr>
              <th>EPO Order sent to commissionerate  on</th>
              <td className="text-left">{moment(records.date_order_request_to_comissionerate_on).format('l')}</td>
            </tr>}
            {records && records.date_epn_file_do&& records.date_epn_file_do&&
              <tr>
              <th> EPN file sent to DO on  </th>
              <td className="text-left">{moment(records.date_epn_file_do).format('l')}</td>
            </tr>}
            {records && records.date_epn_receive_do&& records.date_epn_receive_do&&
              <tr>
              <th>DO received on</th>
              <td className="text-left">{moment(records.date_epn_receive_do).format('l')}</td>
            </tr>}
            {records && records.date_order_received_by_do_on&& records.date_order_received_by_do_on&&
              <tr>
              <th>EPO Order received by DO  on</th>
              <td className="text-left">{moment(records.date_order_received_by_do_on).format('l')}</td>
            </tr>}
            {records && records.date_do_reply&& records.date_do_reply&&
              <tr>
              <th>DO replied on</th>
              <td className="text-left">{moment(records.date_do_reply).format('l')}</td>
            </tr>}
            {records && records.date_do_reply&& records.date_do_reply&&
              <tr>
              <th>DO replied on</th>
              <td className="text-left">{moment(records.date_do_reply).format('l')}</td>
            </tr>}

              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(FSOEPODetails);