import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const LicensingDetails = (props) => {
    const { districts, traderTypes } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getFbo = (category_of_fbo) => {
      let fbo = null;
      traderTypes &&
      traderTypes.forEach((item) => {
          if (item.id === category_of_fbo) {
            fbo = item.name;
          }
        });
      return fbo;
  };

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Licensing Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Category of FBO</th>
                 <td className="text-left">{getFbo(records.category_of_food_business_operator)}</td>
                </tr>
                <tr>
                  <th>Licence exist till last month</th>
                   <td className="text-left">{records.no_of_license_existing_till_last_month}</td>
                </tr>
              <tr>
                <th>Number of licence granted during month</th>
                <td className="text-left">{records.no_of_license_granted_during_the_month}</td>
              </tr>              
              <tr>
                <th>Number of licence expired during month</th>
                <td className="text-left">{records.no_of_license_cancelled_expired_during_the_month}</td>
              </tr>
              <tr>
                <th>Total Licences</th>
                <td className="text-left">{records.total_number_of_license}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes
});

export default connect(mapStateToProps, mapDispatchToProps)(LicensingDetails);