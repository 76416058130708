import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getFsoOfficers } from "../../components/reducers/actions"

const SurveillanceDetails = (props) => {
    const { districts, wards, traderTypes,fsoOfficers,onGetFsoOfficers } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(async() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
            await onGetFsoOfficers();
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])
    const getFSO = (fs) => {
      let fsoName = null;
      fsoOfficers &&
      fsoOfficers.forEach((item) => {
        console.log(item, fs)
          if (item.fso.id == fs) {
            fsoName = item.fso.name;
          }
        });
        return fsoName;
      }
    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getFbo = (category_of_fbo) => {
      let fbo = null;
      traderTypes &&
      traderTypes.forEach((item) => {
          if (item.id === category_of_fbo) {
            fbo = item.name;
          }
        });
      return fbo;
  };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">FSS-Surveillance Sample Collected Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
              <tr>
                <th>Date Of Sampling</th>
                <td className="text-left">{moment(records.date_of_sampling).format("l")}</td>
              </tr>
                <tr>
                  <th>Sample No/Year</th>
                   <td className="text-left">{records.sample_no_year}</td>
                </tr>
              <tr>
                <th>Name Of the Food Safety Officer</th>
                <td className="text-left">{getFSO(records.name_of_the_food_safety_officer)}</td>
              </tr>  
              <tr>
                <th>FBO Name and Address of Sampling </th>
                <td className="text-left">{records.name_and_address_of_sampling}</td>
              </tr>  
              <tr>
                <th>License/Registration Number </th>
                <td className="text-left">{records.license_registration_number}</td>
              </tr>              
              <tr>
                <th>Nature of the Sample with Brand Name</th>
                <td className="text-left">{records.nature_of_the_sample_with_brand_name}</td>
              </tr>
              <tr>
                <th>Category of the Food Stuff</th>
                <td className="text-left">{records.category_of_food_stuff}</td>
              </tr>
              <tr>
                <th>Name Of the Manufacturing Company</th>
                <td className="text-left">{records.name_of_the_manufacturing_company}</td>
              </tr>
              <tr>
                <th>License/Registration Number of Manufacturing Company</th>
                <td className="text-left">{records.license_registration_company}</td>
              </tr>
          
              <tr>
                <th>Category of the Traders</th>
                <td className="text-left">{getFbo(parseInt(records.category_of_trader))}</td>
              </tr>
              <tr>
                <th>Name Of the Laboratory</th>
                <td className="text-left">{records.name_of_the_laboratory}</td>
              </tr>
              {records.other_name_of_the_laboratory && <tr>
                <th>Name Of the Laboratory(Other)</th>
                <td className="text-left">{records.other_name_of_the_laboratory}</td>
              </tr>}
              <tr>
                <th>Lab Report </th>
                <td className="text-left">{records.result}</td>
              </tr>
              {records.date_result_informed_to_fbo_date&& <tr>
                <th>Lab Report sent to FBO on</th>
                <td className="text-left">{moment(records.date_result_informed_to_fbo_date).format("l")}</td>
              </tr>}
              <tr>
                <th>Show Cause Notice Issued Date</th>
                <td className="text-left">{moment(records.show_cause_notice_issued_date).format("l")}</td>
              </tr>
              <tr>
                <th>Remarks Rectified Date</th>
                <td className="text-left">{moment(records.remarks_rectified_date).format("l")}</td>
              </tr>
              <tr>
                <th>Whether Act Samples Lifted</th>
                <td className="text-left">{moment(records.act_samples_lifted).format("l")}</td>
              </tr>
              <tr>
                <th>Remarks</th>
                <td className="text-left">{records.remarks}</td>
              </tr>
              <tr>
                <th>Evidence Upload</th>
                <td className="text-left">{records.evidence_upload}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data))

});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards:state.home.wards,
  traderTypes: state.home.traderTypes,
  fsoOfficers: state.home.fsoOfficers,

});

export default connect(mapStateToProps, mapDispatchToProps)(SurveillanceDetails);