import React, { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { bannedProducts, getBanned } from "../../components/reducers/actions";
import DownloadLink from "react-download-link";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

const BanItem = (props) => {
  const { ongetBannedProducts,ongetBanned,user } = props;
  const { t, i18n } = useTranslation();
  const [product, setProduct]=useState([])
  const [blink, setBlink] = useState(true)
  const getProduct = async () => {
    let id = props.location.state.item
      const data = {'category':id};
      console.log(id)
      const resp = await ongetBannedProducts(data);
    if(resp.response){
      console.log(resp)
      setProduct(resp.response)
    }
   
  };
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setBlink(!blink)
  //   },1000)
  // },[blink])
useEffect(()=>{
  getProduct()
},[])
  useEffect(() => {
    getProduct()
  }, [props]);
  useEffect(() => {
    getProduct()
  }, [user]);
  const Download = (props) => {
    const saveFile = () => {
      saveAs(
        props.data.attachment,
        props.data.file_name
      );
    };
    return (
      <div className="download_btn">
        <a href={props.data.attachment} target="_blank">
          {" "}
          View
        </a>
        <b>/</b>
        <div>
          <a
            onClick={()=>{saveFile(props)}}
            style={{textDecoration:'underline',color:'blue'}}
          >
          Download
          </a>
        </div>
      </div>
    );
  };

  return (
    <div style={{marginBottom:'150px'}}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">{t("BP")}</h1>
            </div>
          </div>
        </div>
      </header>
      {/* {product && product.map((i, index)=>(
          i.category ==='One Time use and throwaway plastic' && index === 0 &&
          <div style={{margin:'0 auto', position:'relative',textAlign:'center', fontSize:16, marginTop:40}}>
         <Link  style={{color:'blue'}} to="https://tnpcb.gov.in/PPFTN/"> PLASTIC POLLUTION FREE TAMIL NADU CAMPAIGN
         </Link>
         </div>
        ))} */}
        <div className="back_btn" onClick={()=>props.history.push('/banned-products')} style={{marginLeft:30, marginTop:30, display:'inline-block', cursor:'pointer'}}>
        <i style={{fontSize:'20px', marginRight:'8px'}} class="fas fa-arrow-left"></i>back
        </div>
      <div className="table_top">
        {  product && <>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>S.No</th>
              <th>{t("Description")}</th>
              <th>{t("Category")}</th>
              {/* <th>{t("Released On")}</th> */}
              <th>{t("Download/View")}</th>
            </tr>
          </thead>
          <tbody>
            {product.map((item, index) => (
              <tr>
                <td>{index+1}</td>
                <td><p style={{position:'relative'}}>{item.recent && blink? <span style={{float:'right',left:10, postion:'absolute',top:-5, right:0, background:'red', color:'#fff', fontWeight:'bold',padding:2, borderRadius:3}}>New</span>:''}{item.description}</p></td>                      <td>{item.category}</td>
                {/* <td>{moment(item.last_updated_at).format("l")}</td> */}
                <td>
                  <Download data={item} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </>}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  ongetBannedProducts: (data) => dispatch(bannedProducts(data)),
  ongetBanned:(data)=>dispatch(getBanned(data)),

});

const mapStateToProps = (state) => ({
  user:state.home.user
});

export default connect(mapStateToProps, mapDispatchToProps)(BanItem);
