import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const SlaughterDetails = (props) => {
    const { districts,wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }
         // eslint-disable-next-line    
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Slaughter Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              {/* <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr> */}
               <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
                <tr>
                  <th>Veterinary doctors available</th>
                 <td className="text-left">{records.veterinary_doctors}</td>
                </tr>
                <tr>
                  <th>Category</th>
                 <td className="text-left">{records.category}</td>
                </tr>
                <tr>
                  <th>Total Number of Slaughter House(s) inspected</th>
                 <td className="text-left">{records.inspected}</td>
                </tr>
                <tr>
                  <th>Total Number of Slaughter House(s)</th>
                   <td className="text-left">{records.total_no_of_slaughter_houses}</td>
                </tr>
              <tr>
                <th>Number of Slaughter House (In Use)</th>
                <td className="text-left">{records.no_of_slaughter_house_in_use}</td>
              </tr>              
              
              <tr>
                <th>Number of Slaughterhouse having valid License/Registration</th>
                <td className="text-left">{records.no_of_slaughter_house_having_valid_license}</td>
              </tr>
              <tr>
                <th>Number of Slaughterhouse(s) without valid License/Registration</th>
                <td className="text-left">{records.no_of_slaughter_house_without_valid_license}</td>
              </tr>
              <tr>
                <th>Number of Slaughter house (Not in Use)</th>
                <td className="text-left">{records.no_of_slaughter_house_not_in_use}</td>
              </tr>
              <tr>
                <th>Number of Slaughterhouse(s) License/Registration expired</th>
                <td className="text-left">{records.no_of_slaughter_house_registration_expired}</td>
              </tr>
              <tr>
                <th>Inspite of S.H Slaughtering Outside</th>
                <td className="text-left">{records.inspite_of_s_h_slaughtering_outside}</td>
              </tr>
              <tr>
                <th>During inspection within the premises (Storage available)</th>
                <td className="text-left">{records.during_inspection_within_premises_storage}</td>
              </tr>
              <tr>
                <th>During inspection within the premises (Sanitization Provided/Available)</th>
                <td className="text-left">{records.during_inspection_within_premises_sanitization}</td>
              </tr>
              <tr>
                <th>During inspection within the premises (Water Supply Available)</th>
                <td className="text-left">{records.during_inspection_within_premises_water_supply}</td>
              </tr>
              <tr>
                <th>Remarks</th>
                <td className="text-left">{records.remarks}</td>
              </tr>
              
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards:state.home.wards
});

export default connect(mapStateToProps, mapDispatchToProps)(SlaughterDetails);