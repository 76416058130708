import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { getFsoOfficers } from "../../components/reducers/actions"
import { createSurveillanceReports, editSurveillanceReports } from "../../components/reducers/actions"
import Datepicker from '../../components/inputs/Datepicker'
import { useParams } from "react-router-dom";
import moment from 'moment'

const resultData = [
    { id:"0", name: 'misbranded' },
    { id:"1", name: 'substandard'},
    { id:"2", name: 'unsafe'}
]
const Rectified=[
    { id:"0", name: 'yes' },
    { id:"1", name: 'no'},
]
const Lab =[
    {id:"0", name: 'Lab-1'},
    {id:"1", name: 'Lab-2'},
    {id:"2", name: 'Lab-3'},
    {id:"3", name: 'Lab-4'},
    {id:"4", name: 'Lab-5'},
    {id:"5", name: 'Lab-6'},
    {id:"6", name: 'Others'},
]
const SurveillanceReports = (props) => {
    const { districts, user, traderTypes, wardsCategory,wards,fsoOfficers, foodTypes,onGetFsoOfficers, 
        onCreateSurveillanceReports, onEditSurveillanceReports } = props;
    const [values, setValues] = useState({
       
        'area_code':'',
        'area_name':'',
        'sample_no_year':'',
        'name_of_the_food_safety_officer':'',
        'nature_of_the_sample_with_brand_name':'',
        'category_of_food_stuff':'',
        'name_of_the_manufacturing_company':'',
        'name_and_address_of_sampling':'',
        'category_of_trader':'',
        'name_of_the_laboratory':'',
        'act_samples_lifted':'',
        'result':'',
        'other_name_of_the_laboratory':'',
        'license_registration_number':'',
        'license_registration_company':'',
        'remarks':'',
        'evidence_upload':'',
        'district' : '',
        'report_details':'',
        'rectified':'',
        'status_of_report':'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            values.category_of_trader = parseInt(values.category_of_trader)
            values.name_of_the_food_safety_officer = parseInt(values.name_of_the_food_safety_officer)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
            getFsoList();
        }
        
        // eslint-disable-next-line 
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const getFsoList = async () => {
        await onGetFsoOfficers({});
      };
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'area_code',
            'area_name',
            'sample_no_year',
            'name_of_the_food_safety_officer',
            'nature_of_the_sample_with_brand_name',
            'category_of_food_stuff',
            'name_of_the_manufacturing_company',
            'name_and_address_of_sampling',
            'category_of_trader',
            'date_of_sampling',
            'name_of_the_laboratory',
            'date_result_informed_to_fbo_date',
            'date_show_cause_notice_issued_date',
            'date_remarks_rectified_date',
            'act_samples_lifted',
            'result',
            'license_registration_number',
            'license_registration_company',
            'remarks',
            'district' 
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => { 
        if (props.history.location && props.history.location.state) {
            console.log(values);
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditSurveillanceReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/ssc-list/`);
            };

        } else {      
            const data = { ...values}
            console.log('values', data);
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateSurveillanceReports(formData);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">FSS-Surveillance Sample Lifted Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area code *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    type="text"
                                    name="area_code"
                                    action="area_code"
                                    value={values.area_code}
                                    error={errors.area_code}
                                    items={wardsCategory}
                                    onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    type="text"
                                    placeholder="Area Name"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_sampling"
                            >Date Of Sampling *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of Sampling`}
                                    name="date_of_sampling"
                                    id="date_of_sampling"
                                    value={values.date_of_sampling}
                                    error={errors.date_of_sampling}
                                    onChangeText={(text) => handleTextChange('date_of_sampling', text)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_no_year"
                            >Sample Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`sample number`}
                                    type="number"
                                    name="sample_no_year"
                                    id="sample_no_year"
                                    value={values.sample_no_year}
                                    error={errors.sample_no_year}
                                    onChange={(e) => handleTextChange("sample_no_year", e.target.value)}
                                />
                            </div>
                        </div>                           
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_food_safety_officer"
                            >Name Of the Food Safety Officer *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`name of the food safety officer`}
                                    items={fsoOfficers}
                                    type="text"
                                    action="fsos"
                                    name="name_of_the_food_safety_officer"
                                    id="name_of_the_food_safety_officer"
                                    value={values.name_of_the_food_safety_officer}
                                    error={errors.name_of_the_food_safety_officer}
                                    onChange={(value) =>handleTextChange("name_of_the_food_safety_officer", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_and_address_of_sampling"
                            > FBO Name and Address of Sampling *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`FBO Name and address of sampling`}
                                    type="text"
                                    name="name_and_address_of_sampling"
                                    id="name_and_address_of_sampling"
                                    value={values.name_and_address_of_sampling}
                                    error={errors.name_and_address_of_sampling}
                                    onChange={(e) => handleTextChange("name_and_address_of_sampling", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_number"
                            > License/Registration Number *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`License/Registration Number`}
                                    type="text"
                                    name="license_registration_number"
                                    id="license_registration_number"
                                    value={values.license_registration_number}
                                    error={errors.license_registration_number}
                                    onChange={(e) => handleTextChange("license_registration_number", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="nature_of_the_sample_with_brand_name"
                            >Nature of the Sample with Brand Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={` nature of the sample`}
                                    type="text"
                                    name="nature_of_the_sample_with_brand_name"
                                    id="nature_of_the_sample_with_brand_name"
                                    value={values.nature_of_the_sample_with_brand_name}
                                    error={errors.nature_of_the_sample_with_brand_name}
                                    onChange={(e) => handleTextChange("nature_of_the_sample_with_brand_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="category_of_food"
                            >Category of the Food Product *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`category of the food product`}
                                    type="text"
                                    action="dropdown"
                                    name="category_of_food_stuff"
                                    value={values.category_of_food_stuff}
                                    error={errors.category_of_food_stuff}
                                    items={foodTypes}
                                    onChange={(text) => handleTextChange("category_of_food_stuff", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_manufacturing_company"
                            >Name Of the Manufacturing Company *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the  manufacturing company`}
                                    type="text"
                                    name="name_of_the_manufacturing_company"
                                    id="name_of_the_manufacturing_company"
                                    value={values.name_of_the_manufacturing_company}
                                    error={errors.name_of_the_manufacturing_company}
                                    onChange={(e) => handleTextChange("name_of_the_manufacturing_company", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="license_registration_company"
                            >License/Registration Number of Manufacturing Company *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`License/Registration Number of Manufacturing Company`}
                                    type="text"
                                    name="license_registration_company"
                                    id="license_registration_company"
                                    value={values.license_registration_company}
                                    error={errors.license_registration_company}
                                    onChange={(e) => handleTextChange("license_registration_company", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="category_of_trader"
                            >Category of the Traders *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Category of the Traders `}
                                    type="text"
                                    name="category_of_trader"
                                    value={values.category_of_trader}
                                    error={errors.category_of_trader}
                                    items={traderTypes}
                                    onChange={(text) => handleTextChange("category_of_trader", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_laboratory"
                            >Sample Sent for Analysis *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Name of the laboratory`}
                                    type="text"
                                    action="dropdown"
                                    items={Lab}
                                    name="name_of_the_laboratory"
                                    id="name_of_the_laboratory"
                                    value={values.name_of_the_laboratory}
                                    error={errors.name_of_the_laboratory}
                                    onChange={(text) => handleTextChange("name_of_the_laboratory", text)}
                                />
                            </div>
                        </div>
                        {values && values.name_of_the_laboratory.includes('Others') ? (
                           <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="other_name_of_the_laboratory"
                           > </label>
                           <div className="col-sm-8">
                               <TextInput
                                   placeholder={`Name of the laboratory`}
                                   name="other_name_of_the_laboratory"
                                   id="other_name_of_the_laboratory"
                                   value={values.other_name_of_the_laboratory}
                                   error={errors.other_name_of_the_laboratory}
                                   onChange={(e) => handleTextChange('other_name_of_the_laboratory', e.target.value)}
                               />
                           </div>
                       </div>  
                        ) : null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_result_informed_to_fbo_date"
                            >Analysis Report sent to FBO on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report sent to FBO on`}
                                    name="date_result_informed_to_fbo_date"
                                    id="date_result_informed_to_fbo_date"
                                    value={values.date_result_informed_to_fbo_date}
                                    error={errors.date_result_informed_to_fbo_date}
                                    onChangeText={(text) => handleTextChange('date_result_informed_to_fbo_date', text)}
                                    />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="result"
                            >Analysis Report : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Lab Report`}
                                    type="text"
                                    name="result"
                                    action="dropdown"
                                    //action="ssc_report_result"
                                    menuPortalTarget={document.body} menuPosition={'fixed'}
                                    value={values.result}
                                    error={errors.result}
                                    items={resultData}
                                    onChange={(text) => handleTextChange("result", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="report_details"
                           >Analysis Report Details </label>
                           <div className="col-sm-8">
                               <TextAreaInput
                                   placeholder={`Analysis Report Details`}
                                   name="report_details"
                                   id="report_details"
                                   value={values.report_details}
                                   error={errors.report_details}
                                   onChange={(e) => handleTextChange('report_details', e.target.value)}
                               />
                           </div>
                       </div>    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_show_cause_notice_issued_date"
                            >Show Cause Notice Issued Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Notice issued`}
                                    name="date_show_cause_notice_issued_date"
                                    id="date_show_cause_notice_issued_date"
                                    value={values.date_show_cause_notice_issued_date}
                                    error={errors.date_show_cause_notice_issued_date}
                                    onChangeText={(text) => handleTextChange('date_show_cause_notice_issued_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="rectified"
                            >Remarks Rectified : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Remarks Rectified`}
                                    type="text"
                                    name="rectified"
                                    action="dropdown"
                                    value={values.rectified}
                                    error={errors.rectified}
                                    items={Rectified}
                                    onChange={(text) => handleTextChange("rectified", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_remarks_rectified_date"
                            >Remarks Rectified Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Remarks Rectified Date`}
                                    name="date_remarks_rectified_date"
                                    id="date_remarks_rectified_date"
                                    value={values.date_remarks_rectified_date}
                                    error={errors.date_remarks_rectified_date}
                                    onChangeText={(text) => handleTextChange('date_remarks_rectified_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="act_samples_lifted"
                            > Act Samples Lifted *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Act Samples Lifted `}
                                    name="act_samples_lifted"
                                    id="act_samples_lifted"
                                    value={values.act_samples_lifted}
                                    error={errors.act_samples_lifted}
                                    onChange={(e) => handleTextChange('act_samples_lifted', e.target.value)}
                                />
                            </div>
                        </div>
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="evidence_upload"
                            >Evidence Upload: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    name="evidence_upload"
                                    type="file"
                                    id="evidence_upload"
                                    error={errors.evidence_upload}
                                    onChange={(e) => handleTextChange('evidence_upload', e.target.files[0])}
                                />
                            </div>
                        </div> 
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateSurveillanceReports: (data) => dispatch(createSurveillanceReports(data)),
    onEditSurveillanceReports: (data, record_id) => dispatch(editSurveillanceReports(data, record_id)),
    onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    traderTypes: state.home.traderTypes,
    wards: state.home.wards,
    foodTypes: state.home.foodTypes,
    fsoOfficers: state.home.fsoOfficers,
    wardsCategory:state.home.wardsCategory,


});
  
export default connect(mapStateToProps, mapDispatchToProps)(SurveillanceReports);