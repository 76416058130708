import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import { getDistrictWards, getFoodCategory, getFsoOfficers } from "../../components/reducers/actions"
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createVehicleInspectionReports, editVehicleInspectionReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from "moment";

const Default = [
    { id: "2", name: "Misbranded" },
    { id: "3", name: "Expiry" }
]
const seized = [
    { id: "0", name: "Food Only" },
    { id: "1", name: "Vehicle and Food" }
]
const Rc = [
    { id: "0", name: "Yes" },
    { id: "1", name: "No" }
]
const FoodType = [
    {id:"0", name:"Pre Packed"},
    {id:"1", name:"Loose Condition"}
]
const Packed = [
    {id:"0", name:"Packed Milk"},
    {id:"1", name:"Loose Milk"},
    {id:"2", name:"Butter, Ghee, IceCream and Other Milk"},
    {id:"3", name:"Edible Oils, Fats & Vanaspati"},
    {id:"4", name:"Fresh Fruits and Vegetables"},
    {id:"5", name:"Processed Fruit & Vegetable Product"},
    {id:"6", name:"Pickles"},
    {id:"7", name:"Cereals and Cereals Product"},
    {id:"8", name:"Meat and Meat Products"},
    {id:"9", name:"Egg and Egg Products"},
    {id:"10", name:"Fish and Fish Products"},
    {id:"11", name:"Bakery & Confectionery"},
    {id:"12", name:"Sweeteners Including Honey"},
    {id:"13", name:"Salt Including Lodisedd Salt"},
    {id:"14", name:"Whole Spices and condiments"},
    {id:"15", name:"Spices and Condiments Powder"},
    {id:"16", name:"Alcoholic Bevarages"},
    {id:"17", name:"Non-Alcholoic(Carbonated) Bevarages"},
    {id:"18", name:"Mineral Water"},
    {id:"19", name:"Packaged Drinking"},
    {id:"20", name:"Savouries/Namkeens/Sweets"},
    {id:"21", name:"Tea, Coffee, Coco, Chicory"},
    {id:"22", name:"Infant Milk Substitutes"},
    {id:"23", name:"Infant Food (Weaning Food)"},
    {id:"24", name:"Supari, Pan-Masala"},
    {id:"25", name:"Hotels and Restaurants Prepared Food"},
    {id:"26", name:"Caterer Prepared Food"},
    {id:"27", name:"Dhaba Prepared Food"},
    {id:"28", name:"Mid-day Meal"},
    {id:"29", name:"Neutraceiticals/Health"},
    {id:"30", name:"Food Additives"},
    {id:"31", name:"Other Miscellaneous Food"},

]
const Destroy = [
    { id: "0", name: "Yes" },
    { id: "1", name: "No" }
]
const Seizure = [
    { id: "0", name: "FSO" },
    { id: "1", name: "DO" },
    { id: "2", name: "FBO" },
    { id: "3", name: "Judicial Magistrate" },
    { id: "4", name: "Other Official" }
]

const VehicleInspectionReport = (props) => {
    const { districts, user,wards,wardsCategory, onCreateVehicleInspectionReports,ongetDist, onEditVehicleInspectionReports, fsoOfficers, onGetFsoOfficers,ongetFoodCategory } = props;
    const [workerDetails, setWorkerDetails] = useState([{
        worker_name: '',
        worker_address: '',
        worker_documents: '',
    }])
    const [productDetails, setProductDetails] = useState([{
        name_of_food_seized: '',
        quantity_of_food_seized: '',
        value_of_food_seized: '',

    }])
    const [values, setValues] = useState({

        'area_name': '',
        'area_code': '',
        'district': '',
        'date_of_inspection': '',
        'place_of_inpsection': '',
        'type_of_vehicle':'',
        'default_found': '',
        'seized_under_custody_of': '',
        'vehicle_number': '',
        'owner_name': '',
        'owner_address': '',
        'food_type':'',
        'owner_doc': '',
        'driver_name': '',
        'driver_address': '',
        'driver_documents': '',
        'sample_lifted': '',
        'food_destroyed': '',
        'coordinated_officers': '',
        'food_category':'',
        'batch_no':'',
        'packed_date':'',
        'packed_address':'',
        'consignment_name':'',
        'consignment_address':'',
        'consignment_documents':'',
        'name_seized_under_custody_of':"",
        'other_food_category':'',
        'rc_available':'',
        'upload_lics':'',
        'license_available':'',
        'up_docx':'',
        'return_property':"",
        'upload_property':'',
        'lic_number':'',
        'seizure_docs':'',


    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [field, setField] = useState({});
    const [workDoc, setWorkDoc]=useState([])
    const [foodCategory, setFoodCategory]=useState([]);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

const getFood=async()=>{
    const resp = await ongetFoodCategory()
    if(resp&&resp.response){
        setFoodCategory(resp.response)
    }
}
useEffect(()=>{
    getFood()
},[])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
           setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            setWorkerDetails(recordData.worker_details)
            setProductDetails(recordData.product_details)
        } else if (slug) {
            console.log(slug);
        }
        if (user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
            getFsoList();
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const getFsoList = async () => {
        await onGetFsoOfficers({});
    };
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])


    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'area_name',
            'area_code',
            'district',
            'date_of_inspection',
            'place_of_inpsection',
            'default_found',
            'seized_under_custody_of',
            'vehicle_number',
            'owner_name',
            'owner_address',
            'type_of_vehicle',
            'driver_name',
            'driver_address',
            'food_type',
            'sample_lifted',
            'consignment_name',
              'consignment_address',
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if (!values[key] || values[key] === '') {
                updatedErrors[key] = ' ';
            }
        });

        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = {
                ...values,
                'worker_details':JSON.stringify(workerDetails),
                'product_details':JSON.stringify(productDetails),
            }
           let doc =  workerDetails.map((i)=>i.worker_documents)
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            for (let i = 0; i < doc.length; i++) {
                formData.append(`Image${i}`, doc[i])
            }
            const resp = await onEditVehicleInspectionReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/vehicle-inspection-list/`);
            };

        } else {
            console.log(workerDetails,productDetails)
            const data = {
                ...values,
            'worker_details':JSON.stringify(workerDetails),//JSON.stringify(workerDetails),
            'product_details':JSON.stringify(productDetails),
            }
           let doc =  workerDetails.map((i)=>i.worker_documents)
            console.log(data, doc)
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
             
            });
            for (let i = 0; i < doc.length; i++) {
                formData.append(`image${i}`, doc[i])
            }
            setDisableSubmit(true);
            const resp = await onCreateVehicleInspectionReports(formData);
            console.log(resp.response)
            if (resp && resp.response) {
                props.history.push("/app/reports/");
            };
        }
    }
    const handleActionChange = (e, index) => {
        var {value} = e.target;
        const { name} = e.target;
        if(e.target.name == 'worker_documents'){
            value = e.target.files[0];
         }
        const workerList = [...workerDetails];
        workerList[index][name] = value;
        setWorkerDetails(workerList);
        console.log(workerDetails)
    };
    const handleProductChange = (e, index) => {
        const { name, value } = e.target;
        const productList = [...productDetails];
        productList[index][name] = value;
        setProductDetails(productList);
    };
    const handleActionAddClick = () => {
        setWorkerDetails([
            ...workerDetails,
            {
                worker_name: '',
                worker_address: '',
                worker_documents: ''
            }
        ]);
    };
    const handleProductAddClick = () => {
        setProductDetails([
            ...productDetails,
            {
                name_of_food_seized: '',
                quantity_of_food_seized: '',
                value_of_food_seized: '',
            }
        ]);
    };
    const handleActionRemoveClick = index => {
        const workerList = [...workerDetails];
        workerList.splice(index, 1);
        setWorkerDetails(workerList);
    };
    const handleProductRemoveClick = index => {
        const productList = [...productDetails];
        productList.splice(index, 1);
        setProductDetails(productList);
    };
    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    
    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Vehicle Inspection Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        <table className="table table-bordered">
                            <tbody>
                                {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                                    <tr className="col-sm-12">
                                        <th colSpan="2">District : </th>
                                        <td colSpan="4" className="input-label">
                                            <DropDownInput
                                                type="text" isDisabled
                                                name="district"
                                                value={values.district}
                                                error={errors.district}
                                                items={districts}
                                                onChange={(value) => handleTextChange("district", value)}
                                            />
                                        </td>
                                    </tr>

                                ) : (
                                    <tr>
                                        <th colSpan="2">District : </th>
                                        <td colSpan="4" className="input-label">
                                            <DropDownInput
                                                placeholder={`District`}
                                                type="text"
                                                name="district"
                                                value={values.district}
                                                error={errors.district}
                                                items={districts}
                                                onChange={(text) => handleTextChange("district", text)}
                                            />
                                        </td>
                                    </tr>
                                )}

                                <tr>
                                    <th colSpan="2">Area code *: </th>
                                    <th colSpan="2" >Area Name *: </th>

                                </tr>
                                <tr>  <td colSpan="2" className="input-label">
                                <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                                </td>
                                    <td colSpan="2" className="input-label">
                                        <TextInput
                                            placeholder={`Area Name`}
                                            type="text"
                                            name="area_name"
                                            value={values.area_name}
                                            error={errors.area_name}
                                            onChange={(e) => handleTextChange("area_name", e.target.value)}
                                        />
                                    </td>

                                </tr>


                                <tr>
                                    <th colSpan="2">Date of Inspection *:</th>
                                    <th colSpan="2">Place Of Inspection *:</th>
                                </tr>
                                <tr>
                                    <td colSpan="2" className="input-label">
                                        <Datepicker
                                            placeholder={`Date Of Inspection`}
                                            name="date_of_inspection"
                                            id="date_of_inspection"
                                            value={values.date_of_inspection}
                                            error={errors.date_of_inspection}
                                            onChangeText={(text) => handleTextChange('date_of_inspection', text)}
                                        />
                                    </td>
                                    <td colSpan="2">
                                        <TextInput
                                            placeholder={`Place Of Inspection`}
                                            type="text"
                                            name="place_of_inpsection"
                                            id="place_of_inpsection"
                                            value={values.place_of_inpsection}
                                            error={errors.place_of_inpsection}
                                            onChange={(e) => handleTextChange("place_of_inpsection", e.target.value)}
                                        />
                                    </td> </tr>
                                <tr>
                                    <th colSpan="2">Vehicle Number *</th>
                                    
                                    <td colSpan="2">
                                        <TextInput
                                            placeholder={`Vehicle Number`}
                                            type="text"
                                            name="vehicle_number"
                                            id="vehicle_number"
                                            value={values.vehicle_number}
                                            errors={values.vehicle_number}
                                            onChange={(e) => handleTextChange("vehicle_number", e.target.value)}
                                        />  </td>
                                       
                                </tr>
                                <tr>
                                <th colSpan="2">Vehicle Type *</th>
                                <td colSpan="2">
                                        <TextInput
                                            placeholder={`Vehicle Type`}
                                            type="text"
                                            name="type_of_vehicle"
                                            id="type_of_vehicle"
                                            value={values.type_of_vehicle}
                                            errors={values.type_of_vehicle}
                                            onChange={(e) => handleTextChange("type_of_vehicle", e.target.value)}
                                        />  </td>
                                </tr>
                                <tr>
                                <th colSpan="2">RC Book Available *</th>
                                <td colSpan="2">
                                <DropDownInput
                                            placeholder={`RC Book Available`}
                                            type="text"
                                            items={Rc}
                                            action="dropdown"
                                            name="rc_available"
                                            id="rc_available"
                                            value={values.rc_available}
                                            error={errors.rc_available}
                                            onChange={(text) => handleTextChange("rc_available", text)}
                                        />  </td>
                                </tr>
                               {values && values.rc_available.includes('Yes')&&( <tr>
                                <th colSpan="2">Upload RC Document *</th>
                                <td colSpan="2">
                                <TextInput
                                            placeholder={`Upload RC Document`}
                                            type="file"
                                            name="up_docx"
                                            id="up_docx"
                                            error={errors.up_docx}
                                            onChange={(e) => handleTextChange("up_docx", e.target.files[0])}
                                        />  </td>
                                </tr>)}
                                <tr>
                                    <th>Respondant's *</th>
                                    <th>Name *</th>
                                    <th>Address</th>
                                    <th colSpan="2">Upload Documents (if any)</th>
                                </tr>
                                <tr>
                                    <th>Owner of the Vehicle *</th>
                                    <td className="col-sm-2">
                                        <TextInput
                                            placeholder={` Owner Name`}
                                            type="text"
                                            name="owner_name"
                                            id="owner_namer"
                                            value={values.owner_name}
                                            errors={values.owner_name}
                                            onChange={(e) => handleTextChange("owner_name", e.target.value)}
                                        />
                                    </td>
                                    <td className="col-sm-2">
                                        <TextAreaInput
                                            placeholder={`Address`}
                                            type="text"
                                            name="owner_address"
                                            id="owner_address"
                                            value={values.owner_address}
                                            errors={values.owner_address}
                                            onChange={(e) => handleTextChange("owner_address", e.target.value)}
                                        />
                                    </td>
                                    <td className="col-sm-2">
                                        <TextInput
                                            placeholder={`Documents`}
                                            type="file"
                                            name="owner_doc"
                                            id="owner_doc"
                                            errors={values.owner_doc}
                                            onChange={(e) => handleTextChange("owner_doc", e.target.files[0])}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Driver Name *</th>
                                    <td className="col-sm-2">
                                        <TextInput
                                            placeholder={`Driver Name`}
                                            type="text"
                                            name="driver_name"
                                            id="driver_name"
                                            value={values.driver_name}
                                            errors={values.driver_name}
                                            onChange={(e) => handleTextChange("driver_name", e.target.value)}
                                        />
                                    </td>
                                    <td className="col-sm-2">
                                        <TextAreaInput
                                            placeholder={`Address`}
                                            type="text"
                                            name="driver_address"
                                            id="driver_address"
                                            value={values.driver_address}
                                            errors={values.driver_address}
                                            onChange={(e) => handleTextChange("driver_address", e.target.value)}
                                        />
                                    </td>
                                    <td className="col-sm-2">
                                        <TextInput
                                            placeholder={`Documents`}
                                            type="file"
                                            name="driver_documents"
                                            id="driver_documents"
                                            errors={values.driver_documents}
                                            onChange={(e) => handleTextChange("driver_documents", e.target.files[0])}
                                        />
                                    </td>
                                </tr>
                                    <tr>
                                        <th>Consignment Delivery Details *</th>
                                        <td className="col-sm-2">
                                        <TextInput
                                            placeholder={`Name of consignment`}
                                            type="text"
                                            name="consignment_name"
                                            id="consignment_name"
                                            value={values.consignment_name}
                                            errors={values.consignment_name}
                                            onChange={(e) => handleTextChange("consignment_name", e.target.value)}
                                        />
                                    </td>
                                    <td className="col-sm-2">
                                        <TextAreaInput
                                            placeholder={`Address`}
                                            type="text"
                                            name="consignment_address"
                                            id="consignment_address"
                                            value={values.consignment_address}
                                            errors={values.consignment_address}
                                            onChange={(e) => handleTextChange("consignment_address", e.target.value)}
                                        />
                                    </td>
                                    <td className="col-sm-2">
                                        <TextInput
                                            placeholder={`Documents`}
                                            type="file"
                                            name="consignment_documents"
                                            id="consignment_documents"
                                            errors={values.consignment_documents}
                                            onChange={(e) => handleTextChange("consignment_documents", e.target.files[0])}
                                        />
                                    </td>
                                    </tr>
                                {workerDetails.map((x, i) => {
                                    return (
                                        <tr>
                                            <th className="col-sm-2">Accompanied Persons *</th>
                                            <td>
                                                <input className="form-control input-label"
                                                    placeholder={`Name`}
                                                    type="text"
                                                    name="worker_name"
                                                    id="worker_name"
                                                    value={x.worker_name}
                                                    errors={errors.worker_name}
                                                    onChange={(e) => handleActionChange(e, i)}
                                                />
                                            </td>
                                            <td>
                                                <textarea className="form-control input-label"
                                                    placeholder={`Address`}
                                                    type="text"
                                                    name="worker_address"
                                                    id="vehicle_number"
                                                    value={x.worker_address}
                                                    errors={errors.worker_address}
                                                    onChange={(e) => handleActionChange(e, i)}
                                                />
                                            </td>
                                            <td style={{ display: "flex" }}  >
                                                <input className="form-control input-label"
                                                    placeholder={`Documents`}
                                                    type="file"
                                                    name="worker_documents"
                                                    id="worker_documents"
                                                    errors={x.worker_documents}
                                                    onChange={(e) => handleActionChange (e, i)}
                                                />

                                                {i !== workerDetails.length - 1 && (
                                                    <button style={{marginLeft:"10px"}} className="btn btn-warning btn-sm btn-circle"
                                                        onClick={() => handleActionRemoveClick(i)}>
                                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                                    </button>
                                                )}
                                                {workerDetails.length - 1 === i && (
                                                    <button style={{marginLeft:"10px"}} className="btn btn-success btn-sm btn-circle" onClick={handleActionAddClick}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                <th colSpan="2"><center>License Available *</center></th>
                                <td colSpan="2" className="input-label">
                                        <DropDownInput
                                            placeholder={`License Available`}
                                            type="text"
                                            items={Rc}
                                            action="dropdown"
                                            name="license_available"
                                            id="license_available"
                                            value={values.license_available}
                                            error={errors.license_available}
                                            onChange={(text) => handleTextChange("license_available", text)}
                                        />
                                    </td>
                                 </tr>
                                 {values && values.license_available.includes('Yes')&&(<>
                                    <tr>
                                <th colSpan="2"><center>License Number *</center></th>
                                <td colSpan="2" className="input-label">
                                        <TextInput
                                            placeholder={`License Number`}
                                            type="text"
                                            name="lic_number"
                                            id="lic_number"
                                            value={values.lic_number}
                                            error={errors.lic_number}
                                            onChange={(e) => handleTextChange("lic_number", e.target.value)}
                                        />
                                    </td>
                                 </tr>
                                 <tr>
                                <th colSpan="2"><center>Upload License</center></th>
                                <td colSpan="2" className="input-label">
                                        <TextInput
                                            placeholder={`Upload License`}
                                            type="file"
                                            name="upload_lics"
                                            id="upload_lics"
                                            error={errors.upload_lics}
                                            onChange={(e) => handleTextChange("upload_lics", e.target.files[0])}
                                        />
                                    </td>
                                 </tr>
                                 </> )}
                                <tr>

                                <th colSpan="2"><center>Violation Found *</center></th>
                                <td colSpan="2" className="input-label">
                                        <DropDownInput
                                            placeholder={`Violation Found`}
                                            type="text"
                                            items={Default}
                                            action="dropdown"
                                            name="default_found"
                                            id="default_found"
                                            value={values.default_found}
                                            error={errors.default_found}
                                            onChange={(text) => handleTextChange("default_found", text)}
                                        />
                                    </td>
                                 </tr>
                                 {values && values.default_found ? (
                                        <tr>
                                            <th colSpan="2"> <center>Food Type </center></th>
                                            <td colSpan="2">
                                            <DropDownInput
                                            placeholder={`Food Type`}
                                            type="text"
                                            action="dropdown"
                                            items={FoodType}
                                            name="food_type"
                                            id="food_type"
                                            value={values.food_type}
                                            error={errors.food_type}
                                            onChange={(text) => handleTextChange("food_type", text)}
                                        />
                                            </td>
                                        </tr>
                                 ): null}
                                 {values && values.food_type.includes('Pre Packed') ? (
                                     <tr>
                                     <th colSpan="2"> <center>Food Category </center></th>
                                     <td colSpan="2">
                                     {/* <DropDownInput
                                     placeholder={`Food Category`}
                                     type="text"
                                     items={Packed}
                                     action="dropdown"
                                     name="food_category"
                                     id="food_category"
                                     value={values.food_category}
                                     error={errors.food_category}
                                     onChange={(text) => handleTextChange("food_category", text)}
                                 /> */}
                                  {foodCategory&&foodCategory.length !==0&& <DropDownInput
                                     placeholder={`Food Category`}
                                     type="text"
                                     items={foodCategory}
                                     action="dropdown"
                                     name="food_category"
                                     id="food_category"
                                     value={values.food_category}
                                     error={errors.food_category}
                                     onChange={(text) => handleTextChange("food_category", text)}
                                 />}
                                     </td>
                                 </tr> 
                                 ): null}
                                 {values && values.food_category.includes('Other Miscellaneous Food') ? (
                                        <tr>
                                            <th colSpan="2"> <center>Food Category</center></th>
                                            <td colSpan="2">
                                            <TextInput
                                            placeholder={`Food Category`}
                                            type="text"
                                            name="other_food_category"
                                            id="other_food_category"
                                            errors={values.other_food_category}
                                            onChange={(e) => handleTextChange("other_food_category", e.target.value)}
                                        /> 
                                            </td>
                                        </tr>
                                 ):null}
                                 {values && values.food_category && values.food_type.includes('Pre Packed') ? (
                                    <> <tr>
                                         <th colSpan="1">Batch Number</th>
                                         <th colSpan="1">Packed Date</th>
                                         <th colSpan="2">Packed Address</th>
                                     </tr>
                                     <tr>
                                         <td colSpan="1"><TextInput
                                            placeholder={`Batch No`}
                                            type="text"
                                            name="batch_no"
                                            id="batch_no"
                                            value={values.batch_no}
                                            errors={values.batch_no}
                                            onChange={(e) => handleTextChange("batch_no", e.target.value)}
                                        /> </td>
                                         <td colSpan="1"><Datepicker
                                            placeholder={`Packed Date`}
                                            type="text"
                                            name="packed_date"
                                            id="packed_date"
                                            value={values.packed_date }
                                            errors={values.packed_date}
                                            onChangeText={(text) => handleTextChange("packed_date", text)}
                                        /> </td>
                                         <td colSpan="2"><TextAreaInput
                                            placeholder={`Packed Address`}
                                            type="text"
                                            name="packed_address"
                                            id="packed_address"
                                            value={values.packed_address}
                                            errors={values.packed_address}
                                            onChange={(e) => handleTextChange("packed_address", e.target.value)}
                                        /> </td>
                                     </tr></>
                                 ): null}
                                <th colSpan="4"><center> Product Seized Particulars *</center>
                                    <tr>
                                        <th className="col-sm-2">Name Of Food Seized *</th>
                                        <th className="col-sm-2">Quantity Of Food Seized *</th>
                                        <th className="col-sm-2">Value Of Food Seized *</th>
                                    </tr>
                                </th>
                               
                                {productDetails.map((x, i)=>{
                                    return(
                                         <tr>
                                        <td colSpan="4">
                                        
                                        <td className="col-sm-2">
                                            <input className="form-control input-label"
                                                placeholder={`Name of the food seized`}
                                                type="text"
                                                name="name_of_food_seized"
                                                id="name_of_food_seized"
                                                value={x.name_of_food_seized}
                                                error={errors.name_of_food_seized}
                                                onChange={(e) => handleProductChange(e, i)}
                                            />
                                        </td>
                                        <td className="col-sm-2">
                                            <input className="form-control input-label"
                                                placeholder={`Quantity Of Food Seized`}
                                                type="text"
                                                name="quantity_of_food_seized"
                                                id="quantity_of_food_seized"
                                                value={x.quantity_of_food_seized}
                                                error={errors.quantity_of_food_seized}
                                                onChange={(e) => handleProductChange(e, i)}
                                            />
                                        </td>
                                        <td className="col-sm-2">
                                            <input className="form-control input-label"
                                                placeholder={`Value Of Food Seized`}
                                                type="text"
                                                name="value_of_food_seized"
                                                id="value_of_food_seized"
                                                value={x.value_of_food_seized}
                                                error={errors.value_of_food_seized}
                                                onChange={(e) => handleProductChange( e, i)}
                                            />
                                            {i !== productDetails.length - 1 && (
                                                    <button style={{marginTop: "-35px",float: "right",marginRight: "-30px"}} className="btn btn-warning btn-sm btn-circle"
                                                        onClick={() => handleProductRemoveClick(i)}>
                                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                                    </button>
                                                )}
                                                {productDetails.length - 1 === i && (
                                                    <button style={{marginTop: "-35px",float: "right",marginRight: "-30px"}} className="btn btn-success btn-sm btn-circle" onClick={handleProductAddClick}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                )}
                                        </td>
                                        
                                        </td>
                                        </tr>
                                    )
                                })}   
                                  <tr>
                                <th colSpan="2">Seizure documents *</th>
                                <td colSpan="2">
                                        <TextInput
                                            placeholder={`Seizure documents`}
                                            type="file"
                                            name="seizure_docs"
                                            id="seizure_docs"
                                            errors={values.seizure_docs}
                                            onChange={(e) => handleTextChange("seizure_docs", e.target.files[0])}
                                        />  </td>
                                </tr>
                                
                                <tr>
                                    <th colSpan="2"><center>Seized and property is Under Custody Of *</center></th>
                                    <th className="col-sm-2">Seized with Vehicle/Food *</th>
                                    <th className="col-sm-2">Whether Sample Lifted *</th>
                                </tr>
                                <tr>
                                    <td colSpan="2" >
                                        <DropDownInput
                                            placeholder={`Seized Under Custody Of`}
                                            type="text"
                                            name="seized_under_custody_of"
                                            id="seized_under_custody_of"
                                            items={Seizure}
                                            action="dropdown"
                                            value={values.seized_under_custody_of}
                                            error={errors.seized_under_custody_of}
                                            onChange={(text) => handleTextChange("seized_under_custody_of", text)}
                                        />
                                    </td>
                                   
                                    <td className="col-sm-2">
                                        <DropDownInput
                                            placeholder={`Seized with`}
                                            type="text"
                                            name="seized_with"
                                            id="seized_with"
                                            items={seized}
                                            action="dropdown"
                                            value={values.seized_with}
                                            error={errors.seized_with}
                                            onChange={(text) => handleTextChange("seized_with", text)}
                                        />
                                    </td>
                                    <td className="col-sm-2">
                                        <TextInput
                                            placeholder={`sample Lifted`}
                                            type="text"
                                            name="sample_lifted"
                                            id="sample_lifted"
                                            value={values.sample_lifted}
                                            error={errors.sample_lifted}
                                            onChange={(e) => handleTextChange("sample_lifted", e.target.value)}
                                        />
                                    </td>
                                </tr>
                                {values && values.seized_under_custody_of.includes('FSO')? (
                                       <tr> <th colSpan="2">FSO</th>
                                        <td colSpan="2"> <DropDownInput
                                            placeholder={`Seized Under Custody Of`}
                                            type="text"
                                            name="fso_name_seized_under_custody_of"
                                            id="fso_name_seized_under_custody_of"
                                            items={fsoOfficers}
                                            action="fso"
                                            value={values.fso_name_seized_under_custody_of}
                                            error={errors.fso_name_seized_under_custody_of}
                                            onChange={(text) => handleTextChange("fso_name_seized_under_custody_of", text)}
                                        /></td></tr>
                                    ) : null}
                                    {values && values.seized_under_custody_of.includes('DO')? (
                                       <tr> <th colSpan="2">DO</th>
                                        <td colSpan="2"> <TextInput
                                            placeholder={`Seized Under Custody Of`}
                                            type="text"
                                            name="name_seized_under_custody_of"
                                            id="name_seized_under_custody_of"
                                            value={values.name_seized_under_custody_of}
                                            error={errors.name_seized_under_custody_of}
                                            onChange={(e) => handleTextChange("name_seized_under_custody_of", e.target.value)}
                                        /></td></tr>
                                    ) : null}
                                    {values && values.seized_under_custody_of.includes('FBO') ? (
                                       <tr> <th colSpan="2">Food Business Operator</th>
                                       <td colSpan="2">
                                       <TextInput
                                            placeholder={`Seized Under Custody Of`}
                                            type="text"
                                            name="name_seized_under_custody_of"
                                            id="name_seized_under_custody_of"
                                            value={values.name_seized_under_custody_of}
                                            error={errors.name_seized_under_custody_of}
                                            onChange={(e) => handleTextChange("name_seized_under_custody_of", e.target.value)}
                                        />
                                       </td>
                                       </tr>
                                    ): null}
                                    {values && values.seized_under_custody_of.includes('Other Official') ? (
                                       <tr> <th colSpan="2">Other Official</th>
                                       <td colSpan="2">
                                       <TextInput
                                            placeholder={`Seized Under Custody Of`}
                                            type="text"
                                            name="other_name_seized_under_custody_of"
                                            id="other_name_seized_under_custody_of"
                                            value={values.other_name_seized_under_custody_of}
                                            error={errors.other_name_seized_under_custody_of}
                                            onChange={(e) => handleTextChange("other_name_seized_under_custody_of", e.target.value)}
                                        />
                                       </td>
                                       </tr>
                                    ): null}
                                    {values && values.seized_under_custody_of.includes('Judicial Magistrate')&&(<>
                                        
                                        <tr>
                                    <th colSpan="2"> <center>Property Returned </center></th>
                                    <td colSpan="2">
                                        <DropDownInput
                                            placeholder={`Property Returned`}
                                            type="text"
                                            name="return_property"
                                            id="return_property"
                                            action="dropdown"
                                            items={Rc}
                                            value={values.return_property}
                                            error={errors.return_property}
                                            onChange={(text) => handleTextChange("return_property", text)}
                                        />
                                    </td>
                                </tr>
                                {values && values.return_property.includes('Yes')&&(
                                     <tr>
                                     <th colSpan="2"> <center>Upload Property Documents </center></th>
                                     <td colSpan="2">
                                         <TextInput
                                             placeholder={`Upload Property Documents`}
                                             type="file"
                                             name="upload_property"
                                             id="upload_property"
                                             error={errors.upload_property}
                                             onChange={(e) => handleTextChange("upload_property", e.target.files[0])}
                                         />
                                     </td>
                                 </tr>
                                )}
                                    </>)}
                                <tr>
                                    <th colSpan="2"> <center>Whether the Food is Destroyed </center></th>
                                    <td colSpan="2">
                                        <DropDownInput
                                            placeholder={`Whether Food is destroyed`}
                                            type="text"
                                            name="food_destroyed"
                                            id="food_destroyed"
                                            action="dropdown"
                                            items={Destroy}
                                            value={values.food_destroyed}
                                            error={errors.food_destroyed}
                                            onChange={(text) => handleTextChange("food_destroyed", text)}
                                        />
                                    </td>
                                </tr>
                                {values && values.food_destroyed.includes('Yes') ? (
                                    <tr>
                                        <th colSpan="2"><center>Co-ordinated Officers:</center></th>
                                        <td colSpan="2">
                                            <TextAreaInput
                                                placeholder={`Co-ordinated Officers`}
                                                type="text"
                                                name="coordinated_officers"
                                                id="coordinated_officers"
                                                value={values.coordinated_officers}
                                                error={errors.coordinated_officers}
                                                onChange={(e) => handleTextChange("coordinated_officers", e.target.value)}
                                            />
                                        </td>
                                    </tr>

                                ) : null}
                                
                                <br />
                                <div className="form-group">
                                    <button
                                        onClick={() => props.history.push("/app/reports/")}
                                        className="btn btn-success"
                                        type="button">
                                        Cancel</button>
                                    <button
                                        disabled={disableSubmit}
                                        onClick={handleSubmit}
                                        type="button"
                                        className="btn btn-success ml-3">Submit</button>
                                </div>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateVehicleInspectionReports: (data) => dispatch(createVehicleInspectionReports(data)),
    onEditVehicleInspectionReports: (data, record_id) => dispatch(editVehicleInspectionReports(data, record_id)),
    onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
    ongetFoodCategory: (data) => dispatch(getFoodCategory(data)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),



});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    fsoOfficers: state.home.fsoOfficers,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInspectionReport);