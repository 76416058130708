import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getComissionerateEPOReports } from "../../components/reducers/actions"
import DataTable from "react-data-table-component";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import TextInput from "../../components/inputs/TextInput";

const AppList = (props) => {
  const { data, handleSelectRow } = props;
  return (
    <div onClick={() => handleSelectRow(data)}>
    </div>
  );
};
const ViewButton =(props) =>{
  const { data, handleSelectRow } = props;
  return(
  <button className="btn btn-primary btn-edit" onClick={()=> handleSelectRow(data)} > View
    <i className="fas fa-eye icon-edit" aria-hidden="true" ></i>
    </button>
  );
};
const EditButton = (props) => {
  const { data, handleEdit } = props;
  return (
    <button className="btn btn-primary btn-edit" onClick={() => handleEdit(data)}>Edit
      <i className="fas fa-pencil-alt icon-edit" aria-hidden="true"></i>
    </button>
  );
};

const CommissionerateEPOList = (props) => {
    const { districts, onGetcommissionerateEPOReports,wardsCategory, user, wards } = props;
    const [values, setValues] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);
    const [page, setPage] = useState(1);
    const [District, setDistrict] = useState({})
    const countPerPage = 10;

    const reportList = async () => {
      const resp = await onGetcommissionerateEPOReports({
        page: page,
        per_page: countPerPage,
      });
      if(resp && resp.response) {
        const data = resp.response
        setValues(data)
      }      
    };
  
    useEffect(() => {
      if((user && user.user_types && user.user_types.includes("do")) || (user && user.user_types && user.user_types.includes("fso"))) {
        setDistrict(user.district);
    }
      reportList();
       // eslint-disable-next-line 
    }, [page]);
    useEffect(()=>{
      wardsCategory.map((o)=>{
          if(o.id == searchFilter.area_code)
          return (
            setSearchFilter({
                  ...searchFilter,
                  area_name: o.ward_name
              })
          )
      })
  },[searchFilter.area_code])
    const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        fontSize: "18px",
        paddingLeft: "30px",
        paddingRight: "30px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        minHeight: "60px",
        color: "#45b151",
        paddingLeft: "25px",
        paddingRight: "30px",
        fontSize: "16px",
        background: "rgba(70,179,84,.10196078431372549)",
      },
    },
    cells: {
      style: {
        paddingLeft: "25px",
      },
    },
  };

    const getDistrict = (district) => {
      let districtName = null;
      districts &&
        districts.forEach((item) => {
          if (item.id === district) {
            districtName = item.name;
          }
        });
      return districtName;
    };

    const columns = [
      {
        omit: true,
        cell: (row) => <AppList data={row} handleSelectRow={handleSelectRow} />,
        selector: "id",
        sortable: true,
      },
      {
        name: "District",
        cell: (row) => getDistrict(row.district),
        selector: "district",
        sortable: true
      },
      
      {
        name: "Created At",
        cell: (row) =>
         row.created_at && moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm"),
        selector: "created_at",
        sortable: true,
      },
      {
        name:"Last Edited",
       cell:(row) =>
       row.last_updated_at && row.last_updated_at ? moment(row.last_updated_at.toString()).format("DD-MM-YYYY hh:mm") : '-',
       selector: "Last_edited",
        sortable:true,
      },
      {
        name: "Action",
        cell: (row) =>
        moment().diff(moment(row.created_at.toString()), 'hours') < 24 ? 
        (<><EditButton data={row} handleEdit={handleEdit} /> <ViewButton data={row} handleSelectRow={handleSelectRow} /></>) : <ViewButton data={row} handleSelectRow={handleSelectRow} />,
        sortable: true,
      }
    ];

    const handleTextChange = (key, value) => {
      if(key === 'date') {
        const updatedValues = { ...searchFilter, [key]: moment(value).format() };
        setSearchFilter(updatedValues);
      }
      const updatedValues = { ...searchFilter, [key]: value };
      setSearchFilter(updatedValues);
    }

    const handleClick = () => {
      if(searchFilter) {
        async function fetchData() {
          const resp = await onGetcommissionerateEPOReports({
            district: searchFilter.district,
            fromdate: searchFilter.fromdate,
            todate: searchFilter.todate
          });
          if(resp && resp.response) {
            const data = resp.response
            console.log(data)
            setValues(data)
          }
        }
        fetchData();
      }
    }

    const handleEdit = (data) => {
      console.log(data);
      props.history.push(`/app/reports/comissionerate-EPO-reports/${data.id}/edit`, { values: data });
    };

    const handleSelectRow = (data) => {
      console.log(data);
      props.history.push(`/app/display-reports/comissionerate-EPO-list/${data.id}`, { records: data });
    };

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Comissionerate EPO Reports List</h1>
            <div className="main">
            {user && user.user_types && user.user_types.includes("ho") ? (
                <div className="row" style={{ marginBottom : 20 }}>
                 <div className="col-sm-2">
                    <DropDownInput
                      placeholder={`Select District`}
                      type="text"
                      name="district"
                      action="district"
                      value={searchFilter.district}
                      items={districts}
                      onChange={(text) => handleTextChange("district", text)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <DropDownInput
                      placeholder={`Area Code`}
                      type="text"
                      name="area_code"
                      action="wards"
                      value={searchFilter.wards}
                      items={wards}
                      onChange={(text) => handleTextChange("area_code", text)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <DropDownInput
                      placeholder={`Area Name`}
                      type="text"
                      name="area_name"
                      action="wards"
                      value={searchFilter.wards}
                      items={wards}
                      onChange={(text) => handleTextChange("area_name", text)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`From Date`}
                        name="fromdate"
                        value={searchFilter.fromdate}
                        onChangeText={(text) => handleTextChange('fromdate', text)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`To Date`}
                        name="todate"
                        value={searchFilter.todate}
                        onChangeText={(text) => handleTextChange('todate', text)}
                    />
                  </div>
                  <div className="col-sm-1">
                    <button
                        type="button"
                        className="btn btn-secondary ml-3"
                        onClick={handleClick}>
                    View</button>
                  </div>
                </div>
              ) : (
                <div className="row" style={{ marginBottom : 20 }}>
                <div className="col-sm-2">  <DropDownInput
                      placeholder={`Select District`}
                      type="text"
                      isDisabled
                      name="district"
                      value={District}
                      items={districts}
                      onChange={(value) => handleTextChange("district", value)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <DropDownInput
                      placeholder={`Area Code`}
                      type="text"
                      name="area_code"
                      action="district"
                      value={searchFilter.wards}
                      items={wardsCategory}
                      onChange={(text) => handleTextChange("area_code", text)}
                    />
                  </div>

                  <div className="col-sm-2">
                    <TextInput
                      placeholder={`Area Name`}
                      type="text"
                      name="area_name"
                      value={searchFilter.area_name}
                      onChange={(e) => handleTextChange("area_name", e.target.value)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`From Date`}
                        name="fromdate"
                        value={searchFilter.fromdate}
                        onChangeText={(text) => handleTextChange('fromdate', text)}
                    />
                  </div>
                  <div className="col-sm-2">
                    <Datepicker
                        placeholder={`To Date`}
                        name="todate"
                        value={searchFilter.todate}
                        onChangeText={(text) => handleTextChange('todate', text)}
                    />
                  </div>
                  <div className="col-sm-1">
                    <button
                        type="button"
                        className="btn btn-secondary ml-3"
                        onClick={handleClick}>
                    View</button>
                  </div>
                  
                </div>
              )}
                <DataTable
                  columns={columns}
                  data={values.results}             
                  onRowClicked={handleSelectRow}
                  pagination
                  paginationServer
                  paginationPerPage={countPerPage}
                  paginationTotalRows={values.count}
                  customStyles={customStyles}
                  noHeader
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  highlightOnHover
                  noDataComponent={
                    <div style={{ color: "#858796", marginTop: 20 }}>
                      No data available in table
                    </div>
                  }
                  onChangePage={(page) => setPage(page)}
                />
          </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
  onGetcommissionerateEPOReports: (data) => dispatch(getComissionerateEPOReports(data))
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  user: state.home.user,
  wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(CommissionerateEPOList);