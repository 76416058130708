import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import { createSpecialProgramReports, editSpecialProgramReports } from "../../components/reducers/actions"
import Datepicker from '../../components/inputs/Datepicker'
import { useParams } from "react-router-dom";
import moment from 'moment';

const SpecialProgramReports = (props) => {
    const { districts, user, wards, onCreateSpecialProgramReports,wardsCategory, onEditSpecialProgramReports } = props;
    const [values, setValues] = useState({
        'name_of_the_fso_do' : '',
        'date':'',
        'area_name':'',
        'area_code':'',
        'foscos_inspection_completed_upto_date':'',
        'foscos_inspection_completed_during_the_day':'',
        'foscoris_inspection_completed_upto_date':'',
        'foscoris_inspection_completed_during_the_day':'',
        'fostac_basic_training_attended_fbos_upto_date':'',
        'fostac_advance_training_attended_fbos_upto_date':'',
        'fostac_special_training_attended_fbos_upto_date':'',
        'fostac_basic_training_attended_fbos_during_the_day':'',
        'fostac_advance_training_attended_fbos_during_the_day':'',
        'fostac_special_training_attended_fbos_during_the_day':'',
        'raid_conducted_no_of_shops_inspected':'',
        'raid_conducted_no_of_notice_issued':'',
        'raid_conducted_seizures_made_panmasala_gutka':'',
        'raid_conducted_seizures_made_solid_food_items_in_kg':'',
        'raid_conducted_seizures_made_solid_food_items_in_packets_boxes':'',
        'raid_conducted_plastic_seized':'',
        'raid_conducted_seizures_made_on_cool_drinks_and_any_other_liquid_food_items_in_ltr':'',
        'raid_conducted_seizure_made_on_others':'',
        'clean_street_food_no_of_mobile_vendor_inspected':'',
        'clean_street_food_no_of_mobile_vendor_motivated':'',
        'ruco_no_of_establishment_inspected':'',
        'ruco_no_of_establishment_motivated':'',
        'ruco_quantity_of_re_used_oil_sent_to_bio_diesel':'',
        'bhog_no_of_temples_visited':'',
        'bhog_no_of_temples_motivated':'',
        'no_of_establishment_inspected_on_hygine_rating':'',
        'no_of_establishment_motivated_on_hygine_rating':'',
        'district' : ''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'name_of_the_fso_do',
            'date',
            'area_name',
            'area_code',
            'foscos_inspection_completed_during_the_day',
            'foscoris_inspection_completed_upto_date',
            'foscoris_inspection_completed_during_the_day',
            'fostac_basic_training_attended_fbos_upto_date',
            'fostac_advance_training_attended_fbos_upto_date',
            'fostac_special_training_attended_fbos_upto_date',
            'fostac_basic_training_attended_fbos_during_the_day',
            'fostac_advance_training_attended_fbos_during_the_day',
            'fostac_special_training_attended_fbos_during_the_day',
            'raid_conducted_no_of_shops_inspected',
            'raid_conducted_no_of_notice_issued',
            'raid_conducted_seizures_made_panmasala_gutka',
            'raid_conducted_seizures_made_solid_food_items_in_kg',
            'raid_conducted_seizures_made_solid_food_items_in_packets_boxes',
            'raid_conducted_plastic_seized',
            'raid_conducted_seizure_made_on_others',
            'clean_street_food_no_of_mobile_vendor_inspected',
            'clean_street_food_no_of_mobile_vendor_motivated',
            'ruco_no_of_establishment_inspected',
            'ruco_no_of_establishment_motivated',
            'ruco_quantity_of_re_used_oil_sent_to_bio_diesel',
            'bhog_no_of_temples_visited',
            'bhog_no_of_temples_motivated',
            'no_of_establishment_inspected_on_hygine_rating',
            'no_of_establishment_motivated_on_hygine_rating',
            'district' 
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditSpecialProgramReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/special-program-list/`);
            };

        } else {       
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateSpecialProgramReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Programme Reports</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_fso_do"
                            >Name of the FSO / DO *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the FSO/DO`}
                                    type="text"
                                    name="name_of_the_fso_do"
                                    id="name_of_the_fso_do"
                                    value={values.name_of_the_fso_do}
                                    error={errors.name_of_the_fso_do}
                                    onChange={(e) => handleTextChange("name_of_the_fso_do", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area code *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    type="text"
                                    name="area_code"
                                    action="area_code"
                                    value={values.area_code}
                                    error={errors.area_code}
                                    items={wardsCategory}
                                    onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}

                                />
                            </div>
                        </div>                     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="foscos_inspection_completed_during_the_day"
                            >Foscos Inspection Completed During the Day *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`foscos inspection completed during the day`}
                                    type="number"
                                    name="foscos_inspection_completed_during_the_day"
                                    id="foscos_inspection_completed_during_the_day"
                                    value={values.foscos_inspection_completed_during_the_day}
                                    error={errors.foscos_inspection_completed_during_the_day}
                                    onChange={(e) =>handleTextChange("foscos_inspection_completed_during_the_day", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="foscoris_inspection_completed_upto_date"
                            >Foscos Inspection Completed Upto Previous Date *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`foscos inspection completed upto date`}
                                    type="number"
                                    name="foscoris_inspection_completed_upto_date"
                                    id="foscoris_inspection_completed_upto_date"
                                    value={values.foscoris_inspection_completed_upto_date}
                                    error={errors.foscoris_inspection_completed_upto_date}
                                    onChange={(e) => handleTextChange("foscoris_inspection_completed_upto_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="foscoris_inspection_completed_during_the_day"
                            >Foscoris Inspection Completed During the Day *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`foscoris inspection`}
                                    type="number"
                                    name="foscoris_inspection_completed_during_the_day"
                                    id="foscoris_inspection_completed_during_the_day"
                                    value={values.foscoris_inspection_completed_during_the_day}
                                    error={errors.foscoris_inspection_completed_during_the_day}
                                    onChange={(e) => handleTextChange("foscoris_inspection_completed_during_the_day", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fostac_basic_training_attended_fbos_upto_date"
                            >Number of FBO's Attended Basic Fostac Training(Upto Date) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of FBO's Attended basic fostac training`}
                                    type="number"
                                    name="fostac_basic_training_attended_fbos_upto_date"
                                    id="fostac_basic_training_attended_fbos_upto_date"
                                    value={values.fostac_basic_training_attended_fbos_upto_date}
                                    error={errors.fostac_basic_training_attended_fbos_upto_date}
                                    onChange={(e) => handleTextChange("fostac_basic_training_attended_fbos_upto_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fostac_advance_training_attended_fbos_upto_date"
                            >Number of FBO's Attended Advance Fostac Training(Upto Date) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of FBO's Attended advance fostac training`}
                                    type="number"
                                    name="fostac_advance_training_attended_fbos_upto_date"
                                    id="fostac_advance_training_attended_fbos_upto_date"
                                    value={values.fostac_advance_training_attended_fbos_upto_date}
                                    error={errors.fostac_advance_training_attended_fbos_upto_date}
                                    onChange={(e) => handleTextChange("fostac_advance_training_attended_fbos_upto_date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fostac_special_training_attended_fbos_upto_date"
                            >Number of FBO's Attended Special Fostac Training(Upto Date) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of FBO's Attended special fostac training`}
                                    type="number"
                                    name="fostac_special_training_attended_fbos_upto_date"
                                    id="fostac_special_training_attended_fbos_upto_date"
                                    value={values.fostac_special_training_attended_fbos_upto_date}
                                    error={errors.fostac_special_training_attended_fbos_upto_date}
                                    onChange={(e) => handleTextChange("fostac_special_training_attended_fbos_upto_date", e.target.value)}
                                />
                            </div>
                        </div>
                      
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fostac_basic_training_attended_fbos_during_the_day"
                            >Number of FBO's Attended Basic Fostac Training(During the Day) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of FBO's Attended basic fostac training`}
                                    type="number"
                                    name="fostac_basic_training_attended_fbos_during_the_day"
                                    id="fostac_basic_training_attended_fbos_during_the_day"
                                    value={values.fostac_basic_training_attended_fbos_during_the_day}
                                    error={errors.fostac_basic_training_attended_fbos_during_the_day}
                                    onChange={(e) => handleTextChange("fostac_basic_training_attended_fbos_during_the_day", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fostac_advance_training_attended_fbos_during_the_day"
                            >Number of FBO's Attended Advance Fostac Training(During the Day) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of FBO's Attended advance fostac training`}
                                    type="number"
                                    name="fostac_advance_training_attended_fbos_during_the_day"
                                    id="fostac_advance_training_attended_fbos_during_the_day"
                                    value={values.fostac_advance_training_attended_fbos_during_the_day}
                                    error={errors.fostac_advance_training_attended_fbos_during_the_day}
                                    onChange={(e) => handleTextChange("fostac_advance_training_attended_fbos_during_the_day", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fostac_special_training_attended_fbos_during_the_day"
                            >Number of FBO's Attended Special Fostac Training(During the Day) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of FBO's Attended special fostac training`}
                                    type="number"
                                    name="fostac_special_training_attended_fbos_during_the_day"
                                    id="fostac_special_training_attended_fbos_during_the_day"
                                    value={values.fostac_special_training_attended_fbos_during_the_day}
                                    error={errors.fostac_special_training_attended_fbos_during_the_day}
                                    onChange={(e) => handleTextChange("fostac_special_training_attended_fbos_during_the_day", e.target.value)}
                                />
                            </div>
                        </div>
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_no_of_shops_inspected"
                            > Raid Conducted (Number of Shops Inspected) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of raid conducted shops`}
                                    type="number"
                                    name="raid_conducted_no_of_shops_inspected"
                                    id="raid_conducted_no_of_shops_inspected"
                                    value={values.raid_conducted_no_of_shops_inspected}
                                    error={errors.raid_conducted_no_of_shops_inspected}
                                    onChange={(e) => handleTextChange("raid_conducted_no_of_shops_inspected", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_no_of_notice_issued"
                            >Raid Conducted (Number of Notice Issued) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of notice issued`}
                                    type="number"
                                    name="raid_conducted_no_of_notice_issued"
                                    id="raid_conducted_no_of_notice_issued"
                                    value={values.raid_conducted_no_of_notice_issued}
                                    error={errors.raid_conducted_no_of_notice_issued}
                                    onChange={(e) => handleTextChange("raid_conducted_no_of_notice_issued", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_seizures_made_panmasala_gutka"
                            >Raid Conducted (Seizured panmasala and gutka in kg's) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Seizure made on panmasala and gutka`}
                                    type="number"
                                    name="raid_conducted_seizures_made_panmasala_gutka"
                                    id="raid_conducted_seizures_made_panmasala_gutka"
                                    value={values.raid_conducted_seizures_made_panmasala_gutka}
                                    error={errors.raid_conducted_seizures_made_panmasala_gutka}
                                    onChange={(e) => handleTextChange("raid_conducted_seizures_made_panmasala_gutka", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_seizures_made_solid_food_items_in_kg"
                            >Raid Conducted (Seizure made on Spoiled Solid Food Item in kg's) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`seizure made on spoiled solid food item`}
                                    type="number"
                                    name="raid_conducted_seizures_made_solid_food_items_in_kg"
                                    id="raid_conducted_seizures_made_solid_food_items_in_kg"
                                    value={values.raid_conducted_seizures_made_solid_food_items_in_kg}
                                    error={errors.raid_conducted_seizures_made_solid_food_items_in_kg}
                                    onChange={(e) => handleTextChange("raid_conducted_seizures_made_solid_food_items_in_kg", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_seizures_made_solid_food_items_in_packets_boxes"
                            >Raid Conducted (Seizure Made on  Expired Solid Food Items in Packets/Boxes) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Seizure made on Expired solid food items in packets/boxes`}
                                    type="number"
                                    name="raid_conducted_seizures_made_solid_food_items_in_packets_boxes"
                                    id="raid_conducted_seizures_made_solid_food_items_in_packets_boxes"
                                    value={values.raid_conducted_seizures_made_solid_food_items_in_packets_boxes}
                                    error={errors.raid_conducted_seizures_made_solid_food_items_in_packets_boxes}
                                    onChange={(e) => handleTextChange("raid_conducted_seizures_made_solid_food_items_in_packets_boxes", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_plastic_seized"
                            >Raid Conducted (OTUP Seized) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Seizure made on OTUP`}
                                    type="number"
                                    name="raid_conducted_plastic_seized"
                                    id="raid_conducted_plastic_seized"
                                    value={values.raid_conducted_plastic_seized}
                                    error={errors.raid_conducted_plastic_seized}
                                    onChange={(e) => handleTextChange("raid_conducted_plastic_seized", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_seizures_made_on_liquid_food_items"
                            >Raid Conducted (Seizure Made on Expired Cool Drinks or Any Other Liquid Food Items in Ltr) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Seizure made on cool drinks or any other liquid food items`}
                                    type="number"
                                    name="raid_conducted_seizures_made_on_cool_drinks_and_any_other_liquid_food_items_in_ltr"
                                    id="raid_conducted_seizures_made_on_cool_drinks_and_any_other_liquid_food_items_in_ltr"
                                    value={values.raid_conducted_seizures_made_on_liquid_food_items}
                                    error={errors.raid_conducted_seizures_made_on_liquid_food_items}
                                    onChange={(e) => handleTextChange("raid_conducted_seizures_made_on_liquid_food_items", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="raid_conducted_seizure_made_on_others"
                            >Raid Conducted (Other Seizure's) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`other Seizures`}
                                    type="number"
                                    name="raid_conducted_seizure_made_on_others"
                                    id="raid_conducted_seizure_made_on_others"
                                    value={values.raid_conducted_seizure_made_on_others}
                                    error={errors.raid_conducted_seizure_made_on_others}
                                    onChange={(e) => handleTextChange("raid_conducted_seizure_made_on_others", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="clean_street_food_no_of_mobile_vendor_inspected"
                            >Street Food  Mobile Vendors (Number of Mobile vendor Inspected) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Mobile vendors inspected`}
                                    type="number"
                                    name="clean_street_food_no_of_mobile_vendor_inspected"
                                    id="clean_street_food_no_of_mobile_vendor_inspected"
                                    value={values.clean_street_food_no_of_mobile_vendor_inspected}
                                    error={errors.clean_street_food_no_of_mobile_vendor_inspected}
                                    onChange={(e) => handleTextChange("clean_street_food_no_of_mobile_vendor_inspected", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="clean_street_food_no_of_mobile_vendor_motivated"
                            >Notice Issued For CSFH *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Notice Issued For CSFH`}
                                    type="number"
                                    name="clean_street_food_no_of_mobile_vendor_motivated"
                                    id="clean_street_food_no_of_mobile_vendor_motivated"
                                    value={values.clean_street_food_no_of_mobile_vendor_motivated}
                                    error={errors.clean_street_food_no_of_mobile_vendor_motivated}
                                    onChange={(e) => handleTextChange("clean_street_food_no_of_mobile_vendor_motivated", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ruco_no_of_establishment_inspected"
                            >RUCO (Number Of Establishment Complied) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of establishment complied`}
                                    type="number"
                                    name="ruco_no_of_establishment_inspected"
                                    id="ruco_no_of_establishment_inspected"
                                    value={values.ruco_no_of_establishment_inspected}
                                    error={errors.ruco_no_of_establishment_inspected}
                                    onChange={(e) => handleTextChange("ruco_no_of_establishment_inspected", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ruco_no_of_establishment_motivated"
                            >RUCO (Number Of Establishment Motivated) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of establishment Motivated`}
                                    type="number"
                                    name="ruco_no_of_establishment_motivated"
                                    id="ruco_no_of_establishment_motivated"
                                    value={values.ruco_no_of_establishment_motivated}
                                    error={errors.ruco_no_of_establishment_motivated}
                                    onChange={(e) => handleTextChange("ruco_no_of_establishment_motivated", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="ruco_quantity_of_re_used_oil_sent_to_bio_diesel"
                            >RUCO (Quantity Of Used cooking Oil Sent for Bio-Diesel) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Quantity Of Used cooking Oil Sent for Bio-Diesel`}
                                    type="number"
                                    name="ruco_quantity_of_re_used_oil_sent_to_bio_diesel"
                                    id="ruco_quantity_of_re_used_oil_sent_to_bio_diesel"
                                    value={values.ruco_quantity_of_re_used_oil_sent_to_bio_diesel}
                                    error={errors.ruco_quantity_of_re_used_oil_sent_to_bio_diesel}
                                    onChange={(e) => handleTextChange("ruco_quantity_of_re_used_oil_sent_to_bio_diesel", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="bhog_no_of_temples_visited"
                            >BHOG (Number of Temple's Motivated) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Temples Visited`}
                                    type="number"
                                    name="bhog_no_of_temples_visited"
                                    id="bhog_no_of_temples_visited"
                                    value={values.bhog_no_of_temples_visited}
                                    error={errors.bhog_no_of_temples_visited}
                                    onChange={(e) => handleTextChange("bhog_no_of_temples_visited", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="bhog_no_of_temples_motivated"
                            >Number Of BHOG Certified *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of BHOG motivated`}
                                    type="number"
                                    name="bhog_no_of_temples_motivated"
                                    id="bhog_no_of_temples_motivated"
                                    value={values.bhog_no_of_temples_motivated}
                                    error={errors.bhog_no_of_temples_motivated}
                                    onChange={(e) => handleTextChange("bhog_no_of_temples_motivated", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_establishment_inspected_on_hygine_rating"
                            >Hygiene Rating (Number Of Establishment Motivated) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of establishment motivated`}
                                    type="number"
                                    name="no_of_establishment_inspected_on_hygine_rating"
                                    id="no_of_establishment_inspected_on_hygine_rating"
                                    value={values.no_of_establishment_inspected_on_hygine_rating}
                                    error={errors.no_of_establishment_inspected_on_hygine_rating}
                                    onChange={(e) => handleTextChange("no_of_establishment_inspected_on_hygine_rating", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_establishment_motivated_on_hygine_rating"
                            >Hygine Rating (Number Of Establishment Certified) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of Establishment certified`}
                                    type="number"
                                    name="no_of_establishment_motivated_on_hygine_rating"
                                    id="no_of_establishment_motivated_on_hygine_rating"
                                    value={values.no_of_establishment_motivated_on_hygine_rating}
                                    error={errors.no_of_establishment_motivated_on_hygine_rating}
                                    onChange={(e) => handleTextChange("no_of_establishment_motivated_on_hygine_rating", e.target.value)}
                                />
                            </div>
                        </div>                
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateSpecialProgramReports: (data) => dispatch(createSpecialProgramReports(data)),
    onEditSpecialProgramReports: (data, record_id) => dispatch(editSpecialProgramReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SpecialProgramReports);