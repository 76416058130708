import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from '../../components/inputs/TextAreaInput';
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createPanmasalaReports, editPanmasalaReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const Trader = [
        {id:"0", name:"Retailer"},
        {id:"1", name:"Manufacturer"},
        {id:"2", name:"Wholesaler"},
        {id:"3", name:"Transporter"},
        {id:"4", name:"Others"}
]
const Sample =[
    {id:"0", name:"Yes"},
    {id:"1", name:"No"},
]
const Official = [
    {id:"0", name:"FSO"},
    {id:"1", name:"DO"},
    {id:"2", name:"FBO"},
    {id:"3", name:"BDO"},
    {id:"4", name:"Police/Thasildhar"},
    {id:"5", name:"Municipalty Officer"},
]
const PanmasalaReport = (props) => {
    const { districts, user, wards,wardsCategory, onCreatePanmasalaReports,ongetDist, onEditPanmasalaReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        'area_name':'',
        'type_of_trader':'',
        'name_of_the_shop' : '',
        'address_of_the_shop' : '',
        'witness_name':'',
        'sample_lifted':'',
        'seized_under_custody':'',
        'license_registration_number':'',
        'witness_address':'',
        'quantity_seized' : '',
        'approximate_value' : '',
        'action_taken' : '',
        'other_type_of_trader':'',
        'documents':'',
        'name_of_official':'',
        'address_of_official':'',
        'inspected_during_the_day':'',
        'sale_banned_items':'',
        'offense_identified':'',
        'fine_imposed':'',
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();
    
    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            console.log(recordData)
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district', 
            'date',  
        'type_of_trader',
         'name_of_the_shop',
         'address_of_the_shop',
         'sample_lifted',
         'license_registration_number',
         'witness_name',
         'witness_address', 
         'quantity_seized', 
         'approximate_value',
        'action_taken', 
         'name_of_official',
        'address_of_official'
         ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditPanmasalaReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/panmasala-list/`);
            };

        } else {       
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreatePanmasalaReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }
    return (
        <div>
            <div className="container-fluid">
                <h1 className="h3 mb-4 text-gray-800">Panmasala and Gutkha Action Taken Report</h1>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >Name of the district : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                            ) : (
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="district"
                                    >Name of the district : </label>
                                    <div className="col-sm-8">
                                        <DropDownInput
                                            type="text"
                                            name="district"
                                            value={values.district}
                                            error={errors.district}
                                            items={districts}
                                            onChange={(text) => handleTextChange("district", text)}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                                >Area Code *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                                >Area Name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Area Name`}
                                        type="text"
                                        name="area_name"
                                        value={values.area_name}
                                        error={errors.area_name}
                                        onChange={(e) => handleTextChange("area_name", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date"
                                >Date *: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Date`}
                                        name="date"
                                        id="date"
                                        value={values.date}
                                        error={errors.date}
                                        onChangeText={(text) => handleTextChange('date', text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type_of_trader"
                                >Type Of Trader *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Type of Trader`}
                                        type="number"
                                        name="type_of_trader"
                                        items={Trader}
                                        action="dropdown"
                                        value={values.type_of_trader}
                                        error={errors.type_of_trader}
                                        onChange={(text) => handleTextChange("type_of_trader", text)}
                                    />
                                </div>
                            </div>
                            {values && values.type_of_trader.includes('Others')? (
                               <div className="form-group row">
                               <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_trader"
                               > </label>
                               <div className="col-sm-8">
                                   <TextInput
                                       placeholder={`Type of Trader`}
                                       type="text"
                                       name="other_type_of_trader"
                                       value={values.other_type_of_trader}
                                       error={errors.other_type_of_trader}
                                       onChange={(e) => handleTextChange("other_type_of_trader", e.target.value)}
                                   />
                               </div>
                           </div> 
                            ): null}
                             <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="inspected_during_the_day"
                                >Number of shops inspected during the day *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of shops inspected during the day`}
                                        type="number"
                                        name="inspected_during_the_day"
                                        value={values.inspected_during_the_day}
                                        error={errors.inspected_during_the_day}
                                        onChange={(e) => handleTextChange("inspected_during_the_day", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sale_banned_items"
                                >Number of shops found sale of banned food products *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of shops found sale of banned food products`}
                                        type="number"
                                        name="sale_banned_items"
                                        value={values.sale_banned_items}
                                        error={errors.sale_banned_items}
                                        onChange={(e) => handleTextChange("sale_banned_items", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="quantity_seized"
                                >Quantity seized in Kgs. *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Quantity seized in Kgs.`}
                                        type="number"
                                        name="quantity_seized"
                                        value={values.quantity_seized}
                                        error={errors.quantity_seized}
                                        onChange={(e) => handleTextChange("quantity_seized", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="offense_identified"
                                >Number of compound offense identified *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of compound offense identified`}
                                        type="number"
                                        name="offense_identified"
                                        value={values.offense_identified}
                                        error={errors.offense_identified}
                                        onChange={(e) => handleTextChange("offense_identified", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="fine_imposed"
                                >Fine Imposed (RS) *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Fine Imposed (RS)`}
                                        type="number"
                                        name="fine_imposed"
                                        value={values.fine_imposed}
                                        error={errors.fine_imposed}
                                        onChange={(e) => handleTextChange("fine_imposed", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sample_lifted"
                                >Whether Sample Lifted *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Sample Lifted`}
                                        type="text"
                                        name="sample_lifted"
                                        items={Sample}
                                        action="dropdown"
                                        value={values.sample_lifted}
                                        error={errors.sample_lifted}
                                        onChange={(text) => handleTextChange("sample_lifted", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="seized_under_custody"
                                >Seized Under Custody Of *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Seized Under Custody Of`}
                                        type="text"
                                        items={Official}
                                        action="dropdown"
                                        name="seized_under_custody"
                                        value={values.seized_under_custody}
                                        error={errors.seized_under_custody}
                                        onChange={(text) => handleTextChange("seized_under_custody", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="name_of_official"
                                >Name of the Official *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Name of the official`}
                                        type="text"
                                        name="name_of_official"
                                        value={values.name_of_official}
                                        error={errors.name_of_official}
                                        onChange={(e) => handleTextChange("name_of_official", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="address_of_official"
                                >Address Of Official *: </label>
                                <div className="col-sm-8">
                                    <TextAreaInput
                                        placeholder={`Address of official`}
                                        type="text"
                                        name="address_of_official"
                                        value={values.address_of_official}
                                        error={errors.address_of_official}
                                        onChange={(e) => handleTextChange("address_of_official", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="approximate_value"
                                >Approximate value in Rs. *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Approximate value in Rs.`}
                                        type="number"
                                        name="approximate_value"
                                        value={values.approximate_value}
                                        error={errors.approximate_value}
                                        onChange={(e) => handleTextChange("approximate_value", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_shop"
                                >Name Of The FBO Premises Where Seized *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Name of the shop inspected`}
                                        type="text"
                                        name="name_of_the_shop"
                                        value={values.name_of_the_shop}
                                        error={errors.name_of_the_shop}
                                        onChange={(e) => handleTextChange("name_of_the_shop", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_shop"
                                >Address Of The Premises Where Seized *: </label>
                                <div className="col-sm-8">
                                    <TextAreaInput
                                        placeholder={`Address Of the shop Inspected`}
                                        type="text"
                                        name="address_of_the_shop"
                                        value={values.address_of_the_shop}
                                        error={errors.address_of_the_shop}
                                        onChange={(e) => handleTextChange("address_of_the_shop", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="license_registration_number"
                                >License/Registration Number *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`License/Registration Number`}
                                        type="text"
                                        name="license_registration_number"
                                        value={values.license_registration_number}
                                        error={errors.license_registration_number}
                                        onChange={(e) => handleTextChange("license_registration_number", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="witness_name"
                                >Witness Name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Witness Name`}
                                        type="text"
                                        name="witness_name"
                                        value={values.witness_name}
                                        error={errors.witness_name}
                                        onChange={(e) => handleTextChange("witness_name", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="witness_address"
                                >Witness Address *: </label>
                                <div className="col-sm-8">
                                    <TextAreaInput
                                        placeholder={`Witness Address`}
                                        type="text"
                                        name="witness_address"
                                        value={values.witness_address}
                                        error={errors.witness_address}
                                        onChange={(e) => handleTextChange("witness_address", e.target.value)}
                                    />
                                </div>
                            </div>
                           
                           
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="action_taken"
                                >Action Taken *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Action Taken`}
                                        type="text"
                                        name="action_taken"
                                        value={values.action_taken}
                                        error={errors.action_taken}
                                        onChange={(e) => handleTextChange("action_taken", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="documents"
                                >Upload your Documents : </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`upload documents if any`}
                                        type="file"
                                        name="documents"
                                        error={errors.documents}
                                        onChange={(e) => handleTextChange("documents", e.target.files[0])}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreatePanmasalaReports: (data) => dispatch(createPanmasalaReports(data)),
    onEditPanmasalaReports: (data, record_id) => dispatch(editPanmasalaReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(PanmasalaReport)
