import React, { useState, useEffect, useRef } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
  Redirect,
  Link,
} from "react-router-dom";
import Footer from "../components/Footer";
import BlogList from "./BlogList";
import Blogs from "./Blogs";
import ContactUs from "./ContactUs";
import Faq from "./Faq";
import Home from "./Home";
import Login from "./Login";
import Register from "./Register";
import OTPVerfication from "./OTPVerfication";
import StaffLogin from "./StaffLogin";
import LoginWithOtp from "./LoginWithOtp";
import GeneratePassword from "./GeneratePassword";
import ContactDetails from "./ContactDetails";
import PrivacyPolicy from "./PrivacyPolicy";
import UserDirectory from "./Headers/UserDirectory";
import AboutDepartment from "./Headers/AboutDepartment";
import BannedProducts from "./Headers/BannedProducts";
import Circular from "./Headers/Circular";
import CourtOrders from "./Headers/CourtOrders";
import Forms from "./Headers/Forms";
import GovernmentOrders from "./Headers/GovernmentOrders";
import PIO from "./Headers/PIO";
import Redressal from "./Headers/Redressal";
import Tenders from "./Headers/Tenders";
import TestingLab from "./Headers/TestingLab";
import Who from "./Headers/Who";
import Organistaion from "./Headers/Organistaion";
import AdjudicationDirectory from "./Headers/AdjudicationDirectory";
import DODirectory from "./Headers/DODirectory";
import FSODirectory from "./Headers/FSODirectory";
import LabDirectory from "./Headers/LabDirectory";
import TribunalDirectory from "./Headers/TribunalDirectory";
import NewHome from "./Headers/NewHome";
import Gallery from "./Headers/Gallery";
import HighCourt from "./Headers/HighCourt";
import SupremeCourt from "./Headers/SupremeCourt";
import CentralOrder from "./Headers/CentralOrder";
import FboForms from "./Headers/FboForms";
import Donate from "./Headers/Donate";
import BanItem from "./Headers/BanItem";
import PIOLab from "./Headers/PIOLab";
import PIOComm from "./Headers/PIOComm";
import Ato from "./Headers/Ato";
import Ado from "./Headers/Ado";
import Books from "./Headers/Books";
import Kids from "./Headers/Kids";
import ScreenReaders from "./Headers/ScreenReaders";
import { Blogc } from "./Blogc";
import Stock from "./Dept/Stock";

const Main = (props) => {
  const [backToTop, setBackToTopd] = useState(false);

  const navRef = useRef();
  navRef.current = backToTop;

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0;
      if (navRef.current !== show) {
        setBackToTopd(show);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handelBacktoTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="main-site">
      <Router>
        <Switch>
          <Route
            path="/:user_id/reset-password/"
            render={(props) => (
              <GeneratePassword
                title="Reset Password"
                isResetPassword={true}
                {...props}
              />
            )}
          />
          <Route
            path="/:user_id/generate-password/"
            render={(props) => (
              <GeneratePassword title="Generate Password" {...props} />
            )}
          />
          <Route
            exact
            path="/privacy-policy/"
            render={(props) => <PrivacyPolicy {...props} />}
          />
          <Route
            path="/forgot-password/"
            render={(props) => <LoginWithOtp {...props} />}
          />
          <Route
            path="/login-with-otp/"
            render={(props) => <LoginWithOtp {...props} />}
          />
          <Route path="/login/" render={(props) => <Login {...props} />} />
          <Route
            path="/staff/login/"
            render={(props) => <StaffLogin {...props} />}
          />
          <Route
            path="/register/"
            render={(props) => <Register {...props} />}
          />
          <Route
            path="/otp-verification/"
            render={(props) => <OTPVerfication {...props} />}
          />
          <Route path="/blogs/:slug" render={(props) => <Blogs {...props} />} />
          <Route path="/blogs" render={(props) => <BlogList {...props} />} />
          <Route path="/faqs" render={(props) => <Faq {...props} />} />
          <Route  path="/user-directory" render={(props)=> <UserDirectory {...props} /> }/>
          <Route  path="/about-department" render={(props)=> <AboutDepartment {...props} /> }/>
          <Route  path="/banned-products" render={(props)=> <BannedProducts {...props} /> }/>
          <Route  path="/banned-item" render={(props)=> <BanItem {...props} /> }/>
          <Route  path="/circulars" render={(props)=> <Circular {...props} /> }/>
          <Route  path="/court-orders" render={(props)=> <CourtOrders {...props} /> }/>
          <Route  path="/high-court-orders" render={(props)=> <HighCourt {...props} /> }/>
          <Route  path="/books" render={(props)=> <Books {...props} /> }/>
          <Route  path="/kids-corner" render={(props)=> <Kids {...props} /> }/>
          <Route  path="/tribunal-orders" render={(props)=> <Ato {...props} /> }/>
          <Route  path="/adjudicating-orders" render={(props)=> <Ado {...props} /> }/>
          <Route  path="/supreme-court-orders" render={(props)=> <SupremeCourt {...props} /> }/>
          <Route  path="/forms" render={(props)=> <Forms {...props} /> }/>
          <Route  path="/fbo-forms" render={(props)=> <FboForms {...props} /> }/>
          <Route  path="/government-orders" render={(props)=> <GovernmentOrders {...props} /> }/>
          <Route  path="/central-orders" render={(props)=> <CentralOrder {...props} /> }/>
          <Route  path="/pio" render={(props)=> <PIO {...props} /> }/>
          <Route  path="/redressal" render={(props)=> <Redressal {...props} /> }/>
          <Route  path="/tenders" render={(props)=> <Tenders {...props} /> }/>
          <Route  path="/testing-lab" render={(props)=> <TestingLab {...props} /> }/>
          <Route  path="/who" render={(props)=> <Who {...props} /> }/>
          <Route  path="/org-chart" render={(props)=> <Organistaion {...props} /> }/>
          <Route  path="/adjudication-officers" render={(props)=> <AdjudicationDirectory {...props} /> }/>
          <Route  path="/do-directory" render={(props)=> <DODirectory {...props} /> }/>
          <Route  path="/fso-directory" render={(props)=> <FSODirectory {...props} /> }/>
          <Route  path="/lab-analyst-directory" render={(props)=> <LabDirectory {...props} /> }/>
          <Route  path="/tribunal-officers-directory" render={(props)=> <TribunalDirectory {...props} /> }/>
          <Route  path="/gallery" render={(props)=> <Gallery {...props} /> }/>
          <Route  path="/home" render={(props)=> <NewHome {...props} /> }/>
          <Route  path="/screen-reader" render={(props)=> <ScreenReaders {...props} /> }/>
          <Route  path="/donate-food" render={(props)=> <Donate {...props} /> }/>
          <Route  path="/lab-pio" render={(props)=> <PIOLab {...props} /> }/>
          <Route  path="/appellate-tribunal-pio" render={(props)=> <PIOComm {...props} /> }/>
          <Route  path="/blog/mental-health/food" render={(props)=> <Blogc {...props} /> }/>
          <Route path="/contact-details" render={(props) => <ContactDetails {...props} />}/>
          <Route path="/contact-us" render={(props) => <ContactUs {...props} />}/>
          <Route path="/" render={(props) => <Home {...props} />} />
          <Redirect path="*" to="/" />
        </Switch>
      </Router>
      <Footer />
      <Link
        onClick={handelBacktoTop}
        className="scroll-to-top rounded"
        to="#"
        style={{ display: backToTop ? "inline" : "none" }}
      >
        <i className="fas fa-angle-up"></i>
      </Link>
    </div>
  );
};

export default withRouter(Main);
