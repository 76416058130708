import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createDOSampleReports, editDOSampleReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { Button } from "reactstrap";


const SampleNumber = [
    {id:"0", number:"1", name:'Venkatesh', date:"01/21/2021"},
    {id:"1", number:"2", name:'Ram', date:"02/10/2021"},
    {id:"2", number:"3", name:'Dinesh', date:"03/01/2021"},
    {id:"3", number:"4", name:'Sadhasivaam', date:"04/18/2021"},
    {id:"4", number:"5", name:'Anbarasan', date:"05/13/2021"},
   
]

const DOSampleDetails = (props) => {
    const { districts,wards, user, wardsCategory,onCreateDOSampleReports, onEditDOSampleReports } = props;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const [filterText, setFilterText] = React.useState("");
    const [Sample, setSample] = useState('')
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'sample_received_from_fso':'',
        'Sample_intimation_received_on' : '',
        'date_of_receipt_of_fal_report_on':''
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
      );
    let { slug } = useParams();


    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const filteredItems =
    SampleNumber &&
    SampleNumber.filter((item) =>
      Object.keys(item).some(
        (key) =>
          (typeof item[key] === "string" &&
            item[key].toLowerCase().includes(filterText.toLowerCase())) ||
          (typeof item[key] === "number" &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(filterText.toLowerCase()))
      )
    );

    const TextField = styled.input`
  height: 45px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #858796;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
    outline: none;
    border: 1px solid #ddd !important;
  }
  &:active,
  &:focus {
    outline: none;
    border: 1px solid #ddd !important;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 45px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
          <TextField
            id="search"
            type="text"
            placeholder="Search..."
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
          />
          <ClearButton type="button" onClick={onClear}>
            X
          </ClearButton>
        </>
      );
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
      wardsCategory.map((o)=>{
          if(o.id == values.area_code)
          return (
            setValues({
                  ...values,
                  area_name: o.ward_name
              })
          )
      })
  },[values.area_code])
    const customStyles = {
        rows: {
          style: {
            minHeight: "82px",
            fontSize: "18px",
            color: "#858796",
          },
        },
        headCells: {
          style: {
            paddingLeft: "8px",
            paddingRight: "8px",
            minHeight: "72px",
            marginTop: "20px",
            fontWeight: "bold",
            color: "#45b151",
            fontSize: "18px",
            background: "rgba(70,179,84,.10196078431372549)",
          },
        },
        cells: {
          style: {
            paddingLeft: "8px",
            paddingRight: "8px",
          },
        },
      };
    
      
      const columns = [
        {
          name: t("Sample Number"),
          cell: (row) => <div>{row.number}</div>,
          selector: "complaint_number",
          sortable: true,
        },
        
        // {
        //   name: t('Status'),
        //   cell: row => <div>{
        //     row.status === 'in_progress' ? (
        //       <span key={row.id} style={{ color: 'orange' }}>{t('In Progress')}</span>
        //     ) : [(
        //       row.status === 'closed' ? (
        //         <span key={row.id} style={{ color: 'red' }}>{t('Closed')}</span>
        //       ) : <span key={row.id} style={{ color: 'green' }}>{t('New')}</span>
        //     )]
        //   }</div>,
        //   selector: 'status',
        //   sortable: true,
        // },
        {
          name: t("Created By"),
          cell: (row) => (
            <div>
             {row.name}
            </div>
          ),
          selector: "current_status",
          sortable: true,
        },
        {
          name: t("Created At"),
          cell: (row) => (
            <div>
              {moment(row.date).format("DD-MM-YYYY")}
            </div>
          ),
          selector: "created_at",
          sortable: true,
        },
        {
          name: t("Action"),
          cell: (row) => (
            <div className="action__area">
              
                <Button style={{margin:3}} className="btn btn-info btn-sm" title={t("View Details")}>
                  {t("View Details")}
                </Button>
                <Button  style={{margin:3}} className="btn btn-info btn-sm" onClick={()=>{clickHandler()}} title={t("Add Details")}>
                  {t("Add Details")}
                </Button>
              {/* <Link to={
              {
                pathname: `/app/complaints/${row.id}/edit/`,
                state: {
                  'complaint': row
                }
              }
            }>
              <Button className="btn btn-success btn-circle btn-sm" title={t("Edit Complaint")}>
                <i className="fas fa-edit"></i>
              </Button>
            </Link>
            <Button onClick={() => handleClickDelete(row)} title={t('Delete Complaint')} className="btn btn-danger btn-circle btn-sm">
              <i className="fas fa-trash"></i>
            </Button> */}
            </div>
          ),
        },
      ];

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'Sample_intimation_received_on','sample_received_from_fso','area_name','area_code','date_of_receipt_of_fal_report_on'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditDOSampleReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/do_sample_list/`);
            };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateDOSampleReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }
const clickHandler = () => {
    setOpen(true);

}

const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);



    return (
            
        <div className="container-fluid">

 <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationServer
            //   paginationTotalRows={complaints.count}
              paginationPerPage={10}
              customStyles={customStyles}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              noDataComponent={
                <div style={{ color: "#858796", marginTop: 20 }}>
                  No Complaints available in table
                </div>
              }
            //   onChangePage={(page) => setPage(page)}
            />
            <div> 
              
               
               <div>
               </div>

            </div>
             {open &&  <> 
              

             <h1 className="h3 mb-4 text-gray-800">Sample Details Report</h1>

            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do")|| user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sample_received_from_fso"
                            >Sample Received From FSO *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Received From FSO`}
                                    name="sample_received_from_fso"
                                    value={values.sample_received_from_fso}
                                    error={errors.sample_received_from_fso}
                                    onChangeText={(text) =>handleTextChange("sample_received_from_fso", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="Sample_intimation_received_on"
                            >Sample Intimation Received On *:  </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sample Intimation Received On`}
                                    name="Sample_intimation_received_on"
                                    value={values.Sample_intimation_received_on}
                                    error={errors.Sample_intimation_received_on}
                                    onChangeText={(text) =>handleTextChange("Sample_intimation_received_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_receipt_of_fal_report_on"
                            >Date Of Receipt Of FAL Report On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of receipt of FAL Report On`}
                                    name="date_of_receipt_of_fal_report_on"
                                    value={values.date_of_receipt_of_fal_report_on}
                                    error={errors.date_of_receipt_of_fal_report_on}
                                    onChangeText={(text) =>handleTextChange("date_of_receipt_of_fal_report_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
          </> }
        </div>        
    ) 
}

const mapDispatchToProps = (dispatch) => ({
    onCreateDOSampleReports: (data) => dispatch(createDOSampleReports(data)),
    onEditDOSampleReports: (data, record_id) => dispatch(editDOSampleReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(DOSampleDetails);