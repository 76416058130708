import React from 'react';
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { getTenders } from "../../components/reducers/actions";
import DownloadLink from "react-download-link";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

const Tenders = (props) => {
    const { ongetTenders, user } = props;
   const { t, i18n } = useTranslation();
   const [product, setProduct]=useState([]);
   const [blink,setBlink] = useState(true);

  //  useEffect(()=>{
  //      setTimeout(()=>{
  //        setBlink(!blink)
  //      },1000)
  //    },[blink])
  const getProduct = async () => {
    const resp = await ongetTenders();
    if(resp.response){
      console.log(resp.response)
      setProduct(resp.response)
    }
    else{
      console.log('error')
    }
  };
  useEffect(() => {
    getProduct();
  }, []);
  
  useEffect(() => {
    getProduct();
  }, [user]); 
  const Download = (props) => {
    const saveFile = () => {
      saveAs(
        props.data.attachment,
        props.data.file_name
      );
    };
    return (
      <div className="download_btn">
        <a href={props.data.attachment} target="_blank">
          {" "}
          View
        </a>
        <b>/</b>
        <div>
          <a
            onClick={()=>{saveFile(props)}}
            style={{textDecoration:'underline',color:'blue'}}
          >
          Download
          </a>
        </div>
      </div>
    );
  };
    return (
        <div style={{marginBottom:'150px'}}>
              <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">
               {t( "FSDT")}
              </h1>
             
            </div>
          </div>
        </div>
      </header>
      <div className='table_top'>
        {product && <>
        <Table responsive bordered>
          <thead>
            <tr>
                <th>S.No</th>
                <th >{t("Description")}</th>
                <th>{t("Released On")}</th>
                <th>{t("Expiring On")}</th>
                <th>{t("Download/View")}</th>
            </tr>
          </thead>
          <tbody>
            {product.map((item, index)=>
                <tr>
                    <td>{index+1}</td>
                    <td><p style={{position:'relative'}}>{item.recent && blink? <span style={{float:'right',left:10, postion:'absolute',top:-5, right:0, background:'red', color:'#fff', fontWeight:'bold',padding:2, borderRadius:3}}>New</span>:''}{item.description}</p></td>
                    <td>{moment(item.tender_issued_date).format("l")}</td>
                    <td>{moment(item.tender_last_date).format("l")}</td>
                    <td>
                        <Download  data={item} />
                    </td>
                </tr>
            )}
          </tbody>
        </Table>
        </>}
        </div>
        </div>
    );
};


const mapDispatchToProps = (dispatch) => ({
    ongetTenders: data=>dispatch(getTenders(data))
});

const mapStateToProps = (state) => ({
  user:state.home.user
});
export default connect(mapStateToProps, mapDispatchToProps)(Tenders);
