import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createRegistrationReports, editRegistrationReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const RegistrationReport = (props) => {
    const { districts, user, traderTypes,wards, onCreateRegistrationReports,ongetDist, onEditRegistrationReports, wardsCategory } = props;
    const [values, setValues] = useState({
        'district' : '',
        'area_code':'',
        'area_name':'',
        'date' : '',
        'total_target':'',
        'category_of_fbo' : '',
        'no_of_registration_granted_during_month_new' : '',
        'registration_granted_during_the_month_renewal':'',
        'registration_granted_during_the_month_modification':'',
        'no_of_registration_expired_during_month' : '',
        'registration_cancelled':'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            values.category_of_fbo = parseInt(values.category_of_fbo)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])

    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district',
            'area_code',
            'area_name',
            'date' ,
            'total_target',
             'category_of_fbo',
            'no_of_registration_granted_during_month_new' ,
            'registration_granted_during_the_month_renewal',
            'registration_granted_during_the_month_modification',
            'no_of_registration_expired_during_month',
            'registration_cancelled'
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {   
        console.log(values);
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditRegistrationReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/registration-list/`);
            };

        } else {    
            const data = { ...values}
            setDisableSubmit(true);
            console.log(data);
            const resp = await onCreateRegistrationReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Registration Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    placeholder={`Area name`}
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange('area_name', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Month`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="total_target"
                            >Total Registration Target *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total registration Enumerated`}
                                    type="number"
                                    name="total_target"
                                    value={values.total_target}
                                    error={errors.total_target}
                                    onChange={(e) =>handleTextChange("total_target", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="category_of_fbo"
                            >Category of FBO *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Category of FBO`}
                                    type="text"
                                    name="category_of_fbo"
                                    value={values.category_of_fbo}
                                    error={errors.category_of_fbo}
                                    items={traderTypes}
                                    onChange={(text) => handleTextChange("category_of_fbo", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="no_of_registration_granted_during_month_new"
                            >Number of Registration Granted During the Month(New) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`New Granted Registration`}
                                    type="number"
                                    name="no_of_registration_granted_during_month_new"
                                    value={values.no_of_registration_granted_during_month_new}
                                    error={errors.no_of_registration_granted_during_month_new}
                                    onChange={(e) =>handleTextChange("no_of_registration_granted_during_month_new", e.target.value)}
                                />
                            </div>
                        </div>
                             <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="registration_granted_during_the_month_renewal"
                            >Number of Registration granted during the month(Renewal) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of registration renewed`}
                                    type="number"
                                    name="registration_granted_during_the_month_renewal"
                                    value={values.registration_granted_during_the_month_renewal}
                                    error={errors.registration_granted_during_the_month_renewal}
                                    onChange={(e) =>handleTextChange("registration_granted_during_the_month_renewal", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="registration_granted_during_the_month_modification"
                            >Number of Registration granted during the month(Modification) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of registration modified`}
                                    type="number"
                                    name="registration_granted_during_the_month_modification"
                                    value={values.registration_granted_during_the_month_modification}
                                    error={errors.registration_granted_during_the_month_modification}
                                    onChange={(e) =>handleTextChange("registration_granted_during_the_month_modification", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_registration_expired_during_month"
                            >Number of Registration expired during month *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of registration expired during month`}
                                    type="number"
                                    name="no_of_registration_expired_during_month"
                                    value={values.no_of_registration_expired_during_month}
                                    error={errors.no_of_registration_expired_during_month}
                                    onChange={(e) => handleTextChange("no_of_registration_expired_during_month", e.target.value)}
                                />
                            </div>
                        </div>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="registration_cancelled"
                            >Number of Registration cancelled during the month *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of registration cancelled during the month`}
                                    type="number"
                                    name="registration_cancelled"
                                    value={values.registration_cancelled}
                                    error={errors.registration_cancelled}
                                    onChange={(e) =>handleTextChange("registration_cancelled", e.target.value)}
                                />
                            </div>
                        </div>    
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                 disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateRegistrationReports: (data) => dispatch(createRegistrationReports(data)),
    onEditRegistrationReports: (data, record_id) => dispatch(editRegistrationReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    traderTypes: state.home.traderTypes,
    wards: state.home.wards,
  wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationReport);