import React from "react";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";


const ScreenReaders = (props) => {
  const { ongetForms, user } = props;
  const { t, i18n } = useTranslation();
  const [product, setProduct]=useState([
    {title:'Screen Access For All (SAFA)',url:'http://www.nabdelhi.org/NAB_SAFA.htm',free:'Free'},
    {title:'Non Visual Desktop Access (NVDA)',url:'http://www.nvda-project.org/',free:'Free'},
    {title:'System Access To Go',url:'http://www.satogo.com/',free:'Free'},
    {title:'Thunder',url:'http://www.nabdelhi.org/NAB_SAFA.htm',free:'Free'},
    {title:'WebAnywhere',url:'http://webanywhere.cs.washington.edu/wa.php',free:'Free'},
    {title:'Hal',url:'http://www.yourdolphin.co.uk/productdetail.asp?id=5',free:'Commercial'},
    {title:'JAWS',url:'http://www.freedomscientific.com/jaws-hq.asp',free:'Commercial'},
    {title:'Supernova',url:'http://www.yourdolphin.co.uk/productdetail.asp?id=1',free:'Commercial'},
    {title:'Window-Eyes',url:'http://www.gwmicro.com/Window-Eyes/',free:'Free'},
  ]);


  return (
    <div style={{marginBottom:'150px'}}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">
              {t("Screen Readers")}
              </h1>
            
            </div>
          </div>
        </div>
      </header>
      <div className="table_top">
        {product && <>
        <Table responsive bordered>
          <thead>
            <tr>
              <th>S.No</th>
              <th>{t("Screen Reader")}</th>
              <th>{t("Website")}</th>
              <th>{t("Free/Commercial")}</th>
            </tr>
          </thead>
          <tbody>
            {product.map((item, index) => (
              <tr>
                <td>{index+1}</td>
                <td>{item.title}</td>
                <td><a target="_blank" href={item.url}>{item.url}</a></td>
                <td>{item.free}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        </>}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  user:state.home.user
});
export default connect(mapStateToProps, mapDispatchToProps)(ScreenReaders);
