import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createSlaughterReports, editSlaughterReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";


const lic =[
    {id:'0', name:'License'},
    {id:'1', name:'Registration'},
]
const SlaughterReports = (props) => {
    const { districts, user,wards, onCreateSlaughterReports,wardsCategory,ongetDist, onEditSlaughterReports } = props;
    const [values, setValues] = useState({
        'area_code':'',
        'area_name':'',
        'total_no_of_slaughter_houses':'',
        'no_of_slaughter_house_in_use':'',
        'no_of_slaughter_house_not_in_use':'',
        'no_of_slaughter_house_having_valid_license':'',
        'no_of_slaughter_house_registration_expired':'',
        'no_of_slaughter_house_without_valid_license':'',
        'no_of_slaughter_house_without_valid_registration':'',
        'inspite_of_s_h_slaughtering_outside':'',
        'during_inspection_within_premises_storage':'',
        'during_inspection_within_premises_sanitization':'',
        'during_inspection_within_premises_water_supply':'',
        'inspection_during_the_month':'',
        'remarks':'',
        'district' : '',
        'date' : '',
        'veterinary_doctors':'',
        'category':'',
        'inspected':'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do")|| user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
          'area_name',
          'area_code',
          'total_no_of_slaughter_houses',
          'no_of_slaughter_house_in_use',
          'no_of_slaughter_house_not_in_use',
          'no_of_slaughter_house_having_valid_license',
            'no_of_slaughter_house_registration_expired',
             'no_of_slaughter_house_without_valid_license',
            'inspite_of_s_h_slaughtering_outside',
            'during_inspection_within_premises_storage',
            'during_inspection_within_premises_sanitization',
            'during_inspection_within_premises_water_supply', 
            'district',
            'veterinary_doctors',
            'category',
            'inspected',
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {   
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditSlaughterReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/slaughter-list/${values.id}`, { records: values });
            };

        } else {    
            const data = { ...values}
            console.log('ssas', data);
            setDisableSubmit(true);
            const resp = await onCreateSlaughterReports(data);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Slaughterhouse Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="category"
                            >Type of license category *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="category"
                                        action="dropdown"
                                        value={values.category}
                                        error={errors.category}
                                        items={lic}
                                        onChange={(value) => handleTextChange("category", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="total_no_of_slaughter_houses"
                            >Total Number of Slaughterhouse(s) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Total Number of Slaughter House`}
                                    type="number"
                                    name="total_no_of_slaughter_houses"
                                    id="total_no_of_slaughter_houses"
                                    value={values.total_no_of_slaughter_houses}
                                    error={errors.total_no_of_slaughter_houses}
                                    onChange={(e) => handleTextChange("total_no_of_slaughter_houses", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="veterinary_doctors"
                            >Number of veterinary doctors available *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of veterinary doctors available`}
                                    type="number"
                                    name="veterinary_doctors"
                                    id="veterinary_doctors"
                                    value={values.veterinary_doctors}
                                    error={errors.veterinary_doctors}
                                    onChange={(e) => handleTextChange("veterinary_doctors", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_slaughter_house_in_use"
                            >Number of Slaughterhouse (In Use) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of slaughter house in use`}
                                    type="number"
                                    name="no_of_slaughter_house_in_use"
                                    id="no_of_slaughter_house_in_use"
                                    value={values.no_of_slaughter_house_in_use}
                                    error={errors.no_of_slaughter_house_in_use}
                                    onChange={(e) => handleTextChange("no_of_slaughter_house_in_use", e.target.value)}
                                />
                            </div>
                        </div>      
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_slaughter_house_having_valid_license"
                            >Number of Slaughterhouse having valid License/Registration *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of slaughter house having valid license/registration`}
                                    type="number"
                                    name="no_of_slaughter_house_having_valid_license"
                                    id="no_of_slaughter_house_having_valid_license"
                                    value={values.no_of_slaughter_house_having_valid_license}
                                    error={errors.no_of_slaughter_house_having_valid_license}
                                    onChange={(e) => handleTextChange("no_of_slaughter_house_having_valid_license", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_slaughter_house_without_valid_license"
                            >Number of Slaughterhouse(s) without valid License/Registration *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of slaughter house without valid license/registration`}
                                    type="number"
                                    name="no_of_slaughter_house_without_valid_license"
                                    id="no_of_slaughter_house_without_valid_license"
                                    value={values.no_of_slaughter_house_without_valid_license}
                                    error={errors.no_of_slaughter_house_without_valid_license}
                                    onChange={(e) => handleTextChange("no_of_slaughter_house_without_valid_license", e.target.value)}
                                />
                            </div>
                        </div>
                 <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_slaughter_house_not_in_use"
                            >Number of Slaughterhouse (Not in Use) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of slaughter house not in use`}
                                    type="number"
                                    name="no_of_slaughter_house_not_in_use"
                                    id="no_of_slaughter_house_not_in_use"
                                    value={values.no_of_slaughter_house_not_in_use}
                                    error={errors.no_of_slaughter_house_not_in_use}
                                    onChange={(e) =>handleTextChange("no_of_slaughter_house_not_in_use", e.target.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="no_of_slaughter_house_registration_expired"
                            >Number of Slaughterhouse(s) License/Registration expired *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of slaughter house's registration expired`}
                                    type="number"
                                    name="no_of_slaughter_house_registration_expired"
                                    id="no_of_slaughter_house_registration_expired"
                                    value={values.no_of_slaughter_house_registration_expired}
                                    error={errors.no_of_slaughter_house_registration_expired}
                                    onChange={(e) => handleTextChange("no_of_slaughter_house_registration_expired", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspected"
                            >Number of Slaughterhouse(s) inspected *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number of slaughterhouse's inspected`}
                                    type="number"
                                    name="inspected"
                                    id="inspected"
                                    value={values.inspected}
                                    error={errors.inspected}
                                    onChange={(e) => handleTextChange("inspected", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="inspite_of_s_h_slaughtering_outside"
                            >Inspite of S.H Slaughtering Outside *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Inspite of S.H Slaughtering Outside`}
                                    type="number"
                                    name="inspite_of_s_h_slaughtering_outside"
                                    id="inspite_of_s_h_slaughtering_outside"
                                    value={values.inspite_of_s_h_slaughtering_outside}
                                    error={errors.inspite_of_s_h_slaughtering_outside}
                                    onChange={(e) => handleTextChange("inspite_of_s_h_slaughtering_outside", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="during_inspection_within_premises_storage"
                            >During inspection within the premises (Storage available) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Storage available`}
                                    type="text"
                                    name="during_inspection_within_premises_storage"
                                    id="during_inspection_within_premises_storage"
                                    value={values.during_inspection_within_premises_storage}
                                    error={errors.during_inspection_within_premises_storage}
                                    onChange={(e) => handleTextChange("during_inspection_within_premises_storage", e.target.value)}
                                />
                            </div>
                        </div>
                       
                                             
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="during_inspection_within_premises_sanitization"
                            >During inspection within the premises (Sanitization Provided/Available) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Sanitization Available`}
                                    type="text"
                                    name="during_inspection_within_premises_sanitization"
                                    id="during_inspection_within_premises_sanitization"
                                    value={values.during_inspection_within_premises_sanitization}
                                    error={errors.during_inspection_within_premises_sanitization}
                                    onChange={(e) => handleTextChange("during_inspection_within_premises_sanitization", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="during_inspection_within_premises_water_supply"
                            >During inspection within the premises (Water Supply Available) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Water Supply Available`}
                                    type="text"
                                    name="during_inspection_within_premises_water_supply"
                                    id="during_inspection_within_premises_water_supply"
                                    value={values.during_inspection_within_premises_water_supply}
                                    error={errors.during_inspection_within_premises_water_supply}
                                    onChange={(e) => handleTextChange("during_inspection_within_premises_water_supply", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="remarks"
                            >Remarks: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Remarks`}
                                    type="text"
                                    name="remarks"
                                    id="remarks"
                                    value={values.remarks}
                                    error={errors.remarks}
                                    onChange={(e) => handleTextChange("remarks", e.target.value)}
                                />
                            </div>
                        </div>               
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateSlaughterReports: (data) => dispatch(createSlaughterReports(data)),
    onEditSlaughterReports: (data, record_id) => dispatch(editSlaughterReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(SlaughterReports);