import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import GoogleMapView from "../../components/GoogleMapView";
import { useParams } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  Button,
  NavItem,
  NavLink,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import ModelPopup from "../../components/ModelPopup";
import UploadImageInput from "../../components/inputs/UploadImageInput";
import {
  deleteComplaint,
  getComplaints,
  getComments,
  createComment,
  getComplaint,
  getFsoOfficers,
  getInspection,
  doComplaintAssign,
  doComplaintUnassign,
  approveInspection,
  doComplaintSendEmails,
  getDelCOmplaints,
  createInspection,
  ComplaintClosure,
} from "../../components/reducers/actions";
import { validateEmail } from "../../utils/validateEmail";
import NewAccordion from "../../components/FsoAccordion/FsoAccordion";
import Accordion from "../../components/Accordion/Accordion";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import { useTranslation } from "react-i18next";
import DropDownInput from "../../components/inputs/DropDownInput";
import Checkbox from "../../components/inputs/Checkbox";
import TextInput from "../../components/inputs/TextInput";
import ClipLoader from "react-spinners/ClipLoader";
import InspectionInfo from "./InspectionInfo";
import Zoom from "react-medium-image-zoom";
import toast from 'react-toast-notification';

const ComplaintDetails = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [complaint, setComplaint] = useState(null);
  const [region, setRegion] = useState({
    lat: 11.1271,
    lng: 78.6569,
  });
  const [emailValues, setEmailValues] = useState({
    emails: [""],
  });
  const [emailErrors, setEmailErrors] = useState({
    emails: [""],
  });
  const [isSpinner, setIsSpinner] = useState(false);
  const [isduplicateComplaint, setIsDuplicateComplaint]=useState(false)
  const [isotherstate, setIsOtherState]=useState(false)
  const [nodata, setNodata]=useState(false)
  const [duplicateComplaint, setDuplicateComplaint]=useState({})
  const [duplicateReason,setDuplicateReason]=useState('')
  const [otherstatereason,setOtherStateReason]=useState('')
  const [complaintnumber, setComplaintNumber]=useState('')
  const [isSameComplaint, setIsSameComplaint]=useState(false)
  const [disableEmailSubmit, setDisableEmailSubmit] = useState(true);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [toggleAction, setToggleAction] = useState(false);
  const [actionValues, setActionValues] = useState({});
  const [assignPopup, setAssignPopup] = useState(false);
  const [assignDistrictPopup, setAssignDistrictPopup] = useState(false);
  const [action, setAction] = useState(null);
  const [disableActionSubmit, setDisableActionSubmit] = useState(true);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [markerCoordinates, setMarkerCoordinates] = useState(null);
  const [activeAccordion, setActiveAccordion] = useState(-1);
  let { id } = useParams();
  const { t } = useTranslation();

  const {
    districts,
    onGetComments,
    onApproveInspection,
    onComplaintSendEmails,
    onComplaintAssign,
    onComplaintUnassign,
    fsoOfficers,
    onGetFsoOfficers,
    user,
    onGetInspection,
    inspection,
    onCreateComment,
    onComplaint,
    comments,
    foodConditions,
    foodTypes,
    foodIssues,
    traderTypes,
    onComplaints,
    onDeleteComplaint,
    ongetComplaints,
    onCreateInspection,
    onCloseComplaints,
  } = props;
  console.log(fsoOfficers)
  useEffect(() => {
    if (props.history.location && props.history.location.state) {
      const complaintData = props.history.location.state.complaint;
      console.log('complaint status', complaintData);
      if (complaintData.id) {
        onGetComments({
          is_base64: true,
          complaint: complaintData.id,
        });
        onGetInspection({
          // is_base64: true,
          complaint: complaintData.id,
        });
      }
      if (
        complaintData &&
        complaintData.coordinates &&
        complaintData.coordinates[0] &&
        complaintData.coordinates[1]
      ) {
        const updatedRegion = { ...region };
        updatedRegion.lat = complaintData.coordinates[0];
        updatedRegion.lng = complaintData.coordinates[1];
        setRegion(updatedRegion);
        let markerCoordinatesData = [
          {
            title: "Complaint Location",
            lat: complaintData.coordinates[0],
            lng: complaintData.coordinates[1],
          },
        ];
        if (
          complaintData.inspection_coordinates &&
          complaintData.inspection_coordinates[0] &&
          complaintData.inspection_coordinates[1]
        ) {
          markerCoordinatesData.push({
            title: "Inspection Location",
            lat: complaintData.inspection_coordinates[0],
            lng: complaintData.inspection_coordinates[1],
          });
        }
        setMarkerCoordinates(markerCoordinatesData);
      }
      if (
        user &&
        user.user_types.includes("do") | user.user_types.includes("fso")
      ) {
        onGetFsoOfficers({
          complaint: complaintData.id,
        });
      }
      setComplaint(complaintData);
      if (props.history.location.state.isFirst) {
        setTimeout(() => {
          props.history.push("/app/complaints/");
        }, 5000);
      }
    } else if (id) {
      const handleGetData = async () => {
        const complaint = await onComplaint({
          is_base64: true,
          id: id,
          type: "all",
        });
        const complaintData = complaint.response;
        if (complaintData.id) {
          onGetComments({
            is_base64: true,
            complaint: complaintData.id,
          });
          onGetInspection({
            // is_base64: true,
            complaint: complaintData.id,
          });
        }
        if (
          complaintData &&
          complaintData.coordinates &&
          complaintData.coordinates[0] &&
          complaintData.coordinates[1]
        ) {
          const updatedRegion = { ...region };
          updatedRegion.lat = complaintData.coordinates[0];
          updatedRegion.lng = complaintData.coordinates[1];
          setRegion(updatedRegion);
          let markerCoordinatesData = [
            {
              title: "Complaint Location",
              lat: complaintData.coordinates[0],
              lng: complaintData.coordinates[1],
            },
          ];
          if (
            complaintData.inspection_coordinates &&
            complaintData.inspection_coordinates[0] &&
            complaintData.inspection_coordinates[1]
          ) {
            markerCoordinatesData.push({
              title: "Inspection Location",
              lat: complaintData.inspection_coordinates[0],
              lng: complaintData.inspection_coordinates[1],
            });
          }
          setMarkerCoordinates(markerCoordinatesData);
        }
        if (
          user &&
          user.user_types.includes("do") | user.user_types.includes("fso")
        ) {
          onGetFsoOfficers({
            complaint: complaintData.id,
          });
        }
        setComplaint(complaintData);
      };
      handleGetData();
    }
  
    // eslint-disable-next-line
  }, [props.history.location.state]);

  useEffect(() => {
    const getUpdatedComments = async () => {
      if (complaint && complaint.id) {
        await onGetComments({
          is_base64: true,
          complaint: complaint.id,
        });
      }
    };
    getUpdatedComments();
    // eslint-disable-next-line
  }, [isSendEmail]);
  useEffect(() => {
    validateValues(values);
    validateActionValues(actionValues);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    validateActionValues(actionValues);
    // eslint-disable-next-line
  }, [action]);
  var hideActionMycomplaints = false;
  if (
    props.history.location.state &&
    props.history.location.state.hideActionMycomplaints
  ) {
    hideActionMycomplaints = true;
  }

  let actionItem = [
    {
      name: complaint && complaint.assigned_to ? "reassign" : "assign",
      label:
        complaint && complaint.assigned_to
          ? t("Reassign to FSO")
          : t("Assign to FSO"),
    },
  ];

  if (
    user &&
    user.user_types.includes("do") &&
    complaint &&
    complaint.district &&
    !complaint.assigned_to
  ) {
    actionItem.push({
      label: t("Assign to HO"),
      name: "assign_to_ho",
    },
    {
      label:"Other State Complaint",
      name: "other_state",
    },
    {
      label:"Duplicate Complaint",
      name: "duplicate_complaint",
    },);
  }
  else if (
    user &&
    user.user_types.includes("ho") &&
    complaint &&
    !complaint.district
  ) {
    actionItem = [
      {
        label: t("Assign to District"),
        name: "assign_to_district",
      },
      {
        label:"Other State Complaint",
        name: "other_state",
      },
      {
        label:"Duplicate Complaint",
        name: "duplicate_complaint",
      },
    ];
  } else if (
    complaint &&
    complaint.assigned_to &&
    user &&
    user.user_types.includes("fso")
  ) {
    actionItem = [
      {
        label: t("Reassign to DO"),
        name: "fso_assign_to_do",
      },
      {
        label: t("Reassign to FSO"),
        name: "reassign",
      },
    ];
  }

  if (
    complaint &&
    complaint.assigned_to &&
    complaint.awaiting_inspection_approval &&
    user &&
    user.user_types.includes("fso")
  ) {
    actionItem = [];
  }

  if (
    complaint &&
    complaint.assigned_to &&
    user &&
    user.user_types.includes("do")
  ) {
    actionItem.push({
      label: t("Reassign to HO"),
      name: "assign_to_ho",
    });
  }

  if (
    complaint &&
    complaint.awaiting_inspection_approval &&
    user &&
    user.user_types.includes("do")
  ) {
    actionItem = [
      {
        label: t("Reassign Same FSO"),
        name: "same_fso",
      },
      {
        label: t("Reassign Different FSO"),
        name: "different_fso",
      },
    ];
  }

  const handleAccordionClick = (value) => {
    console.log(value);
  };

  const handleActivateAccordion = (index) => {
    setActiveAccordion((prevState) => (prevState === index ? -1 : index));
  };


  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ["message"];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };

  let districtName = null;
  districts &&
    districts.forEach((districtItem) => {
      if (complaint && districtItem.id === complaint.district) {
        districtName = districtItem.name;
      }
    });

  let foodCondition = null;
  foodConditions &&
    foodConditions.forEach((item) => {
      if (complaint && item.id === complaint.condition_of_food) {
        foodCondition = item.name;
      }
    });

  let traderType = null;
  traderTypes &&
    traderTypes.forEach((item) => {
      if (complaint && item.id === complaint.type_of_trader) {
        traderType = item.name;
      }
    });

  let natureOfFood = null;
  foodTypes &&
    foodTypes.forEach((item) => {
      if (complaint && item.id === complaint.nature_of_food) {
        natureOfFood = item.name;
      }
    });

  let issueOfFood = null;
  foodIssues &&
    foodIssues.forEach((item) => {
      if (complaint && item.id === complaint.issue_of_food) {
        issueOfFood = item.name;
      }
    });

  const handleRemoveImages = (key, value) => {
    const updatedValues = { ...values };
    delete updatedValues[key];
    setValues(updatedValues);
  };

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    if (key === "request_info_from_consumer" && value) {
      updatedValues.visible_to_consumer = true;
    }
    if (key === "request_info_from_consumer" && !value) {
      updatedValues.visible_to_consumer = false;
    }
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleDeleteSubmit = async (item) => {
    const resp = await onDeleteComplaint({
      complaint_id: item.id,
    });
    if (resp && resp.data === "success") {
      onComplaints();
      setModal(false);
      props.history.push("/app/complaints");
    }
  };

  const validateActionValues = (values) => {
    console.log(values,action)
    var updatedErrors = {};
    var keys = [];
    if (action && action === "assign_to_district") {
      keys = ["assigned_district"];
    } else if (
      action &&
      (action === "fso_assign_to_do") |
        (action === "assign_to_ho") |
        (action === "same_fso")
    ) {
      keys = ["reason"];
    } 
    else if(action ==='assign'){
      keys = ["assigned_fso"];
    }
    else if(action === 'different_fso' ||   'reassign'){
      keys = ["assigned_fso",'reason'];
    }
    else if (
      action &&
      action !== "fso_assign_to_do" &&
      action !== "assign_to_ho" &&
      action !== "same_fso"
    ) {
      keys = ["assigned_fso"];
    }
  
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableActionSubmit(shouldDisableSubmit);
    return updatedErrors;
  };

  const handleTextActionChange = (key, value) => {
    const updatedValues = { ...actionValues, [key]: value };
    setActionValues(updatedValues);
    validateActionValues(updatedValues);
  };

  const handleCancelBtn = () => {
    setToggleAction(false);
    setAssignPopup(false);
    setActionValues({});
    setAction(null);
  };

  const handleActionSubmitChange = async (value) => {
    setIsSpinner(true);
    let data = {};
    if (action === "same_fso" && complaint) {
      data = {
        id: complaint.id,
        reason: value.reason,
        user_id: complaint.assigned_to,
        action: "Reassigned",
      };
    } else if (action === "different_fso") {
      data = {
        id: complaint.id,
        user_id: value.assigned_fso,
        reason: value.reason,
        action: "Reassigned",
      };
    } else {
      data = {
        id: complaint.id,
        user_id: value.assigned_fso,
        reason: value.reason,
        action: complaint && complaint.assigned_to ? "Reassigned" : "Assigned",
      };
    }
    const resp = await onComplaintAssign(data);
    console.log('reassign data', data);
    if (resp.response) {
      setAction(null);
      setAssignPopup(false);
      setToggleAction(false);
      setIsSpinner(false);
      setActionValues({});
      if (
        action &&
        action === "assign" &&
        user &&
        user.user_types.includes("do")
      ) {
        props.history.push("/app/complaints", { tab: "6" });
      } else {
        props.history.push("/app/complaints");
      }
    } else {
      setAction(null);
      setAssignPopup(false);
      setIsSpinner(false);
      setToggleAction(false);
      setActionValues({});
    }
  };

  const handleActionSubmit = () => {
    setDisableActionSubmit(true);
    if (action && action === "fso_assign_to_do") {
      handleAssignDO(actionValues);
    } else if (action && action === "assign_to_ho") {
      handleAssignHO(actionValues);
    } else if (action && action === "assign_to_district") {
      handleAssignDistrict(actionValues);
    } else {
      handleActionSubmitChange(actionValues);
    }
  };

  const handleAssignDO = async (value) => {
    setIsSpinner(true);
    let data = {
      id: complaint.id,
      reason: value.reason,
      action: "assigned_to_do",
    };
    const resp = await onComplaintUnassign(data);
    if (resp.response) {
      setIsSpinner(false);
      setAction(null);
      props.history.push("/app/complaints");
    } else {
      setAction(null);
      setIsSpinner(false);
    }
  };

  const handleAssignDistrict = async (value) => {
    setIsSpinner(true);
    const data = {
      id: complaint.id,
      district: value.assigned_district,
      reason: value.reason,
      action: "district_assigned",
    };
    const resp = await onComplaintAssign(data);
    if (resp.response) {
      setAction(null);
      setToggleAction(false);
      setIsSpinner(false);
      setAssignDistrictPopup(false);
      props.history.push("/app/complaints");
    } else {
      setAction(null);
      setToggleAction(false);
      setIsSpinner(false);
      setAssignDistrictPopup(false);
    }
  };

  const handleAssignHO = async (value) => {
    setIsSpinner(true);
    const data = {
      id: complaint.id,
      reason: value.reason,
      action: "assigned_to_ho",
    };
    const resp = await onComplaintAssign(data);
    if (resp.response) {
      setAction(null);
      setIsSpinner(false);
      setAssignPopup(false);
      props.history.push("/app/complaints");
    } else {
      setIsSpinner(false);
      setAction(null);
      setAssignPopup(false);
    }
  };

  const handleActionChange = (value) => {
    setToggleAction(!toggleAction);
    if (
      value === "assign" ||
      value === "reassign" ||
      value === "different_fso"
    ) {
      setAssignPopup(true);
    } else if (value === "assign_to_district") {
      setAssignDistrictPopup(true);
    }
    else if(value=='other_state'){
      setIsOtherState(true)
      setToggleAction(false);
    }
    else if(value=='duplicate_complaint'){
      setIsDuplicateComplaint(true)
      setToggleAction(false);
    }
    setActionValues({});
    setAction(value);
  };

  const handleSubmitButton = async () => {
    const updatedErrors = validateValues(values);
    if (Object.keys(updatedErrors).length === 0) {
      const photos = [];
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith("image_")) {
          photos.push(value);
        }
      });
      const data = {
        ...values,
        photos: photos,
        complaint: complaint.id,
        action: "Comment",
      };
      const resp = await onCreateComment(data);
      if (resp.response) {
        const complaintsData = await onComplaint({
          is_base64: true,
          id: complaint.id,
          type: "all",
        });
        if (complaintsData && complaintsData.response) {
          setValues({});
          validateValues({});
          props.history.push({
            pathname: `/app/complaints/${complaint.id}`,
            state: {
              complaint: complaintsData.response,
            },
          });
        } else {
          props.history.push("/app");
        }
      } else {
        setValues({});
        validateValues({});
        props.history.push({
          pathname: `/app/complaints/${complaint.id}`,
          state: {
            complaint: complaint,
          },
        });
      }
    }
  };

  const inspectionApprove = async () => {
    setIsSpinner(true);
    const data = {
      complaint_id: complaint.id,
    };
    const resp = await onApproveInspection(data);
    if (resp.response) {
      setIsSpinner(false);
      props.history.push("/app/complaints");
    }
  };

  const activityList = (comment) => {
    if (comment.action === "Assigned") {
      return (
        <>
          {" "}
          assigned the complaint to{" "}
          <span style={{ fontWeight: "bold" }}>
            {capitalize(comment.to_assignee)}
          </span>
        </>
      );
    } else if (comment.action === "assigned_to_do") {
      return (
        <>
          {" "}
          assigned the complaint to{" "}
          <span style={{ fontWeight: "bold" }}>District Officer</span>
        </>
      );
    } else if (comment.action === "send_emails") {
      return <> sent emails to </>;
    } else if (comment.action === "district_assigned") {
      return (
        <>
          {" "}
          assigned the complaint to{" "}
          <span style={{ fontWeight: "bold" }}>District</span>
        </>
      );
    } else if (comment.action === "assigned_to_ho") {
      return (
        <>
          {" "}
          assigned the complaint to{" "}
          <span style={{ fontWeight: "bold" }}>Header Officer</span>
        </>
      );
    } else if (comment.action === "Unassigned") {
      return (
        <>
          {" "}
          unassigned the complaint from{" "}
          <span style={{ fontWeight: "bold" }}>
            {capitalize(comment.from_assignee)}
          </span>
        </>
      );
    } else if (comment.action === "Reassigned") {
      return (
        <>
          {" "}
          reassigned the complaint from{" "}
          <span style={{ fontWeight: "bold" }}>
            {capitalize(comment.from_assignee)}
          </span>{" "}
          to{" "}
          <span style={{ fontWeight: "bold" }}>
            {capitalize(comment.to_assignee)}
          </span>
        </>
      );
    } else if (comment.action === "Inspection") {
      return " added a inspection";
    } else {
      return " added a comment";
    }
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const commentsList = comments
    ? comments.map((comment, idx) => {
        return (
          <div className="comment-items" key={idx.toString()}>
            <div className="comment-item-content">
              <p key={`id${idx}`}>
                <b>{capitalize(comment.created_by)}</b> {activityList(comment)}
              </p>
            </div>
            {comment.reason ? (
              <div
                style={{ fontSize: 14, alignSelf: "flex-start", marginTop: 15 }}
              >
                <span style={{ fontWeight: "bold" }}>Reason:</span>{" "}
                {comment.reason}
              </div>
            ) : null}
            {comment.message ? (
              <div
                style={{
                  fontSize: 14,
                  alignSelf: "flex-start",
                  marginTop: 15,
                  marginBottom: 20,
                }}
              >
                {comment.action === "send_emails"
                  ? JSON.parse(comment.message).map((item, idx) => (
                      <>
                        {item}
                        {idx !== JSON.parse(comment.message).length - 1
                          ? ","
                          : null}{" "}
                      </>
                    ))
                  : comment.message}
              </div>
            ) : null}
            <div className="form-group">
              <Row>
                {comment
                  ? comment.photos.map((item, idx) => {
                      return (
                        <Col md="2" key={idx}>
                          <Zoom>
                            <img
                              style={{
                                width: 60,
                                height: 60,
                              }}
                              alt={`image_${idx}`}
                              key={idx}
                              src={`${item}`}
                            />
                          </Zoom>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </div>
            <div
              style={{
                textAlign: "right",
                alignSelf: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <p style={{ fontSize: 14, marginTop: 15, color: "#5f6368" }}>
                {moment(comment.created_at.toString()).format(
                  "DD-MM-YYYY hh:mm"
                )}
              </p>
            </div>
          </div>
        );
      })
    : null;

  const handleStartInspection = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          const updatedRegion = { ...region };
          updatedRegion.lat = position.coords.latitude;
          updatedRegion.lng = position.coords.longitude;
          props.history.push("/app/inspections/add/", {
            complaint: complaint,
            coordinates: updatedRegion,
          });
        },
        (error) => {
          console.log(error);
          props.history.push("/app/inspections/add/", {
            complaint: complaint,
          });
        }
      );
    }
  };

  const handleUpdateInspection = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          const updatedRegion = { ...region };
          updatedRegion.lat = position.coords.latitude;
          updatedRegion.lng = position.coords.longitude;
          props.history.push(`/app/inspections/${inspection.id}/edit/`, {
            complaint: complaint,
            inspection: inspection,
            coordinates: updatedRegion,
          });
        },
        (error) => {
          console.log(error);
          props.history.push(`/app/inspections/${inspection.id}/edit/`, {
            complaint: complaint,
            inspection: inspection,
          });
        }
      );
    }
  };

  const addEmailInput = emailValues
    ? emailValues.emails.map((el, i) => {
        return (
          <div key={i} style={{ display: "flex" }}>
            <div className="w-100">
              <TextInput
                placeholder={t("Email Address")}
                type="text"
                name="email"
                error={emailErrors.emails[i]}
                value={el || ""}
                onChange={(e) => handleEmailChange(i, e.target.value)}
              />
            </div>
            {i < emailValues.emails.length - 1 ? (
              <button
                type="button"
                onClick={() => removeClick(i)}
                style={{ width: "10%", height: "100%" }}
                className="btn btn-danger btn-user btn-block mb-3"
              >
                -
              </button>
            ) : (
              <button
                type="button"
                onClick={() => addClick()}
                style={{ width: "10%", height: "100%" }}
                className="btn btn-primary-green btn-user btn-block mb-3"
              >
                +
              </button>
            )}
          </div>
        );
      })
    : null;

  const validateEmailValues = (emails) => {
    let errorEmails = [...emails];
    emails.forEach((value, idx) => {
      if (value && !validateEmail(value)) {
        errorEmails[idx] = "Invalid email address";
      } else {
        errorEmails[idx] = " ";
      }
    });
    var shouldDisableSubmit = true;
    errorEmails.forEach((item) => {
      if (item.length > 1) {
        shouldDisableSubmit = true;
      } else {
        shouldDisableSubmit = false;
      }
    });
    setDisableEmailSubmit(
      shouldDisableSubmit ||
        emailValues.emails.some((item) => item.length === 0)
    );
    setEmailErrors({ emails: errorEmails });
  };

  const handleEmailChange = (index, value) => {
    let emails = [...emailValues.emails];
    emails[index] = value;
    setEmailValues({ emails: emails });
    validateEmailValues(emails);
  };

  const addClick = () => {
    setEmailValues({ emails: [...emailValues.emails, ""] });
  };

  const removeClick = (index) => {
    let emails = [...emailValues.emails];
    emails.splice(index, 1);
    setEmailValues({ emails: emails });
  };

  const handleEmailCancelBtn = () => {
    setIsSendEmail(false);
    setEmailValues({ emails: [""] });
    setEmailErrors({ emails: [""] });
  };

  const handleEmailSubmit = async () => {
    setIsSpinner(true);
    setDisableEmailSubmit(true);
    const data = {
      emails: emailValues.emails,
      id: complaint.id,
    };
    const resp = await onComplaintSendEmails(data);
    if (resp && resp.response) {
      setIsSendEmail(false);
      setIsSpinner(false);
      setEmailValues({ emails: [""] });
      setEmailErrors({ emails: [""] });
    } else {
      setIsSendEmail(false);
      setIsSpinner(false);
      setEmailValues({ emails: [""] });
      setEmailErrors({ emails: [""] });
    }
  };

  const searchComplaint=async()=>{
    setIsSameComplaint(false)
    if(complaintnumber!==complaint?.complaint_number){
    const data={'complaint_id':complaintnumber}
    const resp=await ongetComplaints(data)
    if(resp&&resp.response){
        if(resp.response.status=='success'&&resp.response.data.length>0){
        setDuplicateComplaint(resp.response.data[0])
        setNodata(false)
        }
       else setNodata(true)
    }
    else setNodata(true)
  }
  else setIsSameComplaint(true)
  }

  const handleCloseComplaint=async(action)=>{
    setIsSpinner(true);
    const data = {
      id: complaint?.id,
      action: action,
      closed_action_taken_message: action == "duplicate" ? duplicateReason : otherstatereason,        
      fso_additional_comment:
        action == "duplicate"
          ? `We apologize, but it appears that a complaint number - ${complaintnumber} with similar issue has already been submitted. To avoid duplication, please ensure that your complaint details are accurate before submission.`
          : `We regret to inform you that the complaint cannot be processed due to an incorrect state address provided in your complaint submission.`,
    };
    if(action=='duplicate'){
      data.search_complaint_number=complaintnumber
    }
      const resp = await onCloseComplaints(data);
      if (resp.response) {
          setIsSpinner(false);
          toast.success('Complaint closed!');
          setIsDuplicateComplaint(false)
          setIsOtherState(false)
          setIsSameComplaint(false)
          setDuplicateComplaint({})
          setDuplicateReason('')
          setNodata(false)
          setOtherStateReason('')
          setComplaintNumber('')
          props.history.push("/app/complaints");
      } else {
        toast.info('Some error occured')
      }

  }

  return (
    <div className="container-fluid">
      <ModelPopup
        modal={modal}
        data={complaint}
        handleDeleteSubmit={handleDeleteSubmit}
        title="Complaint"
        toggle={() => setModal(!modal)}
      ></ModelPopup>
      <h1 className="h4 mb-4 text-gray-800">{t("Complaint Details")}</h1>
      <div className="text-center">
        <h1 className="h2 mb-0 text-gray-800">
          {complaint ? complaint.name_of_restaurant : ""}
        </h1>
        {complaint && complaint.complaint_number ? (
          <div className="mb-2">
            <span style={{ fontWeight: "bold" }}>{t("Complaint No")}</span> :{" "}
            {complaint.complaint_number}
          </div>
        ) : null}
        {user && complaint && user.id === complaint.created_by ? (
          <p style={{ textAlign: "center", fontSize: 15, marginBottom: 10 }}>
            ( Created by Me )
          </p>
        ) : null}
        {complaint && complaint.current_status ? (
          <p>
            <span style={{ fontWeight: "bold" }}>{t("Current Status")}</span> :{" "}
            <>
              {complaint.current_status === "closed" ? (
                <span style={{ color: "green" }}>{t("Closed")}</span>
              ) : (
                [
                  complaint.current_status === "Waiting for do's approval" ? (
                    <span style={{ color: "grey" }}>
                      {t("waiting")}
                    </span>
                  ) :
                  complaint.current_status === "Inprogress"? <span>{t("Inprogress")}</span>:
                  (
                    <span style={{ color: "red" }}>{t("Open")}</span>
                  ),
                ]
              )}
            </>
          </p>
        ) : null}
      </div>
      <div className="complaint-detail-header">
        {/* {
          user && complaint && user.id === complaint.created_by ? (
            <>
              <div className="complaint-detail-header-item">
                <Link to={
                  {
                    pathname: `/app/complaints/${complaint ? complaint.id : null}/edit/`,
                    state: {
                      'complaint': complaint
                    }
                  }
                }>
                  <Button className="btn btn-success btn-circle btn-sm" title={t("Edit Complaint")}>
                    <i className="fas fa-edit"></i>
                  </Button>
                </Link>
              </div>
              <div className="complaint-detail-header-item">
                <Button onClick={() => setModal(!modal)} title={t("Delete Complaint")} className="btn btn-danger btn-circle btn-sm">
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
            </>
          ) : null
        } */}
        {/* <div className="complaint-detail-header-item">
          <Button onClick={() => setIsSendEmail(!isSendEmail)} title={t("Send Emails")} className="btn btn-warning btn-sm">
            <i className="fas fa-envelope"></i>
            <span className="ml-2">Send Emails</span>
          </Button>
        </div> */}
      </div>
      <div className="complaint__actions">
        <div className="row" style={{ justifyContent: "center" }}>
          {/* For Inspection */}
          {user &&
          complaint &&
          complaint.assigned_to &&
          user.user_types.includes("fso") &&
          !hideActionMycomplaints &&
          !complaint.is_inspected ? (
            <div key={"add-inspection"} className="col-sm-3">
              <button
                type="button"
                onClick={handleStartInspection}
                className="btn btn-primary-green font-medium btn-user btn-block mb-3"
              >
                {t("Start Inspection")}
              </button>
            </div>
          ) : (
            [
              user &&
              complaint &&
              inspection &&
              Object.keys(inspection).length > 0 &&
              user.user_types.includes("fso") &&
              !hideActionMycomplaints &&
              !inspection.is_approved ? (
                <div key={"edit-inspection"} className="col-sm-3">
                  <button
                    type="button"
                    onClick={handleUpdateInspection}
                    className="btn btn-primary-green font-medium btn-user btn-block mb-3"
                  >
                    {t("Edit Inspection")}
                  </button>
                </div>
              ) : null,
            ]
          )}
          {/* DO ACTIONS */}
          {user &&
          user.user_types.includes("do") &&
          complaint &&
          complaint.status !== "closed" &&
          complaint.district &&
          !hideActionMycomplaints ? (
            <>
              {actionItem
                ? actionItem.map((item, idx) => (
                    <div key={idx} className="col-sm-3">
                      <button
                        type="button"
                        onClick={() => handleActionChange(item.name)}
                        className="btn btn-primary-green font-medium btn-user btn-block mb-3"
                      >
                        {item.label}
                      </button>
                    </div>
                  ))
                : null}
            </>
          ) : null}
          {/* HO ACTIONS */}
          {user &&
          user.user_types.includes("ho") &&
          complaint &&
          !complaint.district &&
          !hideActionMycomplaints ? (
            <>
              {actionItem
                ? actionItem.map((item, idx) => (
                    <div key={idx} className="col-sm-3">
                      <button
                        type="button"
                        onClick={() => handleActionChange(item.name)}
                        className="btn btn-primary-green font-medium btn-user btn-block mb-3"
                      >
                        {item.label}
                      </button>
                    </div>
                  ))
                : null}
            </>
          ) : null}
          {/* FSO ACTIONS */}
          {complaint &&
          complaint.assigned_to &&
          user &&
          inspection &&
          complaint.status !== "closed" &&
          user.user_types.includes("fso") &&
          !hideActionMycomplaints ? (
            <>
              {actionItem
                ? actionItem.map((item, idx) => (
                    <div key={idx} className="col-sm-3">
                      <button
                        type="button"
                        onClick={() => handleActionChange(item.name)}
                        className="btn btn-primary-green font-medium btn-user btn-block mb-3"
                      >
                        {item.label}
                      </button>
                    </div>
                  ))
                : null}
            </>
          ) : null}
          {/* <div className="col-sm-3">
            <button
              type="button"
              onClick={() => setIsSendEmail(!isSendEmail)}
              title={t("Share via Email")}
              className="btn btn-primary-green font-medium btn-user btn-block mb-3"
            >
              <i className="fas fa-envelope"></i>
              <span className="ml-2">{t("Share via Email")}</span>
            </button>
          </div> */}
        </div>
        {/* SEND EMAILS POPUP */}
        {isSendEmail ? (
          <Modal isOpen={isSendEmail}>
            <ModalHeader toggle={() => setIsSendEmail(false)}>
              {t("Add Email Address")}
            </ModalHeader>
            <ModalBody>
              <div>
                {addEmailInput}
                <div className="text-center mt-3 mb-3">
                  <Button color="default" onClick={handleEmailCancelBtn}>
                    {t("Cancel")}
                  </Button>{" "}
                  <Button
                    disabled={disableEmailSubmit}
                    color="success"
                    onClick={handleEmailSubmit}
                  >
                    <ClipLoader
                      color="#fff"
                      loading={isSpinner}
                      size={15}
                      css={{ marginRight: "10px" }}
                    />
                    <span>{t("Submit")}</span>
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
        {toggleAction ? (
          <Modal isOpen={toggleAction}>
            <ModalHeader toggle={() => setToggleAction(false)}>
              {t("Actions")}
            </ModalHeader>
            <ModalBody>
              <div>
                {assignPopup &&
                action &&
                action !== "fso_assign_to_do" &&
                action !== "assign_to_ho" &&
                action !== "same_fso" ? (
                  <>
                    <div className="form-group">
                      <DropDownInput
                        type="text"
                        items={fsoOfficers}
                        name="assigned_fso"
                        placeholder="Select food safety officer"
                        value={actionValues.assigned_fso}
                        // valueId={true}
                        onChange={(text) =>
                          handleTextActionChange("assigned_fso", text)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <TextAreaInput
                        placeholder={"Remarks"}
                        name="reason"
                        value={actionValues.reason}
                        onChange={(e) =>
                          handleTextActionChange("reason", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : null}
                {action &&
                (action === "fso_assign_to_do") |
                  (action === "assign_to_ho") |
                  (action === "same_fso") ? (
                  <div className="form-group">
                    <TextAreaInput
                      placeholder={"Remarks"}
                      name="reason"
                      value={actionValues.reason}
                      onChange={(e) =>
                        handleTextActionChange("reason", e.target.value)
                      }
                    />
                  </div>
                ) : null}
                {/* HO ASSIGN DISTRICT */}
                {assignDistrictPopup &&
                action &&
                action === "assign_to_district" ? (
                  <>
                    <div className="form-group">
                      <DropDownInput
                        type="text"
                        items={districts}
                        name="assigned_district"
                        placeholder="Select District"
                        value={actionValues.assigned_district}
                        valueId={true}
                        onChange={(text) =>
                          handleTextActionChange("assigned_district", text)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <TextAreaInput
                        placeholder={"Remarks"}
                        name="reason"
                        value={actionValues.reason}
                        onChange={(e) =>
                          handleTextActionChange("reason", e.target.value)
                        }
                      />
                    </div>
                  </>
                ) : null}
                {action ? (
                  <>
                    {user && user.user_types.includes("fso") ? (
                      <div>
                        <p>
                          Note: The Complaint #
                          {complaint ? complaint.complaint_number : ""} you have
                          selected will no longer be able to access are you sure
                          to reassign.
                        </p>
                      </div>
                    ) : null}
                    <div className="text-center">
                      <Button color="default" onClick={handleCancelBtn}>
                        {t("Cancel")}
                      </Button>{" "}
                      <Button
                        className="font-medium"
                        disabled={disableActionSubmit}
                        color="success"
                        onClick={handleActionSubmit}
                      >
                        <ClipLoader
                          color="#fff"
                          loading={isSpinner}
                          size={15}
                          css={{ marginRight: "10px" }}
                        />
                        <span>{t("I understand reassin now")}</span>
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
            </ModalBody>
          </Modal>
        ) : null}
        {isotherstate ? (
          <Modal isOpen={isotherstate}>
            <ModalHeader toggle={() =>{
              setIsOtherState(false)
              setOtherStateReason('')
            }}>
              {t("Actions")}
            </ModalHeader>
            <ModalBody>
              <div>
                  <>
                    <div className="form-group">
                      <TextAreaInput
                        placeholder={"Remarks *"}
                        name="reason"
                        value={otherstatereason}
                        onChange={(e) => setOtherStateReason(e.target.value) }
                      />
                    </div>
                  </>
                  <>
                    <div className="text-center">
                      <Button color="default" onClick={() =>{
                        setIsOtherState(false)
                        setOtherStateReason('')
                      }}>
                        {t("Cancel")}
                      </Button>{" "}
                      <Button
                        className="font-medium"
                        disabled={otherstatereason?.trim().length === 0}
                        color="success"
                        onClick={()=>handleCloseComplaint('other_state')}
                      >
                        <ClipLoader
                          color="#fff"
                          loading={isSpinner}
                          size={15}
                          css={{ marginRight: "10px" }}
                        />
                        <span>Close Complaint</span>
                      </Button>
                    </div>
                  </>
              </div>
            </ModalBody>
          </Modal>
        ) : null}
        {isduplicateComplaint ? (
          <Modal isOpen={isduplicateComplaint}>
            <ModalHeader toggle={() =>{
              setIsDuplicateComplaint(false)
              setComplaintNumber('')
              setDuplicateReason('')
              setDuplicateComplaint({})
            }}>
              {t("Actions")}
            </ModalHeader>
            <ModalBody>
              <div>
                  <>
                    <div className="form-group d-flex">
                      <TextInput
                        placeholder={"Enter complaint number"}
                        name="complaintNumber"
                        value={complaintnumber}
                        onChange={(e) => setComplaintNumber(e.target.value)
                        }
                      />
                      <Button color="success" onClick={() => searchComplaint()}>
                        Search
                      </Button>{" "}
                    </div>
                   
                  </>
                  {complaintnumber&&nodata&&<>
                  <h6 style={{color:'red'}}>No data available for this complaint number!</h6>
                  </>}
                  {complaintnumber&&isSameComplaint&&<>
                  <h6 style={{color:'red'}}>Complaint Number should not be the same as selected complaint!</h6>
                  </>}
                  {Object.keys(duplicateComplaint).length !== 0&& <>
                  <h6 className="pt-2">Complaint Number: {duplicateComplaint&&duplicateComplaint?.complaint_number}</h6>
                  <h6 className="py-2">Establishment Name: {duplicateComplaint&&duplicateComplaint?.name_of_restaurant}</h6>
                  {/* <h6 className="pb-2">Complaint Status: {duplicateComplaint&&duplicateComplaint?.status}</h6> */}
                  <h6 className="pb-2">Complaint Current Status: {duplicateComplaint&&duplicateComplaint?.current_status}</h6>
                  </>}
                  {Object.keys(duplicateComplaint).length !== 0&&<>
                      <TextAreaInput
                        placeholder={"Remarks *"}
                        name="reason"
                        value={duplicateReason}
                        onChange={(e) => setDuplicateReason(e.target.value) }
                      />
                    </>}
                 {Object.keys(duplicateComplaint).length !== 0&& <>
                    <div className="text-center my-3">
                      <Button color="default" onClick={() => {
                          setIsDuplicateComplaint(false)
                          setComplaintNumber('')
                          setDuplicateReason('')
                          setDuplicateComplaint({})
                      }}>
                        {t("Cancel")}
                      </Button>{" "}
                      <Button
                        className="font-medium"
                        disabled={duplicateReason?.trim().length === 0}
                        color="success"
                        onClick={()=>handleCloseComplaint('duplicate')}
                      >
                        <ClipLoader
                          color="#fff"
                          loading={isSpinner}
                          size={15}
                          css={{ marginRight: "10px" }}
                        />
                        <span>Close Complaint</span>
                      </Button>
                    </div>
                  </>}
              </div>
            </ModalBody>
          </Modal>
        ) : null}
      </div>
      <div className="mt-5">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {t("Complaint Details")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {t("Comments")}
            </NavLink>
          </NavItem>
          {inspection &&
          inspection.length > 0 &&
          user &&
          complaint &&
          user.user_types.includes("do") ? (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                {t("Inspection Info")}
              </NavLink>
            </NavItem>
          ) : (
            [
              inspection &&
              Object.keys(inspection).length > 0 &&
              user &&
              complaint &&
              (inspection.is_approved ||
                (user.user_types.includes("do") &&
                  user.district === complaint.district &&
                  complaint.awaiting_inspection_approval) ||
                (user.user_types.includes("fso") &&
                  user.id === inspection.last_updated_by)) ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    {t("Inspection Info")}
                  </NavLink>
                </NavItem>
              ) : null,
            ]
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="card shadow mb-4">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>{t("Trader Type")}</td>
                        <td>{traderType}</td>
                      </tr>
                      <tr>
                        <td>{t("Nature of Food")}</td>
                        <td>{natureOfFood}</td>
                      </tr>
                      <tr>
                        <td>{t("Condition of Food")}</td>
                        <td>{foodCondition}</td>
                      </tr>
                      <tr>
                        <td>{t("Issue of Food")}</td>
                        <td>{issueOfFood}</td>
                      </tr>
                      <tr>
                        <td>{t("Cash Bill No")}</td>
                        <td>{complaint ? complaint.cash_bill_number : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("Created At")}</td>
                        <td>
                          {complaint && complaint.created_at
                            ? moment(complaint.created_at.toString()).format(
                                "DD-MM-YYYY HH:MM"
                              )
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("Product Purchased On")}</td>
                        <td>
                          {complaint && complaint.product_purchased_on
                            ? moment(
                                complaint.product_purchased_on.toString()
                              ).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("Product Used/Opened On")}</td>
                        <td>
                          {complaint && complaint.product_used_on
                            ? moment(
                                complaint.product_used_on.toString()
                              ).format("DD-MM-YYYY")
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("District")}</td>
                        <td>{districtName}</td>
                      </tr>
                      <tr>
                        <td>{`${t("Street Address")} 1`}</td>
                        <td>{complaint ? complaint.sub_division : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("House/Flat No")}</td>
                        <td>{complaint ? complaint.street : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("City")}</td>
                        <td>{complaint ? complaint.village : "-"}</td>
                      </tr>
                      <tr>
                        <td>{`${t("cv")}`}</td>
                        <td>{complaint ? complaint.sub_division1 : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("ZipCode")}</td>
                        <td>{complaint ? complaint.zipcode : "-"}</td>
                      </tr>
                      <tr>
                        <td>{t("Short Note")}</td>
                        <td>{complaint ? complaint.short_note : "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="form-group">
                  <Row>
                    {complaint
                      ? complaint.photos.map((item, idx) => {
                          return (
                            <Col key={idx}>
                              <Zoom>
                                <img
                                  style={{
                                    width: 100,
                                    height: 100,
                                  }}
                                  alt={`image_${idx}`}
                                  key={idx}
                                  src={`${item}`}
                                />
                              </Zoom>
                            </Col>
                          );
                        })
                      : null}
                  </Row>
                </div>
                <div className="form-group">
                  <GoogleMapView
                    region={region}
                    multiple={true}
                    markerCoordinates={markerCoordinates}
                    setMarkerCoordinates={setMarkerCoordinates}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="card shadow mb-4">
              <div className="card-body">
                {complaint && complaint.status !== "closed" ? (
                  <div className="form-group comment-form">
                    <Accordion title={t("Add comment")}>
                      <div className="form-group">
                        <TextAreaInput
                          placeholder={`${t("Enter your comment")}`}
                          name="message"
                          error={errors.message}
                          value={values.message}
                          onChange={(e) =>
                            handleTextChange("message", e.target.value)
                          }
                        />
                      </div>
                  <div className="form-group">
                        <Checkbox
                          placeholder={`${t("Visible to consumer")}`}
                          name="visible_to_consumer"
                          disabled={values&&values.request_info_from_consumer}
                          value={values.visible_to_consumer || false}
                          onChange={(text) =>
                            handleTextChange("visible_to_consumer", text)
                          }
                        />
                        <div>
                          {/* {(values && values.visible_to_consumer) || false ? (
                            <p>
                              Visible to customer dashboard, FSO need not click
                              Visible to customer.
                            </p>
                          ) : null} */}
                        </div>
                      </div>
                      <div className="form-group">
                        <Checkbox
                          placeholder={`${t("Request Information from Consumer")}`}
                          name="request_info_from_consumer"
                          value={values.request_info_from_consumer || false}
                          onChange={(text) =>
                            handleTextChange("request_info_from_consumer", text)
                          }
                        />
                      </div>
                      <div className="form-group mt-4 mb-4">
                        <p>{t('upp')}</p>
                        <div className="row">
                          <div className="col-sm-2">
                            <UploadImageInput
                              size={80}
                              photo={values.image_1}
                              name="image_1"
                              handleRemoveImages={(text) =>
                                handleRemoveImages("image_1", text)
                              }
                              onChangeText={(text) =>
                                handleTextChange("image_1", text)
                              }
                            />
                          </div>
                          <div className="col-sm-2">
                            <UploadImageInput
                              size={80}
                              photo={values.image_2}
                              name="image_2"
                              handleRemoveImages={(text) =>
                                handleRemoveImages("image_2", text)
                              }
                              onChangeText={(text) =>
                                handleTextChange("image_2", text)
                              }
                            />
                          </div>
                          <div className="col-sm-2">
                            <UploadImageInput
                              size={80}
                              photo={values.image_3}
                              name="image_3"
                              handleRemoveImages={(text) =>
                                handleRemoveImages("image_3", text)
                              }
                              onChangeText={(text) =>
                                handleTextChange("image_3", text)
                              }
                            />
                          </div>
                          <div className="col-sm-2">
                            <UploadImageInput
                              size={80}
                              photo={values.image_4}
                              name="image_4"
                              handleRemoveImages={(text) =>
                                handleRemoveImages("image_4", text)
                              }
                              onChangeText={(text) =>
                                handleTextChange("image_4", text)
                              }
                            />
                          </div>
                          <div className="col-sm-2">
                            <UploadImageInput
                              size={80}
                              photo={values.image_5}
                              name="image_5"
                              handleRemoveImages={(text) =>
                                handleRemoveImages("image_5", text)
                              }
                              onChangeText={(text) =>
                                handleTextChange("image_5", text)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          disabled={disableSubmit}
                          type="button"
                          onClick={() => handleSubmitButton()}
                          className="btn btn-success"
                        >
                         {`${t( "Submit Comment")}`}
                        </button>
                      </div>
                    </Accordion>
                  </div>
                ) : null}
                {commentsList}
              </div>
            </div>
          </TabPane>
          {inspection &&
          inspection.length > 0 &&
          user &&
          complaint &&
          user.user_types.includes("do") ? (
            <TabPane tabId="3">
              <div className="card shadow mb-4">
                <div className="card-body inspection__form">
                  <div className="form-group comment-form">
                    {inspection.map((inspectionData, idx) => {
                      return (
                        <div
                          key={idx}
                          className="form-group mt-2"
                          style={{ padding: "5px 25px" }}
                        >
                          <NewAccordion
                            key={idx}
                            title={`FSO (${inspectionData.last_updated_by})
                            ${
                              inspectionData.is_deleted
                                ? `Inspection declined`
                                : ""
                            } - ${moment(
                              inspectionData.created_at.toString()
                            ).format("DD-MM-YYYY hh:mm")}`}
                            data_id={inspectionData.id}
                            index={idx}
                            activeAccordion={activeAccordion}
                            handleAccordionClick={handleAccordionClick}
                            activateAccordion={() =>
                              handleActivateAccordion(idx)
                            }
                          >
                            <div className="card shadow">
                              <div className="card-body">
                                <InspectionInfo
                                  districts={districts}
                                  inspection={inspectionData}
                                  user={user}
                                  complaint={complaint}
                                  inspectionApprove={inspectionApprove}
                                  isSpinner={isSpinner}
                                />
                              </div>
                            </div>
                          </NewAccordion>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </TabPane>
          ) : (
            [
              inspection &&
              Object.keys(inspection).length > 0 &&
              user &&
              complaint &&
              (inspection.is_approved ||
                (user.user_types.includes("do") &&
                  user.district === complaint.district &&
                  complaint.awaiting_inspection_approval) ||
                (user.user_types.includes("fso") &&
                  user.id === inspection.last_updated_by)) ? (
                <TabPane tabId="3">
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      <InspectionInfo
                        districts={districts}
                        inspection={inspection}
                        user={user}
                        complaint={complaint}
                        inspectionApprove={inspectionApprove}
                        isSpinner={isSpinner}
                      />
                    </div>
                  </div>
                </TabPane>
              ) : null,
            ]
          )}
        </TabContent>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetInspection: (data) => dispatch(getInspection(data)),
  onDeleteComplaint: (data) => dispatch(deleteComplaint(data)),
  onComplaints: () => dispatch(getComplaints()),
  onCreateComment: (data) => dispatch(createComment(data)),
  onComplaint: (data) => dispatch(getComplaint(data)),
  onGetComments: (data) => dispatch(getComments(data)),
  onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
  onComplaintAssign: (data) => dispatch(doComplaintAssign(data)),
  onComplaintUnassign: (data) => dispatch(doComplaintUnassign(data)),
  onApproveInspection: (data) => dispatch(approveInspection(data)),
  onComplaintSendEmails: (data) => dispatch(doComplaintSendEmails(data)),
  ongetComplaints: (data) => dispatch(getDelCOmplaints(data)),
  onCloseComplaints: (data) => dispatch(ComplaintClosure(data)),
  onCreateInspection: (data) => dispatch(createInspection(data)),
});

const mapStateToProps = (state) => ({
  complaints: state.home.complaints,
  comments: state.home.comments,
  inspection: state.home.inspection,
  user: state.home.user,
  districts: state.home.districts,
  foodConditions: state.home.foodConditions,
  traderTypes: state.home.traderTypes,
  foodTypes: state.home.foodTypes,
  foodIssues: state.home.foodIssues,
  fsoOfficers: state.home.fsoOfficers,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintDetails);
