import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useTranslation } from "react-i18next";

const GooglePlaceAutoComplete = (props) => {
  const [address, setAddress] = useState("");

  const {
    districts,
    values,
    region,
    setMarkerCoordinates,
    setRegion,
    setValues,
    validateValues,
  } = props;
  const { t } = useTranslation();

  const handleSelect = async (value, placeId, suggestion) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    const updatedValues = { ...values };
    const mapRegion = { ...region };
    results[0].address_components.forEach((address) => {
      const types = address.types;
      if (types.indexOf("administrative_area_level_2") > -1) {
        const districtName = address.long_name;
        // updatedValues.district = districtName;
        const districtId = districts.filter((district) => {
          return district.name.toLowerCase() === districtName.toLowerCase();
        });
        console.log("District Id");
        console.log(districtId);
        if (districtId.length) {
          updatedValues.district = districtId[0].id;
        }
      } else if (types.indexOf("locality") > -1) {
        updatedValues.village = address.long_name;
      } else if (
        types.indexOf("sublocality") > -1 ||
        types.indexOf("route") > -1
      ) {
        updatedValues.sub_division = results[0].formatted_address;
      } else if (types.indexOf("street_number") > -1) {
        updatedValues.street = address.long_name;
      } else if (types.indexOf("postal_code") > -1) {
        updatedValues.zipcode = address.long_name;
      }
    });
    updatedValues.name_of_restaurant = suggestion.formattedSuggestion.mainText;
    updatedValues.coordinates = [latLng.lat, latLng.lng];
    updatedValues.google_place_id = placeId;
    setValues(updatedValues);
    validateValues(updatedValues);
    mapRegion.lat = latLng.lat;
    mapRegion.lng = latLng.lng;
    const newMarkerCoordinates = {
      lat: latLng.lat,
      lng: latLng.lng,
    };
    setMarkerCoordinates(newMarkerCoordinates);
    setRegion(mapRegion);
  };

  const searchOptions = {
    componentRestrictions: { country: ["in"] },
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      searchOptions={searchOptions}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <input
            {...getInputProps({
              placeholder: `${t("Search Places")} ...`,
              className: "location-search-input form-control",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, idx) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  key={idx}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlaceAutoComplete;
