import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { userDirectory } from "../../components/reducers/actions";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const PIOComm = (props) => {
  const { onUserDirectory, user } = props;
  const[users, setUsers]=useState();
  const { t, i18n } = useTranslation();

  
 
  
  return (
    <div style={{marginBottom:'150px'}}>
      <Header />
      <header className="page-header" style={{ backgroundColor: "#1589FF" }}>
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">
                {t("Food Safety Department -PIO's")}
              </h1>
             
            </div>
          </div>
        </div>
      </header>
      <div style={{width:'100%', margin:'0 auto'}}>
      <div style={{fontSize:'14px',marginTop:20,marginLeft:'100px'}}>
          {localStorage.i18nextLng === 'en' ? (
     <>
     <div>
     <h6 style={{textDecoration:'underline'}}>Appellate Authority</h6>
         <p>044-24310046,<br />
             commrfssatn@gmail.com,<br />
       No. 359, Anna Salai,<br /> 5th Floor, DMS Campus,<br /> Teynampet, Chennai-6</p>
       
     </div>
     <h6 style={{textDecoration:'underline'}}>Public Information Officer - Ariyalur</h6>
    <p>
        04329-223576,<br/>
        dofssaari@gmail.com,<br/>
        IInd Floor, Old Collectorate Complex,<br/> Jayankondam Road, Ariyalur – 621 704
    </p>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Chennai</h6>
        <p>
        044-23813095,<br/> dofssache@gmail.com,<br/> 
       Office of the Deputy Director of Health Services,<br/>  No. 33, West Jones Road,<br/>  Saidapet, Chennai – 600 015
        </p>
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Coimbatore</h6>
    <p>0422-2220922,<br/>dofssacoimbatore@gmail.com,<br/>
     219, Race Course Road,<br/> Coimbatore – 641 018.</p>
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Cuddalore</h6>
    <p>04142-221081, <br/>
    dofssacud@gmail.com,<br/> 
    Room No. 315, Second Floor, New Collectorate Building,<br/>  Alpettai, Cuddalore – 607 001.</p>

    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Dharmapuri</h6>
        <p>04342-230385, <br/>
        dofssadpi@gmail.com,<br/>
        Disdivict Collectorate Office Campus,<br/>
         Tamilnadu Arasu Cabel TV Organisation,<br/> Dharmapuri – 636 701</p>
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Dindigul</h6>
             <p>0451-2435020,<br/>
        fsddgldo@gmail.com,<br/>
        Office of the Deputy Director of Health Services,<br/> Palani Road,<br/> Dindigul – 624 002</p>
     
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Erode</h6>
    <p>0424-2223545, <br/>
       dofssaerd@gmail.com,<br/>
        Office of the Deputy Director of Health Services,<br/> Government Headquarters Hospital Campus,<br/> Erode – 638 009. </p>
       
     
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Kancheepuram</h6>
    <p>044-27233540, <br/>
        dofssakpm@gmail.com,<br/>
     Office of the Deputy Director of Health Services Kanchipuram Campus,<br/> 42A, Railway Road,<br/> Kanchipuram – 631 501.</p>
      
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Chengalpattu</h6>
        <p>044-27233541 <br/>
       dofssakpm@gmail.com,<br/>
       Office of the Deputy Director of Health Services Kanchipuram Campus,<br/> 42A, Railway Road,<br/> Kanchipuram – 631 501.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Kanyakumari</h6>

    <p>04652-276786,<br/>
     dofssakkm@gmail.com,<br/>
    36, Sannathi Sdiveet,<br/> Krishnan Koil, Vadacheri,<br/> Nagarcoil – 629 001.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Krishnagiri</h6>

    <p>04343-230102,<br/>
        dofssakgi@gmail.com,<br/>
       Room No. 128, Second Floor,<br/> Collector Office, Krishnagiri – 635 001.</p>
      
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Karur</h6>
    <p>04324-255347,<br/>
       dofssakar@gmail.com,<br/>
       66, Disdivict Medivo Project Complex,<br/> Multistoried Building, Collectorate Campus,<br/> Karur – 639 001</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Madurai</h6>
    <p>0452–2640036, <br/>
        dofssamdu@gmail.com,<br/>
       Office of the Deputy Director of Health Services,<br/> Multipurpose Health Supervisors (F) divaining School,<br/>  Viswanathapuram,  Madurai – 625 014.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Nagapattinam</h6>

    <p>04365-253054,<br/>
        dofssangp@gmail.com, <br/>
       No. 315, 3rd Floor, Collector Office,<br/> Nagapattinam – 611 001.</p>
       
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Mayiladudurai</h6>
    <p>04365-253055,<br/>
        dofssamyd@gmail.com,<br/>
       Collector Office,<br/> Mayiladudurai – 609001.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Namakkal</h6>
    <p>04286-281242,<br/>
      dofssanmk@gmail.com, <br/>
       Collectorate Campus, Thiruchengode Road, <br/>Nallipalayam Post, Namakkal – 637 003.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Perambalur</h6>
    <p>04328–224033,<br/>
    dofssapmb1@gmail.com,<br/>
    Second Floor, Collectorate Complex,<br/> Perambular – 621 212.</p>
      
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Pudukottai</h6>
    <p>04322-222199,<br/>
     dofssapdknew@gmail.com, <br/>
        Office of the Deputy Director of Health Services, <br/>6th Sdiveet, Santhanathapuram Pudukkottai – 622 001.</p>
 
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Ramanathapuram</h6>
    <p>04567–231170,<br/>
      dofssarmd@gmail.com,<br/>
   Collectorate Complex, Opp. to Police in service divaining cendive,<br/> Ramanathapuram – 623 503.</p>
  
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Salem</h6>
    <p>0427–2450332,<br/>
     dofssaslm@gmail.com,<br/>
    Office of the Deputy Director of Health Services,<br/> Old Disdivict Board Building  Campus,<br/> Salem – 636 001.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Sivagangai</h6>

    <p>04575-243725,<br/>
      dofssasvg@gmail.com,<br/>
     Room No. 239, Second Floor, <br/>Collector Office, Sivagangai – 630 562.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Thanjavur</h6>
    <p>0436-2276511,<br/>
     dofssatnj@gmail.com,<br/>
      Office of the Deputy Director of Health Services,<br/> Gandhiji Road, Thanjavur – 613 001.</p>
     
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - The Nilgiris</h6>
    <p>0423-2450088,<br/>
     dofssanlg@gmail.com,<br/>
      No. 1-B Block, Additional Collector Office, Finger Post,<br/>  Ootacamund – 643 005</p>
    
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Theni</h6>

    <p>04546-294434,<br/>
        dofssathn1@gmail.com,<br/>
        Office of the Deputy Director of Health Services,<br/> Master Plan Complex, Behind Disdivict Collectorate,<br/> Theni – 625 531.</p>
   
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Tiruvallur</h6>
    <p>044-27662002,<br/>
        dofssatlr@gmail.com,<br/>
        2nd Floor, Collectorate Campus,<br/> Thiruvallur - 602 001</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Tiruvannamalai</h6>
    <p>04175-233416,<br/>
        dofssatvm@gmail.com,<br/>
       Chengam Road, Old Government Hospital 1st Floor, <br/>Thiruvannamalai - 606 603</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Thiruvarur</h6>
    <p>04366- 241034,<br/>
       dofssatvr1@gmail.com,<br/>
     Office of the Deputy Director of Health Services,<br/> Ezhil Nagar, Vilamal Post, Opp. to State Bank of India,<br/> Thiruvarur – 613 701.</p>
       
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Tirunelveli</h6>
    <p>0462-2555070 ,<br/>
  dofssatnv@gmail.com,<br/>
       Medical College Campus, Opp. to Income Tax Office,<br/> Thiruchendur Road, Maharajanagar  Post., <br/>Thirunelveli – 627 011.</p>
       
        
    </div>
    {/* <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Thenkasi</h6>
    <p>0462-2555071,<br/>
    dofssatnv@gmail.com,<br/>
      Medical College Campus, Opp. to Income Tax Office,<br/> Thiruchendur Road, Maharajanagar  Post., <br/>Thirunelveli – 627 011.</p>
       
        
    </div> */}
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Tiruppur</h6>
    <p>0421-2971190,<br/>
        dofssatpr@gmail.com,<br/>
        New Collectorate Building,<br/> Room No. 429-431, IVth Floor, <br/>Thiruppur – 641 604.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Tiruchirappalli</h6>
    <p>0431-2333330,<br/>
        dofssatut@gmail.com,<br/>
       Office of the Deputy Director of Health Services,<br/> Near Jamal Mohammed College, Race Course Road,<br/> Thiruchirappalli – 620 020.</p>
       
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Thoothukudi</h6>
    <p>0461-2340699,<br/>
     dofssadivy1@gmail.com,<br/>
      Agriculture Join Director Buildings,<br/> Ist Floor, Collectorate Campus,<br/> Korampallam, Thoothukudi- 628 101.</p>
 
        
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Vellore</h6>
    <p>0416–2258249,<br/>
       dofssavel@gmail.com,<br/>
     B-Block, IInd Floor,<br/> Collectorate Campus, Vellore – 632 001.</p>
       
       
    </div>
    {/* <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Thiruppathur</h6>
    <p>0416–2258250,<br/>
        dofssavel@gmail.com,<br/>
        B-Block, IInd Floor,<br/> Collectorate Campus, Vellore – 632 001.</p>
       
      
    </div> */}
    {/* <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Ranipet</h6>
    <p>0416–2258251,<br/>
        dofssavel@gmail.com,<br/>
     B-Block, IInd Floor, Collectorate Campus, <br/>Vellore – 632 001.</p>
      
       
    </div> */}
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Villuppuram</h6>
    <p>04146- 228546,<br/>
       dofssavpm@gmail.com,<br/>
       No. 38, 2nd Floor, Collectorate Building,<br/> Villupuram – 605 602.</p>
       
       
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Kallakuruchi</h6>
    <p>04146- 228547,<br/>
        dofssakkr@gmail.com,<br/>
     No. 38, 2nd Floor, Collectorate Building,<br/> Kallakuruchi – 606 202.</p>
       
      
    </div>
    <div>
    <h6 style={{textDecoration:'underline'}}>Public Information Officer - Virudhunagar</h6>
    <p>04562-252255,<br />
        dofssavnr1@gmail.com,<br />
       Room No. 205, Second Floor, Collectorate Complex,<br /> Virudhunagar  - 626 002.</p>
  
    </div>
    </>
                ):(
  <>
<div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - சென்னை</h6>
<p>044-24310046,<br/>
        commrfssatn@gmail.com,<br/>
       No. 359, அண்ணா சாலை,  DMS வளாகம், <br/>தேனாம்பேட்டை, சென்னை.-6</p>
       
</div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - அரியலூர்</h6>

<p>04329-223576,<br/>
        dofssaari@gmail.com,<br/>
        2வது தளம்,  மாவட்ட ஆட்சியர் அலுவலகம் ஜெயங்கொண்டம் ரோடு,<br/>  அரியலூர் – 621 704 </p>
      
        
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - சென்னை</h6>

<p>044-23813095,<br/>
        dofssache@gmail.com,<br/>
         துணை இயக்குனர் நலப்பணிகள் அலுவலகம்,<br/> No. 33, மேற்கு ஜோன்ஸ் ரோடு, சைதாபேட்டை,<br/> சென்னை. 600 015</p>

        
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - கோயம்புத்தூர்</h6>

<p>0422-2220922,<br/>
dofssacud@gmail.com,<br />
       219, குதிரை பந்தய சாலை,<br/> கோயம்புத்தூர் – 641 018.</p>
       
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - கடலூர்</h6>

<p>04142-221081,<br/>
        dofssacud@gmail.com,<br />
       அறை எண். 315, இரண்டாவது தளம்,<br /> புதிய மாவட்ட ஆட்சியர் கட்டிடம்,<br />  கடலூர். – 607 001.</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - தர்மபுரி</h6>

<p>04342-230385,<br/>
        dofssadpi@gmail.com,<br />
        மாவட்ட ஆட்சியர் அலுவலக வளாகம்,<br /> தமிழ்நாடு அரசு கேபிள் TV நிறுவனம் <br /> தர்மபுரி 636 701</p>
      
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - திண்டுக்கல்</h6>

<p>0451-2435020,<br/>
       fsddgldo@gmail.com,<br/>
     துணை இயக்குனர் நலப்பணிகள் அலுவலக வளாகம்,<br/>பழனி ரோடு,<br/> திண்டுக்கல்– 624 002</p>
        
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - ஈரோடு</h6>

<p>0424-2223545,<br/>
       dofssaerd@gmail.com,<br />
     துணை இயக்குனர் நலப்பணிகள் வளாகம், <br /> அரசு தலைமை மருத்துவமனை வளாகம்,<br />  ஈரோடு – 638 009. </p>
        
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - காஞ்சிபுரம்</h6>

<p>044-27233540,<br/>
      dofssakpm@gmail.com,<br />
      துணை இயக்குனர் நலப்பணிகள் வளாகம்,<br />  42A, ரயில்வே ரோடு, காஞ்சிபுரம். – 631 501.</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - செங்கல்பட்டு</h6>

<p>044-27233541,<br/>
        dofssakpm@gmail.com,<br/>
     துணை இயக்குனர் நலப்பணிகள் வளாகம், <br/> 42A, ரயில்வே ரோடு, காஞ்சிபுரம். – 631 501.</p>
       
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - கன்னியாகுமரி</h6>
        
<p>04652-276786,<br/>
        dofssakkm@gmail.com,<br />
        36, சன்னதி தெரு. கிருஷ்ணன் கோயில், வடசேரி,<br />  நாகர்கோயில், – 629 001</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - கிருஷ்ணகிரி</h6>

<p>04343-230102,<br/>
        dofssakgi@gmail.com,<br />
        அறை எண். 128, 2வது தளம்,  மாவட்ட ஆட்சியர் அலுவலகம்,<br />  கிருஷ்ணகிரி– 635 001.</p>
        
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - கரூர்</h6>
<p>04324-255347,<br />
        dofssakar@gmail.com,<br />
      66, மாவட்ட  மெட்ரோ திட்ட  வளாகம் மாவட்ட ஆட்சியர் அலுவலகம்,<br />  கரூர். – 639 001</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - மதுரை</h6>
<p>0452–2640036,<br />
        dofssamdu@gmail.com,<br />
        துணை இயக்குனர் நலப்பணிகள் அலுவலக வளாகம், <br />சுகாதார மேற்பார்வையாளர்,(பெண்கள்) பயிற்சி நிலையம், <br />விஸ்வநாதபுரம், மதுரை.– 625 014.</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - நாகப்பட்டினம்</h6>

<p>04365-253054,<br />
        dofssangp@gmail.com,<br />
        No. 315, 3 வது தளம்,  மாவட்ட ஆட்சியர் அலுவலகம், <br />நாகப்பட்டினம் – 611 001.</p>
       
     
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - மயிலாடுதுறை</h6>

<p>04365-253055 ,<br/>
dofssamyd@gmail.com,<br/>
    <br/>  மாவட்ட ஆட்சியர் அலுவலகம், மயிலாடுதுறை – 611 001.</p>
       
        
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - நாமக்கல்</h6>
<p>04286-281242,<br />
        dofssanmk@gmail.com,<br/>
      
       மாவட்ட ஆட்சியர் அலுவலகம், <br/> திருசெங்கோடு, நாமக்கல்– 637 003.</p>
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - பெரம்பலூர்</h6>

<p>04328–224033,<br />
       dofssapmb1@gmail.com,<br />
      2வது தளம்,  மாவட்ட ஆட்சியர் அலுவலகம், <br /> பெரமபலூர் – 621 212.</p>
       
        
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - புதுக்கோட்டை</h6>

<p>04322-222199,<br/>
        dofssapdknew@gmail.com,<br/>
    துணை இயக்குனர் நலப்பணிகள்  6வது தெரு,<br/>  சந்தானபுரம்,  புதுக்கோட்டை – 622 001.</p>
        
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - இராமநாதபுரம்</h6>

<p>04567–231170,<br/>
        dofssarmd@gmail.com<br/>
        மாவட்ட ஆட்சியர் அலுவலகம் காவலர் பயிற்சி மையம் எதிரில்,<br/> இராமநாதபுரம் – 623 503.</p>
       
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - சேலம்</h6>

<p>0427–2450332,<br />
        dofssaslm@gmail.com ,<br/>
       துணை இயக்குனர் நலப்பணிகள்  பழைய மாவட்ட அமைப்பு கட்டிட வளாகம், <br/>  சேலம் – 636 001.</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - சிவகங்கை</h6>

<p>04575-243725,<br/>
        dofssasvg@gmail.com,<br/>
     அறை எண். 239, 2வது தளம்,<br/>  மாவட்ட ஆட்சியர் அலுவலகம், சிவகங்கை– 630 562.</p>
    
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - தஞ்சாவூர்</h6>

<p>0436-2276511,<br />
      dofssatnj@gmail.com,<br />
சுகாதாரப் பணிகள் துணை இயக்குநர் அலுவலகம்,<br /> காந்திஜி சாலை, தஞ்சாவூர் - 613 001.</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - நீலகிரி</h6>

<p>0423-2450088 ,<br />
        dofssanlg@gmail.com, <br/>
        
       No. 1-B பிளாக்,  துணை ஆட்சியர் அலுவலகம், <br/>  ஊட்டி, – 643 005.</p>
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - தேனி</h6>

<p>04546-294434<br />
       dofssathn1@gmail.com, <br />
       துணை இயக்குனர் நலப்பணிகள்  திட்ட பணிகள் வளாகம்,<br />  மாவட்ட ஆட்சியர் அலுவலகம் பின்புறம்,<br /> தேனி 625 531.</p>
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - திருவள்ளூர்</h6>

<p>044-27662002,<br/>
        dofssatlr@gmail.com,<br/>
        2வது தளம்,  மாவட்ட ஆட்சியர் அலுவலகம்,<br/> திருவள்ளூர்.- 602 001</p>
       
      
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - திருவண்ணாமலை</h6>

<p>04175-233416,<br />
        dofssatvm@gmail.com,<br />
      செங்கம் ரோடு, பழைய அரசு மருத்துவமனை,<br /> முதல் தளம், திருவண்ணாமலை.- 606 603</p>
        
        
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - திருவாரூர்</h6>
<p>04366- 241034,<br/>
       dofssatvr1@gmail.com,<br/>
       துணை இயக்குனர் நலப்பணிகள் , எழில் நகர்,<br/> ஸ்டேட் பேங்க் எதிரில்,<br/>  திருவாரூர் – 613 701.</p>
       
    
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - திருநெல்வேலி</h6>

        <p>0462-2555070,<br/>
        dofssatnv@gmail.com,<br/>
    மருத்துவ கல்லுரி வளாகம், வருமான வரித்துறை அலுவலகம் எதிரில்,<br/>  திருசெந்தூர் ரோடு,  மகாராஜா நகர் போஸ்ட்,<br/>  திருநெல்வேலி, – 627 011.</p>
       
      
    </div>
    {/* <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - தென்காசி</h6>

<p>0462-2555071,<br />
        dofssatnv@gmail.com,<br/>
        மருத்துவ கல்லுரி வளாகம்,  வருமான வரித்துறை அலுவலகம் எதிரில், <br/> திருசெந்தூர் ரோடு,  மகாராஜா நகர் போஸ்ட்,<br/>  திருநெல்வேலி, – 627 011.</p>
        
      
    </div> */}
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - திருப்பூர்</h6>

<p>0421-2971190,<br/>
        dofssatpr@gmail.com,<br/>
    புதிய மாவட்ட ஆட்சியர் அலுவலகம், <br/> அறை எண்.. 429-431, IV வது தளம், <br/>திருப்பூர்,  641 604.</p>
  
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - தூத்துக்குடி</h6>
        <p>0431-2333330,<br/>
        dofssatut@gmail.com, <br/>
        இணை இயக்குனர் வேளாண்மைத்துறை அலுவகல வளாகம்,<br/> முதல் தல, மாவட்ட ஆட்சியர் அலுவலகம்,<br/>  கோரபள்ளம்,  தூத்துக்குடி- 628 101.</p>
      
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - திருச்சிராப்பள்ளி</h6>

        <p>0461-2340699,<br />
        dofssadivy1@gmail.com, <br />
      துணை இயக்குனர் நலப்பணிகள்  ஜமால் முஹம்மத் கல்லுரி அருகில்,<br />  திருச்சிராப்பள்ளி– 620 020.</p>
      
        
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - சென்னை</h6>

        <p>0416–2258249,<br/>
        dofssachn@gmail.com,<br/>
         மாவட்ட ஆட்சியர் அலுவலகம்,<br/>  சென்னை.– 600 001.</p>
      
        
    </div>
    {/* <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - ராணிபேட்டை</h6>

        <p>0416–2258250,<br/>
        dofssavel@gmail.com,<br/>
      B-பிளாக், 2வது தல, மாவட்ட ஆட்சியர் அலுவலகம்,<br/>  வேலூர்.– 632 001.</p>
       
        
    </div> */}
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - வேலூர்</h6>

        <p>0416–2258251,<br/>
        dofssavel@gmail.com,<br/>
        B-பிளாக், 2வது தளம், மாவட்ட ஆட்சியர் அலுவலகம், <br/> வேலூர்.– 632 001.</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - விழுப்புரம்</h6>

        <p>04146- 228546, <br/>
        dofssavpm@gmail.com,<br/>
       No. 38, 2வது தளம்,  மாவட்ட ஆட்சியர் அலுவலகம்,<br/> விழுப்புரம் – 605 602.</p>
      
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - கள்ளகுருச்சி</h6>

        <p>04146- 228547<br/>
        dofssakkr@gmail.com,<br/>
     No. 38, 2வது தளம், , மாவட்ட ஆட்சியர் அலுவலகம்,<br/> கள்ளகுருச்சி – 606 202.</p>
       
       
    </div>
    <div>
<h6 style={{textDecoration:'underline'}}>பொதுத் தகவல் அலுவலர் - விருதுநகர்</h6>

<p>04562-252255,<br/>
       dofssavnr1@gmail.com,<br/>
      அறை எண். 205, 2வது தளம்,  மாவட்ட ஆட்சியர் அலுவலகம்,<br/> விருதுநகர்  - 626 002.</p>
        
       
    </div></>

                )}
 </div>
 </div>
 <div className='directory_links'>
      <Link to="/lab-pio">{t("Click here for list of Lab Analyst PIO officer's details")}</Link><br/>
      <Link to="/pio">{t("Click here for list of Commissionerate PIO officer's details")}</Link><br/>
    
    </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUserDirectory: (data) => dispatch(userDirectory(data)),
});

const mapStateToProps = (state) => ({
  user:state.home.user
});
export default connect(mapStateToProps, mapDispatchToProps)(PIOComm);
