import React, { useEffect,useState } from "react";

const TextInput = (props) => {
  const [first, setfirst] = useState('')
  const [err, seterr] = useState(false)
  const errorMessage = props.error ? (
    <span className="error-message">{props.error}</span>
  ) : (
    <span className="error-message"></span>
  );
  const htmlTagsRegex = /<\/?[a-z][\s\S]*>/i;
  const validateInputValue=(value)=>{
    if (htmlTagsRegex.test(value)) {
      seterr(true)
      setfirst('')
    }
    else{
      seterr(false)
      setfirst(value)
    }
  }
  useEffect(()=>{
      validateInputValue(props?.value)
  },[props?.value])
  return (
    <>
    {props && props.type !== "number" ?
     <> <input
        className="form-control form-control-user"
        autoComplete="off"
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
        disabled={props.disabled}
        value={first == null?'':first}
        onChange={props.onChange}
      />
      {errorMessage} 
      {err?<span className="error-message">Input contains unsupported characters. Please only use letters, numbers.</span>:null}
     </>
      :
     <> <input
        className="form-control form-control-user"
        autoComplete="off"
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
        min="0"
        value={props.value === null ? "" : props.value}
        onChange={props.onChange}
      />
      {errorMessage}
      {props.value && props.value < 0  &&  <span className="error-message">Value should be greater than 0</span>}
     </>
  }
    </>
  );
};

export default TextInput;
