import React, { useState, useEffect } from 'react';
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

const ToggleSwitch = (props) => {

  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setChecked(props.value);
  }, [props])

  const handleChange = nextChecked => {
    setChecked(nextChecked);
    props.onValueChange();
  };

  return (
    <>
      <label htmlFor="material-switch">
        <span>{t(props.title)}</span>
        <Switch
          checked={checked}
          onChange={handleChange}
          onColor="#4cc264"
          onHandleColor="#4cc264"
          handleDiameter={25}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
          activeBoxShadow="0px 0px 1px 10px rgb(75 193 100)"
          height={18}
          width={48}
          className="react-switch"
          id="material-switch"
        />
      </label>
    </>
  )
}

export default ToggleSwitch;