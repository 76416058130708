import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const ERCUploadDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            console.log(recordData)
            recordData.date = moment(recordData.date).format("DD-MM-YYYY")
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">ERC Records Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{records.date}</td>
              </tr>
                <tr>
                  <th>Type</th>
                 <td className="text-left">{records.type}</td>
                </tr>
                {records.activity_photos && <tr>
                  <th>Name Of the Activity</th>
                   <td className="text-left">{records.activity_photos}</td>
                </tr>}
                {records.other_activity_photos && <tr>
                  <th>Name Of the Activity-Others</th>
                   <td className="text-left">{records.other_activity_photos}</td>
                </tr>}
                {records.photos_count && <tr>
                  <th>Photos Uploaded During The Month</th>
                   <td className="text-left">{records.photos_count}</td>
                </tr>}
                {records.activity_video && <tr>
                  <th>Name Of the Activity</th>
                   <td className="text-left">{records.activity_video}</td>
                </tr>}
                {records.other_activity_video && <tr>
                  <th>Name Of the Activity - Others</th>
                   <td className="text-left">{records.other_activity_video}</td>
                </tr>}
                {records.videos_count && <tr>
                  <th>Videos Uploaded During The Month </th>
                   <td className="text-left">{records.videos_count}</td>
                </tr>}
                {records.activity_banner && <tr>
                  <th>Name Of the Activity </th>
                   <td className="text-left">{records.activity_banner}</td>
                </tr>}
                {records.other_activity_banner && <tr>
                  <th>Name Of the Activity - Others </th>
                   <td className="text-left">{records.other_activity_banner}</td>
                </tr>}
                {records.banners_count && <tr>
                  <th>Number Of Banners/Pamplets Displayed During The Month </th>
                   <td className="text-left">{records.banners_count}</td>
                </tr>}
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(ERCUploadDetails);