import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const CommrCriminalDetails = (props) => {
    const { districts, traderTypes, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
      return wardName;
  };
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Comissionerate - Criminal case Details Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Case File Received On</th>
                 <td className="text-left">{moment(records.case_file_received_on).format("l")}</td>
                </tr>
                <tr>
                  <th>Sample Number</th>
                 <td className="text-left">{records.sample_number}</td>
                </tr>
              <tr>
                  <th>Date of Receipt of Recommedation by COFS </th>
                 <td className="text-left">{moment(records.file_approval_date).format("l")}</td>
                </tr>
               <tr>
                  <th>Date of File/Reply Submitted to APP Grade II On </th>
                 <td className="text-left">{moment(records.file_submitted).format("l")}</td>
                </tr>
                 <tr>
                  <th>Need Clarification</th>
                 <td className="text-left">{records.need_clarification}</td>
                </tr>
                 {records.file_submitted_to_grade_I && <tr>
                  <th>File/Reply submitted to APP Grade I On </th>
                 <td className="text-left">{moment(records.file_submitted_to_grade_I).format("l")}</td>
                </tr>}
               {records.clarification_sent_to_do_on &&  <tr>
                  <th>Calrification Request Sent to DO on  </th>
                 <td className="text-left">{moment(records.clarification_sent_to_do_on).format("l")}</td>
                </tr>}
               {records.clarification_received_from_do_on && <tr>
                  <th>Clarification Received From DO On </th>
                 <td className="text-left">{moment(records.clarification_received_from_do_on).format("l")}</td>
                </tr>}
                {records.received_from_commissioner && <tr>
                  <th>Case File Received From Comissioner On </th>
                 <td className="text-left">{moment(records.received_from_commissioner).format("l")}</td>
                </tr>}
                {records.case_file_recommended_to_do && <tr>
                  <th>Case File Recommended to DO for Filing Case On </th>
                 <td className="text-left">{moment(records.case_file_recommended_to_do).format("l")}</td>
                </tr>}
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(CommrCriminalDetails);