import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const ERCActivityDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            console.log(recordData)
            recordData.date = moment(recordData.date).format("DD-MM-YYYY")
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">ERC Activity Records Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{records.date}</td>
              </tr>
                <tr>
                  <th>Type</th>
                 <td className="text-left">{records.type}</td>
                </tr>
                {records.innovative_activity && <tr>
                  <th>Name Of the Activity</th>
                   <td className="text-left">{records.innovative_activity}</td>
                </tr>}
                {records.video_visibility && <tr>
                  <th>Number Of Visibilty In Public Places During the Month</th>
                   <td className="text-left">{records.video_visibility}</td>
                </tr>}
                {records.activity_count && <tr>
                  <th>Number Of E-Innovative Activities Done During The Month</th>
                   <td className="text-left">{records.activity_count}</td>
                </tr>}
                {records.media_impressions && <tr>
                  <th>Number Of Digital Media Impressions During The Month</th>
                   <td className="text-left">{records.media_impressions}</td>
                </tr>}
                {records.erc_visibility && <tr>
                  <th>Visibility Of Promoting ERC in Public Places During The Month</th>
                   <td className="text-left">{records.erc_visibility}</td>
                </tr>}
                
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(ERCActivityDetails);