import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { validateEmail } from '../utils/validateEmail';
import PasswordInput from '../components/inputs/PasswordInput';
import TextInput from '../components/inputs/TextInput';
import Header from '../components/Header';
import { Image } from 'react-bootstrap';
import { doRegister } from '../components/reducers/actions';
import { useTranslation } from 'react-i18next';
import bcrypt from 'bcryptjs'
import blue from '../assets/img/bluelogo.jpg'
import { Helmet } from "react-helmet"
var CryptoJS = require("crypto-js");

const Register = (props) => {

  const [values, setValues] = useState({
    'country_code': '+91',
    'name': '',
    'email': '',
    'password': '',
    'confirm_password': '',
    'phone_number': '',
  });
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const salt = bcrypt.genSaltSync(10)

  const { t } = useTranslation();

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ['name', 'email', 'phone_number', 'confirm_password', 'password', 'country_code'];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === '') {
        updatedErrors[key] = ' ';
      }
    });

    if (values.email && !validateEmail(values.email)) {
      updatedErrors.email = t('Invalid email address');
    }

    if (values.phone_number && (!values.phone_number || values.phone_number.length !== 10)) {
      updatedErrors.phone_number = t('Invalid phone number');
    }

    if (values.password && values.password.length < 6) {
      updatedErrors.password = t('Minimum 6 characters required');
    }

    if (values.password && values.confirm_password && values.confirm_password !== values.password) {
      updatedErrors.confirm_password = t('Confirm password not match with password');
    }

    if (values.country_code && values.country_code.length < 2) {
      updatedErrors.phone_number = t('Country code should at least be 2 characters');
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  }

  useEffect(() => {
    if (props.error) {
      setErrors(props.error);
    }
    validateValues(values);
    // eslint-disable-next-line
  }, [values, props]);


  const handleSubmitButton = async (e) => {
    e.preventDefault();
    var message = values.password;
    var key ='AAAAAAAAAAAAAAAA'
    key = CryptoJS.enc.Utf8.parse(key);
    var encrypted = CryptoJS.AES.encrypt(message, key, {mode: CryptoJS.mode.ECB});
    encrypted =encrypted.toString();
    // const enc = bcrypt.hashSync(values.password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
    let data = {...values}
    data.password=encrypted;
    data.confirm_password=encrypted;
    data.decrypt_check='Yes';
    const updatedErrors = validateValues(values);
    console.log(updatedErrors);
    if (Object.keys(updatedErrors).length === 0) {
      const resp = await props.onRegister(data);
      if (resp && resp.data === 'success') {
        props.history.push('/otp-verification', { 'phone_number': values.phone_number });
      }
      else {
        if (resp && resp.error) {
          setErrors(resp.error);
        }
      }
    }
  }

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    validateValues(updatedValues);
  }


  return (
    <>
       <Helmet>
     <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.1.1/crypto-js.min.js"></script>
      </Helmet>
      <Header activeMenu="Register" />
      <div className="container p-5">
        <div className="card o-hidden border-0 shadow-lg my-4">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <Image style={{ width: '100%', height: 'auto' }} src={blue}>
                </Image>
              </div>
              <div className="col-lg-6">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">{t('Create an Account!')}</h1>
                  </div>
                  <form onSubmit={handleSubmitButton} className="user">
                    <div className="form-group">
                      <TextInput
                        placeholder={t("Name")}
                        type='text'
                        name='name'
                        error={errors.name}
                        value={values.name}
                        onChange={(e) => handleTextChange('name', e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <TextInput
                        placeholder={t("Email Address")}
                        type='text'
                        name='email'
                        error={errors.email}
                        value={values.email}
                        onChange={(e) => handleTextChange('email', e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <TextInput
                        placeholder={t("Phone number")}
                        type='text'
                        name='phone_number'
                        error={errors.phone_number}
                        value={values.phone_number}
                        onChange={(e) => handleTextChange('phone_number', e.target.value)}
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 mb-3 mb-sm-0">
                        <PasswordInput
                          placeholder={t("Password")}
                          type='text'
                          name='password'
                          error={errors.password}
                          value={values.password}
                          onChange={(e) => handleTextChange('password', e.target.value)}
                        />
                      </div>
                      <div className="col-sm-6">
                        <PasswordInput
                          placeholder={t("Repeat Password")}
                          type='text'
                          name='confirm_password'
                          error={errors.confirm_password}
                          value={values.confirm_password}
                          onChange={(e) => handleTextChange('confirm_password', e.target.value)}
                        />
                      </div>
                    </div>
                    <button type="submit" disabled={disableSubmit} className="btn btn-primary-green btn-user btn-block">
                      {t('Register Account')}
                  </button>
                    <hr />
                    <div className="text-center">
                      <Link className="small" to="/login">{t('Already have an account? Login!')}</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


Register.propsType = {
  onRegister: PropTypes.func,
};


const mapDispatchToProps = dispatch => ({
  onRegister: (loginUser) => dispatch(doRegister(loginUser)),
});

const mapStateToProps = state => ({
  user: state.home.user,
  error: state.home.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);