import React, { useEffect, useState } from "react";
import { Checkbox as CheckboxInput } from "react-input-checkbox";

const Checkbox = (props) => {
  const errorMessage = props.error ? (
    <span className="error-message">{props.error}</span>
  ) : (
    <span className="error-message"></span>
  );

  const [checked, setChecked] = useState(props.value);

  useEffect(() => {
    setChecked(props.value);
  }, [props]);

  const handleToggle = () => {
    setChecked(!checked);
    props.onChange(!checked);
  };

  return (
    <>
      <CheckboxInput
        theme="bootstrap-checkbox"
        value={checked}
        disabled={props.disabled}
        onChange={handleToggle}
      >
        {props.placeholder}
      </CheckboxInput>
      {errorMessage}
    </>
  );
};

export default Checkbox;
