import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createMarketReports, editMarketReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const Type = [
    {id:"0", name:"Vegetable"},
    {id:"1", name:"Fish"},
    {id:"2", name:"Meat"}
]

const MarketCertificationReport = (props) => {
    const { districts, user,wards,wardsCategory, onCreateMarketReports,ongetDist, onEditMarketReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'place' : '',
        'address':'',
        'owner_name':'',
        'contact_number':'',
        'type_of_market':'Fruit/Vegetable Market',
        'date_valid':'',
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const ValidateValues = (values) => {
        var updatedErrors = {};
        var keys = [
        'district',
         'date', 
         'place',
         'owner_name',
         'date_valid',
         'contact_number',
         'address',
        'area_name',
        'area_code'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditMarketReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/market-list/`);
            };

        } else { 
            const data = { ...values}
            console.log(data)
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateMarketReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
       ValidateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Clean Fruits/Vegetables Certification Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date of certification *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_market"
                            >Type Of the Market *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Fruit/Vegetable Market`}
                                    type="text" isDisabled
                                    name="type_of_market"
                                    items={Type}
                                    action="dropdown"
                                    value="Fruit/Vegetable Market"
                                    error={errors.type_of_market}
                                    onChange={(text) =>handleTextChange("type_of_market", text)}
                                />
                            </div>
                        </div>
                      
                               <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name"
                            >Name Of the Market *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the market`}
                                    type="text"
                                    name="place"
                                    value={values.place}
                                    error={errors.place}
                                    onChange={(e) =>handleTextChange("place", e.target.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="contact_number"
                            >FBO Contact Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`FBO Contact Number`}
                                    type="number"
                                    name="contact_number"
                                    value={values.contact_number}
                                    error={errors.contact_number}
                                    onChange={(e) =>handleTextChange("contact_number", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="owner_name"
                            > Name of Owner/Partner *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Owner Name`}
                                    type="text"
                                    name="owner_name"
                                    value={values.owner_name}
                                    error={errors.owner_name}
                                    onChange={(e) =>handleTextChange("owner_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address"
                            >Address Of the Market *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the market`}
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    error={errors.address}
                                    onChange={(e) =>handleTextChange("address", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_valid"
                            >certification date_valid Upto *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Certification date_valid Upto`}
                                    name="date_valid"
                                    id="date_valid"
                                    value={values.date_valid }
                                    error={errors.date_valid}
                                    onChangeText={(text) => handleTextChange('date_valid', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="documents"
                            >Uplaod Documents (If any) *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="documents"
                                    error={errors.documents}
                                    onChange={(e) =>handleTextChange("documents", e.target.files[0])}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateMarketReports: (data) => dispatch(createMarketReports(data)),
    onEditMarketReports: (data, record_id) => dispatch(editMarketReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(MarketCertificationReport);