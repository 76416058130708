import moment from "moment/moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();



  return (
    <footer className="layout footer-wrapper">
      <div className="layout__container">
        <div className="layout__content footer-wrapper__content">
          <div className="footer">
            <div>
              <nav
                aria-labelledby="block-menu-footer-menu"
                id="block-menu-footer"
                className="footer__links"
              >
                <h2 className="visually-hidden" id="block-menu-footer-menu">
                  Mobile footer
                </h2>
                <ul className="menu">
                 
                  <li className="menu-item">
                    <a href="/contact-us">{t("Contact Us")}</a>
                  </li>
                  <li className="menu-item">
                    <a href="/blogs">{t("Blog")}</a>
                  </li>
                 
                  <li className="menu-item">
                    <a href="/privacy-policy">{t("Privacy Policy")}</a>
                  </li>
                
                  <li className="menu-item">
                    <a href="/screen-reader">{t("sr")}</a>
                  </li>
                  <li className="menu-item">
                    <a href="/faqs">{t("FAQ's")}</a>
                  </li>
                
                </ul>
              </nav>
            </div>
            <div className="social-media">
              <p className="visually-hidden">
                {t("Food Standards Agency on social media")}
              </p>
              <div className="social-media__list">
                <a
                  href="https://www.facebook.com/tnfoodsafety/"
                  className="social-media__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="visually-hidden">{t("Facebook")}</span>
                  <div className="svg" aria-hidden="true">
                    <svg
                      role="presentation"
                      focusable="false"
                      viewBox="0 0 40 40"
                    >
                      <path
                        d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20zm21.418 11.093V20.212h3.004l.398-3.75h-3.402l.005-1.877c0-.978.093-1.502 1.498-1.502h1.877v-3.75h-3.004c-3.608 0-4.878 1.82-4.878 4.878v2.252h-2.25v3.75h2.25v10.88h4.502z"
                        fill="#007C75"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </a>

                <a
                  href="https://twitter.com/tnfoodsafety"
                  className="social-media__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="visually-hidden">{t("Twitter")}</span>
                  <div className="svg" aria-hidden="true">
                    <svg
                      role="presentation"
                      focusable="false"
                      viewBox="0 0 40 40"
                    >
                      <path
                        d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20zm19.401-3.744l.042.692-.7-.084c-2.546-.325-4.77-1.427-6.659-3.277l-.923-.918-.238.678c-.503 1.511-.182 3.107.868 4.18.56.594.433.678-.532.325-.336-.113-.63-.198-.657-.155-.098.099.237 1.384.503 1.892.364.707 1.105 1.399 1.917 1.808l.685.325-.811.014c-.784 0-.812.014-.728.31.28.919 1.385 1.893 2.616 2.317l.868.297-.756.452a7.876 7.876 0 0 1-3.749 1.045c-.63.014-1.147.07-1.147.113 0 .141 1.707.932 2.7 1.243 2.98.918 6.52.522 9.177-1.046 1.889-1.115 3.777-3.333 4.659-5.48.475-1.143.951-3.234.951-4.236 0-.65.042-.735.825-1.512.462-.452.896-.946.98-1.087.14-.268.126-.268-.588-.028-1.189.423-1.357.367-.77-.269.434-.452.952-1.27.952-1.51 0-.043-.21.027-.448.154-.252.142-.811.354-1.23.48l-.756.24-.686-.465c-.377-.255-.91-.537-1.189-.622-.713-.198-1.805-.17-2.448.057-1.749.635-2.854 2.274-2.728 4.067z"
                        fill="#007C75"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </a>

                <a
                  href="https://www.linkedin.com/company/tn-foodsafety/"
                  className="social-media__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="visually-hidden">{t("Linkedin")}</span>
                  <div className="svg" aria-hidden="true">
                    <svg
                      role="presentation"
                      focusable="false"
                      viewBox="0 0 40 40"
                    >
                      <path
                        d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20zm14.134-3.435H9.6v13.618h4.533V16.565zm.298-4.213c-.03-1.335-.984-2.352-2.534-2.352-1.551 0-2.565 1.017-2.565 2.352 0 1.308.984 2.354 2.506 2.354h.029c1.58 0 2.564-1.046 2.564-2.354zM30.48 22.375c0-4.183-2.235-6.13-5.218-6.13-2.406 0-3.484 1.322-4.085 2.25v-1.93h-4.533c.06 1.278 0 13.618 0 13.618h4.533v-7.605c0-.407.029-.813.149-1.105.327-.813 1.073-1.654 2.325-1.654 1.641 0 2.297 1.248 2.297 3.078v7.286h4.532v-7.808z"
                        fill="#007C75"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCW8lNoyEQVIo2s-mXK6zPUg"
                  className="social-media__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="visually-hidden">{t("Youtube")}</span>
                  <div className="svg" aria-hidden="true">
                    <svg
                      role="presentation"
                      focusable="false"
                      viewBox="0 0 41 40"
                    >
                      <g fill="#007C75" fillRule="evenodd">
                        <path d="M0 20C0 8.954 8.967 0 20.029 0s20.029 8.954 20.029 20-8.967 20-20.03 20C8.969 40 0 31.046 0 20zm30.453-4.026s-.208-1.502-.848-2.163c-.811-.87-1.72-.873-2.137-.924-2.985-.22-7.463-.22-7.463-.22h-.01s-4.478 0-7.463.22c-.417.05-1.326.055-2.138.924-.64.661-.847 2.163-.847 2.163s-.214 1.765-.214 3.529v1.654c0 1.764.214 3.528.214 3.528s.208 1.502.847 2.164c.812.868 1.878.841 2.353.932C14.453 27.948 20 28 20 28s4.483-.007 7.468-.228c.417-.05 1.326-.055 2.137-.923.64-.662.848-2.164.848-2.164s.214-1.764.214-3.528v-1.654c0-1.764-.214-3.529-.214-3.529z"></path>
                        <path d="M18 23.459v-6.126l5.764 3.074z"></path>
                      </g>
                    </svg>
                  </div>
                </a>

                <a
                  href="https://www.instagram.com/tnfoodsafety/"
                  className="social-media__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="visually-hidden">{t("Instagram")}</span>
                  <div className="svg" aria-hidden="true">
                    <svg
                      role="presentation"
                      focusable="false"
                      viewBox="0 0 40 40"
                    >
                      <path
                        d="M20 0a20 20 0 1 0 20 20A20 20 0 0 0 20 0zm8.95 23.71a6.61 6.61 0 0 1-.42 2.18 4.6 4.6 0 0 1-2.63 2.63 6.6 6.6 0 0 1-2.18.42c-1 0-1.27.05-3.71.05s-2.75 0-3.71-.05a6.6 6.6 0 0 1-2.18-.42 4.6 4.6 0 0 1-2.63-2.63 6.61 6.61 0 0 1-.42-2.18c0-1-.05-1.27-.05-3.71s0-2.75.05-3.71a6.61 6.61 0 0 1 .42-2.18 4.6 4.6 0 0 1 2.63-2.63 6.61 6.61 0 0 1 2.18-.42c1 0 1.27-.05 3.71-.05s2.75 0 3.71.05a6.61 6.61 0 0 1 2.18.42 4.6 4.6 0 0 1 2.63 2.63 6.61 6.61 0 0 1 .42 2.18c0 1 .05 1.27.05 3.71s0 2.75-.05 3.71zM20 15.38A4.62 4.62 0 1 0 24.62 20 4.62 4.62 0 0 0 20 15.38zM20 23a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm5.88-7.8a1.08 1.08 0 1 1-1.08-1.08 1.08 1.08 0 0 1 1.08 1.08z"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </a>
              </div>
             <p style={{fontSize:"12px"}}>Copyright@{moment().year()}Tamil Nadu Food Safety and Drug Administration Department</p> 
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
