import React from "react";
import { FaPen } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import UploadImage from "../inputs/UploadImage/UploadImage";

const UploadImageInput = (props) => {
  console.log(props.photo)
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleChangeImg = async (img) => {
    const imageBase64 = await toBase64(img);
    props.onChangeText(imageBase64);
  };

  const handleRemoveImage = (value) => {
    props.handleRemoveImages(value);
  };

  return (
    <UploadImage
      size={props.size}
      name={props.name}
      currentImage={props.photo}
      onChange={handleChangeImg}
      fileType={"image/*"}
    >
      {props.photo || (props.photo && props.enableDelete) ? (
        <div className="uploadimage-icon" style={{ zIndex: 1000 }}>
          <button
            type="button"
            onClick={() => handleRemoveImage(props.value)}
            className="btn btn-danger btn-circle btn-sm"
          >
            <FaRegTrashAlt />
          </button>
        </div>
      ) : (
        <div className="uploadimage-icon">
          <button type="button" className="btn btn-success btn-circle btn-sm">
            <FaPen />
          </button>
        </div>
      )}
    </UploadImage>
  );
};

export default UploadImageInput;
