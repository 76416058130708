import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import {
  updateLangSuccess,
  getBlogs,
  getBanners,
  getFaqCategory,
  logout,
  getFaqs,
  getNotifications,
  getDoContacts,
  getBanned,
  userDirectory,
  testingLab,
} from "../components/reducers/actions";
import { getLangCode } from "../utils/config";
import dummpyImage from "../assets/img/dummy.jpeg";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../assets/img/logo.png";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const getLogout = async () => {
    const resp = await props.onLogout();
    if (resp && resp.response) {
      localStorage.clear();
      if (user && user.user_types && user.user_types.length > 0) {
        window.location.href = "/login/";
      } else {
        window.location.href = "/login/";
      }
    }
  };

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.substr(1);
  };

  let menus = [
    {
      label: t("Home"),
      name: "Home",
      url: "/",
    },
    {
      label: t("About"),
      name: "About",
      url: "/",
    },
    {
      label: t("Services"),
      name: "Services",
      url: "/",
    },
    {
      label: t("BP"),
      name: "Banned Products",
      url: "/banned-products",
    },
    {
      label: t("Resources"),
      name: "Resources",
      url: "/",
    },
    {
      label: t("Tenders"),
      name: "Tenders",
      url: "/",
    },
    {
      label: t("RTI"),
      name: "RTI",
      url: "/",
    },
    {
      label: t("Important Links"),
      name: "Important Links",
      url: "/",
    },
    {
      label: t("IEC"),
      name: "IEC",
      url: "/",
    },
    // {
    //   label: t("Blogs"),
    //   name: "Blogs",
    //   url: "/blogs",
    // },
    // {
    //   label: t("Faqs"),
    //   name: "Faqs",
    //   url: "/faqs",
    // },
    // {
    //   label: t("Contact us"),
    //   name: "Contact us",
    //   url: "/contact-us",
    // },
    // {
    //   label: t("Contact Details"),
    //   name: "Contact Details",
    //   url: "/contact-details",
    // },
    {
      label: t("Complaints"),
      name: "Complaints",
      url: "/app/complaints",
    },
    {
      label: t("Login"),
      name: "Login",
      url: "/login",
    },
    {
      label: t("Raise your complaint"),
      name: "Register",
      url: "/register",
    },
  ];

  const subMenu = [];
  const loggedInRoutes = ["Complaints"];
  const nonLoggedInRoutes = ["Login", "Register"];
  const [updatedRoutes, setUpdatedRoutes] = useState([]);
  const {
    user,
    onUpdateLang,
    onGetBlogs,
    onGetFaqs,
    onGetBanners,
    onGetFaqCategory,
    onGetDoContacts,
    onGetNotifications,
    ongetBanned,
    onUserDirectory,
    ongetLabs,
  } = props;
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);
  const [ban, setBan]=useState([]);
  const wrapperRef = useRef(null);
  const navRef = useRef();
  navRef.current = navBackground;

  const getBn=async()=>{
    const resp =  await ongetBanned()
    if(resp.response){
      setBan(resp.response)
    }
   }
 useEffect(()=>{
getBn()
  },[])
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // document.addEventListener("click", handleClickOutside, false);
    // return () => {
    //   document.removeEventListener("click", handleClickOutside, false);
    // };
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setMobileNav(false);
    }
  };

  useEffect(() => {
    const currentRoutes = menus.filter((route) => {
      if (
        user &&
        Object.keys(user).length > 1 &&
        nonLoggedInRoutes.indexOf(route.name) > -1
      ) {
        return false;
      } else if (
        user &&
        Object.keys(user).length === 1 &&
        loggedInRoutes.indexOf(route.name) > -1
      ) {
        return false;
      } else if (!user && loggedInRoutes.indexOf(route.name) > -1) {
        return false;
      }
      return true;
    });
    setUpdatedRoutes(currentRoutes);
    // eslint-disable-next-line
  }, [user, i18n.language]);

  const getAssets = () => {
    onGetBanners();
    onGetFaqs();
    onGetBlogs();
    onGetFaqCategory();
    onGetNotifications();
    onUserDirectory();
    ongetLabs();
    getBn();
    onGetDoContacts({
      do_contacts: true,
    });
  };

  const handleChange = (val) => {
    console.log(val)
    i18n.changeLanguage(getLangCode(val));
    onUpdateLang(val);
    getAssets();
  };

  return (
    <>
      <div
        className={
          navBackground || props.showHeader
            ? "layout topbar-wrapper scrolled"
            : "layout topbar-wrapper"
        }
      >
        <div className="layout__container">
          <div className="layout__content">
            <div className="header__topbar">
                <div className= { !navBackground ?"header__region pull_center" : "no_display"}>  <img src={logo}  style={{height:100, width:105}} alt="" /><br/></div>
                    <div className={!navBackground ?"top_text":'no_display'}>
                    {t("TNFSD")} <br/>
                    <p className={!navBackground ?'text_p':'no_display'}>{t("TFSD")}</p>
                    </div>
                    <div className={!navBackground ?"pull_center_second ":'no_display'}> <img src="/images/tn_govt_logo2.png" id="logo"   />
                    </div>
                    <div className={!navBackground ?"header__region":'no_display'}>
                    <a href='/screen-reader' style={{marginRight:'140px'}}>{t('sr')}</a>
                    
                <div
                  className="language-switcher-language-url"
                  id="block-languageswitcher"
                  role="navigation"
                  aria-label="Language selection"
                >
                  <ul className="links">
                    <li onClick={() => handleChange("english")}>
                      <button
                        className={
                          i18n.language === "en"
                            ? "language-link is-active"
                            : "language-link"
                        }
                      >
                        English
                      </button>
                    </li>
                    <li onClick={() => handleChange("tamil")}>
                      <button
                        className={
                          i18n.language === "ta"
                            ? "language-link is-active"
                            : "language-link"
                        }
                      >
                        தமிழ்
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        ref={wrapperRef}
        onClick={() => setMobileNav(!mobileNav)}
        type="button"
        id="mobile-nav-toggle"
      >
        <i className={mobileNav ? "fa fa-times" : "fa fa-bars"}></i>
      </button>
      <header
        id="header"
        className={navBackground || props.showHeader ? "header-scrolled" : ""}
      >
        <div className="container-fluid main__nav__container">
          <div id="logo" className="pull-left">
            
            {navBackground ? <p className="blog-title">{props.title}</p> : null}
          </div>
          {!props.hideMenu ? (
            <nav id="nav-menu-container">
              <ul className="nav-menu">
                {updatedRoutes
                  ? updatedRoutes.map((menu, idx) => {
                      return (
                        <li
                          key={idx}
                          className={
                            menu.name === props.activeMenu
                              ? "menu-active"
                              : null
                          }
                        >
                          {menu.name === "Complaints" ? (
                            <a rel="noopener noreferrer" href={menu.url}>
                              {menu.label}
                            </a>
                          ) : (
                            <>
                              {menu.name === "Services" ? (
                                <li className="dropdown">
                                  <a
                                    href="#"
                                    class="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                  >
                                    {" "}
                                    <span className="nav-label">
                                      {t("Services")}
                                    </span>{" "}
                                    <span className="caret"></span>
                                  </a>
                                  <ul className="dropdown-menu">
                                  <li>
                                      <a
                                        href="https://foscos.fssai.gov.in/"
                                        target="_blank"
                                      >
                                        {t("License/Registration")}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="https://foscos.fssai.gov.in/user-manual"
                                        target="_blank"
                                      >
                                        {t("License/Registration Guidelines")}
                                      </a>
                                    </li>
                                    
                                    <li>
                                      <Link to="/redressal">
                                        {t("Greievance Redressal")}
                                      </Link>
                                    </li>
                                 
                                    <li>
                                      <Link to="/testing-lab">
                                        {t("Testing Labs")}
                                      </Link>
                                    </li>
                                    <li>
                                      <a
                                        href="https://fostac.fssai.gov.in/index"
                                        target="_blank"
                                      >
                                        FOSTAC
                                      </a>
                                    </li>
                                    
                                  </ul>
                                </li>
                              ) : (
                                <>
                                  {menu.name === "About" ? (
                                    <li class="dropdown ">
                                      <a
                                        href="#"
                                        class="dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                      >
                                        {" "}
                                        <span className="nav-label">
                                          {t("About")}
                                        </span>{" "}
                                        <span className="caret"></span>
                                      </a>
                                      <ul className="dropdown-menu">
                                        <li>
                                          <Link to="/about-department">
                                            {t("ATD")}
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/org-chart">
                                            {t("Organisation Structure")}
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/who">{t("ww")}</Link>
                                        </li>
                                        <li>
                                          <Link to="/user-directory">
                                            {t("Directory")}
                                          </Link>
                                        </li>
                                      </ul>
                                    </li>
                                  ) : (
                                    <>
                                      {menu.name === "Resources" ? (
                                        <li class="dropdown">
                                          <a
                                            href="#"
                                            class="dropdown-toggle"
                                            data-toggle="dropdown"
                                            role="button"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                          >
                                            {" "}
                                            <span className="nav-label">
                                              {t("Resources")}
                                            </span>{" "}
                                            <span className="caret"></span>
                                          </a>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <a
                                                href="https://www.fssai.gov.in/cms/food-safety-and-standards-act-2006.php"
                                                target="_blank"
                                              >
                                                {t("Act")}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="https://www.fssai.gov.in/cms/food-safety-and-standards-rules--2011.php"
                                                target="_blank"
                                              >
                                                {t("Rules")}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="https://www.fssai.gov.in/cms/food-safety-and-standards-regulations.php"
                                                target="_blank"
                                              >
                                                {t("Regulations")}
                                              </a>
                                            </li>
                                            {/* <li>
                                              <a href="https://www.fssai.gov.in/cms/guidance-notes.php" target="_blank">
                                                {t("FSSAI Guidelines")}
                                              </a>
                                            </li> */}
                                            <li>
                                              <a href="https://www.fssai.gov.in/cms/guidance-notes.php" target="_blank">
                                                {t("Notifications")}
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="https://fssai.gov.in/cms/guidance-document.php"
                                                target="_blank"
                                              >
                                                {t("FSSAI Guidance Notes")}
                                              </a>
                                            </li>
                                            <li className="dropdown-submenu">
                                              <a
                                                href="#"
                                                class="dropdown-toggle"
                                                data-toggle="dropdown"
                                                role="button"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                {" "}
                                                <span
                                                  className="nav-label"
                                                  style={{ padding: "3px" }}
                                                >
                                                  {t("Government Orders")}
                                                </span>
                                                <span className="caret"></span>
                                              </a>
                                              <ul className="dropdown-menu bg-white p-2">
                                                <li>
                                                  <Link to="/government-orders">
                                                    {t("State Government")}
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link to="/central-orders">
                                                    {t("Central Government")}
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li>
                                              <Link to="/circulars">
                                                {t("COFS")}
                                              </Link>
                                            </li>
                                            <li className="dropdown-submenu ">
                                              <a
                                                href="#"
                                                class="dropdown-toggle"
                                                data-toggle="dropdown"
                                                role="button"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                {" "}
                                                <span
                                                  className="nav-label"
                                                  style={{ padding: "3px" }}
                                                >
                                                  {t("CO")}
                                                </span>
                                                <span className="caret"></span>
                                              </a>
                                              <ul className="dropdown-menu bg-white p-2">
                                                <li>
                                                  <Link to="/supreme-court-orders">
                                                    {t("SC")}
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link to="/high-court-orders">
                                                    {t("HC")}
                                                  </Link>
                                                </li>
                                                <li>
                                                <Link to="/tribunal-orders">
                                                    {t("ATO")}
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link to="/adjudicating-orders">
                                                    {t("ADO")}
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link to="/court-orders">
                                                    {t("Others")}
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                            <li className="dropdown-submenu ">
                                              <a
                                                href="#"
                                                class="dropdown-toggle"
                                                data-toggle="dropdown"
                                                role="button"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                              >
                                                {" "}
                                                <span
                                                  className="nav-label"
                                                  style={{ padding: "3px" }}
                                                >
                                                  {t("Forms")}
                                                </span>
                                                <span className="caret"></span>
                                              </a>
                                              <ul className="dropdown-menu bg-white p-2">
                                               
                                                <li>
                                                  <Link to="/fbo-forms">
                                                    {t("For FBO")}
                                                  </Link>
                                                </li>
                                               { user && user?.user_types?.includes("do")||user?.user_types?.includes("fso") ||user?.user_types?.includes("ho")? <li>
                                                  <Link to="/forms">
                                                    {t("For Department")}
                                                  </Link> 
                                                </li>:null}
                                              </ul>
                                            </li>
                                            <li>
                                              <a href="/faqs">{t("FAQ'S")}</a>
                                            </li>
                                          </ul>
                                        </li>
                                      ) : (
                                        <>
                                          {menu.name === "Tenders" ? (
                                            <li class="dropdown">
                                              <a
                                                href="#"
                                                class="dropdown-toggle"
                                                data-toggle="dropdown"
                                                role="button"
                                                aria-haspopup="true"
                                                aria-expanded="true"
                                              >
                                                {" "}
                                                <span className="nav-label">
                                                  {t("Tenders")}
                                                </span>{" "}
                                                <span className="care"></span>
                                              </a>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <a
                                                    href="https://cms.tn.gov.in/sites/default/files/acts/TNTIT_act_Rules_Amended_upto_June_2018.pdf"
                                                    target="_blank"
                                                  >
                                                    {t("TTA")}
                                                  </a>
                                                </li>
                                                {/* <li>
                                                  <a
                                                    href="https://www.india.gov.in/tamil-nadu-tender-transparency-act-and-rules"
                                                    target="_blank"
                                                  >
                                                    {t("TTA")}
                                                  </a>
                                                </li> */}
                                                <li>
                                                  <Link to="/tenders">
                                                    {t("Tenders/Quatations")}
                                                  </Link>
                                                </li>
                                              </ul>
                                            </li>
                                          ) : (
                                            <>
                                              {menu.name === "RTI" ? (
                                                <li class="dropdown">
                                                  <a
                                                    href="#"
                                                    class="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="true"
                                                  >
                                                    {" "}
                                                    <span className="nav-label">
                                                      {menu.name}
                                                    </span>{" "}
                                                    <span className="caret"></span>
                                                  </a>
                                                  <ul className="dropdown-menu">
                                                    <li>
                                                      <Link to="/pio">
                                                        {t("List of PIO")}
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <a
                                                        href="http://www.tnsic.gov.in/"
                                                        target="_blank"
                                                      >
                                                        {t(
                                                          "RTI Guidelines"
                                                         )}
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        href="http://www.tnsic.gov.in/petitions/otp-login.php"
                                                        target="_blank"
                                                      >
                                                        {t("Second Appeal")}
                                                      </a>
                                                    </li>
                                                   
                                                  </ul>
                                                </li>
                                              ) : (
                                                <>
                                                  {menu.name ===
                                                  "Important Links" ? (
                                                    <li class="dropdown">
                                                      <a
                                                        href="#"
                                                        class="dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        role="button"
                                                        aria-haspopup="true"
                                                        aria-expanded="true"
                                                      >
                                                        {" "}
                                                        <span className="nav-label">
                                                          {t("Important Links")}
                                                        </span>{" "}
                                                        <span className="caret"></span>
                                                      </a>
                                                      <ul className="dropdown-menu">
                                                        <div style={{ overflowY:'scroll', maxHeight:450 }} >
                                                        <li>
                                                          <a
                                                            href="https://www.india.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t("Government Of India")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t("Government Of TamilNadu")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://tnhealth.tn.gov.in/tngovin/healthdirectorates.php"
                                                            target="_blank"
                                                          >
                                                            {t("Health Directorates")}
                                                          </a>
                                                        </li>
                                                        
                                                        <li>
                                                          <a
                                                            href="https://main.mohfw.gov.in/"
                                                            target="_blank"
                                                          >
                                                           {t("Ministry of Health and Family Welfare")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.fssai.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t("Food Safety and Standards Authority of India")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="http://www.mrb.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                          {t("Medical Services Recruitment Board")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="http://www.nhm.tn.gov.in/en"
                                                            target="_blank"
                                                          >
                                                          {t("National Health Mission Tamil Nadu")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="http://www.drugscontrol.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Tamilnadu Drug Control"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://labour.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Goverment of Tamil Nadu Labour Department"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.bis.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "bis"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.tnsocialwelfare.tn.gov.in/swd/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Social Welfare Department"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://icds.tn.gov.in/icdstn/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "ICDS"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.tncsc.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Tncsc"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://tnpcb.gov.in/PPFTN/events.php"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "PPTN"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.dvac.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Vigilance and Anti curruption Department"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://eofficehod1.tn.gov.in/SSOComponent/gate.php"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "eoffice"
                                                            )}
                                                          </a>
                                                        </li>
                                                        </div>
                                                      </ul>
                                                    </li>
                                                  ) : (
                                                    <>
                                                      {menu.name === "IEC" ? (
                                                        <li class="dropdown">
                                                          <a
                                                            href="#"
                                                            class="dropdown-toggle"
                                                            data-toggle="dropdown"
                                                            role="button"
                                                            aria-haspopup="true"
                                                            aria-expanded="true"
                                                          >
                                                            {" "}
                                                            <span className="nav-label">
                                                              {menu.name}
                                                            </span>{" "}
                                                            <span className="caret"></span>
                                                          </a>
                                                          <ul className="dropdown-menu">
                                                            <li className="dropdown-submenu">
                                                              <a
                                                                href="#"
                                                                class="dropdown-toggle"
                                                                data-toggle="dropdown"
                                                                role="button"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                {" "}
                                                                <span
                                                                  className="nav-label"
                                                                  style={{
                                                                    padding:
                                                                      "3px",
                                                                  }}
                                                                >
                                                                  {t(
                                                                    "Basics of Food Safety"
                                                                  )}
                                                                </span>
                                                                <span className="caret"></span>
                                                              </a>
                                                              <ul className="dropdown-menu bg-white p-2">
                                                                <li>
                                                                  <a href="https://foodsafety.tn.gov.in/blogs/food-packets-labels-requirements">
                                                                    {t(
                                                                      "Labeling"
                                                                    )}
                                                                  </a>
                                                                </li>
                                                                {/* <li>
                                                                  <a href="https://foodsafety.tn.gov.in/blogs/storages-and-warehouses">
                                                                    {t(
                                                                      "Whare house and storages"
                                                                    )}
                                                                  </a>
                                                                </li> */}
                                                                <li>
                                                                  <a href="https://foodsafety.tn.gov.in/blogs/detection-of-adulteration-and-rapid-test-dart">
                                                                    {t(
                                                                      "Detection od Adultrationwith Rapid Test"
                                                                    )}
                                                                  </a>
                                                                </li>
                                                              </ul>
                                                            </li>
                                                            <li>
                                                              <Link to="/blogs">
                                                                {t("Blogs")}
                                                              </Link>
                                                            </li>
                                                            <li>
                                                                <Link to='/books'>
                                                                {t("Books")}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://eatrightindia.gov.in/"
                                                                target="_blank"
                                                              >
                                                              {t("Blissful Hygienic Offering to God(BHOG)")}
                                                              </a>
                                                            </li>
                                                            {/* <li>
                                                              <a
                                                                href="#"
                                                                target="_blank"
                                                              >
                                                                {t("Cure ")}
                                                              </a>
                                                            </li> */}
                                                            <li>
                                                              <a
                                                                href="https://fssai.gov.in/cms/myth-buster.php"
                                                                target="_blank"
                                                              >
                                                                {t(
                                                                  "Myth Buster"
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <Link to="/gallery">
                                                                {t("Gallery")}
                                                              </Link>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://fssai.gov.in/ruco/"
                                                                target="_blank"
                                                              >
                                                                {t("Repurpose Used Cooking Oil(RUCO)")}
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://jaivikbharat.fssai.gov.in/"
                                                                target="_blank"
                                                              >
                                                                {t(
                                                                  "Organic Good"
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <Link
                                                                to="/donate-food"
                                                              >
                                                                {t(
                                                                  "Save food Share food"
                                                                )}
                                                              </Link>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://fortification.fssai.gov.in/"
                                                                target="_blank"
                                                              >
                                                                {t(
                                                                  'FF'
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <Link
                                                              to="/kids-corner"
                                                              >
                                                                {t(
                                                                  "Kids Corner"
                                                                )}
                                                              </Link>
                                                            </li>
                                                          </ul>
                                                        </li>
                                                      ) : 
                                          
                                                          (
                                                            
                                                              <Link to={menu.url}>
                                                                {menu.label}
                                                              </Link>
                                                            
                                                          )
                                                            } </>
                                                      )
                                                      }
                                                    </>
                                               
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </li>
                      );
                    })
                  : null}

                {user && Object.keys(user).length > 1 ? (
                  <li className="nav-item no-arrow header">
                    <Dropdown className="nav-link ">
                      <Dropdown.Toggle className="profile-btn" variant="link">
                        {/* <span className="mx-1 d-none d-lg-inline small">
                          {props && props.user && props.user.name
                            ? capitalize(props.user.name)
                            : ""}
                        </span> */}
                        <img
                          alt="logo"
                          className="mx-1 img-profile rounded-circle"
                          src={
                            props && props.user && props.user.profile_picture
                              ? props.user.profile_picture
                              : dummpyImage
                          }
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item tag="a" href="/app/profile/">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          {t("Profile")}
                        </Dropdown.Item>
                        {user &&
                          user.user_types &&
                          (user.user_types.includes("do") ||
                            user.user_types.includes("ho")) && (
                            <Dropdown.Item tag="a" href="/app/dashboard/">
                              <i className="fa fa-rocket fa-sm fa-fw mr-2 text-gray-400"></i>
                              {t("Gotd")}
                            </Dropdown.Item>
                          )}
                        <Dropdown.Item onClick={getLogout}>
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          {t("Logout")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                ) : null}
              </ul>
            </nav>
          ) : null}
          <div id="logo2" style={{ float: "right" }}>
            {/* <Link to="/">
              <img src="/images/tn_govt_logo2.png" alt="" title="" />
            </Link> */}
            {navBackground ? <p className="blog-title">{props.title}</p> : null}
          </div>
        </div>
      </header>
      <div
        id="mobile-body-overly"
        style={{ display: mobileNav ? "block" : "none" }}
      ></div>
      <nav id="mobile-nav" style={{ left: mobileNav ? "0" : null }}>
        <ul style={{ touchAction: "pan-y" }} className="nav-menu">
          {updatedRoutes
            ? updatedRoutes.map((menu, idx) => {
                return (
                  <li
                    key={idx}
                    className={
                      menu.name === props.activeMenu ? "menu-active" : null
                    }
                  >
                    {menu.name === "Complaints" ? (
                      <a rel="noopener noreferrer" href={menu.url}>
                        {menu.label}
                      </a>
                    ) : (
                      <>
                        {menu.name === "Services" ? (
                          <li className="dropdown">
                            <a
                              href="#"
                              class="dropdown-toggle"
                              data-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="true"
                            >
                              {" "}
                              <span className="nav-label">
                                {t("Services")}
                              </span>{" "}
                              <span className="caret"></span>
                            </a>
                            <ul className="dropdown-menu bg-white p-2">
                            <li>
                                <a
                                  href="https://foscos.fssai.gov.in/"
                                  target="_blank"
                                >
                                  {t("License/Registration")}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://foscos.fssai.gov.in/user-manual"
                                  target="_blank"
                                >
                                  {t("License/Registration Guidelines")}
                                </a>
                              </li>
                             
                              <li>
                                <Link to="/redressal">
                                  {t("Greievance Redressal")}
                                </Link>
                              </li>
                              {/* <li className="dropdown-submenu">
                                         <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <span className="nav-label">Public Griverance Redreassal sysytem</span><span className="caret"></span></a>
                                         <ul className="dropdown-menu">
                                             <li><a href="#">Mobile</a></li>
                                             <li><a href="#">Raise Your Complaint</a></li>
                                             <li><a href="#">Email</a></li>
                                             <li><a href="#">Whatsapp</a></li>
                                         </ul>
                                     </li> */}
                              <li>
                                <Link to="/testing-lab">{t("Testing Labs")}</Link>
                              </li>
                              <li>
                                <a
                                  href="https://fostac.fssai.gov.in/index"
                                  target="_blank"
                                >
                                  FOSTAC
                                </a>
                              </li>
                            </ul>
                          </li>
                        ) : (
                          <>
                            {menu.name === "About" ? (
                              <li class="dropdown">
                                <a
                                  href="#"
                                  class="dropdown-toggle"
                                  data-toggle="dropdown"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  {" "}
                                  <span className="nav-label">
                                    {t("About")}
                                  </span>{" "}
                                  <span className="caret"></span>
                                </a>
                                <ul className="dropdown-menu bg-white p-2">
                                  <li>
                                    <Link to="/about-department">{t("ATD")}</Link>
                                  </li>
                                  <li>
                                    <Link to="org-chart">
                                      {t("Organisation Structure")}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/who">{t("ww")}</Link>
                                  </li>
                                  <li>
                                    <Link to="/user-directory">
                                      {t("Directory")}
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            ) : (
                              <>
                                {menu.name === "Resources" ? (
                                  <li class="dropdown">
                                    <a
                                      href="#"
                                      class="dropdown-toggle"
                                      data-toggle="dropdown"
                                      role="button"
                                      aria-haspopup="true"
                                      aria-expanded="true"
                                    >
                                      {" "}
                                      <span className="nav-label">
                                        {t("Resources")}
                                      </span>{" "}
                                      <span className="caret"></span>
                                    </a>
                                    <ul className="dropdown-menu bg-white p-2">
                                      <li>
                                        <a
                                          href="https://www.fssai.gov.in/cms/food-safety-and-standards-act-2006.php"
                                          target="_blank"
                                        >
                                          {t("Act")}
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="https://www.fssai.gov.in/cms/food-safety-and-standards-rules--2011.php"
                                          target="_blank"
                                        >
                                          {t("Rules")}
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="https://www.fssai.gov.in/cms/food-safety-and-standards-regulations.php"
                                          target="_blank"
                                        >
                                          {t("Regulations")}
                                        </a>
                                      </li>
                                      {/* <li>
                                        <a href="https://www.fssai.gov.in/cms/guidance-notes.php">
                                          {t("FSSAI Guidelines")}
                                        </a>
                                      </li> */}
                                      <li>
                                        <a href="https://www.fssai.gov.in/cms/guidance-notes.php">
                                          {t("Notifications")}
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href="https://fssai.gov.in/cms/guidance-document.php"
                                          target="_blank"
                                        >
                                          {t("FSSAI Guidance Notes")}
                                        </a>
                                      </li>
                                      <li className="dropdown-submenu">
                                        <a
                                          href="#"
                                          class="dropdown-toggle"
                                          data-toggle="dropdown"
                                          role="button"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {" "}
                                          <span
                                            className="nav-label"
                                            style={{ padding: "3px" }}
                                          >
                                            {t("Government Orders")}
                                          </span>
                                          <span className="caret"></span>
                                        </a>
                                        <ul className="dropdown-menu bg-white p-2">
                                          <li>
                                            <Link to="/government-orders">
                                              {t("State Government")}
                                            </Link>
                                          </li>
                                          <li>
                                            <Link to="/central-orders">
                                              {t("Central Government")}
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <Link to="/circulars">{t("COFS")}</Link>
                                      </li>
                                      <li className="dropdown-submenu">
                                        <a
                                          href="#"
                                          class="dropdown-toggle"
                                          data-toggle="dropdown"
                                          role="button"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {" "}
                                          <span
                                            className="nav-label"
                                            style={{ padding: "3px" }}
                                          >
                                            {t("CO")}
                                          </span>
                                          <span className="caret"></span>
                                        </a>
                                        <ul className="dropdown-menu bg-white p-2">
                                          <li>
                                            <Link to="/supreme-court-orders">
                                              {t("SC")}
                                            </Link>
                                          </li>
                                          <li>
                                            <Link to="/high-court-orders">
                                              {t("HC")}
                                            </Link>
                                          </li>
                                          <li>
                                                  <Link to="/tribunal-orders">
                                                    {t("ATO")}
                                                  </Link>
                                                </li>
                                                <li>
                                                  <Link to="/adjudicating-orders">
                                                    {t("ADO")}
                                                  </Link>
                                                </li>
                                          <li>
                                            <Link to="/court-orders">
                                              {t("Others")}
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                      <li className="dropdown-submenu">
                                        <a
                                          href="#"
                                          class="dropdown-toggle"
                                          data-toggle="dropdown"
                                          role="button"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          {" "}
                                          <span
                                            className="nav-label"
                                            style={{ padding: "3px" }}
                                          >
                                            {t("Forms")}
                                          </span>
                                          <span className="caret"></span>
                                        </a>
                                        <ul className="dropdown-menu bg-white p-2">
                                        <li>
                                            <Link to="/fbo-forms">
                                              {t("For FBO")}
                                            </Link>
                                          </li>
                                          <li>
                                            <Link to="/forms">
                                              {t("For Department")}
                                            </Link>
                                          </li>
                                         
                                        </ul>
                                      </li>
                                      {/* <li>
                                              <a href="/forms">{t("Forms")}</a>
                                            </li> */}
                                    </ul>
                                  </li>
                                ) : (
                                  <>
                                    {menu.name === "Tenders" ? (
                                      <li class="dropdown">
                                        <a
                                          href="#"
                                          class="dropdown-toggle"
                                          data-toggle="dropdown"
                                          role="button"
                                          aria-haspopup="true"
                                          aria-expanded="true"
                                        >
                                          {" "}
                                          <span className="nav-label">
                                            {t("Tenders")}
                                          </span>{" "}
                                          <span className="care"></span>
                                        </a>
                                        <ul className="dropdown-menu bg-white p-2">
                                          <li>
                                            <a
                                              href="https://cms.tn.gov.in/sites/default/files/acts/TNTIT_act_Rules_Amended_upto_June_2018.pdf"
                                              target="_blank"
                                            >
                                              {t("TTA")}
                                            </a>
                                          </li>
                                          {/* <li>
                                            <a
                                              href="https://www.india.gov.in/tamil-nadu-tender-transparency-act-and-rules"
                                              target="_blank"
                                            >
                                              {t("TTRules")}
                                            </a>
                                          </li> */}
                                          <li>
                                            <Link to="/tenders">
                                              {t("Tenders/Quatations")}
                                            </Link>
                                          </li>
                                        </ul>
                                      </li>
                                    ) : (
                                      <>
                                        {menu.name === "RTI" ? (
                                                <li class="dropdown">
                                                  <a
                                                    href="#"
                                                    class="dropdown-toggle"
                                                    data-toggle="dropdown"
                                                    role="button"
                                                    aria-haspopup="true"
                                                    aria-expanded="true"
                                                  >
                                                    {" "}
                                                    <span className="nav-label">
                                                      {menu.name}
                                                    </span>{" "}
                                                    <span className="caret"></span>
                                                  </a>
                                                  <ul className="dropdown-menu bg-white p-2">
                                                    <li>
                                                    <Link to="/pio">
                                                        {t("List of PIO")}
                                                      </Link>
                                                    </li>
                                                    <li>
                                                      <a
                                                        href="http://www.tnsic.gov.in/"
                                                        target="_blank"
                                                      >
                                                        {t(
                                                          "RTI Guidelines"
                                                         )}
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        href="http://www.tnsic.gov.in/petitions/otp-login.php"
                                                        target="_blank"
                                                      >
                                                        {t("Second Appeal")}
                                                      </a>
                                                    </li>
                                                   
                                                  </ul>
                                                </li>
                                        ) : (
                                          <>
                                                  {menu.name ===
                                                  "Important Links" ? (
                                                    <li class="dropdown" >
                                                      <a
                                                        href="#"
                                                        class="dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        role="button"
                                                        aria-haspopup="true"
                                                        aria-expanded="true"
                                                      >
                                                        {" "}
                                                        <span className="nav-label">
                                                          {t("Important Links")}
                                                        </span>{" "}
                                                        <span className="caret"></span>
                                                      </a>
                                                      <ul className="dropdown-menu bg-white p-2">
                                                        <li>
                                                          <a
                                                            href="https://www.india.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t("Government Of India")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t("Government Of TamilNadu")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://tnhealth.tn.gov.in/tngovin/healthdirectorates.php"
                                                            target="_blank"
                                                          >
                                                            {t("Health Directorates")}
                                                          </a>
                                                        </li>
                                                        
                                                        <li>
                                                          <a
                                                            href="https://main.mohfw.gov.in/"
                                                            target="_blank"
                                                          >
                                                           {t("Ministry of Health and Family Welfare")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.fssai.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t("Food Safety and Standards Authority of India")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="http://www.mrb.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                          {t("Medical Services Recruitment Board")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="http://www.nhm.tn.gov.in/en"
                                                            target="_blank"
                                                          >
                                                          {t("National Health Mission Tamil Nadu")}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="http://www.drugscontrol.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Tamilnadu Drug Control"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://labour.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Goverment of Tamil Nadu Labour Department"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.bis.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "bis"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.tnsocialwelfare.tn.gov.in/swd/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Social Welfare Department"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://icds.tn.gov.in/icdstn/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "ICDS"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.tncsc.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Tncsc"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://tnpcb.gov.in/PPFTN/events.php"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "PPTN"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://www.dvac.tn.gov.in/"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "Vigilance and Anti curruption Department"
                                                            )}
                                                          </a>
                                                        </li>
                                                        <li>
                                                          <a
                                                            href="https://eofficehod1.tn.gov.in/SSOComponent/gate.php"
                                                            target="_blank"
                                                          >
                                                            {t(
                                                              "eoffice"
                                                            )}
                                                          </a>
                                                        </li>
                                                      </ul>
                                                    </li>
                                            ) : (
                                              <>
                                                      {menu.name === "IEC" ? (
                                                        <li class="dropdown">
                                                          <a
                                                            href="#"
                                                            class="dropdown-toggle"
                                                            data-toggle="dropdown"
                                                            role="button"
                                                            aria-haspopup="true"
                                                            aria-expanded="true"
                                                          >
                                                            {" "}
                                                            <span className="nav-label">
                                                              {menu.name}
                                                            </span>{" "}
                                                            <span className="caret"></span>
                                                          </a>
                                                          <ul className="dropdown-menu bg-white p-2">
                                                            <li className="dropdown-submenu" >
                                                              <a
                                                                href="#"
                                                                class="dropdown-toggle"
                                                                data-toggle="dropdown"
                                                                role="button"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                              >
                                                                {" "}
                                                                <span
                                                                  className="nav-label"
                                                                  style={{
                                                                    padding:
                                                                      "3px",
                                                                  }}
                                                                >
                                                                  {t(
                                                                    "Basics of Food Safety"
                                                                  )}
                                                                </span>
                                                                <span className="caret"></span>
                                                              </a>
                                                              <ul className="dropdown-menu bg-white p-2">
                                                                <li>
                                                                  <a href="https://foodsafety.tn.gov.in/blogs/food-packets-labels-requirements">
                                                                    {t(
                                                                      "Labeling"
                                                                    )}
                                                                  </a>
                                                                </li>
                                                                {/* <li>
                                                                  <a href="https://foodsafety.tn.gov.in/blogs/storages-and-warehouses">
                                                                    {t(
                                                                      "Whare house and storages"
                                                                    )}
                                                                  </a>
                                                                </li> */}
                                                                <li>
                                                                  <a href="https://foodsafety.tn.gov.in/blogs/detection-of-adulteration-and-rapid-test-dart">
                                                                    {t(
                                                                      "Detection od Adultrationwith Rapid Test"
                                                                    )}
                                                                  </a>
                                                                </li>
                                                              </ul>
                                                            </li>
                                                            <li>
                                                              <Link to="/blogs">
                                                                {t("Blogs")}
                                                              </Link>
                                                            </li>
                                                            <li>
                                                                <Link to='/books'>
                                                                {t("Books")}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://fssai.gov.in/upload/media/FSSAI_News_Corona_Chef_28_03_2020.pdf"
                                                                target="_blank"
                                                              >
                                                                {t(
                                                                  "Do and Don’t's"
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://eatrightindia.gov.in/"
                                                                target="_blank"
                                                              >
                                                               {t("Blissful Hygienic Offering to God(BHOG)")}
                                                              </a>
                                                            </li>
                                                            {/* <li>
                                                              <a
                                                                href="#"
                                                                target="_blank"
                                                              >
                                                                {t("Cure ")}
                                                              </a>
                                                            </li> */}
                                                            <li>
                                                              <a
                                                                href="https://fssai.gov.in/cms/myth-buster.php"
                                                                target="_blank"
                                                              >
                                                                {t(
                                                                  "Myth Buster"
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <Link to="/gallery">
                                                                {t("Gallery")}
                                                              </Link>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://fssai.gov.in/ruco/"
                                                                target="_blank"
                                                              >
                                                                Repurpose Used Cooking Oil(RUCO)
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://jaivikbharat.fssai.gov.in/"
                                                                target="_blank"
                                                              >
                                                                {t(
                                                                  "Organic Good"
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li>
                                                            <Link
                                                                to="/donate-food"
                                                              >
                                                                {t(
                                                                  "Save food Share food"
                                                                )}
                                                              </Link>
                                                            </li>
                                                            <li>
                                                              <a
                                                                href="https://fortification.fssai.gov.in/"
                                                                target="_blank"
                                                              >
                                                                {t(
                                                                  'FF'
                                                                )}
                                                              </a>
                                                            </li>
                                                            <li>
                                                              <Link
                                                              to="/kids-corner"
                                                              >
                                                                {t(
                                                                  "Kids Corner"
                                                                )}
                                                              </Link>
                                                            </li>
                                                          </ul>
                                                        </li>
                                                      ) : 
                                                     
                                                          
                                                          (
                                                            
                                                              <Link to={menu.url}>
                                                                {menu.label}
                                                              </Link>
                                                            
                                                          )
                                                            } </>
                                                      )
                                                      }
                                                    </>
                                               
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </li>
                      );
                    })
                  : null}

          {user && Object.keys(user).length > 1 ? (
            <li className="nav-item no-arrow header">
              <Dropdown className="nav-link">
                <Dropdown.Toggle className="profile-btn" variant="link">
                  {/* <span className="mx-1 d-none d-lg-inline small">
                    {props && props.user && props.user.name
                      ? capitalize(props.user.name)
                      : "Manikandan"}
                  </span> */}
                  <img
                    alt="logo"
                    className="mx-1 img-profile rounded-circle"
                    src={
                      props && props.user && props.user.profile_picture
                        ? props.user.profile_picture
                        : dummpyImage
                    }
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item tag="a" href="/app/profile/">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    {t("Profile")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={getLogout}>
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    {t("Logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ) : null}
          <li className="menu-has-children">
            <Link to="#">
              {i18n.language === "en" ? "English" : "தமிழ்"}
              <i className="fa fa-chevron-down"></i>
            </Link>
            <ul>
              <li>
                <button onClick={() => handleChange("english")}>English</button>
              </li>
              <li>
                <button onClick={() => handleChange("tamil")}>தமிழ்</button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetBlogs: () => dispatch(getBlogs()),
  onGetBanners: () => dispatch(getBanners()),
  onGetNotifications: () => dispatch(getNotifications()),
  onGetFaqs: () => dispatch(getFaqs()),
  onUpdateLang: (data) => dispatch(updateLangSuccess(data)),
  onLogout: () => dispatch(logout()),
  onGetFaqCategory: () => dispatch(getFaqCategory()),
  onGetDoContacts: (data) => dispatch(getDoContacts(data)),
  ongetBanned:(data)=>dispatch(getBanned(data)),
  onUserDirectory: (data) => dispatch(userDirectory(data)),
  ongetLabs: (data) => dispatch(testingLab(data)),


});

const mapStateToProps = (state) => ({
  user: state.home.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
