import axios from 'axios';
import config from '../config';
import i18n from '../../i18n';
import { getAuthToken } from '../Auth';

const apiClient = axios.create({
  baseURL: config.API_URL,
  headers: {
    Accept: 'application/json',
  }
});

apiClient.interceptors.request.use(
  async config => {
    const token = await getAuthToken();
    config.headers.Authorization = 'Token ' + token;
    config.headers['Accept-Language'] = i18n.language;
    return config;
  }
)


apiClient.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 403) {
    localStorage.clear();
    window.location.href = "/login/";
  }
  throw error;
});

export default apiClient;
