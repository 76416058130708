import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createFboReports, createFundSpend, editFboReports , editFundSpend, getDistrictWards, getFundDetails, getFundspendDetails, getFundspenddistributeDetails} from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";

const FinanceSpend = (props) => {
    const { districts, user,wards, onCreatefundspend,wardsCategory,ongetFund,ongetoverall, oneditfundspen, traderTypes } = props;
    const [values, setValues] = useState({
        distribute:'',
        utilized_amount:'',
        utilized_date:'',
        comments:'',
        utilized_attachment:'',
        approval_attachments:'',
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const[overall, setOverall]=useState();
    const[fundlist, setFundlist]=useState([]);
    const[selectedfund, setSelectedfund]=useState();
    const [error, setError] = useState(false);

    let { slug } = useParams();
 const Types = [
    { id: "1", name: "Government" },
    { id: "2", name: "Private" },
 ]

 const getfunds=async()=>{
    const resp = await ongetFund()
    if(resp&&resp.response){
        setOverall(resp.response)
    }
}
const getoverall=async()=>{
    const resp=await ongetoverall()
    console.log(resp)
    if(resp&&resp.response){setFundlist(resp.response)}

}
const getfun=async()=>{
    const resp = await ongetoverall()
    const funlist=resp?.response
    if(funlist&&funlist?.length>0){
        let arr = funlist&&funlist.filter(i=>i.id==values.distribute)[0]
        console.log(arr,funlist)
        setSelectedfund(arr) 
    }
}
useEffect(()=>{
    getfun()
},[values&&values.distribute])
useEffect(()=>{
    console.log(props)
    getfunds()
    getoverall()
},[])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line   
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.fso_area_code)
            return (
                setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.fso_area_code])
    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'distribute',
            'utilized_amount',
            
        ];
        if(values&&values.utilized_amount&&parseInt(values.utilized_amount) > parseInt(selectedfund&&selectedfund.remaining_distribute_amount)){
            keys.push('utilized_amount')
            setError(true)
        } else setError(false)
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key== 'utilized_date') {
                    formData.append(key, moment(data[key]).format('YYYY-MM-DD'));
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await oneditfundspen(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/reports`);
            };

        } else {
            const data = { ...values}
            setDisableSubmit(true);
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key== 'utilized_date') {
                    formData.append(key, moment(data[key]).format('YYYY-MM-DD'));
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onCreatefundspend(formData);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports");
            };
        }       
        
    }

    const handleTextChange = (key, value) => {
        if(key=='distribute'){
            let arr = fundlist&&fundlist.filter(i=>i.id==value)[0]
            setSelectedfund(arr)
            console.log(arr)
        }
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (
        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Fund Spended Report</h1>
            <div className="card shadow mb-4">
            <div className="row">
            <div className="container-fluid">
              <div className="card-deck">
            <div className="card bg-success">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {overall?.distributed_amount
                        ? overall.distributed_amount
                        : "0"}
                    </button>
                    <p className="text text-light">Fund Distributed</p>
                  </div>
                </div>
                <div className="card bg-info">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {overall?.utilized_amount
                        ? overall.utilized_amount
                        : "0"}
                    </button>
                    <p className="text text-light">Fund Utilized</p>
                  </div>
                </div>
                <div className="card bg-success">
                  <div className="card-body text-center">
                    <button
                      className="btn btn-sm text-light number-label"
                      type="button"
                    >
                      {overall?.remaining_distribute
                        ? overall.remaining_distribute
                        : "0"}
                    </button>
                    <p className="text text-light">Fund Remaining</p>
                  </div>
                </div>
                </div>
                </div>
                </div>
                <div className="card-body">
                    <form>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Fund Name *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        value={values.distribute}
                                        error={errors.distribute}
                                        items={fundlist}
                                        onChange={(value) => handleTextChange("distribute", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="utilized_amount"
                            >Amount Used *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Amount Used`}
                                    name="utilized_amount"
                                    type="number"
                                    value={values.utilized_amount}
                                    error={errors.utilized_amount}
                                    onChange={(e) => handleTextChange("utilized_amount", e.target.value)}
                                />
                            </div>
                        </div> 
                        {error && <span style={{color:'red', textAlign:'center',marginLeft:'120px',fontSize:'13px',marginTop:'-22px',fontWeight:'bold'}}>Amount used should not exceed the available fund</span>}

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="utilized_date"
                            >Amount Spent Date: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Amount spent date`}
                                    name="date"
                                    value={values.utilized_date}
                                    error={errors.utilized_date}
                                    onChangeText={(text) => handleTextChange('utilized_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="comments"
                            >Comments: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Comments`}
                                    name="comments"
                                    value={values.comments}
                                    error={errors.comments}
                                    onChange={(e) => handleTextChange('comments', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_institution"
                            >Bill's Attachment: </label>
                            <div className="col-sm-8">
                            <TextInput
                                        type="file"
                                        name="utilized_attachment"
                                        value={values.utilized_attachment}
                                        error={errors.utilized_attachment }
                                        onChange={(e) => handleTextChange("utilized_attachment", e.target.files[0])}
                                />
                            </div>
                        </div>  
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type"
                                >Approval Document (If Any Attachment): </label>
                                <div className="col-sm-8">
                                <TextInput
                                            type="file"
                                            name="approval_attachments"
                                            error={errors.approval_attachments }
                                            onChange={(e) => handleTextChange("approval_attachments", e.target.files[0])}
                                    />
                                </div>
                            </div>   
      
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={error?error:disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreatefundspend: (data) => dispatch(createFundSpend(data)),
    oneditfundspen: (data, record_id) => dispatch(editFundSpend(data, record_id)),
    onGetnewWards:(data)=>dispatch(getDistrictWards(data)),
    ongetFund:(data)=>dispatch(getFundspendDetails(data)),
    ongetoverall:(data)=>dispatch(getFundspenddistributeDetails(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
    traderTypes: state.home.traderTypes,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(FinanceSpend);