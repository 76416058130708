import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { getUsers, doResetPassUser, getUsersView } from "../../components/reducers/actions";
import Table from "../../components/Table/Table";
import ModelResetPopup from "../../components/ModelResetPopup";
import ReactShowMoreText from "react-show-more-text";
import MaterialTable from 'material-table';

const customStyles = {
  rows: {
    style: {
      minHeight: "82px",
      fontSize: "18px",
      color: "#858796",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      minHeight: "72px",
      marginTop: "20px",
      fontWeight: "bold",
      color: "#1589FF",
        fontSize: "18px",
        background: "#e1eef0",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
     
    },
  },
};

const UserList = (props) => {
  const { users, user, onUsers, districts,wardsCategory, onResetPassUser } = props;
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [userData, setUserData] = useState({});

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };


  useEffect(() => {
    const userList =
      users &&
      // users.results &&
      users.map((item) => {
        return {
          ...item,
          district: getDistrict(item.district),
        };
      });  
    const userData = { ...users, results: userList };
    setUserData(userData);
    // eslint-disable-next-line
  }, [users]);

  const getDistrict = (district) => {
    let districtName = null;
    districts &&
      districts.forEach((item) => {
        if (item.id === district) {
          districtName = item.name;
        }
      });
    return districtName;
  };

  const getUserData = (user_id) => {
    return (
      users &&
      users.results &&
      users.results.find((item) => item.id === user_id)
    );
  };

  var columns = [
    {
      title: t("Name"),
      field:'name',
    },
    {
      title: t("ward"),
      field:'block',
      render: (rowData) => (
        <ReactShowMoreText
          lines={4}
          more="Show more"
          less="Show less"
          expanded={false}
          truncatedEndingComponent={'... '}
        >
          {rowData.block}
        </ReactShowMoreText>
      ),
    },
    {
      title: t("Email"),
      field:'email',
    },
    {
      title: t("Phone number"),
      field:'phone_number',
    },
    {
      title: t("User Types"),
      field:'user_types',
    },
    {
      title: t("Language"),
      field:'user_types',
      render: (rowData) => (
        <div>{capitalize(rowData.language)}</div>
      ),
    },
    {
      title: t("District"),
      field:'district',
    },
    {
      title: t("Verified"),
      field:'is_verified',
      render: (rowData) => (
        <div title={rowData.is_verified ? "Verified" : "Not Verified"}>
          {rowData.is_verified ? "Yes" : "No"}
        </div>
      ),
    },
    {
      title: t("Active"),
      field:'is_active',
      render: (rowData) => (
        <div
          className={
            rowData.is_active
              ? "users__list__item active"
              : "users__list__item inactive"
          }
          title={rowData.is_active ? "Active" : "Inactive"}
        ></div>
      ),
    },
    {
      title: t("Action"),
      field:'id',
      render: (rowData) => (
        <div className="action__area">
          <Link
            to={{
              pathname: `/app/users/${rowData.id}/edit/`,
              state: {
                user: getUserData(rowData.id) || rowData,
              },
            }}
          >
            <Button
              className="btn btn-success btn-circle btn-sm"
              title={t("Update User")}
            >
              <i className="fas fa-edit"></i>
            </Button>
          </Link>

        </div>
      ),
    },
  ];

  const handleClickReset = (item) => {
    setSelectedItem(item);
    toggle();
  };

  const loadMoreUsers = () => {
    onUsers({
      page: page,
      per_page: countPerPage,
      is_base64: true,
    });
  };

  useEffect(() => {
      loadMoreUsers();
    // eslint-disable-next-line
  }, [page]);

  const handleResetSubmit = async (item) => {
    setIsSpinner(true);
    const resp = await onResetPassUser({
      user_id: item.id,
    });
    if (resp && resp.data === "success") {
      setSelectedItem({});
      setModal(false);
      setIsSpinner(false);
      props.history.push("/app/users/");
    }
  };

  const toggle = () => setModal(!modal);

  return (
    <div className="container-fluid">
      <div>
        {modal ? (
          <ModelResetPopup
            modal={modal}
            data={selectedItem}
            isSpinner={isSpinner}
            handleResetSubmit={handleResetSubmit}
            title="User"
            toggle={toggle}
          ></ModelResetPopup>
        ) : null}
      </div>
      <div className="mb-4">
        <h1 className="h4 mb-4 text-gray-800">{t("Users")}</h1>
        <div className="card shadow mb-4">
          {user && user.user_types && user.user_types.includes("ho") ? (
            <div className="card-header py-3">
              <Link
                className="btn btn-primary-green pull-right"
                to="/app/users/add/"
              >
                {t("+ Add New User")}
              </Link>
            </div>
          ) : null}

          {userData && userData.results ? (
            <div className="card-body">
              {/* <Table
                columns={columns}
                data={userData.results}
                count={userData.count}
                countPerPage={countPerPage}
                pageFalse
                customStyles={customStyles}
                handlePageChange={(page) => setPage(page)}
              /> */}
              <MaterialTable 
              columns={columns}
              data={userData.results}
              options={{
                exportButton: {
                  csv: true,
                  pdf: false,
                },
                search: true,
                filtering: true,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [20, 30, 40, 50, 60, 70, 80, 90, 100,200,300,400,500],
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF",
                  borderColor: "#fff",
                  textAlign: "center",zIndex:0
                },
              }}
              title="User List"
              localization={{
                toolbar: {
                  searchPlaceholder: 'Search',
                },
              }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUsers: (data) => dispatch(getUsersView(data)),
  onResetPassUser: (data) => dispatch(doResetPassUser(data)),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  users: state.home.users,
  districts: state.home.districts,
  wardsCategory:state.home.wardsCategory,

});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
