import React from 'react';
import Header from '../../components/Header';
import { useEffect , useState} from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getReferralLab, testingLab } from "../../components/reducers/actions";
import DownloadLink from "react-download-link";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { saveAs } from 'file-saver';

const TestingLab = (props)=>{
  const { t, i18n } = useTranslation();
  const [product, setProduct]=useState([]);
  const [referral, setReferrals]= useState([]);
  const { ongetLabs,user,ongetReferal } = props;
  const getProduct = async () => {
    const resp = await ongetLabs();
    if(resp.response){
      setProduct(resp.response)
    }
    else{
      console.log('error')
    }
  };
  const Download = (props) => {
    const saveFile = () => {
      saveAs(
        props.data.attachment,
        props.data.file_name
      );
    };
    return (
      <div style={{width:'90px'}} className="download_btn">
        <a href={props.data.attachment} target="_blank">
          {" "}
          View
        </a>
        <b>/</b>
        <div>
          <a
            onClick={()=>{saveFile(props)}}
            style={{textDecoration:'underline',color:'blue'}}
          >
          Download
          </a>
        </div>
      </div>
    );
  };
  const getProducts = async () => {
    const resp = await ongetReferal();
    if(resp.response){
      setReferrals(resp.response)
    }
    else{
      console.log('error')
    }
  };
  useEffect(() => {
    getProduct();
    getProducts();
  }, []);
useEffect(()=>{
    getProduct();
    getProducts();
},[user])
    return(
        <div style={{marginBottom:'150px'}}>
           <Header />
           <header
        className="page-header"
        style={{backgroundColor:"#1589FF" }}
      >
        <div className="container position-relative px-5 text-center text-white">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">{t("Testing labs of Tamilnadu food safety department")}</h1>
            </div>
          </div>
        </div>
      </header>
      <div className='table_top'>
      {product && <>
      <Table  bordered  responsive style={{fontSize:12}}>
        
          <thead>
            <tr>
              <th>S.No</th>
              <th>{t("Name")}</th>
              <th>{t("Location")}</th>
              <th>{t("Contact Person")}</th>
              <th>{t("Email")}</th>
              <th>{t("Contact Number")}</th>
            </tr>
          </thead>
          <tbody>
            {product.map((item, index)=>
            <tr>
              <td>{index+1}</td>
              <td>{item.name}</td>
              <td>{item.location}</td>
              <td>{item.contact_person}</td>
              <td>{item.email}</td>
              <td>{item.phone_number}</td>
            </tr>
            )}
          </tbody>
          </Table>
          </>}
        </div>
        <div style={{width:'85%', margin:'20px auto'}}>
          <h3 style={{textAlign:'center'}}>{t("Referral Labs")}</h3>
          {referral && <>
      <Table  bordered  responsive style={{fontSize:12}}>
      <thead>
            <tr>
              <th>S.No</th>
              <th style={{textAlign:'center'}}>{t("Description")}/Title</th>
              <th tyle={{textAlign:'center'}}>{t("Download/View")}</th>
            </tr>
          </thead>
          <tbody>
            {referral.map((item, index) => (
              <tr>
                <td>{index+1}</td>
                <td>{item.title}</td>
                <td tyle={{textAlign:'center'}}>
                  <Download data={item} />
                </td>
              </tr>
            ))}
          </tbody>
          </Table>
          </>}
        </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
  ongetLabs: (data) => dispatch(testingLab(data)),
  ongetReferal: (data) => dispatch(getReferralLab(data)),
});

const mapStateToProps = (state) => ({
  user:state.home.user
});

export default connect(mapStateToProps, mapDispatchToProps)(TestingLab);
