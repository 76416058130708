import React, { useEffect } from "react";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import FaqAccordion from "../components/FaqAccordion/FaqAccordion";
import {
  getBanners,
  getNotifications,
  getFaqs,
  getBlogs,
  getFaqCategory,
} from "../components/reducers/actions";
import { Link } from "react-router-dom";
import { getLangCode } from "../utils/config";
import { style } from "d3";
const Home = (props) => {
  const {
    user,
    banners,
    notifications,
    faqs,
    blogs,
    onGetBanners,
    onGetFaqCategory,
    onGetBlogs,
    onGetFaqs,
    onGetNotifications,
  } = props;

  const { t, i18n } = useTranslation();

  const getAssets = () => {
    onGetBanners();
    onGetFaqs();
    onGetBlogs();
    onGetFaqCategory();
    onGetNotifications();
  };

  useEffect(() => {
    getAssets();
    // eslint-disable-next-line
   
  }, []);

  useEffect(() => {
    if (user && user.language) {
      i18n.changeLanguage(getLangCode(user.language));
      getAssets();
    }
    // eslint-disable-next-line
  }, [user]);

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  let blogsFilter = blogs && blogs.filter((item) => item.is_visible_home);
  const blogsList = blogs
    ? blogsFilter.map((item, idx) => {
        if (idx % 2 === 0) {
          return (
            <div key={idx} className="blogs__list__item">
              <div className="blogs__image">
                <img src={item.image} alt="" />
              </div>
              <div className="blogs__content">
                <h4 className="title">{item.title}</h4>
                <div className="intro">{item.description}</div>
                <div className="link">
                  <Link
                    to={{
                      pathname: `/blogs/${item.slug}`,
                      state: {
                        blog: item,
                      },
                    }}
                  >
                    {item.title}
                  </Link>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div key={idx} className="blogs__list__item">
              <div className="blogs__content blogs__content__white">
                <h4 className="title">{item.title}</h4>
                <div className="intro">{item.description}</div>
                <div className="link">
                  <Link
                    to={{
                      pathname: `/blogs/${item.slug}`,
                      state: {
                        blog: item,
                      },
                    }}
                  >
                    {item.title}
                  </Link>
                </div>
              </div>
              <div className="blogs__image">
                <img src={item.image} alt="" />
              </div>
            </div>
          );
        }
      })
    : null;

  const eventsList = notifications
    ? notifications.map((item, idx) => {
        return (
          item.type_of_notification === "events" && (
            <div>
              <a
                key={idx}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  item.notification_file
                    ? item.notification_file
                    : item.notification_url
                    ? item.notification_url
                    : ""
                }
              >
                <span>{item.title}</span>
              </a>
            </div>
          )
        );
      })
    : null;

  const notificationList = notifications
    ? notifications.map((item, idx) => {
        return (
          item.type_of_notification !== "events" && (
            <div key={idx}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  item.notification_file
                    ? item.notification_file
                    : item.notification_url
                    ? item.notification_url
                    : ""
                }
              >
                <span>
                  {item.title} -{" "}
                  <span className="notification__bg">
                    {capitalize(item.type_of_notification)}
                  </span>
                </span>
              </a>
            </div>
          )
        );
      })
    : null;

  const bannersList = banners
    ? banners.map((item, idx) => {
        return (
          <div key={idx}>
            <img className="banner-image" src={item.image} alt="bg3" />
            {/* <div className="carousel__content">
              <div className="carousel__content-wrapper__content w-100">
                <div className="carousel__content-row-wrapper">
                  <div>
                    <h1 className="carousel__content__heading">{item.title}</h1>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        );
      })
    : null;

  return (
    <>
      <Header activeMenu="Home" />
      <div id="main">
        <section id="intro">
          <div className="intro-container">
            <Carousel
              showArrows={true}
              showThumbs={false}
              showIndicators={true}
              showStatus={false}
              autoPlay={true}
              infiniteLoop={true}
              interval={2000}
           >
              {bannersList}
            </Carousel>
          </div>
        </section>
        <section id="news_content">
          <div style={{ backgroundColor: "#fff" }}>
            <div className="news__container">
              <div className="container">
                <div className="section-header">
                  <h3>{t("Latest news and alerts")}</h3>
                </div>
                <div className="row news__content">
                  <div className="col">
                    <div className="notification__container">
                      <h4 className="title">{t("Notifications")}</h4>
                      <div className="marquee">
                        <div className="notification__list">
                          {notificationList}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="notification__container">
                      <h4 className="title">{t("Events")}</h4>
                      <div className="marquee">
                        <div className="notification__list">{eventsList}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="featured-services">
          <div className="featured__container">
            <header className="section-header">
              <h3>{t("Popular content")}</h3>
              <p></p>
            </header>
            <div className="row">
              <div className="col-lg-4 mb-5">
              <h4 className="title header_title">{t("For Consumer")}</h4>
                <div className="box">
                  <img src="/images/cons.png" alt="" />
                  <div className="content">
                    <div className="blogs__list">
                                <Link
                                 to="/redressal" >
                                  {t("Greievance Redressal")}   
                                </Link>
                                
                                <Link
                                  to="/login"
                                >
                                  {t("Raise your complaint")}
                                </Link>
                                <Link
                                  to="/blogs"
                                >
                                   {t("Blog")}
                                </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5">
              <h4 className="title header_title">{t("For Business")}</h4>
                <div className="box">
                  <img src="/images/forbusiness.png" alt="" />
                  <div className="content">
                    <div className="blogs__list">
                                <a
                                  href="https://foscos.fssai.gov.in/"
                                  target="_blank"
                                >
                                 {t("License/Registration")}
                                </a>
                                <a
                                  href="https://foscos.fssai.gov.in/user-manual"
                                  target="_blank"
                                >
                                 {t("License/Registration Guidelines")}
                                </a>
                                <Link
                                  to="/fbo-forms"
                                >
                                 {t("Forms")}
                                </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-5">
              <h4 className="title header_title">{t("About us")}</h4>
                <div className="box">
                  <img src="/images/about.png" alt="" style={{background:'#d7d7d7'}} />
                  <div className="content">
                    <div className="blogs__list">
                  <Link to="/org-chart">
                                            {t("Organisation Structure")}
                                          </Link>
                                          <Link to="/who">{t("ww")}</Link>
                                          <Link to="/user-directory">
                                            {t("Directory")}
                                          </Link>
                                          <Link to="/about-department">
                                            {t("ATD")}
                                          </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="blogs__list__container">{blogsList}</div>
        </section> */}
        {/* <section>
          <div className="blogs__list__container">
            <FaqAccordion panels={faqs} />
          </div>
        </section> */}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetBanners: () => dispatch(getBanners()),
  onGetNotifications: () => dispatch(getNotifications()),
  onGetFaqs: () => dispatch(getFaqs()),
  onGetBlogs: () => dispatch(getBlogs()),
  onGetFaqCategory: () => dispatch(getFaqCategory()),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  banners: state.home.banners,
  notifications: state.home.notifications,
  faqs: state.home.faqs,
  blogs: state.home.blogs,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
