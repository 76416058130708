import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createDOAppealReports, editDOAppealReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';
import TextAreaInput from "../../components/inputs/TextAreaInput";

const TypeOfTrader = [
    { id: '0', name: 'Manufacturer' },
    { id: '1', name: 'Retailer' },
    { id: '2', name: 'WholeSaler/Distributor/Repacker' },
    { id: '3', name: 'Transporter' },
    { id: '4', name: 'Others' },
]

const Appeal = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const Allowed = [
    {id:'0', name:'Appeal Allowed'},
    {id:'1', name:'Dismissed'},
]
const Case = [
    {id:'0', name:'Civil'},
    {id:'1', name:'Criminal'},
]
const DOAppealReport = (props) => {
    const { districts,wards, user,wardsCategory, onCreateDOAppealReports, onEditDOAppealReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'report_received_on':'',
        'report_intimated_to_fso_on' : '',
        'report_intimated_to_fbo_on':'',
        'type_of_trader':'',
        'appeal_received_from_fbo':'',
        'appeal_received_from_fbo_on':'',
        'appeal_allowed':'',
        'sent_another_part_of_sample':'',
        'lab_name':'',
        'lab_address':'',
        'result_sent_to_fso_on':'',
        'case_category':'',
        'case_file_recommended_to_lab_APP':'',
        'case_file_recommended_to_commisionerate_on':'',
        'other_type_of_trader':'',
        'dismiss_reason':'',
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district' ,
        'date' ,
        'area_code',
        "area_name",
        'report_received_on',
        'report_intimated_to_fso_on',
        'report_intimated_to_fbo_on',
        'type_of_trader',
        'appeal_received_from_fbo',
       
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditDOAppealReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/do-appeal-list/`);
            };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateDOAppealReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Sample Result Appeal Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="report_received_on"
                            >Report Received On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report Received On`}
                                    name="report_received_on"
                                    value={values.report_received_on}
                                    error={errors.report_received_on}
                                    onChangeText={(text) =>handleTextChange("report_received_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="report_intimated_to_fso_on"
                            >Report Intimated to FSO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report Intimated to FSO On`}
                                    name="report_intimated_to_fso_on"
                                    value={values.report_intimated_to_fso_on}
                                    error={errors.report_intimated_to_fso_on}
                                    onChangeText={(text) =>handleTextChange("report_intimated_to_fso_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="report_intimated_to_fbo_on"
                            >Report Intimated To FBO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Report Intimated To FBO On`}
                                    name="report_intimated_to_fbo_on"
                                    value={values.report_intimated_to_fbo_on}
                                    error={errors.report_intimated_to_fbo_on}
                                    onChangeText={(text) =>handleTextChange("report_intimated_to_fbo_on", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_trader"
                            >Type Of Trader *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type Of Trader`}
                                    name="type_of_trader"
                                    items={TypeOfTrader}
                                    action="dropdown"
                                    value={values.type_of_trader}
                                    error={errors.type_of_trader}
                                    onChange={(text) =>handleTextChange("type_of_trader", text)}
                                />
                            </div>
                        </div> 
                        {values && values.type_of_trader.includes('Others')?
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_trader"
                         ></label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Type Of Trader`}
                                 name="other_type_of_trader"
                                 id="other_type_of_trader"
                                 value={values.other_type_of_trader}
                                 error={errors.other_type_of_trader}
                                 onChange={(e) =>handleTextChange("other_type_of_trader", e.target.value)}
                             />
                         </div>
                         </div>
                        : null}
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_received_from_fbo"
                            >Appeal Received From FBO *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Received from Fbo`}
                                    name="appeal_received_from_fbo"
                                    items={Appeal}
                                    action="dropdown"
                                    value={values.appeal_received_from_fbo}
                                    error={errors.appeal_received_from_fbo}
                                    onChange={(text) =>handleTextChange("appeal_received_from_fbo", text)}
                                />
                            </div>
                        </div> 
                        {values && values.appeal_received_from_fbo.includes('Yes') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_received_from_fbo_on"
                            >Appeal Received From FBO On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Received from Fbo on`}
                                    name="appeal_received_from_fbo_on"
                                    value={values.appeal_received_from_fbo_on}
                                    error={errors.appeal_received_from_fbo_on}
                                    onChangeText={(text) =>handleTextChange("appeal_received_from_fbo_on", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_allowed"
                            >Appeal Allowed: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Allowed`}
                                    name="appeal_allowed"
                                    items={Allowed}
                                    action="dropdown"
                                    value={values.appeal_allowed}
                                    error={errors.appeal_allowed}
                                    onChange={(text) =>handleTextChange("appeal_allowed", text)}
                                />
                            </div>
                        </div>
                        {values && values.appeal_allowed.includes('Appeal Allowed') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="sent_another_part_of_sample"
                            >Sent Another Part to Lab On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Sent Another Part Sample to lab on`}
                                    name="sent_another_part_of_sample"
                                    value={values.sent_another_part_of_sample}
                                    error={errors.sent_another_part_of_sample}
                                    onChangeText={(text) =>handleTextChange("sent_another_part_of_sample", text)}
                                />
                            </div>
                        </div><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="lab_name"
                            >Name of the Lab: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the lab`}
                                    name="lab_name"
                                    value={values.lab_name}
                                    error={errors.lab_name}
                                    onChange={(e) =>handleTextChange("lab_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="lab_address"
                            >Address of the Lab: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Address of the lab`}
                                    name="lab_address"
                                    value={values.lab_address}
                                    error={errors.lab_address}
                                    onChange={(e) =>handleTextChange("lab_address", e.target.value)}
                                />
                            </div>
                        </div>
                         </> : null}
                         {values && values.appeal_allowed.includes('Dismissed') ? <>
                           <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="dismiss_reason"
                            >Reason For Dismissal: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Reason for dismissal`}
                                    name="dismiss_reason"
                                    value={values.dismiss_reason}
                                    error={errors.lab_name}
                                    onChange={(e) =>handleTextChange("dismiss_reason", e.target.value)}
                                />
                            </div>
                        </div>
                         </> : null}
                         </> : null}
                     
                        {values && values.appeal_received_from_fbo.includes('No') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_category"
                            >Case Category: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Category`}
                                    name="case_category"
                                    items={Case}
                                    action="dropdown"
                                    value={values.case_category}
                                    error={errors.case_category}
                                    onChange={(text) =>handleTextChange("case_category", text)}
                                />
                            </div>
                        </div>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="result_sent_to_fso_on"
                            >Result Sent to FSO On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Result Sent to FSO On`}
                                    name="result_sent_to_fso_on"
                                    value={values.result_sent_to_fso_on}
                                    error={errors.result_sent_to_fso_on}
                                    onChangeText={(text) =>handleTextChange('result_sent_to_fso_on', text)}
                                />
                            </div>
                        </div>
                        {values && values.case_category.includes('Civil') ? 
                         
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="case_file_recommended_to_lab_APP"
                         >Case File Recommended to Lap APP Grade II: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`Case File Recommended to Lap APP Grade II`}
                                 name="case_file_recommended_to_lab_APP"
                                 value={values.case_file_recommended_to_lab_APP}
                                 error={errors.case_file_recommended_to_lab_APP}
                                 onChangeText={(text) =>handleTextChange("case_file_recommended_to_lab_APP", text)}
                             />
                         </div>
                     </div>
                        : null}
                         {values && values.case_category.includes('Criminal') ? 
                          <div className="form-group row">
                          <label className="control-label col-sm-4 text-label" htmlFor="case_file_recommended_to_commisionerate_on"
                          >Case File Recommended to Commisionerate On: </label>
                          <div className="col-sm-8">
                              <Datepicker
                                  placeholder={`Case File Recommended to Commisionerate On`}
                                  name="case_file_recommended_to_commisionerate_on"
                                  value={values.case_file_recommended_to_commisionerate_on}
                                  error={errors.case_file_recommended_to_commisionerate_on}
                                  onChangeText={(text) =>handleTextChange("case_file_recommended_to_commisionerate_on", text)}
                              />
                          </div>
                      </div>
                      
                        : null}
                         </> : null}
                      
                    
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateDOAppealReports: (data) => dispatch(createDOAppealReports(data)),
    onEditDOAppealReports: (data, record_id) => dispatch(editDOAppealReports(data, record_id)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(DOAppealReport);