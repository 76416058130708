import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createDOClarifyReports, createSampleSuccess, editDOClarifyReports, updateCivil, updateCriminal } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const TypeOfTrader = [
    { id: '0', name: 'Manufacturer' },
    { id: '1', name: 'Retailer' },
    { id: '2', name: 'WholeSaler/Distributor/Repacker' },
    { id: '3', name: 'Transporter' },
    { id: '4', name: 'Others' },
]

const Appeal = [
    {id:'0', name:'Yes'},
    {id:'1', name:'No'},
]
const Converted = [
    {id:'0', name:'Case Converted'},
    {id:'1', name:'Minor Faulty/Defect Case Closed'},
]
const Case = [
    {id:'0', name:'Civil'},
    {id:'1', name:'Criminal'},
]
const DOCaseClarifyReport = (props) => {
    const { districts,wards, user,wardsCategory,onupdataData,fso_submit_data,resultObtained,onUpdateCivil,onUpdateCriminal, criminalResults,civilResults, onCreateDOClarifyReports, onEditDOClarifyReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'case_converted':'',
        'case_category':'',
        'appeal_received_from_fbo':'',
        'appeal_received_from_fbo_on':'',
        'appeal_allowed':'',
        'approval_received_from_app_on':'',
        'ao_case_file_sent_to_fso':'',
        'case_file_approval_from_commissioner_on':'',
        'case_file_sent_to_fso_on':''
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
        'district' ,
      
        'area_code',
        "area_name",
        'case_converted',
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            let id=props.history.location.state.values.sample_number_given_by_do
            const data = { ...values}
            if(values&&values.case_converted ==='Case Converted'){
                if(values&&values.case_category=='Civil'){
                    let filt = resultObtained.filter((i,indx)=>i.sample_number_given_by_do==id)[0]
                    filt.sample_status="Waiting for APP-I approval"
                    let dat = []
                    dat.push(filt)
                    await onUpdateCivil(dat)
                    let changeData=fso_submit_data
                    let index = fso_submit_data.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter((i=>i))[0]
                    changeData[index?index:0].sample_status="Waiting for APP-I approval"
                    await onupdataData(changeData)
                }
               
                else{
                    let filt = resultObtained.filter((i,indx)=>i.sample_number_given_by_do==id)[0]
                    filt.sample_status="Waiting for APP-II approval"
                    let dat = []
                    dat.push(filt)
                    await onUpdateCriminal(dat) 
                    let changeData=fso_submit_data
                    let index = fso_submit_data.map((i,indx)=>{if(i.sample_number_given_by_do==id)return indx}).filter((i=>i))[0]
                    changeData[index?index:0].sample_status="Waiting for APP-II approval"
                    await onupdataData(changeData)
                }
            }
            
            // const resp = await onEditDOClarifyReports(data, values.id);
            // if (resp && resp.response) {
            //     console.log(resp.response);
            //     props.history.push(`/app/display-reports/do-clarify-list/`);
            // };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateDOClarifyReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">CaseFile Clarification Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do")|| user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        {/* <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div> */}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_converted"
                            >Case Converted *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Converted`}
                                    name="case_converted"
                                    items={Converted}
                                    action="dropdown"
                                    value={values.case_converted}
                                    error={errors.case_converted}
                                    onChange={(text) =>handleTextChange("case_converted", text)}
                                />
                            </div>
                        </div>{ values && values.case_converted.includes('Case Converted')? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_category"
                            >Case Category: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Category`}
                                    name="case_category"
                                    items={Case}
                                    action="dropdown"
                                    value={values.case_category}
                                    error={errors.case_category}
                                    onChange={(text) =>handleTextChange("case_category", text)}
                                />
                            </div>
                        </div>
                        {values && values.case_category.includes('Csivil')? <> 
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_received_from_fbo"
                            >Clarification Received From FBO : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Clarification Received from Fbo`}
                                    name="appeal_received_from_fbo"
                                    items={Appeal}
                                    action="dropdown"
                                    value={values.appeal_received_from_fbo}
                                    error={errors.appeal_received_from_fbo}
                                    onChange={(text) =>handleTextChange("appeal_received_from_fbo", text)}
                                />
                            </div>
                        </div> 
                        {values && values.appeal_received_from_fbo.includes('sYes') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_received_from_fbo_on"
                            >Clarification Received From FBO On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Clarification Received from Fbo on`}
                                    name="appeal_received_from_fbo_on"
                                    value={values.appeal_received_from_fbo_on}
                                    error={errors.appeal_received_from_fbo_on}
                                    onChangeText={(text) =>handleTextChange("appeal_received_from_fbo_on", text)}
                                />
                            </div>
                        </div>  </> : null} 
                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="approval_received_from_app_on"
                         >Approval Received From APP on: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`Approval Received From APP on`}
                                 name="approval_received_from_app_on"
                                 value={values.approval_received_from_app_on}
                                 error={errors.approval_received_from_app_on}
                                 onChangeText={(text) =>handleTextChange("approval_received_from_app_on", text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="ao_case_file_sent_to_fso"
                         >AO Case File Sent to FSO On: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`AO Case File Sent To FSO On`}
                                 name="ao_case_file_sent_to_fso"
                                 value={values.ao_case_file_sent_to_fso}
                                 error={errors.ao_case_file_sent_to_fso}
                                 onChangeText={(text) =>handleTextChange("ao_case_file_sent_to_fso", text)}
                             />
                         </div>
                     </div>
                        
                         </>: null}
                         {values && values.case_category.includes('sCriminal') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_received_from_fbo"
                            >Clarification Received From FBO : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Received from Fbo`}
                                    name="appeal_received_from_fbo"
                                    items={Appeal}
                                    action="dropdown"
                                    value={values.appeal_received_from_fbo}
                                    error={errors.appeal_received_from_fbo}
                                    onChange={(text) =>handleTextChange("appeal_received_from_fbo", text)}
                                />
                            </div>
                        </div> 
                        {values && values.appeal_received_from_fbo.includes('sYes') ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_received_from_fbo_on"
                            >Clarification Received From FBO On: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Clarification Received from Fbo on`}
                                    name="appeal_received_from_fbo_on"
                                    value={values.appeal_received_from_fbo_on}
                                    error={errors.appeal_received_from_fbo_on}
                                    onChangeText={(text) =>handleTextChange("appeal_received_from_fbo_on", text)}
                                />
                            </div>
                        </div>  </> : null} 

                        <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="case_file_approval_from_commissioner_on"
                         >Case File Approval Received From Commissioner On: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`Case File Approval rececived from Commissioner On`}
                                 name="case_file_approval_from_commissioner_on"
                                 value={values.case_file_approval_from_commissioner_on}
                                 error={errors.case_file_approval_from_commissioner_on}
                                 onChangeText={(text) =>handleTextChange("case_file_approval_from_commissioner_on", text)}
                             />
                         </div>
                     </div>
                     <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="case_file_sent_to_fso_on"
                         >Case File Sent to FSO On: </label>
                         <div className="col-sm-8">
                             <Datepicker
                                 placeholder={`Case File Sent To FSO On`}
                                 name="case_file_sent_to_fso_on"
                                 value={values.case_file_sent_to_fso_on}
                                 error={errors.case_file_sent_to_fso_on}
                                 onChangeText={(text) =>handleTextChange("case_file_sent_to_fso_on", text)}
                             />
                         </div>
                     </div>
                          </> 
                         : null }
                      
                      </> : null}

                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateDOClarifyReports: (data) => dispatch(createDOClarifyReports(data)),
    onEditDOClarifyReports: (data, record_id) => dispatch(editDOClarifyReports(data, record_id)),
    onUpdateCivil: (data, record_id) => dispatch(updateCivil(data, record_id)),
    onUpdateCriminal: (data, record_id) => dispatch(updateCriminal(data, record_id)),
    onupdataData: (data, record_id) => dispatch(createSampleSuccess(data, record_id)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
    civilResults:state.home.civilResults,
    criminalResults:state.home.criminalResults,
    resultObtained:state.home.resultObtained,
    fso_submit_data:state.home.fso_submit_data,


});
  
export default connect(mapStateToProps, mapDispatchToProps)(DOCaseClarifyReport);