import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const FoscorisDigitalDetails = (props) => {
    const { districts, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
 
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Foscoris Digital Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
              <th>Area Code</th>
              <td className="text-left">{getAreaCode(records.area_code)}</td>
            </tr>
            <tr>
              <th>Area Name</th>
              <td className="text-left">{records.area_name}</td>
            </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Number Of Inspections Conducted Through Foscoris</th>
                   <td className="text-left">{records.no_of_inspections_conducted_through_foscoris}</td>
                </tr>
                <tr>
                  <th>Foscoris Digital Inspection Conducted during the month(High Risk)</th>
                   <td className="text-left">{records.inspection_during_the_month_high}</td>
                </tr>
                {records.other_high && <tr>
                  <th>Foscoris Digital Inspection Conducted during the month(High Risk)-Others</th>
                   <td className="text-left">{records.other_high}</td>
                </tr> }
              <tr>
                <th>Number of Foscoris Digital Inspection Conducted during the month(High Risk)</th>
                <td className="text-left">{records.inspection_high}</td>
              </tr> 
              <tr>
                <th>Foscoris Digital Inspection Conducted during the month(Medium Risk):</th>
                <td className="text-left">{records.inspection_during_the_month_medium}</td>
              </tr>
              {records.other_medium &&  
              <tr>
                <th>Foscoris Digital Inspection Conducted during the month(Medium Risk)-Others:</th>
                <td className="text-left">{records.other_medium}</td>
              </tr>  } 
              <tr>
                <th>Number Of Foscoris Digital Inspection Conducted during the month(Medium Risk)</th>
                <td className="text-left">{records.inspection_medium}</td>
              </tr>   
              <tr>
                <th>Foscoris Digital Inspection Conducted during the month(Low Risk)</th>
                <td className="text-left">{records.inspection_during_the_month_low}</td>
              </tr>   
              {records.other_low && <tr>
                <th>Foscoris Digital Inspection Conducted during the month(Low Risk)-Others</th>
                <td className="text-left">{records.other_low}</td>
              </tr>  }
              <tr>
                <th>Number of Foscoris Digital Inspection Conducted during the month(Low Risk)</th>
                <td className="text-left">{records.inspection_low}</td>
              </tr>  
              <tr>
                <th>Remarks</th>
                <td className="text-left">{records.remarks}</td>
              </tr>
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards,

});

export default connect(mapStateToProps, mapDispatchToProps)(FoscorisDigitalDetails);