import React, { useState } from "react";
import Panel from './Panel';
import { useTranslation } from 'react-i18next';
import './FaqAccordion.css';

const FaqAccordion = (props) => {

  const [activeTab, setActiveTab] = useState(0);
  const { panels, hideTitle, isCategory } = props;
  const { t } = useTranslation();

  const handleActivateTab = (index) => {
    setActiveTab(prevState => (prevState === index ? -1 : index));
  }

  return (
    <div className="faq__list">
      <div className='accordion' role='tablist'>
        {
          !hideTitle ? (
            <h2>{t('Frequently Asked Questions')}</h2>
          ) : null
        }
        {panels && panels.map((panel, index) =>
          <Panel
            key={index}
            activeTab={activeTab}
            index={index}
            isCategory={isCategory}
            {...panel}
            activateTab={() => handleActivateTab(index)}
          />
        )}
      </div>
    </div>
  )
}

export default FaqAccordion;