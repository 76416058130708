import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Button } from 'reactstrap';
import $ from 'jquery';
import ModelPopup from './ModelPopup';
import { deleteMasterData, getMasterData } from './reducers/actions';
import { Link } from 'react-router-dom';

const Table = (props) => {

  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const { onDeleteMasterData, onGetMasterData } = props;

  useEffect(() => {
    if (props.data) {
      $(document).ready(function () {
        $('#dataTable').DataTable();
      });
      setData(props.data);
    }
  }, [props]);

  const handleClickDelete = (item) => {
    setSelectedItem(item);
    toggle();
  }

  const handleDeleteSubmit = async (item) => {
    const data = { ...item, 'path': props.path };
    const resp = await onDeleteMasterData(data);
    if (resp && resp.data === 'success') {
      onGetMasterData();
      setSelectedItem({});
      setModal(false);
    }
  }

  const toggle = () => setModal(!modal);

  const tableBody = data && data.length > 0 ? data.map((item, idx) => (
    <tr key={idx}>
      <td>{idx + 1}</td>
      <td>{item.name}</td>
      <td>
        <img src={item.image} alt={item.name} width="30" height="30" />
      </td>
      <td>
        <div className="action__area">
          <Link to={
            {
              pathname: `/app/${props.path}/${item.id}/edit/`,
              state: item
            }
          }>
            <Button className="btn btn-success btn-circle btn-sm">
              <i className="fas fa-edit"></i>
            </Button>
          </Link>
          <Button onClick={() => handleClickDelete(item)} className="btn btn-danger btn-circle btn-sm">
            <i className="fas fa-trash"></i>
          </Button>
        </div>
      </td>
    </tr>
  )) : null;

  return (
    <div className="container-fluid">
      <div>
        {
          modal ? (
            <ModelPopup modal={modal} data={selectedItem} handleDeleteSubmit={handleDeleteSubmit} title={props.title} toggle={toggle}></ModelPopup>
          ) : null
        }
      </div>
      <h1 className="h3 mb-4 text-gray-800">{props.title}</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <a className="btn btn-primary pull-right" href={`/app/${props.path}/add/`}>+ Add {props.name}</a>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tableBody}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onDeleteMasterData: (data) => dispatch(deleteMasterData(data)),
  onGetMasterData: () => dispatch(getMasterData()),
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
