import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createComissionerateDirectReports, editComissionerateDirectReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const Clarification = [
    {id:'0',name:'Yes'},
    {id:'1',name:'No'},
]



const ComissionerateDirectReport = (props) => {
    const { districts,wards, user,wardsCategory, onCreateComissionerateDirectReports, onEditComissionerateDirectReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'case_file_received_on':'',
        'need_clarification':'',
        'clarification_sent_to_do_on':'',
        'clarification_received_from_do_on':'',
        'file_approval_date':'',
        'approval_sent_to_do':'',
       
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district' ,
            'date' ,
            'area_code',
            "area_name",
            'case_file_received_on',
            'need_clarification',
           
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditComissionerateDirectReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/comissionerate-direct-list/`);
            };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateComissionerateDirectReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Prosecution Sanction Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_file_received_on"
                            >Case File Received on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case File Received on`}
                                    name="case_file_received_on"
                                    value={values.case_file_received_on}
                                    error={errors.case_file_received_on}
                                    onChangeText={(text) =>handleTextChange("case_file_received_on", text)}
                                />
                            </div>
                        </div>
                           
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="need_clarification"
                         >Need Clarification *: </label>
                         <div className="col-sm-8">
                             <DropDownInput
                                 placeholder={`Need Clarification`}
                                 name="need_clarification"
                                 items={Clarification}
                                 action="dropdown"
                                 value={values.need_clarification}
                                 error={errors.need_clarification}
                                 onChange={(text) =>handleTextChange('need_clarification', text)}
                             />
                         </div>
                     </div>
                     {values && values.need_clarification.includes('Yes')? <>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="clarification_sent_to_do_on"
                            >Clarification Request Sent to DO on *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Clarification Request Sent to DO on`}
                                    name="court_name"
                                    value={values.clarification_sent_to_do_on}
                                    error={errors.clarification_sent_to_do_on}
                                    onChangeText={(text) =>handleTextChange('clarification_sent_to_do_on', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="clarification_received_from_do_on"
                            >Clarification Received From DO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Clarification Received From DO on`}
                                    name="clarification_received_from_do_on"
                                    value={values.clarification_received_from_do_on}
                                    error={errors.clarification_received_from_do_on}
                                    onChangeText={(text) =>handleTextChange('clarification_received_from_do_on', text)}
                                />
                            </div>
                        </div>
                     
                      </> :null}
                      {values && values.need_clarification.includes('No')? <>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="file_approval_date"
                            >Direct Case File Approved On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Case File Approved On`}
                                    name="file_approval_date"
                                    value={values.file_approval_date}
                                    error={errors.file_approval_date}
                                    onChangeText={(text) =>handleTextChange('file_approval_date', text)}
                                />
                            </div>
                        </div><div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="approval_sent_to_do"
                            >Direct Case File Recommended to DO On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Direct Case File Recommended to DO On`}
                                    name="approval_sent_to_do"
                                    value={values.approval_sent_to_do}
                                    error={errors.approval_sent_to_do}
                                    onChangeText={(text) =>handleTextChange('approval_sent_to_do', text)}
                                />
                            </div>
                        </div>
                        </> :null}
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateComissionerateDirectReports: (data) => dispatch(createComissionerateDirectReports(data)),
    onEditComissionerateDirectReports: (data, record_id) => dispatch(editComissionerateDirectReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(ComissionerateDirectReport);