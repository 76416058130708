import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { connect } from "react-redux";
import {
  useParams
} from "react-router-dom";
import { getBlogs } from '../components/reducers/actions';

const Blogs = (props) => {

  const { blogs, onGetBlogs } = props;
  const [blog, setBlog] = useState({});
  let { slug } = useParams();

  useEffect(() => {
    console.log(props)
 
    const getAssets = async () => {
      await onGetBlogs();
    }
    getAssets();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(slug && blogs){
      var blogObj = blogs.find(o => o.slug === slug);
      var bl = blogObj?.content;
      console.log(bl)
      if(bl.includes('https://foodsafety.tn.gov.in/api/api/v1/media/images/')){
        setBlog(blogObj);
      }
      else{
        var c= bl.replaceAll('/tnapp/media/images/', 'https://foodsafety.tn.gov.in/api/api/media/images/')
        blogObj.content = c
       setBlog(blogObj);
      }
      
    }
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <Header activeMenu="Blogs"  />
      <section>
        <div className="blog-section">
          <div className="blog-header-title">
            <h1 className="h2 mb-4 text-gray-800">{blog.title}</h1>
          </div>
          <div className="blog-body" dangerouslySetInnerHTML={{ __html: blog.content }} />
        </div>
      </section>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  onGetBlogs: () => dispatch(getBlogs()),
});

const mapStateToProps = state => ({
  blogs: state.home.blogs,
});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);