import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createAdjudicationAppealReports, editAdjudicationAppealReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const CaseFiled = [
    {id:'0',name:'Yes'},
    {id:'1',name:'No'},
]

const FiledBy=[
    {id:'0', name:'FSO'},
    {id:'1', name:'DO'},
    {id:'2', name:'FBO'},
    {id:'3', name:'Others'},
]
const Status=[
    {id:'0', name:'Convicted'},
    {id:'1', name:'Aquitted'},

    ]
    
const Court = [
    {id:'0', name:'High Court'},
    {id:'1', name:'Appealate Tribunal'},
]
const AdjudicationAppealReport = (props) => {
    const { districts,wards, user,wardsCategory, onCreateAdjudicationAppealReports, onEditAdjudicationAppealReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'other_case_filed_by':'',
        'fbo_filed_for_appeal':'',
        'case_file_filed_by' : '',
        'court_name':'',
        'date_act_court_date':'', 
        'case_number':'',
        'interim_order_generated':'',
        'order_upload':'',
        'appeal_filed_at':'',
        'judgement_status':'',
        'jugdgement_upload':'',
        'whether_appeal_filed':'',
        'appeal_filed_by':'',
        'appeal_case_no':'',
        'appeal_court_name':'',
        'judgement_status_of':'',
        'convicted_with_penalty':'',
    

       
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do")||user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district' ,
            'date' ,
            'area_code',
            "area_name",
            'fbo_filed_for_appeal',
            'case_file_filed_by',
            'court_name',
            'case_number',
            'interim_order_generated',
            'appeal_filed_at',
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditAdjudicationAppealReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/adjudication-appeal-list/`);
            };

        } else { 
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateAdjudicationAppealReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800"> Adjudication Appeal Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do")||user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District <span style={{color:'red'}}>*</span>: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *:</label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fbo_filed_for_appeal"
                            > Whether Case Filed For Appeal *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`FBO Filed for Appeal`}
                                    items={CaseFiled}
                                    action="dropdown"
                                    name="fbo_filed_for_appeal"
                                    value={values.fbo_filed_for_appeal}
                                    error={errors.fbo_filed_for_appeal}
                                    onChange={(text) =>handleTextChange("fbo_filed_for_appeal", text)}
                                />
                            </div>
                        </div>
                        {values && values.fbo_filed_for_appeal.includes('Yes') ? <> 

                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="appeal_filed_at"
                            >Appeal Filed At *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Appeal Filed at`}
                                    name="appeal_filed_at"
                                    items={Court}
                                    action="dropdown"
                                    value={values.appeal_filed_at}
                                    error={errors.appeal_filed_at}
                                    onChange={(text) =>handleTextChange('appeal_filed_at', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="interim_order_generated"
                            >Whether Interim Order Generated : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Select`}
                                    name="interim_order_generated"
                                    items={CaseFiled}
                                    action="dropdown"
                                    value={values.interim_order_generated}
                                    error={errors.interim_order_generated}
                                    onChange={(text) =>handleTextChange('interim_order_generated', text)}
                                />
                            </div>
                        </div>
                        {values && values.interim_order_generated.includes('Yes') ? <>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="order_upload"
                            >Interim Order Upload : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Select`}
                                    name="interim_order_generated"
                                    type="file"
                                    error={errors.order_upload}
                                    onChange={(e) =>handleTextChange('order_upload', e.target.files[0])}
                                />
                            </div>
                        </div> </> : null }
                        {values && values.appeal_filed_at ? <> 
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_file_filed_by"
                            >Case Filed By : </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Case Filed By`}
                                    name="case_file_filed_by"
                                    items={FiledBy}
                                    action="dropdown"
                                    value={values.case_file_filed_by}
                                    error={errors.case_file_filed_by}
                                    onChange={(text) =>handleTextChange('case_file_filed_by', text)}
                                />
                            </div>
                        </div>
                        {values && values.case_file_filed_by.includes('Others') ? 
                         <div className="form-group row">
                         <label className="control-label col-sm-4 text-label" htmlFor="other_case_filed_by"
                         ></label>
                         <div className="col-sm-8">
                             <TextInput
                                 placeholder={`Case Filed By`}
                                 name="other_case_filed_by"
                                 value={values.other_case_filed_by}
                                 error={errors.other_case_filed_by}
                                 onChange={(e) =>handleTextChange('other_case_filed_by', e.target.value)}
                             />
                         </div>
                     </div>
                        :null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="court_name"
                            > Appeal Case Filed Court Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case Filed Court Name`}
                                    name="court_name"
                                    value={values.court_name}
                                    error={errors.court_name}
                                    onChange={(e) =>handleTextChange('court_name', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_act_court_date"
                            >Appeal Case Filed  at Court On *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Appeal Case Filed at Court On`}
                                    name="date_act_court_date"
                                    value={values.date_act_court_date}
                                    error={errors.date_act_court_date}
                                    onChangeText={(text) =>handleTextChange('date_act_court_date', text)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="case_number"
                            >Case Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Case Number`}
                                    name="case_number"
                                    value={values.case_number}
                                    error={errors.case_number}
                                    onChange={(e) =>handleTextChange('case_number', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_first_hearing_date"
                            >First Hearing Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`First Hearing Date`}
                                    name="date_first_hearing_date"
                                    value={values.date_first_hearing_date}
                                    error={errors.date_first_hearing_date}
                                    onChangeText={(text) =>handleTextChange('date_first_hearing_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_next_hearing_date"
                            >Next Hearing Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next Hearing Date`}
                                    name="date_next_hearing_date"
                                    value={values.date_next_hearing_date}
                                    error={errors.date_next_hearing_date}
                                    onChangeText={(text) =>handleTextChange('date_next_hearing_date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_judgement_date"
                            >Judgement Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Next Hearing Date`}
                                    name="date_judgement_date"
                                    value={values.date_judgement_date}
                                    error={errors.date_judgement_date}
                                    onChangeText={(text) =>handleTextChange('date_judgement_date', text)}
                                />
                            </div>
                        </div>
                       { values && values.appeal_filed_at.includes('High Court') ?  <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="judgement_status"
                            >Judgement Status *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Judgement Status`}
                                    name="judgement_status"
                                    items={Status}
                                    action="dropdown"
                                    value={values.judgement_status}
                                    error={errors.judgement_status}
                                    onChange={(text) =>handleTextChange('judgement_status', text)}
                                />
                            </div>
                        </div> : null}
                        { values && values.appeal_filed_at.includes('Appealate Tribunal') ? <>
                          <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="judgement_status_of"
                            >Judgement Status *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Judgement Status`}
                                    name="judgement_status"
                                    items={Status}
                                    action="dropdown"
                                    value={values.judgement_status_of}
                                    error={errors.judgement_status_of}
                                    onChange={(text) =>handleTextChange('judgement_status_of', text)}
                                />
                            </div>
                        </div>
                        {values && values.judgement_status_of.includes('Aquitted') ? 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="convicted_with_penalty"
                            >Convicted With Penalty : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Convicted With Penalty`}
                                    name="convicted_with_penalty"
                                    value={values.convicted_with_penalty}
                                    error={errors.convicted_with_penalty}
                                    onChange={(e) =>handleTextChange('convicted_with_penalty', e.target.value)}
                                />
                            </div>
                        </div> : null }
                         </> : null}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="jugdgement_upload"
                            >Judgement Upload : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Judgement Upload`}
                                    name="jugdgement_upload"
                                    type="file"
                                    error={errors.jugdgement_upload}
                                    onChange={(e) =>handleTextChange('jugdgement_upload', e.target.files[0])}
                                />
                            </div>
                        </div>
                        </> : null}
                        </> : null}
                        
                      
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={ values.fbo_filed_for_appeal =="No" ? false: disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateAdjudicationAppealReports: (data) => dispatch(createAdjudicationAppealReports(data)),
    onEditAdjudicationAppealReports: (data, record_id) => dispatch(editAdjudicationAppealReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(AdjudicationAppealReport);