import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ModalPopup = (props) => {
  const {
    modal,
    toggle,
    data,
    title,
    handleDeleteSubmit,
  } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t(`Delete ${title}`)}</ModalHeader>
        <ModalBody>
          {t(`Are you sure you want to delete the ${title}?`)}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={toggle}>{t('Cancel')}</Button>{' '}
          <Button color="danger" onClick={() => handleDeleteSubmit(data)}>{t('Delete')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalPopup;