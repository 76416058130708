import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import DropDownInput from "../components/inputs/DropDownInput";
import TextInput from "../components/inputs/TextInput";
import {
  profileUpdate,
  getMasterData,
  generateOtp,
  setLoading,
} from "../components/reducers/actions";
import { validateEmail } from "../utils/validateEmail";
import toast from "react-toast-notification";
import { getLangCode } from "../utils/config";
import PasswordInput from "../components/inputs/PasswordInput";
import UploadImageInput from "../components/inputs/UploadImageInput";
import { Link } from "react-router-dom";
import bcrypt from 'bcryptjs'
import { Helmet } from "react-helmet"
var CryptoJS = require("crypto-js");

const Profile = (props) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [updateField, setupdateField] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const {
    districts,
    wards,
    user,
    updateProfile,
    onGetMasterData,
    onGenerateOtp,
  } = props;
  const { t, i18n } = useTranslation();
  const languages = [
    {
      value: "tamil",
      label: "தமிழ்",
    },
    {
      value: "english",
      label: "English",
    },
  ];

  const generateOtp = async (phoneNumber) => {
    phoneNumber = phoneNumber || values.phone_number;
    const data = { phone_number: phoneNumber };
    const resp = await onGenerateOtp(data);
    if (resp && resp.data === "success") {
      toast.success("OTP Sent successfully");
      setShowOtp(true);
    } else {
      let message = "Error generating OTP. Please try again";
      if (resp && resp.error && resp.error.non_field_errors) {
        message = resp.error.non_field_errors[0];
      } else if (resp && resp.error && resp.error.phone_number) {
        message = "Your account does not exist. Please register your account.";
      }
      if (resp && resp.error) {
        toast.error(message);
      }
      if (resp && resp.error && resp.error.phone_number) {
        props.history.push("/register");
      }
    }
    setShowOtp(true);
  };

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ["phone_number", "name", "email", "language"];
    if (values.new_password) {
      keys = [...keys, "new_password", "confirm_password", "otp"];
    }

    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        updatedErrors[key] = " ";
      }
    });

    if (values.email && !validateEmail(values.email)) {
      updatedErrors.email = "Invalid email address";
    }

    if (
      values.phone_number &&
      (!values.phone_number || values.phone_number.length !== 10)
    ) {
      updatedErrors.phone_number = "Invalid phone number";
    }

    if (values.new_password && values.new_password.length < 6) {
      updatedErrors.new_password = "Minimum 6 characters required";
    }

    if (
      values.new_password &&
      values.confirm_password &&
      values.confirm_password !== values.new_password
    ) {
      updatedErrors.confirm_password =
        "Confirm password not match with password";
    }

    if (
      values &&
      values.new_password &&
      values.confirm_password &&
      values.confirm_password === values.new_password &&
      !showOtp
    ) {
      generateOtp();
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  };

  useEffect(() => {
    if (user) {
      const wardNames = [];
      wards.forEach((item) => {
        // eslint-disable-next-line
        user.wards.map((ward_no) => {
          if (ward_no === item.id) {
            wardNames.push(item.ward_name);
          }
        });
      });
      const userData = { ...user, wards: wardNames };
      setValues(userData);
    }
    // eslint-disable-next-line
  }, [user]);

  const getDistrict = (district) => {
    let districtName = null;
    districts &&
      districts.forEach((item) => {
        if (item.id === district) {
          districtName = item.name;
        }
      });
    return districtName;
  };

  const fetchMaterData = async () => {
    await onGetMasterData();
  };

  const handleSubmitButton = async () => {
    props.onLoading(true);
    const updatedErrors = validateValues(values);
    var message =values.new_password;
    var key ='AAAAAAAAAAAAAAAA'
    key = CryptoJS.enc.Utf8.parse(key);
    var encrypted = CryptoJS.AES.encrypt(message, key, {mode: CryptoJS.mode.ECB});
    encrypted =encrypted.toString();
    var message1 =values.confirm_password;
    var key1 ='AAAAAAAAAAAAAAAA'
    key1 = CryptoJS.enc.Utf8.parse(key1);
    var encrypted1 = CryptoJS.AES.encrypt(message1, key1, {mode: CryptoJS.mode.ECB});
    encrypted1 =encrypted1.toString();
    // const enc = bcrypt.hashSync(values.new_password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
    // const enc1 = bcrypt.hashSync(values.confirm_password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
    // console.log(enc)
    if (Object.keys(updatedErrors).length === 0) {
      var data = {};
      updateField.map((key) => {
        if (key === "profile_picture" && !values[key]) {
          data[key] = "";
        } else {
          data[key] = values[key];
        }
        return false;
      });
      data.new_password=encrypted;
      data.confirm_password=encrypted1
      console.log(data)

      const resp = await updateProfile(data);
      if (resp.response) {
        toast.success(t("Profile updated successfully."));
        setValues({});
        i18n.changeLanguage(getLangCode(data.language));
        fetchMaterData();
        props.onLoading(false);
         props.history.push("/app/complaints/");
      } else {
        let message = "";
        if (resp.errors) {
          setErrors(resp.errors);
        } else if (resp.errors && resp.errors.non_field_errors) {
          message = resp.errors.non_field_errors[0];
        } else if (resp.errors && resp.errors.message) {
          message = resp.errors.message;
        } else {
          message = "Something went wrong. Please try again";
        }
        props.onLoading(false);
        toast.error(message);
      }
    }
  };

  const handleTextChange = (key, value) => {
    let updatedValues = { ...values, [key]: value };
    if (!updateField.includes(key)) {
      setupdateField([...updateField, key]);
    }
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  const handleRemoveImages = (key, value) => {
    const updatedValues = { ...values };
    if (!updateField.includes(key)) {
      setupdateField([...updateField, key]);
    }
    delete updatedValues[key];
    setValues(updatedValues);
    validateValues(updatedValues);
  };

  return (
    <div className="container-fluid">
        <Helmet>
     <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.1.1/crypto-js.min.js"></script>
      </Helmet>
      <h1 className="h4 mb-4 text-gray-800">{t("Profile")}</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <Form>
            <div className="form-group col-sm-3">
              <UploadImageInput
                size={100}
                photo={values.profile_picture}
                name="profile_picture"
                enableDelete={true}
                handleRemoveImages={(text) =>
                  handleRemoveImages("profile_picture", text)
                }
                onChangeText={(value) =>
                  handleTextChange("profile_picture", value)
                }
              />
            </div>
            {user &&
            user.user_types &&
            (user.user_types.includes("do") ||
              user.user_types.includes("fso")) ? (
              <div className="form-group row">
                <div className="col-sm-2"> District : </div>
                <div className="col-sm-10 text-left">
                  {" "}
                  {values.district ? getDistrict(values.district) : null}{" "}
                </div>
              </div>
            ) : null}
            {user && user.user_types && user.user_types.includes("fso") ? (
              <div className="form-group row">
                <div className="col-sm-2"> Area Codes : </div>
                <div className="col-sm-10 text-left">
                  {" "}
                  {values.wards ? values.wards.join(", ") : null}{" "}
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <TextInput
                placeholder={t("Name")}
                type="text"
                name="name"
                value={values.name}
                error={errors.name}
                onChange={(e) => handleTextChange("name", e.target.value)}
              />
            </div>
            <div className="form-group">
              <TextInput
                placeholder={t("Email Address")}
                type="email"
                name="email"
                value={values.email}
                error={errors.email}
                onChange={(e) => handleTextChange("email", e.target.value)}
              />
            </div>
            <div className="form-group">
              <TextInput
                placeholder={t("10 digit Phone Number")}
                type="text"
                name="phone_number"
                value={values.phone_number}
                error={errors.phone_number}
                onChange={(e) =>
                  handleTextChange("phone_number", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <DropDownInput
                placeholder={t("Language")}
                type="text"
                name="language"
                value={values.language}
                error={errors.language}
                items={languages}
                action="language"
                onChange={(text) => handleTextChange("language", text)}
              />
            </div>
            <div className="form-group">
              <PasswordInput
                placeholder={t("New Password")}
                type="text"
                name="new_password"
                appIcon={true}
                value={values.new_password}
                error={errors.new_password}
                onChange={(e) =>
                  handleTextChange("new_password", e.target.value)
                }
              />
            </div>
            <div className="form-group">
              <PasswordInput
                placeholder={t("Confirm Password")}
                type="text"
                name="confirm_password"
                appIcon={true}
                value={values.confirm_password}
                error={errors.confirm_password}
                onChange={(e) =>
                  handleTextChange("confirm_password", e.target.value)
                }
              />
            </div>
            {showOtp && (
              <>
                <Link
                  className="small mb-2"
                  style={{ float: "right" }}
                  onClick={() => generateOtp()}
                  to="#"
                >
                  {t("Resend OTP")}
                </Link>
                <div className="form-group">
                  <TextInput
                    placeholder={t("OTP")}
                    type="text"
                    name="otp"
                    value={values.otp}
                    error={errors.otp}
                    onChange={(e) => handleTextChange("otp", e.target.value)}
                  />
                </div>
              </>
            )}
            <div className="form-group">
              <button
                onClick={() => props.history.push("/app/complaints/")}
                className="btn btn-success"
                type="button"
              >
                {t("Back")}
              </button>
              <button
                disabled={disableSubmit}
                onClick={handleSubmitButton}
                type="button"
                className="btn btn-success ml-3"
              >
                {t("Submit")}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGenerateOtp: (data) => dispatch(generateOtp(data)),
  updateProfile: (data) => dispatch(profileUpdate(data)),
  onGetMasterData: () => dispatch(getMasterData()),
  onLoading: (data) => dispatch(setLoading(data)),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  districts: state.home.districts,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
