import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import ModelPopup from "../../components/ModelPopup";
import { Link } from "react-router-dom";
import {
  deleteComplaint,
  getComplaints,
  getFsoOfficers,
} from "../../components/reducers/actions";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/FsoAccordion/FsoAccordion";
import Table from "../../components/Table/Table";
import LoadingOverlay from "react-loading-overlay";
import DropDownInput from "../../components/inputs/DropDownInput";

const ComplaintList = (props) => {
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const {
    complaints,
    foodIssues,
    user,
    fsoCategory,
    assignfsoComplaints,
    onComplaints,
    onGetFsoOfficers,
    assignComplaints,
    waitingComplaints,
    closedComplaints,
    unasignComplaints,
    onDeleteComplaint,
    districts,
  } = props;
  const [activeTab, setActiveTab] = useState(
    user && user.user_types.includes("fso") ? "3" : "1"
  );
  const { t, i18n } = useTranslation();
  const [pageClosed, setPageClosed] = useState(1);
  const [pageAssign, setPageAssign] = useState(1);
  const [pageAssignfso, setPageAssignfso] = useState(1);
  const [selectedFso, setSelectedFso] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageUnassign, setPageUnassign] = useState(1);
  const [pageWaiting, setPageWaiting] = useState(1);
  const [pageMyComplaint, setPageMyComplaint] = useState(1);
  const [activeAccordion, setActiveAccordion] = useState(-1);
  const [selectedDistrict, setSelectedDistrict]=useState('');
  const countPerPage = 10;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (user && user.user_types.includes("do")) {
      onGetFsoOfficers({
        fso_category: true,
      });
      if (props.history.location && props.history.location.state) {
        setActiveTab(props.history.location.state.tab);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleChagneDistrict=async(id)=>{
    setLoading(true);
    setSelectedDistrict(id)
    const resp= await onComplaints({
        page: pageClosed,
        per_page: countPerPage,
        is_base64: true,
        district:id,
        assign_status:user&&user.user_types&&user.user_types=='ho'&& activeTab==1?"unassign":"closed",
    })
    setLoading(false);
    console.log(resp)
  }

  const loadMoreClosedComplaint = async () => {
    setLoading(true);
    if (activeTab === "4") {
      if(selectedDistrict){
        await onComplaints({
          page: pageClosed,
          per_page: countPerPage,
          is_base64: true,
          assign_status: "closed",
          district:selectedDistrict,
        });
      }
      else{
        await onComplaints({
          page: pageClosed,
          per_page: countPerPage,
          is_base64: true,
          assign_status: "closed",
        });
      }
      setLoading(false);
    }
  };

  const loadMoreAssignComplaint = async () => {
    setLoading(true);
    if (activeTab === "3") {
      await onComplaints({
        page: pageAssign,
        per_page: countPerPage,
        is_base64: true,
        assign_status: "assign",
      });
      setLoading(false);
    }
  };

  const loadMoreUnssignComplaint = async () => {
    setLoading(true);
    
    if (activeTab === "1") {
      if(selectedDistrict){
        await onComplaints({
          page: pageUnassign,
          per_page: countPerPage,
          is_base64: true,
          assign_status:"unassign",
          district:selectedDistrict,
        });
      }
      else{
        await onComplaints({
          page: pageUnassign,
          per_page: countPerPage,
          is_base64: true,
          assign_status:"unassign",
        });
      }
      setLoading(false);
    }
  };

  const loadMoreWaitingComplaint = async () => {
    setLoading(true);
    if (activeTab === "2") {
      await onComplaints({
        page: pageWaiting,
        per_page: countPerPage,
        is_base64: true,
        assign_status: "waiting_final_status",
      });
      setLoading(false);
    }
  };

  const loadMoreMyComplaint = async () => {
    setLoading(true);
    if (activeTab === "5") {
      await onComplaints({
        page: pageMyComplaint,
        per_page: countPerPage,
        is_base64: true,
        assign_status: "my_complaints",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreClosedComplaint();
    // eslint-disable-next-line
  }, [pageClosed, activeTab]);

  useEffect(() => {
    loadMoreAssignComplaint();
    // eslint-disable-next-line
  }, [pageAssign, activeTab]);

  useEffect(() => {
    loadMoreUnssignComplaint();
    // eslint-disable-next-line
  }, [pageUnassign, activeTab]);

  useEffect(() => {
    loadMoreWaitingComplaint();
    // eslint-disable-next-line
  }, [pageWaiting, activeTab]);

  useEffect(() => {
    loadMoreMyComplaint();
    // eslint-disable-next-line
  }, [pageMyComplaint, activeTab]);

  const loadMoreUnssignComplaintFso = async () => {
    if (activeTab === "6") {
      setLoading(true);
      await onComplaints({
        page: pageAssignfso,
        per_page: countPerPage,
        is_base64: true,
        assign_status: "open",
        fso: selectedFso,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreUnssignComplaintFso();
    console.log(pageAssignfso, activeTab, selectedFso,)
    // eslint-disable-next-line
  }, [pageAssignfso, activeTab, selectedFso]);

  const handleAccordionClick = (value) => {
    setSelectedFso(value);
  };

  const handleActivateAccordion = (index) => {
    setActiveAccordion((prevState) => (prevState === index ? -1 : index));
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "82px",
        fontSize: "18px",
        color: "#858796",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        minHeight: "72px",
        marginTop: "20px",
        fontWeight: "bold",
        color: "#1589FF",
        fontSize: "18px",
        background: "#e1eef0",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  var columns = [
    {
      name: t("Complaint No"),
      cell: (row) => <div>{row.complaint_number}</div>,
      selector: "complaint_number",
      sortable: true,
    },
    {
      name: t("Name"),
      cell: (row) => <div>{row.name_of_restaurant}</div>,
      selector: "name_of_restaurant",
      sortable: true,
    },
    {
      name: t("Complaint"),
      cell: (row) => <div>{getIssueOfFoodName(row.issue_of_food)}</div>,
      selector: "issue_of_food",
      sortable: true,
    },
    // {
    //   name: t('Status'),
    //   cell: row => <div>{
    //     row.status === 'in_progress' ? (
    //       <span key={row.id} style={{ color: 'orange' }}>{t('In Progress')}</span>
    //     ) : [(
    //       row.status === 'closed' ? (
    //         <span key={row.id} style={{ color: 'red' }}>{t('Closed')}</span>
    //       ) : <span key={row.id} style={{ color: 'green' }}>{t('New')}</span>
    //     )]
    //   }</div>,
    //   selector: 'status',
    //   sortable: true,
    // },
    {
      name: t("Current Status"),
      cell: (row) => <div>{row.current_status && row.current_status == 'Waiting to Allocate'? `${t("toAllocate")}`:row.current_status == `Waiting for do's approval` ? `${t("waiting")}`:row.current_status == `Inprogress` ? `${t("Inprogress")}`:row.current_status == `closed` ? `${t("Closed")}`:row.current_status}</div>,
      selector: "current_status",
      sortable: true,
    },
    {
      name: t("Created At"),
      cell: (row) => (
        <div>
          {moment(row.created_at.toString()).format("DD-MM-YYYY hh:mm A")}
        </div>
      ),
      selector: "created_at",
      sortable: true,
    },
    {
      name: t("Action"),
      cell: (row) => (
        <div className="action__area">
          <Link
            to={{
              pathname: `/app/complaints/${row.id}`,
              state: {
                complaint: row,
                hideActionMycomplaints: activeTab && activeTab === "5",
              },
            }}
          >
            <Button
              className="btn btn-info btn-sm"
              title={
                user &&
                user.user_types &&
                user.user_types.includes("fso") &&
                activeTab === "3"
                  ? row.current_status ==`Waiting for do's approval`?'View Inspection':t("Start Inspection")
                  : t("View Complaint")
              }
            >
              {user &&
              user.user_types &&
              user.user_types.includes("fso") &&
              activeTab === "3"
                ? row.current_status ==`Waiting for do's approval`?'View Inspection': t("Start Inspection")
                : t("View Complaint")}
            </Button>
          </Link>
          {/* {
          activeTab === '5' ? (
            <>
              <Link to={
                {
                  pathname: `/app/complaints/${row.id}/edit/`,
                  state: {
                    'complaint': row
                  }
                }
              }>
                <Button className="btn btn-success btn-circle btn-sm" title={t("Edit Complaint")}>
                  <i className="fas fa-edit"></i>
                </Button>
              </Link>
              <Button onClick={() => handleClickDelete(row)} title={t('Delete Complaint')} className="btn btn-danger btn-circle btn-sm">
                <i className="fas fa-trash"></i>
              </Button>
            </>
          ) : null
        } */}
        </div>
      ),
    },
  ];

  const getIssueOfFoodName = (issueId) => {
    let issueOfFood = null;
    foodIssues &&
      foodIssues.map((item) => {
        if (issueId && item.id === issueId) {
          issueOfFood = item.name;
        }
        return false;
      });
    return issueOfFood;
  };

  // const handleClickDelete = (item) => {
  //   setSelectedItem(item);
  //   toggle();
  // }

  const handleDeleteSubmit = async (item) => {
    const resp = await onDeleteComplaint({
      complaint_id: item.id,
    });
    if (resp && resp.data === "success") {
      onComplaints({
        is_base64: true,
      });
      setSelectedItem({});
      setModal(false);
    }
  };

  const toggle = () => setModal(!modal);

  return (
    <div className="container-fluid">
      {modal ? (
        <ModelPopup
          modal={modal}
          data={selectedItem}
          handleDeleteSubmit={handleDeleteSubmit}
          title="Complaint"
          toggle={toggle}
        ></ModelPopup>
      ) : null}
      <div className="mb-4">
        <div className="py-3">
          <h1 className="h4 mb-4 text-gray-800">{t("Complaints")}</h1>
          <Link
            className="btn btn-primary-green pull-right"
            to="/app/complaints/add/"
          >
            {t("+ Add New Complaint")}
          </Link>
        </div>
      {user&&user.user_types&&user&&user.user_types=='ho' && (activeTab==1 || activeTab==4)?  <div>
        <div className="form-group row">
                                <label className="control-label col-sm-2 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-4 ml-md-n5 pl-md-n4">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={selectedDistrict}
                                        items={districts}
                                        onChange={(text) => handleChagneDistrict(text)}
                                    />
                                </div>
                            </div>
        </div>:null}
        <div
          className="mt-5"
          style={{ fontSize: i18n.language === "ta" ? 15 : null }}
        >
          <Nav tabs>
            {user && user.user_types.includes("fso") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    {t("Open Complaints")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    {t("Closed Complaints")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    {t("My Complaints")}
                  </NavLink>
                </NavItem>
              </>
            ) : null}
            {user && user.user_types.includes("do") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    {t("Complaints to be Allocated")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    {t("Complaints waiting for final status")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      toggleTab("6");
                    }}
                  >
                    {t("Open Complaints")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    {t("Closed Complaints")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    {t("My Complaints")}
                  </NavLink>
                </NavItem>
              </>
            ) : null}
            {user && user.user_types.includes("ho") ? (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    {t("Open Complaints")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "4" })}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    {t("Closed Complaints")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      toggleTab("5");
                    }}
                  >
                    {t("My Complaints")}
                  </NavLink>
                </NavItem>
              </>
            ) : null}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {unasignComplaints && unasignComplaints.results ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={unasignComplaints.results}
                      count={unasignComplaints.count}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageUnassign(page)}
                    />
                  </div>
                ) : null}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {waitingComplaints && waitingComplaints.results ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={waitingComplaints.results}
                      count={waitingComplaints.count}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageWaiting(page)}
                    />
                  </div>
                ) : null}
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {assignComplaints && assignComplaints.results ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={assignComplaints.results}
                      count={assignComplaints.count}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageAssign(page)}
                    />
                  </div>
                ) : null}
              </div>
            </TabPane>
            <TabPane tabId="4">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {closedComplaints && closedComplaints.results ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={closedComplaints.results}
                      count={closedComplaints.count}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageClosed(page)}
                    />
                  </div>
                ) : null}
              </div>
            </TabPane>
            <TabPane tabId="5">
              <div className="card shadow mb-4">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                {complaints && complaints.results ? (
                  <div className="card-body">
                    <Table
                      columns={columns}
                      data={complaints.results}
                      count={complaints.count}
                      countPerPage={countPerPage}
                      customStyles={customStyles}
                      handlePageChange={(page) => setPageMyComplaint(page)}
                    />
                  </div>
                ) : null}
              </div>
            </TabPane>
            <TabPane tabId="6">
              <div className="card shadow mb-2">
                <LoadingOverlay
                  active={loading}
                  className="secondary__loader"
                  spinner
                  text="Loading..."
                />
                <div className="card-body">
                  {fsoCategory && fsoCategory.length > 0
                    ? fsoCategory.map((item, idx) => {
                        return (
                          item.complaints_count > 0 && (
                            <div
                              key={idx}
                              className="form-group mt-2"
                              style={{ padding: "5px 25px" }}
                            >
                              <Accordion
                                title={`${capitalize(
                                  item.fso.name
                                )} - Complaints(${item.complaints_count})`}
                                data_id={item.fso.id}
                                index={idx}
                                activeAccordion={activeAccordion}
                                handleAccordionClick={handleAccordionClick}
                                activateAccordion={() =>
                                  handleActivateAccordion(idx)
                                }
                              >
                                <div className="card shadow">
                                  {assignfsoComplaints &&
                                  assignfsoComplaints.results ? (
                                    <div className="card-body">
                                      <Table
                                        columns={columns}
                                        data={assignfsoComplaints.results}
                                        count={assignfsoComplaints.count}
                                        countPerPage={countPerPage}
                                        customStyles={customStyles}
                                        handlePageChange={(page) =>
                                          setPageAssignfso(page)
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </Accordion>
                            </div>
                          )
                        );
                      })
                    : null}
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onDeleteComplaint: (data) => dispatch(deleteComplaint(data)),
  onComplaints: (data) => dispatch(getComplaints(data)),
  onGetFsoOfficers: (data) => dispatch(getFsoOfficers(data)),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
  districts:state.home.districts,
  complaints: state.home.complaints,
  assignComplaints: state.home.assignComplaints,
  closedComplaints: state.home.closedComplaints,
  unasignComplaints: state.home.unasignComplaints,
  waitingComplaints: state.home.waitingComplaints,
  foodIssues: state.home.foodIssues,
  fsoCategory: state.home.fsoCategory,
  assignfsoComplaints: state.home.assignfsoComplaints,
});

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintList);
