import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createNumberReports, editNumberReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';

const Type =[
    {id:"0", name:"Play Videos in Cinema hall/Public Places"},
    {id:"1", name:"E-innovative Activities"},
    {id:"2", name:"Digital Media Outreach"},
    {id:"3", name:"Promoting ERC"},
    
]


const NumberReport = (props) => {
    const { districts, user,wards, onCreateNumberReports,ongetDist, wardsCategory,onEditNumberReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'type' : '',
        'video_visibility':null,
        'innovative_activity':null,
        'activity_count':null,
        'media_impressions':null,
        'erc_visibility':null,
        
               
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [
            'district', 
            'date', 
            'type',
        'area_name',
        'area_code'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {      
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditNumberReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/number-list/`);
            };

        } else { 
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateNumberReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">ERC Activity Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area Name`}
                                    type="text"
                                    name="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) =>handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Month *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    showMonthYearPicker
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                    
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type"
                            >Type *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type`}
                                    type="text"
                                    name="type"
                                    action="dropdown"
                                    items={Type}
                                    value={values.type}
                                    error={errors.type}
                                    onChange={(text) =>handleTextChange("type", text)}
                                />
                            </div>
                        </div>
                        { values && values.type.includes('Play Videos in Cinema hall/Public Places')? (
                         
                                <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="video_visibility"
                            >Number Of Visibilty In Public Places During the Month: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={` No of visibility in public places `}
                                    type="number"
                                    name="video_visibility"
                                    value={values.video_visibility}
                                    error={errors.video_visibility}
                                    onChange={(e) =>handleTextChange("video_visibility", e.target.value)}
                                />
                            </div>
                        </div>
                     ) : null}
                        { values && values.type.includes('E-innovative Activities')? (
                           <div>
                                <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="innovative_activity"
                            >Name Of the Activity : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name Of the activity`}
                                    type="text"
                                    name="innovative_activity"
                                    value={values.innovative_activity}
                                    error={errors.innovative_activity}
                                    onChange={(e) =>handleTextChange("innovative_activity", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="activity_count"
                            >Number Of E-Innovative Activities Done During The Month : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of E-innovative Activities Done During the month`}
                                    type="number"
                                    name="activity_count"
                                    value={values.activity_count}
                                    error={errors.activity_count}
                                    onChange={(e) =>handleTextChange("activity_count", e.target.value)}
                                />
                            </div>
                        </div>
                           </div>
                         ) : null}
                          { values && values.type.includes('Digital Media Outreach')? (
                        
                                <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="media_impressions"
                            >Number Of Digital Media Impressions During The Month : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`No of impressions during the month`}
                                    type="number"
                                    name="media_impressions"
                                    value={values.media_impressions}
                                    error={errors.media_impressions}
                                    onChange={(e) =>handleTextChange("media_impressions", e.target.value)}
                                />
                            </div>
                        </div>
                         ) : null}
                        { values && values.type.includes('Promoting ERC')? (
                        
                        <div className="form-group row">
                    <label className="control-label col-sm-4 text-label" htmlFor="erc_visibility"
                    >Visibility Of Promoting ERC in Public Places During The Month : </label>
                    <div className="col-sm-8">
                        <TextInput
                            placeholder={`No of Visibility in public places`}
                            type="text"
                            name="erc_visibility"
                            value={values.erc_visibility}
                            error={errors.erc_visibility}
                            onChange={(e) =>handleTextChange("erc_visibility", e.target.value)}
                        />
                    </div>
                </div>
                 ) : null}
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateNumberReports: (data) => dispatch(createNumberReports(data)),
    onEditNumberReports: (data, record_id) => dispatch(editNumberReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,

});
  
export default connect(mapStateToProps, mapDispatchToProps)(NumberReport);