import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createSeizureReports, editSeizureReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import moment from "moment";

const FBO = [
    {id:"0", name:"Retailer"},
    {id:"1", name:"Distributor"},
    {id:"2", name:"Manufacturer"},
    {id:"3", name:"Transporter"},
    {id:"4", name:"Wholesaler"},
   ]

   const seizure = [
       {id:"0", name:"Banded Product"},
       {id:"1", name:"Food Product"},
       {id:"2", name:"other"},
  ]
const Banded=[
    {id:"0", name:"Plastic"},
    {id:"1", name:"Tobacco"},
    {id:"2", name:"Newspaper"},
]
const Food=[
    {id:"0", name:"Packed Food"},
    {id:"1", name:"Non-Packed"},
    {id:"2", name:"Prepared"},
]
const Section =[
    {id:"0", name:"Form I"},
    {id:"1", name:"Form II"},
    {id:"2", name:"Form III"},
    {id:"3", name:"Form IV"},
]

const Official = [
    {id:"0", name:"FSO"},
    {id:"1", name:"FBO"},
    {id:"2", name:"DO"},
    {id:"3", name:"BDO"},
    {id:"4", name:"Police/Thasildhar"},
    {id:"5", name:"Municipalty Officer"},
    {id:"6", name:"Others"}
]
const convertArray = val => val.map((v, idx)=>{
    return(
        [{id:`${idx}`, name:`${v}`}]
    )
})
const SeizureReport = (props) => {
    const { districts, user,wards, onCreateSeizureReports,wardsCategory,ongetDist, onEditSeizureReports } = props;
    const [values, setValues] = useState({
       
        'area_name':'',
        'area_code':'',
        'district' : '', 
        'date':'',
        'fbo_type':'',
        'name_of_the_fbo':'',
        'address_of_the_fbo':'',
        'name_of_the_shop':'',
        'name_of_the_owner':'',
        'product_details':'',
        'batch_no':'',
        'banded':'',
        'food':'',
        'num_of_units':'',
        'quantity_in_kg':'',
        'value_in_RS':'',
        'type_of_seizure':'',
        'default_found':'',
        'upload_documents':'',
        'seized_under_section':'',
        'details':'',
        'seized_under_custody_of':'',
        'name_of_official':'',
        'address_of_official':'',
        'other_type_of_seizure':'',
        'other_seized_under_custody_of':""

    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [field, setField] = useState(false);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])
    useEffect(() => {
        if (props.history.location && props.history.location.state) {

            const recordData = props.history.location.state.values;
            console.log(recordData)
            recordData.fbo_type=(props.history.location.state.values.fbo_type).split(",")
            recordData.seized_under_section=(props.history.location.state.values.seized_under_section).split(",")
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );
            
             console.log(recordData.fbo_type)
        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = [ 
            'area_name',
            'area_code',
            'district', 
            'date',
            'fbo_type',
            'name_of_the_fbo',
            'address_of_the_fbo',
            'name_of_the_shop',
            'name_of_the_owner',
            'quantity_in_kg',
            'value_in_RS',
            'default_found',
            'seized_under_custody_of',
            'name_of_official',
            'address_of_official'
        ];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });
    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditSeizureReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/seizure-list/`);
            };

        } else {       
            const data = { ...values}
            // data.fbo_type = JSON.stringify(values.fbo_type)
            // data.seized_under_section = JSON.stringify(values.seized_under_section)
            console.log(data);
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateSeizureReports(formData);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    const handleBlur = ()=>{
        if(values.activities === "9"){
                setField(true)
                console.log("set field");
        }
        else setField(false)
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800"> Food Product Seizure Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                            >Area Code *: </label>
                            <div className="col-sm-8">
                            <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                            >Area Name *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Area name`}
                                    type="text"
                                    name="area_name"
                                    id="area_name"
                                    value={values.area_name}
                                    error={errors.area_name}
                                    onChange={(e) => handleTextChange("area_name", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    type="text"
                                    name="date"
                                    id="date"
                                    value={values.date }
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange("date", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="fbo_type"
                            >FBO Category *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`FBO Category`}
                                    type="text"
                                    name="fbo_type"
                                    id="fbo_type"
                                    items={FBO}
                                    action={true}
                                    value={values.fbo_type}
                                    error={errors.fbo_type}
                                    onChange={(text) => handleTextChange("fbo_type", text)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_shop"
                            >Name Of the Shop *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the shop`}
                                    type="text"
                                    name="name_of_the_shop"
                                    id="name_of_the_shop"
                                    value={values.name_of_the_shop}
                                    error={errors.name_of_the_shop}
                                    onChange={(e) => handleTextChange("name_of_the_shop", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_fbo"
                            >Name Of the Owner/Partner *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the FBO`}
                                    type="text"
                                    name="name_of_the_fbo"
                                    id="name_of_the_fbo"
                                    value={values.name_of_the_fbo}
                                    error={errors.name_of_the_fbo}
                                    onChange={(e) => handleTextChange("name_of_the_fbo", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_the_fbo"
                            >Address Of the FBO with License/Registration Number *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Address of the FBO`}
                                    type="text"
                                    name="address_of_the_fbo"
                                    id="address_of_the_fbo"
                                    value={values.address_of_the_fbo}
                                    error={errors.address_of_the_fbo}
                                    onChange={(e) => handleTextChange("address_of_the_fbo", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_the_owner"
                            >Name of the Incharge *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the Incharge`}
                                    type="text"
                                    name="name_of_the_owner"
                                    id="name_of_the_owner"
                                    value={values.name_of_the_owner}
                                    error={errors.name_of_the_owner}
                                    onChange={(e) => handleTextChange("name_of_the_owner", e.target.value)}
                                />
                            </div>
                        </div> 
                      
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="type_of_seizure"
                            >Type of Product seizure: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Type of Product Seizure`}
                                    type="text"
                                    name="type_of_seizure"
                                    id="type_of_seizure"
                                    items={seizure}
                                    action="dropdown"
                                    value={values.type_of_seizure}
                                    error={errors.type_of_seizure}
                                    onChange={(text) => handleTextChange("type_of_seizure", text)}
                                />
                            </div>
                        </div> 
                        
                        {values && values.type_of_seizure.includes('Banded Product') ? (
                           <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="banded"
                           >Type of Banded Product</label>
                           <div className="col-sm-8">
                               <DropDownInput
                                   placeholder={`Type of Banded Product`}
                                   type="text"
                                   name="banded"
                                   id="banded"
                                   action="dropdown"
                                   items={Banded}
                                   value={values.banded}
                                   error={errors.banded}
                                   onChange={(text) => handleTextChange("banded", text)}
                               />
                           </div>
                       </div>  
                        ): null}
                        {values && values.type_of_seizure.includes('Food Product') ? (
                           <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="food"
                           >Type of Food Product</label>
                           <div className="col-sm-8">
                               <DropDownInput
                                   placeholder={`Type of Food Product`}
                                   type="text"
                                   name="food"
                                   id="food"
                                   action="dropdown"
                                   items={Food}
                                   value={values.food}
                                   error={errors.food}
                                   onChange={(text) => handleTextChange("food", text)}
                               />
                           </div>
                       </div>  
                        ): null}
                        {values && values.type_of_seizure.includes('other') ? (
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="other_type_of_seizure"
                            > </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Type of Seizure`}
                                    type="text"
                                    name="other_type_of_seizure"
                                    id="other_type_of_seizure"
                                    value={values.other_type_of_seizure}
                                    error={errors.other_type_of_seizure}
                                    onChange={(e) => handleTextChange("other_type_of_seizure",e.target.value)}
                                />
                            </div>
                        </div> 
                        ):null} {values && values.food.includes('Non-Packed') ? null : (<div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="product_details"
                            >Name of the Product *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the product`}
                                    type="text"
                                    name="product_details"
                                    id="product_details"
                                    value={values.product_details}
                                    error={errors.product_details}
                                    onChange={(e) => handleTextChange("product_details", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="batch_no"
                            >Batch No: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Batch No`}
                                    type="text"
                                    name="batch_no"
                                    id="batch_no"
                                    value={values.batch_no}
                                    error={errors.batch_no}
                                    onChange={(e) => handleTextChange("batch_no", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date_of_packing"
                            >Date of Packing </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date of packing`}
                                    name="date_of_packing"
                                    id="date_of_packing"
                                    value={values.date_of_packing}
                                    error={errors.date_of_packing}
                                    onChangeText={(text) => handleTextChange("date_of_packing", text)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="num_of_units"
                            >Number Of Units: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Number Of Units`}
                                    type="number"
                                    name="num_of_units"
                                    id="num_of_units"
                                    value={values.num_of_units}
                                    error={errors.num_of_units}
                                    onChange={(e) => handleTextChange("num_of_units", e.target.value)}
                                />
                            </div>
                        </div> </div>)}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="quantity_in_kg"
                            >Quantity in KG's *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Quantity in kg`}
                                    type="number"
                                    name="quantity_in_kg"
                                    id="quantity_in_kg"
                                    value={values.quantity_in_kg}
                                    error={errors.quantity_in_kg}
                                    onChange={(e) => handleTextChange("quantity_in_kg", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="value_in_RS"
                            >Value in RS *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Value in RS`}
                                    type="number"
                                    name="value_in_RS"
                                    id="value_in_RS"
                                    value={values.value_in_RS}
                                    error={errors.value_in_RS}
                                    onChange={(e) => handleTextChange("value_in_RS", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="seized_under_section"
                            >SeizedForm Details *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={`Seized Under Section of`}
                                    type="text"
                                    name="seized_under_section"
                                    id="seized_under_section"
                                    value={values.seized_under_section}
                                    error={errors.seized_under_section}
                                    onChange={(e) => handleTextChange("seized_under_section", e.target.value)}
                                />
                            </div>
                        </div> 
                
                           <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="seized_under_custody_of"
                            >Seized Under Custody of *: </label>
                            <div className="col-sm-8">
                                <DropDownInput
                                    placeholder={`Seized under custody of`}
                                    type="text"
                                    items={Official}
                                    action="dropdown"
                                    name="seized_under_custody_of"
                                    id="seized_under_custody_of"
                                    value={values.seized_under_custody_of}
                                    error={errors.seized_under_custody_of}
                                    onChange={(text) => handleTextChange("seized_under_custody_of", text)}
                                />
                            </div>
                        </div> 
                        {values && values.seized_under_custody_of.includes('Others') ? (
                           <div className="form-group row">
                           <label className="control-label col-sm-4 text-label" htmlFor="other_seized_under_custody_of"
                           ></label>
                           <div className="col-sm-8">
                               <TextInput
                                   placeholder={`Seized under custody of`}
                                   type="text"
                                   name="other_seized_under_custody_of"
                                   id="other_seized_under_custody_of"
                                   value={values.other_seized_under_custody_of}
                                   error={errors.other_seized_under_custody_of}
                                   onChange={(e) => handleTextChange("other_seized_under_custody_of", e.target.value)}
                               />
                           </div>
                       </div>  
                        ): null}
                        {values && values.seized_under_custody_of ? <>
                            <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="name_of_official"
                            >Name of the Official *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Name of the official`}
                                    type="text"
                                    name="name_of_official"
                                    id="name_of_official"
                                    value={values.name_of_official}
                                    error={errors.name_of_official}
                                    onChange={(e) => handleTextChange("name_of_official", e.target.value)}
                                />
                            </div>
                        </div>  <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="address_of_official"
                            >Address Of the Official *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Address of Official`}
                                    type="text"
                                    name="address_of_official"
                                    id="address_of_official"
                                    value={values.address_of_official}
                                    error={errors.address_of_official}
                                    onChange={(e) => handleTextChange("address_of_official", e.target.value)}
                                />
                            </div>
                        </div>  </> : null}
                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="default_found"
                            >Violations Found *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Default Found`}
                                    type="text"
                                    name="default_found"
                                    id="default_found"
                                    value={values.default_found}
                                    error={errors.default_found}
                                    onChange={(e) => handleTextChange("default_found", e.target.value)}
                                />
                            </div>
                        </div> 
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="upload_documents"
                            >Upload Documents : </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Upload Documents`}
                                    type="file"
                                    name="upload_documents"
                                    id="upload_documents"
                                    error={errors.upload_documents}
                                    onChange={(e) => handleTextChange("upload_documents", e.target.files[0])}
                                />
                            </div>
                        </div> 
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateSeizureReports: (data) => dispatch(createSeizureReports(data)),
    onEditSeizureReports: (data, record_id) => dispatch(editSeizureReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SeizureReport);