import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createDLACReports, editDLACReports } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment';


const Place=[
    {id:"0", name:"Commissionaerate"},
    {id:"1", name:"Food Safety Appellate Tribunal"},
    {id:"2", name:"Office of the Designated Officer"},
    {id:"3", name:"Food Analysis Laboratories"},
]
const Commissionerate = [
    {id:"0", name:"Commissioner"},
    {id:"1", name:"Director and Additional Commissioner"},
    {id:"2", name:"Deputy Director of Public Health and Preventive Medicine Grade II"},
    {id:"3", name:"Chief Accounts Officer"},
    {id:"4", name:"Deputy Director (Administration)"},
    {id:"5", name:"Deputy Director (Information Management)"},
    {id:"6", name:"Health Officer"},
    {id:"7", name:"Law Officer"},
    {id:"8", name:"Assistant Public Prosecutor Grade I"},
    {id:"9", name:"Assistant Public Prosecutor Grade II"},
    {id:"10", name:"Statistical Officer"},
    {id:"11", name:"Administrative Officer"},
    {id:"12", name:"Superindendent"},
    {id:"13", name:"Statistical Assistant"},
    {id:"14", name:"Assistant"},
    {id:"15", name:"Junior Assistant"},
    {id:"16", name:"Typist"},
    {id:"17", name:"Food Safety Officer"},
    {id:"18", name:"Steno Typist"},
    {id:"19", name:"Record Clerk"},
    {id:"20", name:"Driver"},
    {id:"21", name:"Office Assistant"},
    {id:"22", name:"Data Entry Operator(Out Sourcing)"},
    {id:"23", name:"Watchman(Out Sourcing)"},
    {id:"24", name:"Sweeper (Out Sourcing)"},
   ]
const  AppellateTribunal = [
        {id:"0", name:"Presiding Officer"},
        {id:"1", name:"Registrar"},
        {id:"2", name:"Assistant Public Prosecutor Grade I"},
        {id:"3", name:"Superindendent"},
        {id:"4", name:"Assistant"},
        {id:"5", name:"Steno Typist"},
        {id:"6", name:"Data Entry Operator (Out Sourcing)"},
        {id:"7", name:"Record Clerk"},
        {id:"8", name:"Driver"},
        {id:"9", name:"Duffadar"},
        {id:"10", name:"Office Assistant"},
]
const DesignatedOfficers=[
    {id:"0", name:"Designated Officer"},
    {id:"1", name:"Food Safety Officers"},
    {id:"2", name:"Assistant"},
    {id:"3", name:"Junior Assistant"},
    {id:"4", name:"Steno Typist"},
    {id:"5", name:"Driver"},
    {id:"6", name:"Office Assistant"},
    {id:"7", name:"Data Entry Operator(Out Sourcing)"},
]
const LabPost=[
    {id:"0", name:"Government Analyst"},
    {id:"1", name:"Public Analyst"},
    {id:"2", name:"Deputy Government Analyst"},
    {id:"3", name:"Senior Analyst"},
    {id:"4", name:"Junior Analyst"},
    {id:"5", name:"Superintendent"},
    {id:"6", name:"Accountant"},
    {id:"7", name:"Microbioloigst"},
    {id:"8", name:"Assistant Public Prosecutor Grade-II"},
    {id:"9", name:"Laboratory Technician Grade-I"},
    {id:"10", name:"Laboratory Technician Grade-II"},
    {id:"11", name:"Assistant"},
    {id:"12", name:"Junior Assistant"},
    {id:"13", name:"Steno-Typist"},
    {id:"14", name:"Typist"},
    {id:"15", name:"Record Clerk"},
    {id:"16", name:"Store Keeper"},
    {id:"17", name:"Electrician"},
    {id:"18", name:"Laboratory Attendant"},
    {id:"19", name:"Laboratory Attendant-IV"},
    {id:"20", name:"Office Assistant"},
    {id:"21", name:"Mazdoor"},
    {id:"22", name:"Watchman"},
    {id:"23", name:"Sweeper"},
    {id:"24", name:"Data Entry Operator (Out Sourcing)"},
   ]
const Labs =[
    {id:"0", name:"Lab-1"},
    {id:"1", name:"Lab-2"},
    {id:"2", name:"Lab-3"},
    {id:"3", name:"Lab-4"},
    {id:"4", name:"Lab-5"},
    {id:"5", name:"Lab-6"},
]
const StaffReport = (props) => {
    const { districts, user, onCreateDLACReports, onEditDLACReports } = props;
    const [values, setValues] = useState({
        'district': '',
        'place': '',
        'designation': '',
        'sanctioned': '',
        'position': '',
        'labs':'',
        'lab':'',


    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if (user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line        
    }, [])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'area_name', 'area_code'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
            if (!values[key] || values[key] === '') {
                updatedErrors[key] = ' ';
            }
        });
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {
        if (props.history.location && props.history.location.state) {
            const data = { ...values }
            const resp = await onEditDLACReports(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/target-list/${values.id}`, { records: values });
            };

        } else {
            const data = { ...values }
            setDisableSubmit(true);
            const resp = await onCreateDLACReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Target Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("ho") ? (<>

                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="place"
                                >Place: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Choose the place`}
                                        type="text"
                                        name="place"
                                        items={Place}
                                        action="dropdown"
                                        value={values.place}
                                        error={errors.place}
                                        onChange={(text) => handleTextChange("place", text)}
                                    />
                                </div>
                            </div>
                            {values && values.place.includes(0) ? (
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="designation"
                                >Designation: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Select Designation`}
                                        type="text"
                                        name="designation"
                                        items={Commissionerate}
                                        action="dropdown"
                                        value={values.designation}
                                        error={errors.designation}
                                        onChange={(text) => handleTextChange("designation", text)}
                                    />
                                </div>
                            </div>
                            ):null}
                          {values && values.place.includes(1) ? ( <>
                          <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="designation"
                                >Designation: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Select Designation`}
                                        type="text"
                                        name="designation"
                                        action="dropdown"
                                        items={AppellateTribunal}
                                        value={values.designation}
                                        error={errors.designation}
                                        onChange={(text) => handleTextChange("designation", text)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                             </>):null} 
                             {values && values.place.includes(2)? (
                                 <div className="form-group row">
                                 <label className="control-label col-sm-4 text-label" htmlFor="designation"
                                 >Designation: </label>
                                 <div className="col-sm-8">
                                     <DropDownInput
                                         placeholder={`Select Designation`}
                                         type="text"
                                         name="designation"
                                         action="dropdown"
                                         items={DesignatedOfficers}
                                         value={values.designation}
                                         error={errors.designation}
                                         onChange={(text) => handleTextChange("designation", text)}
                                     />
                                 </div>
                             </div>
                             ):null}
                             {values && values.place.includes(3) ? ( <>
                                <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="labs">Laboratory : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Select the Laboratory`}
                                        type="text"
                                        name="labs"
                                        action="dropdown"
                                        value={values.labs}
                                        error={errors.labs}
                                        items={Labs}
                                        onChange={(text) => handleTextChange("labs", text)}
                                    />
                                </div>
                            </div>
                          <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="designation"
                                >Designation: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Select Designation`}
                                        type="text"
                                        name="designation"
                                        action="dropdown"
                                        items={LabPost}
                                        value={values.designation}
                                        error={errors.designation}
                                        onChange={(text) => handleTextChange("designation", text)}
                                    />
                                </div>
                            </div>
                            
                             </>):null} 
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sanctioned"
                                >Sanctioned: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Sanctioned`}
                                        type="text"
                                        name="sanctioned"
                                        value={values.sanctioned}
                                        error={errors.sanctioned}
                                        onChange={(e) => handleTextChange("sanctioned", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="position"
                                >Position: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Position`}
                                        type="text"
                                        name="position"
                                        value={values.position}
                                        error={errors.position}
                                        onChange={(e) => handleTextChange("position", e.target.value)}
                                    />
                                </div>
                            </div>

                        </>) : null}

                        {user && user.user_types && user.user_types.includes("do") ? (<>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="designation"
                                >Designation: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Designation`}
                                        type="text"
                                        name="designation"
                                        value={values.designation}
                                        error={errors.designation}
                                        onChange={(e) => handleTextChange("area_code", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sanctioned"
                                >Sanctioned: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Sanctioned`}
                                        type="text"
                                        name="sanctioned"
                                        value={values.sanctioned}
                                        error={errors.sanctioned}
                                        onChange={(e) => handleTextChange("sanctioned", e.target.value)}
                                    />
                                </div>
                            </div><div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="position"
                                >Position: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Position`}
                                        type="text"
                                        name="position"
                                        value={values.position}
                                        error={errors.position}
                                        onChange={(e) => handleTextChange("position", e.target.value)}
                                    />
                                </div>
                            </div>

                        </>) : null}

                        {user && user.user_types && user.user_types.includes("fso") ? (<>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="lab"
                                >Lab Place: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Lab`}
                                        type="text"
                                        name="lab"
                                        value={values.lab}
                                        error={errors.lab}
                                        onChange={(e) => handleTextChange("lab", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="designation"
                                >Designation: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Designation`}
                                        type="text"
                                        name="designation"
                                        value={values.designation}
                                        error={errors.designation}
                                        onChange={(e) => handleTextChange("designation", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="sanctioned"
                                >Sanctioned: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Sanctioned`}
                                        type="text"
                                        name="sanctioned"
                                        value={values.sanctioned}
                                        error={errors.sanctioned}
                                        onChange={(e) => handleTextChange("area_code", e.target.value)}
                                    />
                                </div>
                            </div><div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="position"
                                >Position: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Position`}
                                        type="text"
                                        name="position"
                                        value={values.position}
                                        error={errors.position}
                                        onChange={(e) => handleTextChange("position", e.target.value)}
                                    />
                                </div>
                            </div>

                        </>) : null}
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateDLACReports: (data) => dispatch(createDLACReports(data)),
    onEditDLACReports: (data, record_id) => dispatch(editDLACReports(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffReport);