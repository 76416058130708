import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const FSOSDetails = (props) => {
    const { districts, traderTypes, wards } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            setRecords(recordData);
        } else if (slug) {
            console.log(slug);
        }  
        // eslint-disable-next-line  
    }, [])

    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };

    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
        return wardName;
    }
  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">FSO - Sample Details Record Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of the Record</th>
                <td className="text-left">{moment(records.date).format("l")}</td>
              </tr>
                <tr>
                  <th>Type of Trader </th>
                 <td className="text-left">{records.type_of_trader}</td>
                </tr>
                {records.other_type_of_trader && <tr>
                  <th>Type of Trader - Other </th>
                 <td className="text-left">{records.other_type_of_trader}</td>
                </tr>}
                {records.name_of_respondant_premisis && <tr>
                  <th>Name of the Respondant Premisis Inspected </th>
                 <td className="text-left">{records.name_of_respondant_premisis}</td>
                </tr>}
                {records.address_of_respondant_premisis && <tr>
                  <th>Address Of the Respondant Premisis Inspected  </th>
                 <td className="text-left">{records.address_of_respondant_premisis}</td>
                </tr>}  
                {records.name_of_the_respondant && <tr>
                  <th>Name of the Respondant </th>
                 <td className="text-left">{records.name_of_the_respondant}</td>
                </tr>}
                {records.address_of_respondant_residence && <tr>
                  <th>Address of the Respondant Residence </th>
                 <td className="text-left">{records.address_of_respondant_residence}</td>
                </tr>}
                {records.upload_documents_id_proof && <tr>
                  <th>Upload documents of ID Proof if any </th>
                 <td className="text-left">{records.upload_documents_id_proof}</td>
                </tr>}
                {records.FSSAI_license_number && <tr>
                  <th>FSSAI License Number </th>
                 <td className="text-left">{records.FSSAI_license_number}</td>
                </tr>}
                {records.upload_document_of_license && <tr>
                  <th>Upload Document of License </th>
                 <td className="text-left">{records.upload_document_of_license}</td>
                </tr>}
                {records.type_of_fbo && <tr>
                  <th>Type of FBO Premisis Inspected</th>
                 <td className="text-left">{records.type_of_fbo}</td>
                </tr>}
                {records.fbo_firm && <tr>
                  <th>Choose FBO Premisis Owner</th>
                 <td className="text-left">{records.fbo_firm}</td>
                </tr>}
                {records.nominee && <tr>
                  <th>Partner/Nominee </th>
                 <td className="text-left">{records.nominee}</td>
                </tr>}
                {records.nominees && <tr>
                  <th>Director/Nominee</th>
                 <td className="text-left">{records.nominees}</td>
                </tr>}
                {records.type_of_sample_lifted && <tr>
                  <th>Type of Sample Lifted</th>
                 <td className="text-left">{records.type_of_sample_lifted}</td>
                </tr>}
                {records.form_V_given_to && <tr>
                  <th>Form V-A Given To</th>
                 <td className="text-left">{records.form_V_given_to}</td>
                </tr>}
                {records.upload_documents_of_V_A && <tr>
                  <th>Upload Documents of V-A</th>
                 <td className="text-left">{records.upload_documents_of_V_A}</td>
                </tr>}
                {records.date_sample_lifted_date_and_time && <tr>
                  <th>Sample Lifted Date and Time</th>
                 <td className="text-left">{records.date_sample_lifted_date_and_time}</td>
                </tr>}
                {records.sample_food_category && <tr>
                  <th>Sample Food Category</th>
                 <td className="text-left">{records.sample_food_category}</td>
                </tr>}
                {records.sample_number_given_by_do && <tr>
                  <th>Sample Number Given By DO</th>
                 <td className="text-left">{records.sample_number_given_by_do}</td>
                </tr>}
                {records.upload_sample_slip && <tr>
                  <th>Upload Sample Slip</th>
                 <td className="text-left">{records.upload_sample_slip}</td>
                </tr>}
                {records.sample_purchased_receipt_in_RS && <tr>
                  <th>Sample Purchased Receipt (in RS)</th>
                 <td className="text-left">{records.sample_purchased_receipt_in_RS}</td>
                </tr>}

                {records.condition_of_sample && <tr>
                  <th>Condition Of Sample </th>
                 <td className="text-left">{records.condition_of_sample}</td>
                </tr>}
                {records.name_of_the_food_specified_in_label && <tr>
                  <th>Name Of the Food Specified In Label</th>
                 <td className="text-left">{records.name_of_the_food_specified_in_label}</td>
                </tr>}
                {records.batch_lot_no && <tr>
                  <th>Batch/Lot Number </th>
                 <td className="text-left">{records.batch_lot_no}</td>
                </tr>}
                {records.date_of_manufacturing_packing && <tr>
                  <th>Date of Manufacturing/Packed</th>
                 <td className="text-left">{moment(records.date_of_manufacturing_packing).format("l")}</td>
                </tr>}
                {records.date_expiry_date_valid_upto && <tr>
                  <th>Expiry Date / Valid Upto</th>
                 <td className="text-left">{moment(records.date_expiry_date_valid_upto).format("l")}</td>
                </tr>}
                {records.Product_bought_from && <tr>
                  <th>Product Bought/Purchased From</th>
                 <td className="text-left">{records.Product_bought_from}</td>
                </tr>}
                {records.name_of_the_product_purchased && <tr>
                  <th>Name Of the Product Purchased</th>
                 <td className="text-left">{records.name_of_the_product_purchased}</td>
                </tr>}
                {records.address_of_the_product_purchased && <tr>
                  <th>Address of the Product Purchased </th>
                 <td className="text-left">{records.address_of_the_product_purchased}</td>
                </tr>}
                {records.form_V_sent_to_owner && <tr>
                  <th>Form V-A sent to Owner on</th>
                 <td className="text-left">{moment(records.form_V_sent_to_owner).format("l")}</td>
                </tr>}
                {records.upload_documents && <tr>
                  <th>Upload Documents</th>
                 <td className="text-left">{records.upload_documents}</td>
                </tr>}
                {records.sample_sent_by && <tr>
                  <th>Sample Sent By </th>
                 <td className="text-left">{records.sample_sent_by}</td>
                </tr>}
                {records.date_sample_sent_to_lab_on && <tr>
                  <th>Sample Sent To Lab On</th>
                 <td className="text-left">{moment(records.date_sample_sent_to_lab_on).format("l")}</td>
                </tr>} 
                {records.upload_postal_receipt && <tr>
                  <th>Upload Postal Receipt</th>
                 <td className="text-left">{records.upload_postal_receipt}</td>
                </tr>}
                {records.select_the_lab && <tr>
                  <th>Select the Lab</th>
                 <td className="text-left">{records.select_the_lab}</td>
                </tr>}
                {records.other_select_the_lab && <tr>
                  <th>Select the Lab - Others</th>
                 <td className="text-left">{records.other_select_the_lab}</td>
                </tr>}
                {records.FBO_sample_4th_part_analysis_nabl_option_intimated && <tr>
                  <th>FBO Sample 4th Part Sample Analysis NABL Option Intimated </th>
                 <td className="text-left">{records.FBO_sample_4th_part_analysis_nabl_option_intimated}</td>
                </tr>}
                {records.date_intimated_to_do_on && <tr>
                  <th>Intimated to DO On</th>
                 <td className="text-left">{moment(records.date_intimated_to_do_on).format("l")}</td>
                </tr>}
                {records.name_and_address_of_nabl_lab && <tr>
                  <th>Name and Address of NABL Lab </th>
                 <td className="text-left">{records.name_and_address_of_nabl_lab}</td>
                </tr>}
                {records.date_act_sample_lifted_date_and_time && <tr>
                  <th>Act Sample Lifted Date and Time </th>
                 <td className="text-left">{records.date_act_sample_lifted_date_and_time}</td>
                </tr>}
              
              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  traderTypes: state.home.traderTypes,
  wards: state.home.wards,
});

export default connect(mapStateToProps, mapDispatchToProps)(FSOSDetails);