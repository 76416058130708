import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

const VehicleDetails = (props) => {
    const { districts, wards, fsoOfficers } = props;
    const [records, setRecords] = useState([]);
    let { slug } = useParams();

   useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.records;
            
            setRecords(recordData);
            console.log(recordData)
           
        } else if (slug) {
            console.log(slug);
        } 
        // eslint-disable-next-line   
    }, [])
    
    const getDistrict = (district) => {
        let districtName = null;
        districts &&
          districts.forEach((item) => {
            if (item.id === district) {
              districtName = item.name;
            }
          });
        return districtName;
    };
    const fso = (id) => {
      let fsoName = [];
      fsoOfficers &&
      fsoOfficers
      .forEach((item) => {
        id.map((i)=>{
          if (item.fso.id == i) {
            fsoName.push(item.fso.name+","+"")
          }
        })
        
        });
        return fsoName;
    }
    const getAreaCode = (ward) => {
      let wardName = null;
      wards &&
        wards.forEach((item) => {
          if (item.id === ward) {
            wardName = item.ward_number;
          }
        });
      return wardName;
  };

  return (
    <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 bg-secondary card-margin">
              <h5 className="text-light text-center">Vehhicle Inspection Records Information</h5>
            </div>
          </div>
          <div className="row">
              <table className=" col-sm-12 table table-hover table-bordered">
              <tbody>
              <tr>
                <th>District</th>
                <td className="text-left">{getDistrict(records.district)}</td>
              </tr>
              <tr>
                <th>Area Code</th>
                <td className="text-left">{getAreaCode(records.area_code)}</td>
              </tr>
              <tr>
                <th>Area Name</th>
                <td className="text-left">{records.area_name}</td>
              </tr>
              <tr>
                <th>Date of Inspection</th>
                <td className="text-left">{moment(records.date_of_inspection).format("l")}</td>
              </tr>
              <tr>
                <th>Place Of Inspection</th>
                <td className="text-left">{records.place_of_inpsection}</td>
              </tr>
              <tr>
                <th>Vehicle Number</th>
                <td className="text-left">{records.vehicle_number}</td>
              </tr>
              <tr>
                <th>Vehicle Type</th>
                <td className="text-left">{records.type_of_vehicle}</td>
              </tr>
              <tr>
                <th>Respondant's Name</th>
                <td className="text-left">{records.owner_name}</td>
              </tr>
              <tr>
                <th>Respondant's Address</th>
                <td className="text-left">{records.owner_address}</td>
              </tr>
              {records.owner_doc && <tr>
                <th>Respondant's Documents</th>
                <td className="text-left">{records.owner_doc}</td>
              </tr>}
              <tr>
                <th>Driver Name</th>
                <td className="text-left">{records.driver_name}</td>
              </tr>
              <tr>
                <th>Driver Address</th>
                <td className="text-left">{records.driver_address}</td>
              </tr>
              {records.driver_documents && <tr>
                <th>Driver Documents</th>
                <td className="text-left">{records.driver_documents}</td>
              </tr>}
              <tr>
                <th>Consignment Delivery Name</th>
                <td className="text-left">{records.consignment_name}</td>
              </tr>
              <tr>
                <th>Consignment Delivery Address</th>
                <td className="text-left">{records.consignment_address}</td>
              </tr>
             {records.consignment_documents && <tr>
                <th>Consignment Delivery Documents</th>
                <td className="text-left">{records.consignment_documents}</td>
              </tr>}
              <tr>
              <th>Accompanied Persons Name</th>
              <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.worker_details, ((item) => {return(<><li>{item.worker_name}</li> </>) }))}</td>
              </tr>
              <tr>
              <th>Accompanied Persons Address</th>
              <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.worker_details,  ((item) => {return(<><li>{item.worker_address}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Accompanied Persons Documents</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.worker_details, ((item) => {return(<><li>{item.worker_documents}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Violation Found</th>
                <td className="text-left">{records.default_found}</td>
              </tr>
              <tr>
                <th>Food Type</th>
                <td className="text-left">{records.food_type}</td>
              </tr>
               {records.food_category &&<tr>
                <th>Food Category </th>
                <td className="text-left">{records.food_category}</td>
              </tr>}
              {records.other_food_category &&<tr>
                <th>Food Category-others </th>
                <td className="text-left">{records.other_food_category}</td>
              </tr>}
              {records.batch_no &&<tr>
                <th>Batch Number </th>
                <td className="text-left">{records.batch_no}</td>
              </tr>}
              {records.packed_date &&<tr>
                <th>Packed Date </th>
                <td className="text-left">{moment(records.packed_date).format("l")}</td>
              </tr>}
              {records.packed_address &&<tr>
                <th>Packed Address </th>
                <td className="text-left">{records.packed_address}</td>
              </tr>}
              <tr>
                <th>Name Of Food Seized</th>
                  <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.product_details, ((item) => {return(<><li>{item.name_of_food_seized}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Quantity Of Food Seized</th>
                  <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.product_details, ((item) => {return(<><li>{item.quantity_of_food_seized}</li> </>) }))}</td>
              </tr>
              <tr>
                <th>Value Of Food Seized</th>
                <td className="text-left">{Array.prototype.map.call(props.history.location.state.records.product_details, ((item) => {return(<><li>{item.value_of_food_seized}</li> </>) }))}</td>
              </tr>  
              <tr>
                <th>Seized and property is Under Custody Of </th>
                <td className="text-left">{records.seized_under_custody_of}</td>
              </tr>
              <tr>
                <th>Seized with Vehicle/Food</th>
                <td className="text-left">{records.seized_with}</td>
              </tr> 

              <tr>
                <th>Whether Sample Lifted</th>
                <td className="text-left">{records.sample_lifted}</td>
              </tr> 
            {records&&records.rc_available && <tr>
                <th>Rc Available</th>
                <td className="text-left">{records.rc_available}</td>
              </tr> }
              {records&&records.lic_number && <tr>
                <th>License Number</th>
                <td className="text-left">{records.lic_number}</td>
              </tr> }
              
              {records&&records.upload_lics && <tr>
                <th>Upload License</th>
                <td className="text-left">{records.upload_lics}</td>
              </tr> } 
              {records&&records.license_available && <tr>
                <th>License Available</th>
                <td className="text-left">{records.license_available}</td>
              </tr> }
              {records&&records.up_docx && <tr>
                <th>Upload Documents</th>
                <td className="text-left">{records.up_docx}</td>
              </tr> }
              {records&&records.return_property && <tr>
                <th>Return Property</th>
                <td className="text-left">{records.return_property}</td>
              </tr> } 
              {records&&records.upload_property && <tr>
                <th>Upload Property</th>
                <td className="text-left">{records.upload_property}</td>
              </tr> }
              {records.name_seized_under_custody_of && <tr>
                <th>Seized Under Custody - Name of Officer</th>
                <td className="text-left">{records.name_seized_under_custody_of}</td>
              </tr> }
              {records&&records.fso_name_seized_under_custody_of&&records.fso_name_seized_under_custody_of.length!==0 && <tr>
                <th>Seized Under Custody - Name of Officer</th>
                <td className="text-left">{fso(records.fso_name_seized_under_custody_of)}</td>
              </tr> } 
              {records&&records.seizure_docs && <tr>
                <th>Seized Documents</th>
                <td className="text-left">{records.seizure_docs}</td>
              </tr> }
             {records.other_name_seized_under_custody_of &&  <tr>
                <th>Whether Sample Lifted</th>
                <td className="text-left">{records.other_name_seized_under_custody_of}</td>
              </tr> }
              <tr>
                <th>Whether the Food is Destroyed </th>
                <td className="text-left">{records.food_destroyed}</td>
              </tr> 
             {records.coordinated_officers && <tr>
                <th>Co-ordinated Officers </th>
                <td className="text-left">{records.coordinated_officers}</td>
              </tr> }

              </tbody>
            </table>
          </div>
       </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
  districts: state.home.districts,
  wards: state.home.wards,
  fsoOfficers: state.home.fsoOfficers,

});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);