import React, { useEffect, useState, useRef } from "react";

const Panel = (props) => {

  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);
  const { question, answer, activeTab, index, activateTab } = props;

  useEffect(() => {
    const activeHeight = contentRef.current.scrollHeight;
    setHeight(activeHeight)
  }, []);

  const isActive = activeTab === index;
  const innerStyle = {
    height: `${isActive ? height : 0}px`
  }

  return (
    <div className='panel'
      role='tabpanel'
      aria-expanded={isActive}>
      <button className='panel__label'
        role='tab'
        onClick={activateTab}>
        {question}
      </button>
      <div className='panel__inner'
        ref={contentRef}
        style={innerStyle}
        aria-hidden={!isActive}>
        <p className='panel__content'>
          {answer}
        </p>
      </div>
    </div>
  )
}

export default Panel;