import React, { useState, useEffect } from 'react';
import { Image, Alert } from 'react-bootstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OtpInputComponent from '../components/inputs/OtpInputComponent';
import PasswordInput from '../components/inputs/PasswordInput';
import Header from '../components/Header';
import { generateOtp, doLogin, forgotPassword, doStaffLogin } from '../components/reducers/actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-toast-notification';
import TextInput from '../components/inputs/TextInput';
import bcrypt from 'bcryptjs'
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap'
import blue from '../assets/img/bluelogo.jpg'
import { Helmet } from "react-helmet"
var CryptoJS = require("crypto-js");

const ForgotPassword = (props) => {

  const [values, setValues] = useState({ country_code: '+91' });
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [toggle, setToggle] = useState(false)

  const { t } = useTranslation();

  const generateOtp = async (phoneNumber) => {
    phoneNumber = phoneNumber || values.phone_number;
    const data = { phone_number: phoneNumber };
    const resp = await props.onGenerateOtp(data);

    if (resp && resp.response) {
      console.log(resp)
      setErrorMessage('OTP Sent successfully');
      setShowOtp(true);
    }
    else {
      let message = 'Error generating OTP. Please try again';
      if (resp && resp.error && resp.error.non_field_errors) {
        message = resp.error.non_field_errors[0]
      }
      else if (resp && resp.error && resp.error.phone_number) {
        message = 'Your account does not exist. Please register your account.';
      }
      if (resp && resp.error) {
        setErrorMessage(message);
      }
      if (resp && resp.error && resp.error.phone_number) {
        props.history.push('/register')
      }
    }
    setShowOtp(true);
  }

  useEffect(() => {
    if (props.history && props.history.location.state) {
      setShowPasswordField(props.history.location.state.action === 'forgotPassword');
      generateOtp(props.history.location.state.phoneNumber);
      setValues({ phone_number: props.history.location.state.phoneNumber, country_code: props.history.location.state.countryCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ['phone_number', 'otp'];
    if (showPasswordField) {
      keys.push('password');
    }
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === '') {
        updatedErrors[key] = ' ';
      }
    });

    if (values.phone_number && (!values.phone_number || values.phone_number.length !== 10)) {
      updatedErrors.phone_number = t('Invalid phone number');
    }

    if (values.otp && values.otp.length < 6) {
      updatedErrors.otp = '';
    }

    if (showPasswordField && (!values.password || values.password.length === 0)) {
      updatedErrors.password = '';
    }

    if (showPasswordField && values.password && values.password.length > 0 && values.password.length < 6) {
      updatedErrors.password = t('Minimum 6 characters required');
    }

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  }

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    setValues(updatedValues);
    setErrorMessage(null);
    validateValues(updatedValues);
  }

  const login = async (values) => {
    if (props.history && props.history.location.state && props.history.location.state.action === 'forgotPassword') {
      var message = values.password;
      var key ='AAAAAAAAAAAAAAAA'
      key = CryptoJS.enc.Utf8.parse(key);
      var encrypted = CryptoJS.AES.encrypt(message, key, {mode: CryptoJS.mode.ECB});
      encrypted =encrypted.toString();
      // const enc = bcrypt.hashSync(values.password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
      let value = values;
      value.password = encrypted
      value.decrypt_check='Yes'
      console.log(value)
      const resp = await props.onForgotPassword(value);
      if (resp && resp.response && props.history.location.state.userType) {
        props.history.push('/staff/login')
      }
      else if (resp && resp.response) {
        toast.success('Password updated successfully');
        props.history.push('/login')
      }
      if (resp && resp.error) {
        setErrors(resp.error);
        setErrorMessage(resp.error.non_field_errors[0]);
      }
    } else {
      const updatedErrors = validateValues(values);
      if (Object.keys(updatedErrors).length === 0) {
        if (props.history && props.history.location.state && props.history.location.state.userType) {
          const resp = await props.onStaffLogin(values);
          if (resp.response) {
            props.history.push('/app')
          }
          else {
            if (resp && resp.error && resp.error.data && resp.error.data.non_field_errors) {
              setErrorMessage(resp.error.data.non_field_errors[0]);
            }
            else if (resp && resp.error && resp.error.data && !resp.error.data.is_verified) {
              toast.success(resp.error.data.message);
              props.history.push({
                pathname: '/otp-verification/',
                state: {
                  'phone_number': values.phone_number
                }
              })
            }
            setValues({ country_code: '+91' });
          }
        }
        else {
          values.source="web"
          const resp = await props.onLogin(values);
          if (resp && resp.response) {
            if(resp && resp.response&& resp.response.multiple_login === true){
              // setToggle(true)
              window.location.href = "/app/complaints"
            }
            else  window.location.href = "/app/complaints";
          
          }
          else {
            let message = "Something went wrong. Please try again";
            if (resp && resp.error && resp.error.data && resp.error.data.non_field_errors) {
              setErrorMessage(resp.error.data.non_field_errors[0]);
            }
            else if (resp && resp.error && resp.error.data && !resp.error.data.is_verified) {
              toast.error(resp.error.data.message);
              props.history.push({
                pathname: '/otp-verification/',
                state: {
                  'phone_number': values.phone_number
                }
              })
            }
            if(message === "Multiple Attempts"){
              props.history.push({
                pathname: "/forgot-password",
                state: {
                  phoneNumber: values.phone_number,
                  countryCode: values.country_code,
                  action: "forgotPassword",
                },
              });
            }
            setValues({ country_code: '+91' });
          }
        }
      }
    }
  }

  const handleSubmitButton = (e) => {
    e.preventDefault();
    console.log("Submit button clicked");
    const updatedErrors = validateValues(values);
    console.log(values);
    if (Object.keys(updatedErrors).length === 0) {
      login(values);
    }
  }

  return (
    <>
       <Helmet>
     <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.1.1/crypto-js.min.js"></script>
      </Helmet>
      <Header />
      <div className="container p-5">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block">
                <Image style={{ width: '100%', height: 'auto' }} src={blue}>
                </Image>
              </div>
              <div className="col-lg-6">
                <div className="p-5 my-5">
                  {
                    showPasswordField ? (
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">{t('Forgot Password')}</h1>
                      </div>
                    ) : (
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">{t('Login with OTP')}</h1>
                        </div>
                      )
                  }

                  <div>
                    {
                      errorMessage ? (
                        <Alert variant="danger">{errorMessage}</Alert>
                      ) : null
                    }
                  </div>
                  <form onSubmit={handleSubmitButton} className="user">
                    <div className="form-group">
                      <TextInput
                        placeholder={t("Phone number")}
                        type='text'
                        name='phone_number'
                        error={errors.phone_number}
                        value={values.phone_number}
                        onChange={(e) => handleTextChange('phone_number', e.target.value)}
                      />
                    </div>
                    {
                      showOtp ? (
                        <>
                          <p className="text-center medium">{t('We have sent an OTP to')} {values.country_code}{values.phone_number}</p>
                          <div className="form-group mb-4">
                            <OtpInputComponent
                              values={values}
                              setValues={handleTextChange}
                            />
                          </div>
                          <div>
                        <Modal isOpen={toggle}
                        style={{width:'300px'}}
                        >
                          <ModalHeader  >
                          <div style={{display:'flex', flexDirection:'row', textAlign:'center'}}> Multiple Login Detected <i className="fas fa-exclamation-triangle" style={{color:'red', marginTop:10, marginLeft:20, fontSize:30}}></i></div>
                          </ModalHeader>
                          <ModalBody >
                              <p>Your Previous session will be logged out!<br/>
                               Continue here!</p>
                              <Button style={{float:'right', backgroundColor:'green'}} onClick= {()=>window.location.href = "/app/complaints"}>Continue</Button>
                          </ModalBody>
                        </Modal>
                  </div>
                          {
                            showPasswordField && (
                              <div className="form-group">
                                <PasswordInput
                                  placeholder="Password"
                                  type='text'
                                  name='password'
                                  error={errors.password}
                                  value={values.password}
                                  onChange={(e) => handleTextChange('password', e.target.value)}
                                />
                              </div>
                            )
                          }
                          <Link className="small mb-2" style={{ float: 'right' }} onClick={() => generateOtp()} to="#">{t('Resend OTP')}</Link>
                        </>
                      ) : null
                    }
                    <button type="submit" disabled={disableSubmit} className="btn btn-primary-green btn-user btn-block">
                      {showPasswordField ? t('Update Password') : t('Login')}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ForgotPassword.propsType = {
  onGenerateOtp: PropTypes.func,
};


const mapDispatchToProps = dispatch => ({
  onGenerateOtp: (data) => dispatch(generateOtp(data)),
  onLogin: (data) => dispatch(doLogin(data)),
  onStaffLogin: (data) => dispatch(doStaffLogin(data)),
  onForgotPassword: (data) => dispatch(forgotPassword(data)),
});

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
