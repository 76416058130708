import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createHotelReports, editHotelReports, getDistrictWards } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";
import moment from 'moment'


const SelectType = [
    {id:"0", name:"hotel"},
    {id:"1", name:"restaurant"},
]
const samples=[
    {id:'0', name:'Act'},
    {id:'1', name:'Surveillance'},
]
const HotelReport = (props) => {
    const { districts, user,wards,  onCreateHotelReports,ongetDist, wardsCategory,onEditHotelReports } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'area_code':'',
        "area_name":'',
        'type_of_premisis':'',
        'hotels_inspected' : '',
        'hotels_issued_us31' : '',
        'hotels_issued_us32' : '',
        'hotels_issued_us55' : '',
        'restaurants_inspected' : '',
        'restaurants_issued_us31' : '',
        'restaurants_issued_us32' : '',
        'restaurants_issued_us55' : '',
        'no_of_sample_lifted':'',
        'quantity_of_food_color_seized':'',
        'value_of_seized':'',
        'quantity_of_plastic_seized':'',
        'value_of_plastic_seized':'',
        'penalty_inposed':'',
        'date_paid_on':'',
        'upload_challon':'',
        
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();
    
    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    const getDis=async(data)=>{
        await ongetDist({district:data})
       }
       useEffect(()=>{
         if(values.district&&values.district){
           getDis(values.district)
         }
       },[values.district&&values.district])


    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])
    useEffect(()=>{
        wardsCategory.map((o)=>{
            if(o.id == values.area_code)
            return (
              setValues({
                    ...values,
                    area_name: o.ward_name
                })
            )
        })
    },[values.area_code])
    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 
        'type_of_premisis',
        'date', 
        'area_code',
        'area_name',
        'no_of_sample_lifted',
        'quantity_of_food_color_seized',
        'value_of_seized',
        'quantity_of_plastic_seized',
        'value_of_plastic_seized',
        'penalty_inposed',
        'date_paid_on',
       ];
       if(values&&values.type_of_premisis&&values.type_of_premisis=='hotel')keys.push('hotels_inspected','hotels_issued_us31','hotels_issued_us32','hotels_issued_us55')
       if(values&&values.type_of_premisis&&values.type_of_premisis=='restaurant')keys.push('restaurants_inspected', 'restaurants_issued_us31','restaurants_issued_us32','restaurants_issued_us55')

        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {  
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            const resp = await onEditHotelReports(formData, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/hotels-and-restaurants-list/`);
            };

        } else {     
            const data = { ...values}
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key.startsWith("date")) {
                    formData.append(key, new Date(data[key]).toISOString());
                } else {
                    formData.append(key, data[key]);
                }
            });
            setDisableSubmit(true);
            const resp = await onCreateHotelReports(formData);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }
    return (
        <div>
            <div className="container-fluid">
                <h1 className="h3 mb-4 text-gray-800">Hotels and Restaurants Report</h1>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district"
                                >Name of the district : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                            ) : (
                                <div className="form-group row">
                                    <label className="control-label col-sm-4 text-label" htmlFor="district"
                                    >Name of the district *: </label>
                                    <div className="col-sm-8">
                                        <DropDownInput
                                            type="text"
                                            name="district"
                                            value={values.district}
                                            error={errors.district}
                                            items={districts}
                                            onChange={(text) => handleTextChange("district", text)}
                                        />
                                    </div>
                                </div>
                            )}
                         
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_code"
                                >Area Code *: </label>
                                <div className="col-sm-8">
                                <DropDownInput
                                        type="text"
                                        name="area_code"
                                        action="area_code"
                                        value={values.area_code}
                                        error={errors.area_code}
                                        items={wardsCategory}
                                        onChange={(value) => handleTextChange("area_code", value)}
                                />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="area_name"
                                >Area Name *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Area Name`}
                                        type="text"
                                        name="area_name"
                                        value={values.area_name}
                                        error={errors.area_name}
                                        onChange={(e) => handleTextChange("area_name", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date"
                                >Month *: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Month`}
                                        name="date"
                                        id="date"
                                        showMonthYearPicker
                                        value={values.date }
                                        error={errors.date}
                                        onChangeText={(text) => handleTextChange('date', text)}
                                    />
                                </div>
                            </div> 
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="type_of_premisis"
                                >Select The Premisis *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Select the Premisis`}
                                        type="text"
                                        name="type_of_premisis"
                                        action="dropdown"
                                        value={values.type_of_premisis}
                                        error={errors.type_of_premisis}
                                        items={SelectType}
                                        onChange={(text) => handleTextChange("type_of_premisis", text)}
                                    />
                                </div>
                            </div>
                           {values && values.type_of_premisis.includes('hotel')? <><div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="hotels_inspected"
                                >Number of of Hotels Inspected *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of of Hotels Inspected`}
                                        type="number"
                                        name="hotels_inspected"
                                        value={values.hotels_inspected}
                                        error={errors.hotels_inspected}
                                        onChange={(e) => handleTextChange("hotels_inspected", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="hotels_issued_us31"
                                >Number of of Hotels Notice issued U/S 31 *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of of Hotels Notice issued U/S 31`}
                                        type="number"
                                        name="hotels_issued_us31"
                                        value={values.hotels_issued_us31}
                                        error={errors.hotels_issued_us31}
                                        onChange={(e) => handleTextChange("hotels_issued_us31", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="hotels_issued_us55"
                                >Number of of Hotels Notice issued U/S 55 *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of of Hotels Notice issued U/S 55`}
                                        type="number"
                                        name="hotels_issued_us55"
                                        value={values.hotels_issued_us55}
                                        error={errors.hotels_issued_us55}
                                        onChange={(e) => handleTextChange("hotels_issued_us55", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="hotels_issued_us32"
                                >Number of Hotels Improvement notice issued U/S 32 *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Hotels Improvement notice issued U/S 32`}
                                        type="number"
                                        name="hotels_issued_us32"
                                        value={values.hotels_issued_us32}
                                        error={errors.hotels_issued_us32}
                                        onChange={(e) => handleTextChange("hotels_issued_us32", e.target.value)}
                                    />
                                </div>
                            </div> </> : null} 
                            {values && values.type_of_premisis.includes('restaurant') ? <>  <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="restaurants_inspected"
                                >Number of of Restaurants Inspected *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of of Restaurants Inspected`}
                                        type="number"
                                        name="restaurants_inspected"
                                        value={values.restaurants_inspected}
                                        error={errors.restaurants_inspected}
                                        onChange={(e) => handleTextChange("restaurants_inspected", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="restaurants_issued_us31"
                                >Number of of Restaurants Notice issued U/S 31 *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of of Restaurants Notice issued U/S 31`}
                                        type="number"
                                        name="restaurants_issued_us31"
                                        value={values.restaurants_issued_us31}
                                        error={errors.restaurants_issued_us31}
                                        onChange={(e) => handleTextChange("restaurants_issued_us31", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="restaurants_issued_us55"
                                >Number of of Restaurants Notice issued U/S 55 *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of of Restaurants Notice issued U/S 55`}
                                        type="number"
                                        name="restaurants_issued_us55"
                                        value={values.restaurants_issued_us55}
                                        error={errors.restaurants_issued_us55}
                                        onChange={(e) => handleTextChange("restaurants_issued_us55", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="restaurants_issued_us32"
                                >Number of Restaurants Improvement notice issued U/S 32 *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Number of Restaurants Improvement notice issued U/S 32`}
                                        type="number"
                                        name="restaurants_issued_us32"
                                        value={values.restaurants_issued_us32}
                                        error={errors.restaurants_issued_us32}
                                        onChange={(e) => handleTextChange("restaurants_issued_us32", e.target.value)}
                                    />
                                </div>
                            </div> </> : null}
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="no_of_sample_lifted"
                                >Type of sample *: </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`Type of sample`}
                                        name="no_of_sample_lifted"
                                        action='dropdown'
                                        items={samples}
                                        value={values.no_of_sample_lifted}
                                        error={errors.no_of_sample_lifted}
                                        onChange={(value) => handleTextChange("no_of_sample_lifted", value)}
                                    />
                                </div>
                            </div> 
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="quantity_of_food_color_seized"
                                >Quantity of food color seized *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Quantity of food color seized`}
                                        type="number"
                                        name="quantity_of_food_color_seized"
                                        value={values.quantity_of_food_color_seized}
                                        error={errors.quantity_of_food_color_seized}
                                        onChange={(e) => handleTextChange("quantity_of_food_color_seized", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="value_of_seized"
                                >Value of food color seized (RS)*: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Value of food color seized`}
                                        type="number"
                                        name="value_of_seized"
                                        value={values.value_of_seized}
                                        error={errors.value_of_seized}
                                        onChange={(e) => handleTextChange("value_of_seized", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="quantity_of_plastic_seized"
                                >Quantity of plastic seized *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Quantity of plastic seized`}
                                        type="number"
                                        name="quantity_of_plastic_seized"
                                        value={values.quantity_of_plastic_seized}
                                        error={errors.quantity_of_plastic_seized}
                                        onChange={(e) => handleTextChange("quantity_of_plastic_seized", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="value_of_plastic_seized"
                                >Value of plastic seized (RS) *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Value of plastic seized`}
                                        type="number"
                                        name="value_of_plastic_seized"
                                        value={values.value_of_plastic_seized}
                                        error={errors.value_of_plastic_seized}
                                        onChange={(e) => handleTextChange("value_of_plastic_seized", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="penalty_inposed"
                                >Compound offense penalty imposed (RS) *: </label>
                                <div className="col-sm-8">
                                    <TextInput
                                        placeholder={`Compound offense penalty imposed`}
                                        type="number"
                                        name="penalty_inposed"
                                        value={values.penalty_inposed}
                                        error={errors.penalty_inposed}
                                        onChange={(e) => handleTextChange("penalty_inposed", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="date_paid_on"
                                >Penalty paid on *: </label>
                                <div className="col-sm-8">
                                    <Datepicker
                                        placeholder={`Penalty paid on`}
                                        name="date_paid_on"
                                        value={values.date_paid_on}
                                        error={errors.date_paid_on}
                                        onChangeText={(text) => handleTextChange("date_paid_on",text)}
                                    />
                                </div>
                            </div>
                           <div className="form-group row">
                             <label className="control-label col-sm-4 text-label" htmlFor="upload_challon"
                             >Upload Challon : </label>
                             <div className="col-sm-8">
                                 <TextInput
                                     placeholder={`Upload Challon`}
                                     type="file"
                                     name="upload_challon"
                                     error={errors.upload_challon}
                                     onChange={(e) =>handleTextChange("upload_challon", e.target.files[0])}
                                 />
                             </div>
                         </div>
                            <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateHotelReports: (data) => dispatch(createHotelReports(data)),
    onEditHotelReports: (data, record_id) => dispatch(editHotelReports(data, record_id)),
    ongetDist:(data)=>dispatch(getDistrictWards(data)),
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,
    wards: state.home.wards,
    wardsCategory:state.home.wardsCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelReport)
