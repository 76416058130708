import React from "react";
import { useEffect, useState, } from "react";
import { Table } from "react-bootstrap";
import Header from "../../components/Header";
import { connect } from "react-redux";
import DownloadLink from "react-download-link";
import { useTranslation } from "react-i18next";
import moment from "moment";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import { foodWaste, getDistrict } from "../../components/reducers/actions";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import toast from 'react-toast-notification'
import donate from '../../assets/img/donate.png'

const Donate = (props) => {
    const{onGetDistrict,districts,onfoodWaste, user}=props;
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState({
    'phone_number':'',
    'whatsapp':'',
    'food':'',
    'zipcode':'',
    'premises':'',
    'district':'',
});
const [errors, setErrors] = useState({});
const [disableSubmit, setDisableSubmit] = useState(true);
const[dist, setDist]=useState(null);
const[isDis, setIsDis]=useState(false);
const[isLoading,  setIsLoading]= useState(false);

const getValue = async(val)=>{
  
    if((val !== undefined) && (val.length==6)) {
      const data = {'zipcode': val}
      const resp = await onGetDistrict(data);
      console.log(resp)
    let dis = resp.response.status ==="success" && resp.response.district_id ? resp.response.district_id :'';
    if(resp.response.status ==="success"){
      setDist(resp.response)
     setValues({
       ...values,
       district:parseInt(dis) ,
       zipcode:val,
     })
     let data={...values,
      district:dis,
      zipcode:val
    }
     validateValues(data)
      setIsLoading(true)
      setIsDis(true)
    }
    else {
      setIsLoading(false)
      setIsDis(false)
  
    }
    }
}
useEffect(()=>{
  if(user){
    setValues({
      ...values,
      phone_number:user.phone_number, 
      whatsapp:user.phone_number
    })
  }
  else{
    props.history.push("/login");
  }
},[])
useEffect(()=>{
 getValue(values.zipcode)
},[values.zipcode])
const validateValues = (values) => {
  console.log(values)
    var updatedErrors = {};
    var keys = ['phone_number','whatsapp','food', 'premises', 'district'];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === '') {
        updatedErrors[key] = ' ';
      }
    });
    if(values.district !== "") setIsLoading(true)
    if (values.zipcode && values.zipcode.length !== 6) {
      updatedErrors.zipcode = "Zipcode should be 6 characters";
    } else if (values.zipcode && values.zipcode[0] !== "6") {
      updatedErrors.zipcode = "Not a valid Tamil Nadu zipcode.";
    }
    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
}

const handleSubmit = async (event) => {
  
        const data = { ...values}
        if(typeof(data.district)=='string'){
          data.district=dist.district_id
        }
        setDisableSubmit(true);
        console.log(data)
        const resp = await onfoodWaste(data);
        if (resp && resp.response) {
            console.log(resp.response);
            toast.success('Food Details Submitted Successfully!');
            setTimeout(()=>{
              props.history.push("/")
            },1000)
          };
}

const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    if(key == 'zipcode'){getValue(updatedValues.zipcode)}
    setValues(updatedValues);
    validateValues(updatedValues);
}
  return (
    <>
    <Header />
    <div className="container-fluid">
            <div style={{ fontSize:'16px', marginTop:10,display:'inline-block'}} className="h3 mb-4 text-gray-800">
              <div style={{margin:28, textAlign:'center', textDecoration:'underline', fontSize:25}}> {t("Save food Share food")}</div>
            <div style={{width:'50%', display:'inline-block',}}>
            {t("sf")} <br/><br/>
            <h5 style={{textDecoration:'underline'}}>{t("AO")} </h5><br/>
            <p>
              {t("sf1")}<br/><br/>
              {t("sf2")}<br/><br/>
              {t("sf3")}<br/><br/>
              {t("sf4")}<br/><br/>
              
            </p>
            </div>
            <div style={{width:'50%',float:'right',position:'relative', top:0}}>
              <img src={donate} style={{ height:500,width:'100%',objectFit:'contain'}}   alt="" />
            </div>
            {t("sf5")}<br/><br/>
            {t("sf6")}<br/><br/>

              </div>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                         <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="phone_number"
                            >{t("Phone number")} *: </label>
                            <div className="col-sm-8">
                            <TextInput
                                    placeholder={t("Phone number")} 
                                    name="phone_number"
                                    type="number"
                                    id="phone_number"
                                    value={values.phone_number}
                                    error={errors.phone_number}
                                    onChange={(e) => handleTextChange("phone_number", e.target.value)}
                                />
                            </div>
                        </div>  
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="whatsapp"
                            >{t("Whatsapp Number")} *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={t("Whatsapp Number")}
                                    name="whatsapp"
                                    id="whatsapp"
                                    type="number"
                                    value={values.whatsapp}
                                    error={errors.whatsapp}
                                    onChange={(e) => handleTextChange("whatsapp", e.target.value)}
                                />
                            </div>
                        </div> 
                       
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="food_details"
                            >{t("Food Details")}*: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={t("Food Details")}
                                    type="text"
                                    name="food"
                                    id="food"
                                    value={values.food}
                                    error={errors.food}
                                    onChange={(e) => handleTextChange("food", e.target.value)}
                                />
                            </div>
                        </div>                        
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="premises"
                            >{t("Address")} *: </label>
                            <div className="col-sm-8">
                                <TextAreaInput
                                    placeholder={t("Enter Full Address")}
                                    type="text"
                                    name="premises"
                                    value={values.premises}
                                    error={errors.premises}
                                    onChange={(e) =>handleTextChange("premises", e.target.value)}
                                />
                            </div>
                        </div>     
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="premises"
                            >{t("ZipCode")} : </label>
                               <div className="col-sm-8">
                      <TextInput
                        placeholder={`${t("ZipCode")} `}
                        type="text"
                        name="zipcode"
                        value={values.zipcode}
                        error={errors.zipcode}
                        onChange={(e) =>
                          handleTextChange("zipcode", e.target.value)
                        }
                      />
                    </div>
                    </div>
                
                    <div className="form-group row">
                    <label className="control-label col-sm-4 text-label" htmlFor="district"
                    >{t("District")} *: </label>
                     <div className="col-sm-8">
                      <DropDownInput
                        placeholder={`${t("District")} *`}
                        type="text"
                        name="district"
                        value={values.district}
                        error={errors.district}
                        items={districts}
                        onChange={(text) => handleTextChange("district", text)}
                      />
                      </div>
                    </div>       
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>    
        </>    
  );
};

const mapDispatchToProps = (dispatch) => ({
  onGetDistrict:(data)=>dispatch(getDistrict(data)),
  onfoodWaste:(data)=>dispatch(foodWaste(data))

});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user,

});
export default connect(mapStateToProps, mapDispatchToProps)(Donate);
