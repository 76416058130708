import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "./reducers/actions";
import dummpyImage from "../assets/img/dummy.jpeg";
import { useTranslation } from "react-i18next";

const Topbar = (props) => {
  const { user } = props;
  const { t } = useTranslation();

  const getLogout = async () => {
    const resp = await props.onLogout();
    if (resp && resp.response) {
      localStorage.clear();
      if (user && user.user_types && user.user_types.length > 0) {
        window.location.href = "/login/";
      } else {
        window.location.href = "/login/";
      }
    }
  };

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.substr(1);
  };

  const handleToggle = () => {
    props.setSidebarToggle(false);
    props.setSidebarToggleTop(!props.sidebarToggleTop);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow ${
          props.sidebarToggleTop || props.sidebarToggle ? "toggled" : ""
        }`}
      >
        <button
          onClick={() => handleToggle()}
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
        >
          <i className="fa fa-bars"></i>
        </button>
        <ul className="navbar-nav ml-auto">
          <div className="topbar-divider d-none d-sm-block"></div>
          <li className="nav-item no-arrow">
            <Dropdown className="nav-link">
              <Dropdown.Toggle className="profile-btn" variant="link">
                <span className="mx-1 d-none d-lg-inline small">
                  {props && props.user && props.user.name
                    ? capitalize(props.user.name)
                    : ""}
                </span>
                <img
                  alt="logo"
                  className="mx-1 img-profile rounded-circle"
                  src={
                    props && props.user && props.user.profile_picture
                      ? props.user.profile_picture
                      : dummpyImage
                  }
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item tag="a" href="/app/profile/">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  {t("Profile")}
                </Dropdown.Item>
                {user &&
                  user.user_types &&
                  (user.user_types.includes("do") ||
                    user.user_types.includes("ho")) && (
                    <Dropdown.Item tag="a" href="/app/dashboard/">
                      <i className="fa fa-rocket fa-sm fa-fw mr-2 text-gray-400"></i>
                      {t("Gotd")}
                    </Dropdown.Item>
                  )}
                <Dropdown.Item onClick={getLogout}>
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  {t("Logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </nav>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(logout()),
});

const mapStateToProps = (state) => ({
  user: state.home.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
