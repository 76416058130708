import React from 'react'
import { connect } from 'react-redux'
import Header from '../components/Header'

export const Blogc = (props) => {
  return (
    <>
         <Header activeMenu="Blogs"  />
      <section>
        <div className="blog-section">
          <div className="blog-header-title">
            <h1 style={{textDecorationLine:'underline'}} className="h2 mb-4 text-gray-800 text-center">Nourish Your Mind and Improve Mental Health</h1>
          </div>
          <div className="blog-body col-md-12 mx-auto" >
                <div className='col-md-12'>
                    <h2 className='mt-2 pt-md-5 pt-3 text-black-500'>Boost Your Mental Health with These Power Foods: The Science Behind Them</h2>
                    <p>Food plays a crucial role in maintaining good mental health  as it provides the necessary nutrients for the proper functioning of the brain and the nervous system. Consuming a balanced diet rich in vitamins, minerals, and essential fatty acids can help to boost mood, reduce stress and anxiety, and improve overall mental well-being. Some foods have been shown to have direct effects on the brain and neurotransmitter production, while others indirectly support mental health through their impact on physical health and inflammation. To maintain good mental health, it is important to eat a varied and balanced diet, limit processed foods, and avoid skipping meals.This blog will explore the science behind <a style={{color:'#848896'}} href='https://careme.health/' target='_blank'>mental health </a>foods  and provide clear evidence of how they can improve mental health.</p>
                    <h2 className='pt-md-4 pt-2'>Mind over Matter: The Surprising Link between Poor Food Habits and Mental Health</h2>
                    <p>
                    Bad food habits, such as consuming too much junk food, skipping meals, overeating, and consuming high amounts of sugar and caffeine, can have negative effects on mental health.
                    <div className='mt-2 ml-3 pl-md-5 pl-0'>
                    <p className='m-0 p-0'>1. Junk food: Processed foods high in sugar and unhealthy fats can lead to spikes in blood sugar levels, followed by crashes that can affect mood and energy levels.</p>
                    <p className='m-0 p-0'>2. Skipping meals: Going too long without food can lead to low blood sugar levels and cause feelings of irritability, fatigue, and anxiety.</p>
                    <p className='m-0 p-0'> 3. Overeating: Consuming too much food, particularly unhealthy foods, can lead to feelings of guilt and depression.</p>
                    <p className='m-0 p-0'>4. High sugar intake: Excessive sugar consumption can lead to mood swings, energy crashes, and anxiety.</p>
                    <p className='m-0 p-0'> 5. High caffeine intake: Consuming too much caffeine can lead to anxiety, restlessness, and difficulty sleeping, which can negatively affect mental health.</p>
                    </div>
                    In summary, poor food choices can cause physical and mental imbalances that can negatively impact mental health. It is important to adopt healthy eating habits to support both physical and mental well-being.
                    </p>
                    <h2 className='pt-md-4 pt-2'>Brain Boosting Foods: Top 5 for Mental Health</h2>
                    <h4 style={{fontSize:'25px'}}>Nutrition plays a crucial role in how we feel, think, and handle stress. Research shows that certain foods can enhance brain function, mood, and overall mental wellness. Here are the top five brain boosting foods you should incorporate into your diet.</h4>
                    <h2 className='pt-md-4 pt-2'>Omega-3 rich foods:</h2>
                    <p>Fatty fish like salmon, mackerel, and sardines are some of the best sources of omega-3 fatty acids, which are essential for brain health. Studies have shown that omega-3 fatty acids play a crucial role in maintaining a healthy brain and improving mood.
                    <p>Omega-3 fatty acids help reduce inflammation in the brain, which has been linked to depression, anxiety, and other <a style={{color:'#848896'}} href='https://careme.health/' target='_blank'>mental health</a> conditions. Additionally, they improve cognitive function, particularly in the areas of memory and attention.</p></p>
                    <h2>Fermented foods:</h2>
                    <p>
                    Probiotic-rich foods like yogurt, kefir, and sauerkraut are another food group that can contribute to good mental health. Probiotics are beneficial bacteria that live in the gut and help regulate digestive function.
                    <p>
                    Studies have shown that gut health is connected to mental health, and a healthy gut can lead to improved mood, reduced anxiety, and decreased symptoms of depression. Fermented foods are an excellent source of probiotics, and incorporating them into your diet can improve gut health, which can, in turn, have a positive impact on mental health.
                    </p> </p>
                     <h2>Fruits and vegetables:</h2>
                     <p>Fruits and vegetables are an excellent source of vitamins, minerals, and antioxidants, which have been shown to have a positive impact on mental health. Vitamin B6, for example, is essential for the production of serotonin, a neurotransmitter that regulates mood, and vitamin C has been linked to reduced symptoms of depression and anxiety.
                        <p>
                    Additionally, fruits and vegetables are an excellent source of antioxidants, which help reduce oxidative stress in the body, which has been linked to <a style={{color:'#848896'}} href='https://careme.health/' target='_blank'> mental health problems</a>.</p></p>
                    <h2>Nuts and seeds:</h2>
                    <p>Nuts and seeds are a great source of healthy fats, which have been linked to improved mood and cognitive function. Foods like almonds, walnuts, and chia seeds contain high amounts of healthy fats, which can help improve brain function and reduce symptoms of depression and anxiety.</p>
                    <h2>Whole grains:</h2>
                    <p>Complex carbohydrates like whole wheat, brown rice, and quinoa provide sustained energy and help regulate blood sugar levels, which can affect mood. Studies have shown that consuming whole grains can help improve mood and cognitive function, particularly in individuals with depression and anxiety.</p>
                    <h1 className='pt-md-4 pt-2'>Conclusion:</h1>
                    <p>In conclusion, there are several foods that have been linked to improving mental health and wellbeing. These foods include omega-3 rich fatty fish, fermented foods, fruits and vegetables, nuts and seeds, and whole grains.
                        It's important to note that while these foods can have a positive impact on mental health, they are just one aspect of a comprehensive approach to mental wellbeing. Additionally, a healthy diet is just one part of a larger plan that should also include regular exercise, stress management, and sufficient sleep.
                        If you are experiencing mental health problems, it is essential to consult with a healthcare professional for personalized advice. They can help you develop a comprehensive plan that addresses all aspects of your <a style={{color:'#848896'}} href='https://careme.health/' target='_blank'>mental health</a> , including diet.</p>
            </div>    
        </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Blogc)