import React from 'react';
import { useEffect, useState } from 'react';
import chart from '../../assets/img/organichart.jpg'
import ose from '../../assets/img/ose.png'
import ost from '../../assets/img/ost.png'
import Header from '../../components/Header';
import { getStruct } from '../../components/reducers/actions';
import { connect } from 'react-redux';

const Organistaion = (props) => {
    const {ongetImg}=props;
    const [imag, setImag] = useState([]);

    const getImg=async()=>{
        const resp = await ongetImg();
        console.log(resp)
        setImag(resp.response)
    }
    useEffect(()=>{
        getImg()
    },[])
    return (
        <div>
            <Header/>
            <div style={{textAlign:"center", margin:40}}>
           {imag &&  <img src={localStorage.i18nextLng === 'en'? imag[0]?.attachment : imag[1]?.attachment} style={{width:'80%', height:'40%', objectFit:'cover'}} /> }
            </div>

        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    ongetImg: (data) => dispatch(getStruct(data)),
  });
  
  const mapStateToProps = (state) => ({
    user:state.home.user
  });
  export default connect(mapStateToProps, mapDispatchToProps)(Organistaion);
