import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { createMasterData, getMasterData, updateMasterData } from '../components/reducers/actions';

const AddMasterData = (props) => {

  const [values, setValues] = useState({
    name: '',
    image: null,
  });
  const [updatedValuesKeys, setUpdatedValuesKeys] = useState({});
  const [errors, setErrors] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(true);

  const { onCreateMasterData, onGetMasterData, onUpdateMasterData, foodConditions } = props;

  const validateValues = (values) => {
    var updatedErrors = {};
    var keys = ['name', 'image'];
    var shouldDisableSubmit = true;
    keys.forEach((key) => {
      if (!values[key] || values[key] === '') {
        updatedErrors[key] = ' ';
      }
    });

    shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
    setDisableSubmit(shouldDisableSubmit);
    setErrors(updatedErrors);
    return updatedErrors;
  }

  useEffect(() => {
    validateValues(values);
  }, [values]);

  useEffect(() => {
    if (props.history && props.history.location.state) {
      setValues(props.history.location.state);
      validateValues(props.history.location.state);
    }
  }, [props]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleSubmitButton = async (e) => {
    e.preventDefault();
    console.log("Submit button clicked");
    const updatedErrors = validateValues(values);
    if (Object.keys(updatedErrors).length === 0) {
      if (props.history && props.history.location.state && !updatedValuesKeys.image) {
        values.image = await fetch(values.image).then(r => r.blob());
      }
      const imageBase64 = await toBase64(values.image);
      if (imageBase64) {
        values.image = imageBase64;
      }
      else {
        values.image = null;
      }
      if (props.history && props.history.location.state) {
        const data = { ...values, 'path': props.match.params.type };
        const resp = await onUpdateMasterData(data);
        if (resp && resp.response) {
          onGetMasterData();
          props.history.goBack();
        }
      }
      else {
        const data = { ...values, 'path': props.match.params.type };
        const resp = await onCreateMasterData(data);
        if (resp && resp.response) {
          onGetMasterData();
          props.history.goBack();
        }
      }
    }
  }

  const handleTextChange = (key, value) => {
    const updatedValues = { ...values, [key]: value };
    if (props.history && props.history.location.state) {
      setUpdatedValuesKeys({ ...updatedValuesKeys, [key]: value });
    }
    setValues(updatedValues);
    validateValues(updatedValues);
  }

  const handleGoBack = () => {
    if(props.match && props.match.params){
      props.history.push(`/app/${props.match.params.type}`);
    }
    else{
      props.history.push('/app');
    }
  }

  console.log(errors);

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">{ props.history && props.history.location.state ? 'Edit' : 'Add'} {props.match && props.match.params.type.replace(/-/g, " ")}</h1>
      <div className="card shadow mb-4">
        <div className="card-body">
          <form onSubmit={handleSubmitButton}>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={values.name}
                onChange={(e) => handleTextChange('name', e.target.value)}
                className="form-control"
              />
            </div>
            {
              props.match && props.match.params.type === "food-issues" ? (
                <div className="form-group">
                  <label>Food Condition</label>
                  <select
                    name="food_condition"
                    className="form-control"
                    value={values.food_condition}
                    onChange={(e) => handleTextChange('food_condition', e.target.value)}
                  >
                    <option value="">---------</option>
                    {
                      foodConditions ? foodConditions.map((item, idx) => {
                        return (<option key={idx} value={item.id}>{item.name}</option>)
                      }) : null
                    }
                  </select>
                </div>
              ) : null
            }
            <div className="form-group">
              <label>Image </label>
              {
                props.history && props.history.location.state ? (
                  <div className="mb-3 ml-3 mt-2">
                    <img src={props.history.location.state.image} alt="img alt" width="30" height="30" />
                  </div>
                ) : null
              }
              <div className="custom-file">
                <input
                  type="file"
                  name="image"
                  className="custom-file-input pt-2"
                  onChange={(e) => handleTextChange('image', e.target.files[0])}
                />
                <label className="custom-file-label pt-2">{values && values.image ? [(values.image.name ? values.image.name : values.image)] : 'Choose File'}</label>
              </div>
            </div>
            <div className="form-group">
              <button disabled={disableSubmit} className="btn btn-success" type="submit">Submit</button>
              <button onClick={() => handleGoBack()} type="button" className="btn btn-default ml-3">Back</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  onCreateMasterData: (data) => dispatch(createMasterData(data)),
  onUpdateMasterData: (data) => dispatch(updateMasterData(data)),
  onGetMasterData: () => dispatch(getMasterData()),
});

const mapStateToProps = state => ({
  foodConditions: state.home.foodConditions,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMasterData);
