import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import TextAreaInput from '../../components/inputs/TextAreaInput';
import Checkbox from '../../components/inputs/Checkbox';
import { createSlaughterHouseReports } from "../../components/reducers/actions"

const SlaughterHouseReport = (props) => {
    const { districts, user,wards, onCreateSlaughterHouseReports } = props;
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);

    useEffect(() => {
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'location', 'total_no_of_slaughter_houses', 
        'no_of_slaughter_houses_in_use', 'no_of_slaughter_houses_not_in_use', 'no_of_slaughter_houses_having_valid_license',
        'no_of_registration_expired_slaughter_houses', 'no_of_slaughter_houses_without_valid_license', 'remarks','veterinary_doctors'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {       
        const data = { ...values}
        setDisableSubmit(true);
        const resp = await onCreateSlaughterHouseReports(data);
        if (resp && resp.response) {
            console.log(resp.response);
        };
        props.history.push("/app/reports/slaughter-house-report/");
        console.log(values);
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Slaughter House Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        <div className="form-group">
                        <label className="control-label" htmlFor="district">District : </label>
                            <div>
                                <DropDownInput
                                    type="text"
                                    name="district"
                                    value={values.district}
                                    error={errors.district}
                                    items={districts}
                                    onChange={(text) => handleTextChange("district", text)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                        <label className="control-label" htmlFor="date"
                            >Date *: </label>
                            <div>
                                <TextInput
                                    type="date"
                                    name="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChange={(e) => handleTextChange("date", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                        <label className="control-label" htmlFor="location"
                            >Location *: </label>
                            <div>
                                <TextInput
                                    type="text"
                                    name="location"
                                    value={values.location}
                                    error={errors.location}
                                    onChange={(e) => handleTextChange("location", e.target.value)}
                                />
                            </div>
                        </div>                        
                        <div className="form-group">
                        <label className="control-label" htmlFor="total_no_of_slaughter_houses"
                            >Total number of slaughter houses *: </label>
                            <div>
                                <TextInput
                                    type="number"
                                    name="total_no_of_slaughter_houses"
                                    value={values.total_no_of_slaughter_houses}
                                    error={errors.total_no_of_slaughter_houses}
                                    onChange={(e) => handleTextChange("total_no_of_slaughter_houses", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                        <label className="control-label" htmlFor="no_of_slaughter_houses_in_use"
                            >Number of slaughter houses in use *: </label>
                            <div>
                                <TextInput
                                    type="number"
                                    name="no_of_slaughter_houses_in_use"
                                    value={values.no_of_slaughter_houses_in_use}
                                    error={errors.no_of_slaughter_houses_in_use}
                                    onChange={(e) => handleTextChange("no_of_slaughter_houses_in_use", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                        <label className="control-label" htmlFor="no_of_slaughter_houses_not_in_use"
                            >Number of slaughter houses not in use : </label>
                            <div>
                                <TextInput
                                    type="number"
                                    name="no_of_slaughter_houses_not_in_use"
                                    value={values.no_of_slaughter_houses_not_in_use}
                                    error={errors.no_of_slaughter_houses_not_in_use}
                                    onChange={(e) => handleTextChange("no_of_slaughter_houses_not_in_use", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                        <label className="control-label" htmlFor="no_of_slaughter_houses_having_valid_license"
                            >Number of slaughter houses having valid license : </label>
                            <div>
                                <TextInput
                                    type="number"
                                    name="no_of_slaughter_houses_having_valid_license"
                                    value={values.no_of_slaughter_houses_having_valid_license}
                                    error={errors.no_of_slaughter_houses_having_valid_license}
                                    onChange={(e) => handleTextChange("no_of_slaughter_houses_having_valid_license", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label" htmlFor="no_of_registration_expired_slaughter_houses"
                            >Number of Registration expired slaughter houses : </label>
                            <div>
                                <TextInput
                                    type="number"
                                    name="no_of_registration_expired_slaughter_houses"
                                    value={values.no_of_registration_expired_slaughter_houses}
                                    error={errors.no_of_registration_expired_slaughter_houses}
                                    onChange={(e) =>handleTextChange("no_of_registration_expired_slaughter_houses", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label" htmlFor="no_of_slaughter_houses_without_valid_license"
                            >Number of slaughter houses without valid license : </label>
                            <div>
                                <TextInput
                                    type="number"
                                    name="no_of_slaughter_houses_without_valid_license"
                                    value={values.no_of_slaughter_houses_without_valid_license}
                                    error={errors.no_of_slaughter_houses_without_valid_license}
                                    onChange={(e) => handleTextChange("no_of_slaughter_houses_without_valid_license", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <Checkbox
                                placeholder="S.H. slaughtering outside without valid storage license"
                                name="sh_slaughtering_outside_without_valid_storage_license"
                                value={values.sh_slaughtering_outside_without_valid_storage_license || false}
                                error={errors.sh_slaughtering_outside_without_valid_storage_license}
                                onChange={(text) => handleTextChange("sh_slaughtering_outside_without_valid_storage_license", text)}
                            />
                        </div>
                        <div className="form-group">
                            <Checkbox
                                placeholder="S.H. slaughtering outside without valid sanitation license"
                                name="sh_slaughtering_outside_without_valid_sanitation_license"
                                value={values.sh_slaughtering_outside_without_valid_sanitation_license || false}
                                error={errors.sh_slaughtering_outside_without_valid_sanitation_license}
                                onChange={(text) => handleTextChange("sh_slaughtering_outside_without_valid_sanitation_license", text)}
                            />
                        </div>
                        <div className="form-group">
                            <label className="control-label" htmlFor="remarks"
                            >Remarks : </label>
                            <div>
                                <TextAreaInput
                                    type="number"
                                    name="remarks"
                                    value={values.remarks}
                                    error={errors.remarks}
                                    onChange={(e) =>handleTextChange("remarks", e.target.value)}
                                />
                            </div>
                        </div>           
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateSlaughterHouseReports: (data) => dispatch(createSlaughterHouseReports(data)),
});

const mapStateToProps = (state) => ({
    user: state.home.user,
    districts: state.home.districts
});
  
export default connect(mapStateToProps, mapDispatchToProps)(SlaughterHouseReport);