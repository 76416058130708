const getAuthToken = () => {
    const authToken = localStorage.getItem('token');
    return authToken;
}

const setAuthToken = (authToken) => {
    localStorage.setItem('token', authToken);
}

const removeAuthToken = () => {
    localStorage.removeItem('token');
};

export { getAuthToken, setAuthToken, removeAuthToken };
