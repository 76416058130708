import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextInput from "../../components/inputs/TextInput";
import DropDownInput from "../../components/inputs/DropDownInput";
import Datepicker from "../../components/inputs/Datepicker"
import { createExpectedInspectionReport, editExpectedInspectionReport } from "../../components/reducers/actions"
import { useParams } from "react-router-dom";

const ExpectedInspectionReport = (props) => {
    const { districts, user, wards, onCreateExpectedInspectionReports, onEditExpectedInspectionReport } = props;
    const [values, setValues] = useState({
        'district' : '',
        'date' : '',
        'new_eateries' : '',
        'expected_inspection' : '',
        'foscoris_inspection' : '',        
        'achieved_percentage' : ''
    });
    const [errors, setErrors] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(true);
    let { slug } = useParams();

    districts.sort(function (a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    useEffect(() => {
        if (props.history.location && props.history.location.state) {
            const recordData = props.history.location.state.values;
            setValues(valueState=>{ Object.assign(valueState,recordData); return valueState;} );

        } else if (slug) {
            console.log(slug);
        }
        if(user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso")) {
            const userData = { ...values, district: user.district };
            setValues(userData);
        }
        // eslint-disable-next-line         
    }, [])

    const validateValues = (values) => {
        var updatedErrors = {};
        var keys = ['district', 'date', 'new_eateries', 'expected_inspection',
        'foscoris_inspection'];
        var shouldDisableSubmit = true;
        keys.forEach((key) => {
          if (!values[key] || values[key] === '') {
            updatedErrors[key] = ' ';
          }
        });    
        shouldDisableSubmit = Object.keys(updatedErrors).length > 0;
        setDisableSubmit(shouldDisableSubmit);
        setErrors(updatedErrors);
        return updatedErrors;
    }

    const handleSubmit = async (event) => {    
        if (props.history.location && props.history.location.state) {
            const data = { ...values}
            const resp = await onEditExpectedInspectionReport(data, values.id);
            if (resp && resp.response) {
                console.log(resp.response);
                props.history.push(`/app/display-reports/expected-inspection-list/`);
            };

        } else {   
            const data = { ...values}
            setDisableSubmit(true);
            const resp = await onCreateExpectedInspectionReports(data);
            if (resp && resp.response) {
                console.log(resp.response)
                props.history.push("/app/reports/");
            };
        }
    }

    const handleTextChange = (key, value) => {
        const updatedValues = { ...values, [key]: value };
        setValues(updatedValues);
        validateValues(updatedValues);
    }

    useEffect(() => {
        if ((values.foscoris_inspection) && (values.expected_inspection)) {
            let percentage = ((values.foscoris_inspection/values.expected_inspection) * 100).toFixed();
            const updatedValues = { ...values, achieved_percentage: percentage };
            setValues(updatedValues);
        }
        // eslint-disable-next-line  
      }, [values.foscoris_inspection, values.expected_inspection]);

    return (

        <div className="container-fluid">
            <h1 className="h3 mb-4 text-gray-800">Expected Inspection Report</h1>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <form>
                        {user && user.user_types && user.user_types.includes("do") || user && user.user_types && user.user_types.includes("fso") ? (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        type="text" isDisabled
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(value) => handleTextChange("district", value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row">
                                <label className="control-label col-sm-4 text-label" htmlFor="district">District : </label>
                                <div className="col-sm-8">
                                    <DropDownInput
                                        placeholder={`District`}
                                        type="text"
                                        name="district"
                                        value={values.district}
                                        error={errors.district}
                                        items={districts}
                                        onChange={(text) => handleTextChange("district", text)}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="date"
                            >Date *: </label>
                            <div className="col-sm-8">
                                <Datepicker
                                    placeholder={`Date`}
                                    name="date"
                                    id="date"
                                    value={values.date}
                                    error={errors.date}
                                    onChangeText={(text) => handleTextChange('date', text)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                        <label className="control-label col-sm-4 text-label" htmlFor="new_eateries"
                            >New eateries *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`new eateries`}
                                    type="number"
                                    name="new_eateries"
                                    value={values.new_eateries}
                                    error={errors.new_eateries}
                                    onChange={(e) => handleTextChange("new_eateries", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="expected_inspection"
                            >Expected inspection *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Expected inspection`}
                                    type="number"
                                    name="expected_inspection"
                                    value={values.expected_inspection}
                                    error={errors.expected_inspection}
                                    onChange={(e) =>handleTextChange("expected_inspection", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="foscoris_inspection"
                            >Foscoris inspection *: </label>
                            <div className="col-sm-8">
                                <TextInput
                                    placeholder={`Foscoris inspection`}
                                    type="number"
                                    name="foscoris_inspection"
                                    value={values.foscoris_inspection}
                                    error={errors.foscoris_inspection}
                                    onChange={(e) => handleTextChange("foscoris_inspection", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="control-label col-sm-4 text-label" htmlFor="achieved_percentage"
                            >Achieved percentage : </label>
                            <div className="col-sm-8">
                                {values.achieved_percentage ? values.achieved_percentage : 0}
                            </div>
                        </div>  
                        <div className="form-group">
                            <button
                                onClick={() => props.history.push("/app/reports/")}
                                className="btn btn-success"
                                type="button">
                                Cancel</button>
                            <button
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                                type="button"
                                className="btn btn-success ml-3">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    )
}

const mapDispatchToProps = (dispatch) => ({
    onCreateExpectedInspectionReports: (data) => dispatch(createExpectedInspectionReport(data)),
    onEditExpectedInspectionReport: (data, record_id) => dispatch(editExpectedInspectionReport(data, record_id))
});

const mapStateToProps = (state) => ({
    districts: state.home.districts,
    user: state.home.user
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ExpectedInspectionReport);